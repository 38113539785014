import React from "react"
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material"
import { useStyles } from "./style"
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
const ManagementCard = (
    {
        t = () => false,
        subTitle,
        title,
        onClick = () => false,
        onClose = () => false,
        hidden = "",
        open = false,
        avatar = "",
        onSelect = () => false,
        dialogData = {},
        hideViewMore = false,
        extraTitle = "",
        mailPhone = ""
    }
) => {
    const classes = useStyles()
    return (
        <Box>
            <Stack direction={"row"} columnGap={1} justifyContent={"space-between"} alignItems={"center"} className={classes.rootBox} onClick={onSelect}>

                <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                    <Avatar className={classes.img} src={avatar} />
                    <Stack>
                        <Typography className={classes.title}>{title}</Typography>
                        {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
                        {!hideViewMore && <Typography className={classes.viewMore} onClick={onClick}>{t("View More")}</Typography>}
                        {extraTitle && <Typography className={classes.secondSubTitle}>{extraTitle}</Typography>}
                    </Stack>
                </Stack>
                <Stack direction={"row"} columnGap={1} justifyContent={"space-between"} alignItems={"center"}>
                    {/* <ChattingIcon />
                    <VideoIcon /> */}
                    <a
                        href={`mailto:${mailPhone?.email_id}`}
                        style={{ textDecorationLine: "none" }}
                    >
                        <MailIcon sx={{ color: "#bd2c2c", fontSize: "20px" }} />
                    </a>
                    <a
                        href={`tel:${mailPhone?.country_code}${mailPhone?.mobile_no}`}
                        style={{ textDecorationLine: "none" }}
                        target="_blank" rel="noreferrer"
                    >
                        <PhoneIcon sx={{ color: "#bd2c2c", fontSize: "20px" }} />
                    </a>
                </Stack>



            </Stack>
            {hidden && <Divider className={classes.divider} />}
        </Box>

    )
}

export default ManagementCard