export const UnitAssetInfo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g id="Group_116908" data-name="Group 116908" transform="translate(2392 17508)">
                <g id="Rectangle_57719" data-name="Rectangle 57719" transform="translate(-2388 -17504)" fill="#5ac782" stroke="#fff" stroke-width="4">
                    <rect width="24" height="24" rx="12" stroke="none" />
                    <rect x="-2" y="-2" width="28" height="28" rx="14" fill="none" />
                </g>
                <path id="icons8-info_7_" data-name="icons8-info (7)" d="M12,4a8,8,0,1,0,8,8A8.009,8.009,0,0,0,12,4Zm0,1.2A6.8,6.8,0,1,1,5.2,12,6.791,6.791,0,0,1,12,5.2ZM12,8a.8.8,0,1,0,.8.8A.8.8,0,0,0,12,8Zm-.009,2.791a.6.6,0,0,0-.591.609v4.4a.6.6,0,1,0,1.2,0V11.4a.6.6,0,0,0-.609-.609Z" transform="translate(-2388 -17504)" fill="#fff" />
            </g>
        </svg>

    )
}