import {Box, Typography,Avatar} from "@mui/material";
import {useStyles} from "./styles";

const RequestBox = (props) => {
    const classes = useStyles();
    return (
        <Box display="flex" alignItems="center">
            <Avatar sx={{backgroundColor:props?.color??"#FEEAEA"}} className={classes.icon}>
            {props.icon}
            </Avatar>
            <Box display="flex" flexDirection="column">
            <Typography className={classes.count}>{props.count}</Typography>
            <Typography className={classes.title}>{props.title}</Typography>
            </Box>
        </Box>
    );
};

export default RequestBox;