import * as React from "react"

export const AsPerAgreement = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={58.852}
    height={71.642}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.473}
        y1={1.217}
        x2={-0.57}
        y2={2.526}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#09005d" />
        <stop offset={1} stopColor="#1a0f91" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={1.399}
        y1={-1.314}
        x2={-0.243}
        y2={2.239}
        xlinkHref="#a"
      />
      <linearGradient
        id="h"
        x1={3.045}
        y1={8.252}
        x2={0.13}
        y2={11.808}
        xlinkHref="#a"
      />
      <linearGradient
        id="i"
        x1={-0.943}
        y1={40.196}
        x2={-2.022}
        y2={46.559}
        xlinkHref="#a"
      />
      <linearGradient
        id="j"
        x1={-0.437}
        y1={40.426}
        x2={-0.882}
        y2={46.638}
        xlinkHref="#a"
      />
      <linearGradient
        id="b"
        x1={3.268}
        y1={-3.767}
        x2={-1.484}
        y2={4.023}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#4042e2" />
        <stop offset={1} stopColor="#4f52ff" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={3.467}
        y1={-3.416}
        x2={-2.099}
        y2={4.194}
        xlinkHref="#b"
      />
      <linearGradient
        id="l"
        x1={3.303}
        y1={-3.258}
        x2={-2.532}
        y2={4.565}
        xlinkHref="#b"
      />
      <linearGradient
        id="m"
        x1={4.053}
        y1={-3.024}
        x2={-3.835}
        y2={4.799}
        xlinkHref="#b"
      />
      <linearGradient
        id="n"
        x1={3.827}
        y1={-2.799}
        x2={-4.062}
        y2={5.023}
        xlinkHref="#b"
      />
      <linearGradient
        id="o"
        x1={1.972}
        y1={-2.486}
        x2={-1.885}
        y2={5.337}
        xlinkHref="#b"
      />
      <linearGradient
        id="p"
        x1={3.183}
        y1={-2.161}
        x2={-4.706}
        y2={5.662}
        xlinkHref="#b"
      />
      <linearGradient
        id="q"
        x1={2.05}
        y1={-1.889}
        x2={-3.026}
        y2={5.934}
        xlinkHref="#b"
      />
      <linearGradient
        id="r"
        x1={2.048}
        y1={-1.646}
        x2={-4.208}
        y2={6.177}
        xlinkHref="#b"
      />
      <linearGradient
        id="s"
        x1={54.491}
        y1={-1.002}
        x2={51.654}
        y2={5.386}
        xlinkHref="#a"
      />
      <linearGradient
        id="c"
        x1={213.98}
        y1={-0.502}
        x2={214.986}
        y2={-0.502}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#febbba" />
        <stop offset={1} stopColor="#ff928e" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={-1.426}
        y1={1.347}
        x2={1.597}
        y2={0.029}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#4f52ff" />
        <stop offset={1} stopColor="#4042e2" />
      </linearGradient>
      <linearGradient
        id="t"
        x1={135.555}
        y1={-2.813}
        x2={128.535}
        y2={4.751}
        xlinkHref="#a"
      />
      <linearGradient
        id="u"
        x1={249.449}
        y1={-0.24}
        x2={250.463}
        y2={-0.24}
        xlinkHref="#c"
      />
      <linearGradient
        id="v"
        x1={-0.953}
        y1={1.273}
        x2={1.069}
        y2={-0.026}
        xlinkHref="#d"
      />
      <linearGradient
        id="e"
        x1={19.95}
        y1={-23.421}
        x2={18.688}
        y2={-21.99}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ff928e" />
        <stop offset={1} stopColor="#fe7062" />
      </linearGradient>
      <linearGradient
        id="w"
        x1={41.268}
        y1={0.32}
        x2={42.262}
        y2={0.32}
        xlinkHref="#c"
      />
      <linearGradient
        id="x"
        x1={-43.305}
        y1={1.225}
        x2={-41.325}
        y2={3.909}
        xlinkHref="#c"
      />
      <linearGradient
        id="y"
        x1={297.224}
        y1={-0.183}
        x2={299.226}
        y2={-1.963}
        xlinkHref="#c"
      />
      <linearGradient
        id="z"
        x1={51.341}
        y1={-59.497}
        x2={48.168}
        y2={-55.853}
        xlinkHref="#e"
      />
      <linearGradient
        id="A"
        x1={32.89}
        y1={0.213}
        x2={33.891}
        y2={0.213}
        xlinkHref="#c"
      />
      <linearGradient
        id="B"
        x1={-0.778}
        y1={3.091}
        x2={0.244}
        y2={1.105}
        xlinkHref="#c"
      />
      <linearGradient
        id="C"
        x1={14.942}
        y1={32.585}
        x2={14.618}
        y2={31.519}
        xlinkHref="#c"
      />
      <linearGradient
        id="f"
        x1={2.503}
        y1={-2.274}
        x2={0.663}
        y2={0.173}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#e1473d" />
        <stop offset={1} stopColor="#e9605a" />
      </linearGradient>
      <linearGradient
        id="D"
        x1={47.402}
        y1={154.373}
        x2={46.455}
        y2={149.76}
        xlinkHref="#c"
      />
      <linearGradient
        id="E"
        x1={30.093}
        y1={-0.062}
        x2={30.607}
        y2={2.428}
        xlinkHref="#a"
      />
      <linearGradient
        id="F"
        x1={102.164}
        y1={0.5}
        x2={103.164}
        y2={0.5}
        xlinkHref="#c"
      />
      <linearGradient
        id="G"
        x1={0.992}
        y1={0.943}
        x2={-0.382}
        y2={-0.026}
        xlinkHref="#f"
      />
      <linearGradient
        id="H"
        x1={4.217}
        y1={-5.772}
        x2={-1.613}
        y2={4.051}
        xlinkHref="#d"
      />
      <linearGradient
        id="I"
        x1={-36.984}
        y1={1.602}
        x2={-35.949}
        y2={-1.187}
        xlinkHref="#c"
      />
      <linearGradient
        id="J"
        x1={3.773}
        y1={-5.215}
        x2={0.17}
        y2={1.479}
        xlinkHref="#d"
      />
      <linearGradient
        id="K"
        x1={-16.328}
        y1={1.698}
        x2={-15.865}
        y2={-1.193}
        xlinkHref="#c"
      />
      <linearGradient
        id="L"
        x1={-16.171}
        y1={0.411}
        x2={-15.938}
        y2={2.003}
        xlinkHref="#d"
      />
      <linearGradient
        id="M"
        x1={-12.088}
        y1={-1.105}
        x2={-13.074}
        y2={-0.09}
        xlinkHref="#d"
      />
      <linearGradient
        id="N"
        x1={-48.456}
        y1={1.857}
        x2={-48.202}
        y2={0.807}
        xlinkHref="#c"
      />
      <linearGradient
        id="O"
        x1={-28.767}
        y1={0.5}
        x2={-27.767}
        y2={0.5}
        xlinkHref="#c"
      />
      <linearGradient
        id="P"
        x1={-34.267}
        y1={-0.391}
        x2={-32.734}
        y2={-2.644}
        xlinkHref="#c"
      />
      <linearGradient
        id="Q"
        x1={2.469}
        y1={2.121}
        x2={0.084}
        y2={0.007}
        xlinkHref="#f"
      />
      <linearGradient
        id="R"
        x1={-131.188}
        y1={-1.999}
        x2={-125.395}
        y2={-9.14}
        xlinkHref="#c"
      />
    </defs>
    <g data-name="Group 105029">
      <g data-name="Group 105020">
        <path
          data-name="Path 98469"
          d="m1134.868 878.589 38.56.3a3.911 3.911 0 0 1 3.958 3.64l2.384 53.231a2.868 2.868 0 0 1-2.914 2.923l-44.2.417a2.731 2.731 0 0 1-2.824-2.683l.464-53.551a4.434 4.434 0 0 1 4.572-4.277Z"
          transform="translate(-1126.358 -870.682)"
          fill="url(#a)"
        />
        <path
          data-name="Path 98470"
          d="M5.03 55.367a.123.123 0 0 1-.124-.121l.01-1.151a.123.123 0 0 1 .127-.119.123.123 0 0 1 .124.121l-.01 1.151a.123.123 0 0 1-.127.119Z"
          fill="#fff"
        />
        <g data-name="Group 105018">
          <path
            data-name="Path 98471"
            d="M8.633 7.477c5.26.721 23.349 2.965 38.329 1.989a1.2 1.2 0 0 1 1.3 1.1l2.521 53.469a1.361 1.361 0 0 1-1.329 1.393c-6.3.305-32.242 1.472-41.483.478a1.077 1.077 0 0 1-.985-1.054l.14-56.14a1.3 1.3 0 0 1 1.5-1.239Z"
            fill="#7d97f4"
          />
          <path
            data-name="Path 98472"
            d="M9.185 8.867c5.061.694 22.469 2.854 36.886 1.914a1.153 1.153 0 0 1 1.253 1.062l2.425 51.456a1.31 1.31 0 0 1-1.279 1.34c-6.062.294-31.028 1.417-39.921.46a1.037 1.037 0 0 1-.948-1.014l.135-54.026a1.248 1.248 0 0 1 1.448-1.192Z"
            fill="#bdd0fb"
          />
          <g data-name="Group 105011" opacity={0.6}>
            <path
              data-name="Path 98473"
              d="M11.306 23.588c-.21 0-.317.151-.238.336l.587 1.379a.606.606 0 0 0 .525.332l32.565-.414a.359.359 0 0 0 .364-.368l-.039-.814a.4.4 0 0 0-.4-.364l-33.364-.088Z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 105012" opacity={0.6}>
            <path
              data-name="Path 98474"
              d="M11.24 28.635a.229.229 0 0 0-.238.336l.587 1.379a.606.606 0 0 0 .525.332l32.565-.414a.359.359 0 0 0 .365-.368l-.039-.814a.4.4 0 0 0-.4-.364l-33.364-.088Z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 105013" opacity={0.6}>
            <path
              data-name="Path 98475"
              d="M11.318 33.687a.229.229 0 0 0-.239.336l.587 1.379a.605.605 0 0 0 .525.332l32.565-.414a.359.359 0 0 0 .365-.368l-.039-.814a.4.4 0 0 0-.4-.364l-33.365-.088Z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 105014" opacity={0.6}>
            <path
              data-name="Path 98476"
              d="M11.296 38.367c-.21 0-.318.151-.238.336l.587 1.379a.605.605 0 0 0 .526.332L44.736 40a.359.359 0 0 0 .365-.368l-.039-.813a.4.4 0 0 0-.4-.365l-33.364-.088Z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 105015" opacity={0.6}>
            <path
              data-name="Path 98477"
              d="M11.425 43.358c-.21 0-.318.151-.239.336l.587 1.379a.6.6 0 0 0 .525.332l32.565-.414a.359.359 0 0 0 .365-.368l-.039-.814a.4.4 0 0 0-.4-.364l-33.364-.087Z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 105016" opacity={0.6}>
            <path
              data-name="Path 98478"
              d="M11.212 55.805a.272.272 0 0 0-.294.347l.338 1.353a.5.5 0 0 0 .471.352l18.9-.131a.365.365 0 0 0 .372-.366l-.023-.814a.4.4 0 0 0-.392-.371Z"
              fill="#fff"
            />
          </g>
          <g data-name="Group 105017" opacity={0.6}>
            <path
              data-name="Path 98479"
              d="M11.228 60.111a.272.272 0 0 0-.293.346l.338 1.353a.5.5 0 0 0 .47.351l18.9-.131a.366.366 0 0 0 .372-.367l-.023-.813a.4.4 0 0 0-.392-.371Z"
              fill="#fff"
            />
          </g>
          <path
            data-name="Path 98480"
            d="M2155.323 2503.038a.985.985 0 0 1-.644-.29 3.936 3.936 0 0 1-1.09-2.873c-.38.405-.768.8-1.153 1.188l-.209-.186c.45-.457.906-.919 1.346-1.4a29.655 29.655 0 0 1 .208-4.316 8.582 8.582 0 0 1 .659-2.647 2.157 2.157 0 0 1 .7-.9 1.139 1.139 0 0 1 1.2-.066 1.357 1.357 0 0 1 .517 1.01 7.313 7.313 0 0 1-1.207 4.713 15.8 15.8 0 0 1-1.791 2.3 3.976 3.976 0 0 0 1.009 2.968.712.712 0 0 0 .465.218c.23-.008.418-.222.571-.429a11.537 11.537 0 0 0 1.606-3.052 3.085 3.085 0 0 1 .286-.945l.269.09q-.119.464-.274.915a2.792 2.792 0 0 0 .038.925c.033.161.177.7.592.771.308.056.631-.167.987-.681l1.448-2.1-.181.743c-.065.266-.174.9.218 1.056.223.087.458-.04.73-.185a1.459 1.459 0 0 1 .866-.254l.147.018-.025.14c-.015.081-.132.8.258.878.178.037.328-.079.553-.277a1.414 1.414 0 0 1 .6-.371l.068.265a1.193 1.193 0 0 0-.47.305.943.943 0 0 1-.809.344c-.441-.094-.549-.568-.5-1.031a1.715 1.715 0 0 0-.542.221 1.167 1.167 0 0 1-.98.2.788.788 0 0 1-.46-.787l-.677.981c-.272.393-.7.9-1.28.8a1.14 1.14 0 0 1-.82-.984 2.744 2.744 0 0 1-.039-.238 11.9 11.9 0 0 1-1.369 2.406 1.11 1.11 0 0 1-.8.546Zm.506-11.356a.983.983 0 0 0-.525.162 1.894 1.894 0 0 0-.6.784 8.358 8.358 0 0 0-.636 2.567 29.505 29.505 0 0 0-.211 3.976 14.811 14.811 0 0 0 1.554-2.033 7.053 7.053 0 0 0 1.17-4.54 1.073 1.073 0 0 0-.393-.818.651.651 0 0 0-.358-.099Z"
            transform="translate(-2119.944 -2440.248)"
            fill="url(#g)"
          />
        </g>
        <path
          data-name="Path 98481"
          d="M1918.5 584.575a4.077 4.077 0 0 0-3.45-.692 1.625 1.625 0 0 0-1.223 1.692l.335 3.822 4.372.4s2.686-3.391-.034-5.222Zm-1.95 3.066a1.04 1.04 0 1 1 1.1-1.03 1.065 1.065 0 0 1-1.097 1.031Z"
          transform="translate(-1888.259 -583.752)"
          fill="url(#h)"
        />
        <path
          data-name="Path 98482"
          d="M26.282 4.135a.207.207 0 0 1-.14-.176l-.053-.609a.209.209 0 0 1 .2-.223.214.214 0 0 1 .234.188l.053.609a.209.209 0 0 1-.2.223.228.228 0 0 1-.094-.012Zm-.1-1.193a.208.208 0 0 1-.14-.176l-.041-.462A1.656 1.656 0 0 1 27.246.582 3.548 3.548 0 0 1 28.899.53a.209.209 0 0 1 .167.245.217.217 0 0 1-.257.159 3.1 3.1 0 0 0-1.443.045 1.242 1.242 0 0 0-.933 1.292l.041.462a.21.21 0 0 1-.2.223.23.23 0 0 1-.097-.014Z"
          fill="#bdd0fb"
        />
        <path
          data-name="Path 98483"
          d="m1713.24 772.7.017-1.92a1.206 1.206 0 0 1 1.283-1.161l13.907.563a1.17 1.17 0 0 1 1.145 1.08l.1 1.846Z"
          transform="translate(-1693.327 -764.636)"
          fill="url(#i)"
        />
        <path
          data-name="Path 98484"
          d="M24.52 5.755a.12.12 0 1 1 0-.24h.6a.122.122 0 0 1 .125.121.123.123 0 0 1-.127.119h-.6Z"
          fill="#bdd0fb"
        />
        <path
          data-name="Path 98485"
          d="M20.596 7.559a.206.206 0 0 1-.14-.195l.007-.777a1.158 1.158 0 0 1 .375-.841 1.283 1.283 0 0 1 .891-.343l2.2.017a.207.207 0 1 1 0 .413l-2.2-.018a.8.8 0 0 0-.829.775l-.007.777a.213.213 0 0 1-.219.2.236.236 0 0 1-.078-.008Z"
          fill="#bdd0fb"
        />
        <path
          data-name="Path 98486"
          d="m1511.043 857.071 24.326.191a1.774 1.774 0 0 1 1.759 1.433l.02.113a1.5 1.5 0 0 1-1.536 1.708l-24.956-.2a.977.977 0 0 1-.994-1.009l.063-1.063a1.286 1.286 0 0 1 1.318-1.173Z"
          transform="translate(-1495.482 -849.741)"
          fill="url(#j)"
        />
        <g data-name="Group 105019">
          <path
            data-name="Path 98487"
            d="m1408.943 1127.682-.289-1.21h-1.312l-.289 1.21h-.937l1.247-4.813h1.271l1.25 4.813Zm-.465-2-.269-1.115c-.016-.068-.052-.238-.109-.51s-.09-.454-.1-.546q-.045.282-.113.621t-.358 1.549Z"
            transform="translate(-1394.856 -1108.41)"
            fill="url(#b)"
          />
          <path
            data-name="Path 98488"
            d="M1551.779 1123.231h1.539v2.459a3.665 3.665 0 0 1-1.391.266 1.585 1.585 0 0 1-1.352-.636 3.028 3.028 0 0 1-.474-1.833 2.745 2.745 0 0 1 .543-1.806 1.865 1.865 0 0 1 1.517-.653 2.392 2.392 0 0 1 1.15.3l-.3.728a1.664 1.664 0 0 0-.8-.236.976.976 0 0 0-.846.454 2.158 2.158 0 0 0-.312 1.237 2.712 2.712 0 0 0 .226 1.236.71.71 0 0 0 .656.429 1.965 1.965 0 0 0 .461-.056v-1.1h-.623Z"
            transform="translate(-1534.785 -1106.618)"
            fill="url(#k)"
          />
          <path
            data-name="Path 98489"
            d="M1692.469 1126.532v1.865h-.937v-4.797h1.226a1.62 1.62 0 0 1 1.149.351 1.411 1.411 0 0 1 .37 1.072 1.329 1.329 0 0 1-.644 1.269l.968 2.1h-1l-.8-1.865h-.334Zm0-.767h.241a.577.577 0 0 0 .475-.179.835.835 0 0 0 .145-.533.729.729 0 0 0-.153-.523.639.639 0 0 0-.474-.156h-.234Z"
            transform="translate(-1672.231 -1109.125)"
            fill="url(#l)"
          />
          <path
            data-name="Path 98490"
            d="M1818.968 1128.4h-2.27v-4.8h2.27v.77h-1.333v1.141h1.237v.774h-1.237v1.328h1.333Z"
            transform="translate(-1793.87 -1109.123)"
            fill="url(#m)"
          />
          <path
            data-name="Path 98491"
            d="M1925.558 1128.4h-2.27v-4.8h2.27v.77h-1.333v1.141h1.237v.774h-1.237v1.328h1.333Z"
            transform="translate(-1897.456 -1109.123)"
            fill="url(#n)"
          />
          <path
            data-name="Path 98492"
            d="m2031.672 1128.395-.958-4.082h-.038a6.334 6.334 0 0 1 .066.866v3.216h-.868v-4.795h1.44l.861 3.767h.028l.854-3.767h1.46v4.793h-.888v-3.249a7.271 7.271 0 0 1 .048-.826h-.028l-.954 4.075Z"
            transform="translate(-2001.039 -1109.123)"
            fill="url(#o)"
          />
          <path
            data-name="Path 98493"
            d="M2228.458 1128.4h-2.269v-4.8h2.269v.77h-1.333v1.141h1.237v.774h-1.237v1.328h1.333Z"
            transform="translate(-2191.822 -1109.123)"
            fill="url(#p)"
          />
          <path
            data-name="Path 98494"
            d="M2336.3 1128.394h-1.143l-1.557-3.472h-.045a4.048 4.048 0 0 1 .087.853v2.619h-.868v-4.794h1.151l1.543 3.435h.038a4.437 4.437 0 0 1-.065-.819v-2.616h.861Z"
            transform="translate(-2295.402 -1109.122)"
            fill="url(#q)"
          />
          <path
            data-name="Path 98495"
            d="M2478.906 1128.4h-.934v-4.009h-.965v-.783h2.863v.783h-.965Z"
            transform="translate(-2435.572 -1109.123)"
            fill="url(#r)"
          />
        </g>
      </g>
      <g data-name="Group 105026">
        <path
          data-name="Path 98496"
          d="M1346.762 3129.18s-.174.886-.263 1.764a.616.616 0 0 0 .6.662c1.229.06 3.857.178 4.8.146a.2.2 0 0 0 .185-.268.448.448 0 0 0-.234-.24l-4.323-1.939Z"
          transform="translate(-1336.916 -3060.913)"
          fill="url(#s)"
        />
        <path
          data-name="Path 98497"
          d="m1351.33 3104.1.122 1.063a1.447 1.447 0 0 0 1.281-.031l-.3-1.186Z"
          transform="translate(-1341.615 -3036.36)"
          fill="url(#c)"
        />
        <path
          data-name="Path 98498"
          d="M1242.493 2364.606c-.06.276-.445 13.83 1.016 19.918a.2.2 0 0 1-.129.239 3.936 3.936 0 0 1-1.242.229.371.371 0 0 1-.367-.258 66.655 66.655 0 0 1-2.64-15.241c-.229-7.541 0-.257 0-.257l.225-4.658Z"
          transform="translate(-1232.479 -2316.816)"
          fill="url(#d)"
        />
        <path
          data-name="Path 98499"
          d="M1159.829 3144.751a6.15 6.15 0 0 0-.532 1.633.372.372 0 0 0 .337.445c.406.042 1.03.1 1.53.1a.376.376 0 0 0 .387-.408 3.021 3.021 0 0 0-.744-1.709Z"
          transform="translate(-1154.984 -3076.067)"
          fill="url(#t)"
        />
        <path
          data-name="Path 98500"
          d="m1170.8 3091.861.293 1.608a1.137 1.137 0 0 0 .91.067l-.13-1.729Z"
          transform="translate(-1166.172 -3024.543)"
          fill="url(#u)"
        />
        <path
          data-name="Path 98501"
          d="M1129.1 2353.934c-1.317 7.14.093 17.679.583 19.744a.329.329 0 0 0 .27.246 2.375 2.375 0 0 0 .974-.028.3.3 0 0 0 .225-.27c.085-1.664.064-11.889 1.265-15.919a.92.92 0 0 1 .377-.5l2.267-1.5.2-2.462Z"
          transform="translate(-1125.115 -2305.782)"
          fill="url(#v)"
        />
        <g data-name="Group 105021">
          <path
            data-name="Path 98502"
            d="M1141.87 1856.729a36.746 36.746 0 0 0-7.858.861 50.943 50.943 0 0 0-.328 13.3 12.277 12.277 0 0 0 6.152-.385c.506-2.578 2.818-9.823 2.818-9.823a12.732 12.732 0 0 0 4.576-1.337 7.448 7.448 0 0 1-1.55-3.162 13.109 13.109 0 0 1-3.81.546Z"
            transform="translate(-1129.816 -1822.057)"
            fill="url(#e)"
          />
          <path
            data-name="Path 98503"
            d="M1576.857 1675.266a.739.739 0 0 0-.828-.229c-.571 2.2-3.468 3.967-6.435 4.814a7.445 7.445 0 0 0 1.55 3.163 11.349 11.349 0 0 0 5.713-7.748Z"
            transform="translate(-1553.729 -1645.725)"
            fill="url(#w)"
          />
        </g>
        <g data-name="Group 105022">
          <path
            data-name="Path 98504"
            d="M1798.995 1552.057c.037.116-.409.289-.666-.188-.007-.228-.143-1.2 0-1.388a18.628 18.628 0 0 1 2.257-2.2.323.323 0 0 1 .039.464l-.722.7a3.237 3.237 0 0 1 .409.79.915.915 0 0 1-.107.7c-.128.214-.3.492-.466.71a1.007 1.007 0 0 1-.744.412Z"
            transform="translate(-1775.956 -1522.41)"
            fill="url(#x)"
          />
          <path
            data-name="Path 98505"
            d="m1815.315 1608.851-.657.52-.21.522.575.619a6.856 6.856 0 0 1 .353-1.261c.148-.224.074-.334-.061-.4Z"
            transform="translate(-1791.683 -1581.358)"
            fill="url(#y)"
          />
        </g>
        <path
          data-name="Path 98506"
          d="m1015.156 1912.1-1.4-3.452s-3.508 2.222-4.11 5.075a8.572 8.572 0 0 1 3.022.8c.275-1.347 2.488-2.423 2.488-2.423Z"
          transform="translate(-1009.562 -1873.114)"
          fill="url(#z)"
        />
        <path
          data-name="Path 98507"
          d="M1015.157 2099.5s-3.934 2.07-5.236.26a1.359 1.359 0 0 1-.261-1.1 8.587 8.587 0 0 0-3.022-.8 3.778 3.778 0 0 0 .423 2.708c2.273 4.048 8.605-.145 8.605-.145Z"
          transform="translate(-1006.55 -2057.254)"
          fill="url(#A)"
        />
        <path
          data-name="Path 98508"
          d="m1258.922 1767.75-1.22 3.257a.116.116 0 0 0 .055.143 2.912 2.912 0 0 0 2.9-.159.552.552 0 0 0 .272-.393l.413-2.825Z"
          transform="translate(-1250.618 -1735.996)"
          fill="url(#B)"
        />
        <g data-name="Group 105023">
          <path
            data-name="Path 98509"
            d="M1288.749 1655.8s1.28 2.348 1.1 3.29-1.792.86-3.2.08l-.961-2.531Z"
            transform="translate(-1277.832 -1627.048)"
            fill="url(#C)"
          />
          <path
            data-name="Path 98510"
            d="M1224.558 1596.266a.094.094 0 0 0 .045-.141 2.462 2.462 0 0 1-.355-.736s-.233-.145.6-.327 2.075-.464 2.342-1.135c.26-.654-.39-2.311-1.468-1a.1.1 0 0 1-.16 0 1.067 1.067 0 0 0-1.266-.4.91.91 0 0 0-.664.874.1.1 0 0 1-.12.1 1.19 1.19 0 0 0-1.368.592c-.284.579-.107.91.056 1.072a.094.094 0 0 1-.045.159.58.58 0 0 0-.435.655c.03.611.747.564.952.538a.105.105 0 0 1 .089.031 3.467 3.467 0 0 0 1.807 1.078.1.1 0 0 0 .108-.127l-.359-1.025a.1.1 0 0 1 .056-.119Z"
            transform="translate(-1215.656 -1565.383)"
            fill="url(#f)"
          />
          <path
            data-name="Path 98511"
            d="M1293.206 1729.608s-.922-.614-.995-.1.828.747 1.278.561Z"
            transform="translate(-1284.158 -1698.579)"
            fill="url(#D)"
          />
        </g>
        <g data-name="Group 105025">
          <g data-name="Group 105024">
            <path
              data-name="Path 98512"
              d="m1283.174 2001.018 2.143-7.123a.215.215 0 0 1 .184-.15 100.02 100.02 0 0 1 2.812-.269 33.557 33.557 0 0 1 4.468-.141.208.208 0 0 1 .179.3 70.283 70.283 0 0 1-4.429 7.61.222.222 0 0 1-.176.083l-4.972-.044a.211.211 0 0 1-.209-.266Z"
              transform="translate(-1275.371 -1955.5)"
              fill="url(#E)"
            />
            <path
              data-name="Path 98513"
              d="M1294.71 2128.672a4.943 4.943 0 0 1-.178-1.583c.12-.414.354-.947 1.131-.829a11.849 11.849 0 0 1 1.7.43s-.047.54-.638.366l.366.27s-.052.433-.552.2l.341.358a.406.406 0 0 1-.582.127c-.37-.226-.546.802-1.588.661Z"
              transform="translate(-1286.389 -2084.875)"
              fill="url(#F)"
            />
          </g>
          <path
            data-name="Path 98514"
            d="M12.801 40.103a.63.63 0 1 0 .629-.6.614.614 0 0 0-.629.6Z"
            fill="#91b3fa"
          />
          <path
            data-name="Path 98515"
            d="m10.192 40.499.436-1.408a.494.494 0 0 1 .377-.335 46.1 46.1 0 0 1 5.148-.33v-.17a45.112 45.112 0 0 0-5.181.333.668.668 0 0 0-.512.454l-.437 1.409Z"
            fill="#91b3fa"
          />
        </g>
      </g>
      <g data-name="Group 105028">
        <path
          data-name="Path 98516"
          d="M2630.453 1673.49s1 .461 1.18.991c.147.425 1.249.48 1.358 1.316a.983.983 0 0 0 .844.843c1.125.164 2.859.72 3.137 2.58a1.677 1.677 0 0 1-1.346 2.144 1.077 1.077 0 0 0-.857 1.243c.114.54-.056 1.164-1.039 1.658-2.2 1.106-4.4-2.622-4.4-2.622l-.451-6.509Z"
          transform="translate(-2583.161 -1644.265)"
          fill="url(#G)"
        />
        <path
          data-name="Path 98517"
          d="m3006.147 3179.737.143 1.116a.473.473 0 0 0 .425.4 7.844 7.844 0 0 0 1.569.024.4.4 0 0 0 .347-.512 3.983 3.983 0 0 0-.786-1.458h-1.3a.389.389 0 0 0-.398.43Z"
          transform="translate(-2949.799 -3109.699)"
          fill="url(#H)"
        />
        <path
          data-name="Path 98518"
          d="m2611.187 2708.01-.22 1.229s.735 7.085.866 9.919a2.136 2.136 0 0 1-.346 1.262l-1.277 1.975 3.248-1.824.065-.377-.532-.7.7-7.937a2.339 2.339 0 0 0-.291-1.347l-.672-1.2Z"
          transform="translate(-2565.021 -2651.039)"
          fill="url(#I)"
        />
        <path
          data-name="Path 98519"
          d="M2593.169 3148.589h1.036a.637.637 0 0 0 .5-.23c.323-.4.992-1.179 1.312-1.2.431-.028.376 1.43.376 1.43h.241l.307-1.811a1.059 1.059 0 0 0-.591-1.121 1.5 1.5 0 0 1-.633 1c-.534.306-2.174 1.4-2.174 1.4a1.542 1.542 0 0 0-.421.011.26.26 0 0 0 .047.521Z"
          transform="translate(-2548.194 -3076.948)"
          fill="url(#J)"
        />
        <path
          data-name="Path 98520"
          d="M2802.73 2711.242s4.305 7.772 4.99 9.547a16.38 16.38 0 0 1 .932 3.4c.015.219.3.429 1.09.429a.77.77 0 0 0 .7-.306.42.42 0 0 0 .018-.424c-.249-.479-1.04-2.012-1.332-2.708-.35-.83-1.1-4.592-1.513-5.9a6.9 6.9 0 0 0-2.674-4.033s-1.228-1.154-2.211-.005Z"
          transform="translate(-2752.117 -2653.687)"
          fill="url(#K)"
        />
        <path
          data-name="Path 98521"
          d="M2497.333 1914.854a7.223 7.223 0 0 1 1.7-7.012 13.159 13.159 0 0 0-5.009-2.36s-3.086 2.147-2.721 3.828c.214.985 2.169 5.9 2.169 5.9a4.489 4.489 0 0 0 3.861-.356Z"
          transform="translate(-2449.439 -1870.035)"
          fill="url(#L)"
        />
        <path
          data-name="Path 98522"
          d="M2567.916 2257.123c-.452-1.3-2.462-2.217-2.462-2.217a4.489 4.489 0 0 1-3.857.358 7.031 7.031 0 0 0-.125 2.845c.28 1.37 2.035 10.178 2.035 10.178 2.766.533 7.019-.537 7.019-.537-1.763-2.977-2.158-9.331-2.61-10.627Z"
          transform="translate(-2517.56 -2210.087)"
          fill="url(#M)"
        />
        <path
          data-name="Path 98523"
          d="m2600.292 1781.765.9 3.86c.087.34-.24.284-.6.219a2.451 2.451 0 0 1-1.609-.763.4.4 0 0 1-.045-.146l-.388-2.677a.478.478 0 0 1 .3-.505l.749-.316a.51.51 0 0 1 .693.328Z"
          transform="translate(-2553.688 -1749.278)"
          fill="url(#N)"
        />
        <path
          data-name="Path 98524"
          d="M2716.794 1993.46s6.3 4.291.806 8.8a1.432 1.432 0 0 1-.622.343 6.953 6.953 0 0 0-1.136.733.22.22 0 0 1-.313-.053.266.266 0 0 1-.016-.266l.78-1.571a.625.625 0 0 1 .781-.032.3.3 0 0 0 .45-.1c.425-.824 1.228-3.245-2.055-5.983a.328.328 0 0 1-.079-.411 3.119 3.119 0 0 1 1.404-1.46Z"
          transform="translate(-2667.198 -1955.653)"
          fill="url(#O)"
        />
        <g data-name="Group 105027">
          <path
            data-name="Path 98525"
            d="M2547.338 1658.93a3.8 3.8 0 0 0-1.375 2.2c-.413 1.577.258 2.143.947 2.108a3.241 3.241 0 0 0 2.549-1.368c.865-1.135-.859-3.696-2.121-2.94Z"
            transform="translate(-2502.462 -1629.962)"
            fill="url(#P)"
          />
          <path
            data-name="Path 98526"
            d="M2560.093 1612.236s.352-2.26-1.619-1.914l-.4.395a1.184 1.184 0 0 1 .1-1.816 2.235 2.235 0 0 1 3.369.842c.82 1.423 1.118 2.423.43 3.307s-1.2.126-1.2.126.315-.765.054-1.094-.6.191-.6.191Z"
            transform="translate(-2513.968 -1580.967)"
            fill="url(#Q)"
          />
          <path
            data-name="Path 98527"
            d="M2653.03 1724.594s.174-1.163.8-.83-.225 1.086-.566 1.342Z"
            transform="translate(-2606.635 -1693.132)"
            fill="url(#R)"
          />
        </g>
      </g>
    </g>
  </svg>
)

