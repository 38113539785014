import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {},
    tabroot: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    tabItem: {
      textTransform: "capitalize",
      fontWeight: "bold",
      color: "#071741",
      padding: "0px",
      minHeight: "auto",
      minWidth: "auto",
      marginRight: "10px",
      backgroundColor: "white",
      borderRadius: "16px"
    },
    tabItemSelect: {
      textTransform: "capitalize",
      fontWeight: "bold",
      color: theme.typography.color.primary,
      marginRight: "8px",
      padding: "0px",
      borderRadius: "18px",
      minHeight: "auto",
      "& .MuiButtonBase-root-MuiTab-root": {
        minHeight: "0px",
      },
      minWidth: "auto",
    },
    title: {
      padding: "8px 14px",
      borderRadius: "4px",
      color: "#071741",
  
    },
    titleselect: {
      padding: "8px 14px",
      borderRadius: "4px",
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    more: {
      border: "1px solid #E4E8EE",
      borderRadius: "4px",
      background: theme.palette.background.paper,
    },
  }));