import { Avatar, Box, Typography } from "@mui/material";
import React from 'react';
import TickImageComponent from '../../../assets/tickImage';
import { useStyles } from "../style";

export const CompanyList = (props) => {
      const classes = useStyles();

      return (
            <Box className={props?.data?.id === props?.seleted ? classes.selectCompanyBorder : classes.selectCompany} onClick={() => props?.companySelect(props?.data)}>
                  <Box className={classes.companySelect}>
                        {/* company logo */}
                        <Avatar src={props?.data?.company_logo ?? ""} alt='comapany_img' className={classes.comapnyImage} />
                        {/* company name */}
                        <Typography className={classes.companyName} noWrap>{props?.data?.name}</Typography>
                  </Box>
                  {/* selected icon */}
                  <Box>
                        {
                              props?.data?.id === props?.seleted && <TickImageComponent />
                        }

                  </Box>
            </Box>
      )
}