export const LocationAssetIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_116869" data-name="Group 116869" transform="translate(-49 -896)">
                <rect id="Rectangle_58383" data-name="Rectangle 58383" width="24" height="24" rx="4" transform="translate(49 896)" fill="#fef4f4" />
                <g id="icons8-location_8_" data-name="icons8-location (8)" transform="translate(47 895.015)">
                    <path id="Path_101862" data-name="Path 101862" d="M19.046,16.883a7.6,7.6,0,1,0-10.894,0c.008.008.013.017.02.025l.025.025.06.06L13,21.738a.844.844,0,0,0,1.194,0h0l4.745-4.745.06-.06.025-.025C19.034,16.9,19.039,16.891,19.046,16.883Z" fill="#b3776d" />
                    <circle id="Ellipse_130999" data-name="Ellipse 130999" cx="3.377" cy="3.377" r="3.377" transform="translate(10.222 8.213)" fill="#f9f9f9" />
                </g>
            </g>
        </svg>

    )
}