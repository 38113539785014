import React from "react";
import { withBottombar } from "../../HOCs";
import { BackupAndRestoreError } from "./backupAndrestoreError";

class BackupAndRestoreErrorParent extends React.Component {
    render() {
        return <BackupAndRestoreError {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(BackupAndRestoreErrorParent, props);