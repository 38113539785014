import { useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Container,
  Dialog,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  AlertDialog,
  DrawerComponent,
  LoderSimmer,
} from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  GET_PROPERTY_AMENITIES,
  GET_UNIT_DETAILS_BY_UNIT_ID,
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  FontFamilySwitch,
  generateImageUrl,
  img_size,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AmenitiesCard } from "./component/amenitiesDetailsCard";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { Routes } from "../../router/routes";
import InfiniteScroll from "react-infinite-scroll-component";
import { PropertyDetails } from "../amenityBookingNew/components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  topDrap: {
    backgroundColor: "white",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1px 15px 1px 15px",
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
  toptitle: {
    color: "#091B29",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  rationame: {
    "&.MuiFormControlLabel-label": {
      color: "#091B29",
      fontSize: "0.9375rem",
      fontFamily: FontFamilySwitch().bold,
    },
  },
  tabroot: {
    display: "flex",
    alignItems: "center",
    padding: "4px 16px",
    backgroundColor: "white",
    boxShadow: "0px -1px 6px #00000021",
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "0px",
    minHeight: "auto",
    borderRadius: "4px",
    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "4px",
    },
    minWidth: "auto",
    marginRight: "8px",
    backgroundColor: "white",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "4px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },

  title: {
    border: "1px solid /serviceE4E8EE",
    padding: "8px 14px",
    borderRadius: "4px",
    background: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "50px",
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  card: {
    padding: "16px 16px 9px 16px",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    marginInlineStart: "12px",
    marginTop: "-8px",
  },
  address: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary,
    marginLeft: "12px",
    marginTop: "4px",
  },
  image: {
    objectFit: "cover",
    width: "64px",
    borderRadius: theme.palette.borderRadius,
    height: "60px",
    border: "1px solid #5C86CB2E",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  subHead: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
  },
  invoice: {
    backgroundColor: "white",
    padding: "14px",
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    margin: "20px 18px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 16px 16px 20px",
    },
  },
  invoice_2: {
    backgroundColor: "white",
    padding: "14px",
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    marginBottom: "12px",
  },

  paddingDiv: {
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 16px 20px",
    },
  },
  marginGrid: {
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 16px 16px 20px",
    },
  },
  marginGrid1: {
    margin: "20px 18px 20px 20px",
    [theme.breakpoints.down("sm")]: {
      margin: "16px 16px 16px 20px",
    },
  },
  header: {
    fontSize: "0.75rem",
    color: theme.palette.secondary,
    marginTop: "12px",
    marginBottom: "12px",
    fontFamily: FontFamilySwitch().bold,
  },

  noData: {
    fontSize: "0.875rem",
    // color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: "white",
    paddingTop: "5px",
    paddingBottom: "5px",
    borderRadius: "4px",
  },
  amenitiesSkeleton: {
    backgroundColor: "white",
    marginBottom: "12px"
  },
}));

const AmenitiesDetails = (props) => {
  const { t = () => false, handleTopBarState } = props;
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const size = useWindowDimensions();
  const selectedTab = state?.tab;
  // eslint-disable-next-line
  const [value, setValue] = React.useState(selectedTab ?? "1");
  const [propertyAmenities, setPropertyAmenities] = React.useState({
    propertyList: null,
    assets: null,
    loading: true,
  });
  const [unitAmenities, setUnitAmenities] = React.useState({
    unitList: null,
    units: [],
    selected: null,
    loading: true,
  });
  // eslint-disable-next-line
  const [unitDetails, setUnitDetails] = React.useState();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [invoiceList, setInvoiceList] = React.useState([]);
  const [offset, setOffSet] = React.useState(0)
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(true);
  const [amenityLoading, setAmenityLoading] = React.useState(true);
  const [avalaibleAmenity, setAvailableAmenity] = React.useState([]);
  const [unitPopup, setUnitPopup] = React.useState(false)
  const [unitDetail, setUnitDetail] = React.useState({
    count: 0,
    unitData: []
  })
  const [selectedAmenity, setSelectedAmenity] = React.useState({})
  const [unitoffset, setUnitOffSet] = React.useState(0)
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const client = useApolloClient();
  const propertyId = state?.propertyId;
  const agreementId = state?.agreementId;
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const closerDrawer = () => {
    setOpen(!open);
  };

  //property amenities
  const PropertyAmenities = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const data = {
      property_id: propertyId,
    };
    NetworkCall(
      `${config.api_url}/amenities_booking_v2/amenities_invoices_list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setInvoiceList(response?.data?.data?.result);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setLoading(false);
      });
    client
      .query({
        query: GET_PROPERTY_AMENITIES(),
        fetchPolicy: "network-only",
        variables: {
          property_id: propertyId,
          offset: 0,
          limit: 10,
          client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id,
        },
      })
      .then((res) => {
        setPropertyAmenities({
          propertyList: res?.data,
          assets: res?.data?.assets,
          loading: false,
        });
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  //units amenities
  const getUnitids = () => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let agreement_units = response.data?.data
          .map((item) => item.agreement_unit)
          .flat(Infinity)
          .filter((i) => i.property_id === propertyId);
        setUnitAmenities({
          units: agreement_units,
          selected: {
            label: agreement_units?.[0]?.unit_no,
            value: agreement_units?.[0]?.unit_id,
          },
          loading: false,
        });
        unitDetailsApi(agreement_units?.[0]?.unit_id, agreement_units?.[0]?.id);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  //unit details

  const unitDetailsApi = (id, agreementId) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    client
      .query({
        query: GET_UNIT_DETAILS_BY_UNIT_ID(),
        fetchPolicy: "network-only",
        variables: {
          unitId: id,
          agreement_unit_id: agreementId,
          client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id,
        },
      })
      .then((res) => {
        setUnitDetails(res?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  // AMENITIES AVAILABILITY

  const getAvailableAmenity = (offset, bool) => {
    const payload = {
      property_id: propertyId,
      selected_date: moment(new Date()).format('YYYY-MM-DD'),
      offset: offset,
      limit: 10
    };
    NetworkCall(
      `${config?.api_url}/amenities_booking_v2/amenities_with_avails`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (bool) {
          setAvailableAmenity(avalaibleAmenity?.concat(response?.data?.data?.amenityByDate));
        }
        else {
          setAvailableAmenity(response?.data?.data?.amenityByDate);
        }
        setAmenityLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setAmenityLoading(false);
      });
  };

  const fetchMoreData = () => {
    setOffSet(offset + 20);
    getAvailableAmenity(offset + 20, true);
  };

  React.useEffect(() => {
    handleTopBarState({
      text: t("Amenities")
    })
    if (value === "1") {
      PropertyAmenities();
      getAvailableAmenity(0, false);
    }
    if (value === "2") {
      getUnitids();
    }
    //eslint-disable-next-line
  }, [value]);
  const SwitchUnit = (val) => {
    setUnitAmenities({
      ...unitAmenities,
      selected: {
        label: val?.unit_no,
        value: val?.unit_id,
      },
      loading: false,
    });
    unitDetailsApi(val?.unit_id, val?.id);
    // backdrop.setBackDrop({
    //     ...backdrop,
    //     open: true,
    //     message: "Switching unit",
    // });
    setOpen(false);
  };

  const selectCard = (unitData, amenityData = selectedAmenity) => {
    history.push({
      pathname: Routes.amenitiesBookingsDetails,
      state: {
        data: amenityData ?? selectedAmenity,
        selectedProperty: state?.selectedProperty,
        // property_images: property_images,
        type: "direct",
        from: "amenities",
        amenity_data: [{
          symbol: selectedAmenity?.symbol,
          rate: selectedAmenity?.amount,
          category: selectedAmenity?.amenity_category,
          amenities_name: selectedAmenity?.amenities_name,
          period: selectedAmenity?.period,
          facility_id: selectedAmenity?.facility_id,
          assets: selectedAmenity?.amenity_assets,
          participants_count: selectedAmenity?.participants_count
        }],
        unitData: {
          agreement_unit_id: unitData?.agreement_unit_id,
          unit_name: unitData?.unit_name,
          unit_no: unitData?.unit_no,
          unit_id: unitData?.unit_id
        },
        selectedDate: new Date()
      },
    });
  }

  // Get unit agaist property
  const getAgreementUnitids = (amenityData, offset, bool) => {
    const data = {
      property_id: propertyId,
      limit: 5,
      offset: offset
    };
    NetworkCall(
      `${config.api_url}/amenities_booking_v2/get_agreementunits_and_units`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (bool) {
          setUnitDetail({
            count: response?.data?.data?.count,
            unitData: unitDetail?.unitData?.concat(response?.data?.data?.agreementUnits)
          })
        } else {
          if (response?.data?.data?.agreementUnits?.length === 1) {
            selectCard(response?.data?.data?.agreementUnits, amenityData)
          } else {
            setUnitDetail({
              count: response?.data?.data?.count,
              unitData: response?.data?.data?.agreementUnits
            })
          }
        }

        setSelectedAmenity(amenityData)
        setUnitPopup(true)

      })
      .catch((err) => {
        console.log(err)
        setUnitPopup(false)
      });
  };

  const fetchMoreDataforUnit = () => {
    setUnitOffSet(unitoffset + 5);
    getAgreementUnitids(selectedAmenity, unitoffset + 5, true);
  };


  return (
    <Container
      className={classes.root}
      maxWidth="sm"
      style={{ height: size?.height }}
    >
      {/* <TitleBar
        text={t("Amenities Booking")}
        goBack={() =>
          selectedTab
            ? history.push(Routes.amenities + "?Id=" + agreementId)
            : history.goBack()
        }
      /> */}
      <Grid container>
        {value === "1" && (
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" className={classes.card}>
              <Box>
                {propertyAmenities?.propertyList?.property?.[0]?.logo ? (
                  <Avatar
                    src={generateImageUrl(propertyAmenities?.propertyList?.property?.[0]?.logo, img_size.small_square)}
                    alt=""
                    className={classes.image}
                  />
                ) : (
                  <Avatar className={classes.image}></Avatar>
                )}
              </Box>
              <Box flexGrow={1}>
                <Stack direction="column">
                  <Typography className={classes.name}>
                    {propertyAmenities?.propertyList?.property?.[0]?.name ??
                      "-"}
                  </Typography>
                  {/* <Typography className={classes.address}>
                    Pallavaram, Chennai
                  </Typography> */}
                </Stack>
              </Box>
            </Box>
          </Grid>
        )}
        {/* {value === "2" && (
          <>
            <Grid item xs={12} padding="16px" bgcolor={"white"}>
              <Box className={classes.topDrap} onClick={closerDrawer}>
                <Box>
                  <Typography className={classes.toptitle}>
                    {unitAmenities?.selected?.label
                      ? unitAmenities?.selected?.label
                      : t("nounitsfound")}
                  </Typography>
                </Box>
                <Box>
                  <ExpandMoreIcon
                    style={{ color: "#091B29", marginTop: "5px" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" className={classes.card}>
                <Box>
                  {unitDetails?.unit?.[0]?.logo ? (
                    <Avatar
                      src={unitDetails?.unit?.[0]?.logo}
                      alt=""
                      className={classes.image}
                    />
                  ) : (
                    <Avatar className={classes.image}></Avatar>
                  )}
                </Box>
                <Box flexGrow={1}>
                  <Typography
                    className={classes.name}
                    marginTop={"0px !important"}
                  >
                    {unitDetails?.unit?.[0]?.unit_no ?? "-"}
                  </Typography>
                  <Box
                    display={"flex"}
                    className={classes.name}
                    marginTop={"0px !important"}
                  >
                    <Typography className={classes.subHead}>
                      {unitDetails?.unit?.[0]?.property?.name ?? "-"}
                    </Typography>
                    <Box className={classes.dot} />
                    <Typography className={classes.subHead}>
                      {unitDetails?.unit?.[0]?.block?.name ?? "-"}
                    </Typography>
                    <Box className={classes.dot} />
                    <Typography className={classes.subHead}>
                      {unitDetails?.unit?.[0]?.floor?.name ?? "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        )} */}
      </Grid>

      {value === "1" && (
        <div
          style={{
            height: size?.height - 55,
            overflow: "auto",
          }}
        >
          {propertyAmenities?.loading ? (
            <LoderSimmer count={10} />
          ) : (
            <>
              <Grid conatiner className={classes.marginGrid}>
                {/* {loading ? (
                  <>
                    <div className={classes.invoice_2}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <Skeleton
                            variant="rounded"
                            width={"100%"}
                            height={120}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Skeleton
                            variant="rounded"
                            width={"100%"}
                            height={120}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Skeleton
                            variant="rounded"
                            width={"100%"}
                            height={120}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  <>
                    {invoiceList > 0 ? (
                      <>
                        <Box className={classes.invoiceTitleBlock}>
                          <Typography className={classes.title}>
                            {props.t("INVOICE")}
                          </Typography>
                        </Box>
                        <div className={classes.invoice_2}>
                          <CardList
                            main={{
                              invoices: invoiceList,
                            }}
                            primary={true}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Typography className={classes.header}>
                          {t("INVOICE")}
                        </Typography>
                        <Typography
                          className={classes.noData}
                          textAlign="center"
                        >
                          {t("No Records Found")}
                        </Typography>
                      </>
                    )}
                  </>
                )} */}
                <Typography className={classes.header}>
                  {t("AVAILABLE AMENITIES")}
                </Typography>
                {amenityLoading ? (
                  <>
                    {[1, 2, 3, 4, 5].map(() => (
                      <Box p={2} className={classes.amenitiesSkeleton}>
                        <Stack direction={"row"} spacing={2}>
                          <Skeleton width={50} height={50} variant="rounded" />
                          <Box flexGrow={1}>
                            <Skeleton variant="text" width={80} />
                            <Skeleton variant="text" width={80} />
                          </Box>
                          <Box>
                            <Skeleton variant="text" width={30} />
                            <Skeleton variant="text" width={30} />
                          </Box>
                        </Stack>
                      </Box>
                    ))}
                  </>
                ) : (
                  <>
                    {avalaibleAmenity?.length > 0 ? (
                      <InfiniteScroll
                        dataLength={avalaibleAmenity.length}
                        next={fetchMoreData}
                        hasMore={true}>
                        {avalaibleAmenity?.map((item) => {
                          return (
                            <>
                              <Grid item xs={12}>
                                <AmenitiesCard
                                  onClick={() => {
                                    getAgreementUnitids(item, 0, false)
                                    // selectCard(item)
                                  }}
                                  data={item}
                                  type={"property"}
                                  companyId={
                                    propertyAmenities?.propertyList?.property?.[0]
                                      ?.companyByID?.id
                                  }
                                  propertyId={
                                    propertyAmenities?.propertyList?.property?.[0]
                                      ?.id
                                  }
                                  agreementId={agreementId}
                                />
                              </Grid>
                              <Box height={"8px"} />
                            </>
                          );
                        })}
                      </InfiniteScroll>
                    ) : (
                      <Typography textAlign={"center"} className={classes.noData}>
                        {t("No Amenities Confirgured Or Active")}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </div>
      )}
      {/* {value === "2" && (
        <div
          style={{
            height: size?.height - 270,
            overflow: "auto",
          }}
        >
          {unitDetails?.loading ? (
            <LoderSimmer count={10} />
          ) : (
            <>
              {unitDetails?.ameneties_usage.length > 0 && (
                <div className={classes.invoice}>
                  <Typography
                    style={{
                      color: "#071741",
                      fontSize: "0.875rem",
                      fontFamily: FontFamilySwitch().bold,
                    }}
                  >
                    {t("Invoice")}
                  </Typography>
                  <Box height="14px" />
                  <AmenitiesCardList data={unitDetails?.ameneties_usage?.[0]} />
                </div>
              )}
              <Grid conatiner spacing={3} className={classes.marginGrid1}>
                {unitDetails?.unit_amenities_breakup?.length > 0 ? (
                  unitDetails?.unit_amenities_breakup?.map((item) => {
                    return (
                      <>
                        <Grid item xs={12}>
                          <AmenitiesCard
                            data={item}
                            type={"unit"}
                            companyId={
                              unitDetails?.unit?.[0]?.property?.companyByID?.id
                            }
                            propertyId={unitDetails?.unit?.[0]?.property?.id}
                            agreementId={agreementId}
                          />
                          <br />
                        </Grid>
                      </>
                    );
                  })
                ) : (
                  <Typography className={classes.noData}>
                    {t("Datanotfound")}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </div>
      )} */}
      {/* drawer */}
      <Hidden smUp>
        <DrawerComponent
          onClose={closerDrawer}
          open={open}
          component={
            <div>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px",
                  borderBottom: "1px solid #E4E8EE",
                }}
              >
                <Box>
                  <Typography className={classes.title}>{t("Unit")}</Typography>
                </Box>
                <Box>
                  <IconButton size="small" onClick={closerDrawer}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <FormControl component="fieldset">
                <RadioGroup
                  name="radio-buttons-group"
                  style={{ padding: "8px" }}
                >
                  {unitAmenities?.units?.map((val) => {
                    return (
                      <>
                        <FormControlLabel
                          value={val?.unit_id}
                          control={
                            <Radio
                              checked={
                                val?.unit_id === unitAmenities?.selected?.value
                                  ? true
                                  : false
                              }
                            />
                          }
                          label={val?.unit_no}
                          onChange={() => SwitchUnit(val)}
                        />
                      </>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </div>
          }
        />
      </Hidden>

      {/* dialog */}
      <Hidden smDown>
        <AlertDialog
          onClose={closerDrawer}
          open={open}
          component={
            <div>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px",
                  borderBottom: "1px solid #E4E8EE",
                }}
              >
                <Box>
                  <Typography className={classes.title}>{t("Unit")}</Typography>
                </Box>
                <Box>
                  <IconButton size="small" onClick={closerDrawer}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <FormControl component="fieldset">
                <RadioGroup style={{ padding: "8px" }}>
                  {unitAmenities?.units?.map((val) => {
                    return (
                      <>
                        <FormControlLabel
                          value={val?.unit_id}
                          control={
                            <Radio
                              checked={
                                val?.unit_id === unitAmenities?.selected?.value
                                  ? true
                                  : false
                              }
                            />
                          }
                          label={val?.unit_no}
                          onChange={() => SwitchUnit(val)}
                        />
                      </>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </div>
          }
        />
      </Hidden>


      <Hidden smDown>
        <Dialog
          maxWidth="sm"
          className={classes.dialog}
          fullWidth
          open={unitPopup}
          onClose={() => {
            setUnitPopup(!unitPopup);
          }}
        >
          <Box
            display="flex"
            p={1}
            style={{ borderBottom: "1px solid #c1c1c1" }}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.modalTitle}>
                {t("Units")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setUnitPopup(!unitPopup);
                  setUnitOffSet(0)
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid xs={12} style={{ padding: "12px" }}>
            <Box sx={{ height: 300 }}>
              <InfiniteScroll
                dataLength={unitDetail?.count}
                next={fetchMoreDataforUnit}
                hasMore={true}
                height={300}
              >
                {
                  unitDetail?.unitData?.map((e) => {
                    return (
                      <PropertyDetails value={e} setPropertyDetail={selectCard} />
                    )
                  })
                }
              </InfiniteScroll>
            </Box>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          maxWidth="sm"
          fullWidth
          anchor="bottom"
          open={unitPopup}
          onClose={() => {
            setUnitPopup(!unitPopup);
          }}
        >
          <Box
            display="flex"
            p={1}
            style={{ borderBottom: "1px solid #c1c1c1" }}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.modalTitle}>
                {t("Units")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setUnitPopup(!unitPopup);
                  setUnitOffSet(0)
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Grid xs={12} style={{ padding: "12px" }}>
            <Box sx={{ height: 300 }}>
              <InfiniteScroll
                dataLength={unitDetail?.count}
                next={fetchMoreDataforUnit}
                hasMore={true}
                height={300}
              >
                {
                  unitDetail?.unitData?.map((e) => {
                    return (
                      <PropertyDetails value={e} setPropertyDetail={selectCard} />
                    )
                  })
                }
              </InfiniteScroll>
            </Box>
          </Grid>
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("amenitiesDetails")(AmenitiesDetails);
