
import AddonImg from "../../components/svg/addonImg"
export const addOnData = [
    {
        img: <AddonImg />,
        unit: "Unit One",
        location: "Unit location ( 1st Floor, A Block )",
        services: [
            {
                img: <AddonImg />,
                service: "First Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            }
        ]
    },
    {
        img: <AddonImg />,
        unit: "Unit Two",
        location: "Unit location ( 1st Floor, A Block )",
        services: [
            {
                img: <AddonImg />,
                service: "Second Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            }
        ]
    },
    {
        img: <AddonImg />,
        unit: "Unit Three",
        location: "Unit location ( 1st Floor, A Block )",
        services: [
            {
                img: <AddonImg />,
                service: "Thrid Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            },
            {
                img: <AddonImg />,
                service: "Laundry Service",
                Qty: "10 Qty / Month",
                amount: "2.00 AED",
                available: "05 Available"
            }
        ]
    }
]