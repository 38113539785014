import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EnterOtp } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, RetainLocalStorage } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%",
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "1.5rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
  },
  img: {
    height: "8%",
    width: "28%",
  },
  verificationText: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    color: theme.typography.color.primary,
    backgroundColor: theme.palette.background.secondary,
    borderRadius: "4px",
    padding: "20px",
  },
}));

const OtpPage = ({ t = () => false }) => {
  const history = useHistory();
  const classes = useStyles();
  const token = localStorage.getItem(LocalStorageKeys.authToken)
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [value, setValue] = React.useState("");

  const handleChange = (otp) => {
    setValue(otp);
  };

  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  const emailId = new URLSearchParams(search).get("data");
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);
  const verifyOtp = (e) => {
    e.preventDefault();
    const payload = {
      otp: value,
    };
    NetworkCall(
      `${config.auth_api_url}/auth/verifyOTP/?token=${token}`,
      NetWorkCallMethods.post,
      payload,
      { "Referer": "https://resident.pms2.propgoto.com/" },
      false,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(LocalStorageKeys.authToken, response.data.token);
          localStorage?.setItem?.(LocalStorageKeys?.user_id, jwt_decode(response?.data?.token)?.id);
          history.push({
            pathname: Routes.companyselect,
            state: response.data.user.clients
          });
        } else {
          RetainLocalStorage();
          history.push(Routes.login);
        }
      })
      .catch((err) => {
        RetainLocalStorage();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("InvalidOTP"),
        });
      });
  };
  return (
    <div>

      <Container maxWidth="sm" style={{ padding: 0 }}>
        <form onSubmit={verifyOtp}>
          <Grid container>
            <Grid
              item
              className={classes.content}
              style={{ height: size.height > 643 ? size.height - 94 : 549 }}
            >
              <Grid item xs={12} className={classes.backgroundImage} />
              <Grid style={{ padding: "0px 16px 0px 16px" }}>
                <Box height={"24px"} />
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent={"space-between"}
                  alignContent={"center"}
                >
                  {/* {logo?.logo_url && (
                    <img
                      src={logo?.logo_url}
                      alt="logo"
                      className={classes.img}
                    />
                  )} */}
                  <img
                    src="/images/PA Logo.svg"
                    alt="logo"
                    className={classes.img}
                  />
                </Grid>
                <Box height={"16px"} />
                <Grid item xs={12}>
                  <Typography className={classes.text}>{t("EnterOTP")}</Typography>
                </Grid>
                <Box height={"24px"} />
                <Grid item xs={12}>
                  <EnterOtp
                    t={t}
                    value={value}
                    handleChange={handleChange}
                    emailId={
                      decoded && decoded.email_id ? decoded.email_id : emailId
                    }
                  />
                </Grid>
                <Box height={"16px"} />
                <Grid item xs={12}>
                  <Typography className={classes.verificationText}>
                    {t("Verificationcodehasbeensenttoyouremailandmobilenumber")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btn}
                type='submit'
                // onClick={verifyOtp}
                disabled={value.length === 4 ? false : true}
              >
                <Typography className={classes.buttonTextStyle}>
                  {("Verify")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>

    </div>
  );
};
export default withNamespaces("welcomePage")(OtpPage)