import React from "react";
import FileManagerView from './fileManagerView';
import { withBottombar } from "../../HOCs";

class FileManagerViewParent extends React.Component {
    render() {
        return <FileManagerView {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(FileManagerViewParent, props);
