import React from "react";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { useStyles } from "./style";
import { AlertContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";

export const InventoryAndServicesContext = React.createContext()

const InventoryAndServicesProvider = (props) => {
    const { t = () => false } = props;
    const [unitDrawer, setUnitDrawer] = React.useState(false);
    const classes = useStyles();
    const [agreement, setAgreement] = React.useState([]);
    const [loading, setLoading] = React.useState(true)
    const initial_units_state = {
        unit: "",
        location: ""
    }
    const [data, setData] = React.useState(initial_units_state);
    const alert = React.useContext(AlertContext);
    const [inspectionList, setInspectionList] = React.useState({
        data: [],
        selectedUnit: false
    })
    const searchURL = useLocation().search;
    const unitId = new URLSearchParams(searchURL).get("unit_id");

    const updateState = (key, value) => {
        setData({ ...data, [key]: value })
    }
    const setUnit = (data) => {
        setUnitDrawer(false)
        updateState("unit", data)
        InspectionList(data?.id)
    }
    const InspectionList = (id) => {
        const payload =
        {
            agreement_unit_id: id
        }
        NetworkCall(
            `${config.api_url}/inspection_items/get_tenant_access`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setInspectionList({ data: response?.data?.data?.item })
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: ("SomethingWentwrong"),
                });
            });
    };

    const getAgreementInfo = () => {
        NetworkCall(
            `${config.api_url}/agreement-unit-info/switcher `,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        ).then((response) => {
            let unitList = []
            response?.data?.data?.map((_) => (
                // eslint-disable-next-line
                _?.agreement_unit?.map((e) => {
                    unitList = [...unitList, e]
                })
            ))
            if (unitId) {
                const filtered = unitList?.filter((i) => i?.unit_id === unitId)
                setData({ ...data, unit: filtered })
                InspectionList(filtered?.[0]?.id)
                updateState("unit", filtered?.[0])
            } else {
                InspectionList(unitList?.[0]?.id);
                updateState("unit", unitList?.[0])
            }

            setAgreement(unitList)

        });

    }
    React.useEffect(() => {
        getAgreementInfo()

        // eslint-disable-next-line
    }, []);

    return (
        <InventoryAndServicesContext.Provider
            value={{
                unitDrawer,
                setUnitDrawer,
                classes,
                agreement,
                updateState,
                data,
                setUnit,
                inspectionList,
                loading,
                t
            }}>
            {props?.children}
        </InventoryAndServicesContext.Provider>
    )
}


export default withNamespaces("inventory")(InventoryAndServicesProvider);