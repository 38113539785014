import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  completeInspection: {
    backgroundColor: "#F6F6F6",
  },
  container: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px",
  },
  completeTitle: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1rem",
    color: theme.typography.color.primary,
  },
  description: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.Tertiary,
    marginTop: "2px",
  },
  instext: {
    fontSize: "0.875rem",
    color: "#78B1FE",
    fontFamily: FontFamilySwitch().semiBold,
    marginInlineStart: "8px"
  },
  insBox: {
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
    cursor: "pointer",
  },
  image: {
    height: "350px",
    borderRadius: "8px",
    width: "100%",
  },
  propertyImage: {
    height: 60,
    width: 60,
    marginTop: 4,
    borderRadius: 4,
    position: "relative",
  },
  content: {
    width: "100%",
    marginInlineStart: "12px",
  },
  propertyId: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  propertyName: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  status_success: {
    fontSize: "0.75rem",
    padding: 4,
    borderRadius: 4,
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().bold,
  },
  inspector: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.Tertiary,
  },
  inspectorImage: {
    borderRadius: "50%",
  },
  userIcon: {
    backgroundColor: "#F1F7FF",
    borderRadius: theme.palette.borderRadius,
    width: "32px",
    height: "32px",
    textAlign: "center",
    marginInlineEnd: "16px"
  },
  userIcon2: {
    backgroundColor: "#eef9ee",
    borderRadius: theme.palette.borderRadius,
    width: "32px",
    height: "32px",
    textAlign: "center",
    marginInlineEnd: "16px"
  },
  date: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  type: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary,
  },
  arrowIcon: {
    color: "#78B1FE",
    marginTop: "3px",
  },
}));