import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
import { FontFamilySwitch, generateImageUrl, img_size, isExpired } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    padding: "12px"
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "2px 8px",
    borderRadius: "0px 0px 8px 8px",
  },
  expired: {
    fontSize: "0.75rem",
    color: "#000000",
    fontFamily: FontFamilySwitch().bold,
    background: "#F6F6F6",
    padding: "2px 8px",
    borderRadius: "0px 0px 8px 8px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "0px 0px 8px 8px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0px 0px 8px 8px",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
    borderRadius: "0px 0px 8px 8px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "70px",
    width: "70px",
    objectFit: "cover",
    borderRadius: "8px"
  },
  bold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem"
    },
  },
  semiBold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,

  },
  bottomText: {
    bottom: 0,
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  bottomText1: {
    position: "absolute",
    bottom: "0px",
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px"
  },
  bottomCard: {
    backgroundColor: "#fff",
    borderRadius: "0px 0px 8px 8px",
    padding: "10px 14px",
    margin: "auto 4px",
    borderTop: "1px solid #E4E8EE"
  }
}));

export const DomesticCard = (props) => {
  const { t=()=>false } = props
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();


  return (
    <Box sx={{ position: "relative" }}>
      <Stack direction={"row"} className={classes.card}
        onClick={() =>
          history.push(Routes.domesticDetails + "?passId=" + props?.data?.id)
        }
      >
        <Box style={{ position: "relative" }}>
          {props?.data?.vehicle_image ?
            <img src={generateImageUrl(props?.data?.vehicle_image, img_size.small_square)} alt="" className={classes.avatar} /> :
            <Avatar className={classes.avatar} src={
              props?.parking
                ? "/images/parkingslot.svg"
                : props?.data?.helper_image
            } variant="square" />
          }
          <Box style={{ position: "absolute" }} className={classes.bottomText1}>
            {isExpired(props?.data?.req_to, new Date().toISOString()) ?
              <Typography
                variant="subtitle2"
                noWrap
                className={classes.expired}
              >
                {t("Expired")}
              </Typography> :
              <>
                {props?.data?.request_status === "Pending" && (
                  <Typography
                    variant="subtitle2"
                    noWrap
                    className={classes.progress}
                  >
                    {t("In Progress")}
                  </Typography>
                )}
                {props?.data?.request_status === "Approved" && (
                  <Typography
                    variant="subtitle2"
                    className={classes.approved}
                  >
                    {t("Approved")}
                  </Typography>
                )}
                {props?.data?.request_status === "Rejected" && (
                  <Typography
                    variant="subtitle2"
                    className={classes.rejected}
                  >
                    {t("Rejected")}
                  </Typography>
                )}
                {props?.data?.request_status === "Cancelled" && (
                  <Typography variant="subtitle2" className={classes.delete}>
                    {t("Cancelled")}
                  </Typography>
                )}
              </>}
          </Box>
        </Box>
        <Stack sx={{ marginInlineStart: "16px" }} direction="column" spacing={"6px"}>
          <Typography noWrap className={classes.title} sx={{ maxWidth: size?.width - 195 }}>
            {props?.data?.name}
          </Typography>
          <Stack direction={"row"} alignItems="center"
            divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" flexItem />}
            sx={{ maxWidth: size?.width - 165 }}>
            <Typography noWrap className={classes.sub}>
              {props?.data?.profession}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.data?.mobile_country_code}{props?.data?.mobile_no}
            </Typography>
          </Stack>
          <Typography pt={"2px"}
            sx={{ maxWidth: size?.width - 165 }}
            variant="subtitle2"
            className={classes.sub}
            noWrap
          >
            {props?.data?.property_name}
          </Typography>
        </Stack>
      </Stack>
      <Stack className={classes.bottomCard} justifyContent={"space-around"} direction="row" alignItems="center" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
        <Stack direction="row" pt={"2px"}>
          <DateIcon />
          <Typography
            sx={{ marginInlineStart: "8px" }}
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            &#x202a;{moment(props?.data?.req_from)
              .tz(moment.tz.guess())
              .format("DD MMM YY")}&#x202c;
            &nbsp;-&nbsp;
            &#x202a;{moment(props?.data?.req_to)
              .tz(moment.tz.guess())
              .format("DD MMM YY ")}&#x202c;
          </Typography>
        </Stack>
        <Box display={"flex"} alignItems="center" gap={"8px"}>
          <img src="/images/unitss.svg" alt="units" />
          <Typography
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            {props?.data?.unit_name}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
