import * as React from "react"

const PassUnits = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 112334">
      <path
        data-name="icons8-real-estate (4)"
        d="M12.692 3a.691.691 0 0 0-.424.145l-6.08 4.707a3.485 3.485 0 0 0-1.342 2.738v1.536l1.385-1.1v-.441a2.09 2.09 0 0 1 .8-1.642l5.657-4.379 5.657 4.379a2.091 2.091 0 0 1 .8 1.642v9.026h-5.53A2.022 2.022 0 0 1 13.154 21h6.231a1.155 1.155 0 0 0 1.154-1.154v-9.257A3.485 3.485 0 0 0 19.2 7.852l-6.08-4.706A.691.691 0 0 0 12.692 3Zm-5.1 8.518a.692.692 0 0 0-.406.149l-3.4 2.675A2.079 2.079 0 0 0 3 15.973v3.874A1.164 1.164 0 0 0 4.154 21h6.923a1.164 1.164 0 0 0 1.154-1.154v-3.873a2.077 2.077 0 0 0-.791-1.632l-3.4-2.675a.692.692 0 0 0-.451-.149Zm.023 1.574 2.968 2.337a.691.691 0 0 1 .263.544v3.643H4.385v-3.643a.69.69 0 0 1 .264-.544Z"
        fill="#896db3"
        stroke="#896db3"
        strokeWidth={0.5}
      />
    </g>
  </svg>
)

export default PassUnits
