import React from "react";
import  Amenities  from "./amenitiesList";
import { withBottombar } from "../../HOCs";

class AmenitiesParent extends React.Component {
    render() {
        return <Amenities {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }
  

export default withBottombar(AmenitiesParent, props);
