import { Button, Grid } from "@mui/material"
import React from "react";
import { withBottombar } from "../../HOCs"
import Step1 from "./step1";
import Step2 from "./step2";
import { AddVisitorPassContext } from "./addVisitorPassContext";


const AddVisitorPass = ({ handleTopBarState }) => {

    const {
        t = () => false,
        CreateVisitor,
        nextStep,
        step,
        setStep,
        size,
        classes,
        isDisableBtn
    } = React.useContext(AddVisitorPassContext);


    React.useEffect(() => {
        handleTopBarState({
            text: t("Add Visitor Pass")
        })
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div style={{ height: size?.height - 135, overflow: "scroll" }}>
                {step === 0 && <Step1 />}
                {step === 1 && <Step2 />}
            </div>
            {step === 0 ?
                <Grid
                    container
                    className={classes.btnroot}
                    justifyContent="space-around"
                    spacing={0}
                >
                    <Grid item xs={12}>
                        <Button
                            className={classes.primary}
                            fullWidth
                            onClick={() => nextStep()}
                            variant="contained">
                            {t("Next")}
                        </Button>
                    </Grid>
                </Grid> :
                <Grid
                    container
                    className={classes.btnroot}
                    justifyContent="space-around"
                    spacing={1}
                >
                    <Grid item xs={6}>
                        <Button
                            className={classes.secondary}
                            fullWidth
                            onClick={() => setStep(0)}
                            variant="outlined">
                            {t("Previous")}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            className={classes.primary}
                            fullWidth
                            variant="contained"
                            onClick={() => CreateVisitor()}
                            disabled={isDisableBtn}
                        >
                            {t("Submit")}
                        </Button>
                    </Grid>
                </Grid>
            }

        </>
    )
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(AddVisitorPass, props)