import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";
import { DialogDrawer } from "../dialogDrawer";
import { NoService } from "../noService";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  buttonStyle: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    // padding: "12px 16px",
    boxShadow: "0px 4px 16px #5C86CB2E",
    height: "99px",
    cursor: "pointer",
    display: "flex"
  },
  lableStyle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
  },
  submitBtn: {
    borderRadius: "4px",
    padding: "12px 8px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  sideBorder: {
    backgroundColor: (props) => props?.data?.map(item => item.borderColor),
    margin: " 3px 0px",
    borderRadius: "0px 10px 10px 0px",
    border: (props) => `3px solid ${props?.data?.map(item => item.borderColor)}`
  },
  img: {
    backgroundColor: "white",
    padding: "8px",
    borderRadius: "4px"

  }
}));
const InformationAndServiceButton = (props) => {
  const { t=()=>false } = props;
  const classes = useStyles();
  const history = useHistory();
  const [open, setopen] = React.useState(false);

  return (
    <>
      <Grid container spacing={1}>
        {props?.data?.map((item) => {
          return (
            <>
              <Grid
                item
                xs={4}
                spacing={1}
                onClick={() => {
                  if (item?.noavailable) {
                    setopen(true);
                  } else if (item?.link === "/vehicle") {
                    history.push(
                      {
                        pathname: item.link,
                        state: {
                          main: {
                            agreement: item.data
                          }
                        }
                      }
                    )
                  } else if (item?.link) {
                    history.push(item?.link + "?Id=" + item?.data);
                  } else {

                  }
                }}
              >
                <div className={classes.buttonStyle} style={{ background: item?.color ? item?.color : "" }} >
                  <Box
                    style={{
                      backgroundColor: item.borderColor,
                      margin: "3px 0px",
                      borderRadius: "0px 10px 10px 0px",
                      border: `3px solid ${item.borderColor}`
                    }}
                  />
                  <div style={{ flex: "auto", padding: "12px 16px" }}>
                    <center>
                      <Box height="4px" />
                      <img src={generateImageUrl(item.icon, img_size.small_square)} alt="" className={item?.color ? classes.img : null} />
                      <Box height="8px" />
                      <Typography className={classes.lableStyle}>
                        {item.lable}
                      </Typography>{item.sub &&
                        <Typography noWrap className={classes.lableStyle}>
                          {item.sub}
                        </Typography>
                      }
                      <Box height="6px" />
                    </center>
                  </div>
                </div>
              </Grid>
            </>
          );
        })}
      </Grid>
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={open}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              mobile
              fullWidth
              onClose={() => setopen(false)}

            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              fullWidth
              onClose={() => setopen(false)}

            />
          </>
        }
        onClose={() => setopen(false)} />

    </>
  );
};

export default withNamespaces("dashboard")(InformationAndServiceButton)
