import React from "react"

export const LocationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="Group_117626" data-name="Group 117626" transform="translate(-5663 5150)">
                <g id="Rectangle_58530" data-name="Rectangle 58530" transform="translate(5663 -5150)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="icons8-location_10_" data-name="icons8-location (10)" d="M12.95,4a5.946,5.946,0,0,0-4.517,9.817c.106.12,2.6,2.954,3.432,3.748a1.57,1.57,0,0,0,2.17,0c.968-.924,3.333-3.635,3.437-3.754A5.946,5.946,0,0,0,12.95,4Zm0,7.7A1.75,1.75,0,1,1,14.7,9.95,1.75,1.75,0,0,1,12.95,11.7Z" transform="translate(5657 -5154)" fill="#98a0ac" />
            </g>
        </svg>

    )
}