import * as React from "react"

const CitySvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14.7} height={14} {...props}>
    <path
      d="M5.308-.002A1.75 1.75 0 0 0 3.5 1.749v5.949h1.225a2.628 2.628 0 0 1 2.625 2.625v3.15a1.213 1.213 0 0 1-.123.525h3.8a.525.525 0 0 0 .525-.525V2.974a1.752 1.752 0 0 0-1.295-1.69L5.705.059a1.723 1.723 0 0 0-.397-.061Zm.642 3.15h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35Zm2.45 0h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35Zm-2.45 2.45h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35Zm2.45 0h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35Zm3.85 0v2.45h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7v1.05h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7v2.1h1.925a.525.525 0 0 0 .525-.525v-5.95a1.931 1.931 0 0 0-1.925-1.925ZM8.4 8.049h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35Zm-6.475.349A1.931 1.931 0 0 0 0 10.324v3.15a.525.525 0 0 0 .525.524h5.6a.525.525 0 0 0 .525-.525v-3.15a1.931 1.931 0 0 0-1.925-1.925Zm6.475 2.1h.7a.344.344 0 0 1 .273.14h.008v.011a.34.34 0 0 1 .07.2v.7a.341.341 0 0 1-.07.2v.011h-.008a.344.344 0 0 1-.273.139h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.351Zm-6.65 0h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35Zm2.45 0h.7a.35.35 0 0 1 .35.35v.7a.35.35 0 0 1-.35.35h-.7a.35.35 0 0 1-.35-.35v-.7a.35.35 0 0 1 .35-.35Z"
      fill="#78b1fe"
    />
  </svg>
)

export default CitySvg