import { Box, Container } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { withBottombar } from "../../HOCs";
import CompleteInspection from "../../components/inspectionCard/component/completeInspection";
import { InspectionUnitCompletedSimmer } from "../../components/simmer/inspectionUnitCompletedSimmer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";

const InspectionUnitCompleted = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const alert = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(true);
  const [inspectionDetails, setInspectionDetails] = React.useState({});
  const [inspectionReportDetails, setInspectionReportDetails] = React.useState([]);
 const [pdfLoading,setPdfLoading]=React.useState(false)

  if (!state) {
    history.replace(Routes?.inspectionOrder);
  }
  const value = state?.value;
  React.useEffect(() => {
    
    setLoading(true);
    getInspectionReport();
    const data = {
      agreement_inspection_id: value?.id,
      unit_id: value?.unit_id,
      agreement_id: value?.agreement_id,
      resource_id: value?.resource_id,
    };
    NetworkCall(
      `${config?.api_url}/move-in-out/get_preview_details`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setInspectionDetails(response?.data?.data)
        handleTopBarState({
          text: response?.data?.data?.property_unit_details?.unit_name
        })
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong"),
        });
      });
    // eslint-disable-next-line
  }, []);
  const getInspectionReport = () => {
setPdfLoading(true)
    const payload = {
        "agreement_inspection_id": value?.id
    }
    NetworkCall(
        (value?.request_type!=="move-in"&&value?.request_type !=="move-out")?`${config.api_url}/general_inspection/template`:`${config.api_url}/move-in-out/template`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
    )
        .then((response) => {
          setInspectionReportDetails(response?.data)
          setPdfLoading(false)
        })
        .catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Some thing went wrong"),
            });
        });
};

  return (
    <>
      <Container maxWidth="sm" className={classes.root}>
        {/* <TitleBar
          text={inspectionDetails?.property_unit_details?.unit_name}
          goBack={() => history.goBack()}
        /> */}
        <Box
          height={size?.height - 55}
          sx={{ backgroundColor: "#f6f6f6", overflow: "overlay" }}
        >
          {loading ? (
            <InspectionUnitCompletedSimmer />
          ) : (
            <CompleteInspection t={t} data={inspectionDetails} inspectionReportDetails={inspectionReportDetails} tenant={value} loading={pdfLoading}/>
          )}
        </Box>
      </Container>
    </>
  );
};

const props = {
  top_bar: "title_bar",
}


export default withBottombar(withNamespaces("inspection")(InspectionUnitCompleted), props);