import * as React from "react"
export const Micon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g fill="#f2f4f7" stroke="#e4e8ee" data-name="Ellipse 131002">
      <circle cx={24} cy={24} r={24} stroke="none" />
      <circle cx={24} cy={24} r={23.5} fill="none" />
    </g>
    <path
      fill="#091b29"
      d="M23.667 14a4.01 4.01 0 0 0-4 4v6.667a4 4 0 1 0 8 0V18a4.01 4.01 0 0 0-4-4Zm-6.01 8.435a.667.667 0 0 0-.657.676v1.556a6.678 6.678 0 0 0 6 6.633v2.034a.667.667 0 1 0 1.333 0V31.3a6.678 6.678 0 0 0 6-6.633v-1.556a.667.667 0 1 0-1.333 0v1.556A5.32 5.32 0 0 1 23.746 30a.63.63 0 0 0-.162 0 5.32 5.32 0 0 1-5.25-5.329v-1.56a.667.667 0 0 0-.677-.676Z"
    />
  </svg>
)
