import { Divider, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FontFamilySwitch } from "../../utils";
import { DocumentViewer } from "../pdfviewer";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius:(props)=>props?.borderRadius?? theme.palette.borderRadius,
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.background.tertiary1,
  },
  Cardcontent: {
    padding: "8px 16px",
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  download: {},
  pdfContent: {
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border.secondary}`,
    overflow: "hidden",
    backgroundColor: theme.palette.background.tertiary1,
    display: "flex",
    objectFit: "cover",
  },
  pdfImg: { width: "100%", height: "155px" },
  body: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  subBody: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,

  },
  divider: {
    margin: "12px 0px",
  },
  pdfView: {
    width: "100%",
    height: "200px",
    // overflow: "auto"
  }
}));

export const Quotation = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <Grid container padding="8px 18px 18px 18px">
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{marginBottom:"4px"}}
          >
            <Typography className={classes.title}>
              {props.data.title}
            </Typography>
            <div className={classes.download}>
              <IconButton onClick={props.onClickDownload}>
                <img src="/images/download.svg" alt="download" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.pdfContent}>
              {
                props?.url ?
                  <div className={classes.pdfView}>
                    {/* <center> */}
                    <DocumentViewer url={props?.url} />
                    {/* </center> */}
                  </div>
                  :
                  <img
                    className={classes.pdfImg}
                    src="/images/Quotation1.png"
                    alt="quotationImage"
                  />
              }

            </div>
          </Grid>
          {!props?.data?.agreement  && 

          <Grid xs={12} className={classes.divider}>
            <Divider />
          </Grid>
          }
          {!props.data.body && !props?.data?.agreement && (
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.subBody}>
                  {props.data.subBody}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.subBody}
                  style={{ textAlign: "right" }}
                >
                  {props?.data?.symbol + " " + Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props.data.subBody2))}
                </Typography>
              </Grid>
            </Grid>
          )}
          {props.data.body && (
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography className={classes.body}>
                  {props.data.body}
                </Typography>
                <Typography className={classes.subBody}>
                  {props.data.body1}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography className={classes.body}>
                  {props.data.subBody}
                </Typography>
                <Typography className={classes.subBody}>
                  {props?.data?.symbol + " " + Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props.data.subBody2))}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
