import React from "react";

function RecordExpenseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="0.177"
          cy="0.274"
          r="0.722"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0"></stop>
          <stop offset="1" stopOpacity="0"></stop>
        </radialGradient>
        <linearGradient
          id="linear-gradient"
          x1="0.157"
          x2="0.853"
          y1="0.157"
          y2="0.853"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fede00"></stop>
          <stop offset="1" stopColor="#ffd000"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 107106" transform="translate(-40.25 -343)">
        <rect
          width="40"
          height="40"
          fill="#fff9e7"
          data-name="Rectangle 55698"
          rx="6"
          transform="translate(40.25 343)"
        ></rect>
        <g data-name="Group 109104" transform="translate(-.75 -602)">
          <g transform="translate(47 952)">
            <circle
              cx="4.524"
              cy="4.524"
              r="4.524"
              fill="#c9d4e0"
              data-name="Ellipse 129638"
              transform="translate(7.333 13.952)"
            ></circle>
            <circle
              cx="3.571"
              cy="3.571"
              r="3.571"
              fill="#b2bbc7"
              data-name="Ellipse 129639"
              transform="translate(8.286 14.905)"
            ></circle>
            <path
              fill="#c9d4e0"
              d="M18.116 35.455v-.68a1.484 1.484 0 00.789.227.741.741 0 00.493-.15.5.5 0 00.178-.407q0-.537-.759-.537a4.8 4.8 0 00-.6.045v-2.019h2v.65H18.9v.723q.159-.015.3-.015a1.233 1.233 0 01.872.292 1.022 1.022 0 01.315.787 1.161 1.161 0 01-.376.893 1.441 1.441 0 01-1.018.347 2.185 2.185 0 01-.877-.156z"
              data-name="Path 100037"
              transform="translate(-7.394 -15.156)"
            ></path>
            <path
              d="M11.046 30.091a8.506 8.506 0 003.466 1.109 7.6 7.6 0 004.417 2.325 4.521 4.521 0 10-7.882-3.432z"
              data-name="Path 100038"
              opacity="0.05"
              transform="translate(-3.691 -12.048)"
            ></path>
            <path
              d="M11.266 29.6a7.979 7.979 0 003.581 1.137 7.069 7.069 0 004.506 2.347 4.325 4.325 0 00.834-2.556 4.507 4.507 0 00-8.921-.928z"
              data-name="Path 100039"
              opacity="0.07"
              transform="translate(-3.806 -12.048)"
            ></path>
            <circle
              cx="6.667"
              cy="6.667"
              r="6.667"
              fill="#ff8f6b"
              data-name="Ellipse 129640"
              transform="translate(9.714 7.286)"
            ></circle>
            <circle
              cx="5.714"
              cy="5.714"
              r="5.714"
              fill="#ed6c47"
              data-name="Ellipse 129641"
              transform="translate(10.667 8.238)"
            ></circle>
            <path
              fill="#f5be00"
              d="M25.927 26.349h-2.8v-.773h.919v-2.761l-.943.2v-.791l1.917-.387v3.735h.912z"
              data-name="Path 100040"
              transform="translate(-10.003 -9.869)"
            ></path>
            <path
              fill="#ff8f6b"
              d="M31.732 26.425q-1.584 0-1.583-2.223a3.028 3.028 0 01.428-1.758 1.428 1.428 0 011.241-.6q1.546 0 1.546 2.26a2.979 2.979 0 01-.422 1.726 1.39 1.39 0 01-1.21.595zm.043-3.84q-.634 0-.634 1.592 0 1.5.621 1.5t.606-1.546q.001-1.547-.592-1.546z"
              data-name="Path 100041"
              transform="translate(-13.697 -9.868)"
            ></path>
            <path
              fill="url(#radial-gradient)"
              d="M16 18.667a6.651 6.651 0 002.51 5.208 8.543 8.543 0 007.632-10.889A6.657 6.657 0 0016 18.667z"
              data-name="Path 100042"
              transform="translate(-6.286 -4.714)"
            ></path>
            <circle
              cx="7.619"
              cy="7.619"
              r="7.619"
              fill="url(#linear-gradient)"
              data-name="Ellipse 129642"
              transform="translate(4 3)"
            ></circle>
            <circle
              cx="6.19"
              cy="6.19"
              r="6.19"
              fill="#f5be00"
              data-name="Ellipse 129643"
              transform="translate(5.429 4.429)"
            ></circle>
            <g data-name="Group 109101" transform="translate(9.918 7.55)">
              <path
                fill="#fee119"
                d="M16.427 18.432V17.3a2.481 2.481 0 001.317.379 1.238 1.238 0 00.822-.25.843.843 0 00.3-.68q0-.9-1.266-.9a7.949 7.949 0 00-1 .076v-3.37h3.34v1.085h-2.2v1.207q.265-.025.5-.025a2.059 2.059 0 011.455.488 1.706 1.706 0 01.526 1.313 1.938 1.938 0 01-.627 1.491 2.4 2.4 0 01-1.7.579 3.621 3.621 0 01-1.467-.261z"
                data-name="Path 100043"
                transform="translate(-16.427 -12.555)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RecordExpenseIcon;