import * as React from "react";
import {
  Box,
  Stack,
  Divider,
  Typography,
} from "@mui/material";
import RequestCalendar from "../../assets/requestCalendar";
import Apartment from "../../assets/apartment";
import { useStyles } from "./styles";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { generateImageUrl, img_size } from "../../utils";
import { format } from "date-fns";

const MaintenanceCard = (props) => {
  const { t = () => false } = props
  const language = localStorage.getItem("i18nextLng")
  const classes = useStyles();
  const { show = false } = props;
  const size = useWindowDimensions();
  const switchRequestStatusColor = (val) => {
    switch (val) {
      case "Open": {
        return "#78B1FE"
      }
      case "Closed": {
        return "#5AC782"
      }
      case "Cancelled": {
        return "#FF4B4B"
      }
      case "Assigned": {
        return "#ffd700"
      }
      case "Reopened": {
        return "#FF9340"
      }
      default: {
        return "#78B1FE"
      }
    }
  }
  return (
    <>
      <Stack direction={"row"} onClick={props?.onClick} sx={{ cursor: "pointer" }}>
        <Box className={classes.imageBox} style={{ position: "relative" }}>
          <img
            src={generateImageUrl(props?.image, img_size.small_square) ?? "/images/unitplaceholder.svg"}
            alt="Repair"
            class="center"
            className={classes.image}
          />
          <Box style={{ position: "absolute", bottom: "5px" }} className={classes.bottomText1}>

            <Typography
              variant="subtitle2"
              noWrap
              className={classes.progress}
              sx={{ backgroundColor: switchRequestStatusColor(props?.request_status) }}
            >
              {t(props?.request_status)}
            </Typography>
          </Box>
        </Box>
        <Stack direction={"column"} flexGrow={1}>
          <Box>
            <Typography className={classes.header} sx={{ maxWidth: size?.width - 155 }} noWrap>
              {props.header}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection="row">
            <Box flexGrow={1}>
              <Box
                display={"flex"}
                className={classes.line2}
                sx={{ maxWidth: size?.width - 195 }}
              >
                {/* <Typography className={classes.subHeader} noWrap>
                {props.category}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ margin: "0 8px" }} /> */}
                <Typography noWrap className={classes.subHeader}>{props.sub_category}</Typography>
              </Box>
              <Typography mt={"2px"} className={classes.subHeader} sx={{ maxWidth: size?.width - 195 }} noWrap>{props.request_type}</Typography>
              <Typography mt={"2px"} className={classes.subHeader} sx={{ maxWidth: size?.width - 195 }} noWrap>{props.status}</Typography>

            </Box>
            {/* <Box ml={2} alignSelf="center">
          <IconButton className={classes.icon}>
            <KeyboardArrowRightIcon
              fontSize="small"
              sx={{ color: "#071741", transform: "translateX(1px)" }}
              color="#071741"
            />
          </IconButton>
        </Box> */}
          </Box>
        </Stack>
      </Stack>
      {!show && (
        <Box>
          <hr color="#eaeef4" />
          <Stack
            spacing={1}
            justifyContent="space-around"
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Box
              display="flex"
              className={classes.date}
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ marginInlineEnd: "8px" }} mt={0.5}>
                <RequestCalendar />
              </Box>
              <Typography className={classes.date}>
                {/* &#x202a;{props.date}&#x202c; */}
                &#x202a;{format(new Date(props?.date), language === "en" ? "dd MMM yyyy" : "dd MMM yyyy")}&#x202c;
              </Typography>
            </Box>
            <Box
              display="flex"
              className={classes.date}
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ marginInlineEnd: "8px" }} mt={0.5}>
                <Apartment />
              </Box>
              <Typography className={classes.date}>
                {props.apartment}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default MaintenanceCard;
