export const Routes = {
  // home: "/",
  dashboard: "/dashboard",
  welcomePage: "/welcome",
  quotationDetails: "/quotation",
  oldQuotationDetails: "/oldquotationdetails",
  quotationPayment: "/quotationpayment",
  setupAccount: "/setupaccount",
  login: "/login",
  otpPage: "/otppage",
  resetpassword: "/resetpassword",
  agreement: "/agreement",
  requestScreen: "/requestscreen",
  profileView: "/profileView",
  units: "/units",
  invoiceScreen: "/invoicescreen",
  experied: "/experiedagreement",
  notification: "/notification",
  requestDetails: "/requestdetails",
  statementofaccount: "/statementofaccount",
  general: "/general",
  maintenanceParent: "/maintenance",
  profileEdit: "/profileedit",
  addAddress: "/addaddress",
  paymentHistory: "/paymenthistory",
  service: "/service",
  success: "/paymentstatus/success",
  failure: "/paymentstatus/failure",
  communityDiscussion: "/community_discussion",
  createDiscussion: "/create_discussion",
  discussions: "/discussions",
  contacts: "/contacts",
  contactsDetails: "/contactdetails",
  workerGatePass: "/worker_gate_pass",
  visitorGatePass: "/visitor_gate_pass",
  workerGatePassDetails: "/worker_gate_pass_details",
  visitorGatePassDetails: "/visitor_gate_pass_details",
  passDetails: "/pass_details",
  createPass: "/create_visitor",
  createWorker: "/create_worker",
  parkingPass: "/parking_pass",
  parkingDetails: "/parking_details",
  createParking: "/create_parking",
  delivery: "/delivery",
  deliveryDetails: "/delivery_details",
  createDelivery: "/create_delivery",
  domesticHelp: "/domestic_help",
  domesticDetails: "/domestic_details",
  domesticCreate: "/domestic_create",
  emergencyNumber: "/emergency_contact",
  emergencyNumberDetails: "/emergency_details",
  announcement: "/announcement",
  announcementDetails: "/announcement_details",
  myNeighbours: "/directory",
  myNeighboursDetails: "/neighbours_details",
  aggreementUnitAccess: "/aggreement_unit_access",
  createNew: "/create_new",
  aggreementUnitAccessDetails: "/aggreement_unit_access_details",
  entries: "/entries",
  swiftinout: "/entries-details",
  vehicle: "/vehicle",
  addVehicle: "/addVehicle",
  viewVehicle: "/viewVehicle",
  managment: '/managment',
  managementdetails: '/managementdetails',
  utilities: '/utilities',
  utilitiesdetails: '/utilitiesdetails',
  utilitiestype: '/utilitiestype',
  agreementparking: '/agreementparking',
  amenities: '/amenities',
  amenitiesdetails: '/amenitiesdetails',
  amenitiestype: '/amenitiestype',
  propertiesList: "/propertiesList",
  ownerslists: '/ownerslist',
  DatePickerTest: '/DatePickerTest',
  companyselect: '/companyselect',
  amenitiesBookingDetails: '/amenitiesBookingDetails',
  amenitiesBookingNew: "/amenitiesBookingNew",
  amenitiesBookingsDetails: "/amenitiesBookingsDetails",
  amenitiesdetailsNew: "/amenitiesdetailsNew",
  bookingConfirmation: "/bookingConfirmation",
  viewAmenities: '/viewAmenities',
  availableSlot: '/availableSlot',
  mybooking: '/mybooking',
  myBookingDetails: "/myBookingDetails",
  myBookingList: "/myBookingList",
  payment: '/payment',
  fileManager: '/fileManager',
  fileManagerView: "/fileManagerView",
  allAgreement: "/all-agreement",
  occupants: "/occupants",
  addOccupants: "/add_occupants",
  kycInformation: "/kycInformation",
  addIdProof: "/addIdProof",
  propertySearch: "/propertySearch",
  filteredPropertyList: "/filteredPropertyList",
  addOn: "/addOn",
  caseManagement: "/caseManagement",
  correspondanceType: "/correspondanceType",
  inspectionOrder: "/inspectionOrder",
  inspectionUnitCompleted: "/inspectionUnitCompleted",
  serviceRecordOrders: "/serviceRecordOrders",
  serviceUnitCompleted: "/serviceUnitCompleted",
  amenitiesdetailsOne: "/amenitiesdetailsOne",
  statistics: "/statistics",
  revenue: "/revenue",
  spendings: "/spendings",
  investment: "/investment",
  createSpending: "/createSpending",
  createInvestment: "/createInvestment",
  bookingHistory: "/bookingHistory",
  shortlistedUnits: "/shortlistedUnits",
  getSupport: "/getSupport",
  getSupportDetailed: "/getSupportDetailed",
  feedback: "/feedback",
  feedbackForm: "/feedbackForm",
  addVisitorPass: "/addVisitorPass",
  addWorkerPass: "/addWorkerPass",
  inventoryAndServices: "/inventoryAndServices",
  maintenancePreview: "/maintenancePreview",
  inspectionItemList: "/inspectionItemList",
  "#": "/#",
  backupAndRestoreError: "/backupAndRestoreError",
  BOMDetails: "/BOMDetails",
  pay_request_invoice_list: "/pay_request_invoice_list",
  videoCall: "/video_call",
  serviceGoto: "/serviceGoto",
  agreementApproval:"/agreement_approval",
  managementDetailsView: "/managementDetailsView",
  walkinDetails: "/walkinDetails"
};

export const BackendRoutes = {
  request_close_request: "/request/close_request",
  client_get_app_configuration: "/client/get_app_configuration",
  user_info: "/user-info",
  nudge_list: "/nudge/list",
  nudge_dismiss: "/nudge/dismiss",
  login_domain_initial_render: "/login_domain/initial_render",
  client_terms_and_conditions: "/client/terms_and_conditions",
  auth_forgotpassword: "/auth/forgotpassword",
  login_domain_domain_list: "/login_domain/domain_list",
  weather_get_current_weather: "/weather/get_current_weather",
  invoice_maintenance_invoice: "/invoice/maintenance_invoice",
  invoice_template: "/invoice/template",
  file_upload_delete: "/file-upload/delete",
  property_board_getPropertyHierarchyLabel: "/property_board/getPropertyHierarchyLabel",
};