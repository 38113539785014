import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { DialogDrawer } from "../../../components";
import AssetView from "../../../components/assetViewer/assetView";
import { useStyles } from "../styles";
import PropertyCard from "./propertyCard";
import UnitImages from "./unitImages";

const UnitView = ({ t = () => false, data, openContactSalesDrawer }) => {
  const classes = useStyles();
  const [unitDrawerImage, setUnitDrawerImage] = React.useState(false);

  const images = data?.images.map((v, index) => ({ id: index + 1, url: "/images/" + v }));
  const imagesAll = data?.images.map((v, index) => ({ id: index + 1, url: "/images/" + v, asset_type: index + 1 }));
  console.log(imagesAll)
  return (
    <Box className={classes.container} sx={{ padding: "0 !important" }}>
      <Box className={classes.borderContainer}>
        <AssetView title={t("Property View")} asset={images} assetAll={imagesAll} />
        {/* <Grid container spacing={1} onClick={() => setUnitDrawerImage(true)}>
          <Grid item xs={data?.images.length <= 1 ? 12 : 9}>
            {data?.images[0] && (
              <img
                className={classes.propertyImg}
                src={"/images/" + data?.images[0]}
                alt=" "
              />
            )}
          </Grid>
          <Grid item xs={3}>
            {data?.images[1] && (
              <img
                className={classes.propertySmImg}
                src={"/images/" + data?.images[1]}
                alt=" "
              />
            )}
            <Box position={"relative"}>
              {data?.images[2] && (
                <img
                  className={classes.propertySmImg}
                  src={"/images/" + data?.images[2]}
                  alt=" "
                />
              )}
              {data?.images.length > 3 && (
                <Box className={classes.overlayCount}>
                  <Box className={classes.countBlock}>
                    <Typography className={classes.countText}>
                      {"+"}
                      {data?.images.length - 3}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid> */}
        <Box height={"10px"} />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={8} xs={7}>
            {/* <Box className={classes.cardfooter}> */}
            <Box flexGrow={1}>
              <Typography className={classes.title} noWrap>
                {data?.propertyName}
              </Typography>
              <Box height={"5px"} />
              <Typography className={classes.subTitle} noWrap>
                {t("Propgoto Listings")}
              </Typography>
              <Typography className={classes.subTitle2} noWrap>
                {data?.address}
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={3} xs={4}>
            <Box className={classes.addBlock}>
              {data?.shortlisted && (
                <Typography
                  component={"span"}
                  className={classes.shortList}
                  noWrap
                >
                  {data?.shortlisted}
                </Typography>
              )}
            </Box>
          </Grid>
          {/* </Box> */}
        </Grid>

        <Box className={classes.footerText2}>
          <Box className={classes.alignImgText}>
            <img src="/images/Bedroom.svg" alt="logo" />{" "}
            <Typography className={classes.subTitle3} noWrap>
              {data?.bed + t(" Bedroom")}
            </Typography>
          </Box>
          <Box className={classes.alignImgText}>
            <img src="/images/SQ_FT.svg" alt="logo" />
            <Typography className={classes.subTitle3} noWrap>
              {data?.sqft + t(" Sq.ft")}
            </Typography>
          </Box>
          <Box className={classes.alignImgText}>
            <img src="/images/Furnished.svg" alt="logo" />
            <Typography className={classes.subTitle3} noWrap>
              {data?.furnishing}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.borderContainer}>
        <Stack direction={"row"} alignItems="center" spacing={1}>
          <img
            className={classes.imgIcon2}
            src="/images/breakup.svg"
            alt="logo"
          />
          <Typography className={classes.subTitle3} noWrap>
            {t("Breakup")}
          </Typography>
        </Stack>
        <Typography component={"h6"} align="center" className={classes.breakupPriceText}>
          {t("This Breakup is for the period of")} {"20 Jan 22"} - {"23 Jan 23"}
        </Typography>
        <Box height={"16px"} />
        <Stack
          direction={"row"}
          alignItems="center"
          spacing={1}
          justifyContent={"space-between"}
        >
          <Typography className={classes.label2} noWrap>
            {t("Base Rent (Monthly)")}
          </Typography>{" "}
          <Typography className={classes.value} noWrap>
            {data?.basicRent}
          </Typography>
        </Stack>
        <Box height={"12PX"} />
        <Stack
          direction={"row"}
          alignItems="center"
          spacing={1}
          justifyContent={"space-between"}
        >
          <Typography className={classes.label2} noWrap>
            {t("Maintanence (Monthly - 10%)")}
          </Typography>
          <Typography className={classes.value} noWrap>
            {data?.maintanence}
          </Typography>
        </Stack>
        <Box height={"12px"} />
        <Stack
          direction={"row"}
          alignItems="center"
          spacing={1}
          justifyContent={"space-between"}
        >
          <Typography className={classes.label2} noWrap>
            {t("Deposit (Onetime - Refundable)")}
          </Typography>
          <Typography className={classes.value} noWrap>
            {data?.deposit}
          </Typography>
        </Stack>
      </Box>
      <Box className={classes.borderContainer}>
        <Box className={classes.filterContainer}>
          <Box flexGrow={1}>
            <Typography className={classes.subTitle4} noWrap>
              {t("Property Location")}
            </Typography>
            <Box height={"6px"} />
            <Typography className={classes.subTitle5} noWrap>
              {t("Rubix Apartment")}
            </Typography>
            <Box className={classes.footerText}>
              <img src="/images/Location.svg" alt="logo" />{" "}
              <Typography className={classes.subTitle} noWrap>
                {data?.location}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.mapContainer}>
            <img src="/images/mapBlue.svg" alt="logo" />
            <Typography className={classes.viewMapText} noWrap>
              {t("View Map")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.borderContainer}>
        <Typography className={classes.subTitle4} noWrap>
          {t("Amenities")}
        </Typography>
        <Box height={"10px"} />
        <Box className={classes.amenitiesList}>
          {data?.amenities.map((list, index) => (
            <Stack
              direction={"row"}
              alignItems="center"
              spacing={1}
              className={classes.amenitieItem}
            >
              <img
                className={classes.imgIcon2}
                src={"/images/" + list?.icon}
                alt="logo"
              />
              <Typography className={classes.subTitle3} noWrap>
                {list?.name}
              </Typography>
            </Stack>
          ))}
        </Box>
      </Box>
      <Box
        className={classes.borderContainer}
        sx={{ paddingBottom: "0px !important" }}
      >
        <Stack direction={"row"} spacing={0.5}>
          <Typography className={classes.subTitle4} noWrap>
            {t("Similar Properties")}
          </Typography>
          <Typography className={classes.subTitle2} noWrap>
            (3)
            {/* {"(" + "3" + ")"} */}
          </Typography>
        </Stack>
        <Box height={"10px"} />

        <ScrollContainer className="scroll-container">
          <Grid container justifyContent={"center"} alignItems="center">
            <Grid item xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <PropertyCard key={1} data={data} border padding />
                <PropertyCard key={1} data={data} border padding />
              </Box>
            </Grid>
          </Grid>
        </ScrollContainer>
      </Box>
      <Box className={classes.btnroot}>
        <Button
          fullWidth
          className={classes.primary}
          variant="contained"
          onClick={() => openContactSalesDrawer()}
        >
          {t("Contact Sales Person")}
        </Button>
      </Box>
      <DialogDrawer
        open={unitDrawerImage}
        header={"Unit Images"}
        onClose={() => setUnitDrawerImage(false)}
        onOpen={() => setUnitDrawerImage(true)}
        isnotTitle={false}
        component={<UnitImages images={data?.images} />}
      />
    </Box>
  );
};

export default UnitView;
