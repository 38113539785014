/* eslint-disable array-callback-return */
import {
  Box, Dialog,
  Drawer, Hidden,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Filter, LoderSimmer, PassCard } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, enumCatagory, enumSelect, FontFamilySwitch, keyMapSource, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
    overflow: "hidden",
  },

  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
    padding: "8px"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "6px"
  },
  titleRoot: {
    padding: "12px"
  },
  nodata: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "gray",
    textAlign: "center"
  }

}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const Visitors = (props) => {
  const { t = () => false, filter = false, setFilter = () => false } = props
  const size = useWindowDimensions();
  const classes = useStyles();

  const [gatelist, setGateList] = React.useState([]);
  const [unitList, setUnitList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const alert = React.useContext(AlertContext);
  const [statusType, setStatusType] = React.useState([]);
  const [securityType, setSecurityType] = React.useState({
    type: {}
  });
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const filterData = [
    {
      id: "1",
      title: "Type",
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: [
        {
          label: "Worker Gate Pass",
          value: securityType?.type?.type2,
        },
        {
          label: "Visitor Gate Pass",
          value: securityType?.type?.type3,
        },
      ],
    },
    {
      id: "2",
      title: "Status",
      key: "status",

      filterType: "CHECKBOX",
      values: statusType?.filter(x => x?.value !== 'Received At Gate'),
    },
    {
      id: "3",
      title: "Unit",
      key: "unit",
      filterType: "CHECKBOX",
      values: unitList,
    },
    {
      id: "4",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];
  const getPassList = (unit, type, status, date, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: type,
      status: status,
      offset: offset,
      // entries: true,
      limit: 20,
      agreement_unit_id: props?.selectedUnit?.value ? [props?.selectedUnit?.value] : null,
      start_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.startDate).toISOString()
          : undefined,
      end_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.endDate).toISOString()
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setGateList(response?.data?.data);
        } else {
          setGateList(gatelist.concat(response?.data?.data));
        }
        setFilter(false);
        setLoading(false);

      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong dfdf"),
        });
      });
  };
  React.useEffect(() => {
    getEnum()
    // eslint-disable-next-line
  }, [props?.units]);
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.security_status_enum_type, enumCatagory?.security_request_type])

    const request_statusType = keyMapSource({ enumType: stayType?.security_status_enum_type })


    let requestSourceMap = stayType?.security_request_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap).map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    console.log(request_statusType)
    setStatusType(stayType?.security_status_enum_type?.filter(x => ![request_statusType?.type4, request_statusType?.type5].includes(x?.value)))
    setSelectedFilter({
      type: [requestType?.type2, requestType?.type3],
      status: stayType?.security_status_enum_type?.filter(x => ![request_statusType?.type4, request_statusType?.type5].includes(x?.value))?.map((val) => val?.value),
      unit: [props?.units],
    });
    getPassList(
      [props?.units],
      [requestType?.type2, requestType?.type3],
      stayType?.security_status_enum_type?.filter(x => ![request_statusType?.type4, request_statusType?.type5].includes(x?.value))?.map((val) => val?.value),
      "",
      true,
      0
    );


    setUnitList(props?.unitlist);
    setSecurityType({ ...securityType, type: requestType })
  }
  const applyFilter = (data) => {
    if (data?.type?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonetype"),
      });
      return false;
    }
    if (data?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonestatus"),
      });
      return false;
    }
    if (data?.unit?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    if (dateFilter?.startDate > dateFilter?.endDate) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Enddatemustbegreaterthanstartdate"),
      });
      return false;
    }
    setOffset(0);
    setSelectedFilter(data);
    getPassList(data.unit, data.type, data.status, dateFilter, filter, 0);
  };
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(
      selectedFilter.unit,
      selectedFilter.type,
      selectedFilter.status,
      "",
      false,
      offset + 20
    );
  };


  return (
    <div className={classes.root} >
      {/* <Box display="flex" alignItems="center" className={classes.titleRoot}>
        <Box flexGrow={1} >
          <Typography className={classes.total}>
            {t("VisitorandWorker")}
          </Typography>
        </Box>
        <Box sx={{ marginInlineStart: "10px" }}>
          <IconButton
            className={classes.filter}
            onClick={() => setFilter(true)}
          >
            <Badge variant="dot" color="primary">
              <img src="/images/icons8-funnel.svg" alt="filter" />
            </Badge>
          </IconButton>
        </Box>
      </Box> */}
      <div style={{ marginRight: "-6px" }}>
        {loading ? (
          <LoderSimmer count={10} />
        ) : (
          <>
            {gatelist.length > 0 ? <InfiniteScroll
              dataLength={gatelist.length}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 180}
            >
              <Box padding={"16px"}>
                {gatelist?.map((item) => {
                  return <Box marginBottom={"12px"}>
                    <PassCard t={t} data={item} />

                  </Box>
                })}
              </Box>
            </InfiniteScroll>
              :
              <Box height={size?.height - 180}>
                <Typography className={classes.nodata}>{t("NoDataFound")}</Typography>
              </Box>
            }

          </>
        )}
      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            disable={true}
            filters={{
              type: selectedFilter.type,
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter.type,
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Drawer>
      </Hidden>
    </div>
  );
};
export default withNamespaces("entriesLog")(Visitors)