import {  Typography, Box, Stack, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";
import ReactQuill from "react-quill";

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "12px",
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    marginTop: "10px",
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  posted: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box !important",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    whiteSpace: "normal",
    marginBottom: "6px",
  },
  dis: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
    marginTop: "4px",
    cursor: "pointer",
  },
  img: {
    width: "101px",
    height: "120px",
    borderRadius: theme.palette.borderRadius,
  },
  health: {
    fontSize: "0.75rem",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().bold,
    background: "#E4E8EE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  announsmentnot: {
    backgroundColor: "#E6F9FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.palette.borderRadius,
    width: "101px",
    height: "120px",
  },
  reactQuil: {
    "& .ql-editor": {
      padding: "0px 0px 10px 0px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box !important",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      whiteSpace: "normal",
      height: "40px",
      width: "95%",
      textAlign: "-webkit-match-parent",
    },
  }
}));

const AnnouncementCard = ({ data = [], t = () => false }) => {
  const classes = useStyles();
  const history = useHistory();
  
  return (
    <div
      className={classes.root}
      onClick={() =>
        history.push(Routes.announcementDetails + "?Id=" + data?.id)
      }
    >
      <Box height={"120px"}>
        <Box display={"flex"} width={"100%"} alignItems={"center"}>
          <Box>
            {data?.description?.[0]?.images ? (
              <>
                <img
                  src={generateImageUrl(data.description[0]?.images, img_size.small_rectangle)}
                  alt=""
                  className={classes.img}
                />
              </>
            ) : (
              <>
                <Box className={classes.announsmentnot}>
                  <img src="/images/announsmentnot.svg" alt="announsment" />
                </Box>
              </>
            )}
          </Box>
          <Box flexGrow={1} sx={{ marginInlineStart: "12px" }}>
            <Stack
              direction={"row"}
              alignItems="center"
              flexWrap={"wrap"}
              marginBottom={"10px"}
              divider={<Divider sx={{ marginInline: "16px" }} orientation="vertical"  flexItem />}
            >
              <Typography noWrap className={classes.health}>
                {data?.category}
              </Typography>
              <Typography className={classes.posted}>
              {moment(new Date(data?.triggered_at)).fromNow()} 
                {/* by {data?.first_name} {t("Post date")} */}
              </Typography>
            </Stack>
            <Box >
              <Typography
                className={classes.title}
              >
                {data?.title}
              </Typography>
            </Box>
            <ReactQuill
                    readOnly
                    theme="bubble"
                    value={data?.description[0]?.Description ?? ""}
                    modules={{
                      toolbar: false,
                    }}
                    className={classes.reactQuil}
                  />
            
          </Box>
        </Box>
      </Box>
     
    </div>
  );
};

export default withNamespaces("announcement")(AnnouncementCard)