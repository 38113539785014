/* eslint-disable array-callback-return */
import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { DomesticCard, Filter, LoderSimmer } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, enumSelect, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
    overflow: "hidden",
    height: '100%'
  },
  menuText: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    marginTop: '30%'
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },
}));

const DomesticHelpList = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const [list, setList] = React.useState([]);
  // const [units, setUnits] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [drawer, setDrawer] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const alert = React.useContext(AlertContext);
  // const [unitsfil, setUnitsfil] = React.useState([]);
  const [unitID, setUnitID] = React.useState([]);
  const [statusType, setStatusType] = React.useState([]);

  const getPassList = (unit, search, offset, filter, status) => {
    const data = {
      tenantId: `${config.tenantid}`,
      offset: offset,
      limit: 20,
      agreement_unit_id: unit,
      query: search,
      status: status,
    };

    NetworkCall(
      `${config.api_url}/services/domestic/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setList(response?.data?.data?.response);
        } else {
          setList(list.concat(response.data.data?.response));
        }
        setDrawer(false);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getUnitids = () => {
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((response) => {
      let data = response?.data?.data.length > 0 ? response?.data?.data?.map(x => x?.agreement_unit).flat() : []
      setUnitID(data?.length > 0 && data?.map((x) => x?.id))
      getEnum(data?.length > 0 && data?.map((x) => x?.id))
    })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  const getEnum = async (data) => {
    const stayType = await enumSelect(['domestic_value_type'])
    setStatusType(stayType?.domestic_value_type)
    getPassList(
      data,
      "",
      0,
      false,
      stayType?.domestic_value_type?.map((val) => val?.value)
    );
    setSelectedFilter(stayType?.domestic_value_type?.map((val) => val?.value));

  }
  React.useState(() => {
    handleTopBarState({
      text: t("Registered Worker Request")
    })
    getUnitids();
    // eslint-disable-next-line
  }, []);
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(unitID, "", offset + 20, false, selectedFilter);
  };
  const filterData = [
    {
      id: "1",
      title: t("Status"),
      key: "status",
      showSearch: true,
      filterType: "CHECKBOX",
      values: statusType
    },
  ];
  const applyFilter = (val) => {
    if (val?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonestatus"),
      });
      return false;
    }
    setOffset(0);
    setSelectedFilter(val?.status);
    getPassList(unitID, "", 0, true, val?.status);
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        text={t("Registered Worker Request")}

        goBack={() => history.push(Routes.service)}
      /> */}
      <div style={{ height: size?.height - 55, overflow: "overlay" }}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" p={1}>
                  <Box flexGrow={1}>
                    <TextField
                      size="small"
                      variant="outlined"

                      placeholder={t("Search")}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "4px",
                      }}
                      fullWidth
                      className={classes.search}
                      onChange={(e) =>
                        getPassList(unitID, e.target.value, 0, true)
                      }
                    />
                  </Box>
                  <Box sx={{ marginInlineStart: "8px" }}>
                    <IconButton
                      className={classes.filter}
                      onClick={() => setDrawer(true)}
                    >
                      <Badge variant="dot" color="primary">
                        <img src="/images/icons8-funnel.svg" alt="filter" />
                      </Badge>
                    </IconButton>
                  </Box>
                </Box>
                <div style={{ marginRight: "-6px" }}>
                  {loading ? (
                    <LoderSimmer count={10} />
                  ) : list.length > 0 ? (
                    <InfiniteScroll
                      dataLength={list.length}
                      next={fetchMoreData}
                      hasMore={true}
                      height={size?.height - 150}
                    >
                      <Box padding={"16px"}>
                        <div>
                          {list?.map((item) => {
                            return <Box marginBottom={"12px"}>
                              <DomesticCard t={t} data={item} />
                            </Box>
                          })}
                        </div>
                      </Box>
                    </InfiniteScroll>
                  ) : (
                    <Typography className={classes.menuText} textAlign="center">
                      {t("Norecordsfound")}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Box sx={{ display: "flex", float: "right" }}>
            <Box>
              <Fab
                color="primary"
                className={classes.fab}
                aria-label="add"
                onClick={() => history.push(Routes.domesticCreate)}
              >
                <AddIcon />
              </Fab>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={() => setDrawer(false)}
          fullWidth
          className={classes.dialog}
          maxWidth="sm"
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter,
            }}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
            onApply={applyFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={drawer}
          onClose={() => setDrawer(false)}
          className={classes.dialog}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter,
            }}
            onApply={applyFilter}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("domesticHelp")(DomesticHelpList)