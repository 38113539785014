import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const PropertyDetailsStyle = makeStyles((theme) => ({
    box: {
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: '4px'
    },
    iconbtn: {
        backgroundColor: theme.palette.border.secondary,
        height: 24,
        width: 24
    },
    apsName: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    apsArea: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    avatar: {
        height: 40,
        width: 40,
        borderRadius: '4px',
        marginInlineEnd: "12px"
    },
    // bodybox: {
    //     height: 300,
    //     overflow: "auto"
    // }
}))