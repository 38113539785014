import React from 'react';
import { DatePickerNew } from "../../components";
import moment from "moment";
export const DatePickers = () => {
      const [startDate, setStartDate] = React.useState({
            startDate: "",
            endDate: '',
      });
      const updateState = (k, v) => {
            setStartDate({ ...startDate, [k]: v })

      }
      return (
            <>
                  <DatePickerNew onChange={(value) => updateState('startDate', value)} value={startDate?.startDate} placeholder={'Start Date'}/>
                  {JSON.stringify(moment(startDate?.startDate).format("DD-MM-YYYY,hh:mm A"))}
            </>
      )
}