import * as React from "react"
const CapacityIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 114302" transform="translate(21667 434)">
      <rect
        width={24}
        height={24}
        fill="#f6f1fe"
        data-name="Rectangle 57697"
        rx={6}
        transform="translate(-21667 -434)"
      />
      <path
        fill="#896db3"
        d="M-21655.4-429a3.5 3.5 0 1 0 3.5 3.5 3.5 3.5 0 0 0-3.5-3.5Zm4.207 8.4h-8.414a1.394 1.394 0 0 0-1.393 1.393v.532a3.217 3.217 0 0 0 1.857 2.822 7.892 7.892 0 0 0 3.743.853c2.7 0 5.6-1.15 5.6-3.675v-.532a1.394 1.394 0 0 0-1.393-1.393Z"
      />
    </g>
  </svg>
)
export default CapacityIcon
