export const Caller = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 108907">
      <path data-name="Rectangle 55935" fill="none" d="M0 0h40v40H0z" />
      <path
        data-name="Path 99703"
        d="M14.769 20.652a2.609 2.609 0 1 1-2.608-2.609 2.609 2.609 0 0 1 2.609 2.609m18.261 0a2.609 2.609 0 1 1-2.609-2.609 2.609 2.609 0 0 1 2.609 2.609"
        fill="#ffa726"
      />
      <path
        data-name="Path 99704"
        d="M11.509 16.087c0-8.3 19.565-5.4 19.565 0v6.522a9.783 9.783 0 1 1-19.565 0v-6.522Z"
        fill="#ffb74d"
      />
      <path
        data-name="Path 99705"
        d="M32.307 15.655c-.309-3.9-1.67-7.732-6.451-8.7L24.552 5h-3.263c-6.016 0-11.085 4.878-11.085 11.739v2.217l1.957 2.344v-3.909l13.7-4.565 4.565 3.913V21.3l1.957-2.348v-2.213a8.994 8.994 0 0 0-.071-1.084"
        fill="#424242"
      />
      <path
        data-name="Path 99706"
        d="M27.161 21.3a1.3 1.3 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3m-9.13 0a1.3 1.3 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3"
        fill="#784719"
      />
      <path
        data-name="Path 99707"
        d="M6.999 21.304c.092 5.9 8.089 13.6 13.162 13.7h1.838a.652.652 0 0 0 .652-.652v-3.917a.652.652 0 0 0-.652-.652h-3.261l-2.313 2.4c-1.2 0-6.423-6.166-6.423-7.365l2.213-2.213v-3.257a.652.652 0 0 0-.651-.652H7.65a.65.65 0 0 0-.651.65Z"
        fill="#009688"
      />
    </g>
  </svg>
)
