// import { Avatar, Box, Grid, Stack, Typography } from '@mui/material'
// import React from 'react'
// import { AuthContext } from '../../../contexts'
// import { generateImageUrl, img_size } from '../../../utils'
// import { PreviewCardStyle } from './style'
// import ElectricIcon from '../../../assets/electricIcon'
// import { withNamespaces } from 'react-i18next'

// const PreviewCard = ({ data = {}, amenityData = {}, t }) => {
//     const auth = React.useContext(AuthContext)
//     const classes = PreviewCardStyle({ language: auth?.auth?.auth?.language })
//     console.log("data", data, "amenityData", amenityData)
//     return (
//         <Box>
//             <Stack direction={"row"} justifyContent={"space-between"}>
//                 <Grid container spacing={1}>
//                     <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
//                         <Avatar className={classes.avatar} src={generateImageUrl(data?.amenity_assets?.[0], img_size.small_square)}></Avatar>

//                     </Grid>
//                     <Grid item xs={9.7} sm={9.7} md={9.7} lg={9.7}>
//                         <Box>
//                             <Typography className={classes.name} noWrap sx={{ width: 200 }}>{data?.amenities_name}</Typography>
//                             {/* <ShowMoreText
//                             lines={2}
//                             more="Show More"
//                             less="Show Less"
//                             className={classes.desc}
//                             anchorClass={classes.seeMoreLessTextStyle}
//                             expanded={false}
//                             truncatedEndingComponent={"... "}
//                         > */}
//                             <Typography className={classes.subtitle}>
//                                 {data?.amenity_category ?? ""}
//                             </Typography>
//                             {/* </ShowMoreText> */}

//                         </Box>
//                     </Grid>

//                 </Grid>
//                 <Stack alignItems={"end"}>
//                     {data?.is_chargeable ?
//                         <Typography className={classes.name} sx={{ float: "right" }}>
//                             {data?.symbol} {data?.amount}
//                         </Typography> :
//                         <Typography className={classes.freeText}>{t("Free")}</Typography>
//                     }
//                     {
//                         amenityData?.peakHourSlot?.length > 0 && amenityData?.response?.amenity?.[0]?.slots_info?.[1]?.slots?.length > 0 &&
//                         <Stack direction={"row"} spacing={1} className={classes.peakBox} alignItems={"center"} px={1}>
//                             <ElectricIcon />
//                             <Typography className={classes.peakBoxText}>{`Peak Hour  ${data?.symbol} ${amenityData?.response?.amenity?.[0]?.slots_info?.[1]?.rate} / Slot`}</Typography>
//                         </Stack>
//                     }
//                 </Stack>
//             </Stack>
//         </Box>
//     )
// }

// export default withNamespaces("amenitiesBooking")(PreviewCard)


import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { AuthContext } from '../../../contexts'
import { generateImageUrl, img_size } from '../../../utils'
import { PreviewCardStyle } from './style'
import ElectricIcon from '../../../assets/electricIcon'
import { withNamespaces } from 'react-i18next'

const PreviewCard = ({ data = {}, amenityData = {}, t = () => false }) => {
    const auth = React.useContext(AuthContext)
    const classes = PreviewCardStyle({ language: auth?.auth?.auth?.language })
    return (
        <Box>
            <Stack direction={"row"} spacing={1}>
                <Avatar className={classes.avatar} src={generateImageUrl(data?.facility_assets?.[0]?.url ?? data?.facility_type?.assets?.[0]?.url, img_size.small_square)}></Avatar>
                <Stack className={classes?.cardTitles} justifyContent={"space-between"}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.amenities_name} noWrap >{data?.description ?? data?.facility_type?.amenities_name}</Typography>
                        {
                            data?.is_chargeable ?
                                <Typography className={classes.name} sx={{ float: "right" }}>
                                    {`${data?.symbol ?? data?.currency_symbol} ${data?.amount ?? data?.rate}`} /
                                    {data?.period === "Daily" ? "Day" : "Slot"}
                                </Typography> :
                                <Typography className={classes.freeText}>{t("Free")}</Typography>
                        }
                    </Stack >
                    <Stack justifyContent={"space-between"} direction="row" className={classes.freeSlotBox}>
                        <Typography className={classes.subtitle} noWrap>
                            {data?.amenity_category ?? data?.amenities_category?.name}
                        </Typography>
                        {/* {Above for 320} */}
                        {/* <Stack className={classes.peakBoxForWeb}> */}
                        {
                            amenityData?.peakHourSlot?.length > 0 && amenityData?.response?.amenity?.[0]?.slots_info?.[1]?.slots?.length > 0 &&
                            <Stack direction={"row"} spacing={1} className={classes.peakBox} alignItems={"center"}>
                                <ElectricIcon />
                                <Typography className={classes.peakBoxText} noWrap>{`Peak Hour  ${data?.symbol ?? data?.currency_symbol} ${amenityData?.response?.amenity?.[0]?.slots_info?.[1]?.rate} / Slot`}</Typography>
                            </Stack>
                        }
                        {/* </Stack> */}

                    </Stack>
                </Stack >
            </Stack >
            {/* <Stack className={classes.peakBoxForMobile}>
                {amenityData?.peakHourSlot?.length > 0 && amenityData?.response?.amenity?.[0]?.slots_info?.[1]?.slots?.length > 0 &&
                    <Stack direction={"row"} spacing={1} className={classes.peakBoxMob} alignItems={"center"}>
                        <ElectricIcon />
                        <Typography className={classes.peakBoxTextMob}>{`Peak Hour  ${data?.symbol ?? data?.currency_symbol} ${amenityData?.response?.amenity?.[0]?.slots_info?.[1]?.rate} / Slot`}</Typography>
                    </Stack>
                }
            </Stack> */}

        </Box >
    )
}

export default withNamespaces("amenitiesBooking")(PreviewCard)