import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RequestedIcon, PriorityIcon, ApprovedTick, ClockTrack, VideoTrack, ProrityTrack } from "../../assets";

export const returnTypeData = (status, t = () => false) => {
    switch (status) {
        case "Pending":
            return ({
                bgColor: "#78B1FE",
                title: t("Requested"),
                icon: <RequestedIcon />
            })

        case "Approved":
            return ({
                bgColor: "#5AC782",
                title: t("Approved"),
                icon: <CheckCircleIcon style={{ color: "white" }} />
            })

        case "Rejected":
            return ({
                bgColor: "#FF4B4B",
                title: t("Rejected"),
                icon: <PriorityIcon />
            })

        default:
            break;
    }
}

export const returnTrackIcon = (status) => {
    switch (status) {
        case "Video Call Send":
            return <VideoTrack />
        case "Approved":
            return <ApprovedTick />
        case "Pending":
            return <ClockTrack />
        case "Rejected":
            return <ProrityTrack />
        default:
            break;
    }
}

export const returnPayloadApi = ({ type, id, agreement_unit_user, profile_id, t = () => false }) => {
    switch (type) {
        case "revoke":
            return {
                api: "upsert_walk_in_log",
                message: t("Request Revoked Successfully"),
                params: {
                    is_swift_in: true,
                    is_walk_in: true,
                    swift_in_param: {
                        id,
                        status: "Rejected",
                        updated_by: profile_id,
                        updated_at: new Date()
                    },
                    walk_in_param: {
                        status: "Rejected",
                        request_id: id,
                        created_by: profile_id,
                        agreement_unit_user,
                        security_guard_id: profile_id,
                        message: "Revoked Request"

                    }
                }

            }

        case "approved":
            return {
                api: "upsert_walk_in_log",
                message: t("Request Approved Successfully"),
                params: {
                    is_swift_in: true,
                    is_walk_in: true,
                    swift_in_param: {
                        id,
                        status: "Approved",
                        updated_by: profile_id,
                        updated_at: new Date()
                    },
                    walk_in_param: {
                        status: "Approved",
                        request_id: id,
                        created_by: profile_id,
                        agreement_unit_user,
                        security_guard_id: profile_id,
                        message: "Approved"

                    }
                }
            }
        case "send_video":
            return {
                api: "send_video_request",
                message: t("Send Video Request To Resident"),
                params: {
                    request_id: id
                }
            }

        case "accpt_send_video":
            return {
                api: "upsert_walk_in_log",
                params: {
                    is_swift_in: true,
                    is_walk_in: true,
                    swift_in_param: {
                        id,
                        status: "Approved",
                        updated_by: profile_id,
                        updated_at: new Date()
                    },
                    walk_in_param: {
                        status: "Approved",
                        request_id: id,
                        created_by: profile_id,
                        agreement_unit_user,
                        security_guard_id: profile_id,
                        message: "Approved"

                    }
                }
            }

        case "check_in":
            return {
                api: "upsert_walk_in_log",
                message: t("Request Checked In"),
                params: {
                    is_swift_in: true,
                    swift_in_param: {
                        id,
                        check_in_time: new Date()
                    }
                }

            }

        case "check_out":
            return {
                api: "upsert_walk_in_log",
                message: t("Request Checked Out"),
                params: {
                    is_swift_in: true,
                    swift_in_param: {
                        id,
                        check_out_time: new Date()
                    }
                }

            }

        default:
            break;
    }
}


export const escortState = {
    person_image: null,
    vehicle_image: null,
    resource: "",
    person_name: "",
    id_proof: "",
    id_number: "",
    vehicle_type: null,
    vehicle_no: "",
    loading:true,
    agreement_unit_user:null,
    is_update:false,
    error: {
        person_image: null,
        vehicle_image: null,
        resource: "",
        person_name: "",
        id_proof: null,
        id_number: "",
        vehicle_type: null,
        vehicle_no: ""
    }
}

export const constructFillParam=(data)=>{
    return {
        person_image: data?.escort_person_image? {src:data?.escort_person_image}:null,
        vehicle_image: data?.vehicle_image? {src:data?.vehicle_image}:null,
        resource: data?.resource?.id?{
            value:data?.resource?.id,
            label:`${data?.resource?.reference_id} | ${data?.resource?.contact?.first_name}`
        }:null,
        person_name: data?.person_to_meet??"",
        id_proof: data?.identification?.label ? data?.identification:null,
        id_number: data?.identification_no??"",
        vehicle_type:data?.vehicle_type?? null,
        vehicle_no: data?.vehicle??"",
        loading:true,
        agreement_unit_user:data?.notes?.[0]?.agreement_unit_user,
        is_update:data?.identification?.label?true:false,
        error: {
            person_image: null,
            vehicle_image: null,
            resource: "",
            person_name: "",
            id_proof: null,
            id_number: "",
            vehicle_type: null,
            vehicle_no: ""
        }
    }
}