import { Container, Grid, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { LoderSimmer } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods, AlertProps, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertContext } from "../../contexts";
import { PropertyListCard } from "../../components/propertyListCard";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F6F6F6",
  },
  roots: {
    // margin: "0px 12px",
    padding: "6px 6px 12px 12px",
  },
  image: {
    objectFit: "cover",
    width: "101px",
    height: "113px",
  },
  card: {
    backgroundColor: "white",
    marginTop: "12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px",
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginTop: "12px",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "0.75rem",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,

  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    margin: "10px 0px 0px 12px",
    alignItems: "center"
  },
  noData: {
    color: "#999",
    textAlign: "center",
    marginTop: "20vh",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px"
  },
}));

const MyNeighbours = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [datas, setDatas] = React.useState({
    propertyCount: 0,
    property_detail: []
  });
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);

  const getproperty = (offset = 0) => {
    const data = JSON.stringify({
      tenantId: `${config.tenantid}`,
      offset: offset,
      limit: 10,
      is_pagination: true
    });
    NetworkCall(
      `${config.api_url}/agreement-property-info/get`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let tempRes = response?.data?.data
        setDatas({
          ...datas,
          propertyCount: datas?.propertyCount + tempRes?.propertyCount,
          property_detail: datas?.property_detail?.concat(tempRes?.property_detail)
        });
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  React.useEffect(() => {
    handleTopBarState({
      text: t("Directory")
    })
    getproperty();
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = () => {
    let tempOffset = offset + 10
    setOffset(tempOffset);
    getproperty(tempOffset);
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        text={t("Directory")}
        goBack={() => history.goBack()}
      /> */}
      <div
        style={{
          height: size?.height - 60,
          overflow: "scroll",
        }}
        className={classes.roots}
      >
        <Typography className={classes.total}>
          {t("Properties")}&nbsp;({datas?.propertyCount > 0 ? datas?.propertyCount : 0}
          )
        </Typography>
        <Box height={'12px'} />
        {loading ? (
          <LoderSimmer card count={10} size={80} />
        ) : (
          <>
            {datas?.property_detail?.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={datas?.property_detail?.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 140}
                >
                  <Grid container spacing={1}>
                    {datas?.property_detail?.length > 0 ? datas?.property_detail?.map((val) => {
                      return (
                        <>
                          <Grid item xs={12}>
                            <PropertyListCard
                              t={t}
                              neighbours
                              data={val}
                              onClick={() =>
                                history.push(
                                  Routes.myNeighboursDetails +
                                  "?propertyId=" +
                                  val?.property_id +
                                  "&name=" +
                                  val?.property_name + "&property_hierarchy_id=" + val?.property_hierarchy_id
                                )
                              }
                            />
                          </Grid>

                        </>
                      );
                    }) :
                      <Typography className={classes.noData}>
                        {t("Datanotfound")}
                      </Typography>}
                  </Grid>
                </InfiniteScroll>
              </>
            ) : (
              <Typography className={classes.title}>{t("NoDataFound")}</Typography>
            )}
          </>
        )}
      </div>
    </Container>
  );
};
export default withNamespaces("neighbours")(MyNeighbours)