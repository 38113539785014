import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const SlotCardStyles = makeStyles((theme) => ({
  main: {
    border: "1px solid #E4E8EE",
    borderRadius: "6px",
    padding: "8px 0px",
    cursor: "pointer",
    backgroundColor: "#F2F4F7",
    height: 58,
    alignItems:"center"
  },
  mainslot: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "8px 0px",
    cursor: "pointer",
    background: "#fff",
    height:58,
    alignItems:"center"
  },
  slotTime: {
    fontSize: "0.875rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: "3px",
    marginTop: (props) => props?.data?.slot_detail?.is_peak ? "0px"  : props?.response?.amenity?.[0]?.is_overbooking ? "2px" : "6px",
    [theme.breakpoints.down(321)]: {
      fontSize: "0.75rem",
      color: "#4E5A6B",
      fontFamily: FontFamilySwitch().extraBold,
      marginBottom: "3px",
    }
  },

  slotPerson: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(321)]: {
      fontSize: "0.75rem",
      color: "#98A0AC",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }
  },

  dot: {
    width: "6px",
    height: "6px",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    marginLeft: "7px",
    marginRight: "7px"
  },

  gridBox: {
    paddingTop: "0 !important",
    textAlign: "center",
    alignItems:"center",
    justifyContent:"center"
  },
  selectedBox: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
    padding: "8px 0px",
    cursor: "pointer",
    height:58,
    background: "#F1F7FF"
  },
  booked: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary
  },
  bookedCard: {
    border: `1px solid #E4E8EE`,
    borderRadius: "4px",
    padding: "8px 0px",
    background: "#F2F4F7",
    height:58
  },
  bookedText: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  slotTimeBooked: {
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem"
  },
  obLabel: {
    position: "absolute",
    top: 2,
    right: 1,
    zIndex: 100,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary
  },
  peakBox: {
    position: "absolute",
    top: "-7px",
    left: "42%",
    zIndex: 100,
    background: theme.palette.warning.light,
    height: 16,
    width: 24,
    display: "flex",
    justifyContent: "center",
    borderRadius: "9px",
    alignItems: "center"
  },
  underMaintanceBox: {
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: "4px",
    padding: "8px 0px",
    cursor: "not-allowed",
    background: theme.palette.warning.light
  },
  // maintanceBox: {
  //   border: `1px solid ${theme.palette.warning.main}`,
  //   borderRadius: 4,
  //   cursor: "pointer",
  //   backgroundColor: theme.palette.warning.light,
  //   height: 58
  // },
  maintanceSlotTime: {


    fontSize: "0.875rem",
    color: theme.palette.warning.main,
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: "3px",
    [theme.breakpoints.down(321)]: {
      fontSize: "0.75rem",
      color: theme.palette.warning.main,
      fontFamily: FontFamilySwitch().extraBold,
      marginBottom: "3px",
    }

    // color: theme.palette.warning.main,
    // fontFamily: Bold,
    // fontSize: "0.75rem",
  },
  maintanceContent: {
    fontSize: "0.75rem",
    color: theme.palette.warning.main,
    fontFamily: FontFamilySwitch().semiBold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(321)]: {
      fontSize: "0.75rem",
      color: theme.palette.warning.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }
  },
}));
