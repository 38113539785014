import { Stack, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { FontFamilySwitch } from "../../utils";
import { LightTooltip } from "../tooltip";
import { InfoIcon } from "../../assets/infoIcon";

const useStyles = makeStyles((theme) => ({
  Label: {

    fontSize: "0.75rem",
    paddingBottom: "8px",
    fontFamily: FontFamilySwitch().bold
  },


  textbox: {
    "& input::placeholder": {
      fontSize: "0.875rem !important",
      fontFamily: FontFamilySwitch()?.regular,
      color: "#4E5A6B !important",
      opacity:1
    },
    "&input,textarea::placeholder": {
      fontSize: "0.875rem !important",
      fontFamily: FontFamilySwitch()?.regular,
      color: "#4E5A6B !important",
      opacity:1
    },
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: (props) => `${props?.border ?? '1px'} solid ${props?.borderColor ?? '#E4E8EE'}`,
      "& :hover": {
        border: (props) => `${props?.border ?? '1px'} solid ${props?.borderColor ?? '#E4E8EE'}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "10px" : "13px 14px 10px 14px"),
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().semiBold
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
  textbox2: {
    "& input::placeholder": {
      fontSize: "0.875rem !important",
      fontFamily: FontFamilySwitch()?.regular,
      color: "#4E5A6B !important",
      opacity:1
    },
    "&input,textarea::placeholder": {
      fontSize: "0.875rem !important",
      fontFamily: FontFamilySwitch()?.regular,
      color: "#4E5A6B !important",
      opacity:1
    },
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "none",
      "& :hover": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "10px" : "13px 14px 10px 0px"),
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().semiBold
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
}));

export const TextBox = (props) => {
  const classes = useStyles(props);
  const [isInfoOpen, setIsInfoOpen] = React.useState(false)

  const getLabel = (props) => {
    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"}>
          {
            <Typography
              variant="body1"
              sx={{ color: props?.labelColor ? props?.labelColor : "#4E5A6B", }}
              color="textsecondary"
              className={classes.Label}
              align="left"
            // gutterBottom
            >
              {props?.label}
            </Typography>
          }
          {props?.isRequired && (
            <Typography color="error" variant="caption">
              &nbsp;*
            </Typography>
          )}
        </Stack>
        {props?.info &&
          <LightTooltip title={props?.info} placement="left-start" open={isInfoOpen}>
            <div
              onClick={() => setIsInfoOpen(!isInfoOpen)}
              onMouseEnter={() => setIsInfoOpen(true)}
              onMouseLeave={() => setIsInfoOpen(false)}>
              <InfoIcon />
            </div>
          </LightTooltip>}
      </Stack>
    );
  };

  return (
    <div className={classes.root}>
      <>
        {props?.label?.length > 0 && getLabel(props)}
        <div className={classes.text}>
          <TextField
            onKeyDown={props?.handleKeypress}
            className={props?.borderNone ? classes.textbox2 : classes.textbox}
            type={props.type}
            id={props.id}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth
            pattern={props?.pattern}
            InputLabelProps={{
              shrink: false,
            }}
            style={{
              backgroundColor: props.color ? "white" : "auto",
              borderRadius: "6px",
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
              autoCapitalize: "none",
            }}
            InputProps={{
              endAdornment: props?.endAdornment,
              startAdornment: props?.startAdornment,
              style: {
                fontSize: props?.fontSize ?? "0.875rem",
                padding: props.multiline ? 0 : "none",
                color: props?.color ?? "",
                fontFamily:FontFamilySwitch()?.semiBold
              },
            }}
            onKeyPress={props?.onKeyPress}
            disabled={props?.disabled ?? false}
            size="small"
            multiline={props.multiline}
            rows={props?.rows ? props.rows : 5}
            rowsMax={10}
            onChange={props.onChange}
            value={props.value}
            onWheel={(e) => e.target.blur()}
            autoComplete={props?.autoComplete && 'off'}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
};
TextBox.defaultProps = {
  label: "",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
