import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: "8px",
        width: '100%',
        overflow: "auto",
        backgroundColor:'#F8F8F8',
        height: 'calc(100vh - 65px) !important',
        // height: {
        //   xs:'calc(100vh - 147px)',
        //   sm:'calc(100vh - 155px)'
        // },
        "&::-webkit-scrollbar": {
          display: "none",
        },     
        md: {
            height: 'calc(100vh - 147px)',
        },
        
        
      },
      container:{
        paddingLeft:"0px !important",
        paddingRight:"0px !important",
      },
      headAvatar:{
        justifyContent:"center",
        marginBottom:"12px",
      },
      headTitle:{
        color:theme.typography.color.primary,
        fontSize: "1.25rem",
        fontFamily:FontFamilySwitch().extraBold,
        textAlign:"center",
        marginBottom:"24px",
      },
      topSection:{
        textAlign:"center"
      }
  }));
  
  