import { CheckRounded } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  InvoiceListGrid: {
    padding: "14px 1px",
   
  },
  checkBox: {
    backgroundColor: "#5078e1",
    color: "#fff",
    borderRadius: "50%",
    padding: "4px",
    fontSize: "1.625rem",
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  roundShape: {
    width: "6px",
    height: "6px",
    backgroundColor: theme.palette.border.primary,
    borderRadius: "100%",
    display: "inline-block",
    marginBottom: "2px",
  },
  amount: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    textAlign: "end",
    marginBottom: "5px",
  },
  status: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: theme.palette.warning.light,
    borderRadius: "4px",
    color: theme.palette.warning.main,
    padding: " 1px 7px",
  },
  statusPaid: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: theme.palette.success.light,
    borderRadius: "4px",
    color: theme.palette.success.main,
    padding: " 1px 7px",
  },
}));

const InvoiceList = ({
  invoiceData = {},
  t = () => false,
  onCheck = () => false,
  viewinvoice = () => false,
}) => {
  const classes = useStyles();
  const language = localStorage.getItem("i18nextLng")
  moment.defaultFormat = "DD MMM YY";

  const handlefun = (invoiceData) => {
    if (invoiceData.payment_status !== "no-due") {
      onCheck(invoiceData.active ? false : true, invoiceData);
    } else {
    }
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        onClick={() => handlefun(invoiceData)}
        alignItems="center"
        className={classes.InvoiceListGrid}
        sx={ {cursor:invoiceData?.payment_status === "due"?  "pointer": "unset"}}
      >
        <Grid item xs={8} display="flex" alignItems="center">
          {invoiceData.active && (
            <Grid item xs={1} marginRight={2}>
              <CheckRounded className={classes.checkBox} />
            </Grid>
          )}
          <Grid item xs={11}>
            <Typography className={classes.title}>
              {invoiceData?.description ? invoiceData?.description : ""}
            </Typography>
            <Box height={"5px"} />
            <Typography className={classes.subTitle}>
              {/* {invoiceData?.invoice_type && invoiceData?.invoice_type} */}
              {invoiceData?.invoice_no} &nbsp;
              <>
                &nbsp;
                <span className={classes.roundShape} />
                &nbsp;
              </>
              {invoiceData?.payment_due_date && (
                <>
                  &nbsp;{"Due"}&nbsp;
                  {moment(invoiceData.payment_due_date)
                    .tz(moment.tz.guess())
                    .format()}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <div style={{ justifyContent: "end" }}>
            <Typography className={classes.amount}>
              {invoiceData?.invoice_due_amount &&
                invoiceData?.currency_master?.symbol +
                  " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                  }).format(invoiceData?.invoice_due_amount)}
            </Typography>

            {invoiceData?.payment_status != null && (
              <span>
                {invoiceData?.payment_status === "due" && (
                  <div style={{ float: language === "ar" ? "left" : "left" }} className={classes.status}>{t("pending")}</div>
                )}
                {invoiceData.payment_status === "no-due" && (
                  <div style={{ float: language === "ar" ? "left" : "left" }} className={classes.statusPaid}>{t("paid")}</div>
                )}
              </span>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default withNamespaces("invoice")(InvoiceList);
