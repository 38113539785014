import * as React from "react"

const Trash = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={11.4} height={12} {...props}>
    <path
      data-name="icons8-trash (2)"
      d="M5.7 0a2.107 2.107 0 0 0-2.076 1.8H1.571a.4.4 0 0 0-.144 0H.45a.45.45 0 1 0 0 .9h.642l.755 7.809A1.656 1.656 0 0 0 3.489 12H7.91a1.656 1.656 0 0 0 1.642-1.491l.756-7.809h.642a.45.45 0 1 0 0-.9h-.977a.45.45 0 0 0-.144 0H7.776A2.107 2.107 0 0 0 5.7 0Zm0 .9a1.189 1.189 0 0 1 1.158.9H4.542A1.189 1.189 0 0 1 5.7.9ZM2 2.7h7.4l-.748 7.722a.744.744 0 0 1-.746.678H3.489a.745.745 0 0 1-.746-.678Zm2.643 1.494a.45.45 0 0 0-.443.456v4.5a.45.45 0 1 0 .9 0v-4.5a.45.45 0 0 0-.457-.456Zm2.1 0a.45.45 0 0 0-.443.456v4.5a.45.45 0 1 0 .9 0v-4.5a.45.45 0 0 0-.457-.456Z"
      fill="#ff4b4b"
    />
  </svg>
)

export default Trash;
