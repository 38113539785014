import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  loading_div: { 
    color: "#fff", zIndex: theme.zIndex.drawer + 1 
  },
  body_div: {
    height: (props) => props?.size?.height - (55 + 72),
    padding: "16px",
    overflow: "auto",
  },
  tab_div: { backgroundColor: "#F8FAFC", padding: "12px 16px" },
  heading: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
  },
  year: {
    fontFamily: FontFamilySwitch().semiBold,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "7px 11px",
    fontSize: "0.75rem",
    borderRadius: "15px",
  },
  month: {
    fontSize: "0.75rem",
    padding: "7px 5px 7px 0",
  },
  activity: {
    backgroundColor: "#FFE5DC",
    color: "#F15A29",
    padding: "2px 8px",
    fontSize: "0.75rem",
    borderRadius: "4px",
  },
  work: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      bottom: "35px",
      right: "35px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  revenueCard: {
    backgroundColor: "white",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "12px",
  },
}));
