import * as React from "react"

const DateIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16.25}
        height={16.25}
        {...props}
    >
        <path
            data-name="icons8-calendar (5)"
            d="M3.014.125A2.9 2.9 0 0 0 .125 3.014v10.222a2.9 2.9 0 0 0 2.889 2.889h10.222a2.9 2.9 0 0 0 2.889-2.889V3.014A2.9 2.9 0 0 0 13.236.125Zm0 1.333h10.222a1.546 1.546 0 0 1 1.556 1.556v.667H1.458v-.667a1.546 1.546 0 0 1 1.556-1.556ZM1.458 5.014h13.334v8.222a1.546 1.546 0 0 1-1.556 1.556H3.014a1.546 1.546 0 0 1-1.556-1.556Zm2.889 1.778a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Zm3.778 0a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Zm3.778 0a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Zm-7.556 4a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Zm3.778 0a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Z"
            fill="#4e5a6b"
            stroke="#4e5a6b"
            strokeWidth={0.25}
        />
    </svg>
)

export default DateIcon
