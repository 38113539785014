import { Box, Typography } from "@mui/material";
import { useStyles } from "./style";
import RevenueCard from "./component/revenueCard";
import React from "react";
import RevenueListCard from "./component/revenueListCard";
import { withNamespaces } from "react-i18next";
// import DoughnutChart from "./component/doughnut";

const Revenue = ( props) => {
  const intervalOption = [
    { label: "Yearly", value: "Yearly" },
    { label: "Monthly", value: "Monthly" },
  ];
  const yearOption = [
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
  ]
  const { t=()=>false } = props;
  const classes = useStyles();
  const value = [
    {
      percentage: "50%",
      tenantId: "PG_342546",
      price: "$200",
      color: "#EE5188",
    },
    {
      percentage: "10%",
      tenantId: "PG_342546",
      price: "$200",
      color: "#A9ACFF",
    },
    {
      percentage: "30%",
      tenantId: "PG_342546",
      price: "$200",
      color: "#F49920",
    },
    {
      percentage: "10%",
      tenantId: "PG_342546",
      price: "$200",
      color: "#25C967",
    },
  ];

  const monthOption = [
    {label: "Jan", value: "1" },
    {label: "Feb", value: "2" },
    {label: "Mar", value: "3" },
    {label: "Apr", value: "4" },
    {label: "May", value: "5" },
    {label: "Jun", value: "6" },
    {label: "Jul", value: "7" },
    {label: "Aug", value: "8" },
    {label: "Sep", value: "9" },
    {label: "Oct", value: "10" },
    {label: "Nov", value: "11" },
    {label: "Dec", value: "12" },
  ]

  const [duration, setDuration] = React.useState(intervalOption[0]);

  return (
    <>
      <div>
        <RevenueCard
         intervalOption={intervalOption}
         duration={duration}
         setDuration={setDuration}
         monthOption={monthOption}
         yearOption={yearOption}
          t = { t }
        />
      </div>
      <div>
        <Typography my={2} className={classes.heading}>
          {t("Summary")}
        </Typography>
        <Box className={classes.revenueCard}>
          {value.map((data) => {
            return (
              <>
              <RevenueListCard data={data} />
              </>
            );
          })}
        </Box>
      </div>
    </>

  );
};

export default  withNamespaces("statistics")(Revenue);
