import { Box, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import ImageViewer from "react-simple-image-viewer";
import { Profile } from "../../assets";
import { PassContext } from "../../screens/createPass/passContext";
import { img_size } from "../../utils";
import { FontFamilySwitch, generateImageUrl } from "../../utils/common";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: "4px",
    backgroundColor: theme.palette.background.paper,
  },
  Cardcontent: {
    padding: "8px 12px",
        marginBottom: "8px",
  },
  title: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  dot2: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "0px 6px",
    display: "inline-block",
  },
  sub: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  proof: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
  },
  view: {
    fontSize: "0.75rem",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  visitorImg: {
    objectFit: "contain",
    marginTop: "4px",
    height: "60px",
    width: "60px",
    borderRadius: "8px",
  },
  borderVertical: {
    borderRight: "1px solid #E4E8EE",
    margin: "0px 6px ",
    padding: "5px 1px",
    display:"inline-block",
  },
}));

export const AddvisiterCard = (props) => {
  const classes = useStyles(props); 
  const { data } = React.useContext(PassContext);
  return (
    <>
      <div className={classes.card}>
        <div className={classes.Cardcontent} >
          <Box alignItems="center" display="flex">
            <Box
              onClick={() => {
                props?.setSelectedImage(props?.data?.profileImg);
                props?.setOpenviewer(true);
              }}
            >
              {props?.data?.profileImg ? (
                <img
                  src={generateImageUrl(props?.data?.profileImg,img_size.small_square)}
                  alt=""
                  className={classes.visitorImg}
                />
              ) : (
                <Profile />
              )}
            </Box>
            <Box flexGrow={1} marginLeft="10px">
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.name}
              </Typography>
              <Box
                justifyContent="center"
                display="flex"
                flexDirection={"column"}
                marginTop="4px"
              >
                <Box>
                <Stack direction={"column"} >
                    <Typography variant="subtitle2" className={classes.proof}>
                      {props?.data?.mobile?.mobile_code}{" "}{props?.data?.mobile?.mobile}
                    </Typography>
                    {/* {props?.data?.mail && 
                    <Typography variant="subtitle2" className={classes.proof}>
                      {props?.data?.mail}
                    </Typography>
                    } */}
                 </Stack>
                </Box>
                {/* {props?.data?.id_label ? <Box className={classes.dot} /> : ""} */}
                <Box>
                  {/* <Button
                    onClick={() => {
                      props.handleDetails(props?.data);
                    }}
                  > */}
                  <Stack flexDirection="column" >
                    {props?.data?.idType && 
                  <Typography variant="subtitle2" className={classes.proof}>
                    {props?.data?.idType?.label ? props?.data?.idType?.label :  props?.data?.idType}{" "} 
                  </Typography>}
                  {props?.data?.idproof &&
                  <Typography variant="subtitle2" className={classes.proof}>
                    {props?.data?.idproof}
                  </Typography>
                   }
                  </Stack>
                 
                  {/* </Button> */}
                </Box>
              </Box>
            </Box>
            {data?.visitor_details?.filter(({view_type})=>view_type==="edit").length <= 0 &&
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent="space-between"
            >
              <IconButton
                size="small"
                onClick={() => {
                  props?.handleDetails(props?.index);
                }}
              >
                <img src="/images/Outline.svg" alt="" />
              </IconButton>
              <Box height={"5px"} />
              <IconButton
                size="small"
                onClick={() => {
                  props?.onDelete(props.index);
                }}
              >
                <img src="/images/deleteIcon.svg" alt="" />
              </IconButton>
            </Box>
            }
          </Box>
        </div>
      </div>

      {props?.openViwer && (
        <ImageViewer
          src={[props?.selectedImage]} // --- pending
          onClose={() => props?.setOpenviewer(false)}
          disableScroll={false}
          currentIndex={0}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  );
};
