import { Box, Container, Typography } from "@mui/material";
import { LoadingSection, TitleBar } from "../../components"
import { RequestCardDetails } from "./components/requestDetailCard"
import { GuestDetailCard } from "./components/guestDetailCard"
import { TrackCard } from "./components/guestDetailCard"
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods, AlertProps, remCalculate, FontFamilySwitch } from "../../utils";
import React from "react";
import moment from "moment";
import { returnPayloadApi } from "./utils";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { useSocket } from "../videoCall/socket"
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    headTitle:{
        color:"#98A0AC",
        fontSize:remCalculate(12),
        fontFamily: FontFamilySwitch().semiBold,
        margin:"12px 0px"
    },
    detailRoot:{
        backgroundColor:"#F6F6F6",
        
        overflow:"overlay"
    },
    trackCard:{
        backgroundColor:"white",
        boxShadow:"0px 3px 30px #4354721F",
        borderRadius:"12px",
        padding:"20px"
    },
    revokeBtn:{
        padding:"12px",
        fontSize:remCalculate(14),
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius:"8px",
        boxShadow:"none"
    },
    btnRoot:{
        backgroundColor:"white",
        boxShadow:"0px -1px 6px #00000021"
    }

}));

const WalkInDetails = ({ t = () => false }) => {

    const searchURL = useLocation().search;
    const history = useHistory();
    const socket = useSocket();
    const passId = new URLSearchParams(searchURL).get("passId");
    const profile_id = localStorage.getItem("user_id");
    const alert = React.useContext(AlertContext);
    const [state, setState] = React.useState({
        details: {},
        loading: true
    });

    const classes = useStyles()

    const getDetails = () => {
        const payload = {
            id: passId
        }
        NetworkCall(
            `${config.api_url}/walkInout/details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                setState({
                    details: res?.data,
                    loading: false
                })
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updateSwiftInOutUpdate = (type) => {

        const { api, params, message } = returnPayloadApi({ type, profile_id, id: passId, agreement_unit_user: state?.details?.notes?.[0]?.agreement_unit_user, t });

        NetworkCall(
            `${config.api_url}/walkInout/${api}`,
            NetWorkCallMethods.post,
            params,
            null,
            true,
            false
        )
            .then((res) => {
                getDetails()
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: message,
                });
            })
            .catch((err) => {
                console.log(err);
            });

    };

    React.useEffect(() => {
        if (socket && state?.details?.id) {
            socket.on("change_request_status", (data, id) => {
                if (data?.id === passId) {

                    setState({
                        ...state,
                        details: {
                            ...state?.details,
                            status: data?.details?.swift_in_param?.status,
                            notes: [{ ...data?.details?.walk_in_param, created_by: state?.details?.created_by }, ...state?.details?.notes]
                        }
                    })
                }
            })
        }
        // eslint-disable-next-line
    }, [socket, state]);

    React.useEffect(() => {
        if (passId) {
            getDetails()
        } else {
            history.push(Routes.dashboard)
        }
        // eslint-disable-next-line 
    }, [])

    const goBack = () => {
        history.goBack()
    }




    return (
        <Container maxWidth="sm" style={{ padding: "0px" }}>
            <Box>

                <TitleBar goBack={goBack} text={state?.details?.unit_name ?? ""} />
                {
                    state?.loading ?
                        <LoadingSection message={"...Loading"} top={"40vh"} />
                        :
                        <Box>
                            <Box p={2} className={classes.detailRoot}
                                style={{ height: "calc(100vh - 54px)" }}
                            >
                                <RequestCardDetails
                                    details={{
                                        requestDate: moment(state?.details?.created_at).format("DD MMM YYYY hh:mm"),
                                        unit_name: state?.details?.unit_name,
                                        location: `${state?.details?.property_name ?? "-"}${state?.details?.block_name ? `, ${state?.details?.block_name}` : ""}${state?.details?.floor_name ? `, ${state?.details?.floor_name}` : ""}`,
                                        visit_date: state?.details?.visit_date,
                                        reference_id: state?.details?.reference_id,
                                        gate_no: state?.details?.gate_no

                                    }}
                                    status={state?.details?.status}
                                    t={t} />
                                <Typography className={classes.headTitle}>{t("GUEST DETAILS")}</Typography>
                                <GuestDetailCard
                                    details={state?.details}
                                    t={t}
                                    status={state?.details?.status}
                                    onSwipe={updateSwiftInOutUpdate}
                                />
                                {
                                    state?.details?.person_to_meet &&
                                <>
                                <Typography className={classes.headTitle}>{t("USER DETAILS")}</Typography>
                                <GuestDetailCard
                                    details={{
                                       person_name:state?.details?.trigger_name,
                                       vendor_image:state?.details?.trigger_image,
                                       mobile_no_country_code:state?.details?.mobile_no_country_code,
                                       mobile_no:state?.details?.mobile_no
                                    }}
                                    t={t}
                                /> 
                                </>
                                 }

{
                                    state?.details?.escort_person_image &&
                                <>
                                <Typography className={classes.headTitle}>{t("ESCORT DETAILS")}</Typography>
                                <GuestDetailCard
                                    details={{
                                       person_name:state?.details?.person_to_meet,
                                       vendor_image:state?.details?.escort_person_image,
                                       identification:state?.details?.identification,
                                       identification_no:state?.details?.identification_no

                                    }}
                                    t={t}
                                />
                                </>
                                 }

                                <Typography className={classes.headTitle}>{t("TRACK")}</Typography>
                                <Box className={classes.trackCard}>
                                    <Box sx={{ marginInlineStart: "14px" }}>
                                        {
                                            state?.details?.notes?.map((val, index) => {
                                                return <TrackCard details={val} showBorder={state?.details?.notes?.length - 1 !== index} paddingBottom={(state?.details?.notes?.length - 1 === index) ? "0px" : "28px"} />
                                            })
                                        }
                                    </Box>
                                </Box>

                            </Box>
                            {/* {
                                state?.details?.status !== "Rejected" &&
                                <Grid container p={1} className={classes.btnRoot}>
                                    <Grid item xs={state?.details?.status === "Approved" ? 6 : 4} p={1}>
                                        <Button
                                            disabled={disabled}
                                            onClick={() => updateSwiftInOutUpdate("revoke")}
                                            className={classes.revokeBtn} fullWidth variant="contained">
                                            {t("Revoke")}
                                        </Button>
                                    </Grid>
                                        <Grid item xs={state?.details?.status === "Approved" ? 6 : 4} p={1}>
                                            <Button
                                                onClick={goToEscort}
                                                disabled={disabled} className={classes.revokeBtn} fullWidth variant="outlined">
                                                {t("Escort")}
                                            </Button>
                                        </Grid>

                                    {
                                        state?.details?.status !== "Approved" &&
                                        <Grid item xs={4} p={1}>
                                            <Button
                                                onClick={() => updateSwiftInOutUpdate("send_video")}
                                                disabled={disabled} className={classes.revokeBtn} fullWidth variant="contained">
                                                {t("Send Call")}
                                            </Button>
                                        </Grid>
                                    }

                                </Grid>
                            } */}

                        </Box>
                }
            </Box>
        </Container>
    )
}
export default withNamespaces("entriesLog")(WalkInDetails)