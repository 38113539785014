import { Badge, Container, Stack, Box, Typography, Skeleton } from '@mui/material';
import React from 'react'
import { withNamespaces } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import NoDataFound from '../../components/noDataFound/noDataFound';
import { config } from '../../config';
import { AlertContext } from '../../contexts';
import { NetworkCall } from '../../networkcall';
import { Routes } from '../../router/routes';
import { AlertProps, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from '../../utils/useWindowDimensions'
import FeedbackListCard from './components/feedbackListCard';
import { useStyles } from './styles';

const FeedbackList = (props) => {
    const { t = () => false, handleTopBarState } = props;
    const classes = useStyles(props);
    const size = useWindowDimensions();
    const history = useHistory();
    const alert = React.useContext(AlertContext);
    const [offset, setOffset] = React.useState(0)
    const [feedbackList, setFeedbackList] = React.useState([]);
    const contact = localStorage.getItem("contactID")
    const [loading, setLoading] = React.useState(true)
    const goView = (val) => {
        history.push({
            pathname: Routes.feedbackForm,
            state: {
                data: val,
            },
        });
    }

    const GetFeedback = (offset = 0, limit = 10 , is_filter) => {
        const payload = {
            contact: [contact],
            start: offset,
            length: limit
        }
        NetworkCall(
            `${config?.api_url}/customer_feedback_replies`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if(is_filter){
                    setFeedbackList(feedbackList.concat(response?.data?.data));
                }
                else{
                    setFeedbackList(response?.data?.data);

                }
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };
    //fetch more data
    const fetchMoreData = () => {
        setOffset(offset + 10)
        GetFeedback(offset + 10, 10 , true)
    }
    React.useEffect(() => {
        handleTopBarState({
            text: t("Feedback")
        })
        GetFeedback(0, 10);
        // eslint-disable-next-line
    }, [])

    return (
        <Container className={classes.root} maxWidth="sm">
            {/* <TitleBar text={t("Feedback")} goBack={goBack} /> */}
            <div style={{ backgroundColor: "#F8F8F8" }}>
                <Stack
                    pt={1}
                    mb={1}
                    pr={2}
                    pl={2}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                >
                    <Typography className={classes.heading}>
                        {t("Sent")} ({feedbackList.length})
                        {/* {"(" + caseManagementList?.length + ")"} */}
                    </Typography>
                    <Box
                        className={classes.filterBlock}
                    // onClick={() => setOpenFilter(true)}
                    >
                        <Badge color="primary" variant="dot">
                            <img src="/images/funnel.svg" alt="" />
                        </Badge>
                    </Box>
                </Stack>
                <div
                    className={classes.mainPadding}
                    style={{
                        height: size?.height - 104, overflow: "overlay",
                    }}
                >
                    {loading ?
                        <>
                            <Box className={classes.listRoot}>
                                <Stack direction={"row"} spacing={2}>
                                    <Skeleton variant="circular" width={48} height={48} />
                                    <Stack direction={"column"}>
                                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                                    </Stack>
                                </Stack>
                            </Box>
                        </>
                        : <>
                            {feedbackList.length > 0 ?


                                <InfiniteScroll
                                    dataLength={feedbackList?.length ?? 10}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 180}
                                >
                                    {feedbackList.map((data) => (
                                        <>
                                            <Box className={classes.listRoot}>
                                                <FeedbackListCard t={t} data={data} goView={goView} />
                                            </Box >
                                            <Box height={"12px"} />
                                        </>
                                    ))}
                                </InfiniteScroll>
                                : <>
                                    <NoDataFound
                                        height={210}
                                        content={t("No Data Found!")}
                                    /></>
                            }
                        </>}
                </div>
            </div>
        </Container >
    )
}

export default withNamespaces("feedback")(FeedbackList)