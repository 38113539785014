import React from "react";
import { withBottombar } from "../../HOCs";
import AmenitiesDetailsOne from "./amenitiesDetailsOne";

class AmenitiesDetailsOneParent extends React.Component {
    render() {
        return <AmenitiesDetailsOne {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(AmenitiesDetailsOneParent, props);