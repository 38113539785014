
import { FontFamilySwitch } from "../../utils";
import makeStyles from "@mui/styles/makeStyles";

export const PieStyle = makeStyles((theme) => ({
    middileCount:{
        width: '36px',
        height: '16px',
        font: 'normal normal bold 12px/16px Nunito Sans',
        letterSpacing: '0px',
        color: 'text.primary',
    },
    tooltip: {
        backgroundColor: 'background?.secondary',
        border: `1px solid`,
        borderColor:"border.secondary",
        display: "flex",
        alignItems: "center",
        padding: "4px 7px",
        zIndex: 999,
        borderRadius: "4px",
        position: "relative",
      },
      root: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-20px",
        "& svg": {
          width: "100%",
          minWidth:  "165px !important",
          height:  "165px !important",
          position: "relative",
    
          cursor: "pointer",
          "& .recharts-wrapper": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      main:{
        margin:"0 auto"
      },
      center: {
        textAlign: "center",
        position: "absolute",
        right: "0px",
        zIndex: 1,
        width:"fit-content",
        padding: "12px",
        left:{
          xs:"49px",
          sm:"70px"
        },
        top:{
          xs:"24%",
          sm:"32%"
        },
      },
      title: {
        fontSize: "0.75rem",
        color: 'text?.secondary',
        fontFamily: FontFamilySwitch().semiBold,
      },
      count: {
        fontSize: "1.125rem",
        color: 'text?.main',
        fontFamily: FontFamilySwitch().bold,
      },
      dot: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        marginRight: "8px",
      },
      name: {
        color: "backgroundcard.primary",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
      },
      value: {  
        color: "text.Teritary",
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginRight: "8px",
      },
  }));

