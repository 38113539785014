import { Avatar, Box, Typography, Stack } from "@mui/material";
import React from "react";
import { propertyStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { generateImageUrl, img_size } from "../../utils";


export const PropertyListCard = (props) => {
  const { t=()=>false } = props;
  const classes = propertyStyles();
  const size = useWindowDimensions();
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      className={classes.card}
      onClick={() => props?.onClick()}
    >
      {props?.data?.asset_url ? (
        <img src={generateImageUrl(props?.data?.asset_url, img_size.small_square)} alt="" className={classes.image} />
      ) : (
        <Avatar className={classes.image} />
      )}
      <Box flexGrow={1}>
        <Typography className={classes.name} noWrap sx={{ maxWidth: size?.width - 155 }}>
          {props?.data?.property_name}
        </Typography>
        {/* <Stack direction={"row"} className={classes.details} divider={<Box className={classes.dot} />}>
          {props?.data?.area &&
            <Typography className={classes.sub}>
              {props?.data?.area}
            </Typography>}
          {props?.data?.city &&
            <Typography className={classes.sub}>
              {props?.data?.city}
            </Typography>}
          {props?.type === "management" &&
            <Typography className={classes.sub}>
              {props?.data?.country}
            </Typography>}
        </Stack> */}
        {/* <Box className={classes.details}></Box> */}
        {props?.neighbours && (
          <Box className={classes.details}>
            <Typography className={classes.residents}>
              {props?.data?.residents
                ? t("Tenants/Residents : ") + props?.data?.residents
                : ""}
            </Typography>
          </Box>
        )}
        {props?.normal && (
          <Box className={classes.details}>
            <Typography className={classes.sub}>
              {props?.data?.mobile_code ? props?.data?.mobile_code + " - " : ""}
              {props?.data?.mobile_no ?? ""}
            </Typography>
            {/* <Box className={classes.dot} />
            <Typography className={classes.sub}>
              {props?.data?.email ?? ""}
            </Typography> */}
          </Box>
        )}
        {props?.types === "emergency" &&
          props?.data?.emergency_contact_count &&
          <Box className={classes.details}>
            <Typography className={classes.sub}>
              {props?.data?.emergency_contact_count ?? ""} {t("Contacts")}
            </Typography>
          </Box>
        }

        {props?.committee && (
          <Box className={classes.details} >
            <Typography className={classes.sub}>
              {props?.data?.management_committee
                ? t("Committee Members :") + props?.data?.management_committee
                : ""}
            </Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
};
