export const RequestSelected = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 94903">
            <g data-name="Group 7570">
                <path data-name="Rectangle 49473" fill="none" d="M0 0h24v24H0z" />
            </g>
            <g data-name="Group 94890" fill="#5078e1">
                <path
                    data-name="Path 3346"
                    d="M19.183 6.381a.456.456 0 0 0-.712-.1l-1.87 1.86a1.291 1.291 0 0 1-1.017-1.017l1.859-1.859a.456.456 0 0 0-.112-.71 4.341 4.341 0 0 0-5.318.86 4.563 4.563 0 0 0-.99 4.98l-6.531 6.531a1.9 1.9 0 0 0 2.69 2.685l6.65-6.642a4.562 4.562 0 0 0 5.349-6.588Zm-1.369 4.791a3.65 3.65 0 0 1-3.924.833.457.457 0 0 0-.491.1l-6.844 6.844a.99.99 0 1 1-1.4-1.4c6.879-6.874 6.913-7.087 6.823-7.263a3.757 3.757 0 0 1 3.274-5.377 3.319 3.319 0 0 1 1.077.18l-1.544 1.552a.457.457 0 0 0-.132.342 2.206 2.206 0 0 0 2.1 2.1.456.456 0 0 0 .342-.132l1.549-1.549a3.554 3.554 0 0 1-.837 3.789Z"
                />
                <path
                    data-name="Path 3352"
                    d="m4.923 16.919-.311 1.73 1.488.692 7.3-7.474 2.491.277 2.353-1.245s.9-1.765.934-1.9a12.1 12.1 0 0 0-.554-1.591l-2.041 1.591-1.453-.484a11.2 11.2 0 0 1-.519-1.107c0-.1 1.073-1.626 1.073-1.626l.208-.623h-1.73l-1.453 1.038a10.286 10.286 0 0 1-.8 1.211c-.1.034-.208.588-.242.9s.1.416 0 .692a.865.865 0 0 0 0 .658c.069.311-.138-.1 0 .553s.1.173 0 .692l-6.435 6.5"
                    stroke="#5078e1"
                />
            </g>
        </g>
    </svg>
)