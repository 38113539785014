import React from "react";

function StatsClothing() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H21V18H0z"
            data-name="Rectangle 55527"
            transform="translate(81.699 184.39)"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 113454" transform="translate(-37 -308)">
        <rect
          width="42"
          height="42"
          fill="#ffe5dc"
          data-name="Rectangle 57716"
          rx="4"
          transform="translate(37 308)"
        ></rect>
        <g data-name="Group 113442" transform="translate(5 -523)">
          <g
            clipPath="url(#clip-path)"
            data-name="Group 105888"
            transform="translate(-38.699 658.61)"
          >
            <path
              fill="#f15a29"
              d="M7.09 5A2.094 2.094 0 005 7.092v11.816A2.094 2.094 0 007.09 21h4.882v-3.964l-5.249-6.864a.41.41 0 01.036-.539l.985-.985-.824-1.154A.409.409 0 016.936 7L8.57 5zm2.549 0l.34 1.231h1.409L12.4 7.713l-1.146 3.151 1.246 4.515 1.7-4.636-1.035-3.019 1-1.493h1.7L16.308 5zm7.786 0l1.634 2a.41.41 0 01-.027.55l-1.146 1.142.941.941a.41.41 0 01.02.558l-6.054 6.984V21h6.113A2.094 2.094 0 0021 18.908V7.092A2.094 2.094 0 0018.906 5zm-.5.689l-3.471 9.466 4.518-5.212-.961-.961a.41.41 0 010-.58l1.173-1.173zM9 5.771l-1.23 1.5.843 1.18a.41.41 0 01-.044.528l-.977.977L11.6 15.2zm6.253 11.947a.821.821 0 11-.821.821.821.821 0 01.822-.821z"
              transform="translate(79.201 180.391)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default StatsClothing;