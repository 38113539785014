import * as React from "react"

export const SuccessRequest = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={70} height={70} {...props}>
    <g data-name="Group 3727" transform="translate(-.494 -.494)">
      <circle
        data-name="Ellipse 2"
        cx={35}
        cy={35}
        r={35}
        transform="translate(.494 .494)"
        fill="#5ac782"
      />
      <path
        data-name="Path 944"
        d="m20.233 34.739 10.788 10.788 20.068-20.068"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={7}
      />
    </g>
  </svg>
)

