import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
// import { DoughnutChart } from "../../../components";
import { withNamespaces } from "react-i18next"
import { SelectBox } from "../../../components/dropDown"
import { Piechart } from "../../../components/pieChartNew"
import { revenueCardUseStyles } from "./style"


const RevenueCard = ({
    intervalOption = [],
    duration = {},
    setDuration = () => false,
    yearOption = [],
    monthOption = [],
    t = () => false,
}) => {

    const classes = revenueCardUseStyles()
    const [isMonthly, setIsMonthly] = React.useState(false);
    const [year, setYear] = React.useState(yearOption[0]);
    const [month, setMonth] = React.useState(monthOption[0]);

    const data = [
        { name: "PG-342546", value: 2000, fill: "#EE5188", data: "PG-342234" },
        { name: "PG-342234", value: 700, fill: "#A9ACFF", data: "PG-432240" },
        { name: "PG-432240", value: 450, fill: "#F49920", data: "PG-332236" },
        { name: "PG-332236", value: 250, fill: "#25C967", data: "PG-342546" },
    ];

    //HANDLE MONTH
    const handleYear = (value) => {
        setYear(value)
    }

    // HANDLE MONTH
    const handleMonth = (value) => {
        setMonth(value)
    }

    // HANDLE DURATION
    const handleDuration = (value) => {
        changeDuration(value)
    }

    const changeDuration = (i) => {
        if (i.value === "Monthly") {
            setDuration(intervalOption[1]);
            setIsMonthly(true);
        }
        else {
            setDuration(intervalOption[0]);
            setIsMonthly(false);
        }
    };

    return (
        <div className={classes.root}>
            <Stack
                className={classes.header_div}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Typography className={classes.heading}>{t("Revenue")}</Typography>
                <div className={classes.period_toggle_div}>
                    {intervalOption?.map((i) => {
                        return (
                            <div
                                className={
                                    duration?.value === i?.value
                                        ? classes.selected_button
                                        : classes.unselected_button
                                }
                                onClick={() => {
                                    handleDuration(i)
                                }}
                            >
                                <Typography className={classes.toggle_button_text}>
                                    {i?.label}
                                </Typography>
                            </div>
                        );
                    })}
                </div>
            </Stack>
            <div
                className={classes.content_div}>
                <Grid container>
                    {isMonthly ?
                        (<>
                            <Grid item xs={4} sm={3} pr={1}>
                                <SelectBox
                                    options={monthOption}
                                    value={month}
                                    onChange={(value) => {
                                        handleMonth(value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <SelectBox
                                    options={yearOption}
                                    value={year}
                                    onChange={(value) => {
                                        handleYear(value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3}></Grid>
                        </>)
                        : (<>
                            <Grid item xs={4} sm={3}>
                                <SelectBox
                                    options={yearOption}
                                    value={year}
                                    onChange={(value) => {
                                        handleYear(value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5} sm={6}></Grid>
                        </>)}
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                            <div style={{ position: "relative" }}>
                                {/* <DoughnutChart height="300px" width="300px" marginTop="-50px" marginBottom="-50px" /> */}
                                <Piechart data={data} />
                                <div className={classes.graph}>
                                    <Stack direction={"column"}>
                                        <Typography className={classes.total_amount}>{"$3K"}</Typography>
                                        <Typography className={classes.total}>{"Total"}</Typography>
                                    </Stack>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
};

export default withNamespaces("statistics")(RevenueCard);

