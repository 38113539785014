import React from "react";
import EmergencyNumberDetails from "./details";
import { withBottombar } from "../../HOCs";

class EmergencyNumberDetailsParent extends React.Component {
  render() {
    return <EmergencyNumberDetails {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(EmergencyNumberDetailsParent, props);
