import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAddOnStyles } from './style';
import { Box, Divider, Stack, Typography } from "@mui/material";
import { addOnData } from './addOnData';

export const UnitDrawer = (props) => {
    const { t=()=>false } = props

    const classes = useAddOnStyles()
    return (
        <>
            <Box>
                <Stack direction="row" className={classes.drawerHead}>
                    <Typography>{t("Change Unit")}</Typography>
                    <Box className={classes.closeIcon} onClick={() => props.handleClose()}>
                        <CloseIcon />
                    </Box>
                </Stack>
            </Box>
            <Divider />
            {
                addOnData?.map((e, i) => {
                    return (
                        <Box m={2} className={classes.drawerBox}>
                            <Stack p={1} direction="row" alignItems={"center"}
                                justifyContent={"space-between"}>
                                <Box className={classes.imgDiv}>
                                    <Box className={classes.svgBox}>{e?.img}</Box>
                                    <Box>
                                        <Box className={classes.unitdetails}>
                                            <Typography className={classes.unitName}>
                                                {e?.unit}

                                            </Typography>
                                            <span className={classes.unitId}>
                                                <Typography>{t("UNIT ID HERE")}</Typography>
                                            </span>
                                        </Box>
                                        <Typography className={classes.propertyName}>{e?.location}</Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.closeIcon} onClick={() => props?.handleUnitChange(e, i)}>
                                    <ChevronRightIcon />
                                </Box>
                            </Stack>
                        </Box>
                    )
                })
            }
        </>
    )
}