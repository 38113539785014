import React from "react";

function Education() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113444" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#f6f1fe"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#896db3"
          d="M13.9 11.9a3.951 3.951 0 113.951-3.951A3.956 3.956 0 0113.9 11.9zm6.585 3.535v-1.557a1.537 1.537 0 00-1.537-1.537h-2.411a2.2 2.2 0 00-2.2 2.2V19.8a.439.439 0 01-.439.439.439.439 0 01-.439-.439v-5.263a2.2 2.2 0 00-2.2-2.2H8.854a1.537 1.537 0 00-1.537 1.537v1.559A1.534 1.534 0 006 16.951v1.317a1.534 1.534 0 001.337 1.516 1.534 1.534 0 001.516 1.337h3.541a1.734 1.734 0 003.016 0h3.541a1.534 1.534 0 001.516-1.337 1.534 1.534 0 001.333-1.516v-1.317a1.534 1.534 0 00-1.312-1.514zM7.317 16.951a.22.22 0 01.22-.22h.439a.22.22 0 01.22.22v1.317a.22.22 0 01-.22.22h-.439a.22.22 0 01-.22-.22zm13.171 1.317a.22.22 0 01-.22.22h-.439a.22.22 0 01-.22-.22v-1.317a.22.22 0 01.22-.22h.439a.22.22 0 01.22.22z"
          transform="translate(44 244)"
        ></path>
      </g>
    </svg>
  );
}

export default Education;