import React from "react";
import { withBottombar } from "../../HOCs";
import Agreementparking from "./agreementparking";

class AgreementParkingParent extends React.Component {
  render() {
    return <Agreementparking {...this.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(AgreementParkingParent, props);
