import React from 'react'
import { useLocation } from 'react-router';
import PassDetails from '../workerGatepass/passDetails';

const VisitorGatePassDetails = () => {
const searchURL = useLocation().search;
const passId = new URLSearchParams(searchURL).get("passId");
  return (
     <PassDetails passId={passId} />
  )
}

export default VisitorGatePassDetails;