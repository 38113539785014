import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { AlertProps, allowed_file_size, generateImageUrl, img_size, singleFileUpload } from "../../utils";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { DialogDrawer } from "../dialogDrawer";
import ImageAndPdfViewer from "../assetViewer/imageAndPdfViewer";
import { PDFImage } from "../../assets";

function BulkImageUpload(props) {
  const classes = useStyles();
  const { setBulkUrl, bulkUrl, setDeletedImage } = props;
  const alert = React.useContext(AlertContext);
  const [imageViewer, setImageViewer] = React.useState(false);

  const deleteImage = (deleteData, index) => {
    const data = bulkUrl.slice();
    data.splice(index, 1);
    setBulkUrl(data);
    //store deleted image
    if (deleteData?.occupants_asset_id) {
      setDeletedImage((data) => [...data, deleteData]);
    }
  };


  const uploadS3 = async (data) => {
    let uploaded_file = await singleFileUpload(data, { tenantId: `${config.tenantid}` }, alert, allowed_file_size)
    if (uploaded_file?.[0]?.url) {
      setBulkUrl([
        ...bulkUrl,
        {
          meta_data: {
            file_name: data?.name.split(".")?.[0],
            file_size: data?.size,
            file_ext: data?.name.split(".")?.[1],
            file_type: data?.type.split("/")?.[0],
          },
          file_url: uploaded_file?.[0]?.url,
        },
      ]);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went wrong",
      });
    }
  };
  return (
    <Box className={classes.root}>
      <Box className={[classes.container, classes.uploadContainer]}>
        <input
          className={classes.uploadInput}
          type="file"
          name="img"
          accept="image/*, application/pdf"
          onChange={(value) => {
            uploadS3(value.target.files[0]);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
          id="uplodebtn"
        />
        <img src="/images/upload.svg" alt="" />
        <Typography className={classes.uplaodTitle}>Upload</Typography>
      </Box>
      {bulkUrl?.map((data, index) => (
        <Box key={index} className={classes.container}>
          {["image","image/jpeg", "image/png", "image/svg","jpg", "svg", "jpeg", "png"].includes(data?.meta_data?.file_type)  ? (
            <Box position={"relative"}>
              <img
                className={classes.deleteImg}
                src="/images/deleteImg.svg"
                alt=""
                onClick={() => deleteImage(data, index)}
              />
              <img
                className={classes.uploadedImg}
                onClick={() => setImageViewer(true)}
                src={generateImageUrl(data.file_url, img_size.small_square)}
                alt=""
              />
            </Box>
          ) : (
            <Box position={"relative"}>
              <PDFImage height={70} onClick={() => setImageViewer(true)} />
              <img
                onClick={() => deleteImage(data, index)}
                className={classes.deleteImg}
                src="/images/deleteImg.svg"
                alt=""
              />
            </Box>
          )}
        </Box>
      ))}
      <DialogDrawer
        open={imageViewer}
        onClose={() => setImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            assets={bulkUrl}
            onClose={() => setImageViewer(false)}
          />
        }
      />
    </Box>
  );
}

export default BulkImageUpload;
