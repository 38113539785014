import * as React from "react"

const Time = (props) => (
  <svg
    data-name="Group 96860"
    xmlns="http://www.w3.org/2000/svg"
    width={23.82}
    height={32}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 53087" fill="none" d="M0 0h23.82v32H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 96858" clipPath="url(#a)">
      <path
        data-name="Path 9183"
        d="M1.544 32c-.06-.1-.174-.1-.267-.135a1.986 1.986 0 0 1 .227-3.78 8.786 8.786 0 0 1 1.519-.082.667.667 0 0 1 .447-.1h15.051a1.871 1.871 0 0 1 .342.023c.486.1.982 0 1.469.066a2.081 2.081 0 0 1 1.62 1.762 1.981 1.981 0 0 1-1.648 2.215.119.119 0 0 0-.084.031Z"
        fill="#b8807f"
      />
      <path
        data-name="Path 9184"
        d="M20.219 32a2.274 2.274 0 0 0 1.345-.926 1.967 1.967 0 0 0-.969-2.917c-.065-.024-.148-.021-.188-.1a.341.341 0 0 1 .4-.064 12.854 12.854 0 0 1 1.368.059 1.983 1.983 0 0 1 .082 3.89.488.488 0 0 0-.1.054Z"
        fill="#b8807f"
      />
      <path
        data-name="Path 9185"
        d="M18.906 28.01H3.24c-.073 0-.145-.006-.218-.009a31.164 31.164 0 0 1 .084-3.5 4.422 4.422 0 0 1 4.25-3.527 6.823 6.823 0 0 0 1.525-.063 1.681 1.681 0 0 0 1.408-1.222 12.874 12.874 0 0 0 .47-2.889 1.722 1.722 0 0 0-1.279-1.752 2.168 2.168 0 0 0-.5-.159 8.886 8.886 0 0 1-4.97-4.324 1.075 1.075 0 0 1-.194-.483c.162-.164.371-.112.562-.112q5.542-.005 11.084 0c.895 0 1.79-.014 2.685.008.492-.014.984-.008 1.476 0 .14 0 .292-.019.4.112 0 .044.008.092-.042.119-.478.03-.957.021-1.434.006a.558.558 0 0 0-.579.362 8.751 8.751 0 0 1-4.72 4.279 1.987 1.987 0 0 1-.451.118 1.844 1.844 0 0 0-1.646 2.223c.107.667.211 1.335.331 2a1.824 1.824 0 0 0 1.792 1.754 6.1 6.1 0 0 0 1.771-.018 10.339 10.339 0 0 1 1.153-.017.325.325 0 0 1 .282.1c-.017.153-.146.126-.237.132a3.055 3.055 0 0 1-.864-.046 3.957 3.957 0 0 1 2.253 1.209 4.3 4.3 0 0 1 1.253 2.359 17.53 17.53 0 0 1 .108 3.145.24.24 0 0 1-.081.2"
        fill="#fdd302"
      />
      <path
        data-name="Path 9186"
        d="M1.667 3.965A1.951 1.951 0 0 1 .015 1.932 1.959 1.959 0 0 1 2.007.021q9.046-.006 18.093 0c.041 0 .083.007.124.01a1.957 1.957 0 0 1 1.745 1.839 2.058 2.058 0 0 1-1.372 2.076 2 2 0 0 1-.3.09 3.387 3.387 0 0 1-.531.021c-5.4 0-10.8.009-16.207-.011-.629 0-1.262-.049-1.894-.08"
        fill="#b87f7f"
      />
      <path
        data-name="Path 9187"
        d="M18.154 10.071c-.093.009-.185.026-.278.026H3.968c-.052 0-.1-.006-.155-.009a4.644 4.644 0 0 1-.518-1.376 12.126 12.126 0 0 1-.253-3.445.52.52 0 0 1 .412-.1c4.705 0 9.41.006 14.115-.01a1.4 1.4 0 0 1 1.389 1.4 9.377 9.377 0 0 1-.541 3.057.855.855 0 0 1-.263.451"
        fill="#eeecee"
      />
      <path
        data-name="Path 9188"
        d="M18.154 10.071a12.913 12.913 0 0 0 .6-2.056 7.661 7.661 0 0 0 .084-1.8 1.224 1.224 0 0 0-1.06-.949H3.042a1.5 1.5 0 0 1-.055-.677c-.008-.568 0-.561-.584-.572a1.662 1.662 0 0 1-.735-.053h14.217q2.26 0 4.521-.006c.138-.043.275-.084.378.067 0 1.05.036 2.1-.014 3.149a6.9 6.9 0 0 1-.753 2.919l-1.862-.019"
        fill="#eeecee"
      />
      <path
        data-name="Path 9189"
        d="M8.978 14.895a1.925 1.925 0 0 1 1.854 2.348l-.35 2.056a1.949 1.949 0 0 1-2.037 1.753 8.161 8.161 0 0 0-1.985.114 4.429 4.429 0 0 0-3.3 3.2 1.456 1.456 0 0 1-.058.141 6.857 6.857 0 0 1 .622-2.486 8.507 8.507 0 0 1 1.821-2.728 8.818 8.818 0 0 1 3.321-2.135 1.2 1.2 0 0 0 .621-1.882 1.426 1.426 0 0 0-.512-.383"
        fill="#eeecee"
      />
      <path
        data-name="Path 9190"
        d="M14.973 21.019a7.482 7.482 0 0 1-1.79.007 1.892 1.892 0 0 1-1.324-.667 2.289 2.289 0 0 1-.472-1.194c-.116-.754-.273-1.5-.349-2.262a1.882 1.882 0 0 1 1.559-1.98 4.868 4.868 0 0 0 1.62-.6 8.681 8.681 0 0 0 3.738-3.91.412.412 0 0 1 .453-.28c.426.017.853 0 1.279.006.1 0 .212-.032.289.07a8.359 8.359 0 0 1-2.449 3.181 8 8 0 0 1-2.74 1.51 6.563 6.563 0 0 0-.861.332 1.893 1.893 0 0 0-.831 2.1c.107.666.215 1.332.335 2a1.839 1.839 0 0 0 1.312 1.55.345.345 0 0 1 .231.144"
        fill="#fdd302"
      />
      <path
        data-name="Path 9191"
        d="M18.906 28.01c0-.738.006-1.476 0-2.215a4.606 4.606 0 0 0-2.3-4.174 6.764 6.764 0 0 0-1.378-.564h1.261c.248-.149.5-.046.748-.006a3.072 3.072 0 0 1 .506.132 4.668 4.668 0 0 1 2.59 2.158 3.65 3.65 0 0 1 .384 1.166 32.4 32.4 0 0 1 .091 3.487l-.4.064a13.419 13.419 0 0 1-1.5-.05"
        fill="#fdd302"
      />
      <path
        data-name="Path 9192"
        d="M20.719 24.509a4.824 4.824 0 0 0-1.427-2.35 5.6 5.6 0 0 0-1.51-.9.963.963 0 0 1-.26-.305 8.711 8.711 0 0 0-4.133-3.568.551.551 0 0 0-.361-.061 1.9 1.9 0 0 1 1.76-2.43 1.217 1.217 0 0 0-.692 1.1 1.161 1.161 0 0 0 .772 1.132 8.876 8.876 0 0 1 5.723 6.463 2.945 2.945 0 0 1 .127.919"
        fill="#eeecee"
      />
      <path
        data-name="Path 9193"
        d="m20.784 4.022-.378-.067a2.258 2.258 0 0 0 1.115-.8A1.982 1.982 0 0 0 20.394.089c-.059-.016-.126 0-.17-.059a10.333 10.333 0 0 1 1.991.04 1.941 1.941 0 0 1 1.591 2.048 2 2 0 0 1-1.961 1.891c-.353.013-.707.009-1.061.013"
        fill="#b87f7f"
      />
      <path
        data-name="Path 9194"
        d="M13.028 17.329c.029-.158.119-.131.23-.084a9.593 9.593 0 0 1 2.647 1.65 9 9 0 0 1 1.877 2.368 4.853 4.853 0 0 0-1.291-.2 12.731 12.731 0 0 0-1.518-.04 1.93 1.93 0 0 1-1.639-1.753c-.093-.647-.2-1.291-.306-1.937"
        fill="#eeecee"
      />
    </g>
  </svg>
)

export default Time;
