import React from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { withBottombar } from "../../HOCs";
import RequestHomeScreen from "./requestHome";
import RequestFromAgreement from "./requsetFromAgreement";
const RequestHomeScreenParent = (props) => {
  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get("Id");
  return requestId ? <RequestFromAgreement {...props} /> : <RequestHomeScreen {...props}  />;
};

const props = {
  top_bar: "top_nav_bar",
  bottom_bar: "bottom_nav_bar"
}

export default withBottombar(withNamespaces("units")(RequestHomeScreenParent), props);
