export const CommunitySelected = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <defs>
            <style>{".cls-1{fill:none}"}</style>
        </defs>
        <g
            id="Group_7567"
            data-name="Group 7567"
            transform="translate(-21984 7260)"
        >
            <path
                id="icons8-submit-idea_1_"
                data-name="icons8-submit-idea (1)"
                className="cls-1"
                d="M13.385 4a.614.614 0 0 0-.385.131L7.173 8.726A3.059 3.059 0 0 0 6 11.143v7.832A1.027 1.027 0 0 0 7.026 20h12.718a1.027 1.027 0 0 0 1.026-1.026v-7.831a3.059 3.059 0 0 0-1.17-2.417l-5.835-4.595a.614.614 0 0 0-.38-.131Zm-.371 4.923h.686a1.917 1.917 0 0 1 1.882 1.51l.359 1.685a3.489 3.489 0 1 1-5.168 0l.356-1.675a1.922 1.922 0 0 1 1.885-1.52Zm0 .821a1.113 1.113 0 0 0-1.081.868l-.4 1.9c0 .015-.007.016 0 .01a2.663 2.663 0 0 0 1.83 4.6 2.663 2.663 0 0 0 1.831-4.6l-.405-1.906a1.114 1.114 0 0 0-1.089-.872h-.687ZM9.077 11.8a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm3.862 0h.837l.209.984a1.245 1.245 0 0 0 .357.635 1.436 1.436 0 1 1-1.97 0 1.248 1.248 0 0 0 .357-.636Zm4.753 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm-9.436 2.047a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm10.256 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .616-.615ZM9.077 15.9a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm8.615 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Z"
                transform="translate(21982.615 -7260)"
            />
            <path
                id="Rectangle_49471"
                data-name="Rectangle 49471"
                className="cls-1"
                transform="translate(21984 -7260)"
                d="M0 0h24v24H0z"
            />
            <path
                id="icons8-people_2_"
                data-name="icons8-people (2)"
                d="M14.45 6a3.1 3.1 0 0 0-2.35 1.008 3.358 3.358 0 0 0 0 4.317 3.241 3.241 0 0 0 4.7 0 3.358 3.358 0 0 0 0-4.317A3.1 3.1 0 0 0 14.45 6ZM7.658 7.056A2.6 2.6 0 0 0 5.7 7.9a2.788 2.788 0 0 0 0 3.591 2.6 2.6 0 0 0 1.957.843 2.6 2.6 0 0 0 1.957-.843 2.788 2.788 0 0 0 0-3.591 2.6 2.6 0 0 0-1.956-.844Zm13.585 0a2.6 2.6 0 0 0-1.957.843 2.788 2.788 0 0 0 0 3.591 2.693 2.693 0 0 0 3.915 0 2.788 2.788 0 0 0 0-3.591 2.6 2.6 0 0 0-1.959-.843ZM5.829 13.389A1.84 1.84 0 0 0 4 15.236v3.431a4.207 4.207 0 0 0 4.18 4.222 4.046 4.046 0 0 0 1.077-.142c-.219-.475-.256-8.92.1-9.358Zm5.747 0a1.85 1.85 0 0 0-1.829 1.847v5.014a4.7 4.7 0 1 0 9.4 0v-5.014a1.85 1.85 0 0 0-1.829-1.847Zm7.963 0c.361.438.324 8.883.1 9.358a4.046 4.046 0 0 0 1.077.142 4.207 4.207 0 0 0 4.18-4.222v-3.431a1.84 1.84 0 0 0-1.829-1.847Z"
                transform="translate(21981 -7263)"
                style={{
                    fill: "#5078e1",
                    stroke: "#fff",
                    strokeMiterlimit: 10,
                    strokeWidth: ".2px",
                }}
            />
        </g>
    </svg>
)