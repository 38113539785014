import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const ammenitiesBookingStyles = makeStyles((theme) => ({
    root: {
        padding: "0px",
        backgroundColor: "#F6F6F6"
    },
    tabroot: {
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        backgroundColor: "white",
        marginBottom: "20px"
    },
    tabSwitchRoot: {
        padding: "1px 16px",
    },
    tabItem: {
        textTransform: "capitalize",
        fontWeight: "bold",
        color: "#ffffff",
        padding: "0px",
        minHeight: "auto",
        borderRadius: "4px",
        "& .MuiButtonBase-root-MuiTab-root": {
            borderRadius: "4px",
        },
        minWidth: "auto",
        // border: "1px solid #E4E8EE",
        marginRight: "8px",
        backgroundColor: "white",
    },
    tabItemSelect: {
        textTransform: "capitalize",
        fontWeight: "bold",
        color: "white",
        marginRight: "8px",
        padding: "0px",
        borderRadius: "4px",
        minHeight: "auto",
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: "0px",
        },
        minWidth: "auto",
        backgroundColor: theme.palette.background.primary,
    },

    title: {
        padding: "8px 14px",
        borderRadius: "4px",
        background: theme.palette.background.primary,
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: theme.typography.color.Tertiary,
    },
    titleselect: {
        padding: "8px 14px",
        borderRadius: "50px",
        backgroundColor: theme.typography.color.Tertiary,
        display: "flex",
        alignItems: "center",
        color: "white",
    },
    unselectedButton: {
        color: theme.typography.color.primary,
        backgroundColor: "#ffffff",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        borderRadius: "18px",
        textTransform: "initial",
        cursor: "pointer",
        '&:hover': {
            color: theme.typography.color.primary,
            backgroundColor: "#ffffff",
        }
    },
    selectedButton: {
        color: "#ffffff",
        backgroundColor: theme.palette.secondary.main,
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        borderRadius: "18px",
        textTransform: "initial",
        cursor: "pointer",
        '&:hover': {
            color: "#ffffff",
            backgroundColor: theme.palette.background.primary,
        }
    },
    amenityCard: {
        // backgroundColor: "white",
        cursor: "pointer",
        width: "100%",
        borderRadius: 12,
        background: "#fff",
        padding: "14px"
        // // height: "134px",
        // '@media (max-width: 426px)': {
        //     width: "112px !important",
        //     height: "134px !important",
        // },
        // '@media (max-width: 376px)': {
        //     width: "100px !important",
        //     height: "134px !important",
        // },
        // '@media (max-width: 321px)': {
        //     width: "88px !important",
        //     height: "134px !important",
        // },
    },
    labels: {
        color: theme.typography.color.primary,
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        fontWeight: 600
    },
    labels1: {
        color: theme.typography.color.Tertiary,
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
    },
    amenityStack: {
        width: "100%",
        height: "120px",
        '@media (max-width: 426px)': {
            height: "85px !important",
        },
        '@media (max-width: 376px)': {
            height: "88px !important",
        },
        '@media (max-width: 321px)': {
            height: "88px !important",
        },
    },
    amenityStackAvatar: {
        // width: "100%",
        // height: "100%",
        borderRadius: "8px",
        width: "64px",
        height: "64px",
        // '@media (max-width: 426px)': {
        //     height: "85px !important",
        // },
        // '@media (max-width: 376px)': {
        //     height: "88px !important",
        // },
        // '@media (max-width: 321px)': {
        //     height: "88px !important",
        // },
    },
    textStack: {
        padding: "10px 8px",
        alignItems: "center",
        height: "50px",
        backgroundColor: "#fff",
        borderRadius: "0px 0px 8px 8px"
    },
    arrow: {
        color: "#CED3DD",
        fontSize: "1.125rem"
    },
    stackTitle: {
        color: "#091B29",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
    },
    pastBookings: {
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "14px",
        marginBottom: "20px"

    },
    pastAvat: {
        width: "64px",
        height: "64px",
        margin: "auto",
        cursor: "pointer"
    },
    pastTitle: {
        color: "#091B29",
        fontSize: "0.75rem",
        textAlign: "center",
        fontFamily: FontFamilySwitch().semiBold,
        // fontFamily: FontFamilySwitch().bold,
        width: "100px",
        paddingTop: "8px",
        height: "100%"

    },
    pastAmmenityStack: {
        marginTop: "12px",

    },
    noAmenitySvg: {
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",

    },
    categoryText: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold
    },
    amenityNameText: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        '@media (max-width: 426px)': {
            width: "270px !important",
        },
        '@media (max-width: 376px)': {
            width: "220px !important",
        },
        '@media (max-width: 321px)': {
            width: "170px !important",
        },
    },
    availableText: {
        fontSize: "0.75rem",
        color: theme.palette.success.main,
        fontFamily: FontFamilySwitch().semiBold
    }


}));