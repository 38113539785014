import React from "react";

function StocksAndEquities() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113455" transform="translate(-37.5 -162)">
        <rect
          width="42"
          height="42"
          fill="#ffecf2"
          data-name="Rectangle 57714"
          rx="4"
          transform="translate(37.5 162)"
        ></rect>
        <g data-name="Group 113440" transform="translate(6 -521)">
          <path
            fill="#d98392"
            d="M6.059 7a1.059 1.059 0 100 2.118H14A1.059 1.059 0 1014 7zm0 3.176a1.059 1.059 0 100 2.118H14a1.059 1.059 0 100-2.118zm0 3.176a1.059 1.059 0 100 2.118H14a1.059 1.059 0 100-2.118zm11.647 0a1.059 1.059 0 100 2.118h6.353a1.059 1.059 0 100-2.118zM6.059 16.529a1.059 1.059 0 100 2.118H14a1.059 1.059 0 100-2.118zm11.647 0a1.059 1.059 0 100 2.118h6.353a1.059 1.059 0 100-2.118zM6.059 19.706a1.059 1.059 0 100 2.118H14a1.059 1.059 0 100-2.118zm11.647 0a1.059 1.059 0 100 2.118h6.353a1.059 1.059 0 100-2.118zM6.059 22.882a1.059 1.059 0 100 2.118H14a1.059 1.059 0 100-2.118zm11.647 0a1.059 1.059 0 100 2.118h6.353a1.059 1.059 0 100-2.118z"
            data-name="icons8-stack-of-coins (1)"
            transform="translate(37.941 688)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default StocksAndEquities;