import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  list: {
    justifyContent: "space-between",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: theme?.palette?.primary?.contrastText,
    marginBottom: "8px",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  Tittle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.palette?.secondary?.main,
  },
  cardTittle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.palette?.secondary?.main,
  },
  lease: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },

  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  box: {
    display: "flex",
    marginTop: "5px",
    alignItems: "center",
  },
  agreementImg: {
    padding: "14px 14px 7px 14px",
    backgroundColor: "#F5F7FA",
    borderRadius: "8px",
  },
  main: {
    padding: "6px 16px 1px 16px" ,
  },
  upcoming: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.palette?.primary?.contrastText,
    backgroundColor: theme?.palette?.success?.dark,
    padding: "2px 4px",
    marginLeft: "10px",
    borderRadius: theme.palette.borderRadius,
    textTransform:"uppercase"
  },
  expired: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.palette?.primary?.contrastText,
    backgroundColor: theme?.palette?.error?.dark,
    padding: "2px 4px",
    borderRadius: theme.palette.borderRadius,
    textTransform:"uppercase"
  },
  filterBlock: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "5px 10px",
    boxShadow: "#4354721F 0px 3px 30px",
    border: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  downlaodImg: {
    borderRadius: "50%",
    border: "1px solid #E4E8EE",
  },
  bottomBorder: {
    borderBottom: "1px solid #E4E8EE",
  },
  propertyBlock: {
    padding: "12px",
  },
  agreementBlock: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    padding: "12px",
  },
}));
