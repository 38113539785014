import { Box, Stack, IconButton, Avatar, Typography, Grid ,Button} from "@mui/material"
import { Micoff, Micon} from "../../assets";
import { TitleBar } from "../../components"
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";



const VideoCall = ({ goBack = () => false, controls = {}, accessControl = () => false, details = {}, partnerControl = {}, t = () => false,partnerAccept=false,changeStatusTrigger=()=>false }) => {

    const size = useWindowDimensions()
    const classes = useStyles(size);

    const handleAction = (type) => {

        const payload = {
            is_swift_in: true,
            is_walk_in: true,
            swift_in_param: {
                id: details?.id,
                status: type,
                updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                updated_at: new Date()
            },
            walk_in_param: {
                status: type,
                request_id: details?.id,
                created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                agreement_unit_user: details?.notes?.[0]?.agreement_unit_user,
                security_guard_id: details?.created_by,
                message: type

            }
        }
        NetworkCall(
            `${config.api_url}/walkInout/upsert_walk_in_log`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            goBack()
            changeStatusTrigger(payload)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Box class={classes.root} style={{position:"relative"}}>
            <Box position={"absolute"} top="0px" left="0px" right="0px" style={{zIndex:1}}>
            <TitleBar goBack={goBack} transparent color="transparent" text="Video Call" />
            </Box>


            <Box height={size?.height - 228} style={{ width: "100%" }}> 

                {
                    !partnerControl?.video &&
                    <center style={{ paddingTop: "20vh" }}>
                        <Avatar src={details?.notes?.[0]?.guard_logo ?? "/images/security_logo.png"} style={{ height: 150, width: 150,borderRadius:"12px" }} />
                         {
                            !partnerAccept &&    
                            <>
                            <Typography className={classes.callingTitle}>Calling Security guard</Typography>
                            <Typography className={classes.callingWait}>Please Wait</Typography>
                            </>
                         }
                       
                    </center>
                }

                <video id="partner-video" autoPlay playsInline 
                style={{ height: partnerControl?.video ? "100%" : "0px" }}
                />
            </Box>

            <Box className={classes.videoBottomRoot} padding="12px" >
                <Stack direction="row" justifyContent="center"  >
                    <IconButton
                        onClick={() =>
                            accessControl({ audio: !controls?.audio, video: false })
                        }
                    >
                        {controls?.audio ? <Micon /> : <Micoff />}
                    </IconButton>
                </Stack>

                <Box className={classes.guestDetailRoot} padding="12px" mt={1}>

                    <Box display="flex" p={2} >
                        <Box>
                            <Avatar src={details?.vendor_image} sx={{ height: 50, width: 50 }} />
                        </Box>
                        <Box sx={{ marginInlineStart: "10px" }}>
                            <Typography className={classes.guestName}>{details?.person_name}</Typography>
                            {details?.mobile_no &&
                                <Typography className={classes.guestMobile}>{details?.mobile_no_country_code} {details?.mobile_no}</Typography>
                            }
                            <Typography className={classes.reason}>
                                Reason: {details?.reason}
                            </Typography>
                        </Box>
                    </Box>


                    <Grid container spacing={1}>
                        <Grid item xs={6} onClick={() => handleAction("Rejected")} >
                            <Button className={classes.btnCard} style={{backgroundColor:"#FF4B4B"}} fullWidth> Do Not Allow</Button>
                        </Grid>
                        <Grid item xs={6} onClick={() => handleAction("Approved")} >
                            <Button className={classes.btnCard} style={{backgroundColor:"#5AC782"}} fullWidth>Allow</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </Box>
    )
}
export default VideoCall