import { useStyles } from "./style";
import { Avatar, Typography, Box, IconButton, Stack } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { generateImageUrl, img_size } from "../../../utils";

export const AmenitiesBookingCard = (props) => {
  const classes = useStyles();
  const language = localStorage.getItem("i18nextLng")
  return (
    <div className={classes.card} onClick={() => props?.onClick()}>
      <Stack direction={"row"} alignItems="center">
        {props?.data?.asset_url ? (
          <img src={generateImageUrl(props?.data?.asset_url, img_size.small_square)} alt="" className={classes.image} />
        ) : (
          <Avatar className={classes.image} />
        )}
        <Box flexGrow={1}>
          <Typography className={classes.name}>
            {props?.data?.property_name}
          </Typography>
        </Box>
        <IconButton className={classes.iconButton}>
          {language === "ar" ?
            <KeyboardArrowLeftIcon fontSize="small" /> :
            <KeyboardArrowRightIcon fontSize="small" />
          }
        </IconButton>
      </Stack>
    </div>
  );
};

export default AmenitiesBookingCard;
