import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const serviceStyles = makeStyles((theme) => ({

    main: {
        fontFamily: FontFamilySwitch().bold,
        fontSize: "1rem",
        color: "white",

    },
    sub: {
        fontSize: "0.875rem",
        color: "white",
        margin: "12px 0px"
    },
    btn: {
        backgroundColor: "white",
        padding: "12px",
        color: theme.palette.primary.main,
        textTransform: "none",
        "&:hover": {
            backgroundColor: "white",

        }
    },
    grid: {
        textAlignLast: "center"
    }

}))