import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyles } from "./styles";

const NoDataFound = (props) => {
  const classes = useStyles();
  const {
    content = "No Data Found!",
    styles = {},
    height = "40px",
    boxShadow,
    borderRadius,
    backgroundColor,
  } = props;
  return (
    <Box
      className={classes.container}
      sx={{
        height: height,
        borderRadius: borderRadius ? borderRadius : "8px",
        boxShadow: boxShadow ?  "0px 3px 30px #4354721f" :"none",
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
      }}
    >
      <Typography
        className={!styles && classes.noDataText}
        component={"span"}
        sx={{ ...styles }}
      >
        {content}
      </Typography>
    </Box>
  );
};

export default NoDataFound;
