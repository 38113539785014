import { Box, Button } from "@mui/material"
import {TextBox} from "../../../components"
import React from "react";
import {componentStyles} from "./styles"
export const StatusForm=({t=()=>false,updateRequestStatus=()=>false})=>{
    const [remarks,setRemarks]=React.useState("")
    const classes=componentStyles()
    const onClick=()=>{
        updateRequestStatus(remarks)
        setRemarks("")
    }
    return (
        <Box>
            <TextBox
            value={remarks}
            onChange={(e)=>setRemarks(e.target.value)}
            label={t("Remarks (optional)")}
            multiline
            />
            <Button 
            onClick={onClick}
            className={classes.submitFormBtn}
            fullWidth
            disabled={remarks?.length===0}
            variant="contained"
            >{t("Submit")}</Button>
        </Box>
    )
}