export const TermsCondition = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 108907" transform="translate(-108 -267)">
            <rect
                width={40}
                height={40}
                fill="#f1f7ff"
                data-name="Rectangle 55935"
                rx={4}
                transform="translate(108 267)"
            />
            <path
                fill="#6a68cc"
                stroke="#6a68cc"
                strokeLinecap="round"
                strokeWidth={0.2}
                d="M120.75 277.5a2.253 2.253 0 0 0-2.25 2.25v15.5a2.253 2.253 0 0 0 2.25 2.25h11.5a2.253 2.253 0 0 0 2.25-2.25v-5.155l-1.5 1.5v3.655a.749.749 0 0 1-.75.75h-11.5a.749.749 0 0 1-.75-.75v-15.5a.749.749 0 0 1 .75-.75h5.75v4.25a2.253 2.253 0 0 0 2.25 2.25h1.155l1.5-1.5h-2.655a.749.749 0 0 1-.75-.75v-3.189l3.675 3.675.735-.74a3.217 3.217 0 0 1 .345-.3l-4.975-4.976a.744.744 0 0 0-.53-.22Zm15.73 4.99a1 1 0 0 0-.687.3l-.331.331a2.208 2.208 0 0 0-.966-.1 2.373 2.373 0 0 0-1.377.676h0l-4.783 4.784a1.749 1.749 0 0 0-.436.723h0l-.866 2.823a.75.75 0 0 0 .937.937l2.823-.866h0a1.749 1.749 0 0 0 .723-.436l4.824-4.824a2.261 2.261 0 0 0 .532-2.3l.334-.334a1 1 0 0 0-.727-1.714Zm-1.813 2.025a.7.7 0 0 1 .492.1.883.883 0 0 1 .118.1l.033.032a.725.725 0 0 1-.03 1.029l-4.824 4.824a.251.251 0 0 1-.1.063l-1.471.451.451-1.471a.251.251 0 0 1 .063-.1l4.781-4.783a.86.86 0 0 1 .487-.244ZM123.25 288a.75.75 0 0 0 0 1.5h3.515l.181-.585a2.644 2.644 0 0 1 .484-.915Zm0 3.5a.75.75 0 0 0 0 1.5h2.92a1.752 1.752 0 0 1-.095-1.265l.075-.235Z"
            />
        </g>
    </svg>
);