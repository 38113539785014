import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalculate } from "../../utils";

export const detailViewStyle = makeStyles((theme) => ({
    notificationroot: {
        padding: "0px 8px 8px 12px",
    },
    root: {
        height: "100%",
        overflow: "auto"
    },
    boxSection: {
        width: "100%",
        padding: "0px 16px",
        height: "calc(100vh - 57px)",
        overflow: "auto"
    },


    managerCardBox: {
        marginTop: "16px",
        backgroundColor: "#fff",
        padding: "16px 0px",
        borderRadius: theme.palette.borderRadius,
        border: "1px solid #E4E8EE",
    },

    managerCard: {
        marginBottom: "16px",
        padding: "0px 16px"
    },
    textBox: {
        marginTop: "16px"
    },
    noDataFoundBox: {
        height: "calc(100vh - 150px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    noDataText: {
        fontSize: remCalculate(14),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    }

}));