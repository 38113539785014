import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils/common";

export const AmenityImageStyle = makeStyles((theme) => ({
  cardImage: {
    height: "256px",
    borderRadius: "16px",
    width:"100%"
    
  },

  imageCard: {
    borderRadius: "16px",

  },

  title: {
    flex: 1,
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "1.125rem",
    fontWeight: "700",
    marginBottom: "4px",
    color:theme.typography.color.primary
  },

  dollar: {
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "1.125rem",
    fontWeight: "700",
    marginBottom: "4px",
    color:theme.typography.color.primary
  },

  subText: {
    flex: 1,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
    color:theme.typography.color.Tertiary
  },

  time: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().regular,
    color:theme.typography.color.Tertiary
  },

  contentText: {
    padding: "12px !important",
  },
}));
