import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    // margin: "auto 8px",
    overflow: "hidden",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  Cardcontent: {
    // [theme.breakpoints.up("sm")]: {
    //   padding: "8px 12px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px 4px",
    // },
    // borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
  },
  yetto: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    background: "#5078E11E",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  received: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#FF9340",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  receivedDetail: {
    fontSize: "0.75rem",
    color: "#FF9340",
    fontFamily: FontFamilySwitch().bold,
    background: "#FFF4EB",
    padding: "1px 8px",
    borderRadius: "4px",
    marginTop: "6px",
    display: "inline",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  collected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "10px 6px 6px 6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
    marginTop: "5px"
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  avatar: {
    height: "120px",
    width: "110px",
    objectFit: "contain"
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
}));

export const SwiftCard = (props) => {
  const { t=()=>false } = props
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <Grid
        container
        className={classes.card}
        onClick={() =>
          history.push(Routes.swiftinout + "?passId=" + props.data.id)
        }
      >
        <Grid item xs={12}>
          <div className={classes.Cardcontent}>
            <Box alignItems="center" display="flex" position={"relative"}>
              {props?.data?.img ?
                <Avatar src={generateImageUrl(props?.data?.img, img_size.small_square)} alt={props?.data?.img} variant="rounded" className={classes.avatar} /> :
                <Avatar className={classes.avatar} variant="rounded" />
              }
              <div className={classes.bottomText}>
                <Typography fontSize={"10px"}>
                  {props?.data?.referenceId}
                </Typography>
              </div>

              <Box flexGrow={1} marginLeft="10px">
                <Box display="flex" alignItems="center" justifyContent={"space-between"}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      noWrap
                    >
                      {props?.data?.title}
                    </Typography>
                  </Box>
                  <Box marginLeft="10px">
                    {!props?.data?.check_out_time && (
                      <Typography
                        variant="subtitle2"
                        noWrap
                        className={classes.yetto}
                      >
                        {t("Yet To Checkout")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box alignItems="center" display="flex" marginTop="0px">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.property}
                    </Typography>
                  </Box>

                  <Box className={classes.dot} />

                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.unit}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {props?.data?.subtitle2}
                  </Typography>
                </Box>
                <Box alignItems="center" display="flex" marginTop="0px">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.subtitle1}
                    </Typography>
                  </Box>

                  <Box className={classes.dot} />

                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.check_out_time
                        ? "Out " +
                        moment(props?.data?.check_out_time)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY hh:mm:a")
                        : "In " +
                        moment(props?.data?.check_in_time)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY hh:mm:a")}
                    </Typography>
                  </Box>
                </Box>


              </Box>

            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
