import * as React from "react"
const PeakHourIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 114302" transform="translate(21667 434)">
      <rect
        width={24}
        height={24}
        fill="#fef2d9"
        data-name="Rectangle 57697"
        rx={6}
        transform="translate(-21667 -434)"
      />
      <path
        fill="#f5aa00"
        d="M-21656.607-415a.32.32 0 0 1-.307-.415l1.616-5.3h-3.379a.322.322 0 0 1-.251-.523l6.133-7.645a.322.322 0 0 1 .561.289l-1.508 5.3h3.253a.323.323 0 0 1 .252.523l-6.118 7.643a.319.319 0 0 1-.252.128Z"
      />
    </g>
  </svg>
)
export default PeakHourIcon
