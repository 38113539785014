import { Avatar, Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { SlideCardStyle } from './style'

export const SlideCard = ({ data = {}, selectCard = () => false }) => {
    const classes = SlideCardStyle()
    return (
        <Box className={classes.box} onClick={()=>selectCard(data)}>
            {/* <Box> */}
            {/* <img className={classes.image} src={data?.amenity_assets[0]} alt='' /> */}
            <Avatar src={data?.amenity_assets[0]} className={classes.image}></Avatar>
            {/* </Box> */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={1}>
                <Typography className={classes.name} noWrap> {data?.amenities_name} </Typography>
                <IconButton className={classes.icon_btn}>
                    <KeyboardArrowRightIcon className={classes.icon} />
                </IconButton>
            </Box>
        </Box>
    )
}