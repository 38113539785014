import React from "react";
import  Announcement  from "./announcement";
import { withBottombar } from "../../HOCs";

class AnnouncementParent extends React.Component {
  render() {
    return <Announcement {...this.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(AnnouncementParent, props);
