import React from "react";
import PayRequestInvoiceList from './payRequestInvoiceList';
import { withBottombar } from "../../HOCs";

class PayRequestInvoiceListParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  render() {
    return <PayRequestInvoiceList {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(PayRequestInvoiceListParent, props);

