import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
    },
    quotationRoot: {
        padding: "12px"
    },
    paycard: {
        backgroundColor: "white",
        boxShadow: "0px -1px 6px #00000021"
    },

}));