import React from "react"
import { Avatar, Box, Checkbox, Radio, Stack, Typography } from "@mui/material";
import PassDetails from "../../assets/passDetails";
import { DialogDrawer, TextBox } from "../../components";
import { AddWorkerPassContext } from "./addWorkerPassContext";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../components/noDataFound/noDataFound";
import { Questionaries } from "./utils";

const Step3 = () => {
    const {
        t = () => false,
        classes,
        data,
        updateStateNew,
        openGateList,
        isGateListOpen,
        setIsGateListOpen,
        gateList,
        fetchMoreGateList,
        chooseGate,
    } = React.useContext(AddWorkerPassContext);

    return (
        <>
            <div className={classes.step_root}>
                <Box className={classes.visiterJourney}>
                    <div className={classes.innerPadding}>
                        {/* PASS DETAILS */}
                        <Stack direction={"row"} mb={"16px"}>
                            <Avatar variant="rounded" className={classes.avatar}>
                                <PassDetails />
                            </Avatar>
                            <Stack sx={{ marginInlineStart: "12px" }} direction={"column"} spacing={"4px"}>
                                <Typography className={classes.title}>{t("Work Information")}</Typography>
                                <Typography className={classes.step}>{t("STEP")} 3/3</Typography>
                            </Stack>
                        </Stack>
                        <div className={classes.borederBottom2}></div>
                        {/* DESCRIPTION */}
                        <Typography mt={"16px"} mb={"8px"} className={classes.label}>{t("Work Description")}</Typography>
                        <TextBox
                            isRequired
                            color
                            labelColor={"#4E5A6B"}
                            placeholder={t("Enter Work Description")}
                            multiline
                            rows={4}
                            value={data?.work_information?.work_description}
                            onChange={(value) => updateStateNew("work_information", { _k: "work_description", _v: value?.target?.value })}
                            isError={data?.work_information?.error?.work_description?.length > 0}
                            errorMessage={data?.work_information?.error?.work_description} />
                        {/* GATE */}
                        <Typography mt={"16px"} mb={"8px"} className={classes.label}>{t("Preferred Access Gate")}</Typography>
                        <Stack direction={"row"}>
                            <Typography
                                onClick={() => updateStateNew("work_information", { _k: "is_any_gate", _v: true })}
                                className={data?.work_information?.is_any_gate ?
                                    classes.gateButtonActive : classes.gateButtonInactive}>
                                <Stack direction={"row"} spacing={"5px"} alignItems={"center"}>
                                    {t("Any Gate")}
                                </Stack>
                            </Typography>
                            <Typography
                                sx={{ marginInlineStart: "8px" }}
                                onClick={openGateList}
                                className={data?.work_information?.gate?.value ?
                                    classes.gateButtonActive : classes.gateButtonInactive}>
                                <Stack direction={"row"} spacing={"5px"} alignItems={"center"}>
                                    {data?.work_information?.gate?.label ?? t("Choose Gate")}
                                    {<KeyboardArrowDownIcon />}
                                </Stack>
                            </Typography>
                        </Stack>
                        <Typography mt={"16px"} mb={"8px"} className={classes.label}>{t("About The Work & Requirement")}</Typography>
                        <Box mt={"16px"}>
                            {Questionaries?.map((_) => {
                                return <Stack direction={"row"} alignItems="center" justifyContent={"space-between"}
                                    onClick={() => updateStateNew("work_information", { _k: _?.key, _v: !data?.work_information?.[_?.key] })}>
                                    <Typography component={"span"} className={classes.questions}>
                                        {t(_?.question)}
                                    </Typography>
                                    <Checkbox checked={data?.work_information?.[_?.key]}
                                        sx={{
                                            color: "#CED3DD",
                                            '&.Mui-checked': {
                                                color: "#4381e3",
                                            },
                                        }} />
                                </Stack>
                            })}
                        </Box>
                    </div>
                </Box>
            </div>
            {/* GATE DRAWER */}
            <DialogDrawer
                open={isGateListOpen}
                onClose={() => setIsGateListOpen(false)}
                maxWidth="sm"
                header={t("Choose Gate")}
                component={
                    <Box className={classes.listContainer}>
                        {gateList?.length > 0 ? (
                            <InfiniteScroll
                                dataLength={gateList.length}
                                next={fetchMoreGateList}
                                hasMore={true}
                                height={500}>
                                <Box height={"16px"} />
                                <Box className={classes.box}>
                                    {gateList?.map((gate) => (
                                        <Stack direction={"row"} alignItems="center" justifyContent="space-between"
                                            onClick={() => chooseGate(gate)}>
                                            <Typography>{gate?.label}</Typography>
                                            <Radio checked={data?.work_information?.gate?.value === gate?.value}
                                                sx={{ color: "#CED3DD" }} />
                                        </Stack>
                                    ))}
                                </Box>
                            </InfiniteScroll>
                        ) : (
                            <NoDataFound
                                height={245}
                                content={t("No Data Found!")} />
                        )}
                    </Box>
                }
            /></>
    )
}

export default Step3;