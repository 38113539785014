import { Box, Grid, Typography,Stack } from "@mui/material"
import { componentStyles } from "./styles"
import moment from "moment"

export const AgreementDetailCard = ({ t = () => false,data ,onViewPdf=()=>false}) => {

    const classes=componentStyles()


    const detailsJson = [
        {
            label: t("Agreement Number"),
            value: data?.agreement_no
        },
        {
            label: t("Agreement Type"),
            value: data?.agreement_type
        },
        {
            label: t("Start Date"),
            value: moment(data?.lease_start_date).format("DD MMM YYYY")
        },
        {
            label: t("End Date"),
            value:  moment(data?.lease_end_date).format("DD MMM YYYY")
        },
        {
            label: t("Billing Cycle"),
            value: data?.bill_generation
        },
        {
            label: t("Primary contact"),
            value: data?.contact_name
        },
        {
            label: t("Account"),
            value: data?.account_name,
            noBorder:true
        }
    ]

    return (
        <Box className={classes.topCardRoot} marginTop="16px" marginBottom={"4px"}>
            <Stack direction="row" justifyContent="space-between">
            <Typography className={classes.detailCardTitle}>{t("Basic Agreement Details")}</Typography>
            <Typography onClick={onViewPdf}  className={classes.viewPdf}>{t("View Pdf")}</Typography>
            </Stack>
            {
                detailsJson?.map((val) => {
                    return (

                        <Grid container sx={{padding:"12px 0px"}} borderBottom={val?.noBorder ? "none":"1px solid #E4E8EE"}>
                            <Grid item xs={6}><Typography noWrap className={classes.detailLabel}>{val?.label}</Typography></Grid>
                            <Grid item xs={6}><Typography noWrap className={classes.detailValue} >{val?.value}</Typography></Grid>
                        </Grid>

                    )
                })
            }



        </Box>
    )
}