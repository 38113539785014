import React from "react";

function Insurance() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g data-name="Group 108196" transform="translate(-309 -593)">
        <path
          fill="#5078e1"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
          d="M321.313 594.001a.733.733 0 00-.514.188s-3.67 3.234-8.066 3.234a.733.733 0 00-.733.733v5.994c0 3.265 1.51 8.637 9 11.793a.733.733 0 00.569 0c7.494-3.156 9-8.528 9-11.793v-5.994a.733.733 0 00-.733-.733c-4.4 0-8.066-3.234-8.066-3.234a.733.733 0 00-.457-.188zm-.024 1.676a14.689 14.689 0 007.822 3.152v5.321c0 2.807-1.079 7.336-7.822 10.3-6.743-2.963-7.822-7.492-7.822-10.3v-5.322a14.689 14.689 0 007.822-3.152zm3.652 6.14a.733.733 0 00-.5.222l-4.37 4.37-1.926-1.926a.736.736 0 10-1.045 1.036l2.444 2.444a.733.733 0 001.037 0l4.889-4.889a.733.733 0 00-.533-1.258z"
        ></path>
      </g>
    </svg>
  );
}

export default Insurance;
