import React from "react";
import AddWorkerPermit from "./addWorkerPermit";
 
import ContextProvider from "./context";
const AddWorkerParent = () => {
  return (
    <ContextProvider>
      <AddWorkerPermit />
    </ContextProvider>
  );
};

export default AddWorkerParent;
