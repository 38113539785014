import * as React from "react"
const ServiceIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <path
      fill="#a9afbc"
      d="M8.847 0a8.847 8.847 0 1 0 5.57 15.711L20.4 21.7a.92.92 0 1 0 1.3-1.3l-5.986-5.986A8.838 8.838 0 0 0 8.847 0Zm0 1.83a7.017 7.017 0 0 1 5.059 11.88.915.915 0 0 0-.2.2A7.017 7.017 0 1 1 8.847 1.83Z"
      data-name="Group 119262"
    />
  </svg>
)
export default ServiceIcon
