import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import InspectionsApartment from "../../assets/inspectionsAparment";
import RequestCalendar from "../../assets/requestCalendar";
import { generateImageUrl, img_size } from "../../utils";
import { useStyles } from "./style";

export const InspectionCard = ({
  value = {},
  onClick = () => false,
  preview = false,
  t = () => false
}) => {
  const classes = useStyles();
  const language = localStorage.getItem("i18nextLng")

  return (
    <Box className={classes.root} onClick={() => onClick(value)}>
      <Stack
        p={1}
        className={classes.container}
        direction={"row"}
        alignItems="center"
      >
        <Avatar className={classes.propertyImage}
          src={generateImageUrl(value?.logo, img_size.small_square)}>
        </Avatar>
        <Box className={classes.content}>
          <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
            <Typography className={classes.propertyId} noWrap>
              {value?.reference_id}
            </Typography>
            {t(value?.request_type) ? (
              <Typography
                sx={{
                  backgroundColor: "#F1F7FF",
                  color: "#78B1FE",
                }}
                className={classes.status_success}
                noWrap
              >
                {t(value?.request_type)}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
          <Stack>
            <Typography
              sx={{ paddingTop: "5px" }}
              className={classes.propertyName}
            >
              {value?.property_name}, {value?.unit_name}
            </Typography>
          </Stack>
          {/* <Typography className={classes.unitName}>
            {value?.unit_no}
          </Typography> */}
          <Stack
            direction={"row"}
            spacing={2}
            marginTop="6px"
            divider={
              <Divider orientation="vertical" flexItem variant="middle" />
            }
            alignItems="center"
          >
            <div>
              {value?.execution && <Stack direction={"row"} alignItems="center">
                <RequestCalendar />
                <Typography sx={{ marginLeft: language === "ar" && "8px"}} className={classes.dateAndType}>
                  {preview
                    ? moment(value?.execution).format("DD-MM-YYYY")
                    : moment(value?.execution).format("DD-MM-YY")}
                </Typography>
              </Stack>}
            </div>
            <div>
              <Stack direction={"row"} alignItems="center">
                <InspectionsApartment />
                <Typography className={classes.dateAndType}>
                  {value?.unit_type}
                </Typography>
              </Stack>
            </div>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};
