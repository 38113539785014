
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalculate } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  unitbackground: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E4E8EE",
    padding: "16px",
  },
  unitBox: {
    backgroundColor: "#F6F1FE",
    borderRadius: "6px",
    padding: "12px",
  },
  title: {
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  subTitle: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary
  },
  unitNoTitle:{
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary 
  },
  changetext: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().bold,
    color: "#5078E1",
    cursor: "pointer"
  },
  nonAssetBox: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #CED3DD",
    borderRadius: "8px",
  },
  nonAssetText: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch()?.semiBold,
    color: "#5AC782",
    backgroundColor: "#EEF9EE",
    borderRadius: "0px 0px 6px 6px",
    padding: "5px",
  },
  inspectionItemIcon: {
    backgroundColor: "#F5F7FA",
    border: "1px solid #E4E8EE",
    padding: "4px",
    borderRadius: "6px"
  },
  productDetails: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch()?.semiBold,
    color: theme.typography.color.primary,
    [theme.breakpoints.only("xs")]: {
      maxWidth:"90px"
    },
  },
  productDetailsTitle: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch()?.regular,
    color: theme.typography.color.secondary
  },
  unitSwitchTitle: {
    fontSize: remCalculate(18),
    fontFamily: FontFamilySwitch()?.extraBold,
    color: theme.typography.color.primary
  },
  unitSectionBox: {
    border: "1px solid #CED3DD",
    borderRadius: "12px"
  },
  unitTitle: {
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  unitDot: {
    width: "6px",
    height: "6px",
    backgroundColor: "#98A0AC",
    borderRadius: "50%",
    margin: "0px 5px"
  },
  propertyName: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.Tertiary,
    [theme.breakpoints.only("xs")]: {
      width: "100px",
      textOverflow:"ellipsis",
      overflow:"hidden"
    },
  },
  productTitle: {
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary
  },
  nodataTitle:{
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary
  },
  productValue: {
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  itemName: {
    fontSize: remCalculate(16),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    textAlign: "center"
  },
  itemCondition: {
    fontSize: remCalculate(12),
    color: "#5AC782",
    fontFamily: FontFamilySwitch()?.semiBold,
  },
  itemBox: {
    backgroundColor: "#EEF9EE",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    justifyContent: "center",
    width: "100px",
    padding: "5px"
  },
  raiseRequestbtn: {
    padding: "8px",
    textTransform:"capitalize",
    fontSize:"0.875rem",
    fontFamily:FontFamilySwitch()?.semiBold
  },
  unitAsset: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    borderRadius: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "127px",
    height: "127px"
  },
  assetInfoBox: {
    backgroundColor: "#EEF9EE",
    borderRadius: "6px",
    padding: "8px"
  },
  infoText: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch()?.semiBold,
    color: "#5AC782"
  },
  content: {
    margin: "16px",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    boxShadow: "0px 3px 30px #4354721F"
  },
  btnRoot: {
    backgroundColor: "#FFFFFF",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    fontSize:"0.875rem",
    fontFamily:FontFamilySwitch()?.semiBold,
    textTransform:"capitalize"
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    marginInlineStart: "16px"
  },
  details: {
    fontSize: "1rem",
    color: "white",
    fontFamily: FontFamilySwitch().bold,
    marginInlineStart: "16px"
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    textTransform:"capitalize",
    color: theme.palette.primary.main,
    '&:hover':{
      background:"#FFFFFF",
  }
  },
  screen: {
    borderRadius: "4px",

    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    marginTop: "5px",
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  serviceTitle: {
    fontSize: remCalculate(16),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  steptitle: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary
  },
  productBox: {
    border: "1px solid #CED3DD",
    borderRadius: "8px"
  },
  contact: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  changeBox: {
    backgroundColor: "#F1F7FF",
    border: "1px solid #5078E1",
    borderRadius: "8px",
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer"
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "box-shadow": "none",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "4px 4px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "1rem",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  verticalBorder: {
    borderRight: "1px solid #E4E8EE",
    height: "14px",
    margin: "0px 5px"
  },
  uploadedImg: {
    width: "40px",
    height: "40px",
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 3px 6px #00000014"
  },
  deleteIcon: {
    border: "1px solid #CED3DD",
    borderRadius: theme.palette.borderRadius,
    padding: "3px",
    display: "flex",
    justifyContent: "center",
    alignitems: "center",
    cursor: "pointer"
  },
  imgName: {
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.only("xs")]: {
      width: "200px"
    },
  },
  subTitleValues: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary
  },
  problemTitle: {
    fontSize: remCalculate(16),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  qrSection: {
    position:"absolute",
    top:"40%",
    left:"50%",
    transform:"translate(-50%,-50%)"
  },
  qrcode: {
    border: "5px solid #42A4EE",
    borderRadius: "8px",
    "& section > div": {
      border: "none !important",
      boxShadow: "none !important",

    },
    width: "240px",
  },
  manualqrtxt: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch()?.bold,
    color: "#B20606"
  },
  scanQrBox:{
    backgroundColor:"#E6E6E6",
    // position:"relative"
  },
  unitDetailsBox:{
    position:"absolute",
    left:0,
    right:0,
    bottom:0,
    padding:"40px"
  },
  scanAssetBox: {
    backgroundImage: `url("../images/scanInspectionItemQR.svg")`,
    background: "no-repeat",
    backgroundColor: "#FFF8E7",
    border: "1px solid #FFEBB7",
    borderRadius: "12px",
    height: "125px",
    padding: "12px",
    // boxShadow: "0px 3px 15px #4250682E",
    position: "relative"
  },
  searchAssetBox: {
    backgroundImage: `url("../images/searchInspectionItem.svg")`,
    background: "no-repeat",
    backgroundColor: "#E6EFFF",
    border: "1px solid #C9D9F6",
    borderRadius: "12px",
    height: "125px",
    padding: "12px",
    // boxShadow: "0px 3px 15px #4250682E",
    position: "relative"
  },
  assettext: {
    color: "#031629",
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().bold
  },
  fixbtn: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    padding: "12px"
  },
  productImage:{
    width:"127px",
    height:"127px",
    borderRadius:"12px",
    border:"1px solid #E4E8EE"
  },
  dateTime: {
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary
  },
  slotTime: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  slotBox:{
    borderRadius:"8px",
    border:"1px solid #CED3DD",
    display:"flex",
    justifyContent:"center",
    padding:"12px 0px"
  },
  selectedSlotBox:{
    borderRadius:"8px",
    border:"1px solid #5078E1",
    display:"flex",
    justifyContent:"center",
    backgroundColor:"#F1F7FF",
    padding:"12px 0px"
  },
  slotTitle: {
    fontSize: remCalculate(12),
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
}));