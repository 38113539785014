import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const AvailableSlotStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F2F2F2",
        padding: "0px",
    },
    panel: {
        padding: "12px !important",
    },
    noData: {
        color: "#999",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        top: "50%",
        left: "20%",
        transform: "translate(0%,50%)",
        position: "absolute"
    },
    flexWithPadding: {
        display: "flex",
        padding: "8px",
        borderBottom: "1px solid #c1c1c1",
        alignItems: "center"
    },
    modalTitle: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    backbtn: {
        height: 32,
        width: 32,
        background: "#00000082",
        color: "#fff",
        position: "relative",
        top: "30px",
        left: "30px",
        transform: "translate(-50%,-50%)",
        zIndex: 3,
        border: '1px solid #fff',
    },
    navigate: {
        background: "transparent",
        height: 0
    },
    bookingbtn: {
        width: 120,
        padding: 12,
        position: "relative",
        top: "30px",
        right: "-50px",
        transform: "translate(-50%,-50%)",
        zIndex: 3,
        border: '1px solid #fff',
        background: "#00000082",
        borderRadius: '4px',
        height: 32,
        whiteSpace: "nowrap",
        fontSize: "0.75rem",
        color: "#fff",
        cursor: "pointer",
        fontFamily: FontFamilySwitch().regular,

    },
    bookingText: {
        fontSize: "0.75rem",
        color: "#fff",
        fontFamily: FontFamilySwitch().regular,
    },
    body: {
        position: "relative",
        zIndex: 1,
        backgroundColor: "transparent",
        borderRadius: '4px',
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-58%)",
        height: "auto",
        marginRight: 8
    },
    fullbody: {
        position: "relative",
        zIndex: 1,
        backgroundColor: "transparent",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,0%)",
        height: "auto",
    },
    fullslot: {
        height: "calc(100vh - 230px)",
        overflow: "auto"
    },
    slot: {
        height: "calc(100vh - 415px)",
        overflow: "auto"
    },
    bar: {
        height: 50,
        width: "100%",
        margin: 8,
        background: "#fff",
        borderRadius: '4px',
        position: "sticky",
        zIndex: 3,
        bottom: 0,
        left: 0
    },
    // MuiPaper-root-MuiSnackbarContent-root
    snackBar: {
        [theme.breakpoints.up('sm')]: {
            '& .MuiSnackbarContent-root': {
                backgroundColor: "#fff",

            },
            width: "90%",
            color: "secondary",
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
        },
        "& .MuiPaper-root": {
            width: "100%",
            background: "#fff"
        }
    },
    snackBarMessage: {
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
    },
    topDetailCard: {
        transform: "translate(0%,-105%)",
        position: "relative",
        margin: "14px"
    },
    hourDetailCard: {
        position: "relative",
        transform: "translate(0%,-75%)",
        margin: "14px"
    },
    dailyDetailCard: {
        position: "relative",
        transform: "translate(0%,-68%)",
        margin: "14px"
    },
    hourSlotCard: {
        position: "relative",
        // transform: "translate(0%,-42%)",
        margin: "14px",
        transform: "translateY(-145px)"
    },
    dailySlotCard: {
        position: "relative",
        // transform: "translate(0%,-54%)",
        margin: "14px",
        transform: "translateY(-60px)"
    },
    hourSlotPanal: {
        // height: "calc(100vh - 415px)",
        overflow: "auto"
    },
    dailySlotPanal: {
        // height: "calc(100vh - 300px)",
        overflow: "auto"
    },
    bannerImg: {
        height: 300,
        width: "100%",
        borderRadius: 0
    },
    customSnackbar: {
        height: "auto",
        width: "100%",
        background: "#fff",
        borderRadius: '4px',
    }
}))

export const BookingPreviewStyle = makeStyles((theme) => ({
    box: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: '0px 0px 4px 4px',
        '@media (min-width: 374px)': {
            padding: "16px",
        },
        '@media (max-width: 321px)': {
            padding: "12px",
        },
    },
    box1: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: '4px 4px 0px 0px',
        '@media (min-width: 374px)': {
            padding: "16px",
        },
        '@media (max-width: 321px)': {
            padding: "12px",
        },
    },
    title: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    subtitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    paybtn: {
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
        color: "#fff",
        height: 48,
        borderRadius: '4px',
        textTransform: "capitalize"
    },
    addUnitBtn: {
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
        color: theme.palette.primary.main,
        height: 48,
        borderRadius: '4px',
        textTransform: "capitalize"
    },
    previewBox: {
        height: "calc(100vh - 385px)",
        overflow: "auto"
    },
    bigDivider: {
        borderBottomWidth: '4px',
        color: "#F2F4F7",
    },
    dot_divider: {
        border: "1px dashed #CED3DD",
    },
    pcTitle: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    pcSubTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular
    },
    confirmBtn: {
        height: 40,
        fontFamily: FontFamilySwitch().bold,
        textTransform: "capitalize",
        borderRadius: 8
    },
    flexWithPadding: {
        display: "flex",
        padding: "8px",
        borderBottom: "1px solid #c1c1c1",
        alignItems: "center",
    },
    modalTitle: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            padding: "0px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    firstBox: {
        padding: "0px 16px 0px",
        '@media (max-width: 321px)': {
            padding: "0px 11px 0px",
        },

    }
}))