import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const MyBookingStyle = makeStyles((theme) => ({
    container: {
        background: "#F5F7FA",
        padding: "0 !important",
    },
    topCard: {
        background: "linear-gradient(to right, #F8997B, #E25C54)",
        height: 230
    },
    topImgae: {
        height: 230,
        width: "100%",
    },
    searchBox: {
        position: "relative",
        zIndex: 3,
        // backgroundColor: "white",
        borderRadius: '4px',
        width: "100%",
        left: "50%",
        top: "-3%",
        transform: "translate(-50%,-50%)",
        padding: "0px 24px !important"
    },
    textfiled: {
        backgroundColor: "white",
        borderRadius: "4px"
    },
    bookingTitle: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    avatar: {
        borderRadius: '4px',
        height: 40,
        width: 40
    },
    title: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width:"150px",
        '@media (max-width: 374px)': {
            width: "120px",
        },
        '@media (max-width: 321px)': {
            width: "85px",
            
        },

    },
    title1: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        

    },
    content: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        whiteSpace: "nowrap",
        fontFamily: FontFamilySwitch().regular,
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    content1: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        whiteSpace: "nowrap",
        fontFamily: FontFamilySwitch().regular,
        // textOverflow: "ellipsis",
        // overflow: "hidden",
        // width: "85px",
        mt: "6px"
    },
    divider: {
        border: `0.5px dashed ${theme.palette.border.secondary}`,
        height: 50
    },
    statusCard: {
        background: "#fff",
        boxShadow: "0px 3px 8px #D2D5D938",
        borderRadius: '4px'
    },
    booked: {
        background: theme.palette.success.main,
        color: "#fff",
        padding: "2px 8px",
        borderRadius: '4px',
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
    },
    not_booked: {
        background: theme.palette.border.secondary,
        color: theme.typography.color.tertiary,
        padding: "2px 8px",
        borderRadius: '4px',
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
    },
    backbtn: {
        height: 32,
        width: 32,
        background: "#624234",
        color: "#fff",
        position: "relative",
        top: "30px",
        transform: "translate(-50%,-50%)",
        zIndex: 3
    },
    navigate: {
        background: "transparent",
        height: 0
    },
    scroll: {
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    canceled: {
        background: theme.palette.warning.light,
        color: theme.palette.warning.main,
        padding: "2px 8px",
        borderRadius: '4px',
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
    }
}))