import { Avatar, Box, Container, Divider, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { DialogDrawer } from "../../components";
import NoDataFound from "../../components/noDataFound/noDataFound";
import useWindowDimensions from "../../utils/useWindowDimensions";
import InventoryCard from "./components/inventoryCard";
import { InventoryAndServicesContext } from "./inventoryAndServiceContext";
import PassUnits from "../../assets/passUnits";
import { useHistory } from "react-router";
import { Routes } from "../../router/routes";
import Info from "../../assets/info";

const InventoryAndServices = ({ handleTopBarState }) => {
    const size = useWindowDimensions();
    const history = useHistory();
    const { unitDrawer, setUnitDrawer, classes, agreement, data, setUnit, inspectionList, loading, t = () => false } = React.useContext(InventoryAndServicesContext)

    React.useEffect(() => {
        handleTopBarState({
            text: t("Inventory & Services"),
            customBackpath:Routes.agreement
        })
        // eslint-disable-next-line
    }, []);

    const inspectionListItem = (datas) => {
        history.push({
            pathname: Routes.inspectionItemList,
            state: {
                data: datas,
                unit: data
            }
        })
    }


    return (
        <>
            <Container maxWidth="sm" className={classes.root}>
                <div style={{ height: size?.height - 55, overflow: "overlay", padding: "16px" }}>
                    <div className={classes.unitBox}>
                        <Stack direction={"row"} alignItems="center">
                            <Avatar variant="rounded" className={classes.unitAvatar}>
                                <PassUnits />
                            </Avatar>
                            <Box flexGrow={1}>
                                <Stack direction={"column"}>
                                    <Typography noWrap className={classes.unitName}>{data?.unit?.name}</Typography>
                                    {data?.unit?.floor_name &&
                                        <Typography className={classes.unitLocation}>{data?.unit?.floor_name}, {data?.unit?.block_name}</Typography>
                                    }
                                </Stack>
                            </Box>
                            <Typography onClick={setUnitDrawer} className={classes.change}>{t("Change")}</Typography>
                        </Stack>
                    </div>
                    <Box pt={"12px"}>
                        <div className={classes.information}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <Info />
                                <Typography sx={{ marginInlineStart: "8px" }} className={classes.infotext}>{t("The following items will be compared during move-out or exit process")}</Typography>
                            </Stack>
                        </div>
                    </Box>
                    <Box mt={"16px"} mb={"8px"}>
                        <Typography className={classes.header}>{t("CONFIGURED ITEMS")}</Typography>
                    </Box>
                    {loading ?
                        <div className={classes.serviceBox}>
                            <Stack my={"12px"} direction={"row"} alignItems={"flex-start"} spacing={"8px"}>
                                <Stack direction={"column"}>
                                    <Skeleton variant="rounded" width={"40px"} height="40px" />
                                </Stack>
                                <Box flexGrow={1}>
                                    <Stack direction={"column"} spacing={"8px"}>
                                        <Skeleton variant="rounded" width={"100px"} height="14px" />
                                        <Skeleton variant="rounded" width={"100px"} height="14px" />
                                    </Stack>
                                </Box>
                                <Box>
                                    <Skeleton variant="rounded" width={"50px"} height="14px" />
                                </Box>
                            </Stack>
                            <Divider />
                        </div>
                        :
                        <>
                            {inspectionList?.data?.length > 0 ?
                                <div className={classes.serviceBox}>
                                    <>
                                        {inspectionList?.data?.map((_) => {
                                            return (
                                                <InventoryCard t={t} handleClick={() => inspectionListItem(_)} data={_} count={inspectionList?.count} name={inspectionList?.name} inspection_type={inspectionList?.inspection_type} />
                                            )
                                        })}
                                    </>

                                </div> :
                                <NoDataFound />}
                        </>
                    }

                </div>
                <DialogDrawer
                    open={unitDrawer}
                    onClose={() => setUnitDrawer(false)}
                    maxWidth="sm"
                    header={t("Change Unit")}
                    height="300px"
                    component={
                        <>
                            {agreement?.map((_) => (
                                <div className={classes.drawerBox} onClick={() => setUnit(_)}>
                                    <Stack direction={"row"}>
                                        <img
                                            style={{
                                                height: "36px",
                                                width: "36px",
                                                objectFit: "cover",
                                            }}
                                            src="/images/apartmentIcon.svg"
                                            alt=""
                                        />
                                        <Stack sx={{ marginInlineStart: "8px" }} direction={"column"}>
                                            <Stack direction={"row"}>
                                                <Typography noWrap className={classes.unitName}>{_?.name}</Typography>
                                                <Box sx={{ marginInlineStart: "8px" }} className={classes.unitBoxDrawer}>
                                                    <Typography noWrap className={classes.unitId}>{_?.unit_no}</Typography>
                                                </Box>
                                            </Stack>
                                            {_?.floor_name ??
                                                <Typography noWrap className={classes.unitLocation}>
                                                    {_?.floor_name}, {_?.block_name}
                                                </Typography>
                                            }
                                        </Stack>
                                    </Stack>
                                </div>
                            ))}
                        </>
                    }
                />
            </Container>
        </>
    )
}

export default InventoryAndServices