import { Box, Button } from "@mui/material";
import React from "react";
import { DocumentViewer } from "../../components";
import { saveAs } from "file-saver";
import NoDataFound from "../../components/noDataFound/noDataFound";

const AgreementDownlaod = ({ t = () => false, agreementData })=> {
 
  return (
    <Box p={1}>
      <div>
        {agreementData?.assets?.[0]?.pdf_url?.length > 0 ? (
          <>
          <DocumentViewer url={agreementData?.assets?.[0]?.pdf_url} />
          <Box height={"16px"} />
          <Button
            variant="contained"
            fullWidth={true}
            sx={{ padding: "12px", borderRadius: "10px", marginTop: "12px" }}
            onClick={() =>
              saveAs(agreementData?.assets[0].pdf_url, `${agreementData?.id}.pdf`)
            }
          >
            {t("Download")}
          </Button>
          </>
          ) : (
          <NoDataFound height={210} content="No Pdf" />
        )}
      </div>

    </Box>
  );
}

export default AgreementDownlaod;
