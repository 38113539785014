import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DatePickerNew, TextBox } from "../../components";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import ButtonGroupSelect from "../../components/ButtonGroupSelect";
import Residential from "../../assets/residential";
import Commercial from "../../assets/commercial";
import { SelectBox } from "../../components/dropDown";
import { withBottombar } from "../../HOCs";

const FilterPropertySearch = (props) => {
  const InitialState = {
    location: "",
    type: "Rent",
    purpose: {
      value: "Residential",
      label: "Residential",
    },
   
    startData: "",
    fromDuration: "",
    period: {
      value: "Months",
      label: "Months",
    },
    furnishing: {
      value: "Furnished",
      label: "Furnished",
    },
    bed: {
      value: "2",
      label: "2",
    },
  
    baths: {
      value: "3",
      label: "3",
    },
     
    areaMin: "0",
    areaMax: "1200",
    areaType: "",
    error: {
      type: "",
      location: "",
      purpose: "",
      period: "",
      startData: "",
      fromDuration: "",
      bed: "",
      baths: "",
      areaMin: "",
      areaMax: "",
      areaType: "",
    },
  };
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  let fullSize = size?.height - 200;
  const { t = () => false, handleTopBarState } = props;
  const [data, setdata] = React.useState({ ...InitialState });
  const updateState = (key, value) => {
    console.log(key, value);
    let error = data?.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const purpose = [
    {
      value: "Residential",
      label: "Residential",
      startIcon: Residential,
    },
    {
      value: "Commercial",
      label: "Commercial ",
      startIcon: Commercial,
    },
  ];
  const period = [
    {
      value: "Months",
      label: "Months ",
    },
    {
      value: "Years",
      label: "Years ",
    },
  ];

  const areaOptions = [
    { value: "Sq.Ft", label: "Sq.Ft" },
    { value: "Sq.mits", label: "Sq.mits" },
  ];
  const counts = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4+", label: "4+" },
  ];

  const isValidData = () => {
    let isValid = true;
    let error = data.error;
    if (data?.location?.length === 0) {
      isValid = false;
      error.location = t("Location is required");
    } else {
      isValid = true;
      error.location = "";
    }
    //purpose
    if (data?.purpose?.length === 0) {
      isValid = false;
      error.purpose = t("Purpose is required");
    } else {
      isValid = true;
      error.purpose = "";
    }
    //propertyType
    // if (data?.propertyType?.length === 0) {
    //   isValid = false;
    //   error.propertyType = "Property Type is required";
    // } else {
    //   isValid = true;
    //   error.propertyType = "";
    // }
    //fromDuration
    if (data?.type === "Rent") {
      if (data?.fromDuration?.length === 0) {
        isValid = false;
        error.fromDuration = t("From Duration is required");
      } else {
        isValid = true;
        error.fromDuration = "";
      }
    }
    if (data?.type === "Rent") {
      //period
      if (data?.period?.length === 0) {
        isValid = false;
        error.period = t("Period is required");
      } else {
        isValid = true;
        error.period = "";
      }
    }

    //Furnishing
    // if (data?.furnishing?.length === 0) {
    //   isValid = false;
    //   error.furnishing = "Furnishing is required";
    // } else {
    //   isValid = true;
    //   error.furnishing = "";
    // }
    //Amenities
    // if (data?.amenities?.length === 0) {
    //   isValid = false;
    //   error.amenities = "Amenities is required";
    // } else {
    //   isValid = true;
    //   error.amenities = "";
    // }
    //Bed
    if (data?.bed?.length === 0) {
      isValid = false;
      error.bed = t("Bed is required");
    } else {
      isValid = true;
      error.bed = "";
    }
    //baths
    if (data?.baths?.length === 0) {
      isValid = false;
      error.baths = t("Baths is required");
    } else {
      isValid = true;
      error.baths = "";
    }
    //Checking Start Date
    if (data?.startData?.length === 0) {
      isValid = false;
      error.startData =  (data?.type === "Rent" ? t("Start Date") : t("Ready to Occupy")) + t(" is required");
    }

    setdata({ ...data, error });
    return isValid;
  };
  const submitData = () => {
    if (isValidData()) {
      history.push({
        pathname: Routes.filteredPropertyList,
        state: {
          filtredData: data,
        },
      });
    }
  };
  const clearForm = () => {
    setdata(InitialState);
  };

  React.useEffect(() => {
    handleTopBarState({
      text: "Property Search"
    })
    // eslint-disable-next-line
  }, []);

 
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={t("Property Search")} goBack={() => history.goBack(-1)} /> */}
      <div className={classes.mainPadding} style={{ paddingBottom: "4px" }}>
        <Box className={classes.tapContainer}>
          <Button
            fullWidth
            onClick={() => updateState("type", "Rent")}
            className={
              data?.type === "Rent"
                ? classes.tapButtonActive
                : classes.tapButton
            }
            variant="contained"
          >
            {"Rent"}
          </Button>
          <Button
            fullWidth
            onClick={() => updateState("type", "Buy")}
            className={
              data?.type === "Buy" ? classes.tapButtonActive : classes.tapButton
            }
            variant="contained"
          >
            {t("Buy")}
          </Button>
        </Box>
      </div>
      <div
        className={classes.mainPadding2}
        style={{ height: fullSize, overflow: "auto" }}
      >
        <Box className={classes.formContainer}>
          <div className={classes.mainPadding}>
            <div className={classes.input}>
              <TextBox
                labelColor="#4E5A6B"
                isRequired
                startAdornment={
                  <img src="/images/icons8-location.svg" alt="" />
                }
                endAdornment={
                  <img src="/images/icons8-search%20(2).svg" alt="" />
                }
                color
                placeholder={t("Enter location")}
                value={data.location}
                onChange={(value) =>
                  updateState("location", value.target.value)
                }
                isError={data?.error?.location?.length > 0}
                errorMessage={data?.error?.location}
              />
            </div>
            <div className={classes.input}>
              <ButtonGroupSelect
                label={t("Purpose")}
                data={purpose}
                variant="outlined"
                state={data}
                labelColor="#4E5A6B"
                stateName={"purpose"}
                updateState={updateState}
              />
            </div>
            {/* <div className={classes.input}>
              <SelectBox
                isRequired
                labelColor="#4E5A6B"
                isMulti={true}
                label={t("Property Type")}
                placeholder={t("Select Property Type")}
                value={data.propertyType}
                options={propertyType}
                onChange={(value) => {
                  updateState("propertyType", value);
                }}
                isError={data?.error?.propertyType?.length > 0}
                errorMessage={data?.error?.propertyType}
              />
            </div> */}
            <div className={classes.input}>
              <DatePickerNew
                isRequired
                labelColor="#4E5A6B"
                label={
                  data?.type === "Rent" ? t("Start Date") : t("Ready to Occupy")
                }
                placeholder={
                  data?.type === "Rent" ? t("Start Date") : t("Select Occupy Date")
                }
                value={data?.startData ?? ""}
                onChange={(value) => updateState("startData", value)}
                isError={data?.error?.startData?.length > 0}
                errorMessage={data?.error?.startData}
                minDate={new Date()}
              />
            </div>
            {data?.type === "Rent" && (
              <div className={classes.input}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextBox
                      isRequired
                      color
                      type={"number"}
                      label={t("From Duration")}
                      labelColor="#4E5A6B"
                      placeholder={t("Enter From Duration")}
                      value={data.fromDuration}
                      onChange={(value) =>
                        updateState("fromDuration", value.target.value)
                      }
                      isError={data?.error?.fromDuration?.length > 0}
                      errorMessage={data?.error?.fromDuration}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Typography
                      className={classes.label}
                      sx={{ color: "#4E5A6B" }}
                      align={"right"}
                      component="h6"
                    >
                      {data?.fromDuration} {data?.period.label}
                    </Typography>
                    <ButtonGroupSelect
                      data={period}
                      labelColor="#4E5A6B"
                      state={data}
                      stateName={"period"}
                      updateState={updateState}
                    />
                  </Grid>
                </Grid>
              </div>
            )}        
            {/* <div className={classes.input}>
              <ButtonGroupSelect
                label={"Furnishing"}
                labelColor="#4E5A6B"
                data={furnishing}
                state={data}
                stateName={"furnishing"}
                updateState={updateState}
              />
            </div> */}
            <div className={classes.input}>
              <ButtonGroupSelect
                endIcon={
                  <img
                    src="/images/bedIcon.svg"
                    height={"16px"}
                    width={"16px"}
                    style={{ opacity: ".6" }}
                    alt=""
                  />
                }
                label={"Bed"}
                isRequired
                data={counts}
                labelColor="#4E5A6B"
                state={data}
                stateName={"bed"}
                updateState={updateState}
              />
            </div>
            <div className={classes.input}>
              <ButtonGroupSelect
                endIcon={
                  <img
                    src="/images/bathsIcon.svg"
                    height={"16px"}
                    style={{ opacity: ".6" }}
                    width={"16px"}
                    alt=""
                  />
                }
                isRequired
                labelColor="#4E5A6B"
                label={"Baths"}
                data={counts}
                state={data}
                stateName={"baths"}
                updateState={updateState}
              />
            </div>
            {data?.type === "Rent" && 
            <div className={classes.input}>
              <Typography className={classes.label}>{t("Area")}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={5.5} sm={4}>
                  <TextBox
                    isRequired
                    color
                    endAdornment={
                      <span className={classes.label3}>{"min"}</span>
                    }
                    type={"number"}
                    placeholder="Enter area min"
                    value={data.areaMin}
                    onChange={(value) =>
                      updateState("areaMin", value.target.value)
                    }
                    isError={data?.error?.areaMin?.length > 0}
                    errorMessage={data?.error?.areaMin}
                  />
                </Grid>
                <Grid item xs={1} display={"flex"} alignItems={"center"} justifyContent="center">
                  <Typography className={classes.chipText}>-</Typography>
                </Grid>
                <Grid item xs={5.5} sm={4}>
                  <TextBox
                    isRequired
                    color
                    endAdornment={
                      <span className={classes.label3}>{"max"}</span>
                    }
                    type={"number"}
                    placeholder={t("Enter area max")}
                    value={data.areaMax}
                    onChange={(value) =>
                      updateState("areaMax", value.target.value)
                    }
                    isError={data?.error?.areaMax?.length > 0}
                    errorMessage={data?.error?.areaMax}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SelectBox
                    placeholder={t("Area")}
                    value={data.areaType}
                    options={areaOptions}
                    onChange={(value) => {
                      updateState("areaType", value);
                    }}
                    isError={data?.error?.areaType?.length > 0}
                    errorMessage={data?.error?.areaType}
                  />
                </Grid>
              </Grid>
            </div>
            }
            {/* <div className={classes.input}>
              <TextBox
                isRequired
                startAdornment={
                  <img src="/images/icons8-search%20(2).svg" alt="" />
                }
                colorlabelColor="#4E5A6B"
                label={"Amenities"}
                placeholder="Add Amenities"
                value={data.amenities}
                onChange={(value) =>
                  updateState("amenities", value.target.value)
                }
                isError={data?.error?.amenities?.length > 0}
                errorMessage={data?.error?.amenities}
              />
            </div> */}
          </div>
        </Box>
      </div>
      <Grid
        container
        className={classes.btnroot}
        justifyContent="space-between"
        alignItems={"center"}
        spacing={0}
      >
        <Grid item xs={5}>
          <Button
            fullWidth
            className={classes.secondary}
            variant="contained"
            onClick={() => clearForm()}
          >
            {t("Reset")}
          </Button>
        </Grid>
        <Divider
          orientation="vertical"
          sx={{ borderLeft: "1px solid #98A0AC", padding: "14px 0" }}
        />
        <Grid item xs={5}>
          <Button
            fullWidth
            className={classes.primary}
            variant="contained"
            onClick={() => submitData()}
          >
            {t("Search")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("filterPropertySearch")(FilterPropertySearch), props);
