import React from "react"
import { withNamespaces } from "react-i18next"
import { TextBox, TitleBar } from "../../components"
import { Box, Button, Container, Divider, Grid, Stack, Typography } from "@mui/material"
import { useStyles } from "./style"
import { BOMIcon } from "../../assets/bomIcon"
import { AlertProps, NetWorkCallMethods } from "../../utils"
import { NetworkCall } from "../../networkcall"
import { config } from "../../config"
import { AlertContext } from "../../contexts"
import { useLocation, useHistory } from "react-router-dom"
import useWindowDimensions from "../../utils/useWindowDimensions"


const BomDetails = ({ t }) => {
    const classes = useStyles()
    const history = useHistory()
    const size = useWindowDimensions()
    const { requestType, requestNo } = useLocation()?.state
    const alert = React.useContext(AlertContext)
    const [notesData, setNotesData] = React.useState("")
    const [showNote, setShowNote] = React.useState(false)
    const [itemDetails, setItemDetails] = React.useState([])
    //get Costing Item details
    const getCostingDetails = (data) => {
        const payload = {
            "request_id": requestNo,
            "type": requestType,
            "bom_type": "Projected"
        }

        NetworkCall(
            `${config.api_url}/request_costing/get_costing`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            setItemDetails({ list: response?.data?.data?.[0]?.costing?.[0], costing_status: response?.data?.data?.[0]?.costing_status })

        }).catch((err) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something Went Wrong",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        })
    }
    React.useEffect(() => {
        if (requestType === "Maintenance") {
            getCostingDetails()
        }
        //eslint-disable-next-line
    }, [])
    const updateRequest = (costing_status) => {
        const payload = {
            "request_id": requestNo,
            "type": requestType,
            "comments": notesData,
            "costing_status": costing_status,
            "bom_type": "Projected"
        }
        NetworkCall(
            `${config.api_url}/request_costing/update_costing_request`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                if (requestType === "Maintenance") {
                    getCostingDetails()
                }
                alert.setSnack({
                    ...alert, open: true, msg: `Costing Request is ${costing_status} Successfully`,
                    severity: AlertProps.severity.success
                })
            })
            .catch((error) => {
                if (error.response) {

                    // Request made and server responded
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: Object.keys(error.response.data.error.message).length !== 0 ? error.response.data.error.message : "Something went wrong",
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });

                }
                else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Something went wrong please try again"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                }

            });
    }
    const handleChangeNotes = (val) => {
        setNotesData(val)
    }
    return (
        <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
            <TitleBar
                text={t("Projected BOM")}
                goBack={() => history.goBack()}
            />
            <Box p={2} height={size?.height-55} position={"relative"}>
                <Box  className={classes.requestDetailsBox}>
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems={"center"}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <BOMIcon />
                                <Box>
                                    <Typography className={classes.requestTitle}>{itemDetails?.list?.symbol} {itemDetails?.list?.total_amount_after_tax ?? 0} </Typography>
                                    <Typography className={classes.requestNo}>{t("Final Amount")}</Typography>
                                </Box>
                            </Stack>
                            <Typography className={classes.BOMstatusBox} style={{ backgroundColor: itemDetails?.costing_status === "Pending" ? "#78B1FE" : itemDetails?.costing_status === "Approved" ? "#5AC782" : "#FF4B4B" }}>{itemDetails?.costing_status}</Typography>
                        </Box>
                    </Box>
                    <Box mt={1} mb={1}>
                        <Divider />
                    </Box>
                    <Box mt={2} maxHeight={size?.height - 470} overflow={"scroll"}>
                        {itemDetails?.list?.items?.map((val) => {
                            return (
                                <Grid container spacing={2} alignItems="center" mb={1}>
                                    <Grid item xs={6}>
                                        <Typography className={classes.itemName} noWrap>{val?.inspection_item_name}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography className={classes.requestNo}>{`${val?.quatity} ${t("QTY")}`}</Typography>
                                    </Grid>
                                    <Grid item xs={3} justifyContent={"end"} display={"flex"}>
                                        <Typography className={classes.itemName}>{itemDetails?.list?.symbol} {val?.line_amount}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}

                        {itemDetails?.list?.deduction?.length > 0 &&

                            <>
                                <Box mt={1} mb={1}>
                                    <Divider />
                                </Box>
                                <Box>
                                    <Typography className={classes.BillingAmountTxt}>{t("Deduction")}</Typography>
                                </Box>
                                {itemDetails?.list?.deduction?.map((x) => {
                                    return (
                                        <Grid container spacing={2} alignItems="center" mb={1}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.itemName} noWrap>{x?.description}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.requestNo}>-</Typography>
                                            </Grid>
                                            <Grid item xs={4} justifyContent={"end"} display={"flex"}>
                                                <Typography className={classes.itemName}>{" - "} {itemDetails?.list?.symbol} {x?.amount}</Typography>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Box mt={1} mb={1}>
                                    <Divider />
                                </Box>
                            </>
                        }
                    </Box>

                    <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                        <Typography className={classes.finalamttext}>{t("Final Amount")}</Typography>
                        <Typography className={classes.finalamttext}>{itemDetails?.list?.symbol} {itemDetails?.list?.total_amount_after_tax ?? 0}</Typography>
                    </Box>

                </Box>
                {itemDetails?.costing_status === "Pending" && showNote ?
                    <Box>
                        <Box mt={2} className={classes.notesBox} mb={2}>
                            <Box p={2} display={"flex"} justifyContent={"space-between"}>
                                <Typography className={classes.notesLabel}>{t("Notes")}</Typography>
                                <Box onClick={() => setShowNote(false)}><Typography className={classes.removeTxt}>{t("Remove")}</Typography></Box>
                            </Box>
                            <Divider />
                            <Box p={1}>
                                <TextBox
                                    label=""
                                    placeholder={t("Type Notes here")}
                                    value={notesData}
                                    onChange={(e) => handleChangeNotes(e?.target?.value)}
                                    border={0}
                                    multiline
                                />
                            </Box>
                        </Box>
                    </Box>
                    : itemDetails?.costing_status === "Pending" &&
                    <Box mt={2} onClick={() => setShowNote(true)} mb={2}>
                        <Typography className={classes.addNote}>+ {t("Add Note")}</Typography>
                    </Box>
                }
                <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
                    {itemDetails?.costing_status === "Pending" &&
                        <Box className={classes.btnfix}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth className={classes.rejectbtn} onClick={() => updateRequest("Rejected")}>{t("Reject")}</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="contained" fullWidth className={classes.approvebtn} onClick={() => updateRequest("Approved")}>{t("Approve")}</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Container>
            </Box>
        </Container>
    )
}
export default withNamespaces("request")(BomDetails)