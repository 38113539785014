import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const MyBookingListStyle = makeStyles((theme) => ({
    backbtn: {
        height: 32,
        width: 32,
        background: "#00000082",
        color: "#fff",
        position: "relative",
        top: "30px",
        left: "30px",
        transform: "translate(-50%,-50%)",
        zIndex: 3
    },
    navigate: {
        background: "transparent",
        height: 0
    },
    body_content: {
        marginTop: 70
    },
    day_to_due: {
        background: "#F1E6FE",
        color: "#5D427F",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        padding: 4,
        borderRadius: 4,
        textTransform: "uppercase"
    },
    header: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    card: {
        background: "#fff",
        borderRadius: '4px',
        width: 370,
    },
    prev_month: {
        background: "#fff",
        borderRadius: '4px',
        width: 370,
    },
    first: {
        background: "#F5F7FE",
        borderRadius: '4px',
        padding: 12,
        width: "100%"
    },
    title: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    content: {
        fontSize: "1.25rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    secondarY_content: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    over_due: {
        color: theme.palette.error.main,
        background: theme.palette.error.light,
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        padding: 4,
        borderRadius: 4,
        textTransform: "uppercase"
    },
    viewinvoice: {
        fontSize: "0.875rem",
        height:20,
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        textAlign: "center",
        background:"none",
        textTransform:"capitalize",
        '&:hover':{
            background:"none",
        }
    },
    container: {
        background: "#F5F7FA",
        padding: "0 !important",
        height: "calc(100vh - 1px)",
        overflow: "auto"
    },
    noPastBooking:{
        fontSize: "1rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().bold,
    },
    centerContent:{
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)",
        position:"absolute"
    },
    backbtntext:{
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        cursor:"pointer"
    }
}))