import { useApolloClient } from "@apollo/client";
import { Box, Container, Divider, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useLocation } from "react-router-dom";
import { DialogDrawer, LoderSimmer } from "../../components";
import { AlertContext } from "../../contexts";
import { GET_PROPERTY_DETAILS_FOR_MANAGEMENT } from "../../graphql/queries";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, remCalculate } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import ManagementCard from "./components/managementCard";
import { SimpleAccordion } from "./components/simpleAccordion";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import DepartmentCard from "./components/departmentCard";
import MyCompanyIcon from "./utils/myCompanyIcon";
import MyOrganizationIcon from "./utils/myOrganaization";
import { ViewCard } from "./components/viewCard";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: '#F6F6F6',
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    textAlign: "center",
    marginTop: "5%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  notificationroot: {
    padding: "0px 8px 8px 12px",
  },
  boxSection: {
    width: "100%",
    padding: "0px 16px",
    height: "calc(100vh - 57px)",
    overflow: "auto"
  },
  avatarImg: {
    width: "80px",
    height: "80px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  avatarImgBox: {
    marginTop: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center"
  },
  name: {
    color: theme.typography.color.primary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    textAlign: "center",
    marginTop: "12px"
  },
  // title: {
  //   fontSize: "0.75rem",
  //   color: "#fff",
  //   fontFamily: FontFamilySwitch().italic,
  //   backgroundColor: theme.palette.background.glaucous,
  //   padding: "4px 8px",
  //   borderRadius: theme.palette.borderRadius

  // },
  department: {
    fontSize: "0.75rem",
    color: "#fff",
    fontFamily: FontFamilySwitch().italic,
    backgroundColor: theme.palette.background.brown,
    padding: "4px 8px",
    borderRadius: theme.palette.borderRadius

  },
  subtitles: {
    gap: "8px",
    marginTop: "12px"
  },
  reportingname: {
    color: theme.typography.color.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    textAlign: "center",
    marginTop: "12px"
  },
  topNavfix: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 999
  },
  managerCardBox: {
    marginTop: "35px",
    backgroundColor: theme.typography.color.tertiary1,
    padding: "16px",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",

  },
  reportingBox: {
    position: "relative",
    background: "#fff"
  },
  accordianBox: {
    marginTop: "24px",



  },
  accordianBottom: {
    marginBottom: "18px",
    backgroundColor: theme.typography.color.tertiary1,
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    position: "relative",
    background: "#fff"
  },
  drawer: {
    "& .MuiDrawer-root": {
      borderRadius: "12px"
    }
  },
  viewAll: {
    color: "#B20606",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    textAlign: "center",
    marginTop: "16px",
    cursor: "pointer"
  },
  dividerSide: {
    position: "absolute",
    color: "red",
    height: "100%",
    borderLeft: "4px solid",
    padding: "4px 0px",
    display: "flex",
    justifyContent: "center",
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px"
  },
  noDataBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  noDataText: {
    fontSize: remCalculate(14),
    fontFamily: FontFamilySwitch().bold
  }
}));

const ManagementDetails = (props) => {
  const { t = () => false, handleTopBarState } = props

  const committeObj = {
    myCompany: {
      accordianTitle: "Company Level",
      accordianContent: [],
      key: "Company Level"
    },
    myOrg: {
      accordianTitle: "Organization Level",
      accordianContent: [],
      key: "Organization Level"
    }
  }

  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const searchURL = useLocation().search;
  const propertyId = new URLSearchParams(searchURL).get("propertyId");
  const propertyName = new URLSearchParams(searchURL).get("name");
  const history = useHistory()
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  // const [offset, setOffset] = React.useState(0);
  // const [search, setSearch] = React.useState("");
  const client = useApolloClient();
  const clientID = JSON.parse(localStorage.getItem(LocalStorageKeys.client))
  const [managerCard, setManagerCard] = React.useState(false)
  // const [teamCard, setTeamCard] = React.useState(false)
  const [screenData, setScreenData] = React.useState(committeObj)
  const [myOrgData, setMyOrgData] = React.useState({
    profile: {},
    myCompanyExpand: false,
    myOrgExpand: false,
    viewCardBool: false,
    selectedViewData: "",
    viewReporter: false
  })
  const dividerColor = {
    "Reporting": "#666666",
    "Property Level": "#5078E1",
    "Company Level": "#896DB3",
    "Organization Level": "#6DAFB3",
  }

  const viewTexts = {
    "Company Level": t("View All Company"),
    "Organization Level": t("View All Organization"),
  }

  React.useEffect(() => {
    getCommitteDetails()
    // eslint-disable-next-line
  }, [])

  const getContactDetails = (offset, search ="", filter) => {
    client.query({
      query: GET_PROPERTY_DETAILS_FOR_MANAGEMENT(),
      fetchPolicy: 'network-only',
      variables: {
        propertyID: propertyId,
        searchText: search,
        client: clientID?.id,
      }
    })
      .then((response) => {
        if (filter) {
          setData(response.data?.management_committee);
        } else {
          setData(data.concat(response.data?.management_committee));
        }
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  React.useEffect(() => {
    handleTopBarState({
      text: propertyName
    })
    getContactDetails();
    // eslint-disable-next-line
  }, []);

  // const fetchMoreData = () => {
  //   setOffset(offset + 10);
  //   getContactDetails(offset + 10, search);
  // };

  // const handleSearch = (value) => {
  //   setSearch(value)
  //   getContactDetails(0, value, true)
  // }

  const getCommitteDetails = () => {
    const payload = {
      is_initial: true
    };
    NetworkCall(
      `${config.api_url}/my_organisation`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setMyOrgData({ ...myOrgData, profile: res?.data?.data })
        getCommitteDetailsTeams(res?.data?.data)
      })
      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  }

  const getCommitteDetailsTeams = (data) => {
    const payload = {
      limit: 5,
      offset: 0
    };
    NetworkCall(
      `${config.api_url}/my_organisation/my_teams`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        const companyConstructedData = data?.company?.data?.map((e) => {
          return {
            accordianTitle: e?.contact_name,
            ...e
          }
        })

        const orgConstructedData = data?.organization?.data?.map((e) => {
          return {
            accordianTitle: e?.contact_name,
            ...e
          }
        })

        setScreenData({
          ...screenData,
          myCompany: {
            ...screenData?.myCompany,
            accordianContent: companyConstructedData,
            count: data?.company?.count
          },
          myOrg: {
            ...screenData?.myOrg,
            accordianContent: orgConstructedData,
            count: data?.organization?.count
          }
        })
      })
      .catch((error) => {
        console.log(error);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  }



  const handleViewData = (data, type) => {
    setMyOrgData({
      ...myOrgData,
      viewCardBool: true,
      selectedViewData: data,
      viewReporter: type === "reporting" ? true : false
    })
  }

  const handleViewClose = () => {
    setMyOrgData({
      ...myOrgData,
      viewCardBool: false,
      selectedViewData: "",
      viewReporter: false
    })
  }

  const viewRedirect = (key, data) => {
    history.push({
      pathname: Routes.managementDetailsView,
      state: {
        viewkey: key,
        data: data
      }
    })
  }


  const components = (item, content, index) => {
    switch (item?.key) {
      case "Company Level":
        return (
          <>
            {
              <DepartmentCard
                t={t}
                title={content?.accordianTitle}
                hidden={item?.accordianContent?.length < 5 ? item?.accordianContent?.length === index + 1 ? false : true : true}
                // onClick={() => setTeamCard(true)}
                icon={<MyCompanyIcon />}
                viewMore={true}
                viewClick={() => handleViewData(content)}
              />
            }
          </>

        );
      case "Organization Level":
        return (
          <>
            <DepartmentCard
              t={t}
              title={content?.accordianTitle}
              hidden={item?.accordianContent?.length < 5 ? item?.accordianContent?.length === index + 1 ? false : true : true}
              // onClick={() => setTeamCard(true)}
              icon={<MyOrganizationIcon />}
              viewMore={true}
              viewClick={() => handleViewData(content)}
            />
          </>

        );
      default:
        break;
    }


  }

  return (
    <Container
      className={classes.root}
      maxWidth="sm"
      style={{ height: size?.height }}
    >
      {/* <TitleBar
        text={propertyName}

        goBack={() => history.goBack()}
      /> */}
      {
        loading ?
          <LoderSimmer count={4} /> :
          <div style={{ padding: "0px 16px 16px 16px" }}>
            {/* <Box display="flex" alignItems="center" p={1}>
          <Box flexGrow={1}>
            <TextField
              size="small"
              variant="outlined"

              placeholder={t("Search")}
              style={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
              value={search}
              fullWidth
              className={classes.search}
            />
          </Box>
        </Box> */}
            {/* {loading ? (
              <LoderSimmer count={10} />
            ) : (
              <>
                {data?.length > 0 ? (
                  <>
                    <InfiniteScroll
                      dataLength={data.length}
                      next={fetchMoreData}
                      hasMore={true}
                      height={size?.height - 120}
                    >
                      {data?.map((val) => {
                        return <ManagementList t={t} data={val} />;
                      })}
                    </InfiniteScroll>
                  </>
                ) : (
                  <Typography className={classes.title}>{t("NoDataFound")}</Typography>
                )}
              </>
            )} */}
            <Box className={classes.accordianBox} marginTop={"24px"}>
              <Box key={0} className={classes.accordianBottom}>
                <Divider fullWidth orientation="vertical" flexItem style={{ borderColor: dividerColor["Property Level"] }} className={classes.dividerSide} />
                <SimpleAccordion
                  headText={t("Property Level")}
                  defaultExpanded={true}
                  dividerColor={dividerColor}
                  // onClick={() => expandAccodion(item?.key)}
                  count={data?.length ?? 0}
                  content={
                    <>
                      {
                        data?.length ?
                          <Grid container rowGap={2} sx={{ maxHeight: 300, overflow: "auto" }}>
                            {
                              data?.map((val) => {
                                return (

                                  <Grid item xs={12}>
                                    <ManagementCard
                                      title={val?.committee_role?.role_name}
                                      subTitle={val?.resident_profileByID?.first_name}
                                      avatar={val?.resident_profileByID?.image_url}
                                      t={t}
                                      open={managerCard}
                                      // onClick={() => handleViewData(myOrgData?.profile, 'reporting')}
                                      onClose={() => setManagerCard(false)}
                                      dialogData={myOrgData?.profile}
                                      hideViewMore
                                      extraTitle={val?.unitByID?.unit_no}
                                      mailPhone={{
                                        email_id: val?.resident_profileByID?.email_id,
                                        country_code: val?.resident_profileByID?.mobile_no_country_code,
                                        mobile_no: val?.resident_profileByID?.mobile_no
                                      }}
                                    />
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                          :
                          <Box className={classes.noDataBox}>
                            <Typography className={classes.noDataText}>{t("No Data Found")}</Typography>
                          </Box>
                      }
                    </>
                  }
                />
              </Box>
            </Box>

            <Box className={classes.accordianBox} marginTop={"24px"}>
              {Object.values(screenData)?.map((item, index) => {
                return (
                  <>
                    <Box key={index} className={classes.accordianBottom}>
                      <Divider fullWidth orientation="vertical" flexItem style={{ borderColor: dividerColor[item?.key] }} className={classes.dividerSide} />
                      <SimpleAccordion
                        headText={t(item?.accordianTitle)}
                        defaultExpanded={false}
                        dividerColor={dividerColor}
                        // onClick={() => expandAccodion(item?.key)}
                        count={item?.count ?? 0}
                        content={
                          <>
                            <Grid container spacing={1.3}>
                              {
                                item?.accordianContent?.length > 0 ?
                                  <>
                                    {
                                      item?.accordianContent?.map((content, index) => {
                                        return (
                                          <Grid item xs={12}>
                                            <Box>
                                              {components(item, content, index)}
                                            </Box>
                                          </Grid>
                                        )
                                      })
                                    }
                                  </>
                                  :
                                  <Box className={classes.noDataBox}>
                                    <Typography className={classes.noDataText}>{t("No Data Found")}</Typography>
                                  </Box>
                              }
                            </Grid>
                            {item?.accordianContent?.length > 1 ?
                              <Typography className={classes.viewAll}
                                onClick={() => item?.key === "Company Level" ? viewRedirect("myCompany", myOrgData?.profile) :
                                  item?.key === "Organization Level" ? viewRedirect("myOrganization", myOrgData?.profile) : viewRedirect("myTeam")}>
                                {t(viewTexts[item?.key])}
                              </Typography> : ""}
                          </>

                        }

                      />
                    </Box>
                  </>
                )

              })}
            </Box>
          </div>
      }

      <DialogDrawer
        height={"370px"}
        maxWidth={"sm"}
        open={myOrgData?.viewCardBool}
        onClose={() => handleViewClose()}
        header={t("View profile")}
        component={
          <ViewCard
            viewData={{
              avatar: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.image_url : myOrgData?.selectedViewData?.reporting_manager?.image_url,
              name: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.first_name : myOrgData?.selectedViewData?.reporting_manager?.first_name,
              jobTitle: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.job_title : myOrgData?.selectedViewData?.reporting_manager?.job_title,
              department: !myOrgData?.viewReporter ? myOrgData?.selectedViewData?.department?.name : myOrgData?.selectedViewData?.reporting_manager?.first_name,
              reportingTo: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.reporting_manager?.reporting_to ?? "-" : myOrgData?.selectedViewData?.reporting_manager?.first_name ?? "-",
              email: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.reporting_manager?.email_id : myOrgData?.selectedViewData?.email_id,
              phone: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.phone : myOrgData?.selectedViewData?.phone,
              mobile_no_country_code: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.mobile_no_country_code : myOrgData?.selectedViewData?.mobile_no_country_code,
              mobile_no: myOrgData?.viewReporter ? myOrgData?.selectedViewData?.mobile_no : myOrgData?.selectedViewData?.mobile_no
            }}
            t={t}
          />
        }
      />
    </Container>
  );
};
export default withNamespaces("management")(ManagementDetails)