import React from "react";
import AddPass  from "./addPass";
import ContextProvider from "./passContext";
const PasscreateParent = () => {
  return (
    <ContextProvider>
      <AddPass />
    </ContextProvider>
  );
};

export default PasscreateParent;
