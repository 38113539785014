import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const amenitiesBookingStyle = makeStyles((theme) => ({
    flexWithPadding: {
        display: "flex",
        padding: "8px",
        borderBottom: "1px solid #c1c1c1",
        alignItems: "center"
    },
    modalTitle: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            height: "50%"
        },
    },
    
}))