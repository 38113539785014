import React from "react";
import { withBottombar } from "../../HOCs";
import FeedbackList from "./feedbackList";

class FeedbackParent extends React.Component {
    render() {
        return <FeedbackList {...this.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(FeedbackParent, props);
