import {
  Box, Button, Container, Divider, Grid, InputAdornment, Stack, TextField, Typography
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import {
  AccessDeniedSVG,
  DeleteIcon,
  FileDetails, GridView,
  ListView
} from "../../assets";
import SearchIcon from "../../assets/searchIcon";
import {
  DialogDrawer, FolderComponent, LoderSimmer, TextBox
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";

const FileManager = ({ t = () => false, handleTopBarState }) => {
  // classes
  const classes = useStyles();
  // context
  const alert = React.useContext(AlertContext);
  // usedimension
  const size = useWindowDimensions();
  const history = useHistory();
  // const typeData = [
  //   {
  //     name: "General",
  //   },
  //   {
  //     name: "My Documents",
  //   },
  // ];
  const detailsFile = (t, data) => [
    {
      title: t("DATE_CREATED_ON"),
      sub: moment(data?.created_at)
        .tz(moment.tz.guess())
        .format("DD MMM YY"),
    },
    {
      title: t("CREATED_BY"),
      sub: data?.created_by ?? "-"
    },
    {
      title: t("NUMBER_OF_FILES"),
      sub: data?.no_of_files ?? "-",
    },
  ];
  const [state, setState] = useState({
    tabValue: 0,
    listType: 2,
    dialog: false,
    folderName: "",
    dialogType: 0,
    order_by: false,
    searchText: "",
    details: {},
    offset: 0,
    error: {
      folderName: "",
    },
  });
  const [loader, setLoader] = useState(false)
  const [list, setList] = useState({
    data: [],
    count: 0,
    property_id: []
  })
  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const viewType = (value) => {
    return state?.listType === value ? "#5078E1" : "#98A0AC";
  };

  const renameFile = () => {
    setState({
      ...state,
      dialog: true,
      folderName: "folderName",
      is_rename: true,
      dialogType: 0,
    });
  };
  const deleteFile = () => {
    setState({
      ...state,
      dialog: true,
      dialogType: 1,
      is_rename: false,
    });
  };
  const viewDetails = (data) => {
    setState({
      ...state,
      dialog: true,
      dialogType: 2,
      is_rename: false,
      details: data?.data
    });
  };
  const dialogTitle = {
    0: t("Create_Folder"),
    1: t("Delete_File"),
    2: t("Folder_Details"),
  };
  const getproperty = () => {
    NetworkCall(
      `${config.api_url}/agreement-property-info/get`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let propertyFilter = response.data.data?.propertyInfo.map((val) => {
          return {
            label: val?.property_name,
            value: val?.property_id,
          };
        });
        getDocuments(propertyFilter?.map(x => x?.value), "", state?.order_by, 0)

      })
      .catch((error) => {
        if (error?.code === 503) {
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some thing Went wrong."),
          });
        }
      });
  };
  const getDocuments = (id, search, order_by, offet) => {
    if (!offet > 0) {
      setLoader(true)
    }
    const data = {
      "property_ids": id ?? [],
      "is_private": [false],
      "search": search ?? undefined,
      "order_by": order_by ? "desc" : "asc" ?? "desc",
      offset: offet,
      limit: 10
    };
    NetworkCall(
      `${config.api_url}/asset_management/property_access_directories`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.data?.length > 0) {
          setList({
            data: offet > 0 ? list?.data.concat(response?.data?.data?.data) : response?.data?.data?.data,
            count: response?.data?.data?.count,
            property_id: id ?? []
          })
        }
        else {
          setList({
            property_id: id ?? [],
            data: offet > 0 ? list?.data.concat(response?.data?.data?.data) : response?.data?.data?.data,
            count: 0,
          })
        }
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        if (error?.code === 503) {
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some thing Went wrong."),
          });
        }
      });
  };
  const onPageChange = (data) => {
    history.push(Routes.fileManagerView + "?Id=" + data?.id);
  }
  const orderFunction = () => {
    getDocuments(list?.property_id, state?.searchText, !state?.order_by, 0)
    updateState("order_by", !state?.order_by)
  }
  const searchFunction = (value) => {
    getDocuments(list?.property_id, value, state?.order_by, 0)
    updateState("searchText", !state?.order_by)
  }
  const fetchMoreData = () => {
    getDocuments(list?.property_id, state?.searchText, state?.order_by, state?.offset + 15);
    updateState("offset", state?.offset + 15);
  };
  const components = {
    0: (
      <Box>
        <TextBox
          label={t("Folder_Name")}
          placeholder={t("Enter_Folder_Name")}
          value={state.folderName}
          onChange={(value) => updateState("folderName", value.target.value)}
          isError={state?.error?.folderName?.length > 0}
          errorMessage={state?.error?.folderName}
          isRequired
        />
        <Box height={"46px"} />
        <Button variant="contained" className={classes.create}>
          {t("Submit")}
        </Button>
      </Box>
    ),
    1: (
      <Box textAlign="center">
        <DeleteIcon />
        <Box height={"20px"} />
        <Typography className={classes.deleteTitle}>
          {t("delete_folder")}
        </Typography>
        <Box height={"20px"} />
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Button className={classes.outLine}>{t("Cancel")}</Button>
          <Box width={"15px"} />
          <Button className={classes.create}>{t("Yes_Delete")}</Button>
        </Box>
      </Box>
    ),
    2: (
      <Box>
        <Box display="flex" alignItems="center" className={classes.detailsBox}>
          <FileDetails />
          <Box width={"16px"} />
          <Box>
            <Typography className={classes.viewFileTilte}>
              {state?.details?.name ?? ""}
            </Typography>
            <Box height={"8px"} />
            <Typography className={classes.viewFileSub}>
              Last Modified on {moment(state?.details?.updated_at).format('YYYY/MM/DD')}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={1}>
            {detailsFile(t, state?.details)?.map((x) => {
              return (
                <Grid item xs={4}>
                  <Box p={2}>
                    <Typography className={classes.viewDetailsTilte} noWrap>
                      {x?.title}
                    </Typography>
                    <Box height={"8px"} />
                    <Typography className={classes.viewDetailsSub} noWrap>
                      {x?.sub}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Divider />
        </Box>
      </Box>
    ),
  };
  useEffect(() => {
    handleTopBarState({
      text: t("File Manager")
    })
    getproperty()
    // eslint-disable-next-line 
  }, [])

  return (
    <div>
      <Container
        maxWidth="sm"
        className={classes.root}
      // style={{ height: size.height - 120 }}
      >
        {/* <TitleBar text={t("File_Manager")} goBack={() => history.goBack(-1)} /> */}
        <Box className={classes.main}>
          <TextField
            size="small"
            variant="outlined"
            placeholder={t("Search")}
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
            }}
            fullWidth
            className={classes.search}
            onChange={(e) => searchFunction(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box height={"16px"} />
          {/* toggle button */}
          {/* <Box className={classes.navTopBar}>
            <Grid container>
              {typeData?.map((x, i) => {
                return (
                  <Grid
                    item
                    xs={6}
                    className={classes.topBtn}
                    onClick={() => updateState("tabValue", i)}
                    style={{
                      backgroundColor:
                        i === state?.tabValue ? "#5078E1" : "#fff",
                      color: i === state?.tabValue ? "#fff" : "#98A0AC",
                    }}
                  >
                    {x?.name}
                  </Grid>
                );
              })}
            </Grid>
          </Box> */}
          {/* <Box height={"21px"} /> */}
          {/* body */}
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography className={classes.folderName}>
              {t("Folders")}
            </Typography>
            <Stack direction="row" spacing={2}>
              {[
                <AccessDeniedSVG color={state?.order_by ? "#5078E1" : "#98A0AC"} onClick={orderFunction} />,
                <ListView color={viewType(1)} onClick={() => updateState("listType", 1)} />,
                <GridView color={viewType(2)} onClick={() => updateState("listType", 2)} />,
              ]?.map((x, i) => {
                return (
                  <Box onClick={x?.onClick}>{x}</Box>
                );
              })}
            </Stack>
          </Box>
          <Box height={"16px"} />
          <Box>
            <InfiniteScroll dataLength={list?.data?.length}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 258}>
              {state?.listType === 2 ?
                loader ? <LoderSimmer count={10} card size="76px" /> : !list?.data?.length > 0 ?
                  <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <Typography className={classes.folderName}>{t("No Data Found")}</Typography>
                  </Box>
                  :

                  <Grid container spacing={2}>
                    {
                      list?.data?.map((x) => {
                        return (
                          <Grid item md={3} sm={4} xs={4}>
                            <FolderComponent
                              data={{
                                fileNmae: x?.name,
                                createdAt: moment(x?.created_at)
                                  .tz(moment.tz.guess())
                                  .format("DD MMM YY"),
                                data: x

                              }
                              }
                              t={t}
                              type={state?.listType}
                              tabValue={state?.tabValue}
                              renameFile={renameFile}
                              deleteFile={deleteFile}
                              viewDetails={viewDetails}
                              onView={onPageChange}
                            />
                          </Grid>
                        )
                      })
                    }

                  </Grid>
                : loader ? <LoderSimmer count={10} card size="76px" /> : !list?.data?.length > 0 ?
                  <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                    <Typography className={classes.folderName}>{t("No Data Found")}</Typography>
                  </Box>
                  :
                  <Box className={list?.data?.length > 1 ? classes.listView : classes.list}>
                    <Grid container spacing={2}>
                      {
                        list?.data?.map((x, index) => {
                          return (
                            <Grid item xs={12} className={classes.paddingTop}>
                              <FolderComponent
                                data={{
                                  fileNmae: x?.name,
                                  createdAt: moment(x?.created_at)
                                    .tz(moment.tz.guess())
                                    .format("DD MMM YY"),
                                  data: x
                                }
                                }
                                t={t}
                                type={state?.listType}
                                tabValue={state?.tabValue}
                                renameFile={renameFile}
                                deleteFile={deleteFile}
                                viewDetails={viewDetails}
                                onView={onPageChange}
                                is_divider={list?.data?.length === index + 1 ? false : true}
                              />
                            </Grid>
                          )
                        })
                      }

                    </Grid>
                  </Box>
              }
            </InfiniteScroll>

          </Box>

          <DialogDrawer
            height={"210px"}
            open={state?.dialog}
            header={
              state?.is_rename
                ? t("Rename_Folder")
                : dialogTitle[state?.dialogType]
            }
            padding={state?.dialogType === 2 ? "0px" : "16px"}
            component={<>{components[state?.dialogType]}</>}
            onClose={() => updateState("dialog", false)}
          />
          {/* <Fab color="primary" aria-label="add" className={classes.addBtn}>
            <AddIcon className={classes.addIcon} onClick={() => addFile()} />
          </Fab> */}
        </Box>
      </Container>
    </div>
  );
};
export default withNamespaces("fileManager")(FileManager);
