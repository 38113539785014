import * as React from "react"
const StandardRateIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 114302" transform="translate(21667 434)">
      <rect
        width={24}
        height={24}
        fill="#edf7f8"
        data-name="Rectangle 57697"
        rx={6}
        transform="translate(-21667 -434)"
      />
      <path
        fill="#6dafb3"
        d="M-21656.05-428a5.95 5.95 0 1 0 1.05 11.8 5.95 5.95 0 1 0 0-11.7 5.943 5.943 0 0 0-1.05-.1Zm3.758 1.343a4.9 4.9 0 0 1 0 9.215 5.939 5.939 0 0 0 0-9.215Zm-4.508 2.157h1.8a.525.525 0 1 1 0 1.05h-1.369l-.1.525h.5a1.664 1.664 0 0 1 1.669 1.663 1.633 1.633 0 0 1-1.75 1.662 2.567 2.567 0 0 1-1.55-.465.525.525 0 0 1 .65-.825 1.586 1.586 0 0 0 .9.24c.32 0 .7-.106.7-.613a.613.613 0 0 0-.612-.613h-1.138a.526.526 0 0 1-.516-.622l.3-1.575a.525.525 0 0 1 .516-.427Z"
      />
    </g>
  </svg>
)
export default StandardRateIcon
