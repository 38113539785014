import * as React from "react";
import { Stack, Box, Typography } from "@mui/material";
import FilterSvg from "../../assets/filterSvg";
import { useStyles } from "./style";
import InvestmentCard from "./component/investmentCard";
import InvestmentListCard from "./component/investmentListCard";
import { withNamespaces } from "react-i18next";
import { NetworkCall } from "../../networkcall";
import { AlertProps, monthList, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";

const Investment = (props) => {
  const intervalOption = [
    { label: "Yearly", value: "Yearly" },
    { label: "Monthly", value: "Monthly" },
  ];

  const yearOption = [
    { label: "2023", value: "2023" },
    { label: "2022", value: "2022" },
    { label: "2021", value: "2021" },
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
  ];

  const monthOption = [
    { label: "Jan", value: "1" },
    { label: "Feb", value: "2" },
    { label: "Mar", value: "3" },
    { label: "Apr", value: "4" },
    { label: "May", value: "5" },
    { label: "Jun", value: "6" },
    { label: "Jul", value: "7" },
    { label: "Aug", value: "8" },
    { label: "Sep", value: "9" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" },
  ];
  const { t=()=>false } = props
  const classes = useStyles();
  const [listing, setListing] = React.useState([]);
  const [loading, setLoading] = React.useState(null)
  const [offset, setOffset] = React.useState(0);
  const [listLoading, setListLoading] = React.useState(true);
  const [selectedCategory, setSelectedCategory] = React.useState({ label: "All", value: null })
  const [year, setYear] = React.useState(yearOption[0]);
  const [month, setMonth] = React.useState(monthOption[0]);
  const [graph, setGraph] = React.useState([]);
  const [isMonthly, setIsMonthly] = React.useState(false);
  const [duration, setDuration] = React.useState(intervalOption[0]);

  //CATEGORY API

  const loadOptions = async (search = "", array, type) => {
    setLoading(type);
    let result, offset, payload = 0;
    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }
    payload = {
      offset: offset,
      limit: 10,
      search
    }
    switch (type) {
      case "selectedCategory":
        result = await NetworkCall(
          `${config?.api_url}/statistics-category-master`,
          NetWorkCallMethods.post,
          payload,
          null,
          true,
          false
        )
        setLoading(null);
        return {
          options: offset === 0 ? [{ label: "All", value: null }, ...result?.data?.data] : [...result?.data?.data],
          hasMore:
            array?.length + result?.data?.data?.length <
            result?.data?.count,
        };
      default:
        return { options: [] };
    }
  };

  // LISTING API

  const getListing = (fetchMore, offset, category) => {
    const payload = {
      offset: offset,
      limit: 10,
      type: "Investments",
      category_id: category?.value
    }
    NetworkCall(
      `${config?.api_url}/statistics_listing/listing`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (fetchMore) {
          setListing((res) => [...res, ...response?.data?.data?.data])
        } else {
          setListing(response?.data?.data?.data)
          setListLoading(false);
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  const fetchMoreData = () => {
    const tempOffset = offset + 10;
    setOffset(tempOffset);
    getListing(true, tempOffset);
  };

  const changeDuration = (i) => {
    if (i.value === "Monthly") {
      setDuration(intervalOption[1]);
      setIsMonthly(true);
    }
    else {
      setDuration(intervalOption[0]);
      setIsMonthly(false);
    }
  };


  // HANDLE YEAR
  const handleYear = (value) => {
    setYear(value)
    getGraphDetails(selectedCategory, duration, value, month)
  }

  // HANDLE MONTH
  const handleMonth = (value) => {
    setMonth(value)
    getGraphDetails(selectedCategory, duration, year, value)
  }
  // HANDLE DURATION
  const handleDuration = (value) => {
    changeDuration(value)
    getGraphDetails(selectedCategory, value, year, month)
  }
  //HANDLE CATEGORY
  const handleCategory = (value) => {
    setSelectedCategory(value)
    getGraphDetails(value, duration, year, month);
    getListing(false, 0, value);
  }


  const handleChangePeriod = (key, value) => {
    switch (key) {
      case "month":
        handleMonth(value)
        break;
        case "year":
        handleYear(value)
        break;
    
      default:
        break;
    }
  } 

  // GRAPH API

  const getGraphDetails = (category, duration, year, month = null) => {
    let payload = {
      year: parseInt(year?.value),
      type: "Investments",
      category: category?.value
    }
    if (duration?.value === intervalOption?.[1].value) {
      payload["month"] = parseInt(month?.value)
    }

    NetworkCall(
      `${config?.api_url}/statistics_spending/spending`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const array = response?.data?.data;
        if (array?.length > 0) {
          setGraph({
            list: response?.data?.data?.filter((val)=> val?.month !== 12)?.map((x) => {
              return {
                month: payload?.month ? x?.day : monthList[x?.month - 1],
                amount: x?.amount ?? 0,
                current_value: x?.current_value ?? 0,
                category: x?.category,
                sub_category: x?.sub_category
              }
            })
          })
        } else {
          setGraph([])
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  React.useEffect(() => {
    getListing(false, 0);
    getGraphDetails(selectedCategory, duration, year, month)
    // eslint-disable-next-line
  }, []);

  return (
    // <Container maxWidth="sm" height={size?.height-68}>
    <Box className={classes.root}>
      {/* <StatisticsTab /> */}
      {/* INVESTMENT */}
      <InvestmentCard
        handleCategory={handleCategory}
        selectedCategory={selectedCategory}
        month={month}
        year={year}
        handleMonth={handleMonth}
        handleYear={handleYear}
        handleDuration={handleDuration}
        loadOptions={loadOptions}
        dropDownloading={loading}
        intervalOption={intervalOption}
        duration={duration}
        setDuration={setDuration}
        monthOption={monthOption}
        yearOption={yearOption}
        t={t}
        data={graph}
        isMonthly={isMonthly}
        handleChangePeriod={handleChangePeriod}
      />

      {/* ACTIVITY */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={"16px"}
      >
        <Typography className={classes.heading}>{t("Activity")}</Typography>
        <FilterSvg />
      </Stack>
      <InvestmentListCard
        listing={listing}
        fetchMoreData={fetchMoreData}
        loading={listLoading}
      />
      {/* CREATE INVESTMENT */}
    </Box>
    // </Container>
  );
};

export default withNamespaces("statistics")(Investment);
