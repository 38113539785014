export const Logins = (props) => (
<svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <linearGradient id="linear-gradient" x1="0.5" y1="-0.337" x2="0.5" y2="-1.221" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#e5a505"/>
      <stop offset="0.01" stop-color="#e9a804"/>
      <stop offset="0.06" stop-color="#f4b102"/>
      <stop offset="0.129" stop-color="#fbb600"/>
      <stop offset="0.323" stop-color="#fdb700"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="0.227" y1="1.606" x2="0.745" y2="0.673" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fede00"/>
      <stop offset="0.639" stop-color="#ffd500"/>
      <stop offset="1" stop-color="#ffd000"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3" x1="0.292" y1="3.08" x2="0.708" y2="1.42" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fcfcfc"/>
      <stop offset="0.495" stop-color="#f4f4f4"/>
      <stop offset="0.946" stop-color="#e8e8e8"/>
      <stop offset="1" stop-color="#e8e8e8"/>
    </linearGradient>
    <linearGradient id="linear-gradient-4" x1="0.292" y1="-0.92" x2="0.708" y2="-2.58"/>
  </defs>
  <g id="Group_108942" data-name="Group 108942" transform="translate(20189 7485)">
    <g id="Rectangle_55784" data-name="Rectangle 55784" transform="translate(-20189 -7485)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
      <rect width="24" height="24" stroke="none"/>
      <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
    </g>
    <g id="icons8-access" transform="translate(-20188.764 -7486.126)">
      <path id="Path_101054" data-name="Path 101054" d="M11.34,28.457a.934.934,0,0,0,.468.81v1.189a.934.934,0,0,0,0,1.619V32.6a1.083,1.083,0,0,1-.267.725l-.646.744H9.936l-.665-.739A1.127,1.127,0,0,1,9,32.589V26.22h2.808v1.427A.934.934,0,0,0,11.34,28.457Z" transform="translate(-4.256 -11.821)" fill="url(#linear-gradient)"/>
      <path id="Path_101055" data-name="Path 101055" d="M6.148,4h0A5.157,5.157,0,0,0,1,9.148c0,.516,0,4.039,0,4.039a1.577,1.577,0,0,0,1.577,1.577H9.712A1.589,1.589,0,0,0,11.3,13.18s0-3.516,0-4.032A5.157,5.157,0,0,0,6.148,4Zm0,2.34A1.638,1.638,0,1,1,4.51,7.978,1.638,1.638,0,0,1,6.148,6.34Z" transform="translate(0)" fill="url(#linear-gradient-2)"/>
      <path id="Path_101056" data-name="Path 101056" d="M35.36,16.616H26.468A.468.468,0,0,1,26,16.148v-4.68A.468.468,0,0,1,26.468,11H35.36a.468.468,0,0,1,.468.468v4.68A.468.468,0,0,1,35.36,16.616Z" transform="translate(-13.3 -3.724)" fill="#abb4be"/>
      <path id="Path_101057" data-name="Path 101057" d="M28,16.744V13h7.956v3.744Z" transform="translate(-14.364 -4.788)" fill="url(#linear-gradient-3)"/>
      <path id="Path_101058" data-name="Path 101058" d="M35.36,32.616H26.468A.468.468,0,0,1,26,32.148v-4.68A.468.468,0,0,1,26.468,27H35.36a.468.468,0,0,1,.468.468v4.68A.468.468,0,0,1,35.36,32.616Z" transform="translate(-13.3 -12.236)" fill="#abb4be"/>
      <path id="Path_101059" data-name="Path 101059" d="M28,32.744V29h7.956v3.744Z" transform="translate(-14.364 -13.3)" fill="url(#linear-gradient-4)"/>
    </g>
  </g>
</svg>

);
