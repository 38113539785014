export const UploadAssetIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_116629" data-name="Group 116629" transform="translate(-32.25 -78)">
                <rect id="Rectangle_57270" data-name="Rectangle 57270" width="48" height="48" rx="8" transform="translate(32.25 78)" fill="#f1f7ff" />
                <path id="icons8-upload-file" d="M10.7,4A2.7,2.7,0,0,0,8,6.7V25.3A2.7,2.7,0,0,0,10.7,28H24.5a2.7,2.7,0,0,0,2.7-2.7V13.6H20.3a2.7,2.7,0,0,1-2.7-2.7V4Zm8.7.527V10.9a.9.9,0,0,0,.9.9h6.373ZM17.6,14.8a.9.9,0,0,1,.636.264l2.4,2.4a.9.9,0,1,1-1.273,1.273l-.864-.864V21.1a.9.9,0,1,1-1.8,0V17.873l-.864.864a.9.9,0,0,1-1.273-1.273l2.4-2.4A.9.9,0,0,1,17.6,14.8Zm-3.9,8.4h7.8a.9.9,0,0,1,0,1.8H13.7a.9.9,0,0,1,0-1.8Z" transform="translate(38.25 86)" fill="#78b1fe" />
            </g>
        </svg>

    )
}