import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #E4E8EE",
  },
  checkBox: {
    color: theme.palette.primary.main,
    borderRadius: "4px",
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  subTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
  },
  roundShape: {
    width: "6px",
    height: "6px",
    margin: "0px 6px 0px 6px",
    backgroundColor: "#CED3DD",
    borderRadius: "100%",
  },
  money: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  viewInvoice: {
    color: "#5078E1",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "0.875rem",
  },
  viewInvoice2: {
    color: "#4E5A6B",
    cursor: "pointer",
    fontSize: "0.875rem",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  agreementimg: {
    height: "50vh",
    msOverflowY: "scroll",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  downloadsub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    display: "flex",
  },

  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
}));

export const Statement = ({ t = () => false, Statementdata = [],
  getPDFDetails = () => false,
  currencySymbol = "",
}) => {
  const classes = useStyles();
  moment.defaultFormat = "YYYY-MM";
  // const openDrawer = () => {
  //   getPDFDetails(Statementdata?.id)

  // }
  return (
    <div className={classes.root}>
      <Grid container padding={2}>
        <Grid
          justifyContent={"center"}
          alignItems={"center"}
          container
          direction={"column"}
        >
          <Grid
            alignItems={"center"}
            container
            justifyContent={"space-between"}
            direction={"row"}
          >
            <Typography className={classes.title}>
              {moment(Statementdata?.created_at)
                .tz(moment.tz.guess())
                .format("DD MMM YYYY hh:mm:ss A")}
            </Typography>
            <Typography className={classes.money}>
              {Statementdata?.payment_amount &&
                Statementdata?.currency_symbol +
                Intl.NumberFormat("en-IN", {
                  minimumFractionDigits: 0,
                }).format(Math.round(Statementdata?.payment_amount))}
              {currencySymbol} &nbsp;{Statementdata?.type === "reciepts" ? Statementdata?.payments?.toFixed(2) : Statementdata?.amount?.toFixed(2)}
            </Typography>
          </Grid>
          <Box height={"6px"} />
          <Grid alignItems={"center"} container direction={"row"}>
            {/* {Statementdata?.invoice_id?.length && (
              <>
                <Typography className={classes.subTitle}>
                  {Statementdata?.invoice_id?.length +
                    (Statementdata?.invoice_id?.length > 1
                      ? t("Invoices")
                      : t("Invoice"))}
                </Typography>
                <Box className={classes.roundShape} />
              </>
            )} */}

            <Typography className={classes.subTitle}>
              {Statementdata?.reference_no}
            </Typography>
            {/* <Box className={classes.roundShape} />
            <Typography className={classes.subTitle}>
              {Statementdata?.payment_response}
            </Typography> */}
            <Box className={classes.roundShape} />
            <Typography
              onClick={() => getPDFDetails(Statementdata?.invoice_id, Statementdata)}
              // onClick={() => getPDFDetails(Statementdata?.id, Statementdata?.type === "reciepts" ? t("Reciept") : t("Invoice"))}
              className={classes.viewInvoice}
            >
              {Statementdata?.type === "reciepts" ? t("Reciept") : t("Invoice")}
            </Typography>
            <Box flexGrow={1} textAlign={"end"}>
              <Typography className={classes.subTitle}>
                {t("Balance")} : {currencySymbol}&nbsp; {Statementdata?.balance}
              </Typography>
            </Box>

          </Grid>
        </Grid>
      </Grid>
      {/* reciept drawer */}
    </div>
  );
};
