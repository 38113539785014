import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { PropertyDetailsStyle } from './style'
import { AuthContext } from '../../../contexts';
import { generateImageUrl, img_size } from '../../../utils';

export const PropertyDetails = (
    {
        value = {},
        setPropertyDetail = () => false,
        type = ""
    }
) => {
    const auth = React.useContext(AuthContext)
    const classes = PropertyDetailsStyle({ language: auth?.auth?.auth?.language })
    const language = localStorage.getItem("i18nextLng")

    return (
        <Box>
            <Stack p={1} spacing={1} className={classes.bodybox}>
                {/* {
                    properties?.map((e) => {
                        return ( */}
                            <div>
                                <Stack direction={"row"} justifyContent={"space-between"} p={2} className={classes.box} alignItems={"center"}
                                    onClick={() => setPropertyDetail(value)}>
                                    <Stack direction={"row"} alignItems={"center"}>
                                        <Avatar className={classes.avatar} src={generateImageUrl(type === "property" ? value?.asset_url : value?.unit_logo, img_size.small_square) ?? generateImageUrl(value?.logo, img_size.small_square)}></Avatar>
                                        <Stack>
                                            <Typography className={classes.apsName}> {!value?.agreement_unit_id ? value?.property_name : value?.unit_name} </Typography>
                                            {type !== "property" && <Typography className={classes.apsArea}> {value?.city}, {value?.state} </Typography>}
                                        </Stack>
                                    </Stack>
                                    <Box>
                                        <IconButton className={classes.iconbtn}>
                                            {language === "ar" ?
                                                <KeyboardArrowLeftIcon /> :
                                                <KeyboardArrowRightIcon />
                                            }
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </div>
                        {/* )
                    })
                } */}
            </Stack>
        </Box>
    )
}