import React from "react";
import { withBottombar } from "../../HOCs";
import PropertiesList from "./propertiesList";

class Owners extends React.Component {
  render() {
    return <PropertiesList {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(Owners, props);
