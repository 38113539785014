import React from "react";
import { AuthContext } from "./contexts";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_version_alert_open: false,
      new_version: "",
      setAuth: this.setAuth
    };
  }

  componentDidMount() {
    // this.checkForLatestBuild();
    this.refreshAPI();
  }

  refreshAPI = () => {
  }

  // checkForLatestBuild = () => {
  //   NetworkCall(
  //     `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ''}/meta.json`,
  //     NetWorkCallMethods.get,
  //     null,
  //     null,
  //     false,
  //     true).then((_) => {
  //       let temp_version = localStorage.getItem(LocalStorageKeys.version)
  //       if (temp_version) {
  //         const isVersion = semverGreaterThan(_.data.version, temp_version);
  //         if (isVersion) {
  //           this?.handleVersionAlert(_.data.version)
  //         }
  //       } else {
  //         localStorage.setItem(LocalStorageKeys.version, _.data.version)
  //       }
  //     }).catch(err => {
  //       console.log('err:', err);
  //     })
  // }

  setAuth = (auth) => {
    this.setState({ auth });
  };
  // handleVersionAlert = (version) => {
  //   this.setState({
  //     is_version_alert_open: !this?.state?.is_version_alert_open,
  //     new_version: version
  //   })
  // }
  render() {

    return (
      <>
        <AuthContext.Provider value={this.state}>
          {this.props.children}
          {/* <VersionAlert
            state={this?.state}
            handleVersionAlert={this?.handleVersionAlert} /> */}
        </AuthContext.Provider>
      </>
    );
  }
}

export default AppAuth;
