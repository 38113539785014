import React from "react";
import Vehicle from "./vehicle";
import { withBottombar } from "../../HOCs";


class VehicleParent extends React.Component {
  render() {
    return <Vehicle {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(VehicleParent, props);
