import * as React from "react";
import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Routes } from "../../router/routes";
import { DashboardUnselected } from "../../assets/bottombar/dashboard-unselected";
import { AgreementUnselected } from "../../assets/bottombar/agreement-unselected";
import { CommunityUnselected } from "../../assets/bottombar/community-unselected";
import { RequestUnselected } from "../../assets/bottombar/request-unselected";
import { DashboardSelected } from "../../assets/bottombar/dashboard-selected";
import { AgreementSelected } from "../../assets/bottombar/agreement-selected";
import { CommunitySelected } from "../../assets/bottombar/community-selected";
import { RequestSelected } from "../../assets/bottombar/request-selected";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
import { config } from "../../config";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.main,
    // borderRadius: "10px",
    color: theme.palette.primary.main,
    // "&.css-14ugdsl-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected":
    //   {
    //     color: "white",
    //   },
    // margin: "0px 8px",
  },
  containers: {
    padding: "10px 0px",
    alignItems: "start",
    backgroundColor: "#ffffff",
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    height: "62px",
    [theme.breakpoints.down('sm')]: {
      height: "77px",
      padding: "10px 0px 15px 0px",
      alignItems: "start",
    },
    boxShadow: "0px -1px 6px #00000021",
  },
  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "0.75rem",
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold
  },
  unselectedLabelTextStyle: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().bold
  },
}));
const BottomNavbar = (props) => {
  const classes = useStyles();
  const { t=()=>false } = props

  return (
    <Container maxWidth="sm" style={{padding:"0px"}}>
    <Grid
      container
      justifyContent={"space-around"}
      alignItems={"center"}
      className={classes.containers}
      id="mobileBottomNav"
    >
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
          onClick={() => window.location.pathname !== Routes?.dashboard ? props.onTab1Clicked() : false}
      >
        <center>
            {window.location.pathname === Routes?.dashboard ?
              <DashboardSelected /> :
              <DashboardUnselected />}
          <Typography
            className={
              window.location.pathname === Routes?.dashboard
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            {t("Dashboard")}
          </Typography>
        </center>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
          onClick={() => window.location.pathname !== Routes?.agreement ? props.onTab2Clicked() : false}

      >
        <center>
            {window.location.pathname === Routes?.agreement ?
              <AgreementSelected /> :
              <AgreementUnselected />}
          <Typography
            className={
              window.location.pathname === Routes?.agreement
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            {t("Agreement")}
          </Typography>
        </center>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
          onClick={() => window.location.pathname !== Routes?.service ? props.onTab3Clicked() : false}
      >
        <center>
            {window.location.pathname === Routes?.service ?
              <CommunitySelected /> :
              <CommunityUnselected />}
          <Typography
            className={
              window.location.pathname === Routes?.service
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            { config.app_x_build === "PG-RS-02" ? t("Community") : t("Services")}
          </Typography>
        </center>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
          onClick={() => window.location.pathname !== Routes?.requestScreen ? props.onTab4Clicked() : false}
      >
        <center>
            {window.location.pathname === Routes?.requestScreen ?
              <RequestSelected /> :
              <RequestUnselected />}
          <Typography
            className={
              window.location.pathname === Routes?.requestScreen
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            {t("Request")}
          </Typography>
        </center>
      </Grid>
    </Grid>
    </Container>
  );
};

export default withNamespaces("bottomNavBar")(BottomNavbar)
