import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  Typography,
} from "@mui/material";
import React from "react";
import { TextBox } from "../../../components";
import ButtonGroupSelect from "../../../components/ButtonGroupSelect";
import { withBottombar } from "../../../HOCs";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { useStyles } from "./styles";

const FilterList = (props) => {
  const { handleTopBarState, t = () => false } = props
  const size = useWindowDimensions();
  let fullSize = size?.height - 230;
  const classes = useStyles();
  const [value, setValue] = React.useState(
    [10, 30]
    //[data?.minRange, data?.maxRange]
  );
  function valuetext(value) {
    return `${value}`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // updateState("minRange", newValue[0]);
    // updateState("maxRange", newValue[1]);
  };
  const chceckList = [
    { label: "Apartment", value: "Apartment" },
    { label: "Barn", value: "Barn" },
    { label: "Boat", value: "Boat" },
    { label: "Bungalow", value: "Bungalow" },
    { label: "Cabin", value: "Cabin" },
    { label: "Caravan", value: "Caravan" },
  ];
  const amenitiesList = [
    { label: "Air conditioning", value: "Air conditioning" },
    { label: "Pet-friendly", value: "Pet-friendly" },
    { label: "Dishwashers", value: "Dishwashers" },
    { label: "Patios", value: "Patios" },
    { label: "Balconies", value: "Balconies" },
    { label: "Smart thermostats", value: "Smart thermostats" },
  ];
  const furnishing = [
    {
      value: "Furnished",
      label: "Furnished",
    },
    {
      value: "Semi-Furnished",
      label: "Semi-Furnished ",
    },
    {
      value: "Unfurnished",
      label: "Unfurnished ",
    },
  ];

  React.useEffect(() => {
    handleTopBarState({
      text: "Filter By"
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <TitleBar text={"Filter By"} goBack={() => history.goBack(-1)} /> */}
      <div
        className={classes.mainPadding2}
        style={{ height: fullSize, overflow: "auto" }}
      >
        <Box className={classes.propertyCard}>
          <Box height={"6px"} />
          <Typography className={classes.title} sx={{ color: "#091B29" }}>
            {t("PROPERTY TYPE")}
          </Typography>
          {chceckList?.map((data, index) => (
            <Box key={index} className={classes.checkContainer}>
              <FormGroup>
                <FormControlLabel
                  className={classes.chceckBox}
                  control={<Checkbox />}
                  label={data.label}
                />
              </FormGroup>
            </Box>
          ))}
          <Typography className={classes.moreTex}>+2 More</Typography>
        </Box>
        <Box className={classes.propertyCard}>
          <Typography className={classes.title} sx={{ color: "#091B29" }}>
            {t("AMENITIES")}
          </Typography>
          {amenitiesList?.map((data, index) => (
            <Box key={index} className={classes.checkContainer}>
              <FormGroup>
                <FormControlLabel
                  className={classes.chceckBox}
                  control={<Checkbox />}
                  label={data.label}
                />
              </FormGroup>
            </Box>
          ))}
          <Typography className={classes.moreTex}>+2 {t("More")}</Typography>
        </Box>
        <Box className={classes.propertyCard}>
          <div className={classes.input}>
            <ButtonGroupSelect
              label={t("FURNISHING")}
              labelColor="#091B29"
              data={furnishing}
              // state={data}
              stateName={"furnishing"}
            // updateState={updateState}
            />
          </div>
        </Box>
        <Box className={classes.propertyCard}>
          <div className={classes.input}>
            <Typography className={classes.title} component="h6">
              {t("BUDGET RANGE")}
            </Typography>
            <Slider
              getAriaLabel={() => "slider range"}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
            />
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <Grid item xs={5.5}>
                <TextBox
                  isRequired
                  endAdornment={
                    <Typography sx={{ color: "#A4B2C1" }}>{t("min")}</Typography>
                  }
                  startAdornment={
                    <Typography sx={{ color: "#4E5A6B" }}>$</Typography>
                  }
                  type={"number"}
                  placeholder="Min Value"
                // value={data.minRange}
                // onChange={(value) => {
                //   updateState("minRange", value.target.value);
                //   setValue((res) => [value.target.value, res[1]]);
                // }}
                // isError={data?.error?.minRange?.length > 0}
                // errorMessage={data?.error?.minRange}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography align="center" sx={{ color: "#A4B2C1" }}>
                  -
                </Typography>
              </Grid>
              <Grid item xs={5.5}>
                <TextBox
                  isRequired
                  endAdornment={
                    <Typography sx={{ color: "#A4B2C1" }}>{t("max")}</Typography>
                  }
                  startAdornment={
                    <Typography sx={{ color: "#091B29" }}>$</Typography>
                  }
                  type={"number"}
                  placeholder="Max Value"
                // value={data.maxRange}
                // onChange={(value) => {
                //   updateState("maxRange", value.target.value);
                //   setValue((res) => [res[0], value.target.value]);
                // }}
                // isError={data?.error?.maxRange?.length > 0}
                // errorMessage={data?.error?.maxRange}
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      </div>
      <Grid
        container
        className={classes.btnroot}
        justifyContent="space-between"
        alignItems={"center"}
        spacing={0}
      >
        <Grid item xs={5}>
          <Button
            fullWidth
            className={classes.secondary}
            variant="contained"
          // onClick={() => clearForm()}
          >
            {t("Reset")}
          </Button>
        </Grid>
        <Divider
          orientation="vertical"
          sx={{ borderLeft: "1px solid #98A0AC", padding: "14px 0" }}
        />
        <Grid item xs={5}>
          <Button
            fullWidth
            className={classes.primary}
            variant="contained"
          // onClick={() => submitData()}
          >
            {t("Apply")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(FilterList, props);
