import * as React from "react"

const UnregisteredWorker = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={38} height={34.381} {...props}>
    <path
      data-name="Path 100557"
      d="M25.333 2.714H12.666C6.333 4.524 2.714 9.952 2.714 17.19v5.424h32.571V17.19c0-7.238-3.619-12.666-9.952-14.476Z"
      fill="#fb8c00"
    />
    <path
      data-name="Path 100558"
      d="M23.524.905h-9.047a1.809 1.809 0 0 0-1.81 1.81v22.89h12.667V2.715a1.809 1.809 0 0 0-1.81-1.81Z"
      fill="#ffa726"
    />
    <path
      data-name="Path 100559"
      d="M35.286 17.19s0 4.524-2.714 4.524c-9.048 0-10.857-11.762-10.857-18.1V.9a.905.905 0 0 0-.9-.9h-3.624a.905.905 0 0 0-.9.9v2.719c0 6.333-1.81 18.1-10.857 18.1-2.714 0-2.714-4.524-2.714-4.524L.905 19v3.619s0 9.048 18.1 9.048 18.1-9.048 18.1-9.048V19Z"
      fill="#ffcc80"
    />
    <path
      data-name="Path 100560"
      d="M37.095 19a4.514 4.514 0 0 1-4.524 2.714c2.714-.9 2.714-4.524 2.714-4.524ZM.905 19a4.514 4.514 0 0 0 4.524 2.714c-2.714-.905-2.714-4.524-2.714-4.524Z"
      fill="#ffb74d"
    />
    <path
      data-name="Path 100561"
      d="M37.1 21.714a37.725 37.725 0 0 1-2.714 3.619c-.726.736-1.734 2.629-15.381 2.711-13.652-.082-14.66-1.975-15.386-2.711A37.725 37.725 0 0 1 .9 21.714c-.045.063-.9.9-.9.9a35.649 35.649 0 0 0 1.81 7.238 4.992 4.992 0 0 0 3.072 2.773c2.352.78 6.881 1.751 14.119 1.751s11.766-.971 14.119-1.751a4.992 4.992 0 0 0 3.072-2.773A35.648 35.648 0 0 0 38 22.614s-.86-.837-.9-.9Z"
      fill="#ffb74d"
    />
  </svg>
)

export default UnregisteredWorker
