import React from "react";
import { withBottombar } from "../../HOCs";
import Agreement from "./agreementNew";
class AgreementParent extends React.Component {
  render() {
    return <Agreement {...this?.props}/>;
  }
}

const props = {
  top_bar: "top_nav_bar",
  bottom_bar: "bottom_nav_bar"
}

export default withBottombar(AgreementParent, props);
