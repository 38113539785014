import * as React from "react"
export const ApprovedTick = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    data-name="Group 112105"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#f2f4f7" data-name="Ellipse 39519" />
    <g data-name="Group 112104">
      <circle
        cx={12}
        cy={12}
        r={12}
        fill="#5078e1"
        data-name="Ellipse 39518"
        transform="translate(8 8)"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.75}
        d="m14.62 20.818 3.712 3.711 7.056-7.059"
        data-name="Path 100044"
      />
    </g>
  </svg>
)
