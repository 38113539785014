
import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {

        width: '100%',
        overflow: "auto",
        backgroundColor: 'background.main',
        height: 'calc(100vh - 65px) !important',
        "&::-webkit-scrollbar": {
            display: "none",
        },
        md: {
            height: 'calc(100vh - 147px)',
        }
    },
    container: {
        backgroundColor: 'background.Teritary',
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },
    ownerIdTag: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "text.light",
        padding: "4px 8px",
        border: "1px solid",
        borderColor: 'text.light',
        borderRadius: "4px",
        backgroundColor: 'primary.contrastText',
    },
    ownerTag: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: 'text.dark',
        padding: "4px 8px",
        border: "1px solid",
        borderColor: 'text.dark',
        borderRadius: "4px",
        backgroundColor: 'primary.contrastText',
    },
    title: {
        color: 'text.main',
        fontSize: "1.5rem",
        fontFamily: FontFamilySwitch().extraBold,
        marginBottom: "8px",
    },
    topSection: {
        backgroundColor: 'primary.contrastText',
        padding: "28px 16px 6px",
        backgroundImage: "url('/images/illustration.svg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    secondSection: {
        padding: "16px",
    },
    cardList: {
        margin: "14px 0px 26px",
        borderRadius: "12px",
        backgroundColor: 'primary.contrastText'
    },
    subTitle: {
        color: "text.Teritary",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginBottom: "20px",
        textAlign: "center",
    },
    drawerCompTitle: {
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        marginBottom: "5px",
    },
    drawerCompsubTitle: {
        color: theme.typography.color.secondary,
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
    containedBtn: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "primary.main",
        borderRadius: "8px",
        textTransform: "capitalize",
        backgroundColor: "text.light1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "text.light1",
            color: "primary.main",
            boxShadow: "none",
        },


    },
    numberText: {
        color: "text.main",
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
    },
    drawerCompBox: {
        border: "1px solid #E4E8EE",
        padding: "16px",
        borderRadius: "12px",
        marginBottom: "16px"

    },
    logOutConfirmation: {
        color: "text.main",
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginBottom: "30px",
        textAlign: "center"
    },
}));
