import React from "react";

function WhiteFeedback() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g data-name="Group 114123" transform="translate(-1977.811 16211.189)">
        <path
          fill="#5078e1"
          d="M24 0A24 24 0 110 24 24 24 0 0124 0z"
          data-name="Path 6956"
          transform="translate(1977.811 -16211.189)"
        ></path>
        <path
          fill="none"
          d="M13.926 3.183a.464.464 0 00-.184.893 5.537 5.537 0 01.956.515.464.464 0 10.524-.766 6.447 6.447 0 00-1.116-.6.464.464 0 00-.18-.04zm-4.794.869c-2.44 0-4.5.526-5.507 1.406a1.91 1.91 0 00-.647 1.784l.1.353a1.48 1.48 0 001.6 1.087l.785-.1a1.446 1.446 0 001.282-1.061l.216-.959A.257.257 0 017.08 6.4a8.5 8.5 0 012.052-.184 8.495 8.495 0 012.052.184.282.282 0 01.127.185l.2.909a1.453 1.453 0 001.282 1.088l.8.1c.05 0 .1.007.15.007a1.467 1.467 0 001.436-1.083l.1-.334.007-.03a1.911 1.911 0 00-.647-1.785c-1.008-.878-3.066-1.404-5.506-1.404zm-1.089 3.4a.464.464 0 00-.456.47v.8l-1.1.551a4.69 4.69 0 00-2.607 4.218 1.7 1.7 0 001.7 1.7h7.11a1.7 1.7 0 001.7-1.7 4.69 4.69 0 00-2.607-4.218l-1.1-.551v-.8a.464.464 0 10-.927 0v.464H8.514v-.468a.464.464 0 00-.471-.47zM2.92 9.217a.464.464 0 00-.244.842 3.327 3.327 0 001.258.566.464.464 0 10.2-.906 2.4 2.4 0 01-.906-.409.464.464 0 00-.258-.093h-.05zm4.513 1.638a.464.464 0 11-.464.464.464.464 0 01.463-.464zm1.7 0a.464.464 0 11-.464.464.464.464 0 01.464-.464zm1.7 0a.464.464 0 11-.464.464.464.464 0 01.464-.464zM7.432 12.4a.464.464 0 11-.464.464.464.464 0 01.464-.464zm1.7 0a.464.464 0 11-.464.464.464.464 0 01.465-.464zm1.7 0a.464.464 0 11-.464.464.464.464 0 01.465-.464z"
          data-name="icons8-ringing-phone (1)"
          transform="translate(1992.859 -16196.373)"
        ></path>
        <g data-name="Group 112053">
          <path
            fill="#fff"
            d="M5.042 6a5.042 5.042 0 105.042 5.042A5.048 5.048 0 005.042 6zm11.916 0A5.042 5.042 0 1022 11.042 5.048 5.048 0 0016.958 6zM6.646 9.208a.688.688 0 11-.687.688.687.687 0 01.687-.688zm11.917 0a.688.688 0 11-.687.688.687.687 0 01.687-.688zm-15.125 0a.687.687 0 11-.687.688.687.687 0 01.687-.687zm11.917 0a.687.687 0 11-.687.688.687.687 0 01.686-.687zM5.042 11.5a1.793 1.793 0 011.823 1.278.459.459 0 01-.448.555h-2.75a.459.459 0 01-.448-.555A1.793 1.793 0 015.042 11.5zm10.542 0h2.75a.459.459 0 01.448.555 1.793 1.793 0 01-1.823 1.278 1.793 1.793 0 01-1.823-1.278.459.459 0 01.448-.555zM11 16.083a.685.685 0 00-.429.15 5.089 5.089 0 00-1.846 2.6H2.063a.688.688 0 100 1.375h6.645v1.6a.687.687 0 00.692.692h3.2a.687.687 0 00.688-.687v-1.6h6.646a.688.688 0 100-1.375h-6.659a5.089 5.089 0 00-1.846-2.6.685.685 0 00-.429-.155z"
            transform="translate(1990.811 -16201.189)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default WhiteFeedback;