import React from "react";

function Gift() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113449" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#ffecec"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#ff4b4b"
          d="M11.707 3a2.864 2.864 0 00-2.853 2.854A2.827 2.827 0 009.31 7.39H7.1A1.1 1.1 0 006 8.488V9.8a1.1 1.1 0 001.1 1.1h13.61a1.1 1.1 0 001.1-1.1V8.488a1.1 1.1 0 00-1.1-1.1H18.5a2.827 2.827 0 00.456-1.537A2.864 2.864 0 0016.1 3a2.808 2.808 0 00-2.2 1.105A2.808 2.808 0 0011.707 3zm0 1.317a1.527 1.527 0 011.537 1.537V7.39h-1.537a1.537 1.537 0 010-3.073zm4.39 0a1.537 1.537 0 010 3.073h-1.536V5.854A1.527 1.527 0 0116.1 4.317zM6.878 11.78v6.366A2.859 2.859 0 009.732 21h8.341a2.859 2.859 0 002.854-2.854V11.78zm5.727 1.757a1.516 1.516 0 011.119.448l.178.174.178-.174a1.531 1.531 0 011.119-.448 1.575 1.575 0 011.081 2.7l-2.074 2a.438.438 0 01-.609 0l-2.073-2a1.575 1.575 0 011.08-2.7z"
          transform="translate(44.098 245)"
        ></path>
      </g>
    </svg>
  );
}

export default Gift;