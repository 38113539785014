import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';


const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        marginTop: "20%"
    }
}))

export const Login = props => {

    const history = useHistory();
    const classes = useStyles();

    const onLogin = () => {

        history.push(Routes.home);
    }

    return <div className={classes.root}>
        <Typography variant="body1">
            Your are not logged in!!!
        </Typography>
        <Button
            variant={"contained"}
            color={"primary"}
            onClick={onLogin}
        >
            LogIn
        </Button>

    </div>
}
