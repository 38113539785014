import React from "react";
import NeighboursDetails from "./details";
import { withBottombar } from "../../HOCs";

class NeighboursDetailsParent extends React.Component {

  render() {
    return <NeighboursDetails {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
  bottom_bar: "bottom_nav_bar"
}

// To Be Refracted In Apple Screen

export default withBottombar(NeighboursDetailsParent, props);
