import {
  Box,
  Paper,
  Grid,
  Button,
  Container,
} from "@mui/material";
import { DatePickerNew, TextBox } from "../../components";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles } from "./component/style";
import { SelectBox } from "../../components/dropDown";
import useWindowDimensions from "../../utils/useWindowDimensions";
import React from "react";
import { AlertProps, NetWorkCallMethods, LocalStorageKeys } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";


const CreateInvestment = (props) => {
  const { t = () => false, handleTopBarState } = props
  const initialState = {
    date: "",
    spendingType: "",
    agreementUnit: "",
    usageType: "",
    category: "",
    subCategory: "",
    unitNumber: "",
    amount: "",
    currentValue: "",
    description: "",
    notes: "",
    error: {
      date: "",
      spendingType: "",
      agreementUnit: "",
      usageType: "",
      category: "",
      subCategory: "",
      unitNumber: "",
      amount: "",
      currentValue: "",
      description: "",
      notes: ""
    },
  };
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const currency = JSON.parse(localStorage.getItem(LocalStorageKeys.currencyId))
  const clientCurrency = JSON.parse(localStorage.getItem(LocalStorageKeys.clientCurrency))
  const { state } = useLocation();
  const [data, setData] = React.useState({ ...initialState });
  const alert = React.useContext(AlertContext);
  const [categories, setCategories] = React.useState([]);
  const [agreementList, setAgreementList] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const updateState = (key, value) => {
    console.log(key, value);
    let error = data?.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  }

  const isValidData = () => {
    let isValid = true;
    let error = data.error;

    // DATE
    if (data?.date?.length === 0) {
      isValid = false;
      error.date = t("Date Is required");
    } else {
      isValid = true;
      error.date = "";
    }

    // SPEDNING TYPE
    if (data?.spendingType?.length === 0) {
      isValid = false;
      error.spendingType = t("Spending Type Is required");
    } else {
      isValid = true;
      error.spendingType = "";
    }

    // USAGE TYPE
    if (data?.usageType?.length === 0) {
      isValid = false;
      error.usageType = t("Usage Type Is required");
    } else {
      isValid = true;
      error.usageType = "";
    }


    // CATEGORY
    if (data?.category?.length === 0) {
      isValid = false;
      error.category = t("Category Is required");
    } else {
      isValid = true;
      error.category = "";
    }

    // SUB-CATEGORY
    if (data?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = t("Sub-Category Is required");
    } else {
      isValid = true;
      error.subCategory = "";
    }

    // UNIT NUMBER
    if (data?.unitNumber?.length === 0) {
      isValid = false;
      error.unitNumber = t("Unit Number Is required");
    } else {
      isValid = true;
      error.unitNumber = "";
    }

    // Amount
    if (data?.amount?.length === 0) {
      isValid = false;
      error.amount = t("Amount Is required");
    } else {
      isValid = true;
      error.amount = "";
    }

    // Value
    if (data?.currentValue?.length === 0) {
      isValid = false;
      error.currentValue = t("Current Value Is required");
    } else {
      isValid = true;
      error.currentValue = "";
    }

    setData({ ...data, error });
    return isValid;
  };

  // CREATE API

  const CreateInvestment = (value) => {
    const payload = {
      date: data?.date,
      category_id: data?.category?.value,
      sub_category_id: data?.subCategory?.value,
      unit: data?.unitNumber?.unit_no,
      amount: data?.amount,
      current_value: data?.currentValue,
      currency_id: currency?.id,
      description: data?.description,
      notes: data?.notes,
      type: "Investments",
      usage_type: null,
    };
    NetworkCall(
      `${config?.api_url}/statistics/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Investment Added Successfully"),
        });
        history.goBack(-1);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  const submitData = () => {
    if (isValidData()) {
      // setDisableButton(true);
      if (state?.data)
        UpdateInvestment(state?.data?.id);
      else {
        CreateInvestment();
      }
    }
  };

  // CATEGORY API

  const getCategory = (offset, fetchMore) => {
    const payload = {
      offset: 0,
      limit: 10
    }
    NetworkCall(
      `${config?.api_url}/statistics-category-master`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setCategories(response?.data?.data)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  // SUB-CATEGORY API

  const getSubCategory = (id) => {
    const payload = {
      offset: "0",
      limit: "10",
      category_id: id
    }
    NetworkCall(
      `${config?.api_url}/statistics-subcategory-master`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setSubCategories(response?.data?.data)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  React.useEffect(() => {
    handleTopBarState({
      text: t("Create Investment")
    })
    if (agreementList?.length > 0) {
      EditInvestment(agreementList)
    }
    // eslint-disable-next-line 
  }, [agreementList])

  // CATEGORY AGAINST SUB-CATEGORY

  const categoryOnChange = (value) => {
    updateState("category", value);
    getSubCategory(value?.value);
  }



  // UPDATE API

  const UpdateInvestment = (id) => {
    const payload = {
      id: id,
      date: data?.date,
      category_id: data?.category?.value,
      sub_category_id: data?.subCategory?.value,
      unit: data?.unit?.unit_no,
      amount: data?.amount,
      currency_id: currency?.symbol,
      description: data?.description,
      notes: data?.notes,
      type: "Investments",
    };
    NetworkCall(
      `${config?.api_url}/statistics/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Investment Added Successfully"),
        });
        history.goBack(-1);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };



  const EditInvestment = (tempUsage) => {
    if (state?.data) {
      let temp_agreement_unit, temp_unit = ""
      for (let i = 0; i < agreementList.length; i++) {
        for (let j = 0; j < agreementList[i].units.length; j++) {
          if (agreementList[i].units[j].unit_no === state?.data?.unit_number) {
            temp_agreement_unit = agreementList[i]
            temp_unit = agreementList[i].units[j]
          }
        }
      }
      setData({
        ...data,
        amount: state?.data?.amount,
        currentValue: state?.data?.current_value,
        date: new Date(state?.data?.created_at),
        agreementUnit: temp_agreement_unit,
        category: {
          label: state?.data?.category_name,
          value: state?.data?.category_id,
        },
        subCategory: {
          label: state?.data?.sub_category_name,
          value: state?.data?.sub_category_id,
        },
        unitNumber: temp_unit,
        description: state?.data?.description,
        notes: state?.data?.notes,
        toggleButton: tempUsage.find((val) => val.value === state?.data?.usage_id)
      })
    }
  }

  React.useEffect(() => {
    getCategory();
    // getUsageType();
    // eslint-disable-next-line
  }, [state]);

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      NetworkCall(
        `${config.api_url}/agreement-unit-info/switcher`,
        NetWorkCallMethods.post,
        {},
        null,
        true,
        false
      )
        .then((response) => {
          const _tempUnitList = response?.data?.data?.map(
            (_uitem, index, array) => {
              let _u;
              try {
                _u = {
                  label: _uitem?.agreement_no ? _uitem?.agreement_no : "",
                  value: _uitem?.id ? _uitem?.id : "",
                  units: _uitem?.agreement_unit?.map(i => {
                    return {
                      value: i?.unit_id,
                      label: (i?.name ?? "") + " - " + (i?.unit_no ?? ""),
                      ...i
                    }
                  }),
                };
              } catch (error) {
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: t("Something Went Wrong"),
                });
              }
              return _u;
            }
          );
          setAgreementList(_tempUnitList);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong"),
          });
        });
    }
    // eslint-disable-next-line
  }, []);



  const agreementOnChange = (value) => {
    updateState("agreementUnit", value);
  }


  return (
    <Container maxWidth="sm" className={classes.noPadding}>
      <Box className={classes.root_create}>
        <Box p={2}>
          <Box
            style={{ height: size?.height - 140, overflow: "auto" }}
            className={classes.whiteBox}
            p={2}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={5.5}>
                <Box pt={2} className={classes.borderBottom}>
                  <DatePickerNew
                    borderNone
                    isRequired
                    label={t("Date")}
                    labelColor={"#5078E1"}
                    placeholder={t("Date")}
                    value={data?.date ?? ""}
                    onChange={(value) => { updateState("date", value) }}
                    isError={data?.error?.date?.length > 0}
                    errorMessage={data?.error?.date}
                  />
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5.5}>
                <Box mt={2} className={classes.borderBottom}>
                  <SelectBox
                    borderNone
                    isRequired
                    placeholder={t("Agreement Unit")}
                    label={t("Agreement Unit")}
                    labelColor={"#5078E1"}
                    options={agreementList}
                    value={data?.agreementUnit}
                    onChange={(value) => {
                      agreementOnChange(value);
                    }}
                    isError={data?.error?.agreementUnit?.length > 0}
                    errorMessage={data?.error?.agreementUnit}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} className={classes.borderBottom}>
                  <SelectBox
                    borderNone
                    isRequired
                    // isDisabled={!Boolean(data?.agreementUnit)}
                    placeholder={t("Unit Number")}
                    label={t("Unit Number")}
                    labelColor={"#5078E1"}
                    options={data?.agreementUnit?.units}
                    value={data?.unitNumber}
                    onChange={(value) => {
                      updateState("unitNumber", value);
                    }}
                    isError={data?.error?.unitNumber?.length > 0}
                    errorMessage={data?.error?.unitNumber}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} className={classes.borderBottom}>
                  <SelectBox
                    borderNone
                    isRequired
                    placeholder={t("Category")}
                    label={t("Category")}
                    labelColor={"#5078E1"}
                    options={categories}
                    value={data?.category}
                    onChange={(value) => {
                      categoryOnChange(value)
                    }}
                    isError={data?.error?.category?.length > 0}
                    errorMessage={data?.error?.category}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} className={classes.borderBottom}>
                  <SelectBox
                    borderNone
                    isRequired
                    placeholder={t("Sub-Category")}
                    label={t("Sub-Category")}
                    labelColor={"#5078E1"}
                    options={subCategories}
                    value={data?.subCategory}
                    onChange={(value) => {
                      updateState("subCategory", value);
                    }}
                    isError={data?.error?.subCategory?.length > 0}
                    errorMessage={data?.error?.subCategory}
                  />
                </Box>
              </Grid>
              <Grid item xs={5.5}>
                <Box mt={2} className={classes.borderBottom}>
                  <TextBox
                    borderNone
                    isRequired
                    color
                    labelColor={"#5078E1"}
                    label={t("Amount")}
                    placeholder={t("Amount")}
                    value={data.amount}
                    onChange={(value) => updateState("amount", value.target.value)}
                    isError={data?.error?.amount?.length > 0}
                    errorMessage={data?.error?.amount}
                    type="number"
                    endAdornment={currency?.symbol ? (currency?.symbol) : (clientCurrency?.code)}
                  />
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5.5}>
                <Box mt={2} className={classes.borderBottom}>
                  <TextBox
                    borderNone
                    isRequired
                    color
                    labelColor={"#5078E1"}
                    label={t("Current Value")}
                    placeholder={t("Current Value")}
                    value={data.currentValue}
                    onChange={(value) => updateState("currentValue", value.target.value)}
                    isError={data?.error?.currentValue?.length > 0}
                    errorMessage={data?.currentValue}
                    type="number"
                    endAdornment={currency?.symbol ? (currency?.symbol) : (clientCurrency?.code)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2} mb={2} className={classes.borderBottom}>
                  <TextBox
                    borderNone
                    isRequired
                    color
                    labelColor={"#5078E1"}
                    label={t("Description")}
                    placeholder={t("Description")}
                    value={data?.description}
                    onChange={(value) => updateState("description", value.target.value)} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Paper elevation={0}>
          <Box
            p={2}
            className={classes.buttonCard}
          >
            <Grid container spacing={2}>
              <Grid item lg={6} xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.primaryButton}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
              <Grid item lg={6} xs={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.secondaryButton}
                  onClick={submitData}
                >
                  {t("Save")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("statistics")(CreateInvestment), props);


