import * as React from "react"

const TickCalendar = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96861" fill="#5078e1">
      <path
        data-name="icons8-calendar (5)"
        d="M3.611 0A3.624 3.624 0 0 0 0 3.611v12.778A3.624 3.624 0 0 0 3.611 20h12.778A3.624 3.624 0 0 0 20 16.389V3.611A3.624 3.624 0 0 0 16.389 0Zm0 1.667h12.778a1.932 1.932 0 0 1 1.944 1.944v.833H1.667v-.833a1.932 1.932 0 0 1 1.944-1.944ZM1.667 6.111h16.666v10.278a1.932 1.932 0 0 1-1.944 1.944H3.611a1.932 1.932 0 0 1-1.944-1.944Z"
      />
      <path
        data-name="Path 9195"
        d="m9.413 13.943 1.252-1.64q1.631-2.138 3.26-4.277a.63.63 0 0 1 .656-.283.616.616 0 0 1 .354.994c-.391.527-.793 1.046-1.19 1.568l-3.716 4.886a.639.639 0 0 1-1.106.056q-1.106-1.18-2.21-2.363a.626.626 0 1 1 .907-.854c.592.629 1.181 1.261 1.791 1.913"
        stroke="#5078e1"
        strokeWidth={0.2}
      />
    </g>
  </svg>
)

export default TickCalendar;
