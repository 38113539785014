import * as React from "react"
const SlotDuration = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 114302" transform="translate(21667 434)">
      <rect
        width={24}
        height={24}
        fill="#fef4f4"
        data-name="Rectangle 57697"
        rx={6}
        transform="translate(-21667 -434)"
      />
      <path
        fill="#b3776d"
        d="M-21655-429a7 7 0 1 0 7 7 7.021 7.021 0 0 0-7-7Zm2.625 8.05h-2.8a.538.538 0 0 1-.525-.525v-4.2a.525.525 0 0 1 1.05 0V-422h2.275a.525.525 0 0 1 0 1.05Z"
        data-name="icons8-time (2)"
      />
    </g>
  </svg>
)
export default SlotDuration
