import React from "react";

function StatsInsurance() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113445" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#e6f9ff"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#20adf8"
          d="M12.619 2a.6.6 0 00-.42.154A11.35 11.35 0 015.6 4.8a.6.6 0 00-.6.6v4.9c0 2.671 1.236 7.066 7.367 9.649a.6.6 0 00.466 0C18.964 17.37 20.2 12.974 20.2 10.3V5.4a.6.6 0 00-.6-.6A11.35 11.35 0 0113 2.153.6.6 0 0012.619 2zM15.6 8.4a.6.6 0 01.424 1.024l-4 4a.6.6 0 01-.848 0l-2-2a.6.6 0 01.848-.848l1.576 1.575 3.576-3.576A.6.6 0 0115.6 8.4z"
          transform="translate(45.4 246.001)"
        ></path>
      </g>
    </svg>
  );
}

export default StatsInsurance;