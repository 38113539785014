import React from "react";
import GetSupportDetailed  from "./getSupportDetailed";
import { withBottombar } from "../../HOCs";

class GetSupportDetailedParent extends React.Component {
    render() {
        return <GetSupportDetailed {...this?.props} />;
    }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(GetSupportDetailedParent, props);
