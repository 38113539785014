import { makeStyles } from "@mui/styles";

export const PropertyCardStyle = makeStyles((theme) => ({
    box: {
        borderRadius: '4px',
        backgroundColor: "#fff"
    },
    name: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    area: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
    },
    distance: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        fontWeight: "bold"
    }
}))

export const FeatureCardStyle = makeStyles((theme) => ({
    viewbtn: {
        backgroundColor: theme.palette.info.main,
        borderRadius: '4px',
        color: "#fff",
        width: 50,
        padding: 2,
        textAlign: "center",
        fontSize: "0.75rem",
        fontWeight:"bold"
    },
    box: {
        borderRadius: '4px',
        backgroundColor: "#fff",
        height: "auto",
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    name: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    subtitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
    },
}))

export const SlideCardStyle = makeStyles((theme) => ({
    box: {
        backgroundColor: "#fff",
        borderRadius: '4px',
        border: `1px solid ${theme.palette.border.secondary}`,
        boxShadow:"0px 3px 8px #D2D5D938",
        width: "153px",
        height: "130px",
        padding:"8px 8px 0px 8px"
    },
    image: {
        height: 90,
        width: 137,
        borderRadius: '4px',
        // objectFit:"cover"
    },
    name: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontWeight: 600,
        whiteSpace: "nowrap",
        width:150
    },
    icon_btn: {
        height: 16,
        width: 16,
        backgroundColor: theme.palette.border.secondary,
        padding:0,
    },
    icon:{
        fontSize: "0.875rem",
        color:theme.typography.color.Tertiary
    }
}))