
import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#F5F7FA",
      overflow: "hidden",
    },
    screen: {
      borderRadius: "4px",
      // border: "1px solid #F5F7FA",
      background: theme.palette.background.tertiary,
    },
    topNavBarStyle: {
      // padding: "0px 16px 0px 16px",
    },
    tabs: {
      padding: "0px 16px 0px 16px",
    },
    screenScrollEffect: {
      overflow: "overlay",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    arrrow: {
      padding: "12px",
      backgroundColor: "white",
    },
    viewChart: {
      padding: "4px 0px",
  
      color: "#5078E1",
  
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().semiBold,
      cursor: "pointer",
    },
    drawer: {
      "& .MuiDrawer-paper": {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        width: "-webkit-fill-available",
      },
      "& .MuiContainer-root": {
        padding: "0px",
        width: "-webkit-fill-available",
      },
    },
    dialog: {
      "& .MuiDialog-paper": {
        borderRadius: "4px",
        padding: "0px",
      },
    },
    bottomSheetStyle: {
      background: "white",
      borderRadius: "4px 4px 0px 0px",
      border: "1px solid #E4E8EE",
      justifyContent: "center",
    },
    dialogStyle: {
      background: "white",
      borderRadius: "4px",
      border: "1px solid #E4E8EE",
      justifyContent: "center",
    },
    drawerHeaderStyle: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
    },
    drawerHeaderTextStyle: {
      fontSize: "1rem",
      fontWeight: "bolder",
      color: theme.typography.color.primary,
    },
    drawerBodyStyle: {
      justifyContent: "space-between",
      alignItems: "center",
      // padding: "16px",
    },
    drawerBodyChildStyle: {
      padding: "16px",
    },
    pdfDrawerBodyStyle: {
      background: theme.palette.background.tertiary,
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      height: "600px",
      width: "inherit",
      overflow: "overlay",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    pdfImageStyle: {
      width: "inherit",
    },
    drawerFooterStyle: {
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
    },
    drawerButtonStyle: {
      "&:hover": "none",
      borderRadius: theme.palette.borderRadius,
      padding: "14px",
      background: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontWeight: "normal",
      color: "#FFF",
    },
    periodStyle: {
      height: "6px",
      width: "6px",
      borderRadius: "50%",
      backgroundColor: "#CED3DD",
      margin: "6px",
    },
    paymentHistoryContainerStyle: {
      padding: "16px",
      background: "white",
      borderRadius: "4px 4px 0px 0px",
      // border: "1px solid #E4E8EE",
      // boxShadow: "0px -7px 20px #0717410D",
      width: "-webkit-fill-available",
    },
    yearFilterbuttonStyle: {
      backgroundColor: "#ffffff",
      cursor: "pointer",
      marginTop: "16px",
      marginRight: "16px",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      border: "1px solid #E4E8EE",
      borderRadius: "4px",
      boxShadow: "none",
    },
    yearFilterTextStyle: {
      color: "#071741",
      fontFamily: FontFamilySwitch().semiBold,
      fontSize: "0.75rem",
    },
    paymentHistoryCard: {
      padding: "16px",
      width: "100%",
      borderRadius: theme.palette.borderRadius,
      // border: "3px solid #FFFFFF",
      backgroundColor: "#E4EDFF",
      // backgroundRepeatX: "no-repeat",
      // backgroundSize: "100% 100%",
      // backgroundImage: `url("/images/Mask_Group_18.png")`,
    },
    filterDrawer: {
      "& .MuiDrawer-paper": {
        width: "-webkit-fill-available",
      },
    },
    viewCharticonButtonStyle: {
      background: "white",
      height: "24px",
      width: "24px",
    },
    profileCardStyle: {
      padding: "24px 16px 16px 16px",
      backgroundImage: `url(${"/images/Illustration.svg"})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50%",
      background: theme.palette.background.tertiary1,
    },
    detailsCardStyle: {
      borderRadius: "4px",
      padding: "12px",
      backgroundColor: theme.palette.background.tertiary1,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "100%",
      backgroundPositionY: "100%",
    },
    bottomNavigationBarStyle: {
      borderRadius: "4px 4px 0px 0px",
      position: "fixed",
      width: "-webkit-fill-available",
    },
    filter: {
      borderRadius: "4px",
      border: "1px solid #E4E8EE",
      color: "black",
      backgroundColor: "white",
    },
    filterdata: {
      padding: "8px 12px",
      fontSize: "1rem",
      fontFamily: FontFamilySwitch().bold,
      cursor: "pointer",
      width: "100%",
    },
    filterdataChild: {
      paddingLeft: "12px",
      fontSize: "1rem",
      fontFamily: FontFamilySwitch().bold,
      width: "100%",
    },
  
    filterleft: {
      borderRight: "1px solid #c1c1c1",
    },
    active: {
      fontSize: "0.75rem",
      padding: "2px 6px",
      color: "#5078E1",
      backgroundColor: "#E4E8EE",
      borderRadius: "50%",
      width: "100%",
    },
    btncontainer: {
      padding: "14px",
      backgroundColor: "#F5F7FA",
    },
    applybtn: {
      padding: "12px",
      borderRadius: "4px",
    },
    cancelbtn: {
      padding: "12px",
      border: "1px solid #E4E8EE",
      backgroundColor: "white",
      borderRadius: "4px",
    },
    filterdataActive: {
      padding: "8px 12px",
      fontSize: "1rem",
      fontFamily: FontFamilySwitch().bold,
      cursor: "pointer",
      backgroundColor: theme.palette.background.secondary,
      width: "100%",
    },
    unSelectedFilterDurationTabStyle: {
      padding: "8px 0px",
      borderBottom: "2px solid #F5F5F5",
      cursor: "pointer",
    },
    unSelectedFilterDurationTabTextStyle: {
      fontSize: "0.8125rem",
      fontFamily: FontFamilySwitch().semiBold,
      color: theme.palette.secondary.main,
    },
    selectedFilterDurationTabStyle: {
      padding: "8px 0px",
      borderBottom: "2px solid #071741",
      cursor: "pointer",
    },
    selectedFilterDurationTabTextStyle: {
      fontSize: "0.8125rem",
      fontFamily: FontFamilySwitch().extraBold,
      color: theme.palette.secondary.main,
    },
    arrow: {
      padding: "8px",
      backgroundColor: "#55BC7E",
      borderRadius: "4px",
    },
    bold: {
      fontFamily: FontFamilySwitch().bold,
    },
    status: {
      borderRadius: "4px",
      backgroundColor: "#EEF9EE",
      padding: "8px 12px 8px 12px",
      color: "#5AC782",
    },
    status1: {
      borderRadius: "4px",
      backgroundColor: "#FFF4EB",
      padding: "8px 12px 8px 12px",
      color: "#FF9340",
    },
    chartRoot:{
      backgroundColor: "#FFFFFF",
      position: "sticky",
      top: "0",
      width: "100%",
      paddingTop: "8px",
    },
    chartTitle:{
      fontSize: "1rem",
      color: "#071741",
      fontFamily: FontFamilySwitch().bold,
    }
  }));
  