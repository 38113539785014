import React from "react";
import AddWorkerPass from "./addWorkerPass";
import AddWorkerPassContext  from "./addWorkerPassContext";

class AddWorkerPassParent extends React.Component {
    render() {
        return (
            <AddWorkerPassContext>
                <AddWorkerPass />
            </AddWorkerPassContext>
        )
    }
}
export default AddWorkerPassParent;