import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";
import { ContactAssetIcon } from "../../assets/contactAsset";
import {
  MobileNumberInputComponent,
  TextBox
} from "../../components";
import { useStyles } from "./style";

const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

const Step2 = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [contactName, setContactName] = React.useState(
    props?.request?.contactName ? props?.request?.contactName : ""
  );
  const [primaryNumber, setPrimaryNumber] = React.useState(
    props?.request?.primaryNumber ? props?.request?.primaryNumber : ""
  );
  const [primaryCountryCode, setPrimaryCountryCode] = React.useState(
    props?.request?.primaryCountryCode ? props?.request?.primaryCountryCode : ""
  );
  const [alternateNumber, setAlternateNumber] = React.useState(
    props?.request?.alternateNumber ? props?.request?.alternateNumber : ""
  );
  const [alternateNumberCountryCode, setAlternateNumberCountryCode] =
    React.useState(
      props?.request?.alternateNumberCountryCode
        ? props?.request?.alternateNumberCountryCode
        : ""
    );
  const isContactValid = () => {
    let isValid = true;
    let error = props?.request?.error;
    if (contactName.length === 0) {
      isValid = false;
      error.contactName = t("ContactNameisRequired");
    } else {
      props.request.contactName = contactName;
      error.contactName = "";
    }
    if (primaryNumber.length === 0 || primaryCountryCode === 0) {
      isValid = false;
      error.primaryNumber = t("PrimaryContactisRequired");
    } else {
      props.request.primaryNumber = primaryNumber;
      props.request.primaryCountryCode = primaryCountryCode;
      error.primaryNumber = "";
    }
    props.request.alternateNumber = alternateNumber;
    props.request.alternateNumberCountryCode = alternateNumberCountryCode;
    error.alternateNumber = "";
    props?.setRequest({ ...props.request, error });
    return isValid;
  };
  const onDrawerOpen = () => {
    setContactName(props.request.contactName);
    setPrimaryNumber(props.request.primaryNumber);
    setPrimaryCountryCode(props.request.primaryCountryCode);
    setAlternateNumber(props.request.alternateNumber);
    setAlternateNumberCountryCode(props.request.alternateNumberCountryCode);
    setShowDrawer(true);
  };
  const onDrawerClose = () => {
    if (isContactValid()) {
      setShowDrawer(false);
    }
  };


  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  React.useEffect(() => {
    handleTopBarState({
      text: "New Maintenance Request",
      customBackHandle:()=>props?.goBack()
    })
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        {/* <TitleBar

          text={t("NewMaintenanceRequest")}
          goBack={goBack}
        /> */}
        <Box className={classes.content}>
          <Box p={2}>
            <Stack direction="row" alignItems="center">
              <Box style={{ marginInlineEnd: "8px" }}><ContactAssetIcon /></Box>
              <Box>
                <Typography className={classes.serviceTitle}>{t("Contact Details")}</Typography>
                <Typography className={classes.steptitle}>{t("STEP")} {props?.step} / 4</Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box p={2}>
            <Box className={classes.productBox}>
              <Box p={1.5} display={"flex"} justifyContent={"space-between"} alignItems="center">
                <Stack direction="row" alignItems="center">
                  <Box style={{ marginInlineEnd: "8px" }}><Avatar src={props?.request?.contact_image} /></Box>
                  <Box>
                    <Typography className={classes.unitTitle}>{props?.request?.contactName}</Typography>
                    <Box display={"flex"} alignItems="center" mt={0.5}>
                      {props?.request?.primaryNumber?.length > 0 &&
                        <Typography className={classes.productDetailsTitle}>{props?.request?.primaryCountryCode + " " + props?.request?.primaryNumber}</Typography>

                      }
                      {props?.request?.alternateNumber?.length > 0 &&
                        <>
                          <Box className={classes.verticalBorder}></Box>
                          <Typography className={classes.productDetailsTitle}>{props?.request?.alternateNumberCountryCode + " " + props?.request?.alternateNumber}</Typography>
                        </>
                      }
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box className={classes.changeBox} mt={4} onClick={onDrawerOpen}>
              <Typography className={classes.changetext}>{t("Change")}</Typography>
            </Box>
          </Box>
          <Container maxWidth="sm" className={classes.btnRoot}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btnPrevious}
                  onClick={() => {
                    props.previous(1);
                  }}
                >
                  {t("Previous")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btn}
                  onClick={() => {
                    props.next(3);
                  }}
                >
                  {t("Next")}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            open={showDrawer}
            fullWidth={true}
            maxWidth="sm"
            onClose={() => setShowDrawer(false)}
          >
            <Grid container direction="column" className={classes.dialogStyle}>
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("ChangeContactDetails")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={() => setShowDrawer(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.drawerBodyStyle}>
                <Box height={16} />
                <TextBox
                  isRequired
                  label="Contact Name"
                  value={contactName}
                  placeholder="Enter Contact Name"
                  onChange={(value) => setContactName(value.target.value)}
                  isError={props?.request?.error?.contactName?.length > 0}
                  errorMessage={props?.request?.error?.contactName}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  isRequired
                  label="Primary Contact"
                  placeholder=" 9876543210"
                  value={{
                    mobile: primaryNumber,
                    mobile_code: primaryCountryCode,
                  }}
                  handleChange={(value) => {
                    setPrimaryNumber(value.mobile);
                    setPrimaryCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.primaryNumber?.length > 0}
                  errorMessage={props?.request?.error?.primaryNumber}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  label="Alternate Contact"
                  placeholder=" 9876543210"
                  value={{
                    mobile: alternateNumber,
                    mobile_code: alternateNumberCountryCode,
                  }}
                  handleChange={(value) => {
                    setAlternateNumber(value.mobile ?? "");
                    setAlternateNumberCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.alternateNumber?.length > 0}
                  errorMessage={
                    props?.request?.error?.alternateNumberCountryCode
                  }
                />
                <Box height={"32px"} />
                <Button
                  fullWidth
                  className={classes.drawerButtonStyle}
                  variant="contained"
                  onClick={onDrawerClose}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            anchor="bottom"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
          >
            <Grid
              container
              direction="column"
              className={classes.bottomSheetStyle}
            >
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("ChangeContactDetails")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={() => setShowDrawer(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.drawerBodyStyle}>
                <Box height={16} />
                <TextBox
                  label="Contact Name"
                  value={contactName}
                  placeholder="Enter Contact Name"
                  onChange={(value) => setContactName(value.target.value)}
                  isError={props?.request?.error?.contactName?.length > 0}
                  errorMessage={props?.request?.error?.contactName}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  label="Primary Contact"
                  placeholder=" 9876543210"
                  isRequired
                  value={{
                    mobile: primaryNumber,
                    mobile_code: primaryCountryCode,
                  }}
                  handleChange={(value) => {
                    setPrimaryNumber(value.mobile);
                    setPrimaryCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.primaryNumber?.length > 0}
                  errorMessage={props?.request?.error?.primaryNumber}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  label="Alternate Contact"
                  placeholder=" 9876543210"
                  // isRequired
                  value={{
                    mobile: alternateNumber,
                    mobile_code: alternateNumberCountryCode,
                  }}
                  handleChange={(value) => {
                    setAlternateNumber(value.mobile);
                    setAlternateNumberCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.alternateNumber?.length > 0}
                  errorMessage={
                    props?.request?.error?.alternateNumberCountryCode
                  }
                />
                <Box height={"32px"} />
                <Button
                  fullWidth
                  className={classes.drawerButtonStyle}
                  variant="contained"
                  onClick={onDrawerClose}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>
      </Container>
    </div>
  );
};

const props = {
  top_bar: "title_bar",
}


export default withBottombar(withNamespaces("maintance")(Step2), props);