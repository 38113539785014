import { Box, Typography, Grid, Avatar } from "@mui/material";
import moment from "moment";
import { useStyles } from "../discussionDetails";
import React from "react";
import ImageViewer from "react-simple-image-viewer";
import { generateImageUrl, img_size } from "../../../utils";

export const CommentCard = ({ val, t = () => false, comment = {} }) => {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };


  return (
    <Box className={classes.replaySection}>
      <Box alignItems="center" display="flex">
        <Box>
          <Avatar src={generateImageUrl(val?.image_url, img_size.small_square) ?? null} />
        </Box>
        <Box flexGrow={1} marginLeft="10px">
          <Box alignItems="center" display="flex">
            <Box>
              <Typography variant="subtitle2" className={classes.name}>
                {val?.first_name}&nbsp;
                {val?.last_name}
              </Typography>
            </Box>
            <Box className={classes.dot} />
            <Box flexGrow={1} noWrap>
              <Typography
                variant="subtitle2"
                className={classes.unit}
                noWrap
              >
                {comment?.unit?.name}
              </Typography>
            </Box>
          </Box>

          <Typography variant="subtitle2" className={classes.time}>
            {t("Postedat")}&nbsp;
            {moment(new Date(val.created_at)).fromNow()}
          </Typography>
        </Box>
      </Box>
      <Typography variant="subtitle2" className={classes.replay}>
        {(val?.comment && val?.comment?.length > 0) && (val?.comment?.split('\n').map((item, i) => <Typography variant="subtitle2" key={i} className={classes.replay1}>{item}</Typography>))}
      </Typography>
      <Grid container spacing={1} marginTop={0.7}>
        {val?.assets?.length > 0 &&
          val?.assets?.map((item, index) => {
            return (
              <>
                <Grid item xs={3} onClick={() => openImageViewer(index)}>
                  <img src={generateImageUrl(item?.url, img_size.small_square)} alt="" className={classes.detailImg2} />


                </Grid>
              </>
            );
          })}
      </Grid>
      {isViewerOpen && (
        <ImageViewer
          src={val?.assets?.map((i) => i?.url)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Box>
  );
};
