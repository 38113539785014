import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { SlotCardStyles } from "./style";
import { format } from "date-fns";
import ElectricIcon from "../../assets/electricIcon";
import { withNamespaces } from "react-i18next";

const Slots = ({ t = () => false, onclick = () => false, ...props }) => {
  const classes = SlotCardStyles(props);
  var selectedIndex = []
  if (props?.selctedslot?.length > 0) {
    selectedIndex = props?.selctedslot?.map(e => {
      return e.index
    })
  }

  const getTimeWithAmPm = (timeString) => {
    const [hour] = timeString.split(":")
    if (hour === "00") {
      return `12:00AM`
    }
    else if (hour === "24") {
      return `12:00PM`
    }
    else {
      if (hour >= 12) {
        if (hour % 12 === 0) return `12:00PM`
        else return `${hour % 12}:00PM`.padStart(7, '0')
      } else return `${hour}:00AM`
    }
  }
  return (
    <Box
      className={
        props?.response?.amenity?.[0]?.operational_status === "Under Maintenance" ? classes.underMaintanceBox :
          (props?.response?.amenity?.[0]?.period === "Hourly" ? props?.data?.slot_detail?.is_booked :
            props?.data?.check_in_date?.is_booked) ?
            classes.bookedCard :
            (props?.response?.amenity?.[0]?.period === "Hourly" && props?.data?.slot_detail?.is_expired) ?
              classes.bookedCard :
              (selectedIndex.includes(props?.index) ? classes.selectedBox : props?.changeBg ?
                classes.mainslot : classes.main)
      }
      onClick={() => {
        if (props?.response?.amenity?.[0]?.period === "Hourly" ? (!props?.data?.slot_detail?.is_booked && !props?.data?.slot_detail?.is_expired) : !props?.data?.check_in_date?.is_booked) {
          if (props?.response?.amenity?.[0]?.operational_status !== "Under Maintenance") {
            onclick(props?.data, props?.index)
          }
        }
      }
      }
      sx={{ position: "relative" }}
    >
      {
        props?.data?.slot_detail?.is_peak &&
        <Box className={classes.peakBox}>
          <ElectricIcon />
        </Box>
      }
      {/* {
        props?.response?.amenity?.[0]?.is_overbooking &&
        <Typography className={classes.obLabel}>OB</Typography>
      } */}
      <Box className={classes.gridBox} >
        <Typography className={
          props?.response?.amenity?.[0]?.operational_status === "Under Maintenance" ? classes.maintanceSlotTime :
            (props?.response?.amenity?.[0]?.period === "Hourly" ? props?.data?.slot_detail?.is_booked : props?.data?.check_in_date?.is_booked) ?
              classes.slotTimeBooked :
              (props?.response?.amenity?.[0]?.period === "Hourly" && props?.data?.slot_detail?.is_expired) ? classes.slotTimeBooked :
                classes.slotTime
        }
        >
          {props?.response?.amenity?.[0]?.period === "Hourly" ?
          
            `${props?.data?.check_in_date ?
              format(new Date(props?.data?.check_in_date?.date), "dd LLL yy")
              :
              getTimeWithAmPm(props?.data?.check_in_time)} 
            -
           ${props?.data?.check_out_date ?
              format(new Date(props?.data?.check_out_date?.date), "dd LLL yy")
              :
              getTimeWithAmPm(props?.data?.check_out_time)}`

            :

            format(new Date(props?.data?.check_in_date?.date), "dd LLL yy")
          }
        </Typography>
      </Box>
      <Box className={classes.gridBox}>

        {
          props?.response?.amenity?.[0]?.operational_status === "Under Maintenance" ?
            <Typography className={classes.maintanceContent}> {t("Under Maintenance")} </Typography> :
            (props?.response?.amenity?.[0]?.period === "Hourly" ? props?.data?.slot_detail?.is_booked : props?.data?.check_in_date?.is_booked) ?
              <Typography className={classes.bookedText}>{t("Booked")}</Typography> :
              (props?.response?.amenity?.[0]?.period === "Hourly" && props?.data?.slot_detail?.is_expired) ?
                <Typography className={classes.bookedText}>{t("Expried")}</Typography> :
                <Typography className={classes.slotPerson}>
                  {
                    props?.response?.amenity?.[0]?.is_overbooking &&
                      `${props?.data?.slot_detail?.book_count} / ${props?.response?.amenity?.[0]?.participants_count} ${t("Booked")}` 
                      // `${props?.response?.amenity?.[0]?.participants_count} Persons / Members`
                  }

                </Typography>
        }

      </Box>
    </Box>
  );
};

export default withNamespaces("amenitiesBooking")(Slots)