import { Box, Button, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "12px 16px",
    backgroundColor: "#fff",
    boxShadow: "0px 8px 24px #1C1C1C40",
    borderRadius: theme.palette.borderRadius,
  },
  totalInvoiceAmountTextStyle: {
    color: "#091B29",
    fontSize: "1rem",
    textAlign: "left",
    fontWeight: "bold",
  },
  totalNoOfInvoiceTextStyle: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    textAlign: "left",
  },
  totalQuotationAmountTextStyle: {
    color: "#091B29",
    fontSize: "1.125rem",
    textAlign: "left",
    fontWeight: "bolder",
  },
  totalQuotationLableTextStyle: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    textAlign: "left",
    fontWeight: "bold",
  },
  payButtonTextStyle: {
    borderRadius: "4px",
    backgroundColor: "#5078e1",
    color: "#fff",
    boxShadow: "none",
    padding: "12px 15px",
    display: "block",
    lineHeight: "15px",
    marginRight: "0",
    marginLeft: "auto",
    textTransform: "capitalize",
    width: "100%",
    fontSize: "0.875rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      backgroundColor: "#5078e1",
      boxShadow: "none",
      opacity: ".7",
    },
  },
}));
const PayCard = (props) => {
  const classes = useStyles();
  // const handleOnClick = () => {
  //     alert("Clicked")
  // };
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        className={classes.root}
      >
        <Grid alignItems="center" direction="column">
          <Grid alignItems="center" direction="column">
            <Grid item>
              <Typography
                className={
                  props?.type === "invoice"
                    ? classes.totalInvoiceAmountTextStyle
                    : classes.totalQuotationLableTextStyle
                }
              >
                {props?.type === "invoice"
                  ? props?.currencySymbol + " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.total))
                  : props?.t("total_amount")}
              </Typography>
            </Grid>
            <Box height="2px" />
            <Grid item>
              <Typography
                className={
                  props?.type === "invoice"
                    ? classes.totalNoOfInvoiceTextStyle
                    : classes.totalQuotationAmountTextStyle
                }
              >
                {props?.type === "invoice"
                  ? props?.t("total_of") +" "+ props.active +" "+ props?.t("invoice")
                  : props?.data.currency_symbol + " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.data.amount))}
              </Typography>
            </Grid>
          </Grid>
          <Box height="2px" />
        </Grid>
        <Box height="2px" />
        <Grid alignItems="center">
          <Button
            variant="contained"
            onClick={props.handleOnClick}
            className={classes.payButtonTextStyle}
          >
              {props?.type === "invoice"
                ? props?.t("proceed_payment")
                : props?.t("pay_now")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default withNamespaces("invoice")(PayCard);
