import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Container, Divider, FormControl, FormControlLabel, Hidden, IconButton, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { AlertDialog, DrawerComponent, LoderSimmer } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { UTILITIES_DROPDOWN_UNITS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { UtilitiesList } from "./detailCard";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
      root: {
            padding: "0px",
            backgroundColor: '#F6F6F6',
      },
      title: {
            fontSize: "1rem",
            fontFamily: FontFamilySwitch().bold,
            color: "#071741",
            textAlign: "center",
            marginTop: "5%",
      },
      search: {
            [`& fieldset`]: {
                  borderRadius: "4px",
                  height: "38px",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #CED3DD",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: "6px 0px 10px 10px",
                  fontSize: "0.875rem",
            },
      },
      topDrap: {
            backgroundColor: theme.palette.background.secondary,
            borderRadius: theme.palette.borderRadius,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1px 15px 1px 15px',
            cursor: 'pointer'
      },
      toptitle: {
            color: theme.typography.color.primary,
            fontSize:"0.75rem",
            fontFamily: FontFamilySwitch().bold,
            [theme.breakpoints.down('sm')]: {
                  maxWidth: "120px"
            }
      },
      rationame: {
            '&.MuiFormControlLabel-label': {
                  color: '#091B29',
                  fontSize: "0.875rem",
                  fontFamily: FontFamilySwitch().bold,
            }
      },
      noData: {
            color: "#999",
            textAlign: "center",
            marginTop: "8vh",
            fontSize: "0.875rem",
            fontFamily: FontFamilySwitch().bold,
            marginLeft: "12px",
            paddingTop: "25px"
      },
      utilitylist: {
            marginTop: "48px"
      },
      popuplabel:{
            [theme.breakpoints.down('sm')]: {
                  maxWidth: "120px"
            }  
      }
}));


const UtilitiesDetails = (props) => {
      const { t = () => false, handleTopBarState } = props
      const classes = useStyles();
      const size = useWindowDimensions();
      const alert = React.useContext(AlertContext);
      const searchURL = useLocation().search;
      const propertyId = new URLSearchParams(searchURL).get("propertyId");
      const propertyName = new URLSearchParams(searchURL).get("name");
      const [data, setData] = React.useState([]);
      const [loading, setLoading] = React.useState(true);
      const [offset, setOffset] = React.useState(0);
      const backdrop = React.useContext(BackdropContext);

      const [open, setOpen] = React.useState(false);
      const [value, setValue] = React.useState([])
      const [selected, setSelected] = React.useState({})

      const porpertyagainstunit = (id) => {
            const data = {
                  query: UTILITIES_DROPDOWN_UNITS,
                  variables: {
                        property_id: propertyId,
                        unit_id: id
                  },
            };
            NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        setValue(response?.data?.data?.unit)
                        setSelected(response?.data?.data?.unit?.[0])
                        handleTopBarState({
                              text: propertyName,
                              isUtilities: true,
                              component: (
                                    <Box className={classes.topDrap} onClick={() => setOpen(true)}>
                                          {/* <Box>
                                                <Typography className={classes.toptitle}>{response?.data?.data?.unit?.[0]?.label ? response?.data?.data?.unit?.[0]?.label : t("nounitsfound")}</Typography>
                                          </Box> */}
                                          <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                                                <Typography className={classes.toptitle} noWrap>{response?.data?.data?.unit?.[0]?.unit_name ? response?.data?.data?.unit?.[0]?.unit_name : t("nounitsfound")}</Typography>
                                                <Typography className={classes.toptitle} noWrap>{response?.data?.data?.unit?.[0]?.label}</Typography>
                                          </Stack>
                                          <Box>
                                                <ExpandMoreIcon style={{ color: '#091B29', marginTop: '5px' }} />
                                          </Box>

                                    </Box>
                              )
                        })
                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("SomethingWentwrong"),
                        });
                  });
      }

      const getUnitDetails = (unitId, offset) => {
            const payload = {
                  tenantId: `${config.tenantid}`,
                  unit_id: unitId,
                  offset: offset,
                  limit: 20,
            };
            NetworkCall(
                  `${config.api_url}/unit-utils`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        setData(response?.data?.data)
                        setOpen(false)
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                        setLoading(false);
                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("SomethingWentwrong"),
                        });
                  });
      };
      const getAggreement = () => {
            NetworkCall(
                  `${config.api_url}/agreement-unit-info/switcher`,
                  NetWorkCallMethods.post,
                  {},
                  null,
                  true,
                  false
            ).then((response) => {
                  const units = response?.data?.data?.map((item) => item.units)
                  const unitId = units?.map((item) => item).flat()
                  porpertyagainstunit(unitId)
                  // getContactDetails(
                  //       res.data.data.agreements.result
                  //             .filter((val) => val.is_primary === true)
                  //             .map((val) => val.id),
                  //       propertyId,
                  //       0,
                  //       "",
                  //       false
                  // );
                  // setAgreement(
                  //       res.data.data.agreements.result
                  //             .filter((val) => val.is_primary === true)
                  //             .map((val) => val.id)
                  // );
            });
      };
      React.useEffect(() => {
            getAggreement();
            // eslint-disable-next-line
      }, []);
      React.useEffect(() => {

            if (selected?.value) {
                  getUnitDetails(selected?.value)
            }
            // eslint-disable-next-line
      }, [selected]);
      const fetchMoreData = () => {
            setOffset(offset + 10);
            getUnitDetails(selected?.value, offset + 10)
      };

      const SwitchUnit = (event) => {
            let temp_selected_unit = value?.filter(_ => event?.target?.value === _?.value)?.[0]
            setSelected(temp_selected_unit)
            handleTopBarState({
                  text: propertyName,
                  isUtilities: true,
                  component: (
                        <Box className={classes.topDrap} onClick={() => setOpen(true)}>
                              <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                                    <Typography className={classes.toptitle} noWrap>{temp_selected_unit?.unit_name ? temp_selected_unit?.unit_name : t("nounitsfound")}</Typography>
                                    <Typography className={classes.toptitle} noWrap>{temp_selected_unit?.label}</Typography>
                              </Stack>
                              <Box>
                                    <ExpandMoreIcon style={{ color: '#091B29', marginTop: '5px' }} />
                              </Box>

                        </Box>
                  )
            })
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: t("Switchingunit"),
            });
      }
      return (
            <Container
                  className={classes.root}
                  maxWidth="sm"
            >
                  {/* <TitleBar
                        text={propertyName}

                        isUtilities
                        component={
                              <Box className={classes.topDrap} onClick={closerDrawer}>
                                    <Box>
                                          <Typography className={classes.toptitle}>{selected?.label ? selected?.label : t("nounitsfound")}</Typography>
                                    </Box>
                                    <Box>
                                          <ExpandMoreIcon style={{ color: '#091B29', marginTop: '5px' }} />
                                    </Box>

                              </Box>
                        }
                        goBack={() => history.goBack()}
                  /> */}

                  <div style={{ height: size?.height }}>
                        {loading ? (
                              <LoderSimmer count={10} />
                        ) : (
                              <>
                                    {data.length > 0 ? (
                                          <Box className={classes.utilitylist}>
                                                <InfiniteScroll
                                                      dataLength={data.length}
                                                      next={fetchMoreData}
                                                      hasMore={true}
                                                      height={size?.height - 120}
                                                >
                                                      {data?.map((val, i) => {
                                                            return <UtilitiesList t={t} data={val} unitId={selected?.value} divider={data?.length - 1 !== i ? true : false} />;
                                                      })}
                                                </InfiniteScroll>
                                          </Box>
                                    ) : (
                                          <Box mt={"50px"}>
                                                <Typography className={classes.noData}>
                                                      {t("noUtilitiesMapped")}
                                                </Typography>
                                          </Box>
                                    )}
                              </>
                        )}



                        {/* drawer */}
                        <Hidden smUp>
                              <DrawerComponent onClose={() => setOpen(false)} open={open}
                                    component={
                                          <div>
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                                      <Box>
                                                            <Typography className={classes.title}>{t("Unit")}</Typography>
                                                      </Box>
                                                      <Box>
                                                            <IconButton size="small" onClick={() => setOpen(false)}>
                                                                  <CloseIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Box>
                                                <FormControl>
                                                      <RadioGroup
                                                            value={selected?.value}
                                                            onChange={SwitchUnit}
                                                            style={{ padding: "8px" }}>
                                                            {value?.map((val) => <FormControlLabel value={val?.value} control={<Radio />} label={<Stack direction={"row"} spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                                                                  <Typography className={classes.popuplabel} noWrap>{val?.unit_name}</Typography>
                                                                  <Typography noWrap>{val?.label}</Typography>
                                                            </Stack>} />)}
                                                      </RadioGroup>
                                                </FormControl>
                                          </div>
                                    }

                              />
                        </Hidden>

                        {/* dialog */}
                        <Hidden smDown>
                              <AlertDialog onClose={() => setOpen(false)} open={open}
                                    component={
                                          <div>
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                                      <Box>
                                                            <Typography className={classes.title}>{t("Unit")}</Typography>
                                                      </Box>
                                                      <Box>
                                                            <IconButton size="small" onClick={() => setOpen(false)}>
                                                                  <CloseIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Box>
                                                <FormControl>
                                                      <RadioGroup
                                                            value={selected?.value}
                                                            onChange={SwitchUnit}
                                                            style={{ padding: "8px" }}>
                                                            {value?.map((val) => <FormControlLabel value={val?.value} control={<Radio />} label={<Stack direction={"row"} spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                                                                  <Typography className={classes.popuplabel} noWrap>{val?.unit_name}</Typography>
                                                                  <Typography noWrap>{val?.label}</Typography>
                                                            </Stack>} />)}
                                                      </RadioGroup>
                                                </FormControl>

                                          </div>
                                    }

                              />
                        </Hidden>
                  </div>
            </Container>
      );
};
export default withNamespaces("utilites")(UtilitiesDetails)