import React from "react";

function BlueCity() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.7"
      height="14"
      viewBox="0 0 14.7 14"
    >
      <path
        fill="#78b1fe"
        d="M8.308 4A1.75 1.75 0 006.5 5.751V11.7h1.225a2.628 2.628 0 012.625 2.625v3.15a1.213 1.213 0 01-.123.525h3.8a.525.525 0 00.525-.525V6.976a1.752 1.752 0 00-1.295-1.69L8.705 4.061A1.723 1.723 0 008.308 4zm.642 3.15h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.35zm2.45 0h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.35zM8.95 9.6h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.35zm2.45 0h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.35zm3.85 0v2.45h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7v1.05h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7V18h1.925a.525.525 0 00.525-.525v-5.95A1.931 1.931 0 0015.775 9.6zm-3.85 2.451h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.35zm-6.475.349A1.931 1.931 0 003 14.326v3.15a.525.525 0 00.525.524h5.6a.525.525 0 00.525-.525v-3.15A1.931 1.931 0 007.725 12.4zm6.475 2.1h.7a.344.344 0 01.273.14h.008v.011a.34.34 0 01.07.2v.7a.341.341 0 01-.07.2v.011h-.008a.344.344 0 01-.273.139h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.351zm-6.65 0h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.35zm2.45 0h.7a.35.35 0 01.35.35v.7a.35.35 0 01-.35.35h-.7a.35.35 0 01-.35-.35v-.7a.35.35 0 01.35-.35z"
        transform="translate(-3 -4.002)"
      ></path>
    </svg>
  );
}

export default BlueCity;