import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  folderName: {
    color: theme?.palette?.color?.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
  },
  createdAt: {
    color: theme?.palette?.color?.Tertiary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
  },
  folderParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: "26px 21px",
    background: "white",
    borderRadius: "4px",
    cursor: "pointer"
  },
  more: {
    fontSize: "1.25rem",
    color: "gray",
    cursor: "pointer",
  },
  menuItem: {
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    margin: "0px 4px",
    fontSize: "0.875rem",
    color: theme?.palette?.secondary?.main,
    fontFamily: FontFamilySwitch().semiBold,
    "&:last-child": {
      border: 0,
    },
  },
  chip: {
    backgroundColor: theme?.palette?.primary?.main,
    padding: "3px 10px",
    borderRadius: "4px",
    color: "white",
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  moreBtn: {
    position: (props) => props?.type === 2 ? "absolute" : "relative",
    top: "3px",
    right: "0px",
  },
  listFolderView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  border: {
    borderBottom: (props) => props?.is_divider ? `1px solid ${theme?.palette?.border?.secondary}` : "none",
    cursor: 'pointer'
  }
}));
