import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  mainPadding: {
    padding: "14px 16px 16px 16px",
  },
  mainPadding2: {
    padding: "14px 14px 16px 16px",
  },
  mainPadding3: {
    padding: "12px 16px 12px 16px",
  },
  containerPadding: {
    padding: "10px 16px 10px 16px",
  },
  mainContainer: {
    backgroundColor: "#fff",
  },
  tapContainer: {
    borderRadius: "8px",
    padding: "6px 8px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "8px",
  },
  tapButtonActive: {
    padding: "6px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.75rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  tapButton: {
    padding: "6px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.75rem",
    boxShadow: "none",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#fff",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  title: {
    fontSize: "1rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
  },

  listContainer: {
    padding: "16px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 3px 30px #4354721F",
  },
  formContainer: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    boxShadow: "0px 3px 30px #4354721F",
  },
  subTitle: {
    fontSize: "0.875rem",
    color: "#404E61",
    fontFamily: FontFamilySwitch().bold,
  },
  subTitle2: {
    fontSize: "0.875rem",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().regular,
  },
  subTitle3: {
    fontSize: "0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
  },
  subTitle4: {
    fontSize: "0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
  },
  subTitle5: {
    fontSize: "0.875rem",
    color: "#404E61",
    fontFamily: FontFamilySwitch().semiBold,
  },
  viewMapText: {
    fontSize: "0.875rem",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().semiBold,
  },
  label: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "8px",
  },
  label2: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
  label3: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
  },
  value: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },

  secondary: {
    padding: "11px 15px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#19274e",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#e6e7ec",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#e6e7ec",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },

  input: {
    margin: "10px 0 16px 0",
  },
  btnroot: {
    zIndex: 1000,
    background: "#fff",
    padding: "14px 14px",
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  filterBlock: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "#4354721F 0px 3px 30px",
    "& *": {
      borderWidth: "0px !important",
    },
  },
  filterBlock2: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "10px 15px",
    boxShadow: "#4354721F 0px 3px 30px",
  },
  container: {
    padding: "16px 16px 5px 16px",
    backgroundColor: "#fff",
  },
  chip: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    marginRight: "8px",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    "& svg": {
      fontSize: "1rem !important",
    },
  },
  filterChip: {
    backgroundColor: "#EEEEEE",
    borderRadius: "6px",
    color: "#4E5A6B",
    padding: "5px 6px",
    cursor: "pointer",
  },
  filterChipActive: {
    backgroundColor: "#5078E1",
    borderRadius: "6px",
    color: "#fff",
    padding: "5px 6px",
    cursor: "pointer",
  },
  chipText: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
  },
  chipTextActive: {
    color: "#fff",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
  },
  propertyCard: {
    borderBottom: "1px solid #E4E8EE",
    padding: "10px 0 10px 0",
    width: "100%",
  },
  propertyImg: {
    width: "100%",
    height: "180px",
    borderRadius: "8px",
  },
  propertySmImg: {
    width: "100%",
    height: "85px",
    borderRadius: "8px",
  },
  cardOverlay: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "180px",
    backgroundColor: "rgba(9, 27, 41, .3)",
    borderRadius: "8px",
    padding: "16px 16px 0 0",
  },
  overlayCount: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "85px",
    backgroundColor: "#1D1D1D73",
    borderRadius: "8px",
  },
  countBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  countText: {
    fontFamily: FontFamilySwitch().bold,
    color: "#FFFFFF",
    fontSize: "1rem",
  },
  propertyType: {
    backgroundColor: "#071741",
    padding: "4px 8px",
    color: "#FFFFFF",
    borderTopRightRadius: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    borderBottomRightRadius: "12px",
    fontSize: "0.75rem",
  },
  propertyRate: {
    backgroundColor: "#5AC782",
    padding: "4px 8px 4px 0px",
    fontFamily: FontFamilySwitch().bold,
    color: "#FFFFFF",
    fontSize: "0.75rem",
    borderRadius: "12px",
  },
  propertyOriginalRate: {
    backgroundColor: "#37AC62",
    padding: "5px 8px 5px 8px",
    fontFamily: FontFamilySwitch().bold,
    color: "#FFFFFF",
    fontSize: "0.75rem",
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
  },
  propertyDetails: {
    backgroundColor: "rgba(9, 27, 41, .5)",
    padding: "6px 16px 6px 16px",
    color: "#FFFFFF",
    borderTopRightRadius: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    borderBottomRightRadius: "12px",
    fontSize: "0.75rem",
  },

  borderRight: {
    borderRight: "1px solid #E4E8EE",
    padding: "0px 1px",
    margin: "3px 6px !important",
  },
  imgIcon: {
    marginRight: "4px",
    maxHeight: "12px",
  },
  imgIcon2: {
    marginRight: "2px",
    maxHeight: "16px",
  },
  footerText: {
    marginTop: "3px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  footerText2: {
    marginTop: "3px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    justifyContent: "space-between",
  },
  cardfooter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  shortList: {
    color: "#E29336",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    backgroundColor: "#FFE6C9",
    padding: "2px 10px",
    borderRadius: "4px",
    marginBottom: "16px",
  },
  addBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "flex-end",
  },
  centerPosition: {
    position: "absolute",
    bottom: "25px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  centerPosition2: {
    position: "absolute",
    bottom: "25px",
    left: "0px",
    width: "100%",
    // transform: "translateX(-50%)",
  },
  mapBtn: {
    padding: "8px 16px",
    textAlign: "center",
    borderRadius: "26px",
    fontSize: "0.875rem",
    boxShadow: "#5C86CB2E 0px 3px 30px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "#5C86CB2E 0px 3px 30px",
    },
  },
  shortlistedContainer: {
    width: "auto",
    backgroundColor: "#fff",
    height: "75px",
    padding: "19px 32px",
    borderRadius: "8px",
    boxShadow: "0px -1px 6px #00000014",
  },
  smText: {
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
  },
  search: {
    padding: "8px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "0px 2px 6px #00000021",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    width: "100%",
    // overflow: "hidden",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",

    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
 
  alignImgText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "6px",
  },
  alignImgText2: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
  },
  borderContainer: {
    padding: "16px 16px",
    borderBottom: "4px solid #F5F7FA",
  },
  mapContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    alignSelf: "flex-end",
  },
  amenitiesList: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexWrap: "wrap",
  },
  amenitieItem: {
    backgroundColor: "#F5F7FA",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    padding: "8px",
    borderRadius: "6px",
  },
  iconText: {
    display: "flex",
    gap: "8px",
  },
  iconName: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
  cardImg: {
    objectFit: "cover",
    width: "70px",
    height: "70px",
    borderRadius: "8px",
  },
  round: {
    width: "6px",
    height: "6px",
    backgroundColor: "#CED3DD",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0 3px",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  trashIcon: {
    width: "12px",
    height: "12px",
    border: "1px solid #E4E8EE",
    borderRadius: "50%",
    padding: "4px",
    boxSizing: "content-box",
    cursor: "pointer",
  },
  areaBlock: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexWrap: "wrap",
  },
  breakupPriceText: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#30C4AB",
    backgroundColor: "#F5F7FA",
    padding: "3px 20px",
    borderRadius: "4px",
    marginTop: "10px",
  },
}));
