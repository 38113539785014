import React from "react";

function Household() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113446" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#eef9ee"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#5ac782"
          d="M6.947 10.211v9.711A3.083 3.083 0 0010.026 23h9a3.083 3.083 0 003.079-3.079v-9.71zm9.711 4.263h-4.263a.711.711 0 110-1.421h4.263a.711.711 0 110 1.421zm5.211-5.211H7.184A1.186 1.186 0 016 8.079V6.184A1.186 1.186 0 017.184 5h14.684a1.186 1.186 0 011.184 1.184v1.895a1.186 1.186 0 01-1.184 1.184z"
          transform="translate(43.474 243)"
        ></path>
      </g>
    </svg>
  );
}

export default Household;