import { Button, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { TextBox } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { UploadComponent } from "./components/index ";
import { withBottombar } from "../../HOCs";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
    textTransform: "capitalize"

  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
    textTransform: "capitalize"
  },
}));
const InitialState = {
  title: "",
  category: "",
  description: "",
  error: {
    title: "",
    category: "",
    description: "",
  },
};
const CreateDiscussion = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  const [createData, setCreateData] = React.useState({ ...InitialState });
  const [disable, setDisable] = React.useState(null);

  // const [loading1, setLoading1] = React.useState(null);
  // const client = useApolloClient();
  // const [getunit, setgetUnit] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const [assets, setAssets] = React.useState([])
  const [agreementList, setAgreementLists] = React.useState([]);

  const updateState = (key, value) => {
    let error = createData.error;
    error[key] = "";
    setCreateData({ ...createData, [key]: value, error });
  };



  const isIamValide = () => {
    let isValid = true;
    let error = createData.error;
    //Title name
    if (createData.title.length === 0) {
      isValid = false;
      error.title = t("Title is Required");
    }
    //description name
    if (createData.description.length === 0) {
      isValid = false;
      error.description = t("Description is Required");
    }
    //category name
    if (createData.category.length === 0) {
      isValid = false;
      error.category = t("Category is Required");
    }

    setCreateData({ ...createData, error });

    return isValid;
  };
  const submit = () => {
    if (isIamValide()) {
      setDisable(true);
      const data = {
        tenantId: `${config.tenantid}`,
        property_id: createData?.category?.property_id,
        unit_id: createData?.category?.value,
        user_profile_id: localStorage.getItem("userProfileId"),
        title: createData?.title,
        description: createData?.description,
        assets: assets ?? null
      };
      NetworkCall(
        `${config.api_url}/community/create`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("Discussion Created Successfully"),
          });
          history.goBack(-1);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Something Went Wrong"),
          });
          setDisable(false);
        });
    } else {
    }
  };

  // As per CR Commented

  // const loadOptions = async (search = "", array, type) => {
  //   setLoading1(type);
  //   let result,
  //     offset = 0;

  //   if (search && !Boolean(array?.length)) {
  //     offset = 0;
  //   } else {
  //     offset = array?.length;
  //   }

  //   switch (type) {
  //     case "unit":
  //       if (!getunit?.length) {
  //         setLoading1(null);
  //         return { options: [] };
  //       }
  //       // result = await client.query({
  //       //   query: GET_PROPERTY_BY_UNITID(),
  //       //   variables: {
  //       //     unit_id: getunit,
  //       //     offset,
  //       //     limit: 10,
  //       //     search,
  //       //   },
  //       // });
  //       // setLoading1(null); 
  //       // return {
  //       //   options: [...result?.data?.unit],
  //       //   hasMore:
  //       //     array?.length + result?.data?.unit?.length <
  //       //     result?.data?.count[0]?.count,
  //       // };
  //     default:
  //       return { options: [] };
  //   }
  // };

  const getUnitIDS = () => {
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.unit_id ?? "",
              unit_id: _uitem?.unit_id ?? "",
              property_id: _uitem?.property_id ?? "",
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }
  React.useState(() => {
    handleTopBarState({
      text: t("Create Community Discussion")
    })
    getUnitIDS()
    // eslint-disable-next-line

  }, []);
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        {/* <TitleBar
          text="Create Community Discussion"

          goBack={goBack}
        /> */}

        <div style={{ height: size.height - 135, padding: "12px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* As Per CR Commented */}
              {/* <SelectBox
                isRequired
                value={createData.category}
                label="Select Unit"
                placeholder="Select Unit"
                onChange={(value) => updateState("category", value)}
                isError={createData?.error?.category?.length > 0}
                errorMessage={createData?.error?.category}
                isPaginate
                loadOptions={(search, array) =>
                  loadOptions(search, array, "unit")
                }
                // options={agreementList}
                loading={loading1 === "unit"}
                debounceTimeout={800}
              /> */}

              <SelectBox
                isRequired
                label={t("Select Unit")}
                placeholder={t("Select Unit")}
                value={createData.category}
                options={agreementList}
                onChange={(value) => updateState("category", value)}
                isError={createData?.error?.category?.length > 0}
                errorMessage={createData?.error?.category}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBox
                color
                isRequired
                label={t("Title")}
                value={createData.title}
                placeholder={t("Enter Title")}
                onChange={(value) => updateState("title", value.target.value)}
                isError={createData?.error?.title?.length > 0}
                errorMessage={createData?.error?.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBox
                color
                isRequired
                multiline
                value={createData.description}
                label={t("Description")}
                placeholder={t("Enter Description")}
                onChange={(value) =>
                  updateState("description", value.target.value)
                }
                isError={createData?.error?.description?.length > 0}
                errorMessage={createData?.error?.description}
              />
            </Grid>
            <Grid item xs={12} padding={"0px 4px"}>
              <UploadComponent
                logo_title={t("Images")}
                assets={assets}
                setAssets={setAssets}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container className={classes.btncontainer} spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contain"
              fullWidth={true}
              className={classes.cancelbtn}
              onClick={() => history.goBack(-1)}
            >
              {t("Cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.applybtn}
              onClick={submit}
              disabled={disable}
            >
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("communityDiscussion")(CreateDiscussion), props)