import React from "react"

export const BOMIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g id="Group_117604" data-name="Group 117604" transform="translate(-76 -121.5)">
                <rect id="Rectangle_58388" data-name="Rectangle 58388" width="40" height="40" rx="6" transform="translate(76 121.5)" fill="#f6f1fe" />
                <g id="icons8-screw" transform="translate(78 123.5)">
                    <path id="Path_101892" data-name="Path 101892" d="M9.771,31.231,6,32.173,6.943,28.4,22.825,12.52l2.829,2.828Z" transform="translate(0 -2.173)" fill="#b99de3" />
                    <path id="Path_101893" data-name="Path 101893" d="M18.4,16.365,17.3,17.458l1.346,4.31,1.082-1.083Zm-2.189,2.19-1.093,1.093,1.346,4.311,1.083-1.083Zm-4.363,4.363-1.076,1.077,1.362,4.294,1.063-1.063Zm2.2-2.2-1.093,1.093,1.345,4.31,1.083-1.083Zm14.969-5.383a2.061,2.061,0,0,1-1.477-.628c-.675-.675-3.983-3.983-4.629-4.631a2.193,2.193,0,0,1-.027-3.093L23.893,6l3.225,3.219-.864.923,1.193,1.189.859-.922,3.284,3.285-1.017.979A2.221,2.221,0,0,1,29.014,15.333Z" transform="translate(-1.589)" fill="#896db3" />
                </g>
            </g>
        </svg>

    )
}