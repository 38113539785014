import React from "react";

function RedCross() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g data-name="Group 106591" transform="translate(-251 -129)">
        <path
          fill="#ff4b4b"
          d="M34.955 20.837a3.768 3.768 0 00-1.055-6.044l-1.571-.741a1.288 1.288 0 01-.727-1.276l.152-1.745a3.77 3.77 0 00-1.307-3.2 3.685 3.685 0 00-3.34-.749l-1.674.455a1.268 1.268 0 01-1.368-.5L23.072 5.6a3.676 3.676 0 00-6.065 0l-.993 1.436a1.268 1.268 0 01-1.369.5l-1.673-.455a3.678 3.678 0 00-3.339.749 3.77 3.77 0 00-1.307 3.2l.152 1.746a1.292 1.292 0 01-.728 1.277l-1.571.74a3.768 3.768 0 00-1.053 6.044l1.224 1.238a1.3 1.3 0 01.25 1.451l-.73 1.589a3.787 3.787 0 00.149 3.457A3.7 3.7 0 008.9 30.431l1.727.153a1.277 1.277 0 011.115.947l.448 1.693a3.731 3.731 0 002.31 2.551 3.67 3.67 0 003.391-.452l1.418-1.007a1.262 1.262 0 011.456 0l1.419 1.007a3.667 3.667 0 003.391.452 3.736 3.736 0 002.31-2.551l.448-1.693a1.274 1.274 0 011.115-.947l1.725-.153a3.7 3.7 0 002.885-1.859 3.789 3.789 0 00.148-3.458l-.733-1.589a1.3 1.3 0 01.253-1.451z"
          data-name="icons8-verified-badge (1)"
          transform="translate(250.961 129)"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
          d="M0 0L9.656 9.656"
          data-name="Line 1688"
          transform="translate(266.172 144.172)"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
          d="M0 9.656L9.656 0"
          data-name="Line 1689"
          transform="translate(266.172 144.172)"
        ></path>
      </g>
    </svg>
  );
}

export default RedCross;