export const LastServiceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="Group_116870" data-name="Group 116870" transform="translate(-49 -896)">
                <rect id="Rectangle_58383" data-name="Rectangle 58383" width="24" height="24" rx="4" transform="translate(49 896)" fill="#fef4f4" />
                <g id="icons8-service_2_" data-name="icons8-service (2)" transform="translate(47.001 894)">
                    <path id="Path_101490" data-name="Path 101490" d="M8.8,21.487c.044-.044.577-.621,1.42-1.509,1.243-1.287,6.657-7.057,6.746-7.145.4-.4,2.308,1.065,3.906-.533A3.683,3.683,0,0,0,21.8,8.439l-2.042,2.042a2.462,2.462,0,0,1-2.219-2.219l2.042-2a3.8,3.8,0,0,0-3.906.932C14.121,8.75,15.5,10.7,15.1,11.1c-.089.044-7.678,7.279-7.767,7.367h0c-.754.71-.8.754-.843.754a1.718,1.718,0,0,0-.044,2.352A1.678,1.678,0,0,0,8.8,21.487ZM7.775,19.579a.666.666,0,1,1-.666.666A.654.654,0,0,1,7.775,19.579Z" transform="translate(0 -0.043)" fill="#607d8b" />
                    <path id="Path_101491" data-name="Path 101491" d="M21.09,19.758,9.772,8.53,8.663,6.888,6.621,6,6,6.621l.888,2.042L8.53,9.772,19.847,21Z" transform="translate(-0.001)" fill="#b0bec5" />
                    <path id="Path_101492" data-name="Path 101492" d="M21.758,19.228c-.932-2.219-1.465-1.021-2.53,0h0c-1.021,1.021-2.219,1.553,0,2.53,2.175.932,4.571,5.193,5.5,6.169a2.06,2.06,0,0,0,3.151,0h0a2.061,2.061,0,0,0,0-3.151C26.951,23.844,22.69,21.448,21.758,19.228Z" transform="translate(-6.661 -6.66)" fill="#ffc107" />
                    <path id="Path_101493" data-name="Path 101493" d="M19.252,19.228c1.021-1.021,1.553-2.219,2.53,0l-2.53,2.53C17.077,20.826,18.231,20.294,19.252,19.228Z" transform="translate(-6.684 -6.66)" fill="#ff9800" />
                </g>
            </g>
        </svg>
    )
}