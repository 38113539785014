import { Box, Container, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { MyBookingListStyle } from './style'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';
import { NetworkCall } from '../../networkcall';
import { config } from '../../config';
import { AlertProps, NetWorkCallMethods } from '../../utils';
import { useLocation } from 'react-router-dom';
import ListCard from './listCard';
import { AlertContext, AuthContext } from '../../contexts';
import { withNamespaces } from 'react-i18next';
import { format } from 'date-fns';

const MyBookingList = ({ t = () => false }) => {
    const auth = React.useContext(AuthContext)
    const classes = MyBookingListStyle({ language: auth?.auth?.auth?.language })
    const history = useHistory()
    const state = useLocation()?.state
    const alert = React.useContext(AlertContext)

    //State
    const [bookingList, setBookingList] = React.useState([])

    const back = () => {
        history.goBack()
    }

    React.useEffect(() => {
        getBookingList()
        // eslint-disable-next-line
    }, [])

    // Get Booking List 
    const getBookingList = () => {
        const payload = {
            property_facility_id: state?.facilityId,
            current_date: format(new Date(),"yyyy-MM-dd HH:mm:ss") 
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_my_bookings`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setBookingList(response?.data?.data?.bookings)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    return (
        <Container maxWidth="sm" className={classes.container}>
            {bookingList?.length > 0 && <Box className={classes.navigate}>
                <IconButton className={classes.backbtn} onClick={back}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
            </Box>}
            {
                bookingList?.length > 0 ?
                    <Stack spacing={2} className={classes.body_content}>
                        {
                            bookingList?.map((e) => {
                                return (
                                    <ListCard data={e} />
                                )
                            })
                        }
                    </Stack>
                    :
                    <Box className={classes.centerContent}>
                        <Stack spacing={3} textAlign={"center"}>
                            <Typography className={classes.noPastBooking}>No Past Bookings Found</Typography>
                            <Typography className={classes.backbtntext} onClick={back} >Back</Typography>
                        </Stack>
                    </Box>
            }
        </Container>
    )
}

export default withNamespaces("MyBookingList")(MyBookingList)