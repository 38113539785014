import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    image: {
        objectFit: "cover",
        width: "50px",
        height: "50px",
        borderRadius: "12px",
        marginInlineEnd: "16px",
        [theme.breakpoints.down(321)]: {
            height: "125px"
        },
    },
    card: {
        backgroundColor: "white",
        borderRadius: theme.palette.borderRadius2,
        boxShadow: "0px 0px 4px #00000029",
        cursor: "pointer",
        position: "relative",
        padding: "10px 16px"
    },
    name: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        marginInlineStart: "12px",
    },
    iconButton: {
        backgroundColor: "#F2F4F7", 
        border: "1px solid #E4E8EE"
    },
  }));