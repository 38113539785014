import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Filter, LoderSimmer } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods, enumSelect, enumCatagory, FontFamilySwitch } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import AnnouncementCard from "./announcementCard";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginLeft: "8px",
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
}));

const Announcement = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const size = useWindowDimensions();
  let companyId = JSON.parse(localStorage.getItem("accessType"));
  const [drawer, setDrawer] = React.useState(false);
  const [datas, setDatas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const [property, setProperty] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [enumValue, setEnumValue] = React.useState({
    type: {}
  });

  const getNotification = (propertyID, type, offSet, filter) => {
    const params = {
      tenantId: `${config.tenantid}`,
      company_id: companyId?.map((val) => val?.company_id),
      type: [type],
      property_id: propertyID,
      offset: offSet,
      limit: 15,
      is_draft: false,
      to_builds: [config.app_x_build] ?? ""

    }
    NetworkCall(
      `${config.api_url}/notifications/list`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setTotal(response?.data?.data?.totalRecords);
        if (filter) {
          setDatas(response?.data?.data?.response);
        } else {
          setDatas(datas.concat(response?.data?.data?.response));
        }
        setLoading(false);
        setDrawer(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  const getproperty = (id) => {
    const data = JSON.stringify({
      // agreementId: id,
      tenantId: `${config.tenantid}`,
      is_pagination: false
    });
    NetworkCall(
      `${config.api_url}/agreement-property-info/get`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let propertyFilter = response.data.data?.propertyInfo.map((val) => {
          return {
            label: val?.property_name,
            value: val?.property_id,
          };
        });
        setSelectedFilter(propertyFilter.map((val) => val?.value));
        getEnum(response.data.data?.propertyInfo.map((val) => val?.property_id))
        setProperty(propertyFilter);
      })
      .catch((error) => {
        if (error?.code === 503) {
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Some thing Went wrong."),
          });
        }
      });
  };

  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.notifications_content_type])

    let requestSourceMap = stayType?.notifications_content_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap).map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    setEnumValue({ ...enumValue, type: requestType })
    getNotification(
      data,
      requestType?.type1,
      0,
      false
    );
  }
  React.useEffect(() => {
    handleTopBarState({
      text: t("Announcement")
    })
    getproperty()
    // eslint-disable-next-line
  }, []);
  const filterData = [
    {
      id: "1",
      title: "Property Name",
      key: "property_name",
      showSearch: true,
      filterType: "CHECKBOX",
      values: property,
    },
  ];
  const applyFilter = (val) => {
    if (val?.property_name?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleast select one property name"),
      });
      return false
    }
    setSelectedFilter(val?.property_name);

    getNotification(val?.property_name, enumValue?.type?.type1, 0, true);
  };
  const fetchMoreData = () => {
    setOffset(offset + 15);
    getNotification(selectedFilter, enumValue?.type?.type1, offset + 15, false);
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        text={t("Announcement")}

        goBack={() => history.push(Routes.service)}
      /> */}
      <Box display="flex" alignItems="center" p={1}>
        <Box flexGrow={1}>
          <Typography className={classes.title}>
            {t("Announcement")} ({total})
          </Typography>
        </Box>
        <Box>
          <IconButton
            className={classes.filter}
            onClick={() => setDrawer(true)}
          >
            <Badge variant="dot" color="primary">
              <img src="/images/icons8-funnel.svg" alt="filter" />
            </Badge>
          </IconButton>
        </Box>
      </Box>
      {loading ? (
        <LoderSimmer card count={10} size={100} />
      ) : (
        <InfiniteScroll
          dataLength={datas?.length}
          next={fetchMoreData}
          hasMore={true}
          height={size?.height - 100}
          style={{overflow: "overlay"}}
        >
          <div
            style={{
              padding: "0px 8px 8px 16px",
            }}
          >
            {datas?.map((val) => {
              return <AnnouncementCard t={t} data={val} />;
            })}
          </div>
        </InfiniteScroll>
      )}

      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={() => setDrawer(false)}
          fullWidth
          className={classes.dialog}
          maxWidth="sm"
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              property_name: selectedFilter,
            }}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
            onApply={applyFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={drawer}
          onClose={() => setDrawer(false)}
          className={classes.dialog}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              property_name: selectedFilter,
            }}
            onApply={applyFilter}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};

export default withNamespaces("announcement")(Announcement)