import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const DetailHeadCardStyle = makeStyles((theme) => ({
    avatar: {
        height: 48,
        width: 48,
        borderRadius: '4px',
        marginInlineEnd: "8px"
    },
    title: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        '@media (min-width: 424px)': {
            width: "90%"
        },
        '@media (max-width: 376px)': {
            width: "80%"
        },
        '@media (max-width: 321px)': {
            width: "75%"
        },

    },
    title1: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",

    },
    content: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
        paddingTop: "5px"
    },
    paid: {
        fontSize: "0.75rem",
        color: theme.palette.success.main,
        textAlign: "right",
        fontFamily: FontFamilySwitch().regular,
        paddingTop: "5px"
    },
    detailHeadCardRoot: {
        '@media (min-width: 424px)': {
            padding:"16px"
        },
        '@media (max-width: 376px)': {
            padding:"16px"
        },
        '@media (max-width: 321px)': {
            padding:"12px"
        },
    }
}))

export const DetailCardStyle = makeStyles((theme) => ({
    title: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    content: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    total: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    bookingStatusBox: {
        borderRadius: 4,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        textAlign: "center",
        color: (props) => props?.booking_status_obj?.color,
        background: (props) => props?.booking_status_obj?.bgcolor
    }
}))

export const BookingCardStyle = makeStyles((theme) => ({
    avatar: {
        height: 42,
        width: 42,
        marginInlineEnd: "8px"
    },
    title: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    content: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
        direction: "ltr"
    },
    headerTitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        marginBottom: 4,
        textTransform: "uppercase",
        fontFamily: FontFamilySwitch().semiBold,
    }
}))

export const CancelBookingStyle = makeStyles((theme) => ({
    header: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    submitBtn: {
        textTransform: "capitalize",
        height: 42,
        borderRadius: '4px'
    }
}))