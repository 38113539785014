import React from "react";

function Resend() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      data-name="Group 106593"
      viewBox="0 0 20 20"
    >
      <path
        fill="#78b1fe"
        d="M18.578 5.973a.812.812 0 00-.8.823v.719a7.4 7.4 0 102.78 4.867.812.812 0 10-1.612.2 5.683 5.683 0 11-5.636-4.974 5.63 5.63 0 012.915.812h-.072a.812.812 0 100 1.625h2.437a.812.812 0 00.812-.812V6.8a.812.812 0 00-.824-.823z"
        transform="translate(-3.311 -3.288)"
      ></path>
    </svg>
  );
}

export default Resend;