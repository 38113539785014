import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().extraBold,
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: "120px",
    // },
  },
  subHeader: {
    color: theme.typography.color.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  status: {
    color: theme.typography.color.primary,
    fontSize: "0.75rem",
    backgroundColor: "#5078E11E",
    display: "inline-block",
    padding: "0 7px 2px 7px",
    fontFamily: FontFamilySwitch().semiBold,
    borderRadius: "4px",
  },
  date: {
    color: theme.typography.color.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular
  },
  rightIcon: {
    fontSize: "1.5625rem",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.tertiary,
    cursor: "pointer",
    padding: "10px",
  },
  image: {
    objectFit: "cover",
    width: "69px",
    height: "69px",
    borderRadius: "8px"
  },
  imageBox: {
    margin: 0,
    textAlign: "left",
    marginInlineEnd: "16px"
  },
  icon: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    width: "32px",
    height: "32px"
  },
  line2: {
    flexDirection: { xs: "row", sm: "row" },
    columnGap: "6px",
    marginBottom: "4px",
  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px"
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    padding: "1px 10px",
    borderRadius: "0 0 8px 8px",
  },
}));