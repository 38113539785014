import { useApolloClient } from "@apollo/client";
import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  GETPROFILEDETAILS as query_3, GET_AGREEMENT_UNIT_ID, GET_MAINTENANCE_CATEGORY_MASTER as query_1,
  GET_MAINTENANCE_SUB_CATEGORY_MASTER_BY_MAINTENANCE_CATEGORY_ID as query_2,
  GET_UNITS_BY_AGREEMENT_ID
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage, deleteS3File } from "../../utils";
import Step0 from "./step_0"
import Step1 from "./step_1";
import Step2 from "./step_2";
import Step3 from "./step_3";
import Step4 from "./step_4";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-use";
import ScanQR from "./scanQR";
function MaintenanceParent({ t = () => false }) {
  //to find the searchparams of the query step

  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  var myCurrentDate = new Date()
  var myFutureDate = new Date(myCurrentDate);
  myFutureDate.setDate(myFutureDate.getDate() + 1);
  const initialRequestState = {
    agreement: [],
    category: [],
    subCategory: [],
    unit: [],
    problemTitle: "",
    problemDescription: "",
    problemSince: myCurrentDate,
    preferredTime: myFutureDate,
    contactName: "",
    primaryNumber: "",
    primaryCountryCode: "",
    alternateNumber: "",
    contact_image: null,
    alternateNumberCountryCode: "",
    selectedImages: [],
    uploadedImages: [],
    categoryList: [],
    subCategoryList: [],
    unitList: [],
    location: "",
    selectedSlot:"",
    error: {
      agreement: "",
      category: "",
      subCategory: "",
      unit: "",
      problemTitle: "",
      problemSince: "",
      preferredTime: "",
      contactName: "",
      primaryNumber: "",
      alternateNumber: "",
      location: ""
    },
  };
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const client = useApolloClient();
  const [request, setRequest] = React.useState({ ...initialRequestState });
  const [loading, setLoading] = React.useState(true);
  const [agreementList, SetagreementList] = React.useState([]);
  const [unitList, SetunitList] = React.useState([]);
  const [subCategory, setsubCategory] = React.useState([]);
  const [step, setStep] = React.useState(0);
  const [categoryList, SetcategoryList] = React.useState([]);
  const [agreeementUnitid, setagreeementUnitid] = React.useState("");
  const [unit, setUnit] = React.useState({
    bool: false,
    selectedUnit: {},
  })
  const [product, setProduct] = React.useState({
    bool: false,
    is_unit: false,
    is_product: false,
    selectedProduct: { assets: JSON.stringify([]) },
  })
  const [images, setImages] = React.useState([]);
  const [deletedAssetURL, setDeletedAssetURL] = React.useState([]);
  const { state } = useLocation()?.state

  //   handleAssetFile
  const handleAssetFile = (data) => {
    setDeletedAssetURL([...deletedAssetURL, data?.id]);
    setImages(images?.filter((val) => val?.url !== data.url));
  };

  const updateFunction = (value) => {
    if (value?.length > 0) {
      setImages([...images, ...value])
    }
  }

  const isStep1Valid = () => {
    let isValid = true;
    let error = request.error;
    // if (request.agreement.length === 0) {
    //   isValid = false;
    //   error.agreement = t("AgreementisRequired");
    // } else {
    //   error.agreement = "";
    // }
    if (request.category.length === 0) {
      isValid = false;
      error.category = t("CategoryisRequired");
    } else {
      error.category = "";
    }
    if (request.subCategory.length === 0) {
      isValid = false;
      error.subCategory = t("Sub-CategoryisRequired");
    } else {
      error.subCategory = "";
    }
    if (request.location?.length === 0) {
      isValid = false;
      error.location = t("Location is required");
    } else {
      error.location = "";
    }
    if (request.problemTitle.length === 0) {
      isValid = false;
      error.problemTitle = t("ProblemTitleisRequired");
    } else {
      error.problemTitle = "";
    }
    if (request.problemSince.length === 0) {
      isValid = false;
      error.problemSince = t("ProblemSinceisRequired");
    } else {
      error.problemSince = "";
    }
    if (request.preferredTime.length === 0) {
      isValid = false;
      error.preferredTime = t("PreferredVisitDateandTimeisRequired");
    } else {
      error.preferredTime = "";
    }
    if (request.problemDescription.length === 0) {
      isValid = false;
      error.problemDescription = t("DescriptionisRequired");
    } else {
      error.problemDescription = "";
    }
    if (request.selectedSlot==="") {
      isValid = false;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Please Choose the Time Slot"),
      });
    } 
    setRequest({ ...request, error });
    return isValid;
  };
  const getSubCategory = (value) => {
    const payload = {
      query: query_2,
      variables: {
        maintenanceCategoryId: value.value,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let tempMaintenanceSubCategoryMasterList =
          response?.data?.data?.maintenance_sub_category_master;
        const _tempMaintenanceSubCategoryMasterList =
          tempMaintenanceSubCategoryMasterList?.map((item, index, array) => {
            let _sm;
            try {
              _sm = {
                label: item?.type ? item?.type : "",
                value: item?.id ? item?.id : "",
              };
            } catch (error) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            }
            return _sm;
          });
        setsubCategory(_tempMaintenanceSubCategoryMasterList);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getUnit = (value) => {
    const payload = {
      query: GET_UNITS_BY_AGREEMENT_ID,
      variables: {
        id: value.units,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        SetunitList(response?.data?.data?.unit);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const onChangeState = (key, value) => {
    setRequest({ ...request, [key]: value });
  };
  const updateRequestState = (key, value,k1,v1) => {
    let error = request?.error
    error[key] = ""
    if (
      key === "category" ||
      key === "location" ||
      key === "subCategory" ||
      key === "unit" ||
      key === "problemSince" ||
      key === "preferredTime" ||
      key === "selectedImages" ||
      key === "uploadedImages"
    ) {
      setRequest({ ...request, [key]: value,[k1]:v1, error });
    } else {
      setRequest({ ...request, [key]: value,[k1]:v1, error });
    }

    if (key === "unit") {
      getagreementUnitid(request?.agreement?.value, value?.value)
    }
  };
  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const payload = {
        query: query_1,
        variables: {},
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          let tempMaintenanceCategoryMasterList =
            response?.data?.data?.maintenance_category_master;
          const _tempMaintenanceCategoryMasterList =
            tempMaintenanceCategoryMasterList?.map((item, index, array) => {
              let _cm;
              try {
                _cm = {
                  label: item?.type ? item?.type : "",
                  value: item?.id ? item?.id : "",
                };
              } catch (error) {
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: t("SomethingWentwrong"),
                });
              }
              return _cm;
            });
          SetcategoryList(_tempMaintenanceCategoryMasterList);
          const payload = {
            query: query_3,
            variables: {
              userID: localStorage.getItem(LocalStorageKeys.userProfileId),
              roleID: localStorage.getItem(LocalStorageKeys.roleID)
            },
          };
          NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              let tempContact = response?.data?.data?.user_profiles[0];
              initialRequestState.contactName =
                (tempContact?.first_name ? tempContact?.first_name : "") +
                (tempContact?.last_name ? " " + tempContact?.last_name : "");
              initialRequestState.primaryNumber = tempContact?.mobile_no
                ? tempContact?.mobile_no
                : "";
              initialRequestState.primaryCountryCode =
                tempContact?.mobile_no_country_code
                  ? tempContact?.mobile_no_country_code
                  : "";
              initialRequestState.alternateNumber =
                tempContact?.alternative_mobile_no
                  ? tempContact?.alternative_mobile_no
                  : "";
              initialRequestState.alternateNumberCountryCode =
                tempContact?.alternative_mobile_no_country_code
                  ? tempContact?.alternative_mobile_no_country_code
                  : "";
              initialRequestState.contact_image = tempContact?.image_url ?? null;
              setRequest(initialRequestState);
              setLoading(false);
            })
            .catch((error) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
      NetworkCall(
        `${config.api_url}/agreement-unit-info/switcher`,
        NetWorkCallMethods.post,
        {},
        null,
        true,
        false
      )
        .then((response) => {
          const _tempUnitList = response?.data?.data?.map(
            (_uitem, index, array) => {
              let _u;
              try {
                _u = {
                  label: _uitem?.agreement_no ? _uitem?.agreement_no : "",
                  value: _uitem?.id ? _uitem?.id : "",
                  units: _uitem.units,
                };
              } catch (error) {
                alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: t("SomethingWentwrong"),
                });
              }
              return _u;
            }
          );
          SetagreementList(_tempUnitList);
          setLoading(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      RetainLocalStorage();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);


  const getagreementUnitid = (v, x) => {
    client.query({
      query: GET_AGREEMENT_UNIT_ID(),
      fetchPolicy: 'network-only',
      variables: {
        agreementId: v,
        unitId: x,
      }
    }).then((res) => {
      setagreeementUnitid(res?.data?.agreement_units?.[0]?.id)
    }).catch((err) => {

    })
  }
  const next = (val) => {
    if (val === 2) {
      if (isStep1Valid()) {
        setStep(val);
      }
    } else {
      setStep(val);
    }
  };
  const previous = (val) => {
    setStep(val);
  };


  const handleDelete = (img, i) => {
    let urls = request?.uploadedImages?.filter((item, index) => index === i)?.map(({ url }) => url)?.filter(Boolean);
    deleteS3File({ urls });

    request.selectedImages = request.selectedImages?.filter((item, index) => index !== i)
    request.uploadedImages = request.uploadedImages?.filter((item, index) => index !== i)
    setRequest(request);
    // // setuploaddocc(uploaddocc?.filter(( index) => index !== i));
    // setuploaddocc([...props.images.slice(i)])
    setImages(images?.filter((item, index) => index !== i))
  };
  const onRemoveAll = () => {
    let urls = request?.uploadedImages?.map(({ url }) => url)?.filter(Boolean);
    deleteS3File({ urls });

    request.selectedImages = [];
    request.uploadedImages = [];
    setRequest(request);
    setImages([]);
  };
  const goBack = () => {
    if (step > 0) {
      setStep(step - 1)
    }
    else {
      if(state?.is_agreement){
      history.goBack(-1)
      }
      else{
        history.push(Routes?.requestScreen)
      }
    }
  }
  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {step === 0 && state?.maintenanceRequestType === "search" && (

            <Step0
              unit={unit}
              setUnit={setUnit}
              product={product}
              setProduct={setProduct}
              next={next}
              previous={previous}
              goBack={goBack}
            />
          )}
          {step === 0 && state?.maintenanceRequestType === "scan" && (

            <ScanQR
              unit={unit}
              setUnit={setUnit}
              product={product}
              setProduct={setProduct}
              next={next}
              previous={previous}
              goBack={goBack}
            />
          )}
          {step === 1 && (
            <Step1
              request={request}
              updateRequestState={updateRequestState}
              agreementList={agreementList}
              unitList={unitList}
              getSubCategorys={getSubCategory}
              getUnit={getUnit}
              subCategory={subCategory}
              setRequest={setRequest}
              next={next}
              step={step}
              onChangeState={onChangeState}
              previous={previous}
              product={product}
              unit={unit}
              goBack={goBack}
            />
          )}
          {step === 2 && (
            <Step2
              request={request}
              updateRequestState={updateRequestState}
              setRequest={setRequest}
              next={next}
              step={step}
              previous={previous}
              categoryList={categoryList}
              goBack={goBack}
            />
          )}
          {step === 3 && (
            <Step3
              request={request}
              setRequest={setRequest}
              updateRequestState={updateRequestState}
              next={next}
              step={step}
              previous={previous}
              updateFunction={updateFunction}
              handleAssetFile={handleAssetFile}
              images={images}
              handleDelete={handleDelete}
              onRemoveAll={onRemoveAll}
              goBack={goBack}
            />
          )}
          {step === 4 && (
            <Step4
              request={request}
              updateRequestState={updateRequestState}
              next={next}
              step={step}
              product={product}
              unit={unit}
              previous={previous}
              agreeementUnitid={agreeementUnitid}
              images={images}
              goBack={goBack}
            />
          )}
        </>
      )}
    </>
  );
}
export default withNamespaces("maintance")(MaintenanceParent)
