import React from "react";
import CaseManagement from "./caseManagement";
import { withBottombar } from "../../HOCs";
class CorrespondanceParent extends React.Component {
    render() {
        return <CaseManagement {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(CorrespondanceParent, props);