import React from "react";
import { useStyles } from "./styles";
import { Box, Grid, Typography } from "@mui/material";
import Assets from "./assets";
import { DialogDrawer } from "../dialogDrawer";
import { assestType, generateImageUrl, img_size } from "../../utils";
import ImageAndPdfViewer from "./imageAndPdfViewer";
// import Assets from "./assets";
// import { Slider } from "../../../components";

const AssetView = ({ asset, assetAll, title, isNormal = false }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const openPopup = () => {
    // if (isNormal) {
    setOpen(true);
    // } else {
    //   setOpen(true);
    // }
  };

  const getOrder = (type) => {
    let order = 1;
    if (type === assestType.General_Images) {
      order = 1;
    } else if (type === assestType.Facility_Images) {
      order = 2;
    } else if (type === assestType.Three_sixty_Images) {
      order = 3;
    } else if (type === assestType.Floor_Plan) {
      order = 4;
    }
    return order;
  };

  asset = asset
    ?.map((val) => ({ ...val, order: getOrder(val?.asset_type) }))
    .sort((a, b) => {
      return a.order - b.order;
    });
  return (
    <>
      {asset?.length === 1 && (
        <Grid container spacing={1}>
          <Grid item xs={12} onClick={openPopup}>
            <img src={generateImageUrl(asset[0]?.url,img_size.small_rectangle)} alt="" className={classes.detailImg} />
          </Grid>
        </Grid>
      )}
      {asset?.length > 1 && (
        <Grid container spacing={1} onClick={openPopup}>
          <Grid item xs={6}>
            <img src={generateImageUrl(asset[0]?.url,img_size.small_rectangle)} alt="" className={classes.detailImg} />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <img
                  src={generateImageUrl(asset[1]?.url,img_size.small_rectangle)}
                  alt=""
                  className={classes.detailImg2}
                />
              </Grid>

              <Grid item xs={6}>
                <img
                  src={generateImageUrl(asset[2]?.url,img_size.small_rectangle) ?? "/images/imagesproperty.svg"}
                  alt=""
                  className={classes.detailImg2}
                />
              </Grid>
              <Grid item xs={6}>
                <img
                  src={generateImageUrl(asset[3]?.url,img_size.small_rectangle) ?? "/images/imagesproperty.svg"}
                  alt=""
                  className={classes.detailImg2}
                />
              </Grid>
              <Grid item xs={6} onClick={openPopup}>
                {asset?.length > 5 ? (
                  <>
                    <Box position={"relative"}>
                      <img
                        src={generateImageUrl(asset[4]?.url,img_size.small_rectangle) ?? "/images/imagesproperty.svg"}
                        alt=""
                        className={classes.detailImg2}
                      />
                      <Box className={classes.overlayCount}>
                        <Box className={classes.countBlock}>
                          <Typography className={classes.countText}>
                            {"+"}
                            {asset?.length - 5}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <img
                    src={generateImageUrl(asset[4]?.url,img_size.small_rectangle) ?? "/images/imagesproperty.svg"}
                    alt=""
                    className={classes.detailImg2}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <DialogDrawer
        open={open}
        onClose={() => setOpen(false)}
        //    onOpen={() => setToggleDrawerBottom(true)}
        isnotTitle={true}
        padding={0}
        component={
          <>
            {isNormal ? (
              <>
          <ImageAndPdfViewer
            assets={assetAll}
            onClose={() => setOpen(false)}
          />
              </>
            ) : (
              <Assets
                assets={assetAll}
                title={title}
                onClose={() => setOpen(false)}
              />
            )}
          </>
        }
      />
      {/* <Dialog
        open={open}
        fullWidth
        maxWidth={maxWidth ?? "md"}
        onClose={() => setOpen(false)}
        className={classes.dialog}
      >
        {isNormal ? (
          <>
            <Slider assets={assetAll ?? []} padding={"0px 21px 0px 23px"} />
          </>
        ) : (
          <Assets
            assets={assetAll}
            title={title}
            onClose={() => setOpen(false)}
          />
        )}
      </Dialog> */}
    </>
  );
};

export default AssetView;
