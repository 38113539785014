import { Avatar, Box, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "capitalize",
  },
  roots: {
    // padding: "8px",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "text"
  },
  call: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    marginInlineStart: "8px"
  },
  mail: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    cursor: "text",
    marginInlineStart: "8px"
  },
  unitName: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    cursor: "text",
  },
  tagStyle: {
    backgroundColor: "#F1E6FE",
    borderRadius: "4px",
    padding: "4px 6px 4px 6px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5D427F",
    textTransform: "uppercase",
  },

  avatar: {
    backgroundColor: "#F0F4FF",
    width: "48px",
    height: "48px",
    marginInlineEnd: "12px"
  },
}));

const NeigbourList = (props) => {
  const { t=()=>false } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stack direction={"row"} p={"12px 16px"}>
        <Avatar className={classes.avatar}>
          <img
            src="/images/neigboursperson.svg"
            alt={"phone"}
            className={classes.img}
          />
        </Avatar>
        <Stack direction={"column"} spacing={"6px"} width={"100%"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Box>
              <Typography className={classes.name} noWrap>
                {props?.data?.account_name}
              </Typography>
            </Box>
            <Box sx={{ display: "inline-block" }}>
              <Typography className={classes.tagStyle}>
                {props?.data?.is_owner ? t("Owner") : t("Resident")}
              </Typography>
            </Box>
          </Stack>
          {props?.data?.unit_name && (
            <Box>
              <Typography className={classes.unitName} noWrap>
                {props?.data?.unit_name}
              </Typography>
            </Box>
          )}
        </Stack>
      </Stack>
      {/* <Divider />
      <Stack
        p={"8px 16px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-around"}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack direction={"row"}  alignItems={"center"}>
          <Floor />
          {props?.data?.block_name ?
            <Typography
              alignItems={"center"}
              className={classes.call} noWrap>
              {props?.data?.block_name}
            </Typography> :
            <Typography
              alignItems={"center"}
              className={classes.call} noWrap>
              -
            </Typography>
          }
        </Stack>
        <Stack direction={"row"} alignItems={"center"}>
          <img src="/images/unFurnished.svg" alt="furnished" />
          {props?.data?.floor_name ?
            <Typography className={classes.mail} noWrap>
              {props?.data?.floor_name}
            </Typography> :
            <Typography className={classes.mail} noWrap>
              -
            </Typography>
          }
        </Stack>
      </Stack> */}
    </div>
  );
};
export default withNamespaces("neighbours")(NeigbourList);
