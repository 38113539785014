import { Box, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => (
  {
    dialog: {
      width: "100%",
      maxWidth: "auto",
      padding: "0 !important",

      "& .MuiPaper-root": {
        width: "100% !important",
        borderRadius: "4px",
        backgroundColor: (props) => props?.noBg ? "transparent" : "auto",
        boxShadow: (props) => props?.noBg ? "none" : "auto",
      },
      "& .MuiDialogContent-root": {
        padding: "0px !important",
        position: 'relative',
      },
      '& .MuiDialog-container': {
        padding: (props) => props?.padding ?? '',
      },

    },
    header: {
      border: "1px solid #E4E8EE",
      fontSize: "1rem",
      fontFamily: FontFamilySwitch().extraBold,
    },
  }));
export function AlertDialog(props) {
  const classes = useStyles(props);
  return (
    <div>
      <Dialog
        id="dialog"
        open={props?.open}
        onClose={props?.onClose}
        aria-labelledby="alert-dialog-title"
        className={classes.dialog}
        maxWidth={props?.md ? "md" : props?.isNormal ? "xs" : props?.medium ? "sm" : "lg"}
        fullWidth
        aria-describedby="alert-dialog-description"

      >
        {
          !props?.isnotTitle &&
          <DialogTitle className={classes.header}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <span>{props?.header}</span>{" "}
              </Box>
              <Box marginRight="12px">
                {props?.render}
              </Box>
              <Box>
                <IconButton onClick={props?.onClose}>
                  <img
                    src="/images/close.svg"
                    alt="close"

                    style={{ cursor: 'pointer' }}
                  />
                </IconButton>
              </Box>

            </Box>



          </DialogTitle>
        }

        <DialogContent style={{ padding: "0 !important" }}>
          {props?.component}
        </DialogContent>
      </Dialog>
    </div >
  );
}
