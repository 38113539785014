import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalculate } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    imgbody: {
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: "#E4EFFF",
        padding: "40px",
        height: "150px",
        marginRight: "10px",
        marginLeft: "10px"
    },
    icon: {
        display: "flex",
        justifyContent: "center",
        color: "#5078E1"
    },
    uploadedImg: {
        width: "100%",
        height: "250px",
        cursor: "pointer"
    },
    imgblock: {
        margin: "0px 30px",
        position: "relative"
    },
    selectedImg: {
        height: "64px",
        borderRadius: "4px",
        width: "100%",
        objectFit: "cover"

    },

    text: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#5078E1"

    },
    selectedimgbody: {

        display: "flex",
        '&:hover': {
            scale: 2
        }
    },
    imgtext: {
        position: "absolute",
        bottom: 10,
        color: "white",
        top: "50%",
        marginLeft: "6px",
    },
    deleteicon: {
        display: "flex",
        position: "absolute",
        bottom: 0,
        top: "-8px",
        color: "#FF4B4B",
        right: "-7px",
        cursor: "pointer",
        height: "24px",
        backgroundColor: "#fff",
        borderRadius: "50%"
    },
    dot: {
        width: "4px",
        height: "4px",
        backgroundColor: "white",
        borderRadius: "50%",
        marginTop: "5px",
        marginLeft: "4px",
        marginRight: "4px",
    },
    imgName: {
        fontFamily: FontFamilySwitch().semiBold,
        color: "#FFFFFF",
        fontSize: "0.75rem",
    },
    textName: {
        fontFamily: FontFamilySwitch().regular,
        color: "#FFFFFF",
        fontSize: "0.75rem",
    },
    hoverCard: {
        opacity: "12",
        backgroundColor: '#0000009c',
        position: 'absolute',
        width: "100%",
        height: "150px",
        borderRadius: '4px',
        top: 1,
        bottom: 0,
        right: 0,
        left: 0,
    },
    delete: {
        position: "absolute",
        top: "-4px",
        right: "-9px"
    },
    hoverIcon: {
        cursor: 'pointer'
    }

}))
export const CommunityCardStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        overflow: "hidden",
        borderRadius: "8px",
        padding: "16px"
    },
    first_name: {
        fontSize: remCalculate(14),
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch()?.semiBold
    },
    posted_at: {
        fontSize: remCalculate(12),
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch()?.regular
    },
    title: {
        fontSize: remCalculate(14),
        color: "#071741",
        fontFamily: FontFamilySwitch()?.semiBold,
        textWrap: "no-wrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    description: {
        fontSize: remCalculate(12),
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch()?.regular,
        margin: "0px !important"
    },
    seeMoreLessTextStyle: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch()?.semiBold,
        color: theme.palette.primary.main,
        cursor: "pointer"
    },
    attachFile: {
        fontSize: remCalculate(10),
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch()?.semiBold
    },
    attachBox: {
        backgroundColor: "#F2F4F7",
        borderRadius: "10px",
        padding: "4px 12px",
        width: "fit-content"
    },
    dot: {
        borderRadius: "50%",
        backgroundColor: "#E4E8EE",
        width: "6px",
        height: "6px"
    },
    likeIcon: {
        fontSize: remCalculate(14),
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch()?.semiBold,
        marginLeft: "5px"
    },
    bottomFix: {
        position: "absolute",
        bottom: "10px"
    },
    moreImages: {
        borderRadius: "4px",
        backgroundColor: "#F2F4F7"
    },
    titleBox:{
        borderRadius: "4px",
        backgroundColor: "#F6F1FE",
        display:"flex",
        justifyContent:"center"  
    }
}))