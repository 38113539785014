/* eslint-disable array-callback-return */
import {
  Box, Dialog,
  Drawer, Hidden,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { DeliveryCard, Filter, LoderSimmer } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, enumCatagory, enumSelect, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
    overflow: "hidden",
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },

  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: "white",
    borderRadius: "4px",
    padding: "6px"
  },
  titleRoot: {
    padding: "12px"
  },
  nodata: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "gray",
    textAlign: "center"
  }
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const Delivery = (props) => {
  const { t=()=>false, filter = false, setFilter = () => false } = props
  const size = useWindowDimensions();
  const classes = useStyles();
  const [gatelist, setGateList] = React.useState([]);
  const [unitList, setUnitList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [offset, setOffset] = React.useState(0);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const alert = React.useContext(AlertContext);
  const [statusType, setStatusType] = React.useState([]);
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  //get pass List
  const getPassList = (unit, status, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: ["DCR"],
      status: status,
      offset: offset,
      limit: 20,

      agreement_unit_id: props?.selectedUnit?.value ? [props?.selectedUnit?.value] : null,
      start_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.startDate).toISOString()
          : undefined,
      end_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.endDate).toISOString()
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setGateList(response?.data?.data);
        } else {
          setGateList(gatelist.concat(response?.data?.data));
        }
        setFilter(false);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.delivery_status_type])
    setStatusType(stayType?.delivery_status_type)

    setSelectedFilter({
      status: stayType?.delivery_status_type?.map((val) => val?.value),
      unit: props?.units,

    });
    getPassList(
      props?.units,
      stayType?.delivery_status_type?.map((val) => val?.value),
      true,
      0
    );
  }

  React.useEffect(() => {
    getEnum()

    setUnitList(props?.unitList);
    // eslint-disable-next-line
  }, []);
  const filterData = [
    {
      id: "1",
      title: t("Status"),
      key: "status",
      showSearch: true,
      filterType: "CHECKBOX",
      values: statusType ?? [],
    },
    {
      id: "3",
      title: t("Unit"),
      key: "unit",
      filterType: "CHECKBOX",
      values: unitList,
    },
    {
      id: "4",
      title: t("Date"),
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];
  const applyFilter = (data) => {
    setSelectedFilter({
      status: data.status,
      unit: data.unit,
    });
    if (data?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonestatus"),
      });
      return false;
    }
    if (data?.unit?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    setOffset(0);
    getPassList(data.unit, data.status, true, 0);
  };
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(selectedFilter.unit, selectedFilter.status, false, offset + 20);
  };
  return (
    <div className={classes.root} maxWidth="sm">
      {/* <Box display="flex" alignItems="center" className={classes.titleRoot}>
        <Box flexGrow={1} >
          <Typography className={classes.total}>
            {t("DeliveryCollections")}
          </Typography>
        </Box>
        <Box sx={{ marginInlineStart: "10px" }}>
          <IconButton
            className={classes.filter}
            onClick={() => setFilter(true)}
          >
            <Badge variant="dot" color="primary">
              <img src="/images/icons8-funnel.svg" alt="filter" />
            </Badge>
          </IconButton>
        </Box>
      </Box> */}
      <div style={{ marginRight: "-6px" }}>
        {
          gatelist.length > 0 ?
            <>
              {loading ? (
                <LoderSimmer count={10} />
              ) : (
                <InfiniteScroll
                  dataLength={gatelist.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 140}
                >
                  <Box padding={"16px"}>
                    {gatelist?.map((item) => {
                      return <Box marginBottom={"10px"}><DeliveryCard t={t} data={item} parking />
                      </Box>
                    })}
                  </Box>
                </InfiniteScroll>
              )}
            </>

            :
            <Box height={size?.height - 180}>
              <Typography className={classes.nodata}>{t("NoDataFound")}</Typography>
            </Box>
        }

      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Drawer>
      </Hidden>
    </div>
  );
};
export default withNamespaces("entriesLog")(Delivery)