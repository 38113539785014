import React from "react";
import Units from './myunits';
import { withBottombar } from "../../HOCs";

class UnitsParent extends React.Component {
    render() {
        return <Units {...this.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(UnitsParent, props);
