import * as React from "react";

const GreenTickIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 40 40"
    >
      <g
        id="Group_106591"
        data-name="Group 106591"
        transform="translate(-251 -129)"
      >
        <g
          id="Rectangle_55631"
          data-name="Rectangle 55631"
          transform="translate(251 129)"
          fill="#fff"
          stroke="#707070"
          stroke-width="1"
          opacity="0"
        >
          <rect width="40" height="40" stroke="none" />
          <rect x="0.5" y="0.5" width="39" height="39" fill="none" />
        </g>
        <path
          id="icons8-verified-badge_1_"
          data-name="icons8-verified-badge (1)"
          d="M34.955,20.837A3.768,3.768,0,0,0,33.9,14.793l-1.571-.741a1.288,1.288,0,0,1-.727-1.276l.152-1.745a3.77,3.77,0,0,0-1.307-3.2,3.685,3.685,0,0,0-3.34-.749l-1.674.455a1.268,1.268,0,0,1-1.368-.5L23.072,5.6a3.676,3.676,0,0,0-6.065,0l-.993,1.436a1.268,1.268,0,0,1-1.369.5l-1.673-.455a3.678,3.678,0,0,0-3.339.749,3.77,3.77,0,0,0-1.307,3.2l.152,1.746a1.292,1.292,0,0,1-.728,1.277l-1.571.74a3.768,3.768,0,0,0-1.053,6.044L6.35,22.075A1.3,1.3,0,0,1,6.6,23.526L5.87,25.115a3.787,3.787,0,0,0,.149,3.457A3.7,3.7,0,0,0,8.9,30.431l1.727.153a1.277,1.277,0,0,1,1.115.947l.448,1.693a3.731,3.731,0,0,0,2.31,2.551,3.67,3.67,0,0,0,3.391-.452l1.418-1.007a1.262,1.262,0,0,1,1.456,0l1.419,1.007a3.667,3.667,0,0,0,3.391.452,3.736,3.736,0,0,0,2.31-2.551l.448-1.693a1.274,1.274,0,0,1,1.115-.947l1.725-.153a3.7,3.7,0,0,0,2.885-1.859,3.789,3.789,0,0,0,.148-3.458l-.733-1.589a1.3,1.3,0,0,1,.253-1.451Zm-8.191-3.248-8.11,8.206a1.208,1.208,0,0,1-1.721,0l-4.055-4.1a1.24,1.24,0,0,1,0-1.74,1.206,1.206,0,0,1,1.72,0l3.2,3.232,7.249-7.335a1.206,1.206,0,0,1,1.72,0A1.241,1.241,0,0,1,26.764,17.589Z"
          transform="translate(250.961 129)"
          fill="#5ac782"
        />
      </g>
    </svg>
  );
};

export default GreenTickIcon;
