import { Box, Stack, Typography } from "@mui/material"
import {componentStyles} from "./styles"
import {returnTopCardDetails} from "../utils"
import moment from "moment"

export const TopDetailCard=({t=()=>false,data})=>{
    const classes=componentStyles()
    const topCardDetails=returnTopCardDetails({t,type:data?.status})
    return(
        <Box className={classes.topCardRoot}>
            <Stack direction="row" alignItems="center" >
                <Box flexGrow={1}>
                <Typography className={classes.topCardTitle}>{topCardDetails?.title}</Typography>
                <Typography className={classes.topCardDes} >on {moment(data?.created_at).format("DD MMM YY hh:mm A")}</Typography>
                </Box>
                {topCardDetails?.icon}
                
            </Stack>
            <Typography className={classes.remarks}>Remark: {data?.remarks}</Typography>

        </Box>
    )
}