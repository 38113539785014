import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogDrawer } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { WorkerContext } from "./context";
import AddWorkers from "./components/addWorkers";
import WorkerInformation from "./components/workerInformation";
import UnitAndSchedule from "./components/unitAndSchedule";
import WorkerInformationCard from "./components/cards/workerInformationCard";
import { Close } from "@mui/icons-material";
import { withBottombar } from "../../HOCs";

function AddWorkerPermit(props) {
  const {
    t = () => false,
    data,
    setData,
    updateState,
    progress,
    submitWorker,
    handelselectStep, openConfirmationDrawer, setOpenConfirmationDrawer
  } = React.useContext(WorkerContext);
  const { handleTopBarState } = props
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();

  const steps = [
    {
      id: "unit_and_schedule",
      label: "Select Unit and Schedule",
      formComponent: <UnitAndSchedule />,
      progress: progress?.unitAndScheduleProgress,
      view_type: data?.unit_and_schedule?.view_type,
      // cardComponent: <UnitAndScheduleCard />,
    },
    {
      id: "select_workers",
      label: "Select Workers",
      formComponent: <AddWorkers />,
      value: progress?.selectWorkerProgress,
      view_type: data?.select_workers_object?.view_type === "hide" ? data?.select_workers_object?.view_type : data?.select_workers.filter(({ view_type }) => view_type === "view").length > 0 ? "view" : "edit",
      progress: progress?.selectWorkerProgress
      // cardComponent: <WorkerAndvendorInfo />,
      // finalComponent: <WorkerAndvendorInfo />,
    },
    {
      id: "worker_info",
      label: "Select Work Information",
      formComponent: <WorkerInformation />,
      cardComponent: <WorkerInformationCard />,
      view_type: data?.worker_info?.view_type,
      progress: progress?.workerInfoProgress
    },
  ];
  const getBorder = (type) => {
    let border = ""
    if (type === "edit") {
      border = "0.12em dashed #78B1FE";
    } else if (type === "view") {
      border = "0.12em solid #5AC782";
    } else {
      border = "0.12em solid #98A0AC";
    }
    return border;
  }
  const editForm = (type) => {
    if (type === "unit_and_schedule") {
      setData({ ...data, unit_and_schedule: { ...data?.unit_and_schedule, previous_state: data?.unit_and_schedule, view_type: "edit" } })
    } else if (type === "select_workers") {
      updateState("select_workers_object", "view_type", "edit");
    } else {
      setData({ ...data, worker_info: { ...data?.worker_info, previous_state: data?.worker_info, view_type: "edit" } })
    }
  }

  React.useEffect(() => {
    handleTopBarState({
      text: "Add Worker Permit"
    })
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={t("Add Worker Permit")} goBack={goBack} /> */}
      <div
        style={{
          height: size?.height - 135,
          overflow: "overlay",
          padding: "16px",
        }}
      >
        {steps?.map((step, index) => (
          <>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={1} sm={0.5}>
                {step?.view_type === "edit" && (
                  <Avatar className={classes.stepIcon}>
                    <Typography className={classes.IconStepCount}>
                      {index + 1}
                    </Typography>
                  </Avatar>
                )}
                {step?.view_type === "hide" && (
                  <Avatar className={classes.stepIconHide}>
                    <Typography className={classes.IconStepCountHide}>
                      {index + 1}
                    </Typography>
                  </Avatar>
                )}
                {step?.view_type === "view" && (
                  <Avatar className={classes.stepIcon}>
                    <img src="/images/checkedSuccess.svg" alt="" />
                  </Avatar>
                )}
              </Grid>
              <Grid item xs={11} sm={11.5}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box flexGrow={1} className={classes.labelContainer}>
                    <Typography className={classes.label}>
                      {step?.label}
                    </Typography>
                  </Box>
                  <Box className={classes.toolsIcon}>
                    {step?.view_type === "hide" && (
                      <img
                        src="/images/addIcon.svg"
                        alt=""
                        onClick={() => handelselectStep(step?.id)}
                      />
                    )}
                    {step?.view_type === "edit" && (
                      <CircularProgress
                        className={classes.progress}
                        variant="determinate"
                        value={step?.progress}
                      />
                    )}
                    {(step?.view_type === "view" && step?.id !== "select_workers") && (
                      <img onClick={() => editForm(step?.id)} src="/images/Outline.svg" alt="" />
                    )}
                  </Box>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={1} sm={0.5} alignItems={"center"}>
                <Box sx={{ height: "100%" }}>
                  <Box className={classes.stepContainer}>
                    {index !== steps?.length - 1 && (
                      <Box
                        className={classes.steperLine}
                        sx={{
                          borderRight: getBorder(step?.view_type)
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={11} sm={11.5}>
                <Box className={classes.stepContent}>
                  {/* {activeStep === index && ( */}
                  <>
                    {step?.formComponent}
                    {/* <Box height={"16px"} /> */}
                    {/* {step?.cardComponent} */}
                    {/* {step?.finalComponent} */}
                  </>
                  {/* )} */}
                </Box>
                {/* </Stack> */}
              </Grid>
            </Grid>
          </>
        ))}
      </div>
      <Grid
        container
        className={classes.btnroot}
        justifyContent="space-between"
        alignItems={"center"}
        spacing={0}
      >
        <Grid item xs={5}>
          <Button
            fullWidth
            className={classes.secondary}
            variant="contained"
            onClick={() => setOpenConfirmationDrawer(true)}
          >
            {t("Cancel")}
          </Button>
        </Grid>
        <Divider
          orientation="vertical"
          sx={{ borderLeft: "1px solid #98A0AC", padding: "14px 0" }}
        />
        <Grid item xs={5}>
          <Button
            fullWidth
            className={classes.primary}
            disabled={(data?.select_workers?.filter(({ view_type }) => view_type === "view").length === 0 || data?.unit_and_schedule?.view_type === "edit" || data?.worker_info?.view_type === "edit" || data?.worker_info?.view_type === "hide")}
            variant="contained"
            onClick={() => submitWorker()}
          >
            {t("Submit Now")}
          </Button>
        </Grid>
      </Grid>
      <DialogDrawer
        // height={"250px"}
        isnotTitle
        open={openConfirmationDrawer}
        padding={"0px"}
        component={<>
          <Box display={"flex"} m={1}>
            <Box flexGrow={1} />
            <Close onClick={() => setOpenConfirmationDrawer(false)} sx={{ color: "#7C8594" }} />
          </Box>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"} px={2}>
            <img src={"/images/info.svg"} alt="" />
            <Box height={"8px"} />
            <Typography className={classes.alertTitle}>
              Are you sure you want to cancel?
            </Typography>
            <Typography className={classes.alertText}>
              Any changes made will not be saved.
            </Typography>
          </Box>
          <Box height={"28px"} />
          <Grid
            container
            // className={classes.btnroot}
            justifyContent="space-around"
            spacing={0}
          >
            <Grid item xs={5}>
              <Button
                fullWidth
                className={classes.secondary}
                variant="contained"
                onClick={() => setOpenConfirmationDrawer(false)}
              >
                {t("No, Back")}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                fullWidth
                className={classes.primary}
                variant="contained"
                onClick={() => history.goBack(-1)}
              >
                {t("Yes, Cancel")}
              </Button>
            </Grid>
          </Grid>
          <Box height={"16px"} />
        </>}
        onClose={() => setOpenConfirmationDrawer(false)}
      />
    </Container>
  );
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(AddWorkerPermit, props);