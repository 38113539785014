import { Box, Stack, IconButton, Container, Grid, Typography, List, ListSubheader, ListItem, Button, Avatar } from '@mui/material'
import React from 'react'
import { PropertyCard } from './components'
import { useHistory } from 'react-router-dom'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useLocation } from "react-router-dom";
import { amenitiesDetailsStyle } from './style'
import ScrollContainer from "react-indiana-drag-scroll";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SlideCard } from './components'
import { Routes } from '../../router/routes'
import { withNamespaces } from 'react-i18next'

import { generateImageUrl, img_size } from '../../utils';
import { NoAmenitySvg } from '../../assets/noAmenity';

const AmenitiesDetailsNew = ({ t = () => false }) => {
    const classes = amenitiesDetailsStyle()
    const history = useHistory()
    const back = () => {
        history.goBack()
    }
    const state = useLocation().state

    const [scroll, setScroll] = React.useState(false)
    const property_images = [
        {
            action_url: "#",
            url: state?.data?.prop_assets[0]?.property_logo ?? ""
        }
    ]

    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.documentElement.scrollTop > 220) {
            setScroll(true);
        }
        else {
            setScroll(false);
        }
    }
    const navigate_viewamenities = (data) => {
        history.push({
            pathname: Routes.viewAmenities,
            state: {
                data: data,
                selectedProperty: state?.selectedProperty,
                property_images: property_images,
                unitData: state?.unitData
            },
        });

    }
    const selectCard = (cardData) => {
        history.push({
            pathname: Routes.availableSlot,
            state: {
                data: cardData,
                selectedProperty: state?.selectedProperty,
                property_images: property_images,
                type: "direct",
                amenity_data: {
                    symbol: cardData?.symbol,
                    rate: cardData?.amount,
                    category: cardData?.amenity_category,
                    amenities_name: cardData?.amenities_name,
                    period: cardData?.period,
                    facility_id: cardData?.facility_id,
                    assets: cardData?.amenity_assets,
                },
                unitData: state?.unitData,
                selectedDate: new Date()
            },
        });
    }
    return (
        <Container maxWidth="sm" className={state?.data?.amenities_categories?.length > 0 ? classes.normalBg : classes.noAmenityBg}  >
            {
                state?.data?.amenities_categories?.length > 0 ?

                    <div>
                        {
                            // !scroll &&
                            <div>
                                <Box className={classes.navigate}>
                                    <IconButton className={classes.backbtn} onClick={back}>
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Box sx={{ height: 300 }}>
                                        <Avatar src={generateImageUrl(state?.data?.prop_assets[0]?.image, img_size.small_square)} alt="" className={classes.bannerImg}></Avatar>
                                    </Box>
                                </Box>
                                {!scroll && <Box mr={3} ml={3} className={classes.topPropertyCard}>
                                    <PropertyCard
                                        cardData={state?.data?.prop_assets[0]}
                                        click={() => setScroll(true)}
                                        status={scroll}
                                    />
                                </Box>}
                            </div>
                        }
                        {/* Content */}
                        <List>
                            {
                                scroll &&
                                <ListSubheader>
                                    <Stack direction={"row"} spacing={2} className={classes.topnav} alignItems={"center"}>
                                        <IconButton className={classes.backbtn1} onClick={back}>
                                            <KeyboardArrowLeftIcon />
                                        </IconButton>
                                        <PropertyCard cardData={state?.data?.prop_assets[0]} click={() => setScroll(false)} status={scroll} />
                                    </Stack>
                                </ListSubheader>
                            }
                            <ListItem className={!scroll ? classes.listItem : classes.fulllistItem}>
                                <Grid Container sx={{ width: "100%" }}>
                                    <Grid item sm={12} mr={1} ml={1} className={scroll ? classes.fullposition : classes.position} >
                                        <Stack spacing={2} className={scroll && classes.scrollAdd}>

                                            {
                                                state?.data?.amenities_categories?.map((data) => {
                                                    return (
                                                        <Stack spacing={2}>
                                                            {/* <FeatureCard data={e} click={clickdata} /> */}
                                                            <Stack className={classes.box} p={1} spacing={2}>
                                                                <Stack direction={"row"} spacing={"space-between"} alignItems={"center"} sx={{ padding: "4px 4px 0px 4px" }}>
                                                                    <Stack flexGrow={1}>
                                                                        <Typography className={classes.name}> {data?.amenity_category} </Typography>
                                                                        <Typography className={classes.subtitle}> {data?.amenities_count}+ {data?.amenity_category} {t("amenitiesavailable")} </Typography>
                                                                    </Stack>
                                                                    <Box>
                                                                        <Typography className={classes.viewbtn} onClick={() => navigate_viewamenities(data)}>{t("ViewAll")}</Typography>
                                                                    </Box>
                                                                </Stack>
                                                                <Box>
                                                                    <ScrollContainer className="scroll-container">
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <Stack spacing={2} direction={"row"}>
                                                                                    {
                                                                                        data?.amenitiesItems?.map(e => {
                                                                                            return (
                                                                                                <SlideCard data={e} selectCard={selectCard} />
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Stack>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </ScrollContainer>
                                                                </Box>
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </List>
                    </div>
                    :
                    <Stack className={classes.noAmenity} spacing={1}>
                        <NoAmenitySvg />
                        <center> <Button onClick={back} >Back</Button> </center>
                    </Stack>
            }

        </Container>
    )
}

export default withNamespaces("amenitiesBooking")(AmenitiesDetailsNew)
