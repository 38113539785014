import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root:{
        backgroundColor: "transparent",
        boxShadow:"none",
        borderBottom:"1px solid none",
        borderColor:"",
    },
    headText:{
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold
    }
  }));