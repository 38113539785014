import { Button, Typography, Box } from "@mui/material";
import React from "react";
import { DocumentViewer } from "../../pdfviewer";
import { saveAs } from "file-saver";
import { FontFamilySwitch } from "../../../utils";
import { withNamespaces } from "react-i18next";
import { LoadingSection } from "../../homeloader";

const InspectionReportDocument = ({ data, t = () => false, reference_id = "", loading = true }) => {
  const downloadPdf = () => {
    if (data) {
      saveAs(`data:application/pdf;base64,${data}`, `${reference_id}.pdf`);
    }
  };
  return (
    <>
      {loading ?
        <Box height="550px">
          <LoadingSection top="10vh" message={t("Loading Pdf")} />
        </Box>
        : data !== "" ? (
          <Box p={1} position={"relative"} height="550px">
            <Box>
              <Box height="550px" overflow={"scroll"}>
                <DocumentViewer url={`data:application/pdf;base64,${data}`} />
              </Box>
              <Box height={"10px"} />
            </Box>
            <Box pb={2}>
              <Button
                fullWidth
                onClick={downloadPdf}
                variant="contained"
                sx={{
                  textAlign: "center",
                  borderRadius: "8px",
                  fontSize: "0.875rem",
                  boxShadow: "none",
                  color: "#FFFFFF",
                  fontFamily: FontFamilySwitch().semiBold,
                  cursor: "pointer",
                  backgroundColor: "#5078E1",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#5078E1",
                    opacity: 0.9,
                    boxShadow: "none",
                  },
                }}
              >
                {t("Download")}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Box height="550px">
              <Typography component={"h6"} align="center">
                {t("No Document Found")}
              </Typography>
            </Box>
          </>
        )}

    </>
  );
};

export default withNamespaces("inspection")(InspectionReportDocument);