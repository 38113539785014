import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { ListIcon } from "../../../assets/agreement/ListIcon";
import { useStyles } from "./styles";

const AgreementList = ({ list, onSelectAgreement, data }) => {
  const classes = useStyles();
 
  return (
    <Box>
      <Typography className={classes.title}>
        {"Agreement (" + list?.length + ")"}
      </Typography>
      {list?.map((res, index) => (
        <Box
          key={index}
          className={
            res.unit_no === data.unit_no
              ? classes.ActiveContainer
              : classes.container
          }
          onClick={() => onSelectAgreement(res)}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={3} sm={2}>
              <ListIcon />
            </Grid>
            <Grid item xs={9} sm={10}>
              <Typography className={classes.title}>{res.name}</Typography>
              <Typography className={classes.title2}>
                {data.property_name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default AgreementList;
