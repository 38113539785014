import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const BackupAndRestoreErrorStyle = makeStyles((theme) => ({
    box: {
        background: "#fff",
        borderRadius: "12px",
        boxShadow: "0px 3px 30px #4354721F",
        margin: "8px",
        height: "calc(100vh - 100px)",
        position: "relative"
    },
    contentBox: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)"
    },
    contentText:{
        fontSize: "1rem",
        color:theme.typography.color.Tertiary,
        fontFamily:FontFamilySwitch().semiBold
    }
}))