import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  label: {
    color: (props) => props?.labelColor ?? theme.typography.color.tertiary,
    fontFamily: (props) => (props?.fontFamily === "bold" ? FontFamilySwitch().bold : FontFamilySwitch().semiBold),
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize ?? "0.75rem",
    marginBottom: (props) => props?.labelMarginBottom ?? "8px",
    textAlign: (props) => props?.textAlign ?? "-webkit-match-parent",

  },
  required: {
    color: "red",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: (props) => props?.labelSize ?? theme.palette.labelFontSize,
    marginBottom: (props) => props?.labelMarginBottom ?? "5px",
  },
}));
