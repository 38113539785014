export const DashboardSelected = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 94902">
            <g data-name="Group 18">
                <path data-name="Rectangle 31" fill="none" d="M0 0h24v24H0z" />
            </g>
            <g fill="#5078e1">
                <path
                    data-name="Path 3339"
                    d="M11.66 5.641A1.642 1.642 0 0 0 10.019 4H5.642a1.642 1.642 0 0 0-1.641 1.641v6.565a1.642 1.642 0 0 0 1.641 1.641h4.376a1.642 1.642 0 0 0 1.641-1.641Z"
                />
                <path
                    data-name="Path 3340"
                    d="M11.66 16.452a1.642 1.642 0 0 0-1.641-1.641H5.642a1.642 1.642 0 0 0-1.641 1.641v2.188a1.642 1.642 0 0 0 1.641 1.641h4.376a1.642 1.642 0 0 0 1.641-1.641Z"
                />
                <path
                    data-name="Path 3341"
                    d="M20.283 5.627A1.635 1.635 0 0 0 18.642 4h-4.376a1.635 1.635 0 0 0-1.641 1.627v13.027a1.635 1.635 0 0 0 1.641 1.627h4.376a1.635 1.635 0 0 0 1.641-1.627Z"
                />
            </g>
        </g>
    </svg>
)