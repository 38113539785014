import * as React from "react"

const CalendarSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      data-name="icons8-calendar (5)"
      d="M3.611 0A3.624 3.624 0 0 0 0 3.611v12.778A3.624 3.624 0 0 0 3.611 20h12.778A3.624 3.624 0 0 0 20 16.389V3.611A3.624 3.624 0 0 0 16.389 0Zm0 1.667h12.778a1.932 1.932 0 0 1 1.944 1.944v.833H1.667v-.833a1.932 1.932 0 0 1 1.944-1.944ZM1.667 6.111h16.666v10.278a1.932 1.932 0 0 1-1.944 1.944H3.611a1.932 1.932 0 0 1-1.944-1.944Zm3.611 2.222a1.389 1.389 0 1 0 1.389 1.389 1.389 1.389 0 0 0-1.389-1.389Zm4.722 0a1.389 1.389 0 1 0 1.389 1.389A1.389 1.389 0 0 0 10 8.333Zm4.722 0a1.389 1.389 0 1 0 1.389 1.389 1.389 1.389 0 0 0-1.389-1.389Zm-9.444 5a1.389 1.389 0 1 0 1.389 1.389 1.389 1.389 0 0 0-1.389-1.389Zm4.722 0a1.389 1.389 0 1 0 1.389 1.389A1.389 1.389 0 0 0 10 13.333Z"
      fill="#5078e1"
    />
  </svg>
)

export default CalendarSvg
