import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    boxStyles:{
        backgroundColor: "#fff",
        padding:"20px",
        borderRadius:"12px",
        cursor:"pointer"
    },
    arrowIcon:{
        color:theme.typography.color.secondary,
        fontSize: "1rem"
    },
    avatarIcon:{
        width:"16px",
        height:"16px",
    },
    text:{
        color:theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold
    },
    bottomText:{
        color:theme.typography.color.secondary,
        fontSize: "0.875rem",
        fontFamily:FontFamilySwitch().regular
    },
    bottomTextDetailed:{
        color:theme.typography.color.secondary,
        fontSize: "0.75rem",
        fontFamily:FontFamilySwitch().regular,
        marginTop:"6px"
    },
    divider:{
        margin:"14px 0px 14px",
        height:"6px"
    },
    contentText:{
        color:theme.typography.color.primary,
        fontSize: "0.875rem",
    }
  }));