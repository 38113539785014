import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      textTransform: "capitalize",
      cursor: "pointer",
      borderRadius: theme.palette.borderRadius,
      boxShadow: "0px 3px 30px #5C86CB2E",
    },
    roots: {
      padding: "14px 16px",
      position: "relative",
    },
    name: {
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().bold,
      marginTop: "2px",
      marginBottom: "6px",
      color: theme.typography.color.primary,
    },
    call: {
      fontSize: "0.75rem",
      color: theme.typography.color.secondary,
    },
    mail: {
      fontSize: "0.75rem",
      color: theme.typography.color.secondary,
    },
    tagStyle: {
      backgroundColor: "#F1E6FE",
      borderRadius: "4px",
      padding: "4px 6px 4px 6px",
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().bold,
      color: "#5D427F",
      // textTransform: "uppercase",
      marginLeft: "8px",
      position: "absolute",
    },
    tagStyle1: {
      backgroundColor: "#FF4B4B29",
      borderRadius: "4px",
      padding: "4px 6px 4px 6px",
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().bold,
      color: "#FF4B4B",
      textTransform: "uppercase",
      marginLeft: "8px",
      position: "absolute",
      right: "10px",
      marginBottom: "16px",
    },
    tagStyle2: {
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().bold,
      color: theme.typography.color.primary,
    },
  
    avatar: {
      width: "48px",
      height: "48px",
      borderRadius: "4px",
      objectFit: "cover",
      marginInlineEnd: "10px"
    },
    perHr:{
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().bold,
      color: '#4E5A6B',
    }
  }));

