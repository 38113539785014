import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import { useStyles } from "./styles";
import { WorkerContext } from "../../context";

const AddWorkersCard = ({ worker, index }) => {
  console.log("🚀 ~ file: addWorkersCard.js:7 ~ AddWorkersCard ~ worker:", worker)
  const { editSelectedWorker, data } = React.useContext(WorkerContext);
  const classes = useStyles();
  return (
    <Box className={classes.mainPadding}>
      {worker?.workerType?.value === "Registered Worker" ?
        <Stack direction={"row"} className={classes.infoCard}>
          <img
            className={classes.profileImg}
            src={worker?.reg_worker_profiles?.[0]?.helper_image ? worker?.reg_worker_profiles?.[0]?.helper_image : "/images/profileBlue.svg"}
            alt=""
          />
          <Box flexGrow={1}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography className={classes.title} noWrap>
                {worker?.reg_worker_profiles?.[0]?.name}
              </Typography>
              {data?.select_workers?.filter(({ view_type }) => view_type === "edit").length === 0 &&
                <img onClick={() => editSelectedWorker(index)} style={{ cursor: "pointer" }} src="/images/Outline.svg" alt="" />
              }
            </Stack>
            <Typography className={classes.subTitle} noWrap>
              {worker?.workerType?.label}
            </Typography>
            {/* <Typography className={classes.subTitle} noWrap>
            9988445511
          </Typography> */}
            <Typography className={classes.subTitle} noWrap>
              {worker?.reg_worker_profiles?.[0]?.identification_type}
            </Typography>
            <Typography className={classes.subTitle} noWrap>
              {worker?.reg_worker_profiles?.[0]?.identification_no}
            </Typography>
          </Box>
        </Stack>
        : <Stack direction={"row"} className={classes.infoCard}>
          <img
            className={classes.profileImg}
            src={worker?.workerProfile ? worker?.workerProfile : "/images/profileBlue.svg"}
            alt=""
          />
          <Box flexGrow={1}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography className={classes.title} noWrap>
                {worker?.workerName}
              </Typography>
              {data?.select_workers?.filter(({ view_type }) => view_type === "edit").length === 0 &&
                <img onClick={() => editSelectedWorker(index)} style={{ cursor: "pointer" }} src="/images/Outline.svg" alt="" />
              }
            </Stack>
            <Typography className={classes.subTitle} noWrap>
              {worker?.workerType?.label}
            </Typography>
            {/* <Typography className={classes.subTitle} noWrap>
              9988445511
            </Typography> */}
            <Typography className={classes.subTitle} noWrap>
              {worker?.workerId?.label}
            </Typography>
            <Typography className={classes.subTitle} noWrap>
              {worker?.idNumber}
            </Typography>
          </Box>
        </Stack>}
    </Box>
  );
};

export default AddWorkersCard;
