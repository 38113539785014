import * as React from "react"

const ThumbsUpIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26.619} height={26} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.162}
        y1={-0.004}
        x2={0.831}
        y2={0.932}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f15a29" />
        <stop offset={1} stopColor="#ffa385" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.153}
        y1={0.037}
        x2={0.665}
        y2={1.022}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
    </defs>
    <path
      data-name="Path 99715"
      d="M9.364 14H4.273A1.272 1.272 0 0 0 3 15.273v15.273a1.272 1.272 0 0 0 1.273 1.273h5.091Z"
      transform="translate(-3 -5.819)"
      fill="url(#a)"
    />
    <path
      data-name="Path 99716"
      d="M30.8 8.667h-9.207l.614-2.476a4.651 4.651 0 0 0 1.841-3.714A2.466 2.466 0 0 0 21.593 0L13 8.667V26h15.169a2.46 2.46 0 0 0 2.4-1.957L33.2 11.662a2.471 2.471 0 0 0-2.4-2.995Z"
      transform="translate(-6.636)"
      fill="url(#b)"
    />
  </svg>
)

export default ThumbsUpIcon
