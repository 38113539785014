import { Box, Stack, Divider, Typography, Button } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { config } from '../../config'
import { AlertContext } from '../../contexts'
import { NetworkCall } from '../../networkcall'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { BillSummary } from './components'
import { BookingPreviewStyle } from './style'
import PreviewCard from './components/previewCard'
import { Routes } from '../../router/routes'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const BookingPreview = (
    {
        navigate = () => false,
        slots = [],
        selectedDate = {},
        stateData = {},
        t = () => false,
        data = {},
        onCLoseDrw = () => false,
        unitData = {},
        pcCount = "",
    }) => {
    const classes = BookingPreviewStyle(stateData?.data)
    const companyId = JSON.parse(localStorage.getItem("accessType"));
    const alert = React.useContext(AlertContext);
    const taxPercentage = data?.response?.amenity?.[0]?.vat_group_master?.vat_item?.map(v => v?.rate)?.reduce((a, b) => a + b, 0)
    const history = useHistory()
    const [hidePaynow, setHidePaynow] = React.useState(false)
    const [amountDetail, setAmountDetail] = React.useState({
        totalAmount: 0,
        taxAmount: 0,
        invoiceAmount: 0
    })

    const bookingSlots = slots?.map((slot) => {
        return [
            slot?.value?.check_in_value,
            slot?.value?.check_out_value
        ]
    })

    const peakHourSlot = data?.peakHourSlot?.map((slot) => {
        return [
            slot?.value?.check_in_value,
            slot?.value?.check_out_value
        ]
    })

    const nonPeakHourSlot = data?.nonPeakHourSlot?.map((slot) => {
        return [
            slot?.value?.check_in_value,
            slot?.value?.check_out_value
        ]
    })

    // Paynow function (book amenities)
    const bookAmenities = () => {
        setHidePaynow(true)
        const sortedSlot = slots?.sort((a, b) => a.index - b.index);
        const payload = {
            property_id: stateData?.propertyId,
            property_facility_id: stateData?.data?.facility_id,
            period: stateData?.data?.period,
            start_date: stateData?.data?.period === "Hourly" ? moment(selectedDate).format("YYYY-MM-DD ") + sortedSlot?.[0]?.value?.check_in_value : moment(sortedSlot?.[0]?.value?.check_in_date?.date).format("YYYY-MM-DD") + " 00:00:00",
            end_date: stateData?.data?.period === "Hourly" ? moment(selectedDate).format("YYYY-MM-DD ") + sortedSlot?.[sortedSlot?.length - 1]?.value?.check_out_value : moment(sortedSlot?.[sortedSlot?.length - 1]?.value?.check_out_date?.date).format("YYYY-MM-DD") + " 23:59:59",
            // start_date: moment(selectedDate).format("YYYY-MM-DD") + " 00:00:00",
            // end_date: moment(selectedDate).format("YYYY-MM-DD") + " 23:59:59",
            booked_slots: [],
            // stateData?.data?.period === "Hourly" ? bookingSlots : [], // old booked slot
            rate: stateData?.data?.amount,
            total_rate: amountDetail?.totalAmount,
            no_of_slots: bookingSlots.length ?? null,
            total_days: stateData?.data?.period === "Hourly" ? null : bookingSlots.length,
            agreement_unit_id: stateData?.unitData?.agreement_unit_id,
            currency: stateData?.data?.symbol,
            paid_on: new Date(),
            paid_amount: amountDetail?.totalAmount,
            company_id: companyId[0]?.company_id,
            overbooking_count: data?.response?.amenity?.[0]?.is_overbooking ? pcCount?.length === 0 ? undefined : Number(pcCount) : 1,
            booked_slots_info: [
                {
                    rate: data?.peakHourAmount,
                    slots: stateData?.data?.period === "Hourly" ? peakHourSlot : [],
                    is_peak: true
                },
                {
                    rate: data?.nonPeakHourAmount,
                    slots: stateData?.data?.period === "Hourly" ? nonPeakHourSlot : [],
                    is_peak: false
                }
            ]
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/book_amenity`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === 'success') {
                    setHidePaynow(false)
                    // navigate()
                    history.push({
                        pathname: Routes.myBookingDetails,
                        state: {
                            property_facility_id: response?.data?.data?.property_facility_id,
                            booking_id: response?.data?.data?.booking_id,
                            from: "booking"
                        }
                    })
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
                setHidePaynow(false)
            });
    };

    const getTotalAmount = () => {
        let total = 0
        // eslint-disable-next-line
        if (data?.response?.amenity?.[0]?.period === "Hourly") {
            // eslint-disable-next-line
            slots?.map((e) => {
                total = total + Number(e?.value?.slot_detail?.rate)
            })
        } else {
            total = data?.response?.amenity?.[0]?.rate * slots?.length
        }

        let taxAmt = data?.response?.amenity?.[0]?.vat_group_master?.vat_item?.map(v => v?.rate)?.reduce((a, b) => a + b, 0) ?? 0

        if (data?.response?.amenity?.[0]?.is_overbooking) {
            setAmountDetail({
                ...amountDetail,
                totalAmount: (Number((total * (taxAmt / 100)) + total) * Number(pcCount)).toFixed(2),
                taxAmount: (Number((total * Number(pcCount)) * (taxAmt / 100))).toFixed(2),
                invoiceAmount: (Number(total) * Number(pcCount)).toFixed(2)
            })
        } else {
            setAmountDetail({
                ...amountDetail,
                totalAmount: ((total * (taxAmt / 100)) + total).toFixed(2),
                taxAmount: (total * (taxAmt / 100)).toFixed(2),
                invoiceAmount: total.toFixed(2)
            })
        }
    }

    React.useEffect(() => {
        getTotalAmount()
        // eslint-disable-next-line
    }, [])


    return (
        // <Container maxWidth={"sm"}>
        <>
            <Box className={classes.previewBox}>
                <Box className={classes.firstBox}>
                    <Stack className={classes.box1} spacing={1}>
                        <PreviewCard data={stateData?.data} amenityData={data} />
                    </Stack>
                    <Divider style={{ color: "#F2F4F7 !important" }} />
                    <Stack className={classes.box} spacing={1} >
                        {/* <PreviewCard data={stateData?.data} amenityData={data} />
                        <Divider /> */}
                        {/* <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.title}>{t("CourtID")}</Typography>
                            <Typography className={classes.subtitle}>{data?.response?.amenity?.[0]?.facility_no}</Typography>
                        </Stack>  */}
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.title}>{t("BookingDate")}</Typography>
                            <Typography className={classes.subtitle}> {moment(selectedDate).format("DD MMM YYYY")} , {moment(selectedDate).format("MMMM")}</Typography>
                        </Stack>

                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.title}>{t("BookingSlot")}</Typography>
                            <Stack>
                                {
                                    slots?.map((slot, index, length) => {
                                        return (
                                            <Typography className={classes.subtitle}>
                                                {stateData?.data?.period === "Daily" ? moment(slot?.value.check_out_date?.date).format("Do MMM YY") : moment(slot?.value.check_in_time, "HH:mm").format("hh:mm A")} - {stateData?.data?.period === "Daily" ? moment(slot?.value.check_in_date?.date).format("Do MMM YY") : moment(slot?.value?.check_out_time, "HH:mm").format("hh:mm A")}
                                                {length.length - 1 !== index && <span>,</span>}
                                            </Typography>
                                        )
                                    })
                                }
                            </Stack>
                        </Stack>
                        <Box className={classes.dot_divider}></Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.title}>{t("BookingUnit")}</Typography>
                            <Typography className={classes.subtitle}>
                                {
                                    stateData?.unitData?.unit_name ?? "-"
                                }
                            </Typography>
                        </Stack>

                    </Stack>

                </Box>
                <Box>
                    {stateData?.data?.is_chargeable && <Box my={2} >
                        <Divider className={classes.bigDivider} />
                    </Box>}
                </Box>
                <Box padding={"0px 16px 0px"}>
                    {stateData?.data?.is_chargeable && <Box mt={1}>
                        <BillSummary
                            data={stateData?.data}
                            value={data}
                            bookingSlots={bookingSlots}
                            amountDetail={amountDetail}
                            slots={slots}
                            pcCount={pcCount}
                            taxPercentage={taxPercentage} />
                    </Box>}
                </Box>

            </Box>
            <Stack direction={"row"} spacing={2} mt={2} mb={2} padding={"0px 16px 0px"}>
                <Button fullWidth variant='contained'
                    onClick={() => {
                        bookAmenities()
                    }}
                    className={classes.paybtn}
                    disabled={hidePaynow}>
                    {t("Book Now")}
                    {stateData?.data?.is_chargeable && `( ${stateData?.data?.symbol ?? stateData?.data?.currency_symbol} ${amountDetail?.totalAmount} )`}

                </Button>
            </Stack>
        </>
        // </Container>
    )
}

export default withNamespaces("amenitiesBooking")(BookingPreview)