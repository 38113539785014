import { Box, Button, Grid } from "@mui/material";
import React from 'react';
import { DatePickerNew, TextBox } from "../../../components";


export const Body = (props) => {
    const {
        components = [],
    } = props;

    const switchComponents = (val) => {
        if (val?.isActive) {
            switch (val.component) {
                case "text":
                    return (
                        <TextBox
                            isRequired={val?.isRequired}
                            label={val?.label}
                            placeholder={val?.placeholder}
                            value={val.value}
                            onChange={val?.onChange}
                            isError={val?.error?.length > 0}
                            errorMessage={val?.error}
                            multiline={val?.multiline}
                        />
                    )
                case "date":
                    return (
                        <DatePickerNew
                            isRequired={val?.isRequired}
                            label={val.label}
                            placeholder={val.placeholder}
                            value={val.value}
                            //minDate={new Date()}
                            onChange={val?.onChange}
                            isError={val?.error?.length > 0}
                            errorMessage={val?.error}
                        />
                    )
                case "button":
                    return (
                        <Button sx={{ padding: "12px", boxShadow: "none", marginTop: "4px" }} onClick={val?.onClick} variant="contained" fullWidth>
                            {val?.label}
                        </Button>
                    )
                default:
                    return null;
            }
        }
    }

    return (
        <Box>
            {
                <Grid container>
                    {
                        components?.length && components.map(_ => (
                            <>
                                <Grid item xs={12}>
                                    {switchComponents(_)}
                                    {
                                        _?.isActive &&
                                        <Box height="8px" />
                                    }

                                </Grid>
                            </>
                        ))
                    }
                </Grid>
            }
        </Box>
    )
}