import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },
  selects: {
    color: "#091B29",
    "& .css-1rhbuit-multiValue": {
      backgroundColor: "#DBEDFE",
    },
  },
  label: {
    fontSize: "0.75rem",
    paddingBottom: "8px",
    fontFamily: FontFamilySwitch().bold,
  },


}));

const customStyles = {
  control: (base) => ({
    ...base,
    height: 45,
    fontSize: "0.875rem",
    minHeight: 45,
    fontFamily: FontFamilySwitch().semiBold,
    border: "1px solid #E4E8EE",
    boxShadow: "none",
    borderRadius: "6px",
    color: "#091B29",
    "&:hover": {
      border: "1px solid #E2E2E2",
    },
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      fontFamily: FontFamilySwitch().regular,
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "100%",
      overflow: "hidden",
      color: "#4E5A6B"

    };
  },
  menu: defaultStyles => {
    return {
      ...defaultStyles,
      zIndex: 999,
      borderRadius: "4px",
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().semiBold,
    };
  },
};

const customStyles2 = {
  control: (base) => ({
    ...base,
    height: 45,
    fontSize: "0.875rem",
    minHeight: 45,
    border: "none",
    boxShadow: "none",
    fontFamily: FontFamilySwitch().semiBold,
    borderRadius: "6px",
    color: "#091B29",
    padding: "0",
    "&:hover": {
      border: "none",
    },
    "& fieldset": {
      borderBottom: "none"
    },
  }),
};

export const SelectBox = ({
  label = "",
  labelColor,
  isRequired = false,
  isPaginate = false,
  createSelect = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isError = false,
  errorMessage = "",
  isMulti = false,
  isDisabled = false,
  debounceTimeout = 0,
  key,
  parentID = "",
  menuPlacement = "auto",
  customOptionComponent = false,
  borderNone = false,
  menuOptionHeight = null
}) => {
  const classes = useStyles();

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props} >
        {customOptionComponent({ ...props })}
      </components.Option>
    );
  };

  return (
    <div className={classes.root}>
      {label && (
        <div style={{ display: "flex" }}>
          {
            <Typography
              variant="body1"
              color="textsecondary"
              className={classes.label}
              sx={{ color: labelColor ? labelColor : "#4E5A6B" }}
              align="left"
            // gutterBottom
            >
              {label}
            </Typography>
          }
          {isRequired && (
            <Typography color="error" variant="caption">
              &nbsp;*
            </Typography>
          )}
        </div>
      )}
      {isPaginate ? (
        <AsyncPaginate
          key={key}
          isClearable
          isSearchable
          components={
            customOptionComponent ?
              {
                IndicatorSeparator: () => null,
                Option,
              } :
              {
                IndicatorSeparator: () => null,
              }
          }
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={customStyles}
          debounceTimeout={debounceTimeout}
          isDisabled={isDisabled}
          isMulti={isMulti}
          // menuPosition={"fixed"}
          menuPlacement={menuPlacement}
          maxMenuHeight={menuOptionHeight ? menuOptionHeight : '150px'}
          menuPortalTarget={document.getElementById(parentID)}
        />
      ) : createSelect ? (
        <CreatableSelect
          isClearable={false}
          options={options}
          isLoading={loading}
          placeholder={placeholder}
          components={
            customOptionComponent ?
              {
                IndicatorSeparator: () => null,
                Option,
              } :
              {
                IndicatorSeparator: () => null,
              }
          }
          value={value}
          onChange={onChange}
          styles={customStyles}
          isDisabled={isDisabled}
          menuPosition={"fixed"}
          menuPlacement={"auto"}
        />
      ) : (
        <Select
          isClearable={false}
          isSearchable
          components={
            customOptionComponent ?
              {
                IndicatorSeparator: () => null,
                Option,
              } :
              {
                IndicatorSeparator: () => null,
              }
          }
          value={value}
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={borderNone ? customStyles2 : customStyles}
          className={classes.selects}
          isMulti={isMulti}
          isDisabled={isDisabled}
          menuPosition={"fixed"}
          menuPlacement={"auto"}
        />
      )}
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
