import * as React from "react"
const Statement = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.165}
        x2={0.88}
        y1={0.04}
        y2={1.022}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#7dd8f3" />
        <stop offset={1} stopColor="#45b0d0" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={0.057}
        y2={1.002}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0176d0" />
        <stop offset={1} stopColor="#16538c" />
      </linearGradient>
    </defs>
    <g data-name="Group 108940">
      <g data-name="icons8-purchase-order (2)">
        <path
          fill="url(#a)"
          d="M24.968 27.815H9.212A1.212 1.212 0 0 1 8 26.6V7.212A1.212 1.212 0 0 1 9.212 6h15.756a1.212 1.212 0 0 1 1.212 1.212V26.6a1.212 1.212 0 0 1-1.212 1.215Z"
          data-name="Path 101043"
          transform="translate(-3.876 -4.907)"
        />
        <path
          fill="url(#b)"
          d="M22.18 38.818V37H4v1.818a1.212 1.212 0 0 0 1.212 1.212h18.18a1.212 1.212 0 0 1-1.212-1.212Z"
          data-name="Path 101044"
          transform="translate(-2.3 -17.121)"
        />
        <path
          fill="#057093"
          d="M18.971 8.971H11.09a.3.3 0 0 1-.3-.3V7.453a.3.3 0 0 1 .3-.3h7.878a.3.3 0 0 1 .3.3v1.212a.3.3 0 0 1-.297.306Z"
          data-name="Path 101045"
        />
        <path
          fill="#057093"
          d="M18.971 12.607H11.09a.3.3 0 0 1-.3-.3v-1.218a.3.3 0 0 1 .3-.3h7.878a.3.3 0 0 1 .3.3v1.212a.3.3 0 0 1-.297.306Z"
          data-name="Path 101046"
        />
        <path
          fill="#057093"
          d="M18.971 16.243H11.09a.3.3 0 0 1-.3-.3v-1.218a.3.3 0 0 1 .3-.3h7.878a.3.3 0 0 1 .3.3v1.212a.3.3 0 0 1-.297.306Z"
          data-name="Path 101047"
        />
        <path
          fill="#057093"
          d="M8.669 8.971H7.454a.3.3 0 0 1-.3-.3V7.453a.3.3 0 0 1 .3-.3h1.212a.3.3 0 0 1 .3.3v1.212a.3.3 0 0 1-.297.306Z"
          data-name="Path 101048"
        />
        <path
          fill="#057093"
          d="M8.669 12.607H7.454a.3.3 0 0 1-.3-.3v-1.218a.3.3 0 0 1 .3-.3h1.212a.3.3 0 0 1 .3.3v1.212a.3.3 0 0 1-.297.306Z"
          data-name="Path 101049"
        />
        <path
          fill="#057093"
          d="M8.669 16.243H7.454a.3.3 0 0 1-.3-.3v-1.218a.3.3 0 0 1 .3-.3h1.212a.3.3 0 0 1 .3.3v1.212a.3.3 0 0 1-.297.306Z"
          data-name="Path 101050"
        />
      </g>
    </g>
  </svg>
)
export default Statement
