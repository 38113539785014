export const ListIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_109213" data-name="Group 109213" transform="translate(-26 -116)">
    <g id="Rectangle_56045" data-name="Rectangle 56045" transform="translate(26 116)" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
      <rect width="20" height="20" stroke="none"/>
      <rect x="0.5" y="0.5" width="19" height="19" fill="none"/>
    </g>
    <path id="icons8-regular-document" d="M19,4H9.8A1.8,1.8,0,0,0,8,5.8V18.2A1.8,1.8,0,0,0,9.8,20H19a1.8,1.8,0,0,0,1.8-1.8V5.8A1.8,1.8,0,0,0,19,4ZM11.4,7.2h2.8a.6.6,0,0,1,0,1.2H11.4a.6.6,0,0,1,0-1.2Zm4.4,9.6H11.4a.6.6,0,0,1,0-1.2h4.4a.6.6,0,1,1,0,1.2Zm1.6-2.4h-6a.6.6,0,1,1,0-1.2h6a.6.6,0,0,1,0,1.2Zm0-2.4h-6a.6.6,0,1,1,0-1.2h6a.6.6,0,0,1,0,1.2Z" transform="translate(22 114)" fill="#ced3dd"/>
  </g>
</svg>
);
