import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { TextBox } from "../../../components";
import { WorkerContext } from "../context";
import { useStyles } from "./styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ButtonGroupSelect from "../../../components/ButtonGroupSelect";
import WorkerInformationCard from "./cards/workerInformationCard";

const WorkerInformation = () => {
  const { t = () => false, data, setData, updateState, workerInfoFormSubmit,gates, setOpenConfirmationDrawer } = React.useContext(WorkerContext);
  const classes = useStyles(); 
  const updateObject = (key, value) => {
    updateState("worker_info", key, value);
  };
  const cancelForm = () => {
    if (data?.worker_info?.is_updated && typeof data?.worker_info?.previous_state === "string") {
      setOpenConfirmationDrawer(true);
    }

    if (typeof data?.worker_info?.previous_state === "object") {
      setData({ ...data, worker_info: { ...data?.worker_info?.previous_state } });
    }
  }
 
  return (
    data?.worker_info?.view_type !== "hide" &&
    <>
      {data?.worker_info?.view_type === "edit" &&
        <Box className={classes.root}>
          <Box className={classes.mainPadding}>
            <Box className={classes.input}>
              <TextBox
                isRequired
                color
                labelColor={"#4E5A6B"}
                label={t("Work Description")}
                placeholder={t("Enter Work Description")}
                value={data?.worker_info?.workDescription}
                onChange={(value) =>
                  updateState("worker_info", "workDescription", value.target.value)
                }
                isError={data?.worker_info?.error?.workDescription?.length > 0}
                errorMessage={data?.worker_info?.error?.workDescription}
                multiline
                rows={4}
              />
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.worker_info?.tenantImprovement}
                      onChange={() =>
                        updateState("worker_info", "tenantImprovement", !data?.worker_info?.tenantImprovement)
                      }
                      name="tenantImprovement"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Is this a Tenant Improvement (TI)?
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.worker_info?.facilittiesStaff}
                      onChange={() =>
                        updateState("worker_info", "facilittiesStaff", !data?.worker_info?.facilittiesStaff)
                      }
                      name="facilittiesStaff"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Is a Facilities Staff member needed?
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.worker_info?.individualAccess}
                      onChange={() =>
                        updateState("worker_info", "individualAccess", !data?.worker_info?.individualAccess)
                      }
                      name="individualAccess"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Is Individual Access Pass required?
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            <Box className={classes.input}>
              <ButtonGroupSelect
                labelColor={"#4E5A6B"}
                isRequired
                label={t("What Access Gates do you need to access to")}
                data={gates}
                state={data?.worker_info}
                stateName={"gateAccess"}
                updateState={updateObject}
              />
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.worker_info?.noisy}
                      onChange={() => updateState("worker_info", "noisy", !data?.worker_info?.noisy)}
                      name="noisy"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Will the work be Noisy?
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.worker_info?.hazardous}
                      onChange={() => updateState("worker_info", "hazardous", !data?.worker_info?.hazardous)}
                      name="hazardous"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Hazardous Material/Chemical Use/Fumes/Paint
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.worker_info?.flames}
                      onChange={() => updateState("worker_info", "flames", !data?.worker_info?.flames)}
                      name="flames"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Flames
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.worker_info?.vendorSafty}
                      onChange={() =>
                        updateState("worker_info", "vendorSafty", !data?.worker_info?.vendorSafty)
                      }
                      name="vendorSafty"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Vendor Safty Certificate on File
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          </Box>
          <Box className={classes.borderBottom} />
          <Grid
            container
            className={classes.btnroot}
            justifyContent="space-between"
            alignItems={"center"}
            spacing={0}
          >
            <Grid item xs={5}>
              <Button
                fullWidth
                disabled={!data?.worker_info?.is_updated}
                className={classes.secondary}
                variant="contained"
                onClick={() => cancelForm()}
              >
                {t("Cancel")}
              </Button>
            </Grid>
            <Divider
              orientation="vertical"
              sx={{ borderLeft: "1px solid #98A0AC", padding: "14px 0" }}
            />
            <Grid item xs={5}>
              <Button
                fullWidth
                className={classes.primary}
                variant="contained"
                onClick={() => workerInfoFormSubmit()}
              >
                {t("Next")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
      {data?.worker_info?.view_type === "view" &&
        <WorkerInformationCard />
      }
    </>
  );
};

export default WorkerInformation;
