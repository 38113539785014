import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const amenitiesUnitCardStyles = makeStyles((theme) => ({
    unitCard: {
        border: "1px solid #E4E8EE",
        borderRadius: "10px",
        padding: "14px 16px",
        marginBottom: "12px",
        cursor: "pointer",
    },
    unitAvatar: {
        borderRadius: "4px",
        width: "64px",
        height: "64px",
    },
    unitId: {
        color: theme.typography.color.secondary,
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        backgroundColor: theme.palette.border.secondary,
        padding: "3px 6px",
        borderRadius: "6px"
    },
    unitLocation: {
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
        fontSize: "1rem",
    },
    unitTitle: {
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        fontSize: "1.125rem",
    },
    amenityDetailsBox: {
        backgroundColor: theme.palette.background.secondary,
        borderRadius: "10px",
        marginBottom: "16px"
    },
    amenityDetailsBoxTop: {
        borderBottom: "2px solid #E4E8EE",
        padding: "16px",
    },
    amenityDetailsBoxBottom: {
        padding: "16px",
    },
    bookingHeads: {
        color: theme.typography.color.secondary,
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().regular,
        marginBottom: "8px"

    },
    bookingDetails: {
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginBottom: "8px"

    },
    paymentHead: {
        color: theme.typography.color.primary,
        fontSize: "1.25rem",
        fontFamily: FontFamilySwitch().bold,
        marginBottom: "8px",

    },
    paymentOpt: {
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().regular,
        marginBottom: "12px",
    },
    paymentOptVal: {
        color: theme.typography.color.primary,
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().semiBold,
        marginBottom: "12px",
    },
    stackSect: {
        borderBottom: "3px solid #F2F4F7",
        padding: "16px",


    }
}));

export const monthlyInvoiceCardStyles = makeStyles((theme) => ({
    invoice_card: {
        backgroundColor: "white",
        padding: "16px",
        borderRadius: theme.palette.borderRadius2,
        boxShadow: "0px 3px 30px #4354721F",
    },
    month: {
        color: theme.typography.color.secondary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold
    },
    due: {
        color: "#5D427F",
        backgroundColor: "#F1E6FE",
        borderRadius: "4px",
        fontSize: "0.75rem",
        padding: "3px 6px"
    },
    bill_div: {
        padding: "16px",
        backgroundColor: "transparent",
        borderRadius: theme.palette.borderRadius2
    },
    label: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold
    },
    text_large: {
        color: theme.typography.color.primary,
        fontSize: "1.25rem",
        fontFamily: FontFamilySwitch().extraBold
    },
    text_small: {
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().extraBold
    },
    invoice: {
        color: "#5078E1",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer"
    },
    over_due: {
        color: "#FF4B4B",
        backgroundColor: "#FF4B4B29",
        padding: "3px 6px",
        fontSize: "0.75rem",
        borderRadius: "4px",
    },
    button: {
        backgroundColor: "#5078E1",
        padding: "15px 100px",
        fontSize: "0.875rem",
        marginTop: "40px",
        borderRadius: "8px"
    },
    header_div: {
        backgroundColor: "transparent",
        padding: "16px 16px 0px 16px",
    },
    viewinvoice: {
        fontSize: "0.875rem",
        height: 20,
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main,
        textAlign: "center",
        background: "none",
        textTransform: "capitalize",
        '&:hover': {
            background: "none",
        }
    },
}));

export const memberCountCardStyles = makeStyles((theme) => ({
    header_div: {
        padding: "0px 7px"
    },
    memberCount: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    },
    numbersAllowed: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular
    },
    button: {
        padding: "15px 76px",
        backgroundColor: "#5078E1",
        textAlign: "center",
        fontSize: "0.875rem",
        color: "white",
        borderRadius: "8px",
        marginTop: "30px"
    }
}));

export const CourtSelectionCardStyles = makeStyles((theme, props) => ({
    header_div: {
        padding: "0px 7px"
    },
    court: {
        backgroundColor: "#F5F7FA",
        borderRadius: "8px",
        padding: "14px 16px"
    },
    time: {
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold
    },
    date: {
        fontFamily: FontFamilySwitch().regular,
        fontSize: "0.75rem",
        color: theme.typography.color.secondary
    },
    slots: {
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
    },
    div_availability: {
        border: "1px solid #E4E8EE",
        borderRadius: "8px",
        padding: "10px 12px",
        marginBottom: "8px",
        cursor: "pointer"
    },
    court_number: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    availability: {
        fontSize: "0.75rem",
    }
}));


export const DetailCardStyle = makeStyles((theme) => ({
    root: {
        background: "#fff",
        borderRadius: 12,
    },
    avatar: {
        height: 64,
        width: 64,
        borderRadius: '8px',
        marginInlineEnd: "16px"
    },
    amenityText: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        '@media (max-width: 426px)': {
            width: "270px !important",
        },
        '@media (max-width: 376px)': {
            width: "220px !important",
        },
        '@media (max-width: 321px)': {
            width: "170px !important",
        },
    },
    amenityCategory: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold
    },
    viewMoreText: {
        fontSize: "0.75rem",
        color: theme.palette.primary.main,
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer"
    },
    label: {
        fontSize: "0.875rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold
    },
    amount: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    slotText: {
        fontSize: "0.875rem",
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold
    },
    viewBookingBox: {
        padding: 8,
        borderRadius: 4,
        background: theme.palette.info.light
    },
    viewBookingLabel: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.palette.primary.main
    },
    desc: {
        fontSize: "0.75rem",
        wordBreak: "break-all",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    seeMoreLessTextStyle: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    cardImage1: {
        height: "157px",
        borderRadius: "16px !important",
        width: "100%",
        border: "1px solid #fff"
    },
    cardImage: {
        height: "157px",
        borderRadius: "16px",
        width: "100%"
    },
    noImageBox:{
        position:"relative",
        height:"157px",
        borderRadius:"8px",
        background:theme.palette.background.secondary
    },
    noImageIcon:{
        position:"absolute",
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)"
    }
}))