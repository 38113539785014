import { Box } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { NewAnnouncementCard } from "./components";


export const CarouselSliders = (props) => {
    var settings = {
        dots: true,
        infinite: false,
        // slidesToShow: 3,
        // slidesToScroll: 1,
        // autoplay: autoScroll,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false
    };
    const { list, t = () => false } = props;

    // const onClickSlider = ({ id, is_read }) => {

    //     if (!is_read) {
    //         const variables = {
    //             query: UNREADNOTIFICATION,
    //             variables: {
    //                 id: id,
    //                 updatedData: {
    //                     is_read: true,
    //                 },
    //             },
    //         };

    //         NetworkCall(
    //             `${config.graphql_url}`,
    //             NetWorkCallMethods.post,
    //             variables,
    //             null,
    //             true,
    //             false
    //         )
    //             .catch((error) => {
    //                 alert.setSnack({
    //                     ...alert,
    //                     open: true,
    //                     severity: AlertProps.severity.error,
    //                     msg: "Some Thing Went Wrong.",
    //                 });
    //             });
    //     }

    //     history.push(Routes.announcementDetails + "?Id=" + id)
    // }

    return (
        <Box>
            <Slider {...settings}>
                {list?.map((_, index) =>
                (

                    <NewAnnouncementCard
                        t={t}
                        data={_}
                        isCommunity={props?.isCommunity}
                        isAll={props?.isAll}
                    // logo={_?.description?.length > 0 ? _?.description[0]?.images : '/images/announsmentnot.svg'}
                    // category={_?.category ? _?.category : "-"}
                    // title={_?.title ? _?.title : "-"}
                    // postedOn={_?.triggered_at ? `Posted at ${moment(new Date(_?.triggered_at)).fromNow()}` : "-"}
                    // onClickSlider={() => onClickSlider(_)}
                    />

                ))
                }
            </Slider>
        </Box>

    );
};
