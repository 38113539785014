import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, vehicleTypeBGSVG, vehicleTypeBGSVGArabic } from "../../../utils";

export const ParkingSlotCardStyle = makeStyles((theme)=>({
    parkingSlotNoStyle: {
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        height: "110px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        backgroundColor:"#F5F7FD",
        backgroundImage: (props) =>  `url("${props?.language === "ar" ? vehicleTypeBGSVGArabic[props?.vehicle_type] : vehicleTypeBGSVG[props?.vehicle_type] ?? `/images/Component 149 – 1.svg`}")`,
    },
    label: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
    value: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
    },
    value1: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().extraBold,
        color: "#091B29",
        width:"55%"
    }
}))

