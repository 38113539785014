import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useStyles } from "./style";
import * as React from "react";

export const NewTabsComponent = ({
  selectedTab = 0,
  tab1Label = "Home",
  tab2Label = "Invoice",
  tab3Label = "Payment",
  onTab1Clicked = () => false,
  onTab2Clicked = () => false,
  onTab3Clicked = () => false,
  tabIcon1 = "",
  tabIcon2 = "",
  tabIcon3 = ""
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Tab Section */}
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            indicatorColor="none"
            textColor="secondary"
            className={classes.tabroot}
            variant="scrollable"
            visibleScrollbar={false}
            scrollButtons={false}
          >
            {/* <Tab
              className={
                selectedTab === 0 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 0 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 0 && (
                    tabIcon1
                  )}
                  &nbsp;&nbsp;{tab1Label}
                </span>
              }
              onClick={onTab1Clicked}
            /> */}
            <Tab
              className={
                selectedTab === 1 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 1 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 1 && (
                    tabIcon2
                  )}
                  &nbsp;&nbsp;{tab2Label}
                </span>
              }
              onClick={onTab2Clicked}
            />
            <Tab
              className={
                selectedTab === 2 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 2 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 2 && (
                    tabIcon3
                  )}
                  &nbsp;&nbsp;{tab3Label}
                </span>
              }
              onClick={onTab3Clicked}
            />
          </Tabs>
        </Grid>
      </Grid>
    </div>
  );
};
