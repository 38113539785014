import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DialogDrawer, TitleBar } from "../../components";
import ContactSales from "./components/contactSales";
import { withNamespaces } from "react-i18next";

const ShortlistedUnits = (props) => {
  const { t=()=>false } = props
  const classes = useStyles(props);
  const history = useHistory();
  const { state } = useLocation();

  const size = useWindowDimensions();
  let fullSize = size?.height - 140;
  const [contactSalesDrawer, setContactSalesDrawer] = React.useState(false);
  const openContactSalesDrawer = () => {
    setContactSalesDrawer(true);
  };
  const closeContactSalesDrawer = () => {
    setContactSalesDrawer(false);
  };
  const [property, setProperty] = React.useState([]);
  let shortlisted = state?.shortlisted;
  React.useEffect(() => {
    setProperty(shortlisted);
  }, [shortlisted]);
  const deleteProperty = (value) => {
    let array = property.slice();
    let index = array.findIndex((x) => x.id === value);
    array.splice(index, 1);
    setProperty(array);
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar text={"Shortlisted Units"} goBack={() => history.goBack(-1)} />
      <div style={{ height: fullSize, overflow: "overlay" }}>
        <Box className={classes.mainPadding3}>
          <Stack direction={"row"} alignItems="center" spacing={1}>
            <img
              className={classes.imgIcon2}
              src="/images/Location.svg"
              alt="logo"
            />
            <Typography className={classes.subTitle4} noWrap>
              Rubix Apartment
            </Typography>
          </Stack>
        </Box>
        {property?.map((data, index) => (
          <>
            <Box key={index} className={classes.mainContainer}>
              <Box className={classes.mainPadding3}>
                {/* <Box className={classes.alignImgText2}> */}
                <Grid container spacing={1}>
                  <Grid item xs={3} sm={2}>
                    <Box>
                      <img
                        className={classes.cardImg}
                        src={"/images/" + data?.images[0]}
                        alt=""
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Box flexGrow={1}>
                      <Typography className={classes.subTitle4} noWrap>
                        {data?.propertyID}
                      </Typography>
                      <Box width={"5px"} />
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        <Typography className={classes.label3} noWrap>
                          {data?.apartment}
                        </Typography>
                        <Box className={classes.round} />
                        <Typography className={classes.label3} noWrap>
                          {"P Tower"}
                        </Typography>
                      </Stack>
                      <Box height={"5px"} />
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        <Typography className={classes.label3} noWrap>
                          {data?.floor}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          variant="middle"
                          sx={{ borderColor: "#E4E8EE" }}
                        />
                        <Typography className={classes.label3} noWrap>
                          {data?.apartmentSize}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          flexItem
                          variant="middle"
                          sx={{ borderColor: "#E4E8EE" }}
                        />
                        <Typography className={classes.label3} noWrap>
                          {data?.sqft}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={3} sm={4}>
                    <Box className={classes.leftContainer}>
                      <Typography className={classes.subTitle4} noWrap>
                        {data?.originalRate}
                      </Typography>
                      <Box height={"16px"} />

                      <img
                        onClick={() => deleteProperty(data?.id)}
                        className={classes.trashIcon}
                        src="/images/trash.svg"
                        alt=""
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Box width={"2px"} />

                {/* </Box> */}
              </Box>
              <Divider sx={{ borderColor: "#E4E8EE", marginBottom: "8px" }} />
              <Grid container>
                <Grid item xs={4} sx={{ borderRight: "1px solid #E4E8EE" }}>
                  <Box
                    className={classes.iconText}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src="/images/unFurnished.svg" alt="furnished" />
                    <Typography className={classes.iconName} noWrap>
                      {data?.furnishing}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sx={{ borderRight: "1px solid #E4E8EE" }}>
                  <Box
                    className={classes.iconText}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src="/images/bathsIcon.svg" alt="bath" />
                    <Typography className={classes.iconName}>
                      {data?.baths}
                      {t(" Baths")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    className={classes.iconText}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <img src="/images/bedIcon.svg" alt="bed" />
                    <Typography className={classes.iconName}>
                      {data?.bed}
                      {t(" Beds")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box height={"9px"} />
            </Box>
            <Box height={"4px"} />
          </>
        ))}
      </div>
      <Box className={classes.btnroot}>
        <Button
          fullWidth
          className={classes.primary}
          variant="contained"
          onClick={() => openContactSalesDrawer()}
        >
          {t("Contact Sales")}
        </Button>
      </Box>
      <DialogDrawer
        open={contactSalesDrawer}
        header={t("Contact Sales")}
        onClose={() => closeContactSalesDrawer()}
        onOpen={() => openContactSalesDrawer()}
        isnotTitle={false}
        component={<ContactSales t={t} />}
      />
    </Container>
  );
};

export default withNamespaces("filterPropertySearch")(ShortlistedUnits)
