import { Stack, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  invoiceOutStandingAmount: {
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
    // marginBottom: "10px",
  },
  invoiceOutStandingAmountText: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    // marginBottom: "7px",
  },
  invoiceOverDue: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.palette.warning.primary,
    backgroundColor: theme.palette.warning.secondary,
    display: "inline",
    padding: "2px 6px",
    borderRadius: theme.palette.borderRadius,
  },
  paynow: {
    padding: "4px 8px",
    color: "#ffffff",
    borderRadius: "12px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#091B29",
  },

}));

export const HomepagePayCard = (props) => {
  const {t=()=>false}=props
  const classes = useStyles(props);
  const language = localStorage.getItem("i18nextLng")
  return (    
    <Stack direction={"row"} spacing={"16px"} justifyContent={"space-between"} alignItems={"start"}>
      <Stack direction={"column"} spacing={"10px"}>
        <Typography className={classes.invoiceOutStandingAmount}>
          {props?.currency_symbol ? (
            <>
              {props?.currency_symbol +
                " " +
                Intl.NumberFormat("en-IN", {
                  minimumFractionDigits: 2,
                }).format(props?.Outstanding_amount)}
            </>
          ) : (
            props?.Outstanding_amount
          )}
        </Typography>
        <Stack direction={"column"} spacing={"4px"}>
          <Typography className={classes.invoiceOutStandingAmountText}>
            {props?.t("outstanding_amount")}
          </Typography>
          <Typography className={classes.invoiceOverDue}>
            {props?.currency_symbol ? (
              <>
                {props?.currency_symbol +
                  " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                  }).format(props?.amount)}
              </>
            ) : (
              0
            )}
            &nbsp;{t("is overdue amount")}
          </Typography>
        </Stack>
      </Stack>
      <Typography
        className={classes.paynow}
        onClick={props?.Outstanding_amount > 0 ? () => props?.onClick() : ""}>
        &nbsp; {props?.t("pay_now")} &nbsp;
        {language === "ar" ?
          <ArrowBackIosIcon style={{ fontSize: "0.75rem" }} /> :
          <ArrowForwardIosIcon style={{ fontSize: "0.75rem" }} />
        }
      </Typography>
    </Stack>
    // <div className={classes.root}>
    //   {/* <Box display="flex">
    //     <Grid container direction={"row"} alignItems={"start"}>
    //       <Grid item xs={6}>
    //         <Box flexGrow={1}>
    //         </Box>
    //       </Grid>
    //       <Grid item xs={6}>
    //       </Grid>
    //     </Grid>
    //   </Box> */}
    // </div>
  );
};
export default withNamespaces("invoice")(HomepagePayCard);
