import { PieChart, Pie, Tooltip } from "recharts";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { PieStyle } from "./style";
import React from "react";

export const Piechart = ({ data = "", width = 200, isTotal=false ,total=0, totalText="",isFull=false,center={}}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const mediaQuery = useMediaQuery('(min-width:602px)')
  const matches = isFull ? true : mediaQuery ; 

  // tool tip
  const Tip = ({ setShowTooltip, ...rest }) => {

    const [payload, setPayload] = React.useState(rest?.payload);

    React.useEffect(() => {
      rest?.payload?.length && setPayload(rest?.payload);
    }, [rest?.payload]);

    return payload?.length ? (
      <div
        onMouseLeave={() => setShowTooltip(false)}
        onMouseMove={(e) => e.stopPropagation()}
        sx={PieStyle().tooltip}
      >
        {/* <Box
          sx={PieStyle().dot}
          style={{ backgroundColor: payload?.[0]?.payload?.fill }}
        />
        <Typography sx={PieStyle().value}>{payload?.[0]?.value}</Typography>
        <Typography sx={PieStyle().name}>{payload?.[0]?.name}</Typography> */}
      </div>
    ) : null;
  };

  return (
    <Box style={{ position: "relative" }}>
        <PieChart 
             width={width}
             height={200}
        sx={{ borderRadius: "10px" }}
         onMouseLeave={() => setShowTooltip(false)}>
          <Pie
            cx={matches ? "51%" : 75}
            cy={matches ? "50%" : 75}
            innerRadius={matches ? 75 : 50}
            outerRadius={matches ? 100 : 65}
            data={data}
            cornerRadius={3}
            paddingAngle={4}
            dataKey="value"
            sx={PieStyle.root}
            isAnimationActive={false}
            onMouseEnter={() => setShowTooltip(true)}
          >
            {showTooltip && (
              <Tooltip
                // Anymation is a bit weird when the tooltip shows up after hidding
                isAnimationActive={false}
                // Send props down to get the payload
                content={<Tip setShowTooltip={setShowTooltip} />}
                // We need this to manage visibility ourselves
                wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
              />
            )}
          </Pie>
        </PieChart>
      {isTotal && (
        <Box sx={{...PieStyle.center , ...center}}>
          <Typography sx={PieStyle.count}>{total ?? 0}</Typography>
          <Typography sx={PieStyle.title}>{totalText}</Typography>
        </Box>
      )}
    </Box>
  );
};
