import { KeyboardArrowRight } from "@mui/icons-material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { GET_IDENTIFICATION_MASTER } from "../../../graphql/queries";
import { Routes } from "../../../router/routes";
import { useStyles } from "../styles";
import { AlertProps, LocalStorageKeys } from "../../../utils";
import { withNamespaces } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { AlertContext } from "../../../contexts";

function IdCardType({ t = () => false }) {
  const history = useHistory();
  const language = localStorage.getItem("i18nextLng")
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const [idProof, setIdProof] = React.useState([]);
  const client = useApolloClient();
  const clientID = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
  const goAddIdPage = (value) => {
    history.push({
      pathname: Routes.addIdProof,
      state: {
        data: value
      }
    });
  };

  const getIdProof = () => {
    client.query({
      fetchPolicy: 'network-only',
      query: GET_IDENTIFICATION_MASTER(),
      variables: { client: clientID }
    }).then((response) => {
      setIdProof(response?.data?.Identification_master);
    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: ("Something Went Wrong"),
        });
      });
  };

  React.useEffect(() => {
    getIdProof();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.editContainer}>
      <Typography
        className={classes.labelItalic}
        sx={{ color: "#98A0AC !important" }}
      >
        {t("Choose the ID proof type to add")}
      </Typography>
      {idProof.map((val) => (
        <Stack
          onClick={() => goAddIdPage(val)}
          direction={"row"}
          sx={{ padding: "8px 0", cursor: "pointer" }}
        >
          <Typography
            className={classes.title}
            sx={{ color: "#071741 !important" }}
          >
            {t(val?.label)}
          </Typography>
          <Box display={"flex"} flexGrow={1} justifyContent={"flex-end"}>
            {language === "ar" ?
              <KeyboardArrowLeftIcon /> :
              <KeyboardArrowRight />
            }
          </Box>
        </Stack>
      ))}

      {/* <Stack
        onClick={() => goAddIdPage()}
        direction={"row"}
        sx={{ padding: "8px 0", cursor: "pointer" }}
      >
        <Typography
          className={classes.title}
          sx={{ color: "#071741 !important" }}
        >
          Aadhar Number
        </Typography>
        <Box display={"flex"} flexGrow={1} justifyContent={"flex-end"}>
          <KeyboardArrowRight />
        </Box>
      </Stack>
      <Stack
        onClick={() => goAddIdPage()}
        direction={"row"}
        sx={{ padding: "8px 0", cursor: "pointer" }}
      >
        <Typography
          className={classes.title}
          sx={{ color: "#071741 !important" }}
        >
          Driving Licence
        </Typography>
        <Box display={"flex"} flexGrow={1} justifyContent={"flex-end"}>
          <KeyboardArrowRight />
        </Box>
      </Stack> */}
    </div>
  );
}

export default withNamespaces("kycInformation")(IdCardType);
