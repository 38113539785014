import React from "react"

export const UpfrontChargesIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Group_117595" data-name="Group 117595" transform="translate(-6269 6220)">
    <g id="Group_117591" data-name="Group 117591" transform="translate(8387 -1546)">
      <rect id="Rectangle_58360" data-name="Rectangle 58360" width="40" height="40" rx="8" transform="translate(-2118 -4674)" fill="#edf7f8"/>
      <path id="icons8-cheque_3_" data-name="icons8-cheque (3)" d="M21.579,20.158v1.421H6.421V20.158a1.421,1.421,0,1,0,0,2.842H21.579a1.421,1.421,0,1,0,0-2.842Zm0-14.447a.71.71,0,0,0-1.1-.591L18.717,6.291,16.734,5.1A.71.71,0,0,0,16,5.1L14,6.3,12,5.1a.71.71,0,0,0-.731,0L9.283,6.291,7.526,5.119a.71.71,0,0,0-1.1.591V20.158H21.579ZM13.289,18.263H9.974a.711.711,0,1,1,0-1.421h3.316a.711.711,0,1,1,0,1.421Zm.947-2.842H9.974a.711.711,0,1,1,0-1.421h4.263a.711.711,0,1,1,0,1.421Zm3.789,2.842h-.947a.711.711,0,1,1,0-1.421h.947a.711.711,0,1,1,0,1.421Zm0-2.842h-.947a.711.711,0,0,1,0-1.421h.947a.711.711,0,1,1,0,1.421Zm0-3.789H9.974a.711.711,0,1,1,0-1.421h8.053a.711.711,0,1,1,0,1.421Z" transform="translate(-2112 -4668)" fill="#6dafb3"/>
    </g>
  </g>
</svg>

    )
}