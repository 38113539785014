import React from "react";
import { withBottombar } from "../../HOCs";
import Service from "./service";
class ServiceParent extends React.Component {
  render() {
    return <Service {...this?.props} />;
  }
}

const props = {
  top_bar: "top_nav_bar",
  bottom_bar: "bottom_nav_bar"
}

export default withBottombar(ServiceParent, props);
