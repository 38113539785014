import React from "react";
import Notifications from './notification';
import { withBottombar } from "../../HOCs";

class NotificationParent extends React.Component {
    render() {
        return <Notifications {...this.props} />;
    }
}


const props = {
    top_bar: "title_bar",
  }
  

export default withBottombar(NotificationParent, props);
