import React from "react";
import EmergencyNumber from "./emergencyNumber";
import { withBottombar } from "../../HOCs";

class EmergencyNumberParent extends React.Component {
  render() {
    return <EmergencyNumber {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(EmergencyNumberParent, props);
