import * as React from "react"

const PdfIcon = (props) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        y1={250.588}
        x2={0.509}
        y2={250.127}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#4997e8" />
      </linearGradient>
    </defs>
    <path
      data-name="Path 97716"
      d="M42.561 13.894v31.58A2.501 2.501 0 0 1 40.084 48H7.878A2.501 2.501 0 0 1 5.4 45.474V2.526A2.501 2.501 0 0 1 7.878 0h21.058l3.716 10.105 9.91 3.79Z"
      fill="#acf3ff"
    />
    <path
      data-name="Path 97717"
      d="M28.926.001v11.368a2.503 2.503 0 0 0 2.478 2.526h11.158L28.926.001Z"
      fill="url(#a)"
    />
    <path
      data-name="Path 97718"
      d="M34.505 24.001H13.458a.628.628 0 0 1-.62-.632v-1.263a.625.625 0 0 1 .62-.631h21.047a.625.625 0 0 1 .619.631v1.264a.627.627 0 0 1-.619.631Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97719"
      d="M32.041 29.053H13.446a.626.626 0 0 1-.619-.632v-1.262a.626.626 0 0 1 .619-.632h18.595a.626.626 0 0 1 .619.632v1.263a.627.627 0 0 1-.619.631Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97720"
      d="M34.505 34.106H13.458a.628.628 0 0 1-.62-.632v-1.263a.626.626 0 0 1 .62-.632h21.047a.625.625 0 0 1 .619.632v1.264a.627.627 0 0 1-.619.631Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97721"
      d="M32.041 39.159H13.446a.627.627 0 0 1-.619-.632v-1.263a.625.625 0 0 1 .619-.631h18.595a.625.625 0 0 1 .619.631v1.264a.627.627 0 0 1-.619.631Z"
      fill="#1a9cc6"
    />
  </svg>
)

export default PdfIcon