import { Avatar, Box, Typography } from "@mui/material";
import { listingCardStyle } from "./style";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const ListingTypeCard = ({ details, onClick = () => false }) => {

    const classes = listingCardStyle()
    const { image, bgColor, title } = details;
    return (
        <Box>

            <Box display="flex" alignItems="center" className={classes?.cardRoot} onClick={() => onClick(details)}>
                <Box>
                    <Avatar style={{ borderRadius: 4, backgroundColor: bgColor, width: 32, height: 32 }}>
                        <img src={image} alt="" />
                    </Avatar>

                </Box>
                <Box flexGrow={1}>
                    <Typography className={classes.title}>{title}</Typography>
                </Box>
                <Box>
                    <KeyboardArrowRightIcon size="small" style={{ marginTop: 8 }} />
                </Box>


            </Box>


        </Box>
    )
}