export const ContactAssetIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_116629" data-name="Group 116629" transform="translate(-32 -78)">
                <rect id="Rectangle_57270" data-name="Rectangle 57270" width="48" height="48" rx="8" transform="translate(32 78)" fill="#f1f7ff" />
                <path id="icons8-name_2_" data-name="icons8-name (2)" d="M24.639,6H9.361A3.528,3.528,0,0,0,6,9.667V26.333A3.528,3.528,0,0,0,9.361,30H24.639A3.528,3.528,0,0,0,28,26.333V9.667A3.528,3.528,0,0,0,24.639,6ZM17,10.667A3.2,3.2,0,0,1,20.056,14,3.2,3.2,0,0,1,17,17.333,3.2,3.2,0,0,1,13.944,14,3.2,3.2,0,0,1,17,10.667Zm5.5,10.359c0,1.906-2.229,3.641-5.5,3.641s-5.5-1.735-5.5-3.641v-.451a1.193,1.193,0,0,1,1.138-1.241h8.724A1.193,1.193,0,0,1,22.5,20.575Z" transform="translate(39 84)" fill="#78b1fe" />
            </g>
        </svg>

    )
}