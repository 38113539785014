export const CommunityUnselected = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 7567">
            <path
                data-name="icons8-submit-idea (1)"
                d="M12 4a.614.614 0 0 0-.385.131L5.788 8.726a3.059 3.059 0 0 0-1.173 2.417v7.832A1.027 1.027 0 0 0 5.641 20h12.718a1.027 1.027 0 0 0 1.026-1.026v-7.831a3.059 3.059 0 0 0-1.17-2.417L12.38 4.131A.614.614 0 0 0 12 4Zm-.371 4.923h.686a1.917 1.917 0 0 1 1.882 1.51l.359 1.685a3.489 3.489 0 1 1-5.168 0l.356-1.675a1.922 1.922 0 0 1 1.885-1.52Zm0 .821a1.113 1.113 0 0 0-1.081.868l-.4 1.9c0 .015-.007.016 0 .01a2.663 2.663 0 0 0 1.83 4.6 2.663 2.663 0 0 0 1.831-4.6l-.405-1.906a1.114 1.114 0 0 0-1.089-.872h-.687ZM7.692 11.8a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm3.862 0h.837l.209.984a1.245 1.245 0 0 0 .357.635 1.436 1.436 0 1 1-1.97 0 1.248 1.248 0 0 0 .357-.636Zm4.753 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm-9.436 2.047a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm10.256 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .616-.615ZM7.692 15.9a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Zm8.615 0a.615.615 0 1 1-.615.615.616.616 0 0 1 .615-.615Z"
                fill="none"
            />
            <path data-name="Rectangle 49471" fill="none" d="M0 0h24v24H0z" />
            <path
                data-name="icons8-people (2)"
                d="M11.45 3A3.1 3.1 0 0 0 9.1 4.008a3.358 3.358 0 0 0 0 4.317 3.241 3.241 0 0 0 4.7 0 3.358 3.358 0 0 0 0-4.317A3.1 3.1 0 0 0 11.45 3ZM4.658 4.056A2.6 2.6 0 0 0 2.7 4.9a2.788 2.788 0 0 0 0 3.591 2.6 2.6 0 0 0 1.957.843 2.6 2.6 0 0 0 1.957-.843 2.788 2.788 0 0 0 0-3.591 2.6 2.6 0 0 0-1.956-.844Zm13.585 0a2.6 2.6 0 0 0-1.957.843 2.788 2.788 0 0 0 0 3.591 2.693 2.693 0 0 0 3.915 0 2.788 2.788 0 0 0 0-3.591 2.6 2.6 0 0 0-1.959-.843Zm-6.792.528a1.437 1.437 0 0 1 1.178.476 1.767 1.767 0 0 1 0 2.214 1.437 1.437 0 0 1-1.178.476 1.438 1.438 0 0 1-1.178-.476 1.767 1.767 0 0 1 0-2.214 1.437 1.437 0 0 1 1.177-.477ZM4.658 5.639a.936.936 0 0 1 .786.311 1.2 1.2 0 0 1 0 1.489.936.936 0 0 1-.786.311.936.936 0 0 1-.786-.311 1.2 1.2 0 0 1 0-1.489.936.936 0 0 1 .786-.311Zm13.585 0a.936.936 0 0 1 .786.311 1.2 1.2 0 0 1 0 1.489 1.147 1.147 0 0 1-1.572 0 1.2 1.2 0 0 1 0-1.489.936.936 0 0 1 .785-.311Zm-15.414 4.75A1.84 1.84 0 0 0 1 12.236v3.431a4.207 4.207 0 0 0 4.18 4.222 4.046 4.046 0 0 0 1.077-.142 5.937 5.937 0 0 1-.47-1.515 2.544 2.544 0 0 1-.606.074 2.627 2.627 0 0 1-2.613-2.639v-3.431a.26.26 0 0 1 .261-.264h2.889a2.868 2.868 0 0 1 .643-1.583Zm5.747 0a1.85 1.85 0 0 0-1.829 1.847v5.014a4.7 4.7 0 1 0 9.4 0v-5.014a1.85 1.85 0 0 0-1.829-1.847Zm7.963 0a2.868 2.868 0 0 1 .643 1.583h2.889a.26.26 0 0 1 .261.264v3.431a2.627 2.627 0 0 1-2.612 2.639 2.544 2.544 0 0 1-.606-.074 5.937 5.937 0 0 1-.47 1.515 4.046 4.046 0 0 0 1.077.142 4.207 4.207 0 0 0 4.18-4.222v-3.431a1.84 1.84 0 0 0-1.829-1.847Zm-7.963 1.583h5.747a.251.251 0 0 1 .261.264v5.014a3.135 3.135 0 1 1-6.27 0v-5.014a.251.251 0 0 1 .262-.264Z"
                fill="#98a0ac"
                stroke="#fff"
                strokeWidth={0.2}
            />
        </g>
    </svg>
)