import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const AmenitiesBookingStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  roots: {
    margin: "0px 12px",
    padding: "12px 0px 0px 5px",
    overflow: "scroll",
  },
  image: {
    objectFit: "cover",
    width: "64px",
    borderRadius: theme.palette.borderRadius,
    height: "60px",
    border: "1px solid #5C86CB2E",
  },
  card: {
    backgroundColor: "white",
    padding: "6px 0px 12px 0px",
    marginTop: "12px",
    borderRadius: "16px",
  },
  hourSlotCard: {
    // height: "calc(100vh - 530px)"
  },
  dailySlotCard: {
    // height: "calc(100vh - 500px)"
  },
  name: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.Tertiary,
    marginBottom: "8px",
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginTop: "12px",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "0.75rem",
  },
  noDataBox: {
    position: "relative",
    height: "150px"
  },
  noData: {
    color: "#999",
    textAlign: "center",
    // marginTop: "100px",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    // marginLeft: "12px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    whiteSpace:"nowrap"
  },
  slots: {
    backgroundColor: "white",
    padding: "14px",
    borderRadius: "4px",
    marginTop: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  titles: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  modalTitle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  title2: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  totalAmount: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },

  subTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      padding: "0px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  Cardcontent: {
    [theme.breakpoints.up("sm")]: {
      padding: "12px",
      backgroundColor: "#F5F7FA",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "12px",
      backgroundColor: "#F5F7FA",
    },
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "10px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  justifyContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  flexWithPadding: {
    display: "flex",
    padding: "8px 14px",
    borderBottom: "1px solid #c1c1c1",
    alignItems: "center",
    '@media (min-width: 374px)': {
      padding: "16px",
    },
    '@media (max-width: 321px)': {
      padding: "12px",
    },
  },
  divider: {
    backgroundColor: "#F2F4F7",
    height: "4px",
    margin: "8px 0px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "red",
    // margin: "6px 6px 6px 8px",
    margin: "auto",
  },
  tabroot: {
    display: "flex",
    alignItems: "center",
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabItemroot: {
    "& .MuiButtonBase-root-MuiTab-root": {},
  },
  tabItem: {
    borderRadius: "4px",
    padding: "8px",
    minHeight: "auto",
    minWidth: "auto",
    maxHeight: "68px",
    margin: "0px 14px",

    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "4px",
    },
  },
  tabItemSelect: {
    backgroundColor: "#F2F5FA",
    borderRadius: "6px",
    padding: "6px 8px",
    minHeight: "auto",
    minWidth: "auto",
    maxHeight: "82px",
    margin: "0px 14px",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: 0,
      borderRadius: "4px",
    },
  },
  textSelect1: {
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
  },
  textSelect2: {
    color: theme.palette.primary.main,
    fontSize: "1.125rem",
  },
  textNotSelect1: {
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
  },
  dueSect: {
    color: "#5D427F",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    backgroundColor: "#F1E6FE",
    width: "60%",
    margin: "auto",
    textAlign: "center",
    padding: "3px 6px",
    borderRadius: "6px",
  },
  textNotSelect2: {
    color: theme.typography.color.secondary,
    fontSize: "1.125rem",
  },
  panel: {
    padding: "12px !important",
  },
  box: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: "16px",
    borderRadius: "16px",
    marginBottom: "14px",
    cursor: "pointer",
    height: "70px",
  },
  tagStyle: {
    backgroundColor: "#F1E6FE",
    borderRadius: "4px",
    padding: "4px 6px 4px 6px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5D427F",
    marginRight: "16px",
  },
  borderGrid: {
    display: "flex",
    borderBottom: "2px dashed #CED3DD",
    borderTop: "2px dashed #CED3DD",
    padding: "12px 0px",
    marginLeft: "10px",
  },
  totalAmnt: {
    color: theme.typography.color.primary,
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
    margin: "12px 0px",
  },
  billSumAmntHead: {
    color: theme.typography.color.secondary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().regular,
    marginBottom: "8px",
  },
  billSumAmnt: {
    color: theme.typography.color.secondary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "8px !important",
  },
  billSum: {
    color: theme.typography.color.primary,
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "8px",
  },
  billSumBox: {
    borderBottom: "1px dotted #CED3DD",
  },
  invoiceBtn: {
    padding: "14px",
    width: "100%",
    borderRadius: "10px",
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
  },
  payBtn: {
    padding: "14px",
    backgroundColor: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
    width: "100%",
    borderRadius: "10px",
  },
  paymentBtnStack: {
    backgroundColor: "white",
    position: "absolute",
    bottom: "0px",
    width: "100%",
    padding: "16px",
  },
  bookingAvatar: {
    borderRadius: "4px",
    width: "64px",
    height: "64px",
  },
  bookingSubText: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "1rem",
  },
  bookingTitle: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1.125rem",
  },
  bookingInvoiceTitle: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "1rem",
  },
  bookingInvoiceAmnt: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "1rem",
  },
  bookingTotalTitle: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1rem",
  },
  bookingsTopBox: {
    backgroundColor: "white",
    padding: "14px 16px",
    borderRadius: "10px",
    marginBottom: "16px",
  },
  bookingsBottomBox: {
    backgroundColor: "white",
    padding: "14px 16px",
    borderRadius: "10px",
  },
  paidStatus: {
    color: theme.palette.success.main,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  bookingPass: {
    color: theme.typography.color.secondary,
    fontSize: "0.75rem",
    textAlign: "center",
    fontFamily: FontFamilySwitch().bold,
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    width: "35%",
    margin: "auto",
  },
  passStack: {
    backgroundColor: theme.palette.info.light,
    borderRadius: "6px",
    padding: "8px",
  },
  backgroundImageStyle: {
    width: "100%",
    borderRadius: "0px",
    height: "100%",
  },
  bookingStatus: {
    color: theme.palette.primary.contrastText,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: theme.palette.success.main,
    padding: "3px 6px",
    borderRadius: "6px",
    textAlign: "center",
    width: "60%",
  },
  bookingconfrAvat: {
    width: "56px",
    height: "56px",
  },
  bookingconfrTitle: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1.25rem",
    marginBottom: "6px",
  },
  bookingconfrSubText: {
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
    marginBottom: "8px",
  },
  bookingUnitTitle: {
    color: theme.typography.color.primary,
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
  },
  bookingUnitSubText: {
    color: theme.typography.color.secondary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  bookingUnitsHeads: {
    color: theme.typography.color.Tertiary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginBottom: "12px",
  },
  bookingDetailsSections: {
    marginTop: "20px",
    position: "relative",
  },
  leftCircleIcon: {
    color: "#F2F5FA",
    width: "34px",
    height: "34px",
    position: "absolute",
    top: "-18px",
    left: "-32px",
  },
  RightCircleIcon: {
    color: "#F2F5FA",
    width: "34px",
    height: "34px",
    position: "absolute",
    top: "-18px",
    right: "-32px",
  },
  leftBottomCircleIcon: {
    color: "#F2F5FA",
    width: "34px",
    height: "34px",
    position: "absolute",
    bottom: "-18px",
    left: "-32px",
  },
  RightBottomCircleIcon: {
    color: "#F2F5FA",
    width: "34px",
    height: "34px",
    position: "absolute",
    bottom: "-18px",
    right: "-32px",
  },
  amenityNameBox: {
    padding: "18px 0px 18px",
    borderBottom: "2px dotted #E4E8EE",
    borderTop: "2px dotted #E4E8EE",
    position: "relative",
  },
  slotDetailsText: {
    color: theme.palette.success.main,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem",
  },
  slotTime: {
    color: theme.palette.success.main,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1rem",
  },
  slotDetails: {
    backgroundColor: "#E8F9E8",
    width: "60%",
    margin: "auto",
    textAlign: "center",
    padding: "5px",
    position: "relative",
    borderRadius: "4px",
  },
  leftSlotCircleIcon: {
    color: "#fff",
    width: "24px",
    height: "24px",
    position: "absolute",
    top: "16px",
    left: "-12px",
  },
  RightSlotCircleIcon: {
    color: "#fff",
    width: "24px",
    height: "24px",
    position: "absolute",
    top: "16px",
    right: "-12px",
  },
  cmpnydetails: {
    backgroundColor: theme.palette.info.light,
    borderRadius: "10px",
    width: "70%",
    margin: "auto",
    padding: "8px",
    marginTop: "16px",
  },
  cmpnydetailsText: {
    color: theme.palette.info.main,
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
    textAlign: "center",
  },
  SquareIcon: {
    transform: "rotate(45deg)",
    fontSize: "0.75rem",
    margin: "0px 8px",
  },
  button_div: {
    backgroundColor: "white",
    padding: "16px",
  },
  button: {
    padding: "15px 122px",
    border: "1px solid #5078E1",
    color: "#5078E1",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  roots_history: {
    overflow: "scroll",
  },
  cardImage: {
    height: "256px",
    borderRadius: "16px",
    width: "100%"
  },
  cardImage1: {
    height: "256px",
    borderRadius: "16px !important",
    width: "100%",
    border: "1px solid #fff"
  },
  imageCard: {
    borderRadius: "16px",
  },
  title: {
    flex: 1,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    fontWeight: "700",
    marginBottom: "4px",
    color: theme.typography.color.primary
  },
  dollar: {
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "1.125rem",
    fontWeight: "700",
    marginBottom: "4px",
    color: theme.typography.color.primary
  },
  subText: {
    flex: 1,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  time: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.Tertiary
  },
  contentText: {
    padding: "12px !important",
  },
  padding: {
    padding: "0 12px"
  },
  customSnackbar: {
    height: "auto",
    width: "95%",
    background: "#fff",
    borderRadius: '12px',
    border: "1px solid #E4E8EE",
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    zIndex: 100,
    margin: "12px",
    boxShadow: '0px 0px 16px #00000014',
  },
  peakBox: {
    borderRadius: "9px",
    background: theme.palette.warning.light
  },
  peakBoxText: {
    fontSize: "0.75rem",
    color: theme.palette.warning.main,
    fontFamily: FontFamilySwitch().semiBold
  },
  descriptionText: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: 4
  },
  desc: {
    fontSize: "0.75rem",
    wordBreak: "break-all",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  seeMoreLessTextStyle: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  freeText: {
    fontSize: "0.875rem",
    color: theme.palette.success.main,
    background: theme.palette.success.light,
    fontFamily: FontFamilySwitch().bold,
    borderRadius: 8,
    padding: "4px 8px 2px 8px"
  },
  snackBarMessage: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
  },
  snackBarButton: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  popupTitle:{
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
  }
}));
