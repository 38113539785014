import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage, ValidateEmail } from "../../utils";
import { Routes } from "../../router/routes";
import jwt_decode from "jwt-decode";

export const silentOTP = (e, login, alert, history, isIamValideToLogin, setDisable, t = () => false, welcomePageAuthToken, deviceToken) => {
    console.log("----------")
    e.preventDefault();
    let data = {
        password: login.password,
    };
    if (isIamValideToLogin()) {
        //eslint-disable-next-line
        var isphone = /^\d+$/.test(login.email)
        //eslint-disable-next-line
        var email = ValidateEmail(
            login.email
        );
        if (isphone) {
            data["mobile_no"] = login.email;
        } else if (email) {
            data["email_id"] = login?.email?.toLowerCase();
        } else {
            data["ucid"] = login.email;
        }
        NetworkCall(
            `${config.auth_api_url}/auth/login`,
            NetWorkCallMethods.post, data, null, false, false
        ).then((response) => {
            if (response?.status === 200) {
                localStorage.setItem(LocalStorageKeys.authToken, response.data.token)
                localStorage?.setItem?.(LocalStorageKeys?.user_id, jwt_decode(response?.data?.token)?.id);
                const payload = {
                    otp: "0000",
                };
                NetworkCall(
                    `${config.auth_api_url}/auth/verifyOTP/?token=${response?.data?.token}`,
                    NetWorkCallMethods.post, payload, null, false, false
                ).then((response) => {
                    if (response.status === 200) {
                        localStorage.setItem(LocalStorageKeys.authToken, response.data.token);
                        localStorage?.setItem?.(LocalStorageKeys?.user_id, jwt_decode(response?.data?.token)?.id);
                        if (deviceToken) {
                            localStorage.setItem(LocalStorageKeys.deviceToken, deviceToken);

                        }
                        history.push({
                            pathname: Routes.companyselect,
                            state: response.data.user.clients
                        });
                    } else {
                        setDisable(false)
                        RetainLocalStorage();
                        history.push(Routes.login);
                    }
                }).catch((err) => {
                    if (err?.response?.data?.status === 403) {
                        alert.setSnack({
                            ...alert, open: true, msg: "Sorry you do not have access",
                            severity: AlertProps.severity.error,
                        });
                        setDisable(false)
                    } else {
                        alert.setSnack({
                            ...alert, open: true, msg: t("ffffffInvalidOTP"),
                            severity: AlertProps.severity.error,
                        });
                        setDisable(false)
                    }
                });
            } else {
                alert.setSnack({
                    ...alert, open: true, msg: t("InvalidCredientials"),
                    severity: AlertProps.severity.error,
                });
                setDisable(false)
            }
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: t("InvalidCredientials"),
                severity: AlertProps.severity.error,
            });
            console.log(err)
            setDisable(false)
        });
    } else {
        setDisable(false)
        return false;
    }
};