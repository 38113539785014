import React from "react";

function Bonds() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H21V18H0z"
            data-name="Rectangle 52013"
            transform="translate(81.699 184.39)"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 105889" transform="translate(-31.5 -502)">
        <rect
          width="42"
          height="42"
          fill="#e6f9ff"
          data-name="Rectangle 52955"
          rx="4"
          transform="translate(31.5 502)"
        ></rect>
        <g
          clipPath="url(#clip-path)"
          data-name="Group 6395"
          transform="translate(-39.199 329.609)"
        >
          <path
            fill="#20adf8"
            d="M9.929 4A1.929 1.929 0 008 5.929v13.285a1.929 1.929 0 001.929 1.929h6.017a1.694 1.694 0 01-.231-.857v-5.143c0-1.688 2.372-2.571 4.714-2.571a9.121 9.121 0 011.286.09v-1.8h-4.929a1.929 1.929 0 01-1.929-1.929V4zm6.214.377v4.552a.644.644 0 00.643.643h4.552zm-4.071 6.073a.536.536 0 01.536.536v.337a1.766 1.766 0 011.328.907.536.536 0 11-.941.513.7.7 0 00-.612-.363H11.7a.7.7 0 100 1.393h.964a1.767 1.767 0 110 3.535h-.054v.3a.536.536 0 11-1.071 0v-.3h-.2a1.768 1.768 0 01-1.553-.922.536.536 0 11.941-.513.7.7 0 00.612.364h1.328a.7.7 0 000-1.393H11.7a1.763 1.763 0 01-.161-3.52v-.337a.536.536 0 01.532-.538zm8.357 2.979c-2.13 0-3.857.768-3.857 1.714s1.727 1.714 3.857 1.714 3.857-.768 3.857-1.714-1.727-1.714-3.857-1.714zm-3.857 3v1.286c0 .947 1.727 1.714 3.857 1.714s3.857-.767 3.857-1.714v-1.286c0 .947-1.727 1.714-3.857 1.714s-3.858-.767-3.858-1.714zm0 2.571v1.286c0 .947 1.727 1.714 3.857 1.714s3.857-.767 3.857-1.714V19c0 .947-1.727 1.714-3.857 1.714s-3.858-.767-3.858-1.714z"
            data-name="icons8-investment (1)"
            transform="translate(76.056 180.391)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Bonds;