import React from "react";
import { withBottombar } from "../../HOCs";
import ProfileView from "./profileView";
class ProfileViewParent extends React.Component {
  render() {
    return <ProfileView {...this?.props} />;
  }
}

const props = {
  top_bar: "top_nav_bar",
  bottom_bar: "bottom_nav_bar"
}

export default withBottombar(ProfileViewParent, props);
