import * as React from "react"
const AccountIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 119693">
      <path
        fill="#a9afbc"
        d="M12 0a12 12 0 1 0 12 12A12.014 12.014 0 0 0 12 0Zm0 1.8A10.2 10.2 0 1 1 1.8 12 10.186 10.186 0 0 1 12 1.8Zm0 3a3.573 3.573 0 0 0-2.7 1.146 3.788 3.788 0 0 0 0 4.908 3.748 3.748 0 0 0 5.4 0 3.788 3.788 0 0 0 0-4.908A3.573 3.573 0 0 0 12 4.8Zm0 1.8a1.659 1.659 0 0 1 1.352.541 1.993 1.993 0 0 1 0 2.517A1.659 1.659 0 0 1 12 10.2a1.659 1.659 0 0 1-1.352-.541 1.993 1.993 0 0 1 0-2.517A1.659 1.659 0 0 1 12 6.6Zm-4.044 6.6A1.97 1.97 0 0 0 6 15.156v.649a3.562 3.562 0 0 0 1.828 2.914A7.753 7.753 0 0 0 12 19.8a7.753 7.753 0 0 0 4.172-1.082A3.562 3.562 0 0 0 18 15.805v-.649a1.97 1.97 0 0 0-1.956-1.956Zm0 1.8h8.088a.144.144 0 0 1 .156.156v.649a1.781 1.781 0 0 1-1 1.4A6.067 6.067 0 0 1 12 18a6.067 6.067 0 0 1-3.2-.8 1.781 1.781 0 0 1-1-1.4v-.649A.144.144 0 0 1 7.956 15Z"
        data-name="icons8-user (5)"
      />
    </g>
  </svg>
)
export default AccountIcon
