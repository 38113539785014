import { Box, Divider, Skeleton, Stack } from "@mui/material";
import { useState } from "react";
import Activity from "./activity";
import { DialogDrawer } from "../../../components";
import Drawer from "../component/drawer";
import { InvestmentListCardUseStyles } from "./style";
import { withNamespaces } from "react-i18next";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const InvestmentListCard = ({
  listing = [],
  fetchMoreData = () => {},
  loading,
  t = () => false
}) => {
  const classes = InvestmentListCardUseStyles();
  const size = useWindowDimensions();
  const [drawer, setDrawer] = useState();
  const alert = React.useContext(AlertContext);
  const [unitListing, setUnitListing] = React.useState([]);

  const handleDrawerClose = () => {
    setDrawer(false);
  };

  const handleDrawerOpen = (id) => {
    // setDrawer(!drawer);
    getUnitListing(id);
  };

  // LISTING

  // const getListing = (fetchMore, offset) => {
  //   const payload = {
  //     limit: 10,
  //     offset: offset,
  //     type: "Investments",
  //   }
  //   NetworkCall(
  //     `${config?.api_url}/statistics_listing/listing`,
  //     NetWorkCallMethods.post,
  //     payload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       if (fetchMore) {
  //         setListing((res) => [...res, ...response?.data?.data?.data])
  //       } else {
  //         setListing(response?.data?.data?.data)
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: ("Something Went Wrong"),
  //       });
  //     });
  // };

  // UNIT LISTING

  const getUnitListing = (id) => {
    const payload = {
      id: id
    }
    NetworkCall(
      `${config?.api_url}/statistics_unit_listing/unit_listing`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setUnitListing(response?.data?.data?.[0]);
        setDrawer(!drawer);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went Wrong"),
        });
      });
  };

  return (
    <Box
      className={classes.investmentListCard}
    >
      <Box
        className={classes.investmentList}
      >
        <InfiniteScroll
          dataLength={listing.length}
          next={fetchMoreData}
          hasMore={true}
          height={size?.height - 210}>
          <Stack divider={<Divider className={classes.divider2} />}>
          {listing.map((val) => (
            <>
              {loading ? (
                <>
                  <Box p={1} pt={2}>
                    <Stack direction={"row"} spacing={2}>
                      <Skeleton variant="rounded" width={50} height={50} />
                      <Stack>
                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      </Stack>
                    </Stack>
                  </Box>
                </>) : (
                <>
                  <Activity
                    onClick={handleDrawerOpen}
                    data={val}
                    type={"Investments"}
                  />
                </>
              )}
            </>
          ))}
          </Stack>
        </InfiniteScroll>
      </Box>
      <DialogDrawer
        maxWidth="sm"
        open={drawer}
        onClick={handleDrawerClose}
        header={t("Investment details")}
        component={
          <>
            <Drawer
              type={"Investments"}
              data={unitListing} />
          </>
        }
        onClose={() => setDrawer(false)}
      />
    </Box>
  );
};

export default withNamespaces("statistics")(InvestmentListCard);
