import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";
import { SimpleAccordion } from "../../components/accordian";
import { AccordianCard } from "../../components/accordianCard";
import { getSupportAccordian } from "../../utils/getsupport";
import CallerSupport from "../../assets/callerSupport";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";



const GetSupport = (props) => {
    const { handleTopBarState, t = () => false } = props
    const classes = useStyles();
    const history = useHistory();

    React.useEffect(() => {
        handleTopBarState({
            text: "Get Support"
        })
        // eslint-disable-next-line
    }, []);

    return (
        <Container
            className={classes.container}
            maxWidth="sm"
        >
            {/* topNavbar */}
            {/* <TitleBar text="Get Support" goBack={() => history.goBack(-1)} /> */}

            {/* CreateInvestment */}
            <Box className={classes.root}>
                <Box className={classes?.topSection} my={3}>
                    <CallerSupport className={classes?.headAvatar} />
                    <Typography className={classes?.headTitle}>{t("Hi, How can we help you?")}</Typography>
                </Box>
                {getSupportAccordian && getSupportAccordian?.map((item, index) => {
                    return (
                        <Box key={index}>
                            <SimpleAccordion
                                headText={item?.accordianTitle}
                                defaultExpanded={index === 0 ? true : false}
                                content={
                                    <Grid container spacing={1.3}>
                                        {item?.accordianContent && item?.accordianContent?.map((content, index) => {
                                            return (

                                                <Grid item xs={12}>
                                                    <Box>
                                                        <AccordianCard
                                                            // detailed={true}
                                                            title={content?.contentTitle}
                                                            bottomText={content?.contentDescription}
                                                            onClick={() => history.push(Routes.getSupportDetailed)}
                                                        />
                                                    </Box>

                                                </Grid>
                                            )

                                        })}

                                    </Grid>

                                }

                            />
                        </Box>
                    )

                })}
            </Box>
        </Container>

    );
};
export default withNamespaces("getSupport")(GetSupport);