import * as React from "react";
import {
  Typography,
  Stack,
  Avatar,
  Box,
  Container,
  Button,
} from "@mui/material";
import { withNamespaces } from "react-i18next";
import { AmenitiesBookingStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import QRCode from "react-qr-code";
import AmenityBookingShareIcon from "../../assets/amenityBookingShareIcon";
import CircleIcon from "@mui/icons-material/Circle";
import CompanyNameAsset from "../../assets/cmpnyNameAsset";
import SquareIcon from "@mui/icons-material/Square";
import DntLogo from "../../assets/dntLogo.png";
import { generateImageUrl, img_size } from "../../utils";
import { withBottombar } from "../../HOCs";

const BookingConfirmation = ({ t = () => false, handleTopBarState }) => {
  const classes = AmenitiesBookingStyles();
  const size = useWindowDimensions();

  // const [qrdata, setQrdata] = React.useState([]);

  const data = {
    image:
      "https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    title: "Amenity Name Here",
    bookingId: "ID1245153",
    bookingAmnt: "$2.00",
    status: "Paid",
  };

  React.useEffect(() => {
    handleTopBarState({
      text: "Booking Confirmation"
    })
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        // text={state?.main?.name}
        text="Booking Confirmation"
        goBack={() => history.goBack()}
      /> */}
      <div
        className={classes.roots}
        style={{
          height: size?.height - (60 + 89),
        }}
      >
        <Box className={classes.bookingsTopBox}>
          {[data]?.map((data, index) => {
            return (
              <Stack
                className={classes.unitCard}
                direction={"row"}
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                paddingBottom={"12px"}
                borderBottom={"1px solid #E4E8EE"}
              >
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                  {/* img */}
                  <Avatar className={classes.bookingAvatar} src={generateImageUrl(data?.image, img_size.small_square)} />

                  {/* title details */}
                  <Stack>
                    <Typography className={classes.bookingTitle}>
                      {data?.title}
                    </Typography>
                    <Typography className={classes.bookingSubText}>
                      {data?.bookingId}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <Typography className={classes.bookingTitle}>
                    {data?.bookingAmnt}
                  </Typography>
                  <Typography className={classes.paidStatus}>
                    {data?.status}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}

          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="space-between"
            alignItems={"center"}
            padding={"12px 0px"}
          >
            <Typography className={classes.bookingInvoiceTitle}>
              Invoice Amount
            </Typography>
            <Typography className={classes.bookingInvoiceAmnt}>
              $ 2.00
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="space-between"
            alignItems={"center"}
            padding={"12px 0px"}
          >
            <Typography className={classes.bookingInvoiceTitle}>Tax</Typography>
            <Typography className={classes.bookingInvoiceAmnt}>$.12</Typography>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent="space-between"
            alignItems={"center"}
            padding={"12px 0px"}
          >
            <Typography className={classes.bookingTotalTitle}>Total</Typography>
            <Typography className={classes.bookingTotalTitle}>
              $ 2.12
            </Typography>
          </Stack>
        </Box>

        {/* booking pass */}

        <Typography className={classes.bookingPass}>
          <SquareIcon className={classes.SquareIcon} /> YOUR BOOKING PASS{" "}
          <SquareIcon className={classes.SquareIcon} />
        </Typography>

        <Box className={classes.bookingsBottomBox}>
          <Box padding={"12px 0px"}>
            <Stack
              className={classes.passStack}
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
              spacing={1}
              mb={2}
            >
              <Stack>
                <Typography className={classes.passTitle}>
                  Amenities Pass
                </Typography>
                <Typography className={classes.subText}>
                  Pass for all amenities booking
                </Typography>
              </Stack>

              <Stack>
                <Avatar
                  className={classes.backgroundImageStyle}
                  src={"./images/amenitiesSportsPass.svg"}
                />
              </Stack>
            </Stack>
            <Box mt={3} mb={3} textAlign="center">
              <img src={DntLogo} alt="logo" />
            </Box>
            <Box mb={2} textAlign="center">
              <QRCode value={`${data?.qr_no}`} size={220} />
            </Box>
            <Stack>
              <Stack className={classes.slotDetails}>
                <CircleIcon className={classes.leftSlotCircleIcon} />
                <CircleIcon className={classes.RightSlotCircleIcon} />
                <Typography className={classes.slotTime}>
                  8:00 - 9:00 AM - 22 Jul 22
                </Typography>
                <Typography className={classes.slotDetailsText}>
                  Slot Details
                </Typography>
              </Stack>

              <Stack className={classes.cmpnydetails}>
                <Typography className={classes.cmpnydetailsText}>
                  <CompanyNameAsset /> Rukon Al Salim Real Estate Dubai
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Stack className={classes.amenityNameBox}>
            <CircleIcon className={classes.leftCircleIcon} />
            <CircleIcon className={classes.RightCircleIcon} />

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                {/* img */}
                <Avatar className={classes.bookingAvatar} src={generateImageUrl(data?.image, img_size.small_square)} />

                {/* title details */}
                <Stack>
                  <Typography className={classes.bookingconfrTitle}>
                    {data?.title}
                  </Typography>
                  <Typography className={classes.bookingconfrSubText}>
                    {data?.bookingId}
                  </Typography>
                  <Typography className={classes.bookingStatus}>
                    Booked
                  </Typography>
                </Stack>
              </Stack>

              <Stack>
                <AmenityBookingShareIcon />
              </Stack>
            </Stack>
            <CircleIcon className={classes.leftBottomCircleIcon} />
            <CircleIcon className={classes.RightBottomCircleIcon} />
          </Stack>

          <Stack className={classes.bookingDetailsSections}>
            <Typography className={classes.bookingUnitsHeads}>
              BOOKED UNIT DETAILS
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Avatar className={classes.bookingconfrAvat} src={generateImageUrl(data?.image, img_size.small_square)} />

              {/* title details */}
              <Stack>
                <Typography className={classes.bookingUnitTitle}>
                  Unit Name Here
                </Typography>
                <Typography className={classes.bookingUnitSubText}>
                  Unit Id here
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack className={classes.bookingDetailsSections}>
            <Typography className={classes.bookingUnitsHeads}>
              BOOKED UNIT DETAILS
            </Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Avatar className={classes.bookingconfrAvat} src={generateImageUrl(data?.image, img_size.small_square)} />

              {/* title details */}
              <Stack>
                <Typography className={classes.bookingUnitTitle}>
                  Unit Name Here
                </Typography>
                <Typography className={classes.bookingUnitSubText}>
                  Unit Id here
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </div>
      <div className={classes.button_div}>
        <Button fullWidth variant="outlined" className={classes.button}>
          Cancel Booking
        </Button>
      </div>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("amenitiesDetails")(BookingConfirmation), props);
