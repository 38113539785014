import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "../styles";

const PropertyCard = ({
  data,
  selectFun,
  selectedData,
  unitDrawerFun,
  border,
  padding,
  t = () => false
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.propertyCard}
      sx={{ borderBottom: border && "0 !important" }}
    >
      <Box
        className={classes.containerPadding}
        sx={{ width: padding && "345px" }}
      >
        <Box
          position={"relative"}
          onClick={() => (unitDrawerFun ? unitDrawerFun(data?.id) : null)}
        >
          <img
            className={classes.propertyImg}
            src={"/images/" + data?.images[0]}
            alt="logo"
          />
          <Box className={classes.cardOverlay}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography className={classes.propertyType}>
                {data?.propertyType}
              </Typography>
              <Typography className={classes.propertyRate} noWrap>
                {data?.offerRate && (
                  <Typography
                    className={classes.propertyOriginalRate}
                    component="span"
                  >
                    {data?.originalRate}
                  </Typography>
                )}
                &nbsp; {data?.offerRate && " Offer " + data?.offerRate}{" "}
                {!data?.offerRate && data?.originalRate}
              </Typography>
            </Stack>
            <Box sx={{ marginTop: "95px" }}>
              <Typography
                className={classes.propertyDetails}
                component="span"
                noWrap
              >
                <img
                  className={classes.imgIcon}
                  src="/images/sqftIcon.svg"
                  alt="logo"
                />
                {data?.sqft}
                <Box className={classes.borderRight} component="span" />
                <img
                  className={classes.imgIcon}
                  src="/images/BedroomIcon.svg"
                  alt="logo"
                />{" "}
                {data?.bed} {t("Bedroom")}
                <Box className={classes.borderRight} component="span" />
                <img
                  className={classes.imgIcon}
                  src="/images/FurnishedIcon.svg"
                  alt="logo"
                />{" "}
                {data?.furnishing}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box height={"3px"} />
        <Grid container justifyContent={"space-between"}>
          <Grid item sm={8} xs={7}>
            {/* <Box className={classes.cardfooter}> */}
            <Box flexGrow={1}>
              <Typography className={classes.title} noWrap>
                {data?.propertyName}
              </Typography>
              <Box height={"3px"} />
              <Typography className={classes.subTitle2} noWrap>
                {data?.address}
              </Typography>
              <Box className={classes.footerText}>
                <img src="/images/Location.svg" alt="logo" />{" "}
                <Typography className={classes.subTitle} noWrap>
                  {data?.location}
                </Typography>
                <img src="/images/buldingIcon.svg" alt="logo" />
                <Typography className={classes.subTitle} noWrap>
                  {data?.buldingNo}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={3} xs={4} alignSelf="flex-end">
            <Box className={classes.addBlock}>
              {data?.shortlisted && (
                <Typography
                  component={"span"}
                  className={classes.shortList}
                  noWrap
                >
                  {data?.shortlisted}
                </Typography>
              )}
              {selectedData?.find((item) => item?.id === data?.id)  ? (
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={
                    selectFun
                      ? () => {
                          selectFun(data?.id, "remove");
                        }
                      : ""
                  }
                >
                  <img src={"/images/checkedPrimary.svg"} alt="logo" />
                </Box>
              ) : (
                <Box
                  onClick={
                    selectFun
                      ? () => {
                          selectFun(data, "add");
                        }
                      : ""
                  }
                >
                  <img
                    src={"/images/addProperty.svg"}
                    sx={{ cursor: "pointer" }}
                    alt="logo"
                  />
                </Box>
              )}
            </Box>
          </Grid>
          {/* </Box> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default PropertyCard;
