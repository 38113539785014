import * as React from "react"

export const Transportation = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 52013"
          transform="translate(81.699 184.39)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h21v18H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 109121">
      <g
        data-name="Group 6395"
        transform="translate(-80.199 -181.391)"
        clipPath="url(#a)"
      >
        <path
          d="M83.007 185.546a.672.672 0 0 0-.661.682v7.391h11.2v-7.391a.672.672 0 1 0-1.344 0v.224H83.69v-.224a.672.672 0 0 0-.682-.682Zm2.25 2.249a.672.672 0 0 1 .672.672v3.136a.672.672 0 0 1-1.344 0v-3.135a.672.672 0 0 1 .672-.672Zm2.688 0a.672.672 0 0 1 .672.672v3.136a.672.672 0 0 1-1.344 0v-3.135a.672.672 0 0 1 .673-.672Zm2.688 0a.672.672 0 0 1 .672.672v3.136a.672.672 0 0 1-1.344 0v-3.135a.672.672 0 0 1 .672-.672Zm5.151 1.792a.9.9 0 0 0-.9.9v8.281a2.462 2.462 0 0 0 4.831.672h.32a2.016 2.016 0 0 0 2.016-2.016v-2.85a2.014 2.014 0 0 0-.174-.819l-1.321-2.972a2.015 2.015 0 0 0-1.842-1.2Zm.9 1.344h2.038a.67.67 0 0 1 .613.4l.737 1.659a.448.448 0 0 1-.409.63h-2.98a.448.448 0 0 1-.448-.448v-1.793a.448.448 0 0 1 .445-.448Zm-14.338 4.032v2.464a2.016 2.016 0 0 0 2.016 2.016h.32a2.46 2.46 0 0 0 4.735 0h3.456a.672.672 0 0 0 .673-.675v-3.805Zm4.7 2.688a1.12 1.12 0 1 1-1.12 1.12 1.111 1.111 0 0 1 1.12-1.121Zm10.3 0a1.12 1.12 0 1 1-1.12 1.12 1.111 1.111 0 0 1 1.126-1.121Z"
          fill="#20adf8"
        />
      </g>
    </g>
  </svg>
)

export default Transportation;
