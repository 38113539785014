import React from "react"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { useStyles } from "./style"
import DepartmentLogo from "../utils/departmentLogo"
import RightArrow from "../utils/RightArrow"

const DepartmentCard = ({ t = () => false, subTitle, title, onClick = () => false, onClose = () => false, hidden = "", open = false, isBorder = false, icon, viewMore = false, viewClick = () => false }) => {
    const classes = useStyles()
    const language = localStorage.getItem("i18nextLng")
    return (
        <Box onClick={onClick}>
            <Stack direction={"row"} columnGap={1} justifyContent={"space-between"} alignItems={"center"} className={classes.rootBox}>
                {isBorder ?? <Divider />}
                <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                    {icon ?? <DepartmentLogo />}
                    <Typography className={classes.title}>{title}</Typography>
                </Stack>
                <Box className={language === "ar" && classes.arrow} onClick={() => viewMore && viewClick()}>
                    <RightArrow />
                </Box>

            </Stack>
            {hidden && <Divider className={classes.divider} />}
        </Box>

    )
}

export default DepartmentCard