import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// import moment from "moment";
import { useStyles } from "../styles";
import moment from "moment-timezone";
import { withNamespaces } from "react-i18next";

const KycInformationDetails = ({ data, t = () => false, setImageViewer }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.editContainer}>
        <div className={classes.contentGrp}>
          <Grid
            container
            sx={{ justifyContent: { xs: "center", sm: "flex-start" } }}
          >
            <Grid item xs={8} sm={8}>
              <Typography className={classes.title}>{data?.name}</Typography>
              <Box height={"5px"} />
              <Typography className={classes.cardSubTitle} noWrap>
                {data?.type}
              </Typography>
              <Box height={"5px"} />
              <Typography className={classes.cardSubTitle} noWrap>
                {data?.number}
              </Typography>
              <Box height={"7px"} />
              <Typography className={classes.label}>
                &#x202a;{moment(data?.valid_from)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}&#x202c;
                {""} -{" "}
                &#x202a;{moment(data?.valid_to)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}&#x202c;
                <div className={classes.dot}></div> {data?.country_name}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Box className={classes.imgGrpp}>
                <AvatarGroup max={4} onClick={() => setImageViewer(true)}>
                  {data?.kyc_assets?.map((val) => (
                    <Avatar
                      alt=""
                      className={classes.cardImgGrp}
                      src={["image", "image/jpeg", "image/png", "image/svg"].includes(val?.type) ? val?.src : "/images/kycPdf.svg"}
                    />
                  ))}
                </AvatarGroup>
                <Stack
                  alignItems={"flex-end"}
                  direction={"row"}
                  justifyContent={"flex-end"}
                >
                  <Box mt={"16px"}>
                    {data.is_valid === true ? (
                      <Typography className={classes.typeValid}>{t("Valid")}</Typography>
                    ) : (
                      <Typography className={classes.typeExpired}>{t("Expired")}</Typography>
                    )}
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default withNamespaces("inspection")(KycInformationDetails);
