import React from "react";
import FileManager from './fileManager';
import { withBottombar } from "../../HOCs";

class FileManagerParent extends React.Component {
    render() {
        return <FileManager {...this.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }
  

export default withBottombar(FileManagerParent, props);
