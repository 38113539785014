import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useParams } from "react-router-dom";
import { DocumentViewer, QuotationHistory } from "../../components";
import { Quotation } from "../../components/quotation";
import { QuotationCard } from "../../components/quotationCard";
import { TopNavBars } from "../../components/topnavbar";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.tertiary1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  screen: {
    borderRadius: "4px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.secondary,
  },
  topNavBarStyle: {
    // padding: "0px 16px 0px 16px",
  },
  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  oldQuotationHistoryContainerStyle: {
    padding: "24px 16px 32px 16px",
    background: "white",
    borderRadius: "4px 4px 0px 0px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    position: "fixed",
    width: "-webkit-fill-available",
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    background: "white",
    borderRadius: "4px 4px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    background: "white",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "1rem",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  pdfDrawerBodyStyle: {
    background: theme.palette.background.tertiary,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    height: "400px",
    width: "inherit",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  pdfImageStyle: {
    width: "inherit",
  },
  drawerFooterStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontWeight: "normal",
    color: "#FFF",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
}));

const OldQuotationDetails = ({ t = () => false }) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  const { id } = useParams();
  React.useEffect(() => {
  });
  const [isQuotationHistoryOpen, setIsQuotationHistoryOpen] =
    React.useState(false);
  const quotationHistory = [
    {
      id: 0,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
    {
      id: 1,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
      status: true,
    },
    {
      id: 2,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
    {
      id: 3,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
    {
      id: 4,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
    },
  ];
  const oldQuotationHistory = [
    {
      id: 0,
      quotationId: "Quotation #2345",
      time: "Received on 30-01-2021 9:00 AM",
      status: true,
    },
  ];
  const data = {
    id: 1,
    title: "Quotation",
    body: "",
    body1: "",
    subBody: "Total amount :",
    subBody2: " $ 340,00,000",
  };
  const downloadData = {
    quotationNo: "2343",
    date: "22 Jan 2021",
    size: "4 MB",
    files: [
      "/images/quotation_2.png",
      "/images/quotation_2.png",
      "/images/quotation_2.png",
    ],
  };
  const closeQuotationHistory = () => {
    setIsQuotationHistoryOpen(false);
  };
  const openQuotationHistory = () => {
    setIsQuotationHistoryOpen(true);
  };
  const [isPdfOpen, setIsPdfOpen] = React.useState(false);
  const closePdf = () => {
    setIsPdfOpen(false);
  };
  const openPdf = () => {
    setIsPdfOpen(true);
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.screen}
        style={{ height: size?.height }}
        item
        xl={4}
        lg={6}
        md={8}
        sm={10}
        xs={12}
      >
        <Grid className={classes.topNavBarStyle}>
          <TopNavBars note={false} home={false} />
          {id}
        </Grid>
        <Grid
          className={classes.screenPadding}
          style={{ height: size?.height - 168 }}
        >
          <Box height="16px" />
          <QuotationCard
            onClick={openQuotationHistory}
            type="details"
            quotationNo="2345"
            generatedOn="24 Jan 21"
            expiresOn="30 Jan 21"
            leasePeriod="2 Years"
          />
          <Box height="8px" />
          <Quotation onClickDownload={openPdf} data={data} />
          <Box height="16px" />
        </Grid>
        <Grid
          xl={4}
          lg={6}
          md={8}
          sm={10}
          xs={12}
          className={classes.oldQuotationHistoryContainerStyle}
        >
          {oldQuotationHistory.map((item, index, array) => {
            return (
              <Grid item>
                <QuotationHistory
                  data={item}
                  index={index}
                  length={array.length}
                />
              </Grid>
            );
          })}
        </Grid>
        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            fullWidth={true}
            maxWidth="sm"
            open={isQuotationHistoryOpen}
            onClose={closeQuotationHistory}
          >
            <Grid container direction="column" className={classes.dialogStyle}>
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("QuotationHistory")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={closeQuotationHistory}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.drawerBodyStyle}>
                {quotationHistory.map((item, index, array) => {
                  return (
                    <Grid item>
                      <QuotationHistory
                        data={item}
                        index={index}
                        length={array.length}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            anchor="bottom"
            open={isQuotationHistoryOpen}
            onClose={closeQuotationHistory}
          >
            <Grid
              container
              direction="column"
              className={classes.bottomSheetStyle}
            >
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("QuotationHistory")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={closeQuotationHistory}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.drawerBodyStyle}>
                {quotationHistory.map((item, index, array) => {
                  return (
                    <Grid item>
                      <QuotationHistory
                        t={t}
                        data={item}
                        index={index}
                        length={array.length}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            fullWidth={true}
            maxWidth="sm"
            open={isPdfOpen}
            onClose={closePdf}
          >
            <Grid container direction="column" className={classes.dialogStyle}>
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("Quotation") + "#" + downloadData.quotationNo}
                  </Typography>
                  <Box height="3px" />
                  <Grid container alignItems="center">
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                      }}
                    >
                      {downloadData.date}
                    </Typography>
                    <Box className={classes.periodStyle} />
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {downloadData.size}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={closePdf}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.pdfDrawerBodyStyle}>
                <DocumentViewer url="https://storage.healthcircles.care/docs/v1/file/8803bdf0-a94e-4433-ad51-376ef2a2d4f5.pdf" />
              </Grid>
              <Grid className={classes.drawerFooterStyle}>
                <Box height={"6px"} />
                <Button
                  fullWidth
                  className={classes.drawerButtonStyle}
                  variant="contained"
                >
                  {t("Download")}
                </Button>
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            anchor="bottom"
            open={isPdfOpen}
            onClose={closePdf}
          >
            <Grid
              container
              direction="column"
              className={classes.bottomSheetStyle}
            >
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("Quotation #") + downloadData.quotationNo}
                  </Typography>
                  <Box height="3px" />
                  <Grid container alignItems="center">
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                      }}
                    >
                      {downloadData.date}
                    </Typography>
                    <Box className={classes.periodStyle} />
                    <Typography
                      style={{
                        fontSize: "0.75rem",
                        color: "#4E5A6B",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {downloadData.size}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={closePdf}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.pdfDrawerBodyStyle}>
                <DocumentViewer url="https://storage.healthcircles.care/docs/v1/file/8803bdf0-a94e-4433-ad51-376ef2a2d4f5.pdf" />
              </Grid>
              <Grid className={classes.drawerFooterStyle}>
                <Box height={"6px"} />
                <Button
                  fullWidth
                  className={classes.drawerButtonStyle}
                  variant="contained"
                >
                  {t("Download")}
                </Button>
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>
      </Grid>
    </div>
  );
};
export default withNamespaces("quotation")(OldQuotationDetails)