import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalculate } from "../../../utils";

export const UpcomingBookingCardStyle = makeStyles((theme) => ({
    box: {
        background: "#fff",
        borderRadius: 12,
        boxShadow: "0px 4px 10px #43547224",
        padding: 16,
        minWidth: (props) => props?.dimension?.width >= 600 ? 552 : props?.dimension?.width - 50,
    },
    avatar: {
        height: 74,
        width: 74,
        borderRadius: 8
    },
    title: {
        fontSize: remCalculate(16),
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    subTitle: {
        fontSize: remCalculate(12),
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold
    }
}))

export const PopularServiceCardStyle = makeStyles((theme) => ({
    box: {
        height: 150,
        borderRadius: "50px",
        minWidth: 96,
        boxShadow: "0px 4px 30px #43547224",
        backgroundImage: (props) => `url(${props?.url})`,
        //  `url(${"/images/p1.svg"})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    textBox:{
        position: "absolute",
        bottom: 30
    },
    text:{
        fontSize: remCalculate(12),
        color: "#fff",
        fontFamily: FontFamilySwitch().regular
    }
}))