export const SupportIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 108907">
      <path data-name="Rectangle 55935" fill="none" d="M0 0h40v40H0z" />
      <path
        data-name="Path 99708"
        d="M13.7 30.2h16.1l4.2 4.2V19.7a2.808 2.808 0 0 0-2.8-2.8H13.7a2.808 2.808 0 0 0-2.8 2.8v7.7a2.808 2.808 0 0 0 2.8 2.8Z"
        fill="#558b2f"
      />
      <path
        data-name="Path 99709"
        d="M23.36 25.16h-2.52l-.49 1.47h-1.54l2.59-7h1.33l2.59 7h-1.54Zm-2.17-1.12h1.75l-.84-2.66Z"
        fill="#1b5e20"
      />
      <path
        data-name="Path 99710"
        d="M26.3 19.7H10.2L6 23.9V7.8A2.808 2.808 0 0 1 8.8 5h17.5a2.808 2.808 0 0 1 2.8 2.8v9.1a2.808 2.808 0 0 1-2.8 2.8Z"
        fill="#8bc34a"
      />
      <path
        data-name="Path 99711"
        d="M20.98 12.14a4.323 4.323 0 0 1-.35 1.75 2.6 2.6 0 0 1-.91 1.19l1.19.91-.91.84-1.54-1.19c-.14 0-.35.07-.56.07a3.964 3.964 0 0 1-1.26-.21 3.155 3.155 0 0 1-.98-.7 2.92 2.92 0 0 1-.63-1.12 4.654 4.654 0 0 1-.21-1.47v-.28a4.654 4.654 0 0 1 .21-1.47 2.92 2.92 0 0 1 .63-1.12 2.511 2.511 0 0 1 .98-.7 3.391 3.391 0 0 1 1.26-.21 3.964 3.964 0 0 1 1.26.21 3.155 3.155 0 0 1 .98.7 2.92 2.92 0 0 1 .63 1.12 4.654 4.654 0 0 1 .21 1.47v.21Zm-1.54-.35a2.691 2.691 0 0 0-.42-1.68 1.2 1.2 0 0 0-1.12-.56 1.271 1.271 0 0 0-1.12.56 2.986 2.986 0 0 0-.42 1.68v.35a4.131 4.131 0 0 0 .14.98 1.625 1.625 0 0 0 .28.7.941.941 0 0 0 .49.42 2.044 2.044 0 0 0 .63.14 1.271 1.271 0 0 0 1.12-.56 3.078 3.078 0 0 0 .42-1.75Z"
        fill="#fff"
      />
    </g>
  </svg>
)
