import {
  Badge,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  CarouselSliders,
  DialogDrawer,
  TextBox,
} from "../../components";

import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
import StraightIcon from "@mui/icons-material/Straight";
import { Close } from "@mui/icons-material";
import PropertyCard from "./components/propertyCard";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { NetWorkCallMethods } from "../../utils/constants";
import { setDate } from "date-fns";
import FilterList from "./components/filterList";
// import { CustomMap} from "../../components/map";
import UnitView from "./components/unitView";
import ContactSales from "./components/contactSales";
import { Routes } from "../../router/routes";
import { CustomMap } from "../../components/map";
import { withBottombar } from "../../HOCs";
const FilteredPropertyList = (props) => {
  const { handleTopBarState, t = () => false } = props
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  let fullSize = size?.height - 229;
  const [data, setdata] = React.useState("");
  const [selectedProperty, setSelectedProperty] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [sqftFilter, setSqftFilter] = React.useState(false);
  const [listView, setListView] = React.useState(true);
  const [rateFilter, setRateFilter] = React.useState(false);
  const [banners, setBanners] = React.useState([]);
  const goMap = () => {
    setListView((data) => !data);
  };
  const [drawer, setDrawer] = React.useState(false);
  const [unitDrawer, setUnitDrawer] = React.useState(false);

  const [contactSalesDrawer, setContactSalesDrawer] = React.useState(false);
  const cardData = [
    {
      id: 1,
      images: [
        "hotels.jpg",
        "bgNew.png",
        "Asset 6new.png",
        "NoPath - Copy.png",
        "Asset 6new.png",
        "hotels.jpg",
      ],
      propertyID: "P-F02-U15",
      propertyType: "Apartment",
      originalRate: "$ 1,200.00/mo",
      offerRate: "$ 650.00/mo",
      sqft: "1250 Sq ft",
      baths: "1",
      bed: "2",
      floor: "2 Floor",
      apartmentSize: "2 BHK",
      furnishing: "Furnished",
      propertyName: "Sanctuary Falls, Jumeirah Golf Estate",
      address: "Miraclz Tower by Danube, Arjan, Dubai",
      propertyAddress: "2nd Floor, K Tower, D Block",
      location: "Arjan, Dubai",
      buldingNo: "K Tower, 1 Floor",
      shortlisted: "12 Shortlisted",
      apartment: "Rubix Apartment",
      basicRent: "$1000",
      maintanence: "$100",
      deposit: "$1000",
      amenities: [
        { name: "Free WiFi", icon: "wifi.svg" },
        { name: "Car Parking", icon: "carParking.svg" },
        { name: "24 hrs CCTV", icon: "wifi.svg" },
        { name: "AC", icon: "wifi.svg" },
      ],
    },
    {
      id: 2,
      // images:["bgNew.png","hotels.jpg","Asset 6new.png", "NoPath - Copy.png"],
      images: ["bgNew.png", "Asset 6new.png", "NoPath - Copy.png"],
      propertyID: "P-F02-U25",
      propertyType: "Villa",
      originalRate: "$ 1,200.00/mo",
      // offerRate: "$ 650.00/mo",
      sqft: "1250 Sq ft",
      bed: "2",
      baths: "1",
      floor: "2 Floor",
      apartmentSize: "2 BHK",
      furnishing: "Furnished",
      propertyName: "Sanctuary Falls, Jumeirah Golf Estate",
      address: "Miraclz Tower by Danube, Arjan, Dubai",
      propertyAddress: "2nd Floor, K Tower, D Block",
      location: "Arjan, Dubai",
      buldingNo: "K Tower, 1 Floor",
      // shortlisted: "12 Shortlisted",
      apartment: "Rubix Apartment",
      basicRent: "$1000",
      maintanence: "$100",
      deposit: "$1000",
      amenities: [
        { name: "Free WiFi", icon: "wifi.svg" },
        { name: "Car Parking", icon: "carParking.svg" },
        { name: "24 hrs CCTV", icon: "wifi.svg" },
        { name: "AC", icon: "wifi.svg" },
      ],
    },
    {
      id: 3,
      // images:[ "Asset 6new.png","hotels.jpg","bgNew.png", "NoPath - Copy.png"],
      images: ["NoPath - Copy.png", "hotels.jpg",],
      propertyID: "P-F02-U10",
      propertyType: "Villa",
      originalRate: "$ 1,200.00/mo",
      offerRate: "$ 600.00/mo",
      sqft: "1150 Sq ft",
      baths: "1",
      bed: "3",
      floor: "2 Floor",
      apartmentSize: "2 BHK",
      furnishing: "Furnished",
      propertyName: "Sanctuary Falls, Jumeirah Golf Estate",
      address: "Miraclz Tower by Danube, Arjan, Dubai",
      propertyAddress: "2nd Floor, K Tower, D Block",
      location: "Arjan, Dubai",
      buldingNo: "K Tower, 1 Floor",
      shortlisted: "12 Shortlisted",
      apartment: "Topas Apartment",
      basicRent: "$1000",
      maintanence: "$100",
      deposit: "$1000",
      amenities: [
        { name: "Free WiFi", icon: "wifi.svg" },
        { name: "Car Parking", icon: "carParking.svg" },
        { name: "24 hrs CCTV", icon: "wifi.svg" },
        { name: "AC", icon: "wifi.svg" },
      ],
    },
  ];

  const { state } = useLocation();
  const filtredData = state?.filtredData;
  if (!filtredData) {
    history.replace("/propertySearch");
  }
  const app = config?.app_x_build === "PG-TE-08" ? "Tenant2" : "Tenant"

  const logo = JSON.parse(localStorage?.getItem("accessType"))?.filter(
    (val) => val?.name === app
  );
  const addProperty = (value, type) => {
    if (type === "add") {
      setSelectedProperty((data) => [...data, value]);
    } else {
      let array = selectedProperty;
      // let index = array?.id.indexOf(value);
      let index = array.findIndex((x) => x.id === value);
      array.splice(index, 1);
      setSelectedProperty(array);
    }
  };
  const [unitViewData, setUnitViewData] = React.useState([]);
  const openUnitDrawer = (id) => {
    setUnitDrawer(true);
    let result = cardData.find((item) => item.id === id);
    setUnitViewData(result);
  };
  const openContactSalesDrawer = () => {
    setContactSalesDrawer(true);
  };
  const closeContactSalesDrawer = () => {
    setContactSalesDrawer(false);
  };
  React.useEffect(() => {
    handleTopBarState({
      text: "Property List"
    })
    setdata(filtredData?.location);

    let data = {
      tenantId: `${config.tenantid}`,
      company_id: logo?.map((val) => val?.company_id),
      offset: 0,
      type: "Dashboard",
      limit: 1000,
    };

    NetworkCall(
      `${config.api_url}/company-banner/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then(
      (response) => {
        let bannerss = response?.data?.data
          ?.map((item) => item?.assets)
          ?.flat();
        setBanners(bannerss);
      },
      [logo, filtredData?.location]
    );
    // eslint-disable-next-line
  }, []);
  const goShortList = () => {
    history.push({
      pathname: Routes.shortlistedUnits,
      state: {
        shortlisted: selectedProperty,
      },
    });
  };

  const [radius, setRadius] = React.useState(10);
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    setList([
      {
        id: "5a2c5498-da2b-4df5-9e35-2ee8370cead7",
        name: "Ruban properties",
        year_built: "2023-01-02T18:30:00.000Z",
        description: "",
        property_no: "ABC-489",
        company_id: 69,
        logo: "https://protomate.objectstore.e2enetworks.net/ed345407-3e8a-4baa-b451-11f671f1eb64/69/Thumbnails/1672827192562-download%20%281%29.jpeg",
        company: {
          name: "DNT Demo Company",
        },
        type: {
          id: 1675,
          group_name: "Apartment",
          description: null,
          company_id: 69,
          created_by: null,
          updated_by: null,
          created_at: "2022-10-08T18:48:26.802Z",
          is_active: true,
          updated_at: "2022-10-08T18:48:26.802Z",
          is_external: false,
          is_delete: false,
          settings: null,
          property_id: null,
          purpose: "Residential",
          client: "ed345407-3e8a-4baa-b451-11f671f1eb64",
        },
        assets: [],
        address: {
          id: "acd65941-d17b-4d1f-b307-f80bdd3d57e3",
          door_no: "",
          street_1: "148",
          street_2: "Rajiv Gandhi Salai",
          street_3: null,
          city: "",
          district: "",
          state: "Tamil Nadu",
          zipcode: "600097",
          po_box: null,
          country: "India",
          mobile_no_country_code: "+91",
          mobile_no: "5077287363",
          email_id: "info@dntdemo.com",
          latitude: "12.942548359423004",
          longitude: "80.23721523843186",
          phone: null,
          landmark: "",
          area: "Besides Park Plaza Hotel",
          geom: "0101000020E61000005FCBD2882E0F5440923DD5B295E22940",
        },
        facilities: [],
        units: [
          {
            id: "1a692f89-d32a-4e0e-b98a-ded53b807270",
            name: "Ruban Lease Unit",
            property_id: "5a2c5498-da2b-4df5-9e35-2ee8370cead7",
            block_id: null,
            floor_id: null,
            carpet_area: 1000,
            total_area: 1002,
            total_bed_rooms: 1,
            room_rent: null,
            currency_id: 3,
            lease_or_sale_value: 12120,
            monthly_lease_value: null,
            daily_lease_value: null,
            RERA_MIN_AREA: null,
            RERA_MAX_AREA: null,
            RERA_MIN_BUDGET: null,
            RERA_MAX_BUDGET: null,
            primary_contact: null,
            is_active: true,
            created_by: null,
            updated_by: null,
            created_at: "2023-01-04T10:14:24.649Z",
            updated_at: "2023-01-04T10:14:24.649Z",
            is_room: false,
            unit_type: 2250,
            vat_group: null,
            is_external: false,
            area_metric: null,
            parent_unit_id: null,
            unit_purpose: "Residential",
            furnishing: "Unfurnished",
            status: "Vacant",
            revenue_type: "Lease",
            unit_category: null,
            is_listing: null,
            listing_status: "None",
            company_id: 69,
            is_pet_allowed: null,
            year_built: "2023-01-03T00:00:00.000Z",
            description: "",
            orientation: "None",
            pet_policy: "Not Allowed",
            total_rooms: 1,
            total_baths: 1,
            govt_utility_no: null,
            planned_hand_over_date: null,
            address_id: "75cb89a7-cc60-48e6-99b9-78e406e2df95",
            util_natural_gas_connection: null,
            logo: "https://protomate.objectstore.e2enetworks.net/ed345407-3e8a-4baa-b451-11f671f1eb64/69/Thumbnails/1672827192562-download%20%281%29.jpeg",
            unit_no: "UNT202301-491",
            client: "ed345407-3e8a-4baa-b451-11f671f1eb64",
            payment_period: "Monthly",
            balconies: 10,
            balcony_area: 10,
            owner_id: null,
            uom_id: 3,
            build_up_area: 1001,
            terrace_area: 10,
            unit_category_id: 2,
            is_asset_functional_location: true,
            is_asset_property: true,
            is_short_term_rental: true,
            is_under_promotion: true,
            execution_status: "Release",
            unit_vacancy: [
              {
                available_from: "2023-01-04T00:00:00.000Z",
                available_to: "2999-01-04T00:00:00.000Z",
                status: "Vacant",
                is_active: true,
              },
            ],
            amenities: [],
            type: {
              id: 2250,
              created_at: "2022-10-08T18:48:26.814Z",
              updated_at: "2022-10-08T18:48:26.814Z",
              created_by: null,
              updated_by: null,
              name: "1 BHK",
              is_external: false,
              is_active: true,
              is_delete: false,
              beds: null,
              baths: null,
              configured_units: null,
              settings: null,
              company_id: 69,
              purpose: "Residential",
              client: "ed345407-3e8a-4baa-b451-11f671f1eb64",
            },
          },
        ],
      },
    ]);
  }, []);
  const changeKms = (kms) => {
    setRadius(kms);
    setLoader(true);
  };
  const handleDetails = (event, value) => {
    // setAnchorEl(propetryPopover.current)
    // setSelectedProperty(value)
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={"Property List"} goBack={() => history.goBack(-1)} /> */}
      <div className={classes.mainPadding} style={{ paddingBottom: "4px" }}>
        <Box className={classes.filterContainer}>
          <Box className={classes.filterBlock} flexGrow={1}>
            <TextBox
              isRequired
              startAdornment={
                <img src="/images/icons8-search%20(2).svg" alt="" />
              }
              placeholder={t("Enter location")}
              value={data}
              onChange={(value) => setDate(value.target.value)}
            />
          </Box>
          <Box className={classes.filterBlock2} onClick={() => setDrawer(true)}>
            <Badge color="primary" variant="dot">
              <img src="/images/funnel.svg" alt="" />
            </Badge>
          </Box>
        </Box>
      </div>
      <Box height={"13px"} />
      <div className={classes.container}>
        <Chip
          className={classes.chip}
          deleteIcon={<Close color="#98A0AC" fontSize="small" />}
          label={filtredData?.bed?.label + " BHK"}
          onDelete={() => { }}
          variant="outlined"
        />
        <Chip
          className={classes.chip}
          deleteIcon={<Close fontSize="14px" />}
          label={filtredData?.furnishing?.label}
          onDelete={() => { }}
          variant="outlined"
        />
        <Chip
          className={classes.chip}
          deleteIcon={<Close fontSize="14px" />}
          label={filtredData?.baths?.label + " Baths"}
          onDelete={() => { }}
          variant="outlined"
        />
        {/* <Chip
          className={classes.chip}
          deleteIcon={<Close fontSize="14px" />}
          label={
            "$ " + filtredData?.minRange + " - $ " + filtredData?.maxRange
          }
          onDelete={() => { }}
          variant="outlined"
        /> */}
        <Box height={"12px"} />
        <Stack direction="row" spacing={1} alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.subTitle}>
              {cardData?.length} {t("Properties")}
            </Typography>
          </Box>
          {listView && (
            <Box>
              <Stack direction="row" spacing={2}>
                <Stack
                  className={
                    sqftFilter ? classes.filterChipActive : classes.filterChip
                  }
                  direction="row"
                  spacing={0}
                  onClick={() => setSqftFilter((data) => !data)}
                >
                  <Typography
                    className={
                      sqftFilter ? classes.chipTextActive : classes.chipText
                    }
                  >
                    {t("Sq ft")}
                  </Typography>
                  <StraightIcon
                    sx={{ transform: !sqftFilter ? "rotate(180deg)" : "" }}
                    color="#4E5A6B"
                    fontSize="small"
                  />
                </Stack>
                <Stack
                  className={
                    rateFilter ? classes.filterChipActive : classes.filterChip
                  }
                  direction="row"
                  spacing={0}
                  onClick={() => setRateFilter((data) => !data)}
                >
                  <Typography
                    className={
                      rateFilter ? classes.chipTextActive : classes.chipText
                    }
                  >
                    ${" "}
                  </Typography>
                  <StraightIcon
                    sx={{ transform: !rateFilter ? "rotate(180deg)" : "" }}
                    color="#4E5A6B"
                    fontSize="small"
                  />
                </Stack>
              </Stack>
            </Box>
          )}
        </Stack>
      </div>
      <div
        className={classes.mainContainer}
        style={{ height: fullSize, overflow: "auto" }}
      >
        {listView ? (
          <>
            {cardData?.map((data, index) => (
              <>
                <PropertyCard
                  t={t}
                  key={index}
                  data={data}
                  unitDrawerFun={openUnitDrawer}
                  selectFun={addProperty}
                  selectedData={selectedProperty}
                />
                {index === 1 && (
                  <>
                    <CarouselSliders autoScroll={true} data={banners} />
                    <Box height={"13px"} />
                  </>
                )}
              </>
            ))}
          </>
        ) : (
          // <MyGoogleMap />
          !loader && (
            <CustomMap
              handleDetails={handleDetails}
              changeKms={changeKms}
              lat={12.942548359423004}
              lang={80.23721523843186}
              radius={radius}
              list={list}
            />
          )
        )}
      </div>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Box sx={{ display: "flex", float: "right" }}>
            {selectedProperty?.length <= 0 ? (
              <Box className={classes.centerPosition}>
                {listView ? (
                  <Button
                    fullWidth
                    startIcon={<img src="/images/mapIcon.svg" alt="" />}
                    className={classes.mapBtn}
                    variant="contained"
                    onClick={() => goMap()}
                  >
                    {t("Map View")}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    startIcon={<img src="/images/listView.svg" alt="" />}
                    className={classes.mapBtn}
                    variant="contained"
                    onClick={() => goMap()}
                  >
                    {t("List View")}
                  </Button>
                )}
              </Box>
            ) : (
              <Box className={classes.centerPosition2}>
                <Box className={classes.shortlistedContainer}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        <img
                          style={{ width: "10px", height: "10px" }}
                          className={classes.propertyImg}
                          src="/images/addHomeIcon.svg"
                          alt=" "
                        />
                        <Typography className={classes.smText}>
                          {t("SHORTLISTED")}
                        </Typography>
                      </Stack>
                      <Box height={"7px"} />
                      <Stack direction={"row"} spacing={1} alignItems="center">
                        <Typography className={classes.title}>
                          {selectedProperty?.length < 9
                            ? "0" + selectedProperty?.length
                            : selectedProperty?.length}
                        </Typography>
                        <Typography
                          className={classes.smText}
                          sx={{ color: "#98A0AC" }}
                        >
                          {t("Unit")}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        className={classes.search}
                        variant="contained"
                        onClick={() => goShortList()}
                        noWrap
                      >
                        {t("View Shortlist")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <DialogDrawer
        open={drawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={<FilterList t={t} />}
      />
      <DialogDrawer
        open={unitDrawer}
        isnotTitle={false}
        header={"Unit"}
        padding={0}
        overflow={"overlay"}
        onClose={() => setUnitDrawer(false)}
        onOpen={() => setUnitDrawer(true)}
        component={
          <UnitView
            t={t}
            openContactSalesDrawer={openContactSalesDrawer}
            data={unitViewData}
          />
        }
      />
      <DialogDrawer
        open={contactSalesDrawer}
        header={"Contact Sales"}
        onClose={() => closeContactSalesDrawer()}
        onOpen={() => openContactSalesDrawer()}
        isnotTitle={false}
        component={<ContactSales t={t} />}
      />{" "}
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("filterPropertySearch")(FilteredPropertyList), props);
