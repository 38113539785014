import * as React from "react"
const AmenityViewBooking = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 114311">
      <path
        fill="#5078e1"
        d="M5.148 3a2.139 2.139 0 0 0-2.139 2.139V14c-.1 1.421.579 2.94 2.058 2.994a.583.583 0 0 0 .081 0h9.722a1.975 1.975 0 0 0 1.571-.867 3.318 3.318 0 0 0 0-3.711 1.975 1.975 0 0 0-1.571-.867h-.194v-6.41A2.139 2.139 0 0 0 12.537 3Zm.777 1.944h1.167a.584.584 0 0 1 0 1.167H5.925a.584.584 0 0 1 0-1.167Zm3.111 0h2.722a.584.584 0 1 1 0 1.167H9.037a.584.584 0 1 1 0-1.167ZM5.925 7.278h2.722a.584.584 0 0 1 0 1.167H5.925a.584.584 0 1 1 0-1.167Zm4.667 0h1.167a.584.584 0 1 1 0 1.167h-1.167a.584.584 0 0 1 0-1.167ZM5.925 9.611h2.722a.584.584 0 0 1 0 1.167H5.925a.584.584 0 1 1 0-1.167Zm-.778 3.111c1.272 0 1.272 3.111 0 3.111s-1.271-3.111.001-3.111Zm1.8 0h7.918a.788.788 0 0 1 .628.386 2.16 2.16 0 0 1 0 2.339.788.788 0 0 1-.628.386H6.953a3.627 3.627 0 0 0 0-3.111Z"
        data-name="icons8-activity-history (1)"
      />
    </g>
  </svg>
)
export default AmenityViewBooking
