import {
    Box, Stack, Grid, Typography, Drawer, Hidden,
    Dialog, IconButton, Container, Button, Avatar
} from '@mui/material'
import React from 'react'
import AvailableSlotSVG from '../../assets/availableSlot'
import Slots from '../../components/slots'
import { DetailCard } from './components'
import { AvailableSlotStyle } from './style'
import BookingPreview from './bookingPreview'
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useHistory, useLocation } from 'react-router-dom'
import { eachDayOfInterval, addDays, subDays, format } from 'date-fns'
import moment from 'moment'
import { AlertProps, generateImageUrl, img_size, NetWorkCallMethods } from '../../utils'
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { AlertContext, AuthContext } from '../../contexts'
import { Routes } from '../../router/routes'
import MyBookingSvg from '../../assets/mybooking'
import { withNamespaces } from 'react-i18next'
import useWindowDimensions from '../../utils/useWindowDimensions'


const AvailableSlot = ({ t = () => false }) => {
    // 10 days function
    const Dates = eachDayOfInterval({
        start: new Date(),
        end: new Date(addDays(new Date(), 9))
    })

    const auth = React.useContext(AuthContext)
    const state = useLocation()?.state
    const classes = AvailableSlotStyle({ language: auth?.auth?.auth?.language })
    const history = useHistory()
    const alert = React.useContext(AlertContext);
    const size = useWindowDimensions();
    const [bpOpen, setbpOpen] = React.useState(false)
    // const [scroll, setScroll] = React.useState(false)
    const [data, setData] = React.useState(
        {
            selected_Date: state?.selectedDate,
            availableSlot: [],
            response: {},
            selected_slot: [],
            slotIndex: null,
            amenityLogo: []
        })

    const openBookingPreview = () => {
        setbpOpen(true)
    }

    //  select slots
    const selectSlot = (value, index) => {
        let selectedIndex = data?.selected_slot?.map(e => {
            return e.index
        })

        if (selectedIndex?.includes(index)) {
            setData({
                ...data,
                selected_slot: [],
                slotIndex: null
            })
        }
        else {
            if (data?.selected_slot?.length === 0) {
                setData({
                    ...data,
                    selected_slot: [{ value, index }],
                    slotIndex: index
                })
            } else {
                if (value?.check_in_date) {
                    if (moment(addDays(new Date(data?.selected_slot[0]?.value?.check_out_date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date).format("YYYY-MM-DD")) {
                        setData({
                            ...data,
                            selected_slot: [{ value, index }, ...data?.selected_slot],
                            slotIndex: index
                        })
                    }
                    else if (moment(subDays(new Date(data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_date), 1)).format("YYYY-MM-DD") === moment(value?.check_in_date).format("YYYY-MM-DD")) {
                        setData({
                            ...data,
                            selected_slot: [...data?.selected_slot, { value, index }],
                            slotIndex: index
                        })
                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Onlyconsecutiveslotscanbebooked"),
                        });
                    }
                }
                else {
                    if ((Number((data?.selected_slot[0]?.value?.check_in_time).slice(0, 2))) === (Number((value?.check_out_time).slice(0, 2)))) {
                        setData({
                            ...data,
                            selected_slot: [{ value, index }, ...data?.selected_slot],
                            slotIndex: index
                        })
                    } else if ((Number((data?.selected_slot[data?.selected_slot?.length - 1]?.value?.check_out_time).slice(0, 2))) === Number((value?.check_in_time).slice(0, 2))) {
                        setData({
                            ...data,
                            selected_slot: [...data?.selected_slot, { value, index }],
                            slotIndex: index
                        })
                    }
                    else {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("Onlyconsecutiveslotscanbebooked"),
                        });
                    }
                }
            }
        }
    }
    // close popup
    const onCLoseDrw = () => {
        setbpOpen(!bpOpen)
    }
    const back = () => {
        history.goBack()
    }
    const navigate = () => {
        history.push({
            pathname: Routes.success,
            state: {
                customLabel:"Booking Successful"
            }
        })
    }
    const mybookingNavigate = () => {
        history.push({
            pathname: Routes.myBookingList,
            state: { facilityId: state?.amenity_data?.facility_id }
        })
    }
    // Select date from date scroll
    const selectedBookingDate = (date) => {
        getAmenitiesBooking(date)
    }
    React.useEffect(() => {
        if (state?.type === "direct") {
            getAmenitiesBooking(data?.selected_Date)
        } else {
            getAmenitiesBooking(data?.selected_Date)
        }
        // eslint-disable-next-line
    }, [])

    // Get amenities Booking 
    const getAmenitiesBooking = (date) => {
        let payload = {}
        if (state?.type === "direct") {
            payload = {
                property_id: state?.selectedProperty?.property_id,
                facility_id: state?.data?.facility_id,
                amenities_type: state?.data?.amenities_type,
                selected_date:  moment(date).format("YYYY-MM-DD"),
                period: state?.data?.period,
                current_date: format(new Date(),"yyyy-MM-dd HH:mm:ss") 
            }
        } else {
            payload = {
                property_id: state?.propertyId,
                facility_id: state?.amenity_data?.facility_id,
                amenities_type: state?.amenity_data?.amenities_type,
                selected_date: moment(date).format("YYYY-MM-DD"),
                period: state?.amenity_data?.period,
                current_date: format(new Date(),"yyyy-MM-dd HH:mm:ss") 
            };
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenities_for_booking`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let constAvailableDates = response?.data?.data?.available_slots?.map((e) => {
                    return response?.data?.data?.amenity[0].period === "Hourly" ?
                        {
                            "check_in_time": e[0],
                            "check_out_time": `${parseInt(e[1].split(":")?.[0]) + 1}:00:00`.padStart(7, '0'),
                            "check_in_value": e[0],
                            "check_out_value": e[1],
                        } :
                        {
                            "check_in_date": e,
                            "check_out_date": e
                        }
                })



                setData({
                    ...data,
                    availableSlot: constAvailableDates ?? [],
                    response: response?.data?.data,
                    selected_Date: new Date(date),
                    selected_slot: [],
                    slotIndex: null,
                    amenityLogo: response?.data?.data?.amenity[0]?.amenity_assets[0] ?? ""
                })
            })
            .catch((error) => {
                setData({
                    ...data,
                    availableSlot: [],
                    response: [],
                    selected_Date: new Date(date),
                    selected_slot: [],
                    slotIndex: null
                })
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("NoslotsavailableforBooking"),
                });
            });
    };

    return (
        <Container className={classes.root} maxWidth="sm">
            <Box sx={{ height: "100vh" }} >
                <Stack className={classes.navigate} direction={"row"} justifyContent={"space-between"}>
                    <IconButton className={classes.backbtn} onClick={back}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>

                    <Stack direction={"row"} justifyContent={"space-between"}
                        alignItems={"center"}
                        className={classes.bookingbtn} onClick={mybookingNavigate}>
                        <Stack direction={"row"} spacing={0.3}>
                            <MyBookingSvg></MyBookingSvg>
                            <Typography className={classes.bookingText}>{t("MyBooking")}</Typography>
                        </Stack>
                        <KeyboardArrowRightIcon />
                    </Stack>
                </Stack>
                <Box sx={{ height: 300 }}>
                    {/* {
                        state?.property_images?.length > 0 ?
                            <img src={state?.amenity_data?.assets?.[0] ?? data?.amenityLogo} alt="" className={classes.bannerImg} />
                            :
                            <Box sx={{ height: 300 }}>
                                <Typography>{t("NoImagesFound")}</Typography>
                            </Box>
                    } */}
                    <Avatar src={generateImageUrl(state?.amenity_data?.assets?.[0], img_size.medium_rectangle) ?? generateImageUrl(data?.amenityLogo, img_size.small_rectangle)} alt="" className={classes.bannerImg}></Avatar>
                </Box>
                <Box className={state?.amenity_data?.period === "Hourly" ? classes.hourDetailCard : classes.dailyDetailCard}>
                    <DetailCard
                        // back={back} scroll={scroll}
                        data={state?.amenity_data}
                        selecteDate={selectedBookingDate}
                        value={data?.selected_Date}
                        tabDate={Dates}
                    />
                </Box>

                <Box className={state?.amenity_data?.period === "Hourly" ? classes.hourSlotCard : classes.dailySlotCard}>
                    <center> {data?.availableSlot?.length > 0 && <AvailableSlotSVG />} </center>
                    <div style={{ height: size.height - (state?.amenity_data?.period === "Hourly" ? 450 :450), display: "flex", overflow: "overlay" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container pt={1} spacing={1}
                                    //  sx={{ height: "calc(100vh - 500px)" }}
                                    // className={scroll ? classes.fullslot : classes.slot}
                                    className={state?.amenity_data?.period === "Hourly" ? classes.hourSlotPanal : classes.dailySlotPanal} >

                                    {
                                        data?.availableSlot?.length > 0 ?
                                            data?.availableSlot?.map((item, index) => { 
                                                return (
                                                    <>

                                                        <Grid item xs={6}>
                                                            <Slots
                                                                data={item}
                                                                response={data?.response}
                                                                index={index}
                                                                onclick={selectSlot}
                                                                selctedslot={data?.selected_slot}
                                                                changeBg={true}
                                                            />
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                            :
                                            <Grid item xs={12}>
                                                <Typography className={classes.noData}>
                                                    {t("NoslotsavailableforBooking")}
                                                </Typography>
                                            </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <Box>
                        {
                            data?.selected_slot?.length > 0 &&
                            <Box className={classes.customSnackbar} p={1.4}>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography className={classes.snackBarMessage}> {data?.selected_slot?.length} Slot Selected</Typography>
                                    <Button onClick={openBookingPreview} variant={"contained"} >{t("Continue")}</Button>
                                </Stack>
                            </Box>
                        }
                    </Box>
                </Box>

                {/* Booking preview  */}
                <Hidden smDown>
                    <Dialog
                        className={classes.dialog}
                        maxWidth="sm"
                        fullWidth
                        open={bpOpen}
                        onClose={onCLoseDrw}
                    >
                        <div>{bookingPreview(onCLoseDrw, navigate, data?.selected_slot, data?.selected_Date, state, history)}</div>
                    </Dialog>
                </Hidden>
                <Hidden smUp>
                    <Drawer
                        anchor="bottom"
                        className={classes.drawer}
                        open={bpOpen}
                        onClose={onCLoseDrw}
                    >
                        <div>{bookingPreview(onCLoseDrw, navigate, data?.selected_slot, data?.selected_Date, state, history)}</div>
                    </Drawer>
                </Hidden>
            </Box>

        </Container>
    )
}

//booking preview modal
const bookingPreview = (onCLoseDrw, navigate, slots, selected_date, stateData,history) => {
    const classes = AvailableSlotStyle()

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className={classes.flexWithPadding}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.modalTitle}>
                            Booking Preview
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={onCLoseDrw}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} p={"16px 0px 0px"}>
                <BookingPreview
                    navigate={navigate}
                    slots={slots}
                    selectedDate={selected_date}
                    stateData={stateData}
                    onCLoseDrw={onCLoseDrw}
                    history={history} />
            </Grid>
        </Grid>
    )
}


export default withNamespaces("amenitiesBooking")(AvailableSlot)