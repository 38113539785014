import React from "react";

function Medical() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113448" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#f1f7ff"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#78b1fe"
          d="M14.308 4a.691.691 0 00-.428.148L7.32 9.317A3.462 3.462 0 006 12.036v7.425A2.538 2.538 0 008.538 22h11.539a2.538 2.538 0 002.538-2.538v-7.427A3.461 3.461 0 0021.3 9.317l-6.56-5.169A.694.694 0 0014.308 4zm1.592 6.462a2.826 2.826 0 011.969.8 2.733 2.733 0 01.824 1.949 2.763 2.763 0 01-.814 1.977l-2.53 2.539-.685.687a2.783 2.783 0 01-1.963.814 2.751 2.751 0 01-1.962-.814 2.721 2.721 0 01-.814-1.938 2.76 2.76 0 01.814-1.977c.039-.046.414-.424 1.587-1.6.67-.672 1.5-1.507 1.587-1.6l.035-.036a2.808 2.808 0 011.952-.801zm.023 1.4a1.431 1.431 0 00-1 .393c-.113.12-.5.505-1.482 1.495l1.959 1.962 1.493-1.5a1.389 1.389 0 00.411-.993 1.35 1.35 0 00-.406-.963 1.433 1.433 0 00-.976-.398z"
          transform="translate(43.692 244)"
        ></path>
      </g>
    </svg>
  );
}

export default Medical;