import * as React from "react";

const InspectionReport = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g
        id="Group_106593"
        data-name="Group 106593"
        transform="translate(-38 -308)"
      >
        <g
          id="Rectangle_55633"
          data-name="Rectangle 55633"
          transform="translate(38 308)"
          fill="#fff"
          stroke="#707070"
          stroke-width="1"
          opacity="0"
        >
          <rect width="20" height="20" stroke="none" />
          <rect x="0.5" y="0.5" width="19" height="19" fill="none" />
        </g>
        <path
          id="icons8-clipboard-list"
          d="M20.375,5.8h-1.6a2.02,2.02,0,0,0-2-1.8h-3.15a2.02,2.02,0,0,0-2,1.8h-1.6A2.027,2.027,0,0,0,8,7.825v12.15A2.027,2.027,0,0,0,10.025,22h10.35A2.027,2.027,0,0,0,22.4,19.975V7.825A2.027,2.027,0,0,0,20.375,5.8Zm-8.55,12.15a.675.675,0,1,1,.675-.675A.675.675,0,0,1,11.825,17.95Zm0-2.7a.675.675,0,1,1,.675-.675A.675.675,0,0,1,11.825,15.25Zm0-2.7a.675.675,0,1,1,.675-.675A.675.675,0,0,1,11.825,12.55ZM12.95,6.025a.676.676,0,0,1,.675-.675h3.15a.675.675,0,0,1,0,1.35h-3.15A.676.676,0,0,1,12.95,6.025ZM18.575,17.95h-4.05a.675.675,0,1,1,0-1.35h4.05a.675.675,0,0,1,0,1.35Zm0-2.7h-4.05a.675.675,0,1,1,0-1.35h4.05a.675.675,0,0,1,0,1.35Zm0-2.7h-4.05a.675.675,0,1,1,0-1.35h4.05a.675.675,0,0,1,0,1.35Z"
          transform="translate(32.999 305)"
          fill="#78b1fe"
        />
      </g>
    </svg>
  );
};

export default InspectionReport;
