import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "./style";
import { Progress, RequestDetails } from "../../../assets";
import { TimeLine } from "../component/timeLine";
import { AlertContext, BackdropContext } from "../../../contexts";
import { NetWorkCallMethods, AlertProps } from "../../../utils";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import moment from "moment";

export const RequestForRenewal = ({
  t = () => false,
  agreement_id,
  requestDetails,
  termination,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [terminationDetails, setTerminationDetails] = React.useState("");
  const backdrop = React.useContext(BackdropContext);
  // context
  const alert = React.useContext(AlertContext);
  const getDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const data = {
      agreement_id: agreement_id,
      agreement_request_id: requestDetails?.agreement_request_id ?? "",
    };
    NetworkCall(
      `${config.api_url}/agreement_request/get_agreement_request_status`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setState(response?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  const getTerminationDetails = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    const data = {
      agreement_request_id: requestDetails?.agreement_request_id ?? "",
    };
    NetworkCall(
      `${config.api_url}/agreement_request/get_move_out_status`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setTerminationDetails(response?.data?.move_out_res?.[0]);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((error) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  };
  React.useEffect(() => {
    if (termination) {
      getTerminationDetails();
    } else {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={2}>
      {termination ? (
        <>
          {terminationDetails?.status === "Approved" && (
            <Box>
              <Typography className={classes.requestStatusTitle}>
                {t("Request Status")}
              </Typography>
              <Box height={"8px"} />
              <Box className={classes.card1} onClick={() => setShow(!show)}>
                <Box className={classes.cardFlexBox}>
                  <Progress />
                  <Box width={"10px"} />
                  <Box>
                    <Typography className={classes.asPerAgreement}>
                      {terminationDetails?.status ?? ""}
                    </Typography>
                    <Typography className={classes.asPerAgreementSub1}>
                      {t("Remarks")} : {terminationDetails?.remarks ?? ""}
                    </Typography>
                  </Box>
                </Box>
                {show && (
                  <Box className={classes.treeParent}>
                    <TimeLine
                      data={[
                        {
                          remarks:
                            terminationDetails?.move_out_status?.status ?? "-",
                          created_at:
                            terminationDetails?.move_out_status?.updated_at ??
                            "",
                        },
                      ]}
                    />
                  </Box>
                )}
              </Box>
              <Box height={"16px"} />
              <Typography className={classes.requestStatusSubTitle}>
                {t("Request Details")}
              </Typography>
              <Box height={"13px"} />
            </Box>
          )}

          <Box>
            <Box display="flex" alignItems={"center"}>
              <RequestDetails />
              <Box width={"10px"} />
              <Box>
                <Typography className={classes.asPerAgreement}>
                  {terminationDetails?.request_details?.first_name ?? "-"}
                </Typography>
                <Typography className={classes.asPerAgreementSub1}>
                  {terminationDetails?.request_details?.reference_id ?? "-"},
                  {t("Requested on")}{" "}
                  {moment(
                    new Date(terminationDetails?.request_details?.requested_on)
                  ).format("DD MMM YY")}
                </Typography>
              </Box>
            </Box>
            <Box height={"12px"} />
            {/* <Box
          className={classes.statusBtn}
          style={{ color: "#23AE56", backgroundColor: "#E8F9E8" }}
        >
          Approved
        </Box> */}
            <Box height={"12px"} />
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography className={classes.asPerAgreementSub1}>
                    {t("START DATE")}
                  </Typography>
                  <Box height={"6px"} />
                  <Typography className={classes.asPerAgreement}>
                    {moment(
                      new Date(terminationDetails?.request_details?.start_date)
                    ).format("DD MMM YY")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.asPerAgreementSub1}>
                    {t("TERMINATION DATE")}
                  </Typography>
                  <Box height={"6px"} />
                  <Typography className={classes.asPerAgreement}>
                    {moment(
                      new Date(terminationDetails?.request_details?.termination_date)
                    ).format("DD MMM YY")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.requestStatusTitle}>
            {t("Request Status")}
          </Typography>
          <Box height={"8px"} />
          <Box className={classes.card1} onClick={() => setShow(!show)}>
            <Box className={classes.cardFlexBox}>
              <Progress />
              <Box width={"10px"} />
              <Box>
                <Typography className={classes.asPerAgreement}>
                  {state?.agreement_request?.[0]?.status}
                </Typography>
                {/* <Typography className={classes.asPerAgreementSub1}>
              Remarks : Unit count, the user should be able to view the units
            </Typography> */}
              </Box>
            </Box>
            {show && (
              <Box className={classes.treeParent}>
                <TimeLine data={state?.status_list ?? []} />
              </Box>
            )}
          </Box>
          <Box height={"16px"} />
          <Typography className={classes.requestStatusSubTitle}>
            {t("Request Details")}
          </Typography>
          <Box height={"13px"} />
          <Box>
            <Box display="flex" alignItems={"center"}>
              <RequestDetails />
              <Box width={"10px"} />
              <Box>
                <Typography className={classes.asPerAgreement}>
                  {state?.agreement_request?.[0]?.first_name ?? "-"}
                </Typography>
                <Typography className={classes.asPerAgreementSub1}>
                  {state?.agreement_request?.[0]?.reference_id ?? "-"},
                  {t("Requested on")}{" "}
                  {moment(
                    new Date(state?.agreement_request?.[0]?.requested_on)
                  ).format("DD MMM YY")}
                </Typography>
              </Box>
            </Box>
            <Box height={"12px"} />
            {/* <Box
          className={classes.statusBtn}
          style={{ color: "#23AE56", backgroundColor: "#E8F9E8" }}
        >
          Approved
        </Box> */}
            <Box height={"12px"} />
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography className={classes.asPerAgreementSub1}>
                    {t("NEW START DATE")}
                  </Typography>
                  <Box height={"6px"} />
                  <Typography className={classes.asPerAgreement}>
                    {moment(
                      new Date(state?.agreement_request?.[0]?.start_date)
                    ).format("DD MMM YY")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.asPerAgreementSub1}>
                    {t("NEW END DATE")}
                  </Typography>
                  <Box height={"6px"} />
                  <Typography className={classes.asPerAgreement}>
                    {moment(
                      new Date(state?.agreement_request?.[0]?.end_date)
                    ).format("DD MMM YY")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
