import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { FontFamilySwitch } from '../../utils';

const useStyles = makeStyles((theme) => ({
  arrowfeed: {
    borderRadius: "100%",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  iconfeed: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
    padding: "8px 12px 0px 12px"
  },
  arrowiconfeed: {
    fontSize: "1rem",
    color: "white",
    fontFamily: FontFamilySwitch().bold
  },
  feedcontainer: {
    backgroundImage: (props) =>
      props?.data?.type === "Marketplace"
        ? "url('/images/surveyBg1.svg')"
        : "url('/images/surveyBg2.svg')",
    borderRadius: theme.palette.borderRadius,
    padding: "16px",
    alignItems: "center",
    backgroundSize: "cover",
    cursor: "pointer",
    display: "flex"
  },
  feed: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  feedsub: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    fontStyle: "italic",
    marginTop: "10px",
  },
  surveyImage: {
    objectFit: "cover",
    height: "120px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
  }
}));
export const SurveyCard = (props) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.feedcontainer}>
      <Box className={classes.iconfeed}>
        {
          props?.data?.icon
        }
      </Box>
      <Box flexGrow={1} marginLeft={1}>
        <Typography className={classes.arrowiconfeed}>
          {props?.data?.name}
        </Typography>
      </Box>
      <Box>
        <ChevronRightIcon className={classes.arrowfeed} />
      </Box>
    </Box>
  );
};
