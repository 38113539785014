import React from "react";

function MutualFund() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113450" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#fef2e6"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#f5b977"
          d="M13.323 4a2.749 2.749 0 00-2.075.882 2.829 2.829 0 00-.694 1.888 2.888 2.888 0 00.4 1.385H8.982a2.544 2.544 0 00-2.457 1.9l-2.273 8.769A2.555 2.555 0 006.709 22h13.774a2.554 2.554 0 002.478-3.089l-1.949-8.769a2.545 2.545 0 00-2.478-1.988h-2.84a2.888 2.888 0 00.4-1.385 2.829 2.829 0 00-.694-1.887A2.749 2.749 0 0013.323 4zm0 1.385a1.276 1.276 0 011.04.416 1.533 1.533 0 010 1.936 1.276 1.276 0 01-1.04.416 1.276 1.276 0 01-1.04-.416 1.533 1.533 0 010-1.936 1.276 1.276 0 011.04-.416zm0 5.077a.691.691 0 01.692.692v.263a2.014 2.014 0 011.514 1.383.692.692 0 11-1.324.406.644.644 0 00-.623-.462l-.379.01a.814.814 0 00.014 1.629h.674a2.2 2.2 0 01.124 4.389V19a.692.692 0 11-1.385 0v-.259a2.021 2.021 0 01-1.523-1.375.692.692 0 011.321-.416.654.654 0 00.623.457l.849-.01a.814.814 0 00-.009-1.629h-.674a2.194 2.194 0 01-.586-4.311v-.3a.691.691 0 01.692-.695z"
          transform="translate(44.404 244)"
        ></path>
      </g>
    </svg>
  );
}

export default MutualFund;