import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "6px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    flexWrap:"wrap",
  },
  container: {
    width: "70px",
    height: "70px",
    borderRadius: "4px",
  },
  uploadContainer: {
    width: "70px",
    height: "70px",
    border: "1px solid #5078E1",
    backgroundColor: "#F1F7FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  },
  uplaodTitle: {
    fontSize: "0.75rem",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().bold,
    marginTop: "6px",
  },
  uploadedImg: {
    width: "100%",
    height: "70px",
    objectFit: "cover",
  },
  deleteImg: {
    width:"20px",
    height:"20px",
    position: "absolute",
    right: "0px",
    top: "0px",
    borderTopRightRadius: "4px",
    zIndex:"999"
  },
  uploadInput: {
    padding: "0px",
    position: "absolute",
    cursor: "pointer",
    height: "100%",
    width: "100%",
    opacity:0,
  },
}));
