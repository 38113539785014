import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from '../../contexts';
import { GET_AGREEMENTUNITACESS_DETAILS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    [theme.breakpoints.up("sm")]: {
      padding: "16px",

    },
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0px",
    },
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
    marginBottom: "14px",
  },
  cardContent: {
    backgroundColor: "white",
    //padding: "12px",
    [theme.breakpoints.up("sm")]: {
      margin: "12px",

    },
    [theme.breakpoints.down("sm")]: {
      margin: "12px 6px 12px 12px",
    },
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  subtitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().bold,
    paddingLeft: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  userimg: {
    borderRadius: "50%",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  stack: {
    backgroundColor: "#F5F7FA",
    margin: "12px",
  },
  stack1: {
    borderRight: "1px solid #E4E8EE",
  },
  preview: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary,
    textAlign: "center",
    marginBottom: "8px",
  },
  input: {
    marginTop: "10px",
  },
  border: {
    borderBottom: "2px solid #E4E8EE",
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "4px",
    padding: "12px 8px",
  },
  sub3: {
    backgroundColor: "#5AC782",
    padding: "2px 4px",
    color: "#EEF9EE",
    borderRadius: "4px",
    fontSize: "0.75rem",
  },
  sub4: {
    backgroundColor: "#FF4B4B",
    padding: "2px 4px",
    color: "#EEF9EE",
    borderRadius: "4px",
    fontSize: "0.75rem",
  },
  sub5: {
    backgroundColor: "#FF9340",
    padding: "2px 4px",
    color: "#EEF9EE",
    borderRadius: "4px",
    fontSize: "0.75rem",
  },
  sub55: {
    backgroundColor: "#FF4B4B",
    padding: "2px 4px",
    color: "#EEF9EE",
    borderRadius: "4px",
    fontSize: "0.75rem",
  },
  sub6: {
    backgroundColor: "#78B1FE",
    padding: "2px 4px",
    color: "#EEF9EE",
    borderRadius: "4px",
    fontSize: "0.75rem",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
}));

const AggreeUnitDetails = (props) => {
  const { t = () => false, handleTopBarState } = props
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const [detail, setDetail] = React.useState([]);
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const userProfileId = localStorage.getItem("userProfileId")
  const detailsData = () => {
    const data = {
      query: GET_AGREEMENTUNITACESS_DETAILS,
      variables: {
        id: passId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetail(response?.data?.data?.agreement_access[0]);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  //AccountRevokeAccess
  const AccountRevokeAccess = () => {

    const data = {
      tenantId: `${config.tenantid}`,
      user_profile_id: userProfileId,
      email_id: detail?.email_id,
      is_unit: false,
      account_no: detail?.account?.account_no,
      access_id: passId

    };
    NetworkCall(
      `${config.api_url}/access-control/revoke`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Account Access Revoked"),
        });
        history.goBack(-1);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  }

  //UnitRevokeAccess
  const UnitRevokeAccess = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      user_profile_id: userProfileId,
      email_id: detail?.email_id,
      is_unit: true,
      agreement_id: detail?.agreement?.id,
      unit_id: detail?.unit?.id,
      access_id: passId

    }
    NetworkCall(
      `${config.api_url}/access-control/revoke`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Unit Access Revoked"),
        });
        history.goBack(-1);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing Went wrong."),
        });
      });
  }

  React.useEffect(() => {
    handleTopBarState({
      text: getPage()
    })
    detailsData();
    // eslint-disable-next-line
  }, []);

  const getPage = () => {
    let title = ""
    if(detail?.request_type === "Unit Access") {
      title = "Unit Level Access"
    } else {
      title = "Account Level Access"
    }
    return title
  }

  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      {/* {detail?.request_type === "Unit Access" && (
        <TitleBar
          text="Unit Level Access"

          goBack={() => history.goBack()}
        />
      )}
      {detail?.request_type === "Agreement Access" && (
        <TitleBar
          text="Account Level Access"

          goBack={() => history.goBack()}
        />
      )} */}
      <div
        className={classes.root}
        style={{ height: size?.height - 55, overflow: "scroll" }}
      >
        <div className={classes.cardContent} >
          <Box display="flex" alignItems="center" p={2}>
            <Box className={classes.img}>
              <img
                src="/images/unit.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginLeft="14px">
              <Typography className={classes.title}>
                {detail?.request_type === "Agreement Access" &&
                  t("Account Level Access")}
                {detail?.request_type === "Unit Access" && t("Unit Level Access")}
              </Typography>
              <Typography className={classes.sub}>
                {t("Requestedon")} {moment(detail?.created_at).format("DD MMM ")}
              </Typography>
              {detail?.request_status === "Approved" && (
                <span className={classes.sub3}>&nbsp;&nbsp;{t("Approved")}&nbsp;&nbsp;</span>
              )}
              {detail?.request_status === "Declined" && (
                <span className={classes.sub4}>&nbsp;&nbsp;{t("Declined")}&nbsp;&nbsp;</span>
              )}
              {detail?.request_status === "Rejected" && (
                <span className={classes.sub55}>&nbsp;&nbsp;{t("Rejected")}&nbsp;&nbsp;</span>
              )}
              {detail?.request_status === "Revoked" && (
                <span className={classes.sub5}>&nbsp;&nbsp;{t("Revoked")}&nbsp;&nbsp;</span>
              )}
              {detail?.request_status === "Pending" && (
                <span className={classes.sub6}>&nbsp;&nbsp;{t("Waitingforapproval")}&nbsp;&nbsp;</span>
              )}
            </Box>
            <br />
          </Box>
          <Grid container>
            {detail?.request_status === "Approved" && (
              <Grid
                item
                xs={12}
                textAlign="center"
                bgcolor={"#EEF9EE"}
                p={1}
                marginTop="10px"
              >
                <Typography color={"#5AC782"} fontSize="12px">{t("Access Mail sent")}</Typography>
              </Grid>
            )}
            {detail?.request_status === "Declined" && (
              <Grid
                item
                xs={12}
                textAlign="center"
                bgcolor={"#EEF9EE"}
                p={1}
                marginTop="10px"
              >
                <Typography color={"#5AC782"} fontSize="12px">{detail?.remarks}</Typography>
              </Grid>
            )}
            {detail?.request_status === "Rejected" && (
              <Grid
                item
                xs={12}
                textAlign="center"
                bgcolor={"#FFECEC"}
                p={1}
                marginTop="10px"
              >
                <Typography color={"#FF4B4B"} fontSize="12px">{detail?.remarks}</Typography>
              </Grid>
            )}
            {detail?.request_status === "Revoked" && (
              <Grid
                item
                xs={12}
                textAlign="center"
                bgcolor={"#FFF4EB"}
                p={1}
                marginTop="10px"
              >
                <Typography color={"#FF9340"} fontSize="12px">{detail?.remarks}</Typography>
              </Grid>
            )}
          </Grid>

          <Grid container p={2}>
            <Grid item xs={6}>
              {detail?.unit && (
                <>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("UNITNAME")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title} style={{ overflowWrap: "anywhere" }}>
                    {detail?.unit?.name}
                  </Typography>
                </>
              )}
              {detail?.account && (
                <>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("AccountNo")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {detail?.account?.account_no}
                  </Typography>
                </>
              )}
              <br />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading} >
                {t("USERNAME")}
              </Typography>
              <Typography variant="subtitle2" className={classes.title} style={{ overflowWrap: "anywhere" }}>
                {detail?.name}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("MOBILENUMBER")}
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {detail?.mobile_no}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("MAILID")}
              </Typography>
              <Typography variant="subtitle2" className={classes.title} style={{ overflowWrap: "anywhere" }}>
                {detail?.email_id}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <div
            style={{
              padding: "12px",
              backgroundColor: "white",
              boxShadow: "0px -1px 6px #00000021",
            }}
          >
            {detail?.request_type === "Unit Access" && (
              <Button
                fullWidth
                className={classes.applybtn}
                variant="contained"
                onClick={UnitRevokeAccess}
                disabled={!detail?.request_status !== "Approved"}
              >
                {t("RevokeAccess")}
              </Button>
            )}
            {detail?.request_type === "Agreement Access" && (
              <Button
                fullWidth
                className={classes.applybtn}
                variant="contained"
                onClick={AccountRevokeAccess}
                disabled={detail?.request_status !== "Approved"}

              >
                {t("RevokeAccess")}
              </Button>
            )}

          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("aggreementUnitAccess")(AggreeUnitDetails), props);