import * as React from "react"

const Pencil = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} {...props}>
    <path
      d="M14.424 3a2.571 2.571 0 0 0-1.824.752l-8.157 8.157a1.29 1.29 0 0 0-.328.559l-1.094 3.828a.553.553 0 0 0 .683.683l3.83-1.089a1.292 1.292 0 0 0 .557-.328L16.248 7.4A2.576 2.576 0 0 0 14.424 3Zm0 1.1a1.468 1.468 0 0 1 1.042.435 1.465 1.465 0 0 1 0 2.084l-.715.715-2.084-2.086.715-.715a1.469 1.469 0 0 1 1.042-.434Zm-2.539 1.93 2.085 2.085-6.661 6.66a.187.187 0 0 1-.08.047l-2.872.821.821-2.873a.18.18 0 0 1 .047-.079Z"
      fill="#4e5a6b"
      data-name="Group 112379"
    />
  </svg>
)

export default Pencil;
