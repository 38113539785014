import React from "react";
import AddVisitorPass from "./addVisitorPass";
import AddVisitorPassContext  from "./addVisitorPassContext";

class AddVisitorPassParent extends React.Component {
    render() {
        return (
            <AddVisitorPassContext>
                <AddVisitorPass />
            </AddVisitorPassContext>

        )
    }
}
export default AddVisitorPassParent;