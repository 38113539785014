import React from "react";
import DeliveryPass  from "./deliveryPass";
import { withBottombar } from "../../HOCs";
class ParkingPassParent extends React.Component {
  render() {
    return <DeliveryPass {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(ParkingPassParent, props);
