import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const ViewAmenitiesHeaderStyle = makeStyles((theme) => ({
    icon_btn: {
        height: 24,
        width: 24,
        border: `1px solid ${theme.palette.border.secondary}`
    },
    name: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    subtitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    box: {
        background: "#fff"
    }
}))

export const SlotDetailCardStyle = makeStyles((theme) => ({
    name: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    slot: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    dot: {
        height: 6,
        width: 6,
        backgroundColor: theme.palette.border.secondary,
        borderRadius: 50
    },
    avatar: {
        height: 42,
        width: 42,
        borderRadius: '4px'
    },
    available: {
        fontSize: "0.75rem",
        color: theme.palette.success.main,
        fontFamily: FontFamilySwitch().regular,
    },
    notavailable: {
        fontSize: "0.75rem",
        color: theme.palette.warning.main,
        fontFamily: FontFamilySwitch().regular,
    }
}))