import React from "react";
import { withBottombar } from "../../HOCs";
import { ExternalSite } from "./externalSite";
class ExternalSiteParent extends React.Component {
    render() {
        return <ExternalSite {...this?.props} />;
    }
}

const props = {
    bottom_bar: "bottom_nav_bar"
}

export default withBottombar(ExternalSiteParent, props);
