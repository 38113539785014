import { Divider, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import React from "react";
import { GeneralRequest } from "../../../assets/dashboard/GeneralRequest";
import { MaintanenceRequests } from "../../../assets/dashboard/MaintanenceRequests";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { FontFamilySwitch } from "../../../utils";
import { CardList } from "../../../components";
const useStyles = makeStyles((theme) => ({
  requestsContainer: {
    borderRadius: theme.palette.borderRadius2,
    backgroundColor: theme.palette.background.tertiary1,
    boxShadow: "0px 3px 30px #4354721F",
  },
  requestList: {
    padding: "12px",
  },
  requestCountCard: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexDirection: "row",
  },
  count: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: "1px",
    color: theme.typography.color.primary,
  },
  requestType: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.Tertiary,
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  iconBg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.warning.main,
    borderRadius: "50%",
    // padding: "14px",
    width: "38px",
    height: "38px",
  },
  requestSwiper: {
    padding: "16px",
  },
  swiperContainer: {
    backgroundColor: theme.palette.background.tertiary1,
    padding: "10px",
    borderRadius: theme.palette.borderRadius2,
    border: "1px solid #E4E8EE",
    minWidth: "202px",
    minHeight: "98px",
    maxHeight: "98px",
    marginRight: "10px",
    cursor: "pointer"
  },
  cardType: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  status: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.palette.info.main,
    backgroundColor: "#F1F7FF",
    padding: "2px 10px",
    borderRadius: theme.palette.borderRadius2,
  },
  cardTitle: {
    marginTop: "7px",
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  category: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
    marginTop: "6px",
  },
  titleBlock:{
    backgroundColor: "#F6F9FF",
    padding: "14px",
    borderTopRightRadius: theme.palette.borderRadius2,
    borderTopLeftRadius: theme.palette.borderRadius2,
  },
  countTitle:{
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
}));
function Requests({ t = () => false, data }) {
  const classes = useStyles();
  const { general, maintenance } = data;
  const history = useHistory();

  const getCount = ()=>{
    const generalCount = general?.totalCount ?? 0;
    const maintenanceCount =  maintenance?.totalCount ?? 0;
    let count = generalCount + maintenanceCount;
    return(count > 9 ? count : ("0"+ count));
  }
  return (
    <>
    {/* {(general?.totalCount && maintenance?.totalCount > 0) &&  */}
    <Box className={classes.requestsContainer}>
       <Box className={classes.titleBlock}>
                  <Typography className={classes.countTitle}>
                   {t("Facility Management Requests")}{" "}{"("}{getCount()}{")"}
                  </Typography>
                </Box>
      <Box className={classes.requestList}>
        <Grid container sx={{cursor:"pointer"}}>
          <Grid onClick={()=> history.push({
            pathname: Routes.requestScreen,
            state: {
              request_type: ["General"]
            }
          })} item xs={6}>
            <RequestCount
              type="dashGeneral"
              count={general?.totalCount}
              t={t}
              color="#FEEAEA"
              svg={<GeneralRequest />}
            />
          </Grid>
          <Grid onClick={()=> history.push({
            pathname: Routes.requestScreen,
            state: {
              request_type: ["Maintenance"]
            }
          })} item xs={6}>
            <RequestCount
              type="dashMaintance"
              count={maintenance?.totalCount}
              t={t}
              color="#DBF0F1"
              svg={<MaintanenceRequests />}
            />
          </Grid>
        </Grid>
      </Box>
     
      <> 
      <Divider />
      <Box className={classes.requestSwiper}>
        <CardList
          main={{
            general: general?.result,
            maintenance: maintenance?.result,
          }}
          primary={true}
        />
      </Box>
      </>
    </Box>
    {/* } */}
    </>
  );
}
const RequestCount = ({ type, count, color, svg, t }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={4} sm={2.5}>
        <Box className={classes.iconBg} sx={{ backgroundColor: color }}>
          {svg}
        </Box>
      </Grid>
      <Grid item xs={8} sm={9.5}>
        <Box>
          <Typography className={classes.count}>{count}</Typography>
          <Typography className={classes.requestType} noWrap>
            {t(type)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Requests;
