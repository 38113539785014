import React from 'react'
import { Stack, Avatar, Typography } from '@mui/material'
import { BookingCardStyle } from './style'
import { AuthContext } from '../../../contexts'
import { generateImageUrl, img_size } from '../../../utils'
export const BookingCard = ({ header="", url="", name="", content="" }) => {
    const auth = React.useContext(AuthContext)
    const classes = BookingCardStyle({ language: auth?.auth?.auth?.language })
    return (
        <Stack alignItems={"left"} width={"100%"}  padding={"4px 20px"}>
            <Typography className={classes.headerTitle}> {header} </Typography>
            <Stack width="100%" pt={1}>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Stack direction={"row"}>
                        <Avatar className={classes.avatar} src={generateImageUrl(url, img_size.small_square)}></Avatar>
                        <Stack>
                            <Typography className={classes.title}> {name} </Typography>
                            <Typography className={classes.content}> {content} </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}