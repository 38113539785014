import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    visiterJourney: {
        borderRadius: "4px",
        boxShadow: "0px 6px 30px #0eeeee11",
        backgroundColor: "#fff",
    },
    innerPadding: {
        padding: "16px",
    },
    avatar: {
        backgroundColor: "#F1F7FF",
        height: "48px",
        width: "48px"
    },
    title: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29"
    },
    step: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#98A0AC"
    },
    input: {
        marginTop: "10px",
        marginBottom: "16px"
    },
    borederBottom: {
        borderBottom: "1px solid #E4E8EE",
        marginTop: "12px",
        marginBottom: "12px"
    },
    borederBottom2: {
        borderBottom: "1px solid #E4E8EE",
        marginBottom: "12px"
    },
    unitAvatar: {
        backgroundColor: "#ebdfff",
        height: "40px",
        width: "40px"
    },
    unitBox: {
        backgroundColor: "#F6F1FE",
        borderRadius: "6px",
        padding: "12px"
    },
    unitName: {
        fontFamily: FontFamilySwitch().regular,
        color: "#98A0AC",
        fontSize: "0.75rem"
    },
    unitNumber: {
        fontSize: "0.875rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().bold,
    },
    change: {
        color: "#5078E1",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer"
    },
    btnroot: {
        zIndex: 1000,
        backgroundColor: "#ffffff",
        boxShadow: "0px -1px 6px #00000021",
        padding: "16px"
    },
    primary: {
        padding: "12px 16px",
        textAlign: "center",
        borderRadius: "8px",
        fontSize: "0.875rem",
        boxShadow: "none",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer",
        backgroundColor: "#5078E1",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#5078E1",
            opacity: 0.9,
            boxShadow: "none",
        },
    },
    label: {
        fontSize: "0.875rem",
        color: "#071741",
        fontFamily: FontFamilySwitch().bold,
    },
    info: {
        color: "#4E5A6B",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().regular
    },
    stayContainer: {
        border: "1px solid #E4E8EE",
        borderRadius: "6px",
        // padding: "6px 12px 12px 12px",
    },
    secondary: {
        padding: "12px 16px",
        textAlign: "center",
        borderRadius: "8px",
        fontSize: "0.875rem",
        boxShadow: "none",
        color: "#071741",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#F6F6F6",
          opacity: 0.9,
          boxShadow: "none",
        },
      },
      img: {
        width: "210px",
        height: "210px"
      },
      addButton: {
        padding: "15px 142px",
        backgroundColor: "#F1F7FF",
        borderRadius: "8px"
      },
      box: {
        border: "1px solid #CED3DD",
        borderRadius: "12px",
        padding: "4px 16px 0px 16px"
      },
      border: {
        borderTop: "12px",
        border: "1px solid #CED3DD"
      },
      gateButtonActive: {
        padding: "15px 12px",
        backgroundColor: "#F1F7FF",
        border: "1px solid #5078E1",
        color: "#5078E1",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "8px",
        cursor: "pointer"
      },
      gateButtonInactive: {
        padding: "15px 12px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #CED3DD",
        color: "#4E5A6B",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        borderRadius: "8px",
        cursor: "pointer"
      },
      subTitle: {
        fontSize: "0.875rem",
        color: "#404E61",
        fontFamily: FontFamilySwitch().bold,
      },
      addButton2: {
        padding: "12px 145px",
        backgroundColor: "#5078E1",
        borderRadius: "8px",
        color: "#ffffff",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#5078E1",
          opacity: 0.8
        },
      },
      profileimg: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "relative",
      },
      profileicon: {
        fontSize: "80px",
        padding: "14px",
      },
      profileimgs: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        border: "2px solid white",
        borderRadius: "50%",
      },
      visitorList: {
        borderRadius: "8px",
        border: "1px solid #CED3DD",
        padding: "12px"
      },
      visitorName: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29"
      },
      visitorNumber: {
        color: "#4E5A6B",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular,
        direction: "ltr"
      },
      dot: {
        backgroundColor: "#CED3DD",
        borderRadius: "50%",
        width: "6px",
        height: "6px"
      }, 
      popper: {
        border: "1px solid #E4E8EE",
        padding: "12px",
      },
      popperText: {
        color: "#091B29",
        fontSize: "0.875rem",
        fontFamiy: FontFamilySwitch().semiBold
      }, close: {
        position: "absolute",
        right: "0px",
        top: "0px",
        backgroundColor: "red",
        borderRadius: "50%",
        color: "white",
        fontSize: "1.25rem",
      },
      imgdiv: {
        position: "relative",
        display: "inline-block",
        borderRadius: "50%",
      },
}))