import * as React from "react"

const PassDetails = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 113308">
      <path
        data-name="icons8-passport-and-tickets (1)"
        d="M15.869 0a2.665 2.665 0 0 0-1.493.459 2.74 2.74 0 0 0-.865.956 4.508 4.508 0 0 1 3.12 4.287v1.862l6.306 1.227.884-4.545a2.7 2.7 0 0 0-2.134-3.168L16.4.05a2.746 2.746 0 0 0-.531-.05ZM2.5 2.996A1.5 1.5 0 0 0 1 4.499v14.427a1.5 1.5 0 0 0 1.5 1.5h9.618a2.709 2.709 0 0 0 2.705-2.705V5.701a2.709 2.709 0 0 0-2.705-2.705Zm5.41 3.607a3.306 3.306 0 1 1-3.306 3.306 3.312 3.312 0 0 1 3.309-3.306Zm0 1.8a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.497-1.497Zm8.717 1v8.32a4.51 4.51 0 0 1-4.509 4.509h-1.506a2.709 2.709 0 0 0 1.347.692l5.278 1.027a3 3 0 0 0 .517.048 2.71 2.71 0 0 0 2.657-2.187l2.182-11.248Zm-11.72 5.616h6.011a.9.9 0 1 1 0 1.8H4.907a.9.9 0 1 1 0-1.8Z"
        fill="#78b1fe"
      />
    </g>
  </svg>
)

export default PassDetails
