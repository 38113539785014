import React from "react";

function VisitorImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        fill="#4e5a6b"
        d="M8.139 6A2.148 2.148 0 006 8.139v9.722A2.148 2.148 0 008.139 20h9.722A2.148 2.148 0 0020 17.861V8.139A2.148 2.148 0 0017.861 6zm0 1.167h9.722a.964.964 0 01.972.972v9.722a.964.964 0 01-.972.972H8.139a.964.964 0 01-.972-.972V8.139a.964.964 0 01.972-.972zM13 8.333a2.316 2.316 0 00-1.748.743 2.455 2.455 0 000 3.181 2.429 2.429 0 003.5 0 2.455 2.455 0 000-3.181A2.316 2.316 0 0013 8.333zM13 9.5a1.075 1.075 0 01.877.351 1.292 1.292 0 010 1.632 1.075 1.075 0 01-.877.351 1.075 1.075 0 01-.877-.351 1.292 1.292 0 010-1.632A1.075 1.075 0 0113 9.5zm-2.621 4.278a1.277 1.277 0 00-1.268 1.268v.421a2.309 2.309 0 001.189 1.888 5.025 5.025 0 002.7.7 5.025 5.025 0 002.7-.7 2.309 2.309 0 001.185-1.889v-.421a1.277 1.277 0 00-1.268-1.268zm0 1.167h5.242a.093.093 0 01.1.1v.421a1.155 1.155 0 01-.646.905A3.933 3.933 0 0113 16.89a3.933 3.933 0 01-2.077-.518 1.155 1.155 0 01-.646-.905v-.421a.093.093 0 01.102-.102z"
        data-name="icons8-name (3)"
        transform="translate(-6 -6)"
      ></path>
    </svg>
  );
}

export default VisitorImage;
