import React from "react";

function Retirement() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113457" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#f6f1fe"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#896db3"
          d="M10.314 6.286h5.712l.96-2.56a.643.643 0 00-.6-.869h-1.614a1.926 1.926 0 00-3.2 0H9.956a.643.643 0 00-.6.869zm6.636 1.285H9.39c-1.779 1.586-4.479 4.65-4.337 8.52A4.054 4.054 0 009.124 20h8.091a4.054 4.054 0 004.071-3.909c.143-3.87-2.557-6.934-4.336-8.52zM13.813 17v.21a.643.643 0 11-1.286 0v-.24a1.877 1.877 0 01-1.414-1.27.642.642 0 111.226-.386.607.607 0 00.579.424l.789-.009a.756.756 0 00-.009-1.513h-.626a2.038 2.038 0 01-.544-4v-.287a.643.643 0 011.286 0v.244a1.87 1.87 0 011.406 1.286.643.643 0 11-1.23.377.607.607 0 00-.579-.429l-.351.009a.756.756 0 00.013 1.513h.627A2.039 2.039 0 0113.813 17z"
          transform="translate(44.83 246)"
        ></path>
      </g>
    </svg>
  );
}

export default Retirement;