import * as React from "react";

const RepairRequest = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="85"
      height="85"
      viewBox="0 0 68 68"
    >
      <g
        id="Group_108759"
        data-name="Group 108759"
        transform="translate(4342 10930)"
      >
        <rect
          id="Rectangle_55778"
          data-name="Rectangle 55778"
          width="68"
          height="68"
          rx="8"
          transform="translate(-4342 -10930)"
          fill="#e8f6ff"
        />
        <g id="icons8-plumber" transform="translate(-4332 -10920)">
          <path
            id="Path_99627"
            data-name="Path 99627"
            d="M23,28.5V19.512h3V28.5A1.5,1.5,0,0,1,24.5,30h0A1.5,1.5,0,0,1,23,28.5Z"
            transform="translate(0 0)"
            fill="#607d8b"
          />
          <circle
            id="Ellipse_129576"
            data-name="Ellipse 129576"
            cx="4"
            cy="4"
            r="4"
            transform="translate(12 7)"
            fill="#ffb74d"
          />
          <path
            id="Path_99628"
            data-name="Path 99628"
            d="M16,29l1.865-8.081a3.985,3.985,0,0,0-2.81-4.8A3.9,3.9,0,0,0,10.3,19.022L8.124,28.895A4,4,0,0,0,8.2,30.827L10.312,38H6a2,2,0,0,0-1.973,2.336A2.075,2.075,0,0,0,6.107,42H12a3,3,0,0,0,2.958-3.5L14,33h4v6.893a2.075,2.075,0,0,0,1.664,2.08A2,2,0,0,0,22,40V32a3,3,0,0,0-3-3Z"
            transform="translate(0 0)"
            fill="#2196f3"
          />
          <path
            id="Path_99629"
            data-name="Path 99629"
            d="M26.489,26.016h-6a1.5,1.5,0,0,1-.937-.329l-4.484-4.516a1.5,1.5,0,0,1-.234-2.108c.518-.646,1.713-1.952,2.359-1.435l3.823,5.389H26.49a1.5,1.5,0,1,1,0,3Z"
            transform="translate(0 0)"
            fill="#2196f3"
          />
          <path
            id="Path_99630"
            data-name="Path 99630"
            d="M26,13.862v2.967c0,.683-1.466.671-1.5.671,0,0-1.5.012-1.5-.671V13.862a3.5,3.5,0,1,0,3,0Z"
            transform="translate(0 0)"
            fill="#607d8b"
          />
          <path
            id="Path_99631"
            data-name="Path 99631"
            d="M36,14V12a1,1,0,0,1,1-1h7"
            transform="translate(0 0)"
            fill="none"
            stroke="#546e7a"
            strokeWidth="2"
          />
          <path
            id="Path_99632"
            data-name="Path 99632"
            d="M43.986,18H31a1,1,0,0,1-1-1h0a1,1,0,0,1,1-1H43.986Z"
            transform="translate(0 0)"
            fill="#bbdefb"
          />
          <path
            id="Path_99633"
            data-name="Path 99633"
            d="M44,24H34a2,2,0,0,1-2-2V20H44Z"
            transform="translate(0 0)"
            fill="#bbdefb"
          />
          <path
            id="Path_99634"
            data-name="Path 99634"
            d="M17.31,23.4l-2.247-2.226,1.611,4.883"
            transform="translate(0 0)"
            fill="#3f51b5"
          />
          <path
            id="Path_99635"
            data-name="Path 99635"
            d="M14,33H11.946l2.543,3Z"
            transform="translate(0 0)"
            fill="#3f51b5"
          />
          <path
            id="Path_99636"
            data-name="Path 99636"
            d="M32,18v2H44l-.014-2Z"
            transform="translate(0 0)"
            fill="#90caf9"
          />
          <path
            id="Path_99637"
            data-name="Path 99637"
            d="M40,29V24H38v5h0a2,2,0,0,0,2,2h4V29Z"
            transform="translate(0 0)"
            fill="#546e7a"
          />
          <rect
            id="Rectangle_55779"
            data-name="Rectangle 55779"
            width="2"
            height="4"
            transform="translate(40 8)"
            fill="#546e7a"
          />
          <rect
            id="Rectangle_55780"
            data-name="Rectangle 55780"
            width="6"
            height="2"
            transform="translate(38 6)"
            fill="#546e7a"
          />
        </g>
      </g>
    </svg>
  );
};

export default RepairRequest;