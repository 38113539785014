import { Box, Stack, Typography } from '@mui/material';
import React from 'react'
import { BackupAndRestoreErrorStyle } from './style';
import BackupRestoreErrorSVG from '../../assets/backupRestoreError';

export const BackupAndRestoreError = (props) => {
    const { handleTopBarState } = props
    const classes = BackupAndRestoreErrorStyle()
    React.useEffect(() => {
        handleTopBarState({
            text: "Error"
        })
        // eslint-disable-next-line
    }, []);
    return (
        <Box className={classes.box}>
            <Stack className={classes.contentBox} spacing={3}>
                <BackupRestoreErrorSVG />
                <Typography className={classes.contentText}>
                    Request cannot be processed. Backup Restoring is In Progress
                </Typography>
            </Stack>
        </Box>
    )
}