import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../router/routes";
import { Grid } from "@mui/material";

export const ExternalSite = () => {
    const history = useHistory()
    const { state } = useLocation()

    React.useEffect(() => {
        if (!Boolean(state?.url?.length > 0)) {
            history.replace(Routes.dashboard)
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Grid maxWidth={"599px"} height={"100%"}>
            {state?.url
                ? <iframe
                    allow={`geolocation 'self' ${state?.url}`}
                    id="childIframe"
                    src={state?.url}
                    title={state?.url} width="100%" height="100%" style={{ border: "none" }} />
                : <div />}
        </Grid>
    )
}