import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

import { detailViewStyle } from "./style";
// import { NetworkCall, AlertProps, NetWorkCallMethods, UseDebounceNew } from "@core/utils";
import { config } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../contexts";
import { ViewCard } from "./components/viewCard";
import SearchIcon from "../../assets/searchIcon";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ManagementCard from "./components/managementCard";
import { DialogDrawer, TextBox, TitleBar, UseDebounce } from "../../components";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { withNamespaces } from "react-i18next";

export const DetailView = (props) => {
    const { t = () => false } = props;
    const classes = detailViewStyle();
    const size = useWindowDimensions();
    const state = useLocation()?.state
    const history = useHistory()
    const alert = React.useContext(AlertContext)
    const debounce = UseDebounce()
    const [myOrgView, setMyOrgView] = React.useState({
        bool: false,
        selectedData: "",
        showMyTeamMember: false,
        selectedMyTeam: ""
    })
    const [offset, setOffset] = React.useState(0)
    const [viewdata, setViewData] = React.useState({
        data: [],
        count: 10
    })
    const [searchText, setSearchText] = React.useState("")

    const apiRoute = {
        myCompany: "my_all_management_roles",
        myOrganization: "my_all_management_roles"
    }

    const heading = {
        myCompany: t("My Company"),
        myOrganization: t("My Organization")
    }


    const goBack = () => {
        if (myOrgView?.showMyTeamMember) {
            getViewData(0, "", false)
            setMyOrgView({
                ...myOrgView,
                showMyTeamMember: false,
                selectedMyTeam: ""
            })
            setSearchText("")
        } else {
            history.goBack()
        }
    };

    React.useEffect(() => {
        getViewData(0, "", false)
        // eslint-disable-next-line
    }, [])

    const getViewData = (offset = 0, searchText = "", bool, customBool = false, custoData = {}) => {
        let payload = {};

        if (!customBool) {
            switch (state?.viewkey) {
                case "myCompany":
                    payload = {
                        contact_id: state?.data?.contact_id,
                        limit: 10,
                        offset: offset,
                        org_role_type: "Company",
                        search: searchText
                    }
                    break
                case "myOrganization":
                    payload = {
                        contact_id: state?.data?.contact_id,
                        limit: 10,
                        offset: offset,
                        org_role_type: "Client",
                        search: searchText
                    }
                    break
                default:
                    payload = {
                        limit: 10,
                        offset: offset,
                        search: searchText
                    }
                    break;
            }
        } else {
            payload = {
                team_id: custoData?.team_id,
                limit: 10,
                offset: offset,
                search: searchText
            }
        }

        NetworkCall(
            `${config.api_url}/my_organisation/${apiRoute[customBool ? "myMembers" : state?.viewkey]}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((res) => {
                const resData = res?.data?.data
                let constructedData = []
                if (!customBool) {
                    switch (state?.viewkey) {
                        case "myCompany":
                            constructedData = resData?.map((e) => {
                                return {
                                    accordianTitle: e?.contact_name,
                                    avatar: e?.image_url,
                                    ...e
                                }
                            })
                            break
                        case "myOrganization":
                            constructedData = resData?.map((e) => {
                                return {
                                    accordianTitle: e?.contact_name,
                                    avatar: e?.image_url,
                                    ...e
                                }
                            })
                            break
                        default:
                            constructedData = []
                            break;
                    }
                } else {
                    constructedData = resData?.map((e) => {
                        return {
                            accordianTitle: e?.first_name,
                            avatar: e?.image_url,
                            ...e
                        }
                    })
                }

                if (bool) {
                    setViewData({
                        ...viewdata,
                        count: res?.data?.count,
                        data: viewdata?.data?.concat(constructedData)
                    })
                } else {
                    setViewData({
                        ...viewdata,
                        count: res?.data?.count,
                        data: constructedData
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("Something Went Wrong"),
                });
            });
    }

    const fetchMoreData = () => {
        setOffset(offset + 10);

        if (myOrgView?.showMyTeamMember) {
            getViewData(offset + 10, searchText, true, true, myOrgView?.selectedMyTeam)
        } else {
            getViewData(offset + 10, searchText, true)
        }

    }

    const handleSearch = (search) => {
        setSearchText(search)
        debounce(() => {
            getViewData(0, search, false, myOrgView?.showMyTeamMember, myOrgView?.selectedMyTeam)
        })
    }

    const handleViewData = (data) => {
        setMyOrgView({
            bool: true,
            selectedData: data
        })
    }

    const handleViewClose = () => {
        setMyOrgView({
            bool: false,
            selectedData: ""
        })
    }

    const handleTeamRedirect = (data) => {
        if (state?.viewkey === "myTeam") {
            if (!myOrgView?.showMyTeamMember) {
                getViewData(0, searchText, false, true, data)
                setOffset(0)
                setMyOrgView({
                    ...myOrgView,
                    showMyTeamMember: true,
                    selectedMyTeam: data
                })
            }
        }
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" style={{ padding: "0px" }}>
                <TitleBar text={heading[state?.viewkey]} goBack={goBack} arrow={true} />
                <Grid container style={{ backgroundColor: "#F6F6F6", justifyContent: "center" }}>
                    <Grid className={classes.boxSection}>
                        <Box className={classes.textBox}>
                            <TextBox
                                label={""}
                                placeholder={t("Search")}
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                                startAdornment={<SearchIcon />}
                                color
                                padding={"12px"}
                                borderRadius={"8px"}
                            />
                        </Box>
                        <Box className={classes.managerCardBox}>
                            {
                                viewdata?.data?.length > 0 ?
                                    <InfiniteScroll
                                        dataLength={viewdata?.count}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        height={size?.height - 160}
                                    >
                                        {viewdata?.data?.map((item, index) => {
                                            return (
                                                <Box className={classes.myOrgView} p={"8px 16px 0px 16px"}>
                                                    <ManagementCard
                                                        title={item?.accordianTitle}
                                                        subTitle={item?.job_title}
                                                        t={t}
                                                        open={myOrgView}
                                                        onClick={() => handleViewData(item)}
                                                        onClose={() => handleViewClose()}
                                                        hidden={viewdata?.data?.length === index + 1 ? false : true}
                                                        avatar={item?.image_url}
                                                        dialogData={item}
                                                        onSelect={() => handleTeamRedirect(item)}
                                                        mailPhone={{
                                                            email_id: item?.email_id,
                                                            country_code: item?.mobile_no_country_code,
                                                            mobile_no: item?.mobile_no
                                                        }}
                                                    />
                                                </Box>
                                            )

                                        })}
                                    </InfiniteScroll>
                                    :
                                    <Box className={classes.noDataFoundBox}>
                                        <Typography className={classes.noDataText}>{t("No Data Found")}</Typography>
                                    </Box>
                            }
                        </Box>
                    </Grid>


                </Grid>

                <DialogDrawer
                    height={"380px"}
                    maxWidth={"sm"}
                    open={myOrgView?.bool}
                    onClose={() => handleViewClose()}
                    header={t("View profile")}
                    component={
                        <ViewCard viewData={{
                            avatar: myOrgView?.selectedData?.image_url,
                            name: myOrgView?.selectedData?.accordianTitle,
                            jobTitle: myOrgView?.selectedData?.job_title,
                            department: myOrgView?.selectedData?.department_name,
                            reportingTo: myOrgView?.selectedData?.reporting_to ?? "-",
                            email: myOrgView?.selectedData?.email_id,
                            phone: myOrgView?.selectedData?.phone,
                            mobile_no_country_code: myOrgView?.selectedData?.mobile_no_country_code,
                            mobile_no: myOrgView?.selectedData?.mobile_no
                        }}
                            t={t}
                        />
                    }
                />


            </Container>
        </div>
    );
};
export default withNamespaces("management")(DetailView)
