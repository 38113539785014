import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const DetailCardStyle = makeStyles((theme, props) => ({
    box: {
        backgroundColor: "#fff",
        borderRadius: '4px',
        padding: props?.padding ?? "8px"
    },
    scorllBox: {
        backgroundColor: "#fff",
        border: `2px solid ${theme.palette.border.secondary}`
    },
    name: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "90%"
    },
    subtitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    tabroot: {
        display: "flex",
        alignItems: "center",
        "& .MuiTabs-indicator": {
            display: "none"
        }
    },
    tabItemroot: {

        // "& .MuiButtonBase-root-MuiTab-root": {
        // },

    },
    tabItem: {
        borderRadius: "4px",
        padding: "8px",
        minHeight: "auto",
        minWidth: "auto",
        maxHeight: "68px",
        margin: "0px 14px",
        "& .MuiButtonBase-root-MuiTab-root": {
            borderRadius: "4px",
        },

    },
    tabItemSelect: {
        backgroundColor: "#f6f8fe",
        borderRadius: "4px",
        padding: "5px 8px",
        minHeight: "auto",
        minWidth: "auto",
        maxHeight: "68px",
        margin: "0px 14px",
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: 0,
            borderRadius: "4px",

        },
    },
    textSelect1: {
        color: "#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.75rem"
    },
    textNotSelect1: {
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem"
    },
    textSelect2: {
        color: "#5078E1",
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().bold,
    },
    textNotSelect2: {
        color: theme.typography.color.secondary,
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().regular,
    },
    panel: {
        padding: "12px !important"
    },
    card: {
        backgroundColor: "white",
        marginTop: "6px",
        borderRadius: "4px",
    },
    backbtn1: {
        height: 32,
        width: 32,
        background: "#fff",
        color: theme.typography.color.secondary,
        border: `1px solid ${theme.palette.border.secondary}`,
        marginTop: 6
    },
    tabList: {
        borderBottom: 1,
        borderColor: "transparent",
        paddingBottom: "8px"
    }
}))

export const PreviewCardStyle = makeStyles((theme) => ({
    name: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        '@media (max-width: 321px)': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            // width: "100% !important",
        },
    },
    amenities_name: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        '@media (max-width: 321px)': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "130px !important",
        },
        '@media (min-width: 375px)': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "160px !important",
        },
        '@media (min-width: 425px)': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "190px !important",
        },

    },
    subtitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        '@media (max-width: 321px)': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100% !important",
            fontSize: "0.75rem",
        },
        '@media (max-width: 376px)': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100% !important",
            fontSize: "0.75rem",
        },
        '@media (min-width: 426px)': {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100% !important",
            fontSize: "0.75rem",
        },

    },
    freeSlotBox: {
        '@media (max-width: 321px)': {

            width: "92% !important",

        },

    },
    amenityNameBox: {
        '@media (max-width: 321px)': {

            width: "90% !important",

        },
    },
    avatar: {
        height: 48,
        width: 48,
        // width: "100%",
        borderRadius: '4px'
    },
    peakBox: {
        borderRadius: "9px",
        background: theme.palette.warning.light,
        paddingLeft: "8px",
        paddingRight: "8px",
        '@media (max-width: 321px)': {
            paddingLeft: "8px",
            paddingRight: "8px",
            // display: "none",
        },
    },
    peakBoxText: {
        fontSize: "0.75rem",
        color: theme.palette.warning.main,
        fontFamily: FontFamilySwitch().semiBold,
        '@media (max-width: 321px)': {
            // display: "none",
            // textOverflow: "ellipsis",
            // whiteSpace: "nowrap",
            // overflow: "hidden",
            // width: "64% !important",
            fontSize: "0.75rem",
        },
    },
    peakBoxMob: {
        borderRadius: "9px",
        background: theme.palette.warning.light,
        paddingLeft: "8px",
        paddingRight: "8px",
        // justifyContent: "center",
        // width: "80%",
        // margin: "0px auto",
        // marginTop: "12px"
    },
    peakBoxTextMob: {
        fontSize: "0.75rem",
        color: theme.palette.warning.main,
        fontFamily: FontFamilySwitch().semiBold,
    },
    peakBoxForMobile: {

        display: "none",
        '@media (max-width: 321px)': {
            display: "block",
            // textOverflow: "ellipsis",
            // whiteSpace: "nowrap",
            // overflow: "hidden",
            // width: "64% !important",
            fontSize: "0.75rem",
            float: "right"
        },
    },
    peakBoxForWeb: {
        display: "block",
        '@media (max-width: 321px)': {
            display: "none",
            // textOverflow: "ellipsis",
            // whiteSpace: "nowrap",
            // overflow: "hidden",
            // width: "64% !important",
            fontSize: "0.75rem",
        },

    },
    desc: {
        fontSize: "0.75rem",
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
        width: "250px"
    },
    seeMoreLessTextStyle: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer"
    },
    freeText: {
        fontSize: "0.875rem",
        color: theme.palette.success.main,
        background: theme.palette.success.light,
        fontFamily: FontFamilySwitch().bold,
        borderRadius: 8,
        padding: "4px 8px 2px 8px"
    },
    cardTitles: {
        width: "100%",
        '@media (max-width: 425px)': {
            width: "100% !important",
        },
        '@media (max-width: 376px)': {
            width: "100% !important",
        },
        '@media (max-width: 321px)': {
            width: "100% !important",
        },
    }
}))

export const BillSummaryStyle = makeStyles((theme) => ({
    title: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    secondary: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    dot_divider: {
        border: "1px dashed #CED3DD",
    },
    primary: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    peakBox: {
        background: theme.palette.warning.light,
        height: 16,
        width: 24,
        display: "flex",
        justifyContent: "center",
        borderRadius: "9px",
        alignItems: "center",

    },

}))