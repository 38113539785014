import { Box, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods, AlertProps, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
import AmenitiesBookingCard from "././component/amenitiesBookingCard";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px",
        backgroundColor: "#F2F5FA",
    },
    roots: {
        margin: "0px 12px",
        padding: "0px 4px",
    },
    image: {
        objectFit: "cover",
        width: "101px",
        height: "113px",
    },
    card: {
        backgroundColor: "white",
        // padding: "16px 16px 9px 16px",
        marginTop: "12px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 4px #00000029",
        cursor: "pointer",
    },
    name: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "12px",
    },
    total: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#071741",
        marginTop: "12px",
        marginBottom: "12px"
    },
    arrow: {
        border: "1px solid #E4E8EE",
        backgroundColor: "#F2F4F7",
        fontSize: "0.75rem",
    },
    noData: {
        color: "#999",
        textAlign: "center",
        marginTop: "20vh",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "12px"
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    sub: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,

    },
    details: {
        flexFlow: 'wrap !important',
        display: "flex",
        margin: "10px 0px 0px 12px",
        alignItems: "center",
    },
    modalTitle: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    }
}));

export const Amenities = (props) => {
    const { t = () => false, handleTopBarState } = props
    const classes = useStyles();
    const history = useHistory();
    const alert = React.useContext(AlertContext);
    const size = useWindowDimensions();
    const [datas, setDatas] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const [offset, setOffSet] = React.useState(0)
    // const [selectedProperty, setSelectedProperty] = React.useState({})
    const searchURL = useLocation().search;
    const agreementId = new URLSearchParams(searchURL).get("Id");
    const getproperty = (offset, infinity) => {
        const data = JSON.stringify({
            agreementId: [agreementId],
            tenantId: `${config.tenantid}`,
            is_pagination: true,
            offset: offset,
            limit: 10
        });
        NetworkCall(
            `${config.api_url}/agreement-property-info/get`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                let resData = response?.data?.data
                if (infinity) {
                    setDatas({
                        ...datas,
                        propertyCount: resData?.propertyCount,
                        propertyInfo: datas?.propertyInfo?.concat(resData?.propertyInfo),
                        property_detail: datas?.property_detail?.concat(resData?.property_detail)
                    });
                } else {
                    setDatas(resData)
                }
                // if (response.data.data?.property_detail?.length === 1) {
                //     history.replace(
                //         {
                //             pathname: Routes.amenitiesdetails,
                //             state: {
                //                 propertyId: response.data.data?.property_detail?.[0]?.property_id,
                //                 agreementId: agreementId,
                //                 unitData: {
                //                     agreement_unit_id: response?.data?.data?.property_detail?.[0]?.agreement_unit_id,
                //                     unit_name: response?.data?.data?.property_detail?.[0]?.unit_name,
                //                     unit_no: response?.data?.data?.property_detail?.[0]?.unit_no,
                //                     unit_id: response?.data?.data?.property_detail?.[0]?.unit_id
                //                 },
                //             },
                //         }
                //     )
                // } else {
                setLoading(false);
                // }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentWrong"),
                });
            });
    };


    React.useEffect(() => {
        handleTopBarState({
            text: t("Amenities")
        })
        getproperty(0, false)
        // eslint-disable-next-line
    }, []);

    const fetchMoreData = () => {
        setOffSet(offset + 10);
        getproperty(offset + 10, true);
    };

    const handleRoute = (data) => {
        history.push(
            {
                pathname: Routes.amenitiesdetails,
                state: {
                    propertyId: data?.property_id,
                    agreementId: agreementId,                    
                },

            }
        )
    }
    return (
        <Container className={classes.root} maxWidth="sm">
            {/* <TitleBar
                text={t("Amenities")}

                goBack={() => history.push(Routes.agreement)}
            /> */}
            <div
                style={{
                    height: size?.height - 60,
                    overflow: "scroll",
                }}
                className={classes.roots}
            >
                <Typography className={classes.total}>
                    {t("Properties")}&nbsp;({datas?.propertyCount > 0 ? datas?.propertyCount : 0}
                    )
                </Typography>

                {loading ? (
                    <LoderSimmer card count={10} size={80} />
                ) : (
                    <>
                        <Grid container spacing={1}>
                            {datas?.property_detail?.length > 0 ?
                                <Box sx={{ width: "100% !important", marginTop: "8px" }}>
                                    <InfiniteScroll
                                        dataLength={datas?.propertyCount}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        height={size?.height - 100}
                                    >
                                        <Grid containter spacing={1}>
                                            {datas?.property_detail?.map((val) => {
                                                return (
                                                    <>
                                                        <Grid item xs={12} pb={1}>
                                                            <AmenitiesBookingCard
                                                                normal
                                                                data={val}
                                                                onClick={() =>
                                                                    // getUnitids(val)
                                                                    handleRoute(val)
                                                                }
                                                            />
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                        </Grid>
                                    </InfiniteScroll>
                                </Box>
                                :
                                <Typography className={classes.noData}>
                                    {t("Datanotfound")}
                                </Typography>
                            }
                        </Grid>

                    </>
                )}
            </div>            
        </Container >
    );
};
export default withNamespaces("amenitiesList")(Amenities)