import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AddAddress from "../screens/addAddress/addAddress";
import AggreeUnitDetails from "../screens/aggreementUnitAccess/aggreeUnitDetails";
import CreateAggreementUnit from "../screens/aggreementUnitAccess/createAggreementUnit";
import AmenitiesType from "../screens/amenitiesDetails/amenitiesType";
import AnnouncementDetails from "../screens/announcement/announcementDetails";
import CreateDiscussion from "../screens/communityDiscussion/createDiscussion";
import DiscussionDetails from "../screens/communityDiscussion/discussionDetails";
import ContactDetails from "../screens/contacts/contactDetails";
import CreateDelivery from "../screens/deliveryInfo/createDelivery";
import DeliveryDetails from "../screens/deliveryInfo/deliveryDetails";
import DomesticDetails from "../screens/domesticHelp/demesticDetails";
import CreateDomestic from "../screens/domesticHelp/domesticCreate";
import InspectionOrdersParent from "../screens/inspections";
import KycInformationParent from "../screens/kycInformation";
import AddIdProof from "../screens/kycInformation/addIdProof";
import OccupantsParent from "../screens/occupants";
import AddOccupants from "../screens/occupants/addOccupants";
import OldQuotationDetails from "../screens/oldQuotationDetails/oldQuotationDetails";
import CreateParking from "../screens/parkingpass/createParking";
import ParkingDetails from "../screens/parkingpass/passDetails";
import PaymentHistory from "../screens/paymentHistory/paymentHistory";
import ProfileEdit from "../screens/profileEdit/profileEdit";
import ServiceRecordOrdersParent from "../screens/serviceRecordOrders";
import ServiceUnitCompleted from "../screens/serviceRecordOrders/serviceUnitCompleted";
import PropertySearchParent from "../screens/propertySearch";
import CreateSpending from "../screens/statistics/createSpending";
import CreateInvesment from "../screens/statistics/createInvestment";
import UtilitiesType from "../screens/utilitesDetails/utilitiestype";
import AddVehicle from "../screens/vehicle/addVehicle";
import ViewVehicle from "../screens/vehicle/vehicleView";
import CompanySelect from "../screens/welcomePage/companyselectpage";
import LoginDetails from "../screens/welcomePage/login";
import OtpPage from "../screens/welcomePage/otpPage";
import ResetPassword from "../screens/welcomePage/resetPassword";
import SetupAccountDetails from "../screens/welcomePage/setupAccountDetails";
import InspectionUnitCompleted from "../screens/inspections/unitCompleted";
import ShortlistedUnits from "../screens/propertySearch/shortlistedUnits";
import BOMDetails from "../screens/requestdetails/costing"
import {
  AddPass,
  AggreeUnitAccess,
  Agreement,
  AgreementParking,
  Amenities, AmenitiesBooking, AmenitiesBookingNew, AmenitiesDetails, AmenitiesDetailsNew, Announcement, AvailableSlot, CommunityDiscussion,
  Contacts,
  Dashboard,
  DatePickerTest,
  DeliveryPass,
  DomesticHelpList,
  EmergencyNumber,
  EmergencyNumberDetails,
  Entries,
  ExperiedAgreemnt, FileManager,
  FileManagerView, General,
  InvoiceScreen,
  MaintenanceParent,
  ManagementDetails,
  Managment, MyBooking, MyBookingDetails, MyBookingList, MyNeighbours,
  NeighboursDetails,
  NotFound,
  Notification,
  Owners,
  Ownerslist,
  ParkingPass, PaymentScreen, ProfileView, Quotation as QuotationDetails,
  QuotationPaymentScreen as QuotationPayment, RequestDetails,
  RequestHomeScreen,
  Service,
  Statementofacoount,
  Success,
  SwiftInOutDetails,
  Units,
  Utilities,
  UtilitiesDetails,
  Vehicle, ViewAmenities, WelcomePage,
  Allagreement,
  AddOn,
  CaseManagement,
  CorrespondanceType,
  AmenitiesDetailsOne,
  Statistics,
  AmenitiesBookings,
  AddWorkerPass,
  ExternalSite,
  BackupAndRestoreError,
  PayRequestInvoiceList,
  ServiceGoto,
  AgreementApproval,
  DetailView,
  WalkInDetails
} from "./../screens";
import BookingConfirmation from '../screens/amenityBookings/bookingConfirmation'
import PrivateRouter from "./privateRouter";
import { Routes } from "./routes";
import BookingHistory from "../screens/amenityBookings/bookingHistory";
import FilteredPropertyList from "../screens/propertySearch/filteredPropertyList";
import AddWorkerParent from "../screens/workerGatepass/addWorkerParent";
import VisitorPassParent from "../screens/createPass";
import WorkerGatePassParent from "../screens/workerGatepass";
import VisitorGatePassDetails from "../screens/createPass/visitorGatePassDetails";
import WorkerGatePassDetails from "../screens/workerGatepass/workerGatePassDetails";
import GetSupportParent from "../screens/getSupport";
import GetSupportDetailedParent from "../screens/getSupportDetailed";
import FeedbackParent from "../screens/feedback";
import FeedbackForm from "../screens/feedback/feedbackForm";
import AddVisitorPassParent from "../screens/addVisitorPassNew";
import InventoryAndServicesParent from "../screens/inventoryAndServices";
import Preview from "../screens/requestdetails/preview";
import InspectionItemList from "../screens/inventoryAndServices/inspectionItemList";
import VideoCall from "../screens/videoCall";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path={Routes.quotationDetails}>
          <QuotationDetails />
        </Route>
        <Route exact path={Routes.agreementApproval}>
          <AgreementApproval />
        </Route>
        <PrivateRouter exact path={Routes.videoCall}>
          <VideoCall />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.aggreementUnitAccess}>
          <AggreeUnitAccess />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.aggreementUnitAccessDetails}>
          <AggreeUnitDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createNew}>
          <CreateAggreementUnit />
        </PrivateRouter>
        {/*Neighbours Details*/}
        <PrivateRouter exact path={Routes.myNeighboursDetails}>
          <NeighboursDetails />
        </PrivateRouter>
        {/* My Neighbours*/}
        <PrivateRouter exact path={Routes.myNeighbours}>
          <MyNeighbours />
        </PrivateRouter>
        {/*Managment*/}
        <PrivateRouter exact path={Routes.managment}>
          <Managment />
        </PrivateRouter>
        {/*Managment*/}
        <PrivateRouter exact path={Routes.utilities}>
          <Utilities />
        </PrivateRouter>
        {/*UtilitiesType*/}
        <PrivateRouter exact path={Routes.utilitiestype}>
          <UtilitiesType />
        </PrivateRouter>
        {/*Management Details*/}
        <PrivateRouter exact path={Routes.managementdetails}>
          <ManagementDetails />
        </PrivateRouter>
        {/* Announcement Detials*/}
        <PrivateRouter exact path={Routes.utilitiesdetails}>
          <UtilitiesDetails />
        </PrivateRouter>
        {/* Announcement Detials*/}
        <PrivateRouter exact path={Routes.announcementDetails}>
          <AnnouncementDetails />
        </PrivateRouter>
        {/* Announcement*/}
        <PrivateRouter exact path={Routes.announcement}>
          <Announcement />
        </PrivateRouter>
        {/* emergency details*/}
        <PrivateRouter exact path={Routes.emergencyNumberDetails}>
          <EmergencyNumberDetails />
        </PrivateRouter>
        {/* aggreement Unit details*/}

        {/* emergency number*/}
        <PrivateRouter exact path={Routes.emergencyNumber}>
          <EmergencyNumber />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createPass}>
          <AddPass />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createWorker}>
          <AddWorkerParent />
        </PrivateRouter>
        {/* <PrivateRouter exact path={Routes.passDetails}>
          <GatePassDetails />
        </PrivateRouter> */}
        {/* Quotation Details Route */}
        <Route exact path={Routes.visitorGatePassDetails}>
          <VisitorGatePassDetails />
        </Route>
        <Route exact path={Routes.workerGatePassDetails}>
          <WorkerGatePassDetails />
        </Route>

        {/* Login path */}
        <Route exact path="/">
          <LoginDetails />
        </Route>
        {/* Old Quotation Details Route */}
        <Route exact path={Routes.oldQuotationDetails}>
          <OldQuotationDetails />
        </Route>

        {/* Quotation Payment Route */}
        <Route exact path={Routes.quotationPayment}>
          <QuotationPayment />
        </Route>

        {/* Welcome page Route */}
        <Route exact path={Routes.welcomePage}>
          <WelcomePage />
        </Route>

        {/* SetupAccount Details Route */}
        <Route exact path={Routes.setupAccount}>
          <SetupAccountDetails />
        </Route>

        {/* Login Route */}
        <Route exact path={Routes.login}>
          <LoginDetails />
        </Route>

        {/* otp Route */}
        <Route exact path={Routes.otpPage}>
          <OtpPage />
        </Route>
        {/* otp Route */}
        <Route exact path={Routes.resetpassword}>
          <ResetPassword />
        </Route>

        {/* Dashboard */}
        <PrivateRouter exact path={Routes.dashboard}>
          <Dashboard />
        </PrivateRouter>

        {/* Agreement */}
        <PrivateRouter exact path={Routes.agreement}>
          <Agreement />
        </PrivateRouter>

        {/* Requestscreen Route */}
        <PrivateRouter exact path={Routes.requestScreen}>
          <RequestHomeScreen />
        </PrivateRouter>

        {/* Pay Request Invoice List */}
        <PrivateRouter exact path={Routes.pay_request_invoice_list}>
          <PayRequestInvoiceList />
        </PrivateRouter>

        {/* Profile View Route */}
        <PrivateRouter exact path={Routes.profileView}>
          <ProfileView />
        </PrivateRouter>

        {/* units Route */}
        <PrivateRouter exact path={Routes.units}>
          <Units />
        </PrivateRouter>

        {/* Service Route */}
        <PrivateRouter exact path={Routes.service}>
          <Service />
        </PrivateRouter>

        {/* InviceScreen Route */}
        <PrivateRouter exact path={Routes.invoiceScreen}>
          <InvoiceScreen />
        </PrivateRouter>

        {/* Experied */}
        <PrivateRouter exact path={Routes.experied}>
          <ExperiedAgreemnt />
        </PrivateRouter>

        {/* notification */}
        <PrivateRouter exact path={Routes.notification}>
          <Notification />
        </PrivateRouter>

        {/* RequsetDetails */}
        <PrivateRouter exact path={Routes.requestDetails}>
          <RequestDetails />
        </PrivateRouter>

        {/* Statement of account */}
        <PrivateRouter exact path={Routes.statementofaccount}>
          <Statementofacoount />
        </PrivateRouter>

        {/* General Route */}
        <PrivateRouter exact path={Routes.general}>
          <General />
        </PrivateRouter>

        {/* MaintenanceScreen Step 1 Route */}
        <PrivateRouter exact path={Routes.maintenanceParent}>
          <MaintenanceParent />
        </PrivateRouter>

        {/* Profile Edit Route */}
        <PrivateRouter exact path={Routes.profileEdit}>
          <ProfileEdit />
        </PrivateRouter>

        {/* Add Address Route */}
        <PrivateRouter exact path={Routes.addAddress}>
          <AddAddress />
        </PrivateRouter>

        {/* Payment History Route */}
        <PrivateRouter exact path={Routes.paymentHistory}>
          <PaymentHistory />
        </PrivateRouter>
        {/* amenities Route */}
        <PrivateRouter exact path={Routes.amenities}>
          <Amenities />
        </PrivateRouter>
        {/* amenities details Route */}
        <PrivateRouter exact path={Routes.amenitiesdetails}>
          <AmenitiesDetails />
        </PrivateRouter>
        {/* amenities details Route */}
        <PrivateRouter exact path={Routes.amenitiestype}>
          <AmenitiesType />
        </PrivateRouter>

        {/* amenitiesDetailsOne */}
        <PrivateRouter exact path={Routes.amenitiesdetailsOne}>
          <AmenitiesDetailsOne />
        </PrivateRouter>



        {/* Home Route */}
        {/* <Route exact path={Routes.home}>
          <Home />
        </Route> */}
        {/* Sucess Route */}
        <Route exact path={Routes.success}>
          <Success />
        </Route>
        {/* Sucess Route */}
        <Route exact path={Routes.failure}>
          <Success failure />
        </Route>
        {/* Sucess Route */}
        <PrivateRouter exact path={Routes.communityDiscussion}>
          <CommunityDiscussion />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createDiscussion}>
          <CreateDiscussion />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.discussions}>
          <DiscussionDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.contacts}>
          <Contacts />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.contactsDetails}>
          <ContactDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.workerGatePass}>
          <WorkerGatePassParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.visitorGatePass}>
          <VisitorPassParent />
        </PrivateRouter>
        {/* parking pass */}
        <PrivateRouter exact path={Routes.parkingPass}>
          <ParkingPass />
        </PrivateRouter>
        {/* parking pass Deatils*/}
        <PrivateRouter exact path={Routes.parkingDetails}>
          <ParkingDetails />
        </PrivateRouter>
        {/* parking pass create*/}
        <PrivateRouter exact path={Routes.createParking}>
          <CreateParking />
        </PrivateRouter>
        {/* Delivery*/}
        <PrivateRouter exact path={Routes.delivery}>
          <DeliveryPass />
        </PrivateRouter>
        {/* Delivery Details*/}
        <PrivateRouter exact path={Routes.deliveryDetails}>
          <DeliveryDetails />
        </PrivateRouter>
        {/* create Details*/}
        <PrivateRouter exact path={Routes.createDelivery}>
          <CreateDelivery />
        </PrivateRouter>
        {/* Domestic Help*/}
        <PrivateRouter exact path={Routes.domesticHelp}>
          <DomesticHelpList />
        </PrivateRouter>
        {/* Domestic Details*/}
        <PrivateRouter exact path={Routes.domesticDetails}>
          <DomesticDetails />
        </PrivateRouter>
        {/* Domestic Create*/}
        <PrivateRouter exact path={Routes.domesticCreate}>
          <CreateDomestic />
        </PrivateRouter>
        {/* Entries*/}
        <PrivateRouter exact path={Routes.entries}>
          <Entries />
        </PrivateRouter>
        {/* Entries Details*/}
        <PrivateRouter exact path={Routes.swiftinout}>
          <SwiftInOutDetails />
        </PrivateRouter>
        {/* Vehicle List*/}
        <PrivateRouter exact path={Routes.vehicle}>
          <Vehicle />
        </PrivateRouter>
        {/* Add Vehicle List*/}
        <Route exact path={Routes.addVehicle}>
          <AddVehicle />
        </Route>
        {/* View Vehicle List*/}
        <PrivateRouter exact path={Routes.viewVehicle}>
          <ViewVehicle />
        </PrivateRouter>
        {/* Entries Details*/}
        <PrivateRouter exact path={Routes.agreementparking}>
          <AgreementParking />
        </PrivateRouter>

        {/* Properties list */}
        <PrivateRouter exact path={Routes.propertiesList}>
          <Owners />
        </PrivateRouter>
        {/* Owners list based on properties */}
        <PrivateRouter exact path={Routes.ownerslists}>
          <Ownerslist />
        </PrivateRouter>
        {/* amenity image card page Route */}
        <PrivateRouter exact path={Routes.amenitiesBookingDetails}>
          <AmenitiesBooking />
        </PrivateRouter>

        {/* amenity image card page Route */}
        <PrivateRouter exact path={Routes.amenitiesBookingsDetails}>
          <AmenitiesBookings />
        </PrivateRouter>

        {/* amenity bookings payment */}
        <PrivateRouter exact path={Routes.bookingConfirmation}>
          <BookingConfirmation />
        </PrivateRouter>




        <Route exact path={Routes.DatePickerTest}>
          <DatePickerTest />
        </Route>
        {/* company select page Route */}
        <Route exact path={Routes.companyselect}>
          <CompanySelect />
        </Route>
        {/* Amenity Booking new Route */}
        <PrivateRouter exact path={Routes.amenitiesBookingNew}>
          <AmenitiesBookingNew />
        </PrivateRouter>
        {/* Amenity Booking new Route */}
        <PrivateRouter exact path={Routes.amenitiesdetailsNew}>
          <AmenitiesDetailsNew />
        </PrivateRouter>

        {/* Amenity Booking new Route */}
        <PrivateRouter exact path={Routes.viewAmenities}>
          <ViewAmenities />
        </PrivateRouter>
        {/* Amenity Booking new Route */}
        <PrivateRouter exact path={Routes.availableSlot}>
          <AvailableSlot />
        </PrivateRouter>
        {/* My booking Route */}
        <PrivateRouter exact path={Routes.mybooking}>
          <MyBooking />
        </PrivateRouter>
        {/* My Booking Details Route */}
        <PrivateRouter exact path={Routes.myBookingDetails}>
          <MyBookingDetails />
        </PrivateRouter>
        {/* My booking List Route */}
        <PrivateRouter exact path={Routes.myBookingList}>
          <MyBookingList />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.payment}>
          <PaymentScreen />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.fileManager}>
          <FileManager />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.fileManagerView}>
          <FileManagerView />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.allAgreement}>
          <Allagreement />
        </PrivateRouter>

        <PrivateRouter exact path={Routes.statistics}>
          <Statistics />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.revenue}>
          <Statistics />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.spendings}>
          <Statistics />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.investment}>
          <Statistics />
        </PrivateRouter>

        <PrivateRouter exact path={Routes.createSpending}>
          <CreateSpending />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createInvestment}>
          <CreateInvesment />
        </PrivateRouter>

        <PrivateRouter exact path={Routes.occupants}>
          <OccupantsParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.addOccupants}>
          <AddOccupants />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.kycInformation}>
          <KycInformationParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.addIdProof}>
          <AddIdProof />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.propertySearch}>
          <PropertySearchParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.shortlistedUnits}>
          <ShortlistedUnits />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.filteredPropertyList}>
          <FilteredPropertyList />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.addOn}>
          <AddOn />
        </PrivateRouter>

        <PrivateRouter exact path={Routes.caseManagement}>
          <CaseManagement />
        </PrivateRouter>

        <PrivateRouter exact path={Routes.correspondanceType}>
          <CorrespondanceType />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.feedback}>
          <FeedbackParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.feedbackForm}>
          <FeedbackForm />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.inspectionOrder}>
          <InspectionOrdersParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.inspectionUnitCompleted}>
          <InspectionUnitCompleted />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.serviceRecordOrders}>
          <ServiceRecordOrdersParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.serviceUnitCompleted}>
          <ServiceUnitCompleted />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.bookingHistory}>
          <BookingHistory />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.getSupport}>
          <GetSupportParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.getSupportDetailed}>
          <GetSupportDetailedParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.addVisitorPass}>
          <AddVisitorPassParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.addWorkerPass}>
          <AddWorkerPass />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.inventoryAndServices}>
          <InventoryAndServicesParent />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.maintenancePreview}>
          <Preview />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.inspectionItemList}>
          <InspectionItemList />
        </PrivateRouter>
        <PrivateRouter exact path={Routes["#"]}>
          <ExternalSite />
        </PrivateRouter>
        <Route exact path={Routes.backupAndRestoreError}>
          <BackupAndRestoreError />
        </Route>
        <PrivateRouter exact path={Routes.BOMDetails}>
          <BOMDetails />
        </PrivateRouter>
        <Route exact path={Routes.serviceGoto}>
          <ServiceGoto />
        </Route>
        <PrivateRouter exact path={Routes.managementDetailsView}>
          <DetailView />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.walkinDetails}>
          <WalkInDetails />
        </PrivateRouter>
        {/* For unknow/non-de
        fined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
