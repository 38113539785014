import Revenue from "./revenue";
import Spendings from "./spendings";
import React, { useState } from "react";
import Investment from "./investments";
import { Backdrop, Box, CircularProgress, Container, Fab, Grid } from "@mui/material";
import { NewTabsComponent } from "../../components/tabComponent/newTab";
// import RevenueImage from "../../assets/revenueImage";
import SpendingImage from "../../assets/spendingsImage";
import InvestmentImage from "../../assets/investmentImage";
import { useStyles } from "./style";
import { useHistory } from "react-router";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Routes } from "../../router/routes";
import AddIcon from "@mui/icons-material/Add";
import { withNamespaces } from "react-i18next";

const Statistics = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const classes = useStyles({ size: size });
  const history = useHistory();
  const inital_tab = window.location.pathname === Routes.revenue ? 1 :
    window.location.pathname === Routes.spendings ? 1 : 2
  const [selectedTab, setSelectedTab] = useState(inital_tab);
  const [loading, setLoading] = React.useState(true);


  const handleToggle = () => {
    switch (selectedTab) {
      case 0:
        break;
      case 1:
        history.push("/createSpending")
        break;
      case 2:
        history.push("/createInvestment")
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    handleTopBarState({
      text: t("Statistics")
    })
    setLoading(false);
    // eslint-disable-next-line
  }, []);
  return (
    <Container className={classes.root} maxWidth="sm">
      {loading ? (
        <Backdrop className={classes.loading_div} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {/* <TitleBar text={"Statistics"} goBack={goBack} /> */}
          <div className={classes.tab_div}>
            <NewTabsComponent
              // tab1Label={"Revenue"}
              tab2Label={t("Spending")}
              tab3Label={t("Investment")}
              selectedTab={selectedTab}
              // onTab1Clicked={() => {
              //   setSelectedTab(0)
              //   history.replace(Routes.revenue)
              // }}
              onTab2Clicked={() => {
                setSelectedTab(1)
                history.replace(Routes.spendings)
              }}
              onTab3Clicked={() => {
                setSelectedTab(2)
                history.replace(Routes.investment)
              }}
              // tabIcon1={<RevenueImage />}
              tabIcon2={<SpendingImage />}
              tabIcon3={<InvestmentImage />}
            />
          </div>
          <div className={classes.body_div}>
            {selectedTab === 0 && <Revenue />}
            {selectedTab === 1 && <Spendings />}
            {selectedTab === 2 && <Investment />}
          </div>
        </>
      )}

      {selectedTab !== 0 && (
        <Grid container>
          <Grid item xs={12}
            style={{ position: "relative" }}
          >
            <Box sx={{ display: "flex", float: "right" }}>
              <Box>
                <Fab
                  color="primary"
                  className={classes.fab}
                  aria-label="add"
                  onClick={handleToggle}
                >
                  <AddIcon />
                </Fab>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};


export default withNamespaces("statistics")(Statistics);
