import * as React from "react"
export const Micoff = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} {...props}>
    <g fill="#ffecec" stroke="#ff4b4b" data-name="Ellipse 131002">
      <circle cx={24} cy={24} r={24} stroke="none" />
      <circle cx={24} cy={24} r={23.5} fill="none" />
    </g>
    <path
      fill="#ff4b4b"
      d="M23.997 14a4.006 4.006 0 0 0-4 4v6.667c0 .093 0 .186.009.275l.658-.657.556-.555 5.444-5.444.644-.646.578-.577A4.008 4.008 0 0 0 23.997 14Zm8.209.883a.667.667 0 0 0-.458.2l-16.44 16.446a.667.667 0 1 0 .943.943l3.064-3.072a6.635 6.635 0 0 0 4.015 1.9v2.038a.667.667 0 1 0 1.333 0V31.3a6.676 6.676 0 0 0 6-6.633v-1.556a.667.667 0 1 0-1.333 0v1.556A5.335 5.335 0 0 1 24.076 30h-.023a.642.642 0 0 0-.134 0 5.3 5.3 0 0 1-3.662-1.54l.945-.945a3.99 3.99 0 0 0 6.794-2.848V20.72l4.694-4.694a.667.667 0 0 0-.482-1.143Zm-14.209 7.561a.666.666 0 0 0-.667.667v1.556a6.635 6.635 0 0 0 .478 2.475l1.053-1.054a5.182 5.182 0 0 1-.2-1.422v-1.555a.666.666 0 0 0-.664-.667Z"
    />
  </svg>
)
