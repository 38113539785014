import moment from "moment";

export const getFilterDate=(filterDuration)=>{
    var currentDate = new Date();
    var tempFilterFromDate = new Date(currentDate);
    var filterFromDate = "";
    switch (filterDuration) {
      case "Max":
        return null;
      case "5Y":
        tempFilterFromDate.setFullYear(tempFilterFromDate.getFullYear() - 5);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
        return filterFromDate

      case "3Y":
        tempFilterFromDate.setFullYear(tempFilterFromDate.getFullYear() - 3);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
          return filterFromDate
      case "1Y":
        tempFilterFromDate.setFullYear(tempFilterFromDate.getFullYear() - 1);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
          return filterFromDate

      case "6M":
        tempFilterFromDate.setMonth(tempFilterFromDate.getMonth() - 6);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
        return filterFromDate

      case "1M":
        tempFilterFromDate.setMonth(tempFilterFromDate.getMonth() - 1);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");

          return filterFromDate
         default:
        tempFilterFromDate = "";
        return null;
    }
}

export const returnChartData=(paymentHistoryData)=>{

    const chartData={
      label:[],
      data:[]
    };

    let tempLable = [];
    let tempData = [];
    let previousDate = "";
    chartData.label = tempLable;
    chartData.data = tempData;

    for (let i = 0; i < paymentHistoryData.length; i++) {
      let tempDate = moment(paymentHistoryData[i].created_at)
        .tz(moment.tz.guess())
        .format("DD MMM")
        .toString();
      if (i === 0) {
        tempLable = chartData.label.concat(
          moment(paymentHistoryData[i].created_at)
            .tz(moment.tz.guess())
            .format("DD MMM")
            .toString()
        );
        tempData = chartData.data.concat(paymentHistoryData[i].amount_tagged);
        chartData.label = tempLable;
        chartData.data = tempData;
      } else if (tempDate === previousDate) {
        chartData.data[i - 1] =
          chartData.data[i - 1] + paymentHistoryData[i].amount_tagged;
      } else {
        tempLable = chartData.label.concat(
          moment(paymentHistoryData[i].created_at)
            .tz(moment.tz.guess())
            .format("DD MMM")
            .toString()
        );
        tempData = chartData.data.concat(paymentHistoryData[i].amount_tagged);
        chartData.label = tempLable;
        chartData.data = tempData;
        previousDate = tempDate;
      }
    }

    console.log(chartData,"chartData")
    return chartData;
}