import { useApolloClient } from "@apollo/client";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SimpleMap from "../../../components/simpleMapWithMarker";
import { GET_PARKING_DETAILS } from "../../../graphql/query/agreementParking";
import { generateImageUrl, img_size, ParkingUnitCategoryId } from "../../../utils";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { ParkinguseStyles } from "./style";
import { withNamespaces } from "react-i18next";
const DrawerDetails = (props) => {
    const { t=()=>false } = props
    const size = useWindowDimensions();
    const classes = ParkinguseStyles();
    const client = useApolloClient();
    const [details, setDetails] = React.useState()
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        client.query({
            query: GET_PARKING_DETAILS,
            fetchPolicy: 'network-only',
            variables: {
                id: props?.selectedUnit,
                type: ParkingUnitCategoryId
            }

        }).then((res) => {
            setDetails(res.data?.unit?.[0])
            setLoading(false)

        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])
    return (
        <div className={classes.rootdetails}>

            <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                    <Typography className={classes.title}>{t("Parking")}</Typography>
                </Box>
                <Box>
                    <IconButton size="small" onClick={props?.closeDrawer}>
                        <CloseIcon />
                    </IconButton>
                </Box>

            </Box>
            {
                loading ?
                    <Box display="flex" alignItems="center" height={size?.height - 120} justifyContent="center">
                        <CircularProgress />
                    </Box>
                    :
                    <div style={{ height: 'auto', overflow: 'auto', paddingLeft: '10px', paddingRight: "5px" }}>
                        {/* assets */}
                        <Slider dots>
                            {details?.unit_assets.map((val) => {
                                return (
                                    <img
                                        src={generateImageUrl(val?.url, img_size.medium_rectangle)}
                                        className={classes.parkingImg}
                                        alt=""
                                    />
                                );
                            })}
                        </Slider>
                        {/* details of units */}
                        <Box
                            style={{ flexFlow: "wrap !important" }}
                            display="flex"
                            p={1}
                            alignItems="center"
                            className={classes.titleroorgary}
                        >
                            <Box flexGrow={1}>
                                <Typography className={classes.title} style={{ fontSize: "1rem" }}>
                                    {details?.name}
                                </Typography>

                            </Box>
                        </Box>
                        <Grid container marginLeft="4px" className={classes.titleroot2} marginTop="-10px" paddingBottom="10px">
                            <Grid item>
                                <Typography className={classes.bottomsub} noWrap>
                                    {details?.property.name}
                                </Typography>
                            </Grid>
                            {details?.block?.name &&
                                <Grid item>
                                    <Typography className={classes.bottomsub} noWrap>
                                        <ChevronRightIcon />   {details?.block?.name}
                                    </Typography>
                                </Grid>
                            }
                            {details?.floor?.name &&
                                <Grid item>
                                    <Typography className={classes.bottomsub} noWrap>
                                        <ChevronRightIcon />  {details?.floor?.name}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                        <Box
                            display="flex"
                            p={1.3}
                            alignItems="center"
                            justifyContent="center"
                            className={classes.titleroot2}
                        >

                            <img
                                sx={{ padding: "10px" }}
                                src="/images/parkingagrrement.svg"
                                alt="altimage"
                            />
                            <Box width="8px" />
                            <Typography className={classes.parkingNo}>
                                {details?.parking_master?.[0]?.parking_no}
                            </Typography>

                        </Box>
                        <Box
                            dtyle={{ display: 'flex', alignItems: 'center' }}
                            p={1}
                            className={classes.titleroot2}
                        >
                            <Typography className={classes.title}>
                                {t("Description")}
                            </Typography>
                            <Typography className={classes.bottomsub}>
                                {details?.parking_master?.[0]?.description}
                            </Typography>
                        </Box>
                        <Box display="flex" p={2} alignItems="center">
                            <Box flexGrow={1} display="flex" alignItems="center">
                                <img src="/images/Location.svg" alt="address" />
                                <Typography className={classes.bottomDetails}>
                                    &nbsp; &nbsp;
                                    {details?.address?.street_1}
                                    {details?.address?.street_1 ? `, ` : ""}
                                    {details?.address?.street_2}
                                    {details?.address?.street_2 ? ", " : ""}
                                    {details?.address?.street_3}
                                    {details?.address?.street_3 ? ", " : ""}
                                    {details?.address?.city}
                                    {details?.address?.city ? ", " : ""}
                                    {details?.address?.district}
                                    {details?.address?.district ? ", " : ""}
                                    {details?.address?.country}
                                    {details?.address?.country ? "," : ""}
                                </Typography>
                            </Box>
                        </Box>
                        {
                            details?.address?.latitude && details?.address?.longitude ?
                                <>
                                    <SimpleMap
                                        // lat={parseFloat(props?.unitdetails?.latitude)}
                                        // lng={parseFloat(props?.unitdetails?.longitude)}
                                        // center={{
                                        //       lat: parseFloat(props?.unitdetails?.latitude),
                                        //       lng: parseFloat(props?.unitdetails?.longitude),
                                        // }}
                                        lat={parseFloat(details?.address?.latitude)}
                                        lng={parseFloat(details?.address?.longitude)}
                                        center={{
                                            lat: parseFloat(details?.address?.latitude),
                                            lng: parseFloat(details?.address?.longitude)
                                        }}
                                        zoom={15}
                                    />
                                </>
                                : ""
                        }

                    </div>
            }
        </div >
    )
}

export default withNamespaces("agreementParking")(DrawerDetails)