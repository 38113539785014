export const ServiceAssetIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g id="Group_112333" data-name="Group 112333" transform="translate(-32 -78)">
                <rect id="Rectangle_57270" data-name="Rectangle 57270" width="48" height="48" rx="8" transform="translate(32 78)" fill="#f1f7ff" />
                <g id="Group_113308" data-name="Group 113308">
                    <g id="Rectangle_57449" data-name="Rectangle 57449" transform="translate(44 90)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                        <rect width="24" height="24" stroke="none" />
                        <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                    </g>
                    <path id="icons8-edit-property" d="M8.3,6A3.3,3.3,0,0,0,5,9.3V24a3.6,3.6,0,0,0,3.6,3.6h6.963c.152-.575.372-1.345.674-2.4H8.6A1.2,1.2,0,0,1,7.4,24V12h18v3.913a3.7,3.7,0,0,1,.787-.248A3.576,3.576,0,0,1,26.9,15.6a3.912,3.912,0,0,1,.9.108V9.3A3.3,3.3,0,0,0,24.5,6Zm1.8,8.4a.9.9,0,1,0,0,1.8h1.2a.9.9,0,1,0,0-1.8Zm4.8,0a.9.9,0,1,0,0,1.8h7.8a.9.9,0,1,0,0-1.8Zm12,2.4a2.822,2.822,0,0,0-.5.046,2.782,2.782,0,0,0-1.465.8l-7.079,7.079a1.8,1.8,0,0,0-.456.771l-.942,3.288a.911.911,0,0,0-.059.355.89.89,0,0,0,.858.86.914.914,0,0,0,.357-.059L20.9,29a1.8,1.8,0,0,0,.77-.456l7.08-7.079a2.782,2.782,0,0,0,.8-1.465,2.877,2.877,0,0,0,.045-.5,2.7,2.7,0,0,0-2.7-2.7ZM10.1,18a.9.9,0,1,0,0,1.8h1.2a.9.9,0,1,0,0-1.8Zm4.8,0a.9.9,0,0,0,0,1.8h6.185l1.782-1.784A.907.907,0,0,0,22.7,18Zm-4.8,3.6a.9.9,0,1,0,0,1.8h1.2a.9.9,0,1,0,0-1.8Zm4.8,0a.9.9,0,0,0,0,1.8h2.585l1.8-1.8Z" transform="translate(38.701 84)" fill="#78b1fe" />
                </g>
            </g>
        </svg>

    )
}