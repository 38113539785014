import React from "react";
import Statementofaccounts from './statementofaccount';
import { withBottombar } from "../../HOCs";


class StatementofaccountParent extends React.Component {
    render() {
        return <Statementofaccounts {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(StatementofaccountParent, props);
