import React from "react";
import ManagementDetails from "./details";
import { withBottombar } from "../../HOCs";

class ManagementDetailsParent extends React.Component {
  render() {
    return <ManagementDetails {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(ManagementDetailsParent, props);
