import Axios from "axios";
import { config } from "../config";
import { getClient, LocalStorageKeys, NetWorkCallMethods, RetainLocalStorage } from "../utils";
import { Routes } from "../router/routes";

export const NetworkCall = (
  url,
  method,
  body,
  headers,
  isAuthorized = false,
  notValidateURL = false,
  otherProps = {}
) => {
  //Check for URL,method,body
  if (!url && !method) {
    return Promise.reject({ message: "URL and HTTP Method is not mentioned." });
  }

  // Check for proper URL
  // if (url && !notValidateURL) {
  //   const expression =
  //     /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  //   const regex = new RegExp(expression);

  //   // if (!url.match(regex)) {
  //   //   return Promise.reject({ message: "Malformed URL, Please check" });
  //   // }
  // }

  //Check for body of the POST method
  if (method && method === NetWorkCallMethods.post && !body) {
    return Promise.reject({
      message: "POST method must contain Request Body.",
    });
  }

  // //Checking the Internet connection
  if (!navigator.onLine) {
    return Promise.reject({ code: 503, message: "Unable to connect with Internet!" });
  }

  //Initializing the header
  let newHeader = {
    ...headers,
    "x-build-code": config.app_x_build,
  };

  //Adding Authorization to headers if it is requested
  if (isAuthorized) {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    //get slug
    let client = getClient(token)
    let slug = client?.tenants?.slug
    newHeader = {
      ...headers,
      "Referer":"https://resident.pms2.propgoto.com/",
      "x-build-code": config.app_x_build,
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + localStorage.getItem(LocalStorageKeys.authToken),
      Slug: `${slug}`,
      "x-api": config.public_listing_api_key
    };
  }

  return Axios({
    method: method,
    url: url,
    data: body,
    headers: newHeader,
    ...otherProps
  }).catch((error) => {
    if (error?.response?.status === 403 && error?.response?.data?.error_subcode === 463) {
      RetainLocalStorage();
      localStorage?.setItem(LocalStorageKeys?.alert_msg, "Your Session has been ended, Login Again!");
      window.location.reload();
    } else if (error?.response?.status === 423) {
      window.location.href = Routes?.backupAndRestoreError
      RetainLocalStorage();
    } else {
      return Promise.reject(error);
    }
  });
};
