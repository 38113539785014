import { Box, Typography } from '@mui/material'
import React from 'react'
import { PropertyCardStyle } from './style'
// import LocationSvg from '../../../assets/locationSvg'

export const PropertyCard = ({ cardData = {}, click = () => false, status = "" }) => {
    const classes = PropertyCardStyle()
    return (
        <Box className={classes.box} p={2}>
            <Typography className={classes.name}> {cardData?.name} </Typography>
            
        </Box>
    )
}