import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  bottomTitle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    font: "normal Nunito Sans",
    textTransform: "capitalize",
  },
  sqft: { color: "#98A0AC", fontFamily: FontFamilySwitch().regular },
  bottomsub: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    marginTop: "4px",
    textTransform: "capitalize",
  },
  titleroot: {
    borderBottom: "1px solid #E4E8EE",
    padding: "16px 8px",
  },
  categoryTitle: {
    fontSize: "0.75rem",
    backgroundColor: "#F1E6FE",
    padding: "4px 6px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5D427F",
  },
  typeTitle: {
    fontSize: "0.75rem",
    backgroundColor: "#F0F4FF",
    padding: "4px 6px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5078E1",
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  contentBottom: {
    padding: "12px",
  },
  unitImg: {
    height: "250px",
    width: "100%",
    borderRadius: "12px",
    objectFit:"cover"
  },
  titleroot2: {
    borderBottom: "1px solid #E4E8EE",
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    flexFlow: "wrap !important",
  },
  phone: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
  },
  bottomDetails: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },
  screenPadding: {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  call: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().semiBold,
    textDecoration: "none",
    color: theme.typography.color.secondary,
  },
  callbox: {
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
    mapImg: {
      width: "32px",
      height: "32px",
      borderRadius: "8px",
    },
  },
  ownerDetails: {
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "#F5F7FA",
  },
  ownerDetailsTxt: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#98A0AC",
  },
  ownerProfile: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
  ownerName: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },

  ownerContact: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#091B29",
  },
  ownerDescription: {
    marginTop: "10px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
}));
