import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    backgroundColor: theme?.palette?.background?.file,
    position: "relative",
  },
  content: {
    padding: "16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  main: {
    padding: "16px",
    height: `calc(100vh - 119px)`,
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  topBtn: {
    padding: "12px",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "4px",
  },
  navTopBar: {
    backgroundColor: "white",
    borderRadius: "4px",
  },
  folderName: {
    color: theme?.typography?.color?.secondary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
  },
  listView: {
    backgroundColor: "white",
    padding: "18px 14px 6px",
  },
  addBtn: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  },
  addIcon: {
    color: "white",
  },
  create: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    padding: "10px 15px",
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.background?.tertiary1,
    },
  },
  viewFileSub: {
    color: theme?.typography?.color?.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  viewFileTilte: {
    color: theme?.typography?.color?.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold
  },
  detailsBox: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    backgroundColor: theme?.palette?.background?.background

  },
  viewDetailsTilte: {
    color: theme?.typography?.color?.Tertiary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  viewDetailsSub: {
    color: theme?.typography?.color?.primary,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold
  },
  outLine: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.primary,
    backgroundColor: "white",
    padding: "10px 15px",
    width: "100%",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    "&:hover": {
      color: theme?.typography?.color?.primary,
      backgroundColor: "white",
      border: `1px solid ${theme?.palette?.border?.secondary}`,
    },
  },
  deleteTitle: {
    color: theme?.typography?.color?.primary,
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold
  },
  paddingLeft: {
    paddingLeft: "12px"
  },
  paddingTop: {
    paddingTop: "12px !important"
  },
  list: {
    padding: '10px 14px 5px',
    background: "white"
  }
}));
