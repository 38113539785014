import { Box, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  MobileNumberInputComponent,
  DatePickerNew,
  TextBox,
  DatePickerTimeNew,
} from "../../components";
import { SelectBox } from "../../components/dropDown";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.875rem",
    marginBottom: "5px",
  },
}));

export const FormGenerator = (props) => {
  const { components = [] } = props;

  const switchComponents = (val) => {
    if (val?.isActive) {
      switch (val.component) {
        case "text":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <TextBox
                isRequired={val?.isRequired}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val.value}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                multiline={val?.multiline}
                isReadonly={val?.isReadonly}
                type={val?.type}
                endAdornment={val?.endAdornment}
                startAdornment={val?.startAdornment}
                fontSize={val?.fontSize}
              />
            </Grid>
          );
        case "button":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <Button
                sx={{
                  padding: "8px",
                  boxShadow: "none",
                  marginTop: val?.top ?? "4px",
                }}
                onClick={val?.onClick}
                variant={val?.variant ?? "contained"}
                fullWidth
                disabled={val?.disable}
              >
                {val?.label}
              </Button>
            </Grid>
          );
        case "select":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <SelectBox
                menuPlacement={val?.menuPlacement}
                parentID={val?.id}
                isRequired={val?.isRequired}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val.value}
                zIndex={val?.zIndex}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                isReadOnly={val?.isReadonly}
                isPaginate={val?.isPaginate}
                loadOptions={val?.loadOptions}
                loading={val?.loading}
                debounceTimeout={800}
                options={val?.options ?? []}
                key={val?.key}
              />
            </Grid>
          );
        case "date":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <DatePickerNew
                isRequired={val?.isRequired}
                minDate={val?.minDate}
                maxDate={val?.maxDate}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val?.value}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                isReadonly={val?.isReadonly}
              />
            </Grid>
          );
        case "mobile":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <MobileNumberInputComponent
                isRequired={val?.isRequired}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val.value}
                handleChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
                multiline={val?.multiline}
                isReadonly={val?.isReadonly}
              />
            </Grid>
          );
        case "datePickerWithTime":
          return (
            <Grid
              item
              xs={val?.size?.xs}
              sm={val?.size?.sm}
              md={val?.size?.md}
              lg={val?.size?.lg}
            >
              <DatePickerTimeNew
                isRequired={val?.isRequired}
                isTime={val?.isNot ? false : true}
                minDate={val?.minDate}
                maxDate={val?.maxDate}
                label={val?.label}
                placeholder={val?.placeholder}
                value={val?.value}
                onChange={val?.onChange}
                isError={val?.error?.length > 0}
                errorMessage={val?.error}
              />
            </Grid>
          );
        default:
          return null;
      }
    }
  };

  return (
    <Box>
      {
        <Grid container spacing={2}>
          {components?.length &&
            components?.map((_) => <>{switchComponents(_)}</>)}
        </Grid>
      }
    </Box>
  );
};
