export const TermOfService = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 108907" transform="translate(-108 -267)">
            <rect
                width={40}
                height={40}
                fill="#f1f7ff"
                data-name="Rectangle 55935"
                rx={4}
                transform="translate(108 267)"
            />
            <path
                fill="#6a68cc"
                stroke="#6a68cc"
                strokeLinecap="round"
                strokeWidth={0.2}
                d="M124.438 275.5a5.937 5.937 0 0 0-5.938 5.938v12.1a2.5 2.5 0 0 0 1.113 2.08l.762.508v-14.688a4.062 4.062 0 0 1 4.063-4.062h10.937l-.508-.762a2.5 2.5 0 0 0-2.08-1.114Zm.625 3.125a3.452 3.452 0 0 0-3.437 3.438v13.75a3.452 3.452 0 0 0 3.438 3.438h10a3.452 3.452 0 0 0 3.436-3.438v-13.75a3.452 3.452 0 0 0-3.437-3.437Zm0 1.875h10a1.549 1.549 0 0 1 1.563 1.563v13.75a1.549 1.549 0 0 1-1.562 1.563h-10a1.549 1.549 0 0 1-1.564-1.563v-13.75a1.549 1.549 0 0 1 1.563-1.563Zm1.875 3.75a.938.938 0 1 0 0 1.875h6.25a.938.938 0 1 0 0-1.875Zm0 3.75a.938.938 0 1 0 0 1.875h6.25a.938.938 0 1 0 0-1.875Zm0 3.75a.938.938 0 1 0 0 1.875h6.25a.938.938 0 1 0 0-1.875Z"
                data-name="icons8-terms-and-conditions (1)"
            />
        </g>
    </svg>
);
