import * as React from "react";
import { Grid, Box, Checkbox, Typography } from "@mui/material";
import { FontFamilySwitch } from "../../../utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const Selection = (props) => {
  const { t=()=>false } = props

  return (
    <>
      {props?.data?.length > 0 ?
        <>
          {props?.data.map((list) => (
            <>
              <Box ml={1} sx={{ cursor: "pointer" }} onClick={props?.type === "block" ? () => props?.setSelectedBolck(list) : () => props?.setSelectedFloor(list)}>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Typography sx={{
                      fontSize: "0.75rem",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}>
                      {list?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box textAlign="right">
                      <Checkbox
                        checked={props?.type === "block" ? props?.selectedBlock?.id === list?.id : props?.selectedFloor?.id === list?.id}
                        // onChange={() =>
                        // }
                        icon={<RadioButtonUncheckedIcon fontSize="small" sx={{ color: "#CED3DD" }} />}
                        checkedIcon={<CheckCircleIcon fontSize="small" sx={{ color: "#5078E1" }} />}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <hr color="#eaeef4" />
            </>
          ))}
        </> :
        <>
          <Typography my={2} variant="body1" align="center">{t("No Data Found!")}</Typography>
        </>
      }
    </>
  );
};

export default Selection;
