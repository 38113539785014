import * as React from "react"
const BookingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 119262">
      <g data-name="Group 119696">
        <path
          fill="#a9afbc"
          d="M9.9 0a2.706 2.706 0 0 0-2.67 2.4H5.1a2.714 2.714 0 0 0-2.7 2.7v16.2A2.714 2.714 0 0 0 5.1 24h13.8a2.714 2.714 0 0 0 2.7-2.7V5.1a2.714 2.714 0 0 0-2.7-2.7h-2.13A2.706 2.706 0 0 0 14.1 0Zm0 1.8h4.2a.9.9 0 1 1 0 1.8H9.9a.9.9 0 0 1 0-1.8ZM5.1 4.2h2.562A2.707 2.707 0 0 0 9.9 5.4h4.2a2.707 2.707 0 0 0 2.238-1.2H18.9a.886.886 0 0 1 .9.9v16.2a.886.886 0 0 1-.9.9H5.1a.886.886 0 0 1-.9-.9V5.1a.886.886 0 0 1 .9-.9Zm2.4 4.2a.9.9 0 1 0 0 1.8h9a.9.9 0 1 0 0-1.8Zm0 4.8a.9.9 0 1 0 0 1.8h4.2a.9.9 0 1 0 0-1.8Zm0 4.8a.9.9 0 1 0 0 1.8h6.6a.9.9 0 1 0 0-1.8Z"
          data-name="icons8-clipboard (1)"
        />
      </g>
    </g>
  </svg>
)
export default BookingIcon
