import { makeStyles } from "@mui/styles";

export const amenitiesDetailsStyle = makeStyles((theme) => ({
    backbutton: {
        position: "absolute",
        top: "15px",
        left: 15,
        zIndex: 3,
        border: `1px solid ${theme.palette.background.primary} `,
        height: 20,
        width: 20,
        backgroundColor: "#00000082",
        padding: 2
    },
    position: {
        position: "relative",
        zIndex: 1,
        backgroundColor: "transparent",
        borderRadius: '4px',
        top: "0%",
        // transform: "translate(0%,-20%)",
        transform: "translateY(-36px)",
        height: "auto",
        transition: "all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1)"

        // position: "relative",
        // top: "50%",
        // left:"50%",
        // transform: "translate(-5%,-17%)",
        // height: "calc(100vh - 745px)"
    },
    fullposition: {
        position: "relative",
        zIndex: 1,
        backgroundColor: "transparent",
        borderRadius: '4px',
        top: "50%",
        transform: "translateY(2%)",
        height: "auto",
        // position: "relative",
        // top: "50%",
        // left: "50%",
        // transform: "translate(-56%, 4%)",
        animation: `$scrollEffect 500ms ease-out`
    },
    headerTransOn: {
        transform: "translateY(6%)",
        transition: " 0.5s cubic-bezier(0.25, 0.1, 0.25, 1)"
    },
    headerTrans: {
        transform: "translateY(0%)",
        transition: " 0.5s cubic-bezier(0.25, 0.1, 0.25, 1)"
    },
    backbtn: {
        height: 32,
        width: 32,
        background: "#00000082",
        color: "#fff",
        position: "relative",
        top: "30px",
        left: "30px",
        transform: "translate(-50%,-50%)",
        zIndex: 3,
        border: '1px solid #fff',
    },
    navigate: {
        background: "transparent",
        height: 0
    },
    backbtn1: {
        height: 32,
        width: 32,
        background: "#fff",
        color: theme.typography.color.secondary,
        position: "relative",
        top: "15px",
        left: "30px",
        transform: "translate(-50%,-50%)",
        zIndex: 3,
        border: `1px solid ${theme.palette.border.secondary}`,
        marginTop: 6
    },
    topnav: {
        height: 70,
        background: "#fff"
    },
    scrollAdd: {
        height: 'calc(100vh - 90px)',
        overflow: "auto",
        // marginTop: 14,
    },
    viewbtn: {
        backgroundColor: theme.palette.info.main,
        borderRadius: '4px',
        color: "#fff",
        width: 50,
        padding: 2,
        textAlign: "center",
        fontSize: "0.75rem",
        fontWeight: "bold",
        cursor: "pointer"
    },
    box: {
        borderRadius: '4px',
        backgroundColor: "#fff",
        height: "auto",
        border: `1px solid ${theme.palette.border.secondary}`,
    },
    name: {
        fontSize: "0.875rem",
        color: theme.typography.color.primary,
        fontWeight: "bold"
    },
    subtitle: {
        fontSize: "0.75rem",
        color: theme.typography.color.Tertiary,
    },
    topPropertyCard: {
        // position: "relative",
        // zIndex: 3,
        // transform: "translate(-56%,-90%)",
        // top: "50%",
        // left: "50%"

        position: "relative",
        zIndex: 3,
        backgroundColor: "white",
        borderRadius: '4px',
        top: "-3%",
        left: "44%",
        transform: "translate(-50%,-50%)",
        padding: "0 !important"
    },
    "@keyframes scrollEffect": {
        "0%": {
            opacity: 0,
            transform: "translateY(25%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(7%)"
        }
    },
    noAmenity: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    normalBg: {
        background: "#F5F7FA", padding: "0 !important",
        height: 'calc(100vh - 10px)'
    },
    noAmenityBg: {
        background: "#F5F7FA", padding: "0 !important"
    },
    listItem: {
        // height: "calc(100vh - 150px)"
    },
    fulllistItem: {
        // height: "calc(100vh - 95px)"
    },
    bannerImg: {
        height: 300,
        width: "100%",
        borderRadius:0
    }
}))