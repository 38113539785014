export const InitialData = (t, current_date) => ({
    pass_details: {
        unit: "",
        entry_type: EntryType(t)?.[0],
        start_date: current_date,
        end_date: current_date,
        start_hour: "09",
        start_hour_meridiem: t("AM"),
        end_hour: "06",
        end_hour_meridiem: t("PM"),
        is_group: "",
        group_count: "1",
        error: {
            unit: "",
            entry_type: "",
            start_date: "",
            end_date: "",
            start_hour: "",
            start_hour_meridiem: "",
            end_hour: "",
            end_hour_meridiem: "",
            group_count: "",
        },
    },
    worker_details: [],
    work_information: {
        work_description: "",
        is_any_gate: true,
        gate: "",
        is_tenant_improvement: false,
        is_facility_member_needed: false,
        is_noisy: false,
        is_flame: false,
        is_vendor_safety: false,
        is_hazardous: false,
        error: {
            work_description: "",
            gate: "",
        }
    },
});

export const InitialWorkerDetails = {
    id: null,
    index: null,
    registered_worker_id: null,
    is_deleted: false,
    profile_image: "",
    name: "",
    mobile: "",
    email_id: "",
    proof_type: "",
    proof_id: "",
    proof_assets: [],
    registered_worker_ref_no: "",
    profession: "",
    error: {
        profile_image: "",
        name: "",
        mobile: "",
        email_id: "",
        proof_type: "",
        proof_id: "",
        proof_assets: "",
    },
}

export const EntryType = (t) => ([
    {
        label: t("Single"),
        value: "single",
        description: t("This Pass can be used once during visit"),
        start_date: true,
        start_date_placeholder: t("Select Visit Date"),
    },
    {
        label: t("Multiple"),
        value: "multiple",
        description: t("This Pass can be used multiple times during the visit in specified date"),
        start_date: true,
        end_date: true,
        start_date_placeholder: t("Start Date"),
        end_date_placeholder: t("End Date"),
    },
]);

export const Questionaries = [
    { key: "is_tenant_improvement", question: ("Is this a Tenant Improvement (TI)?"), extra_info: "" },
    { key: "is_facility_member_needed", question: ("Is a Facilities Staff member needed?"), extra_info: "" },
    { key: "is_noisy", question: ("Will the work be Noisy?"), extra_info: "" },
    { key: "is_flame", question: ("Flames"), extra_info: "" },
    { key: "is_vendor_safety", question: ("Vendor Safety Certificate on File"), extra_info: "" },
    { key: "is_hazardous", question: ("Hazardous"), extra_info: "(Material/ Chemical Use/ Fumes/ Paint)" },
]