import React from 'react'
import { useLocation } from 'react-router';
import PassDetails from './passDetails';

const WorkerGatePassDetails = () => {
const searchURL = useLocation().search;
const passId = new URLSearchParams(searchURL).get("passId");
  return (
     <PassDetails passId={passId} />
  )
}

export default WorkerGatePassDetails;