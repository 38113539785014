import { Box, Container } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import CompleteInspection from "../../components/inspectionCard/component/completeInspection";
import { withBottombar } from "../../HOCs";
const ServiceUnitCompleted = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const classes = useStyles();

  React.useEffect(() => {
    handleTopBarState({
      text: "Service Records Orders"
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Container maxWidth="sm" className={classes.root}>
        <Box height={size?.height - 68} sx={{ backgroundColor: "#f6f6f6" }}>
          {/* <TitleBar text="Service Records Orders" goBack={() => history.goBack()} /> */}
          <CompleteInspection 
          unitName = "Unit Name Here" 
          propertyName = "Property Name, Apartment"
          inspectorDetails = "INSPECTOR DETAILS"
          inspectorName = "Inspector Name"
          inspectorID = "inspector ID"
          image="https://protomate.objectstore.e2enetworks.net/rayDcode/Amenity%20Company/Thumbnails/1665207460547-pexels-photo-269077.jpeg"
          avatar= "H"
          />
        </Box>
      </Container>
    </>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("unitCompleted")(ServiceUnitCompleted), props);
