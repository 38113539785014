import { Avatar, Box, Divider, Stack, Typography } from "@mui/material"
import Laundry from "../../../assets/laundry";
import { useStyles } from "../style"

const InventoryCard = (props) => {
    const classes = useStyles()
    const { data = {}, handleClick = () => false, t = () => false } = props;
    return (
        <>
            <Stack my={"12px"} direction={"row"} alignItems={"flex-start"} spacing={"8px"} onClick={() => handleClick()}>
                <Stack direction={"column"}>
                    <Avatar className={classes.avatar}>
                        <Laundry />
                    </Avatar>
                </Stack>
                <Box flexGrow={1}>
                    <Stack direction={"column"} spacing={"4px"}>
                        <Typography className={classes.unitName}>{data?.name}</Typography>
                        <Typography className={classes.quantity}>{data?.count} {t("Quantity")}</Typography>
                    </Stack>
                </Box>
                <Box className={classes.box}>
                    <Typography className={classes.services}>{data?.inspection_type}</Typography>
                </Box>
            </Stack>
            <Divider />
        </>
    )
}

export default InventoryCard;