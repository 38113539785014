import { Button, Stack, Typography } from "@mui/material";
import { Routes } from "../../router/routes";
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";

const KycExpiryCard = () => {

    const classes = useStyles();
    const history = useHistory();
    // const goPage = () => {
    //     history.push(Routes.kycInformation)
    // }

    return (
        <div className={classes.expiryCard}>
            <Stack alignItems={"center"} spacing={"5px"} justifyContent="space-between" direction={"row"}>
            
            <img src="/images/kycExpiry.svg" alt="" />
            <Typography className={classes.expiryDetails}>Your <b>KYC Proof</b> about to <b>expire</b> please <b>update valid proof</b></Typography>
            <Button onClick={() => history.push(Routes.kycInformation)} className={classes.update}>Update</Button>
            </Stack>
            
        </div>
    )
}

export default KycExpiryCard;