import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalculate } from "../../../utils";

export const componentStyles = makeStyles((theme) => ({
    topCardRoot:{
        backgroundColor:"white",
        borderRadius:"12px",
        padding:"16px",
        boxShadow:"0px 3px 30px #4354721F"
    },
    topCardTitle:{
        color:"#091B29",
        fontFamily:FontFamilySwitch()?.bold,
        fontSize:remCalculate(14)
    },
    topCardDes:{
        color:"#4E5A6B",
        fontFamily:FontFamilySwitch().regular,
        fontSize:remCalculate(12),
        marginTop:"4px"
    },
    remarks:{
        color:"#4E5A6B",
        fontFamily:FontFamilySwitch().regular,
        fontSize:remCalculate(12),
        marginTop:"12px",
        backgroundColor:"#F5F7FA",
        borderRadius:"4px",
        padding:"12px"
    },
    detailCardTitle:{
        color:"#071741",
        fontFamily:FontFamilySwitch()?.bold,
        fontSize:remCalculate(16),
        marginBottom:"12px"
    },
    detailLabel:{
        color:"#091B29",
        fontFamily:FontFamilySwitch()?.semiBold,
        fontSize:remCalculate(14)
    },
    detailValue:{
        color:"#4E5A6B",
        fontFamily:FontFamilySwitch()?.semiBold,
        fontSize:remCalculate(14),
        float:"right"
    },
    submitFormBtn:{
        padding:"12px",
        textTransform:"capitalize",
        boxShadow:"none",
        marginTop:"12px",
        fontSize:remCalculate(14),
        fontFamily:FontFamilySwitch()?.semiBold,
     borderRadius:"8px"
    },
    viewPdf:{
        fontSize:remCalculate(14),
        textTransform:"capitalize",
        textDecoration:"underline",
        fontFamily:FontFamilySwitch()?.semiBold,
        color:"#5078E1",
        cursor:"pointer"
    }
}));