import * as React from "react";

const Dispute = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="Group_108759" data-name="Group 108759" transform="translate(4342 10930)">
        <rect id="Rectangle_55778" data-name="Rectangle 55778" width="40" height="40" rx="8" transform="translate(-4342 -10930)" fill="#fff2ee" />
        <g id="icons8-technical-support" transform="translate(-4334.647 -10922.353)">
          <path id="Path_99638" data-name="Path 99638" d="M13,30H25.941v7.059H13Z" transform="translate(-7.118 -13.529)" fill="#bf360c" />
          <path id="Path_99639" data-name="Path 99639" d="M8.353,22a2.353,2.353,0,1,0,2.353,2.353A2.353,2.353,0,0,0,8.353,22Zm16.471,0a2.353,2.353,0,1,0,2.353,2.353A2.353,2.353,0,0,0,24.824,22Z" transform="translate(-4.235 -10.235)" fill="#ffa726" />
          <path id="Path_99640" data-name="Path 99640" d="M26.647,15.723C26.647,8.252,9,10.84,9,15.723V21.6a8.824,8.824,0,0,0,17.647,0Z" transform="translate(-5.471 -5.723)" fill="#ffb74d" />
          <path id="Path_99641" data-name="Path 99641" d="M24.235,24a1.176,1.176,0,1,0,1.176,1.176A1.176,1.176,0,0,0,24.235,24Zm-7.059,0a1.176,1.176,0,1,0,1.176,1.176A1.176,1.176,0,0,0,17.176,24Z" transform="translate(-8.353 -11.059)" fill="#784719" />
          <path id="Path_99642" data-name="Path 99642" d="M15.353,2C10.353,2,3,5.412,3,21.765l5.882,3.765V14.941l9.882-5.765,3.059,4V25.529l5.882-4.824c0-3.294-.529-17.059-9.059-17.059L17.824,2Z" transform="translate(-3 -2)" fill="#ff5722" />
          <path id="Path_99643" data-name="Path 99643" d="M33.882,14.941a.556.556,0,0,0-.588.588V11.412A9.439,9.439,0,0,0,23.882,2H18.588A.556.556,0,0,0,18,2.588a.556.556,0,0,0,.588.588h5.294a8.26,8.26,0,0,1,8.235,8.235v5.882a.588.588,0,1,0,1.176,0v1.176a4.092,4.092,0,0,1-4.118,4.118H21.529a.588.588,0,0,0,0,1.176h7.647a5.272,5.272,0,0,0,5.294-5.294V15.529A.556.556,0,0,0,33.882,14.941Z" transform="translate(-9.176 -2)" fill="#757575" />
          <g id="Group_108763" data-name="Group 108763" transform="translate(11.176 11.765)">
            <path id="Path_99644" data-name="Path 99644" d="M35.529,22h-.588a1.18,1.18,0,0,0-1.176,1.176v2.353a1.18,1.18,0,0,0,1.176,1.176h.588a1.18,1.18,0,0,0,1.176-1.176V23.176A1.18,1.18,0,0,0,35.529,22ZM23.176,30.235a1.176,1.176,0,1,0,1.176,1.176A1.176,1.176,0,0,0,23.176,30.235Z" transform="translate(-22 -22)" fill="#37474f" />
          </g>
        </g>
      </g>
    </svg>

  );
};

export default Dispute;
