import { Box, Stack, Typography } from "@mui/material";
import { useStyles } from "../styles"; 
import { withNamespaces } from "react-i18next";
import PdfIcon from "../../../assets/fileManager/pdfIcon";
import { generateImageUrl, img_size } from "../../../utils";

const KycListCard = ({ data, openDrawer, setKycData, t = () => false }) => {
  const classes = useStyles();
  const open = (data) => {
    setKycData(data);
    openDrawer(true);
  };
  return (
    <Box className={classes.cardContainer} onClick={() => open(data)}>
      <Box>
        <Box position={"relative"}>
        {data?.kyc_assets?.length > 0 ?
        <>
          {data?.kyc_assets?.[0]?.type === "application" ? (
            <Box className={classes.cardImg}>
              <PdfIcon />
              <img
                className={classes.verifedImgPdf}
                src="/images/verifed.svg"
                alt=""
              />
            </Box>
          ) : (
            <>
              <img
                className={classes.cardImg}
                src={generateImageUrl(data?.kyc_assets?.[0]?.src, img_size.small_square)}
                alt=""
              />
              <img
                className={classes.verifedImg}
                src="/images/verifed.svg"
                alt=""
              />
            </>
          )}
        </>:
           <Box className={classes.cardImg}>
           <PdfIcon />
           <img
             className={classes.verifedImgPdf}
             src="/images/verifed.svg"
             alt=""
           />
         </Box>
}
        </Box>
      </Box>
      <Box flexGrow={1} alignSelf="flex-start">
        <Typography className={classes.cardTitle}>
          {data?.name}
        </Typography>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography className={classes.cardSubTitle} noWrap>
            {t(data?.type)}
          </Typography>
          <div className={classes.dot} />
          <Typography className={classes.cardSubTitle} noWrap>
            {data?.number}
          </Typography>
        </Stack>
      </Box>
      <Box alignSelf={"flex-start"}>
        {data.is_valid === true ? (
          <Typography className={classes.typeValid}>{t("Valid")}</Typography>
        ) : (
          <Typography className={classes.typeExpired}>{t("Expired")}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default withNamespaces("kycInformation")(KycListCard);
