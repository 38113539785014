import React from "react";
import { withBottombar } from "../../HOCs";
import General from './general';

class GeneralParent extends React.Component {
    render() {
        return <General {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(GeneralParent, props);
