import { Close } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import React from "react";
import { withNamespaces } from "react-i18next";

import { FontFamilySwitch } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  img: {
    height: "210px",
    width: "210px",
  },
  title: {
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    textAlign: "center",
  },
  subTitle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    textAlign: "center",
    color: "#4E5A6B",
  },
  subTitle2: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    textAlign: "center",
    color: "#4E5A6B",
  },
  contactCard: {
    width: "291px",
    height: "71px",
    padding: "13px",
    boxShadow: "0px 3px 30px #4354721F",
    borderRadius: "12px",
  },
  cardImg: {
    width: "45px",
    height: "45px",
  },
}));
const CommunityMemberModel = ({ t = () => false, onClose }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography align="right" onClick={onClose}>
        <Close sx={{ color: "#00000080" }} />
      </Typography>
      <Box className={classes.content}>
        <img className={classes.img} src="/images/communityMember.png" alt="member" />
        <Box height={"20px"} />
        <Typography className={classes.subTitle}>
          {t("Seems like you don't have")}
        </Typography>
        <Typography className={classes.title}>{t("Community Membership")}</Typography>
        <Typography className={classes.subTitle}>
          {t("to avail this service")}
        </Typography>
        <Box height={"10px"} />
        <Box className={classes.contactCard}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={2}>
              <img className={classes.cardImg} src="/images/person.png" alt="person" />
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.subTitle2}>
                {t("Please contact your property manager for membership")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box height={"20px"} />
      </Box>
    </Box>
  );
};

export default withNamespaces("service")(CommunityMemberModel);
