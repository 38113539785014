export const RequestUnselected = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 7570">
            <path
                data-name="Path 3346"
                d="M19.969 5.66a.51.51 0 0 0-.8-.11l-2.091 2.078a1.443 1.443 0 0 1-1.137-1.137l2.078-2.078a.51.51 0 0 0-.125-.793 4.852 4.852 0 0 0-5.943.961 5.1 5.1 0 0 0-1.107 5.566l-7.3 7.3a2.127 2.127 0 0 0 3.006 3l7.435-7.424a5.1 5.1 0 0 0 5.979-7.363Zm-1.53 5.354a4.079 4.079 0 0 1-4.385.931.51.51 0 0 0-.548.115l-7.649 7.649a1.108 1.108 0 1 1-1.565-1.569l7.532-7.526a.51.51 0 0 0 .094-.591 4.2 4.2 0 0 1 3.659-6.01 3.71 3.71 0 0 1 1.2.2l-1.726 1.729a.51.51 0 0 0-.148.382 2.466 2.466 0 0 0 2.346 2.346.509.509 0 0 0 .382-.148l1.731-1.731a3.972 3.972 0 0 1-.936 4.235Z"
                fill="#98a0ac"
            />
            <path data-name="Rectangle 49473" fill="none" d="M0 0h24v24H0z" />
        </g>
    </svg>
)