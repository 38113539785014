import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalculate } from "../../../utils";

export const listingCardStyle = makeStyles((theme) => ({

    cardRoot: {
        padding: 16,
        border: "1px solid #E4E8EE",
        backgroundColor: "white",
        borderRadius: "8px",
        marginTop: "6px",
        width: "100%",
        cursor: "pointer"
    },
    title: {
        fontSize: remCalculate(14),
        color: "#091B29",
        fontFamily: FontFamilySwitch().semiBold,
        marginInlineStart: "12px",
        textAlign: "left"
    }
}))


export const EntriesCardStyle = makeStyles((theme) => ({
    card: {
        background: "#fff",
        borderRadius: '8px',
        boxShadow: "0px 3px 30px #00000021",
        width: "100%",
        overflow: "hidden"
    },
    avatarBox: {
        width: 70,
        height: 70,
        borderRadius: 10,
        background: theme.palette.info.light,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    avatar: {
        height: 80,
        width: 50,
        objectFit: "contain !important",
        borderRadius: "10px 10px 0px 0px"
    },
    parkingavatar: {
        height: 80,
        width: 50,
        objectFit: "contain !important",
        borderRadius: "10px 10px 0px 0px"
    },
    avatarIcon: {
        height: 80,
        width: 50,
        objectFit: "contain !important",
        borderRadius: "10px 10px 0px 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    cancelled: {
        fontSize: remCalculate(12),
        background: theme.palette.background.primary,
        fontFamily: FontFamilySwitch().bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    title1: {
        fontSize: remCalculate(16),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "auto"
        // width: "280px"
    },
    title2: {
        fontSize: remCalculate(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.secondary,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        // width: "130px"
    },
    dateText: {
        fontSize: remCalculate(12),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary
    },
    pending: {
        fontSize: remCalculate(12),
        background: theme.palette.info.main,
        fontFamily: FontFamilySwitch().bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    approved: {
        fontSize: remCalculate(12),
        background: theme.palette.success.main,
        fontFamily: FontFamilySwitch().bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    rejected: {
        fontSize: remCalculate(12),
        background: theme.palette.error.main,
        fontFamily: FontFamilySwitch().bold,
        padding: "2px 8px",
        textAlign: "center",
        borderRadius: "4px",
        color: "#fff",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    tooltipText:{
        fontSize: remCalculate(14),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    arrow: {
        fontSize: 20,
        color: "#4A4A4A",
        "&::before": {
          backgroundColor: "blue",
          border: "2px solid red"
        }
      }
}))


export const useStyles = makeStyles((theme) => ({
    statusSection: {
        padding: "12px",
        borderRadius: "12px 12px 0px 0px"

    },
    requestTypeTitle: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalculate(14),
        color: "#FFFFFF"
    },
    requestDate: {
        fontFamily: FontFamilySwitch().regular,
        fontSize: remCalculate(10),
        color: "#FFFFFF"
    },
    requestCardRoot: {
        boxShadow: "0px 3px 30px #4354721F",
        borderRadius: "12px",
        backgroundColor: "white"

    },
    location: {
        fontFamily: FontFamilySwitch().regular,
        color: "#091B29",
        fontSize: remCalculate(14)
    },
    unitName: {
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
        fontSize: remCalculate(16)
    },
    detTitle: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalculate(10),
        textAlign: "center"
    },
    detDescription: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalculate(14),
        textAlign: "center"
    },
    guestName: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalculate(14),
    },
    guestMobile: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().regular,
        fontSize: remCalculate(12),
        marginTop: "2px"
    },
    reason: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().italic,
        fontSize: remCalculate(12),
    },
    guestDetailRoot: {
        boxShadow: "0px 3px 30px #4354721F",
        backgroundColor: "white",
        borderRadius: "12px",
        cursor: "pointer"
    },
    checkInTitle: {
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalculate(12),
        color: "#4E5A6B"
    },
    trackTitle: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: remCalculate(14)
    },
    trackDes: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().regular,
        fontSize: remCalculate(12)
    },
    trackCardRoot: {
        position: "relative",
        borderLeft: (props) => props?.showBorder ? "1px dashed #5078E1" : "none"
    },
    vehicleCardRoot: {
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "4px",
        padding: "12px",
        width: "33.33%",
        alignItems: "center",
        cursor: "pointer",
    }

}));