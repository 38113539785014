export const UnitIcon = () => {
    return (
        <svg id="Group_112335" data-name="Group 112335" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <rect id="Rectangle_57272" data-name="Rectangle 57272" width="40" height="40" rx="6" fill="#ebdfff" />
            <g id="Group_112334" data-name="Group 112334" transform="translate(8 8)">
                <g id="Rectangle_57271" data-name="Rectangle 57271" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="24" height="24" stroke="none" />
                    <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
                </g>
                <path id="icons8-real-estate_4_" data-name="icons8-real-estate (4)" d="M13.692,4a.691.691,0,0,0-.424.145L7.188,8.852a3.485,3.485,0,0,0-1.342,2.738v1.536l1.385-1.1v-.441a2.09,2.09,0,0,1,.8-1.642l5.657-4.379,5.657,4.379a2.091,2.091,0,0,1,.8,1.642v9.026H14.615A2.022,2.022,0,0,1,14.154,22h6.231a1.155,1.155,0,0,0,1.154-1.154V11.589A3.485,3.485,0,0,0,20.2,8.852l-6.08-4.706A.691.691,0,0,0,13.692,4Zm-5.1,8.518a.692.692,0,0,0-.406.149l-3.4,2.675A2.079,2.079,0,0,0,4,16.973v3.874A1.164,1.164,0,0,0,5.154,22h6.923a1.164,1.164,0,0,0,1.154-1.154V16.973a2.077,2.077,0,0,0-.791-1.632l-3.4-2.675a.692.692,0,0,0-.451-.149Zm.023,1.574,2.968,2.337a.691.691,0,0,1,.263.544v3.643H5.385V16.973a.69.69,0,0,1,.264-.544Z" transform="translate(-1 -1)" fill="#896db3" stroke="#896db3" stroke-width="0.5" />
            </g>
        </svg>

    )
}