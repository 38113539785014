import React from "react";
// import { withBottombar } from "../../HOCs";
import MyBookingDetails from "./myBookingDetails";
import { withBottombar } from "../../HOCs";
class MyBookingDetailsParent extends React.Component {
    render() {
        return <MyBookingDetails {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(MyBookingDetailsParent, props)