import * as React from "react"

const Hour = (props) => (
  <svg
    data-name="Group 96863"
    xmlns="http://www.w3.org/2000/svg"
    width={20.508}
    height={20.47}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 53088"
          fill="#5078e1"
          d="M0 0h20.508v20.47H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96862" clipPath="url(#a)" fill="#5078e1">
      <path
        data-name="Path 9196"
        d="M10.855 20.469h-1.2a1.1 1.1 0 0 0-.155-.033 9.77 9.77 0 0 1-4.474-1.408A10.1 10.1 0 0 1 .16 12c-.072-.384-.107-.774-.16-1.161v-1.2l.049-.432a10.057 10.057 0 0 1 2.512-5.723A10.046 10.046 0 0 1 9.824.012a9.763 9.763 0 0 1 5.655 1.427 10.085 10.085 0 0 1 4.869 7.034c.072.384.107.774.16 1.162v1.2l-.049.431a10.1 10.1 0 0 1-2.575 5.793 10.062 10.062 0 0 1-5.864 3.251c-.386.068-.777.107-1.165.159m-.607-1.59a8.646 8.646 0 1 0-8.656-8.646 8.66 8.66 0 0 0 8.656 8.646"
      />
      <path
        data-name="Path 9197"
        d="M9.457 7.908c0-.773-.005-1.546 0-2.318a.8.8 0 0 1 1.078-.787.816.816 0 0 1 .514.824v2.818c0 .426.006.853 0 1.279a.32.32 0 0 0 .152.295c.884.655 1.761 1.319 2.644 1.976a.794.794 0 1 1-.9 1.307c-.5-.363-.994-.741-1.491-1.113-.523-.392-1.041-.791-1.571-1.172a.976.976 0 0 1-.43-.869c.009-.746 0-1.492 0-2.239"
      />
    </g>
  </svg>
)

export default Hour
