import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
   root:{
    overflow:"overlay"
   },
   actionRoot: {
      backgroundColor: "white",
      padding: "12px 16px 16px 16px",
      borderRadius: "16px 16px 0px 0px",
      borderTop: "1px solid #E4E8EE",
  },
}));