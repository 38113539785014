import React from "react"

export const AssetIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            <g id="Group_117627" data-name="Group 117627" transform="translate(-5678 5165)">
                <g id="Rectangle_58531" data-name="Rectangle 58531" transform="translate(5678 -5165)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
                    <rect width="14" height="14" stroke="none" />
                    <rect x="0.5" y="0.5" width="13" height="13" fill="none" />
                </g>
                <path id="icons8-sofa" d="M4.864,9a1.909,1.909,0,0,0-1.909,1.909v.347a1.9,1.9,0,0,1,2.227,1.881v1.591h7.636V13.136a1.9,1.9,0,0,1,2.227-1.881v-.347A1.909,1.909,0,0,0,13.136,9ZM3.273,11.864A1.273,1.273,0,0,0,2,13.136V16.8a1.428,1.428,0,0,0,1.252,1.414l-.158.158a.477.477,0,1,0,.675.675l.815-.815h8.832l.815.815a.477.477,0,1,0,.675-.675l-.158-.158A1.428,1.428,0,0,0,16,16.8V13.136a1.273,1.273,0,0,0-2.545,0v2.227H4.545V13.136A1.273,1.273,0,0,0,3.273,11.864Z" transform="translate(5676 -5172)" fill="#98a0ac" />
            </g>
        </svg>

    )
}