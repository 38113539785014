import * as React from "react";
import { Box, Divider, Skeleton, Stack } from "@mui/material";
import Activity from "./activity";
import { DialogDrawer } from "../../../components";
import Drawer from "../component/drawer";
import { spendingListCardUseStyles } from "./style";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { AlertContext } from "../../../contexts";
import { withNamespaces } from "react-i18next";
import { config } from "../../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../../utils/useWindowDimensions";


const SpendingListCard = ({
  listing = [],
  fetchMoreData = () => {},
  loading,
}) => {
  const alert = React.useContext(AlertContext);
  const classes = spendingListCardUseStyles();
  const [drawer, setDrawer] = React.useState(false);
  const [unitListing, setUnitListing] = React.useState([]);
  const [drawerLoading, setDrawerLoading] = React.useState(true);
  const size = useWindowDimensions();
  // const [offset, setOffset] = React.useState(0);

  const handleDrawerClose = () => {
    setDrawer(false);
  };

  const handleDrawerOpen = (id) => {
    // setDrawer(!drawer);
    getUnitListing(id);
  };

  // UNIT LISTING

  const getUnitListing = (id) => {
    const payload = {
      id: id
    }
    NetworkCall(
      `${config?.api_url}/statistics_unit_listing/unit_listing`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let assets = response?.data?.data?.[0]?.assets?.map((i) => {
          let temp_file_meta = JSON.parse(i?.file_meta)
          return {
            file_meta: {
              name: temp_file_meta?.name ?? "",
              size: temp_file_meta?.size ?? "",
              type: temp_file_meta?.type ?? "",
              ext: temp_file_meta?.ext ?? ""
            },
            url: i?.url
          }
        })
        let temp_res = { ...response?.data?.data?.[0], assets: assets ?? "" }
        console.log(temp_res, "Hello")
        setUnitListing(temp_res);
        setDrawer(!drawer);
        setDrawerLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: ("Something Went Wrong"),
        });
      });
  };
  
  return (
    <Box
      className={classes.spendingList}
    >
      <Box
        className={classes.spendingListCard}
      >
        <InfiniteScroll
          dataLength={listing.length}
          next={fetchMoreData}
          hasMore={true}
          height={size?.height - 220}>
          <Stack divider={<Divider className={classes.divider2} />}>
          {listing.map((val) => (
            <>
              {loading ? (
                <>
                  <Box p={1} pt={2}>
                    <Stack direction={"row"} spacing={2}>
                      <Skeleton variant="rounded" width={50} height={50} />
                      <Stack>
                        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      </Stack>
                    </Stack>
                  </Box>
                </>) : (
                <>
                  <Activity
                    onClick={handleDrawerOpen}
                    data={val}
                    type={"Spendings"}
                  />
                </>
              )}
            </>
          ))}
          </Stack>
        </InfiniteScroll>
      </Box>
      <DialogDrawer
        maxWidth="sm"
        open={drawer}
        onClick={handleDrawerClose}
        header="Spending details"
        component={
          <>
            {drawerLoading ? (<>
              <Skeleton variant="rounded" width={"100%"} height={60} />
              <Box my={2}>
                <Divider className={classes.divider} />
              </Box>
              <Skeleton variant="rounded" width={"100%"} height={200} />
            </>) : (<>
              <Drawer
                type={"Spendings"}
                data={unitListing}
              /></>)}
          </>
        }
        onClose={() => setDrawer(false)}
      />
    </Box >
  );
};
export default withNamespaces("statistics")(SpendingListCard);
