import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #4250682E",
    backgroundColor: "#ffffff",
  },
  mainPadding: {
    padding: "12px",
  },
  label: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  borderBottom: {
    borderBottom: "1px solid #E4E8EE",
  },
  infoCard: {
    display: "flex",
    gap: "12px",
  },
  regInfoCard: {
    display: "flex",
    // flexDirection:"column",
    // padding:"8px",
    flexGrow:1,
    gap: "12px",
    borderRadius:"4px",
    boxShadow: "0px 3px 30px #4250682E",
  },
  propertyImg: {
    width: "60px",
    height: "60px",
    borderRadius: "8px",
  },
  profileImg: {
    width: "70px",
    height: "70px",
    borderRadius: "8px",
  },
  regProfileImg:{
    width: "32px",
    height: "32px",
    borderRadius: "8px",
  },
  unitName: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  mailId: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
    backgroundColor: "#F5F7FA",
    padding: "2px 6px",
    borderRadius: "4px",
  },
  dotIcon: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  subTitle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
    "& span": {
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().bold,
      color: "#091B29",
    },
  },

  description: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#98A0AC",
    lineHeight: "16px",
  },
}));
