import { Avatar, Box, Typography } from "@mui/material";
import { useStyles } from "./styles";
export const Status = ({ invoice_no = "", onClick = () => false, status = false, status_type }) => {
    const classes = useStyles()
    return (
        <Box>
            <Box display="flex" alignItems="center" p={1} className={classes.status} onClick={onClick}>
                <Box>
                    <Avatar sx={{ backgroundColor: "#78B1FE" }}>
                        <img src="/images/regularDocument.svg" alt="" />
                    </Avatar>
                </Box>
                <Box marginLeft="10px">
                    <Typography className={classes.statusdes}>
                        {status ? status_type : `${`Pay against quotation # ${invoice_no}`}`}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}