import { Box, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer } from "../../components";
import { PropertyListCard } from "../../components/propertyListCard";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  roots: {
    padding: "12px"
  },
  image: {
    objectFit: "cover",
    width: "101px",
    height: "113px",
  },
  card: {
    backgroundColor: "white",
    marginBottom: "12px",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
  },
  name: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px",
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginBottom: "12px",
    marginTop: "12px"
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "0.75rem",
  },
  noData: {
    color: "#999",
    textAlign: "center",
    marginTop: "20vh",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px"
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,

  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    margin: "10px 0px 0px 12px",
    alignItems: "center"
  }
}));

const Utilities = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [datas, setDatas] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffSet] = React.useState(0)
  const searchURL = useLocation().search;
  const agreementId = new URLSearchParams(searchURL).get("Id");


  const getproperty = (offset, bool) => {
    const data = JSON.stringify({
      agreementId: [agreementId],
      tenantId: `${config.tenantid}`,
      is_pagination: true,
      offset: offset,
      limit: 10
    });
    NetworkCall(
      `${config.api_url}/agreement-property-info/get`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let resData = response?.data?.data
        if (bool) {
          setDatas({
            ...datas,
            propertyCount: datas?.propertyCount + resData?.propertyCount,
            propertyInfo: datas?.propertyInfo?.concat(resData?.propertyInfo),
            property_detail: datas?.property_detail?.concat(resData?.property_detail)
          });
        } else {
          setDatas(resData)
        }
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  React.useEffect(() => {
    handleTopBarState({
      text: t("Utilities")
    })
    getproperty(0, false)
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = () => {
    setOffSet(offset + 10);
    getproperty(offset + 10, true);
  };

  // const handleSelected = (val) => {
  //   history.replace(
  //     Routes.utilitiesdetails +
  //     "?propertyId=" + val?.property_id +
  //     "&name=" + val?.property_name)
  // }
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar
        text={t("Utilities")}

        goBack={() => history.goBack()}
      /> */}
      <div
        style={{
          height: size?.height - 60,
          overflow: "scroll",
        }}
        className={classes.roots}
      >
        <Typography className={classes.total}>
          {t("Properties")}&nbsp;({datas?.propertyCount > 0 ? datas?.propertyCount : 0}
          )
        </Typography>

        {loading ? (
          <LoderSimmer card count={10} size={80} />
        ) : (
          <>
            {datas?.property_detail?.length > 0 ?
              <Box sx={{ width: "100% !important", marginTop: "12px" }}>
                <InfiniteScroll
                  dataLength={datas?.property_detail?.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 100}
                >{datas?.property_detail?.map((val) => {
                  return (
                    <>
                      <PropertyListCard
                        t={t}
                        normal
                        data={val}
                        onClick={() =>
                          history.push(
                            Routes.utilitiesdetails +
                            "?propertyId=" +
                            val?.property_id +
                            "&name=" +
                            val?.property_name
                          )
                        }
                      />
                    </>
                  );
                })}
                </InfiniteScroll>
              </Box>
              :
              <Typography className={classes.noData}>
                {t("Datanotfound")}
              </Typography>}
          </>
        )}
      </div>
    </Container>
  );
};
export default withNamespaces("utilites")(Utilities)