import { Avatar, Box, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",

    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
    // display: "flex"
  },
  Cardcontent: {
    // [theme.breakpoints.up("sm")]: {
    //   padding: "8px 12px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px 0px",
    // },
    // borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
    marginTop: "auto"
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
    marginTop: "5px"
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#FF4B4B",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  revoked: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  avatar: {
    height: "120px",
    width: "110px",
    objectFit: "contain",
    [theme.breakpoints.down(321)]: {
      height: "140px",

    },
  },
  bottomText: {
    // position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    margin: "5px 0px 0px 12px",
    alignItems: "center"
  },
  flexFlow: {
    flexFlow: 'wrap !important',

  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    color: "white",
    width: "110px",
    textAlign: "center"
  },
}));

export const UnitAccessCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  return (
    <>
      <div
        className={classes.card}
        onClick={() =>
          history.push(
            Routes.aggreementUnitAccessDetails + "?passId=" + props?.data?.id
          )
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex" position={"relative"}>
            {props?.data?.img ? (
              <Avatar
                src={generateImageUrl(props?.data?.img, img_size.small_square)}
                alt={props?.data?.img}
                variant="rounded"
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.avatar} variant="rounded" />
            )}
            <div className={classes.bottomText1}>
              {props?.data?.request_status === "Pending" && (
                <Typography
                  variant="subtitle2"
                  noWrap
                  className={classes.progress}
                >
                  In Progress
                </Typography>
              )}
              {props?.data?.request_status === "Approved" && (
                <Typography variant="subtitle2" className={classes.approved}>
                  Approved
                </Typography>
              )}
              {props?.data?.request_status === "Rejected" && (
                <Typography variant="subtitle2" className={classes.rejected}>
                  Rejected
                </Typography>
              )}
              {props?.data?.request_status === "Revoked" && (
                <Typography variant="subtitle2" className={classes.revoked}>
                  Revoked
                </Typography>
              )}
              {props?.data?.request_status === "Cancelled" && (
                <Typography variant="subtitle2" className={classes.delete}>
                  Cancelled
                </Typography>
              )}
            </div>
            <Box flexGrow={1} marginLeft="10px">
              <Box
                alignItems="center"
                display="flex"
                justifyContent={"space-between"}
                className={classes.flexFlow}
              >
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  noWrap
                >
                  {props?.data?.request_type === "Unit Access" && "Unit Access"}
                  {props?.data?.request_type === "Agreement Access" &&
                    "Account Access"}{" "}
                </Typography>

                {props?.data?.referenceId && (
                  <Box marginRight="10px">
                    <Typography
                      fontSize={"10px"}
                      className={classes.bottomText}
                    >
                      {props?.data?.referenceId}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                alignItems="center"
                display="flex"
                className={classes.flexFlow}
              >
                {props?.data?.request_type === "Unit Access" && (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.request_type === "Unit Access" &&
                      props?.data?.unit_name
                        ? props?.data?.unit_name
                        : " - "}
                      {/* {props?.data?.account_id && props?.data?.account_id ? props?.data?.account_id : " - "}
                    {props?.data?.unit_id && props?.data?.unit_id ? props?.data?.unit_id : " - "} */}
                    </Typography>
                  </Box>
                )}
                {props?.data?.request_type === "Agreement Access" && (
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.request_type === "Agreement Access" &&
                      props?.data?.account_id
                        ? props?.data?.account_id
                        : " - "}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                <Typography variant="subtitle2" className={classes.sub} noWrap>
                  <Hidden smUp>
                    {props?.data?.name?.length > 15 ? (
                      <>{props?.data?.name.slice(0, 15)}...</>
                    ) : (
                      props?.data?.name
                    )}
                  </Hidden>
                  <Hidden smDown>
                    {props?.data?.name?.length > 40 ? (
                      <>{props?.data?.name.slice(0, 40)}...</>
                    ) : (
                      props?.data?.name
                    )}
                  </Hidden>
                </Typography>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                className={classes.flexFlow}
              >
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {props?.data?.mobile_country_code
                      ? props?.data?.mobile_country_code + " - "
                      : ""}{" "}
                    {props?.data?.mobile_no ?? ""}
                  </Typography>
                </Box>
                <Box className={classes.dot} />
                <Box>
                  <Typography variant="subtitle2" className={classes.sub}>
                    {props?.data?.email_id.length > 20
                      ? props?.data?.email_id.slice(0, 15) + "..."
                      : props?.data?.email_id}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
