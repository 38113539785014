
import * as React from "react"

const ApprovedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 118782">
      <path
        fill="#5ac782"
        d="M34.916 20.837a3.768 3.768 0 0 0-1.055-6.044l-1.571-.741a1.288 1.288 0 0 1-.727-1.276l.152-1.745a3.77 3.77 0 0 0-1.307-3.2 3.685 3.685 0 0 0-3.34-.749l-1.674.455a1.268 1.268 0 0 1-1.368-.5L23.033 5.6a3.676 3.676 0 0 0-6.065 0l-.993 1.436a1.268 1.268 0 0 1-1.369.5l-1.673-.455a3.678 3.678 0 0 0-3.339.749 3.77 3.77 0 0 0-1.307 3.2l.152 1.746a1.292 1.292 0 0 1-.728 1.277l-1.571.74a3.768 3.768 0 0 0-1.053 6.044l1.224 1.238a1.3 1.3 0 0 1 .25 1.451l-.73 1.589a3.787 3.787 0 0 0 .149 3.457 3.7 3.7 0 0 0 2.881 1.859l1.727.153a1.277 1.277 0 0 1 1.115.947l.448 1.693a3.731 3.731 0 0 0 2.31 2.551 3.67 3.67 0 0 0 3.391-.452l1.418-1.007a1.262 1.262 0 0 1 1.456 0l1.419 1.007a3.667 3.667 0 0 0 3.391.452 3.736 3.736 0 0 0 2.31-2.551l.448-1.693a1.274 1.274 0 0 1 1.115-.947l1.725-.153a3.7 3.7 0 0 0 2.885-1.859 3.789 3.789 0 0 0 .148-3.458l-.733-1.589a1.3 1.3 0 0 1 .253-1.451Zm-8.191-3.248-8.11 8.206a1.208 1.208 0 0 1-1.721 0l-4.055-4.1a1.24 1.24 0 0 1 0-1.74 1.206 1.206 0 0 1 1.72 0l3.2 3.232 7.249-7.335a1.206 1.206 0 0 1 1.72 0 1.241 1.241 0 0 1-.003 1.737Z"
        data-name="icons8-verified-badge (1)"
      />
    </g>
  </svg>
)

const DeclinedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 118782">
      <path
        fill="#ff4b4b"
        d="M34.916 20.837a3.768 3.768 0 0 0-1.055-6.044l-1.571-.741a1.288 1.288 0 0 1-.727-1.276l.152-1.745a3.77 3.77 0 0 0-1.307-3.2 3.685 3.685 0 0 0-3.34-.749l-1.674.455a1.268 1.268 0 0 1-1.368-.5L23.033 5.6a3.676 3.676 0 0 0-6.065 0l-.993 1.436a1.268 1.268 0 0 1-1.369.5l-1.673-.455a3.678 3.678 0 0 0-3.339.749 3.77 3.77 0 0 0-1.307 3.2l.152 1.746a1.292 1.292 0 0 1-.728 1.277l-1.571.74a3.768 3.768 0 0 0-1.053 6.044l1.224 1.238a1.3 1.3 0 0 1 .25 1.451l-.73 1.589a3.787 3.787 0 0 0 .149 3.457 3.7 3.7 0 0 0 2.881 1.859l1.727.153a1.277 1.277 0 0 1 1.115.947l.448 1.693a3.731 3.731 0 0 0 2.31 2.551 3.67 3.67 0 0 0 3.391-.452l1.418-1.007a1.262 1.262 0 0 1 1.456 0l1.419 1.007a3.667 3.667 0 0 0 3.391.452 3.736 3.736 0 0 0 2.31-2.551l.448-1.693a1.274 1.274 0 0 1 1.115-.947l1.725-.153a3.7 3.7 0 0 0 2.885-1.859 3.789 3.789 0 0 0 .148-3.458l-.733-1.589a1.3 1.3 0 0 1 .253-1.451Z"
        data-name="icons8-verified-badge (1)"
      />
      <path
        fill="#fff"
        stroke="#fff"
        d="M25.294 15a.707.707 0 0 0-.494.217l-4.8 4.8-4.8-4.8a.707.707 0 1 0-1 1l4.8 4.8-4.8 4.8a.707.707 0 1 0 1 1l4.8-4.8 4.8 4.8a.707.707 0 1 0 1-1L21 21.02l4.8-4.8a.707.707 0 0 0-.506-1.217Z"
        data-name="icons8-close (3)"
      />
    </g>
  </svg>
)

const ActionIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 17V0h17v17Zm7.647-4.958a4.395 4.395 0 1 0 4.395-4.395 4.386 4.386 0 0 0-4.395 4.395Z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 4390" transform="translate(-1977.811 16211.189)">
      <circle
        cx={16}
        cy={16}
        r={16}
        fill="#fff"
        data-name="Ellipse 39546"
        transform="translate(1977.811 -16211.19)"
      />
      <g data-name="icons8-agreement (7)">
        <g
          clipPath="url(#a)"
          data-name="Group 118776"
          transform="translate(1985.811 -16203.189)"
        >
          <g data-name="Group 118775">
            <path
              fill={props?.color}
              d="M4.426 1.417a1.6 1.6 0 0 0-1.594 1.594V13.99a1.6 1.6 0 0 0 1.594 1.594h8.146a1.6 1.6 0 0 0 1.594-1.594V7.084h-4.073A1.6 1.6 0 0 1 8.499 5.49V1.417Zm5.135.311V5.49a.532.532 0 0 0 .531.531h3.762ZM6.906 8.501A1.576 1.576 0 0 1 8.5 10.268a3.592 3.592 0 0 1-.811 2.1 1.41 1.41 0 0 0 .279.028c.29 0 .4-.135.623-.591.159-.329.4-.825.97-.825.655 0 .8.631.878.935.11.468.152.481.361.481a.535.535 0 0 0 .184-.446.531.531 0 0 1 1.049-.17 1.593 1.593 0 0 1-.369 1.247 1.132 1.132 0 0 1-.864.432 1.31 1.31 0 0 1-1.328-1.041 1.645 1.645 0 0 1-1.506 1.041 2.318 2.318 0 0 1-1.2-.319 2.673 2.673 0 0 1-1.282.319.531.531 0 1 1 0-1.062 1.637 1.637 0 0 0 .446-.06 3.84 3.84 0 0 1-.623-2.065 1.577 1.577 0 0 1 1.594-1.771Zm0 1.062c-.145 0-.531 0-.531.708a2.73 2.73 0 0 0 .453 1.48 2.6 2.6 0 0 0 .609-1.484c0-.7-.386-.7-.531-.7Z"
              data-name="Path 101916"
            />
          </g>
        </g>
        <g data-name="Group 118777">
          <path
            fill={props?.color}
            d="M1997.853-16194.866a3.719 3.719 0 1 0 3.719 3.719 3.719 3.719 0 0 0-3.719-3.719Zm2.267 2.605-2.7 2.7a.338.338 0 0 1-.478 0l-1.357-1.347a.338.338 0 1 1 .478-.478l1.114 1.113 2.465-2.466a.338.338 0 0 1 .478.478Z"
            data-name="Path 101917"
          />
        </g>
      </g>
    </g>
  </svg>
)




export const returnTopCardDetails=({t=()=>false,type})=>{
    let details={
        "Approved":{
            title:t("Draft Agreement Approved"),
            icon:<ApprovedIcon/>
        },
        "Declined":{
            title:t("Draft Agreement Declined"),
            icon:<DeclinedIcon/>
        }
    }
    return details[type];
}


export const returnActionCard=({t=()=>false})=>{
  return [
    {
      id: 1,
      title: t("Approve"),
      color: "#35A27D",
      value: true,
      icon:<ActionIcon color="#35A27D"/>,
      type:"Approved"
    },
    {
      id: 2,
      title: t("Decline"),
      color: "#FF4B4B",
      value: false,
      icon:<ActionIcon color="#FF4B4B"/>,
      type:"Declined"

    }
  ];

}

export const returnStatusBased=({t=()=>false,status="approved"})=>{

 let details={
        "approved":{
            formTitle:t("Approve"),
        },
        "declined":{
          formTitle:t("Decline"),
        }
    }
    return details[status];
}
