import * as React from "react"

const SearchIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 97126" transform="translate(-15 -112)">
      <rect
        data-name="Rectangle 45263"
        width={32}
        height={32}
        rx={8}
        transform="translate(15 112)"
        fill="none"
      />
      <path
        data-name="icons8-search (2)"
        d="M30.12 121a6.12 6.12 0 1 0 3.68 10.995l2.759 2.758a.844.844 0 1 0 1.194-1.194l-2.758-2.759a6.106 6.106 0 0 0-4.875-9.8Zm0 1.688a4.433 4.433 0 0 1 3.228 7.469.844.844 0 0 0-.189.189 4.432 4.432 0 1 1-3.039-7.658Z"
        fill="#4e5a6b"
      />
    </g>
  </svg>
)

export default SearchIcon
