import React from "react";
import FilterPropertySearch from "./FilterPropertySearch";
 

class PropertySearchParent extends React.Component {
  render() {
    return <FilterPropertySearch />;
  }
}

export default PropertySearchParent;
