import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from './style';

export const SimpleAccordion = ({
    headText="",
    content="",
    defaultExpanded=false
}) => {
    const classes = useStyles();
  return (
    <div>
      <Accordion className={classes?.root} defaultExpanded={defaultExpanded}>
        {/* Accordian Heading */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes?.headText}>{headText}</Typography>
        </AccordionSummary>
        {/* Accordian Content */}
        <AccordionDetails>
          <Typography>
            {content}
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}