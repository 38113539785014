import React from "react";
import { Divider, Stack, Typography, Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useStyles } from "./styles";
import { WorkerContext } from "../../context";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { generateImageUrl, img_size } from "../../../../utils";

const RegWorkerList = ({ state, index }) => {
    const { regWorkerList, updateState } = React.useContext(WorkerContext);
    const classes = useStyles();
    const addRegister = (value) => {
        if (state?.reg_worker_profiles?.filter(({ helper_id }) => helper_id === value?.helper_id).length === 0) {
            updateState("select_workers", "reg_worker_profiles", [value], index);
        } else {
            updateState("select_workers", "reg_worker_profiles", [], index);
        }
    }
    return (
        <Box className={classes.regInfoCard}>
            <Stack flexGrow={1} divider={<Box sx={{ borderBottom: "1px dashed #E4E8EE", marginBottom: "5px" }} />} flexDirection={"column"}>
                {regWorkerList?.map((worker) => (
                    <Box p={0.8} display="flex" flexDirection={"row"} gap={"8px"} flexGrow={1} onClick={() => addRegister(worker)}>
                        <img
                            className={classes.regProfileImg}
                            src={worker?.helper_image ? generateImageUrl(worker?.helper_image, img_size.small_square) : "/images/profileBlue.svg"}
                            alt=""
                        />
                        <Box flexGrow={1}>
                            <Stack
                                direction="row"
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Typography className={classes.title} noWrap>
                                    {worker?.name}
                                </Typography>
                                <Box justifyContent={"flex-end"} display={"flex"} >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state?.reg_worker_profiles?.filter(({ helper_id }) => helper_id === worker?.helper_id).length !== 0}
                                                    //   onChange={() =>
                                                    //     updateState("worker_info", "vendorSafty", !data?.worker_info?.vendorSafty)
                                                    //   }
                                                    icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                                                    checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Box>
                                {/* {data?.select_workers?.filter(({view_type})=>view_type === "edit").length === 0 && 
            <img onClick={() => editSelectedWorker(index)} style={{cursor:"pointer"}} src="/images/Outline.svg" alt="" />
          } */}
                            </Stack>
                            <Stack
                                mt={0.4}
                                direction="row"
                                alignItems={"center"}
                                spacing={1}
                                divider={<Box className={classes.dotIcon} />}
                            >
                                <Typography className={classes.subTitle} noWrap>
                                    {worker?.workerType?.label}
                                </Typography>
                                {/* <Typography className={classes.subTitle} noWrap>
              9988445511
            </Typography> */}
                            </Stack>
                            <Stack
                                mt={0.4}
                                direction="row"
                                alignItems={"center"}
                                spacing={1}
                                divider={
                                    <Divider
                                        orientation="vertical"
                                        sx={{ borderLeft: "1px solid #CED3DD", padding: "6px 0" }}
                                    />
                                }
                            >
                                {worker?.identification_type &&
                                    <Typography className={classes.subTitle} noWrap>
                                        {worker?.identification_type}
                                    </Typography>
                                }
                                {worker?.identification_no &&
                                    <Typography className={classes.subTitle} noWrap>
                                        {worker?.identification_no}
                                    </Typography>
                                }
                            </Stack>
                        </Box>
                        <Box height={"10px"} />
                    </Box>
                ))}
            </Stack>
        </Box>
    );
};

export default RegWorkerList;