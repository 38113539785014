import * as React from "react"
const DepartmentLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 114116" transform="translate(14640.75 -637.5)">
      <rect
        width={40}
        height={40}
        fill="#fffceb"
        data-name="Rectangle 57450"
        rx={20}
        transform="translate(-14640.75 637.5)"
      />
      <path
        fill="#b3a16d"
        d="M-14615.324 649.5h-10.185a2.352 2.352 0 0 0-2.241 2.444v11.112a2.352 2.352 0 0 0 2.241 2.444h10.185a2.352 2.352 0 0 0 2.241-2.444v-11.112a2.352 2.352 0 0 0-2.241-2.444Zm-5.093 3.111a2.136 2.136 0 0 1 2.037 2.222 2.136 2.136 0 0 1-2.037 2.222 2.136 2.136 0 0 1-2.033-2.222 2.136 2.136 0 0 1 2.033-2.222Zm3.667 6.906c0 1.271-1.486 2.428-3.667 2.428s-3.667-1.157-3.667-2.428v-.3a.8.8 0 0 1 .759-.828h5.816a.8.8 0 0 1 .759.828Z"
        data-name="icons8-name (2)"
      />
    </g>
  </svg>
)
export default DepartmentLogo
