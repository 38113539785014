import { Box } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

const UnitImages = ({images}) => {
  const classes = useStyles();
  return (
    <Box>
      {images.map((data) => (
        <img className={classes.imageViewer} src={"/images/" + data} alt="" />
      ))}
    </Box>
  );
};

export default UnitImages;
