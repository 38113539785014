import { Box, Container, Typography, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { LoderSimmer } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import NeigbourList from "./detailCard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DialogDrawer } from "../../components";
import { withNamespaces } from "react-i18next";
import Selection from "./component/selection";
import { getPropertyHierarchyDetails } from "../../utils/propertyHierarchy";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F6F6F6",
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    textAlign: "center",
    marginTop: "5%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  // MY CSS
  clickableBox: {
    backgroundColor: "white",
    border: "none",
    fontSize: "0.875rem",
    borderRadius: "4px",
  },
  searchIcon: {
    backgroundColor: "white",
    borderRadius: "4px",
    height: "39px",
    width: "39px",
    padding: "3px",
  },
  listRoot: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    // padding: "12px 16px",
  },
  label: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
}));

const NeighboursDetails = (props) => {
  const { t = () => false, handleTopBarState } = props;
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const searchURL = useLocation().search;
  const propertyId = new URLSearchParams(searchURL).get("propertyId");
  const propertyName = new URLSearchParams(searchURL).get("name");
  const property_hierarchy_id = new URLSearchParams(searchURL).get("property_hierarchy_id");
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [openBlock, setOpenBolck] = React.useState(false);
  const [drawerFloor, setDrawerFloor] = React.useState(false);
  const [selectedBlock, setSelectedBolck] = React.useState("");
  const [selectedFloor, setSelectedFloor] = React.useState("");
  const [dropDownData, setDropDownData] = React.useState({
    blocks: [],
    floors: [],
  });
  const [property_hierarchy_details, set_property_hierarchy_details] = useState({ custom_block_label: "", custom_floor_label: "" });

  const handleOpenBlock = () => {
    getBlock();
    setOpenBolck(true);
  };

  const handleCloseBlock = () => {
    setOpenBolck(false);
  };

  const handleDrawerOpenFloor = () => {
    if (selectedBlock?.id) {
      getFloor();
      setDrawerFloor(true);
    }
  };

  const handleDrawerCloseFloor = () => {
    setDrawerFloor(false);
  };
  const handleSelectedBolck = (data) => {
    setSelectedBolck(data);
    if (data?.id?.length === 0) {
      setSelectedFloor({ name: t(`All ${property_hierarchy_details?.custom_floor_label}`), id: "" })
    }
    getContactDetails(propertyId, offset, "", true, data?.id, selectedFloor?.id);
    handleCloseBlock();
  }

  const handleSelectedFloor = (data) => {
    setSelectedFloor(data);
    getContactDetails(propertyId, offset, "", true, selectedBlock?.id, data?.id);
    handleDrawerCloseFloor();
  }

  const getBlock = () => {
    const data = {
      offset: 0,
      limit: 10,
      property_id: propertyId,
    };
    NetworkCall(
      `${config.api_url}/my-neighbours/blocks`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDropDownData({
          ...dropDownData,
          blocks: [{ name: t(`All ${property_hierarchy_details?.custom_block_label}`), id: "" }, ...response?.data?.data?.data],
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getFloor = () => {
    if (selectedBlock?.id) {
      const data = {
        offset: 0,
        limit: 10,
        block_id: selectedBlock?.id,
      };
      NetworkCall(
        `${config.api_url}/my-neighbours/floors`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setDropDownData({
            ...dropDownData,
            floors: response?.data?.data?.data,
          });
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
  };

  const getContactDetails = (propertyid, offset, search, filter, block, floor) => {
    const payload = {
      tenantId: `${config.tenantid}`,
      property_id: propertyid,
      offset: offset,
      limit: 10,
      query: "",
    };
    if (block) {
      payload["block_id"] = [block]
    }
    // if (floor) {
    //   payload["floor_id"] = [floor]
    // }
    NetworkCall(
      `${config.api_url}/my-neighbours/list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setData(response?.data?.data);
        } else {
          setData(data.concat(response?.data?.data));
        }
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  React.useEffect(() => {
    if (propertyId && propertyName) {
      getPropertyHierarchy(propertyId);
      getContactDetails(propertyId, 0, "");
      handleTopBarState({
        text: propertyName
      })
    }
    // eslint-disable-next-line
  }, [propertyId, propertyName]);

  const getPropertyHierarchy = async (property_id = "") => {
    const property_hierarchy_details = await getPropertyHierarchyDetails({ property: { id: property_id } });

    const custom_level_1 = property_hierarchy_details?.hierarchy_id === 5 && property_hierarchy_details;
    const custom_level_2 = property_hierarchy_details?.hierarchy_id === 6 && property_hierarchy_details;

    const custom_block_label = custom_level_1?.is_primary
      ? custom_level_1?.block_label
      : "Block";

    const custom_floor_label = custom_level_1?.is_primary
      ? custom_level_1?.floor_label
      : custom_level_2?.is_primary
        ? `${custom_level_2?.block_label}/${custom_level_2?.floor_label}`
        : "Floor";

    set_property_hierarchy_details({ custom_block_label, custom_floor_label });

    setSelectedBolck({ name: t(`All ${custom_block_label}`), id: "" });
    setSelectedFloor({ name: t(`All ${custom_floor_label}`), id: "" });
  }
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getContactDetails(propertyId, offset + 10, "", selectedBlock?.id, selectedFloor?.id);
  };
  // const handleSearch = (val) => {
  //   setSearch(val);
  //   getContactDetails(propertyId, offset, val, true);
  // }; 

  console.log((parseInt(property_hierarchy_id)))

  const isDrowDownFloor = () => {
    return ([3, 4, 5, 6]?.includes(parseInt(property_hierarchy_id)));
  }
  const isDrowDownBlock = () => {
    return [3, 5]?.includes(parseInt(property_hierarchy_id));
  }
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={propertyName} goBack={() => history.goBack(-1)} /> */}
      <Box sx={{ padding: "16px" }}>
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Box className={classes.clickableBox} flexGrow={1}>
            <Stack direction={"row"} alignItems="center">
              {isDrowDownBlock() &&
                <Box
                  flexGrow={1}
                  onClick={handleOpenBlock}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    borderRight: "1px solid #e9edf1",
                    cursor: "pointer",
                    padding: "3px 15px",
                  }}
                >
                  <Typography className={classes.label} noWrap sx={{ maxWidth: size?.width - 245 }}>
                    {selectedBlock?.name
                      ? t(selectedBlock?.name)
                      : t(`All ${property_hierarchy_details?.custom_block_label}`)}
                  </Typography>
                  <Box mt={0.5} mr={1}>
                    <ExpandMoreIcon sx={{ fontSize: "1.25rem" }} />
                  </Box>
                </Box>
              }
              {isDrowDownFloor() &&
                <Box
                  flexGrow={1}
                  onClick={handleDrawerOpenFloor}
                  sx={{ cursor: "pointer", padding: "3px 15px" }}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box textAlign="left" ml={2}>
                    <Typography className={classes.label} sx={{ maxWidth: size?.width - 245 }} noWrap>
                      {selectedFloor?.name
                        ? t(selectedFloor?.name)
                        : t(`All ${property_hierarchy_details?.custom_floor_label}`)}
                    </Typography>
                  </Box>
                  <Box mt={0.5}>
                    <ExpandMoreIcon sx={{ fontSize: "1.25rem" }} />
                  </Box>
                </Box>
              }
            </Stack>
          </Box>
          {/* <Box onClick={handleSearch} className={classes.searchIcon}>
              <SearchIcon fontSize="inherit" />
            </Box> */}
        </Stack>
        {/* <div style={{ height: size?.height - 55, overflow: "overlay" }}
      > */}
        {loading ? (
          <LoderSimmer count={10} />
        ) : (
          <>
            {data.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 128}
                  style={{ overflow: "overlay", marginTop:"12px" }}
                >
                  {data?.map((val) => (
                    <>
                      <Box className={classes.listRoot}>
                        <NeigbourList t={t} data={val} />
                      </Box>
                      <Box height={"12px"} />
                    </>
                  ))}
                </InfiniteScroll>
              </>
            ) : (
              <Typography className={classes.title}>
                {t("NoDataFound")}
              </Typography>
            )}
          </>
        )}
        {/* </div> */}
      </Box>
      <DialogDrawer
        maxWidth="sm"
        open={openBlock}
        onClick={handleCloseBlock}
        header={t("Block Selection")}
        component={
          <>
            {/* {?.map((val) => { */}
            {/* return ( */}
            <Selection
              t={t}
              type={"block"}
              data={dropDownData?.blocks}
              setSelectedBolck={handleSelectedBolck}
              selectedBlock={selectedBlock}
            />
            {/* ); */}
            {/* })} */}
          </>
        }
        onClose={() => handleCloseBlock(false)}
      />

      <DialogDrawer
        maxWidth="sm"
        open={drawerFloor}
        onClick={handleDrawerCloseFloor}
        header={t("Floor Selection")}
        component={
          <>
            {/* {dropDownData?.blocks?.map((val) => {
              return ( */}
            <Selection
              t={t}
              type={"floor"}
              data={dropDownData?.floors}
              setSelectedFloor={handleSelectedFloor}
              selectedFloor={selectedFloor}
            />
            {/* );
            })} */}
          </>
        }
        onClose={() => handleDrawerCloseFloor(false)}
      />
    </Container>
  );
};
export default withNamespaces("neighbours")(NeighboursDetails);
