import React from "react";
import GetSupport from "./getSupport";
import { withBottombar } from "../../HOCs";

class GetSupportParent extends React.Component {
    render() {
        return <GetSupport {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(GetSupportParent, props);
