import React from "react"


export const RescheduleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g id="Group_117604" data-name="Group 117604" transform="translate(-75.5 -121.5)">
                <rect id="Rectangle_58388" data-name="Rectangle 58388" width="40" height="40" rx="6" transform="translate(75.5 121.5)" fill="#fef4f4" />
                <path id="icons8-user-clock_3_" data-name="icons8-user-clock (3)" d="M11.4,3a5.581,5.581,0,0,0,0,11.163h.069A5.581,5.581,0,0,0,11.467,3H11.4ZM20.86,14.721A6.139,6.139,0,1,0,27,20.86,6.14,6.14,0,0,0,20.86,14.721ZM5.078,16.4A2.155,2.155,0,0,0,3,18.616v.849a5.184,5.184,0,0,0,2.665,4.48,10.49,10.49,0,0,0,5.428,1.38,11.512,11.512,0,0,0,3.645-.581,7.223,7.223,0,0,1,.411-8.349Zm15.224.558a.558.558,0,0,1,.558.558V20.86h2.791a.558.558,0,1,1,0,1.116H20.3a.558.558,0,0,1-.558-.558V17.511A.558.558,0,0,1,20.3,16.953Z" transform="translate(80.5 126.5)" fill="#b3776d" />
            </g>
        </svg>

    )
}