import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils/common";
export const useStyles = makeStyles((theme) => ({
    status: {
        backgroundColor: "white",
        padding: "auto 12px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 3px 18px #0000000F",
        cursor: "pointer",
    },
    statusdes: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().semiBold,
    },
}));