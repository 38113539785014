import * as React from "react"
const HomeIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <g data-name="Group 119262">
      <g data-name="Group 119267">
        <path
          fill="#436bef"
          d="M2.772 20.229V8.11l8.493-6.711 9.147 7.177-.653 11.652Z"
          data-name="Path 4327"
          opacity={0.1}
        />
        <path
          fill="#436bef"
          d="M11.049.001a.846.846 0 0 0-.5.182L2.536 6.501A4.233 4.233 0 0 0 .923 9.822V20.59a1.423 1.423 0 0 0 1.41 1.411h17.49a1.423 1.423 0 0 0 1.41-1.41V9.822a4.233 4.233 0 0 0-1.616-3.321L11.6.182a.846.846 0 0 0-.551-.181Zm.028 1.924 7.494 5.9a2.535 2.535 0 0 1 .967 1.993v10.49H2.615V9.822a2.535 2.535 0 0 1 .967-1.993Z"
          data-name="icons8-home (2)"
        />
        <path
          fill="none"
          stroke="#436bef"
          strokeWidth={1.6}
          d="M7.498 22v-6.35s.262-3.4 3.439-3.4 3.734 2.342 3.734 3.873V22"
          data-name="Path 4326"
        />
      </g>
    </g>
  </svg>
)
export default HomeIcon
