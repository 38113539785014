import React from "react";
import { withBottombar } from "../../HOCs";
import AmenityBookings from "./amenityBookings";


class AmenityImageParent extends React.Component {
    render() {
        return <AmenityBookings {...this?.props}  />
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(AmenityImageParent, props);