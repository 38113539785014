import * as React from "react";

const InvestmentImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14.476} height={16} {...props}>
    <path
      data-name="icons8-investment (1)"
      d="M1.714 0A1.714 1.714 0 0 0 0 1.714v11.81a1.714 1.714 0 0 0 1.714 1.714h5.348a1.506 1.506 0 0 1-.205-.762V9.9c0-1.5 2.108-2.286 4.19-2.286a8.108 8.108 0 0 1 1.143.08V6.1H7.81A1.714 1.714 0 0 1 6.1 4.381V0Zm5.524.335v4.046a.572.572 0 0 0 .571.571h4.046Zm-3.619 5.4a.476.476 0 0 1 .476.476v.3a1.57 1.57 0 0 1 1.18.806.476.476 0 1 1-.836.456.619.619 0 0 0-.544-.323h-.609a.619.619 0 1 0 0 1.238h.857a1.571 1.571 0 1 1 0 3.142H4.1v.27a.476.476 0 1 1-.952 0v-.268h-.181a1.571 1.571 0 0 1-1.38-.819.476.476 0 1 1 .836-.456.619.619 0 0 0 .544.324h1.181a.619.619 0 1 0 0-1.238h-.857a1.567 1.567 0 0 1-.143-3.129v-.3a.476.476 0 0 1 .471-.481Zm7.429 2.648c-1.894 0-3.429.682-3.429 1.524s1.535 1.524 3.429 1.524 3.429-.682 3.429-1.524-1.536-1.526-3.429-1.526ZM7.619 11.05v1.14c0 .842 1.535 1.524 3.429 1.524s3.429-.682 3.429-1.524v-1.142c0 .842-1.535 1.524-3.429 1.524s-3.429-.682-3.429-1.524Zm0 2.286v1.143c0 .842 1.535 1.524 3.429 1.524s3.429-.682 3.429-1.524v-1.146c0 .842-1.535 1.524-3.429 1.524s-3.429-.682-3.429-1.524Z"
      fill="#fff"
    />
  </svg>
);

export default InvestmentImage;
