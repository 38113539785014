import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useStyles } from "./style";
import moment from "moment";
export const TimeLine = ({ data }) => {
  const classes = useStyles();
  return (
    <Box>
      <div className={classes.createnewsectionlog}>
        <Grid container direction={"column"} spacing={1}>
          {data?.map((item, index, array) => {
            return (
              <Box className={classes.treeBox}>
                <Box className={classes.border}>
                  <Avatar variant="circular" className={classes.avatars1}>
                    <Box className={classes.image} />
                  </Avatar>
                  <Box>
                    <Typography className={classes.title}>
                      {item?.remarks ?? "-"}
                    </Typography>
                    <Box height={"4px"} />
                    <Typography className={classes.notesTimeLine}>
                      Accepted on{" "}
                      {moment(new Date(item?.created_at)).format("DD MMM YY")}
                    </Typography>
                    {/* <Box height={"4px"} />
                    <Box className={classes.treeInnerBox}>
                        <Avatar className={classes.treeAvater}/>
                        <Typography className={classes.treeTitle}>Balavignesh Kumaran K</Typography>
                    </Box> */}
                  </Box>
                  <Box height={"20px"} />
                </Box>
              </Box>
            );
          })}
        </Grid>
      </div>
    </Box>
  );
};
