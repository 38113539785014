import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  second: {
    backgroundColor: "white",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    padding: "12px",
  },
}));
export const AgreementSimmer = (props) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  return (
    <div style={{ height: props?.height ?? size.height }}>
      <Skeleton variant="rectangular" height={50} />
      <Grid item style={{ padding: "12px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Skeleton
              variant="rectangular"
              height={300}
              style={{ borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={4}>
            <Skeleton
              variant="rectangular"
              height={300}
              style={{ borderRadius: "4px" }}
            />
          </Grid>
        </Grid>
        <br />
        <Skeleton
          variant="rectangular"
          height={50}
          style={{ borderRadius: "4px" }}
        />
      </Grid>
      <div className={classes.second}>
        <br />
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              height={100}
              style={{ borderRadius: "4px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              height={100}
              style={{ borderRadius: "4px" }}
            />
          </Grid>
        </Grid>
        <br />
        <Skeleton
          variant="rectangular"
          height={50}
          width={50}
          style={{ borderRadius: "4px" }}
        />
      </div>
    </div >
  );
};
