import React from "react"

export const AcknowledgeIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
  <g id="Group_117717" data-name="Group 117717" transform="translate(6340 1515)">
    <rect id="Rectangle_58550" data-name="Rectangle 58550" width="28" height="28" rx="14" transform="translate(-6340 -1515)" fill="#896db3"/>
    <g id="Group_117716" data-name="Group 117716" transform="translate(-93 -67)">
      <g id="Rectangle_58549" data-name="Rectangle 58549" transform="translate(-6240 -1441)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="14" height="14" stroke="none"/>
        <rect x="0.5" y="0.5" width="13" height="13" fill="none"/>
      </g>
      <path id="icons8-approve_1_" data-name="icons8-approve (1)" d="M11,4a7,7,0,1,0,7,7A7.008,7.008,0,0,0,11,4Zm3.117,7.162-.54,2.117a1.624,1.624,0,0,1-1.958,1.175L8.709,13.74a1.054,1.054,0,0,1-.78-.82l-.154-.8a.857.857,0,0,1,.536-.965l.181-.069a2.453,2.453,0,0,0,1.181-.963l1.094-1.712a.265.265,0,0,0,.034-.074q.144-.494.2-.7c.016-.056.031-.113.058-.218.088-.335.164-.623.482-.623.441,0,.957.37.957,1.412a5.877,5.877,0,0,1-.354,1.642H13.1a1.049,1.049,0,0,1,1.017,1.309Z" transform="translate(-6244 -1445)" fill="#fff"/>
    </g>
  </g>
</svg>

    )
}