import React from "react"

export const LikeIcon = () => {
    return (
        <svg id="Group_117863" data-name="Group 117863" xmlns="http://www.w3.org/2000/svg" width="18" height="15.75" viewBox="0 0 18 15.75">
            <path id="icons8-heart_1_" data-name="icons8-heart (1)" d="M8.95,7A4.96,4.96,0,0,0,4,11.95c0,1.843,1.389,3.579,3.083,5.359s3.779,3.583,5.44,5.244a.675.675,0,0,0,.954,0c1.661-1.661,3.746-3.464,5.44-5.244S22,13.793,22,11.95A4.96,4.96,0,0,0,17.05,7,5.179,5.179,0,0,0,13,9.267,5.179,5.179,0,0,0,8.95,7Z" transform="translate(-4 -7)" fill="#ff4b4b" opacity="0" />
            <path id="icons8-heart" d="M7.011,3.875A5.088,5.088,0,0,0,3.406,5.256,4.989,4.989,0,0,0,2,8.886c0,2.477,1.518,4.324,3.247,5.881a54.733,54.733,0,0,1,5.114,4.551.811.811,0,0,0,.614.307h.051a.811.811,0,0,0,.614-.307,54.72,54.72,0,0,1,5.114-4.551C18.482,13.211,20,11.363,20,8.886a5.007,5.007,0,0,0-1.432-3.631,5.026,5.026,0,0,0-3.58-1.381,5.235,5.235,0,0,0-3.349,1.457,4.29,4.29,0,0,0-.639.69,4.148,4.148,0,0,0-.639-.69A5.228,5.228,0,0,0,7.011,3.875Zm0,1.636A3.875,3.875,0,0,1,9.287,6.585a7.819,7.819,0,0,1,1.048,1.1.817.817,0,0,0,1.33,0,7.594,7.594,0,0,1,1.048-1.1,3.875,3.875,0,0,1,2.276-1.074,3.416,3.416,0,0,1,2.455.92,3.27,3.27,0,0,1,.92,2.455c0,1.812-1.1,3.2-2.71,4.653C14.225,14.828,12.48,16.148,11,17.707c-1.483-1.566-3.222-2.88-4.653-4.168-1.614-1.454-2.71-2.841-2.71-4.653a3.27,3.27,0,0,1,.92-2.455A3.41,3.41,0,0,1,7.011,5.511Z" transform="translate(-2 -3.875)" fill="#98a0ac" />
        </svg>

    )
}