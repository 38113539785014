import { Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";

const ButtonGroupSelect = ({
  data,
  updateState,
  label,
  stateName,
  state,
  variant,
  labelColor,
  isRequired,
  endIcon,
  index = null
}) => {
  const classes = useStyles();
  return (
    <>
      {label && (
        <Typography
          className={classes.label}
          sx={{ color: labelColor ? labelColor : "#98A0AC" }}
        >
          <Stack direction={"row"} alignItems="center">
            <span>
              {label}{" "}
              {isRequired && (
                <Typography color="error" variant="caption">
                  &nbsp;*
                </Typography>
              )}
            </span>
            {endIcon}
          </Stack>
        </Typography>
      )}
      <Grid container spacing={1} sx={{ marginTop: "-16px" }}>
        {data?.map((value) => {
          const Icon = value?.startIcon;
          const EndIcon = value?.endIcon;
          return (
            <Grid item>
              <Button
                onClick={() => updateState(stateName, value, index)}
                startIcon={
                  Icon && (
                    <Icon
                      color={
                        state?.[stateName]?.value === value?.value
                          ? "#5078E1"
                          : "#98a0ac"
                      }
                    />
                  )
                }
                endIcon={
                  EndIcon && (
                    <Icon
                      color={
                        state?.[stateName]?.value === value?.value
                          ? "#5078E1"
                          : "#98a0ac"
                      }
                    />
                  )
                }
                className={
                  state?.[stateName]?.value === value?.value
                    ? variant === "outlined"
                      ? classes.active2
                      : classes.active
                    : variant === "outlined"
                    ? classes.unactive2
                    : classes.unactive
                }
              >
                {value?.label}
              </Button>
            </Grid>
          );
        })}
      </Grid>
      {state?.error?.[stateName]?.length > 0 && (
        <Typography
          style={{ marginTop: "6px" }}
          color="error"
          variant="caption"
        >
          {state?.error?.[stateName]}
        </Typography>
      )}
    </>
  );
};
export default ButtonGroupSelect;
