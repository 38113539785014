import { Avatar, Box, Button, Container, Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

import { Close } from "@mui/icons-material";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Slider from "react-slick";
import { withBottombar } from "../../HOCs";
import { ArrowWithCircleIcon } from "../../assets/arrowWithCircle";
import { UnitAssetInfo } from "../../assets/assetInfo";
import { EllipseIcon } from "../../assets/ellipseIcon";
import { NonAssetIcon } from "../../assets/nonAssetIcon";
import { UnitAssetIcon } from "../../assets/unitAsset";
import { UnitIcon } from "../../assets/unitIcon";
import { DialogDrawer } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { GET_UNITS_BY_AGREEMENT_ID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Search } from "../agreementparking/component";
import { useStyles } from "./style";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";

const useUnload = (fn) => {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
        cb.current = fn;
    }, [fn]);

    useEffect(() => {
        const onUnload = (...args) => cb.current?.(...args);

        window.addEventListener("beforeunload", onUnload);

        return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
};
const Step0 = (props) => {
    const match = useMediaQuery('(min-width:602px)')
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: match ? 3 : 2.5,
        slidesToScroll: 2,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    };
    const Imgsettings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        // autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
    }
    const { t = () => false, handleTopBarState, unit = "", setUnit = () => false, product = "", setProduct = () => false,goBack=()=>false } = props
    const classes = useStyles();
    const alert = React.useContext(AlertContext)
    const size = useWindowDimensions()
    const backdrop = React.useContext(BackdropContext);
    const [searchText, setSearchText] = React.useState("")
    const [unitList, setUnitList] = React.useState([])
    const [productList, setProductList] = React.useState([])
    const [offset, setOffset] = React.useState(0);
    const [unitOffset, setUnitOffset] = React.useState(0);
    const [imageViewer, setImageViewer] = React.useState(false)
    useUnload((e) => {
        e.preventDefault();
        e.returnValue = "";
    });

    // const handleAgreementList = (value) => {
    //   // props?.getUnit(value);
    //   props.request.agreement = value;
    //   props.request.unit = [];
    //   props.setRequest(props?.request);
    // };


    React.useEffect(() => {
        handleTopBarState({
            text: t("Choose Asset"),
            customBackHandle:()=>goBack()
        })
        getAllunitsByuserId()
        // eslint-disable-next-line
    }, []);
    const getAllunitsByuserId = (offset = 0, scroll = false) => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        NetworkCall(
            `${config.api_url}/agreement-unit-info/switcher`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        )
            .then((response) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                getUnit(response?.data?.data?.map((val) => { return val?.id }), offset, scroll)
            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                if (error?.code === 503) {
                } else {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                    });
                }
            });
    };
    const getUnit = (value, offset = 0, scroll = false) => {
        const payload = {
            query: GET_UNITS_BY_AGREEMENT_ID,
            variables: {
                id: value,
                offset: offset,
                limit: 10
            },
        };
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                if (scroll) {
                    setUnitList(unitList?.concat(response?.data?.data?.agreement_units));
                    setUnit({ ...unit, selectedUnit: { ...unitList?.[0]?.unit, agreement_id: unitList?.[0]?.agreement_id } })
                }
                else {
                    setUnitList(response?.data?.data?.agreement_units);
                    setUnit({ ...unit, selectedUnit: { ...response?.data?.data?.agreement_units?.[0]?.unit, agreement_id: response?.data?.data?.agreement_units?.[0]?.agreement_id } })
                    getInspectionItems(response?.data?.data?.agreement_units?.[0]?.unit?.value)
                }

            })
            .catch((error) => {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };
    const getInspectionItems = (unit_id, offset = 0, search = "", scroll = false) => {

        const payload = {
            unit_id: unit_id,
            search: search,
            offset: offset,
            limit: 10,
            active: null,
            item_type: ["Inventory", "Product", "Services"]
        }
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        NetworkCall(
            `${config.api_url}/inspection_item_mapping/getAll`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
            if (scroll) {
                setProductList(productList?.concat(response?.data?.data));
            }
            else {
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "",
                });
                setProductList(response?.data?.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    //handle search
    const handleSearch = (value) => {
        setSearchText(value)
        getInspectionItems(unit?.selectedUnit?.value, offset, value);
    }
    const handleSelectUnit = (val) => {
        setUnit({ ...unit, bool: false, selectedUnit: { ...val?.unit, agreement_id: val?.agreement_id } })
        getInspectionItems(val?.unit?.value)
    }
    const goToNextPage = () => {
        setProduct({ ...product, bool: false, is_product: false, is_unit: false })
        props?.next(1)
    }
    const fetchMoreData = () => {
        setOffset(offset + 10)
        getInspectionItems(unit?.selectedUnit?.value, offset + 10, "", true);
    }
    const fetchMoreUnitData = () => {
        setUnitOffset(unitOffset + 10)
        getAllunitsByuserId(unitOffset + 10, true);
    }
    const openImageViewer = () => {
        setImageViewer(true)
    }
    const closeImageViewer = () => {
        setImageViewer(false)
    }
    return (
        <Container maxWidth="sm" className={classes.root}>
            <Box className={classes.unitbackground}>
                <Box className={classes.unitBox} display={"flex"} justifyContent={"space-between"} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <UnitIcon />
                        <Box style={{ marginInlineStart: "8px" }}>
                            <Typography className={classes.title}>{unit?.selectedUnit?.name}</Typography>
                            <Typography className={classes.unitNoTitle}>{unit?.selectedUnit?.unit_no}</Typography>
                        </Box>
                    </Stack>
                    <Typography className={classes.changetext} onClick={() => setUnit({ ...unit, bool: true })}>{t("Change")}</Typography>
                </Box>
            </Box>
            <Box p={2}>
                <InfiniteScroll
                    dataLength={productList?.length ?? 10}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 200}
                >
                    <Search onChange={handleSearch} value={searchText} placeholder={t("Search Item")} />
                    <Box mt={2} className={classes.nonAssetBox}>
                        <Box display="flex" justifyContent={"center"} className={classes.nonAssetBg}>
                            <Typography className={classes.nonAssetText}>{t("For Unit/Seat Based Service and Maintenance")}</Typography>
                        </Box>
                        <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <NonAssetIcon />
                                <Box style={{ marginInlineStart: "8px" }}>
                                    <Typography className={classes.title}>{unit?.selectedUnit?.name}</Typography>
                                    <Typography className={classes.subTitle}>{unit?.selectedUnit?.unit_no}</Typography>
                                </Box>
                            </Stack>
                            <Box onClick={() => setProduct({ ...product, bool: true, is_unit: true, selectedProduct: "" })} style={{ cursor: "pointer" }}>
                                <ArrowWithCircleIcon />
                            </Box>
                        </Box>
                    </Box>

                    {productList?.length > 0 ? productList?.map((val, i) => {
                        return (
                            <Box mt={1} className={classes.nonAssetBox}>
                                <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Avatar src={val?.assets !== null ? JSON.parse(val?.assets)?.[0]?.url : "../images/inspectionItem.svg"} variant="square" className={classes.inspectionItemIcon} />
                                        <Box style={{ marginInlineStart: "8px" }}>
                                            <Typography className={classes.title}>{val?.name ?? "-"}</Typography>
                                            <Typography className={classes.subTitle}>{val?.manufaturer_name ?? "-"}</Typography>
                                        </Box>
                                    </Stack>
                                    <Box onClick={() => setProduct({ ...product, bool: true, selectedProduct: val, is_product: true })} style={{ cursor: "pointer" }}>
                                        <ArrowWithCircleIcon />
                                    </Box>
                                </Box>
                                <Divider />

                                <Box p={2}>
                                    <Slider {...settings}>
                                        {val?.location_id !== null &&
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Stack direction="row" alignItems="center">
                                                    <Box style={{ marginInlineEnd: "8px" }}><img src="../images/locationAsset.svg" alt="icon" /></Box>
                                                    <Box>
                                                        <Typography className={classes.productDetails} noWrap>{val?.location_name ?? "-"}</Typography>
                                                        <Typography className={classes.productDetailsTitle} noWrap>{t("Location")}</Typography>
                                                    </Box>
                                                </Stack>
                                            </Grid>
                                        }
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Stack direction="row" alignItems="center">
                                                <Box style={{ marginInlineEnd: "8px" }}><img src="../images/serialNumber.svg" alt="icon" /></Box>
                                                <Box>
                                                    <Typography className={classes.productDetails} noWrap>{val?.serial_number ?? "-"}</Typography>
                                                    <Typography className={classes.productDetailsTitle} noWrap>{t("Serial Number")}</Typography>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Stack direction="row" alignItems="center">
                                                <Box style={{ marginInlineEnd: "8px" }}><img src="../images/modelNumber.svg" alt="icon" /></Box>
                                                <Box>
                                                    <Typography className={classes.productDetails} noWrap>{val?.model_number ?? "-"}</Typography>
                                                    <Typography className={classes.productDetailsTitle} noWrap>{t("Model Number")}</Typography>
                                                </Box>
                                            </Stack>
                                        </Grid>


                                        <Grid item xs={6} sm={6} md={6}>
                                            <Stack direction="row" alignItems="center">
                                                <Box style={{ marginInlineEnd: "8px" }}><img src="../images/lastServiceAsset.svg" alt="icon" /></Box>
                                                <Box>
                                                    <Typography className={classes.productDetails} noWrap>{moment(val?.last_service_date).format("DD MMM YYYY")}</Typography>
                                                    <Typography className={classes.productDetailsTitle} noWrap>{t("Last Service")}</Typography>
                                                </Box>
                                            </Stack>
                                        </Grid>

                                        {val?.item_condition !== null &&
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Box className={classes.itemBox} mt={0.5}>
                                                    <Typography className={classes.itemCondition}>{val?.item_condition}</Typography>
                                                </Box>
                                            </Grid>
                                        }
                                    </Slider>
                                </Box>

                            </Box>
                        )
                    })
                        :
                        <Box mt={3} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography className={classes.nodataTitle}>{t("No Assets Found")}</Typography>
                        </Box>
                    }
                </InfiniteScroll>
            </Box>
            <DialogDrawer
                open={unit?.bool}
                onClose={() => setUnit({ ...unit, bool: false })}
                isnotTitle={true}
                padding={0}
                overflow={"overlay"}
                component={
                    <Box>
                        <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.unitSwitchTitle}>{t("Unit Switcher")}</Typography>
                            <Close color="#4E5A6B" onClick={() => setUnit({ ...unit, bool: false })} />
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Box className={classes.unitSectionBox} p={2}>
                                <InfiniteScroll
                                    dataLength={unitList?.length ?? 10}
                                    next={fetchMoreUnitData}
                                    hasMore={true}
                                    height={size?.height - 200}
                                >
                                    {unitList?.map((val, i) => {
                                        return (
                                            <>
                                                <Box display="flex" justifyContent={"space-between"} alignItems={"center"} onClick={() => handleSelectUnit(val)}>
                                                    <Stack direction={"row"} alignItems="center">
                                                        <Box style={{ marginInlineEnd: "8px" }}><UnitIcon /></Box>
                                                        <Box>
                                                            <Typography className={classes.unitTitle}>{val?.unit?.label}</Typography>
                                                            <Box display="flex" alignItems="center">
                                                                <Typography className={classes.subTitle} noWrap>{val?.unit?.unit_no}</Typography>
                                                                <Box className={classes.unitDot}></Box>
                                                                <Typography className={classes.propertyName} noWrap>{val?.unit?.property?.name}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Stack>
                                                    {unit?.selectedUnit?.value === val?.unit?.value ?
                                                        <Box style={{ cursor: "pointer" }}>
                                                            <EllipseIcon color="#5078E1" /></Box>
                                                        : <Box  style={{ cursor: "pointer" }}><EllipseIcon /></Box>}

                                                </Box>
                                                {unitList?.length - 1 !== i &&
                                                    <Box mt={1.5} mb={1.5}><Divider /></Box>
                                                }
                                            </>
                                        )
                                    })}
                                </InfiniteScroll>
                            </Box>
                        </Box>
                    </Box>
                }
            />
            <DialogDrawer
                open={product?.bool}
                onClose={() => setProduct({ ...product, bool: false, is_product: false, is_unit: false })}
                isnotTitle={true}
                padding={0}
                overflow={"overlay"}
                component={
                    <Box>
                        <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems="center">
                            <Typography className={classes.unitSwitchTitle}>{product?.is_product ? t("Asset Details") : t("Unit Details")}</Typography>
                            <Close color="#4E5A6B" onClick={() => setProduct({ ...product, bool: false, is_product: false, is_unit: false })} />
                        </Box>
                        {product?.is_product ?
                            <Box p={2}>
                                <center>
                                    {product?.selectedProduct?.assets?.length > 0 &&
                                        <Slider {...Imgsettings}>
                                            {JSON.parse(product?.selectedProduct?.assets)?.map((x) => {
                                                return (

                                                    <Box mb={1.5} onClick={openImageViewer}>
                                                        <img src={x?.url} alt="product asset" className={classes.productImage} />
                                                    </Box>

                                                )
                                            })}
                                        </Slider>
                                    }
                                    {product?.selectedProduct?.item_condition !== null &&
                                        <Box className={classes.itemBox}>
                                            <Typography className={classes.itemCondition}>{product?.selectedProduct?.item_condition}</Typography>
                                        </Box>
                                    }
                                    <Box mt={1.5}>
                                        <Typography className={classes.itemName}>{product?.selectedProduct?.name ?? "-"}</Typography>
                                    </Box>
                                </center>
                                <Box mt={2} height={"180px"}>
                                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography className={classes.productTitle}>{t("Manufacturer")}</Typography>
                                        <Typography className={classes.productValue}>{product?.selectedProduct?.manufaturer_name ?? "-"}</Typography>
                                    </Box>
                                    <Box mt={1.5} mb={1.5}><Divider /></Box>
                                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography className={classes.productTitle}>{t("Serial Number")}</Typography>
                                        <Typography className={classes.productValue}>{product?.selectedProduct?.serial_number ?? "-"}</Typography>
                                    </Box>
                                    <Box mt={1.5} mb={1.5}><Divider /></Box>
                                    <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography className={classes.productTitle}>{t("Model Number")}</Typography>
                                        <Typography className={classes.productValue}>{product?.selectedProduct?.model_number ?? "-"}</Typography>
                                    </Box>
                                    {product?.selectedProduct?.location_id !== null &&
                                        <>
                                            <Box mt={1.5} mb={1.5}><Divider /></Box>
                                            <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                                <Typography className={classes.productTitle}>{t("Location")}</Typography>
                                                <Typography className={classes.productValue}>{product?.selectedProduct?.location_name ?? "-"}</Typography>
                                            </Box>
                                        </>
                                    }
                                    {product?.selectedProduct?.last_service_date !== null &&
                                        <>
                                            <Box mt={1.5} mb={1.5}><Divider /></Box>
                                            <Box display="flex" justifyContent={"space-between"} alignItems={"center"}>
                                                <Typography className={classes.productTitle}>{t("Last Service")}</Typography>
                                                <Typography className={classes.productValue}>{moment(product?.selectedProduct?.last_service_date)?.format("DD MMM YYYY")}</Typography>
                                            </Box>
                                        </>
                                    }
                                </Box>

                            </Box>
                            :
                            <Box p={2}>
                                <center>
                                    <Box className={classes.unitAsset}>
                                        <UnitAssetIcon />
                                    </Box>
                                    <Box mt={1}>
                                        <Typography className={classes.itemName}>{unit?.selectedUnit?.label}</Typography>
                                    </Box>
                                    <Box mt={0.5}>
                                        <Typography className={classes.productValue}>{unit?.selectedUnit?.unit_no}</Typography>
                                    </Box>
                                    <Box mt={1}>
                                        <UnitAssetInfo />
                                    </Box>
                                    <Box className={classes.assetInfoBox} mt={-2}>
                                        <Typography className={classes.infoText}>{t("With this type you can create maintenance request against any unit level problem.")}</Typography>
                                        <Box mt={1}>
                                            <Typography className={classes.infoText}>{t("Ex: Water leakage, Switch etc")}</Typography>
                                        </Box>
                                    </Box>
                                </center>
                            </Box>
                        }
                        <Box p={2} mt={1}>
                            <Button variant="contained" fullWidth className={classes.raiseRequestbtn} onClick={() => goToNextPage()}>{t("Create Request")}</Button>
                        </Box>
                    </Box>
                }
            />
            {/* Image Viewer */}
            {imageViewer &&
                <DialogDrawer
                    open={imageViewer}
                    onClose={() => closeImageViewer()}
                    isnotTitle={true}
                    padding={0}
                    overflow={"overlay"}
                    component={
                        <ImageAndPdfViewer
                            title={t("Assets")}
                            assets={JSON.parse(product?.selectedProduct?.assets)?.length > 0 ? JSON.parse(product?.selectedProduct?.assets)?.map((val) => {
                                return {
                                    meta_data: {
                                        file_type: "image",
                                    },
                                    file_url: val?.url,
                                };
                            }) : []}
                            onClose={() => closeImageViewer()}
                        />
                    }
                />
            }
        </Container>
    );
};

const props = {
    top_bar: "title_bar",
}

export default withBottombar(withNamespaces("maintance")(Step0), props)