import * as React from "react"

export const Progress = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 108159" transform="translate(-344.84 -311.998)">
      <rect
        data-name="Rectangle 55272"
        width={40}
        height={40}
        rx={4}
        transform="translate(344.84 311.998)"
        fill="#fff4eb"
      />
      <g data-name="Group 104828">
        <path
          data-name="Rectangle 55282"
          fill="none"
          d="M352.84 319.998h24v24h-24z"
        />
        <path
          d="M364.751 322.999a9.2 9.2 0 0 0-1.008.055.909.909 0 0 0-.753.528.893.893 0 0 0 .1.908.913.913 0 0 0 .851.352 7.375 7.375 0 0 1 .81-.043 7.2 7.2 0 1 1 0 14.4 7.369 7.369 0 0 1-.808-.044q-.045-.006-.091-.007a.902.902 0 0 0-.11 1.8 9.22 9.22 0 0 0 .933.054h.075a9 9 0 1 0 .072-18l-.071-.003Zm-3.939 1.046-.509.111-.246.135-.06.037-.53.348-.057.04-.5.387-.053.045-.237.215-.3.685.331.671.726.2.47-.225.209-.19.453-.35.461-.3.217-.121.447-.6-.161-.734Zm-3.22 3.727-.747.089-.349.383-.091.192-.029.063-.23.6-.023.069-.182.618-.018.07-.073.352.166.727.663.353.7-.262.248-.454.066-.318.161-.545.206-.534.075-.157.016-.748Zm10.39 1.068a.913.913 0 0 0-.708.289l-3.7 3.861-1.842-1.658a.915.915 0 0 0-1.284.061.894.894 0 0 0 .061 1.272l2.5 2.25a.915.915 0 0 0 1.27-.047l4.317-4.5a.894.894 0 0 0 .2-.96.908.908 0 0 0-.814-.568Zm-11.521 3.914-.559.5-.062.512.008.046.012.06.13.529.017.058.164.515.02.056.2.5.024.055.081.17.573.483.744-.111.4-.628-.077-.511-.067-.14-.179-.454-.139-.444-.118-.476v-.027l-.415-.6Zm2.671 4.324-.645.383-.136.738.267.443.2.181.045.038.415.33.047.035.437.3.05.032.457.275.747.089.558-.5-.012-.745-.35-.382-.427-.257-.382-.264-.377-.3-.175-.166Z"
          fill="#ff9340"
        />
      </g>
    </g>
  </svg>
)

