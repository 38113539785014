import { AmenitiesBookingStyles } from "./style";
import MonthlyInvoiceCard from "./components/monthlyInvoice";
import useWindowDimensions from "../../utils/useWindowDimensions";
import {
  Box, Container, Hidden,
  Dialog, Drawer,
  IconButton, Typography
} from "@mui/material";
import { withBottombar } from "../../HOCs";
import React from "react";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { useLocation } from "react-router";
import NoDataFound from "../../assets/noData";
import { withNamespaces } from "react-i18next";
import { format } from "date-fns";
import { AlertContext } from "../../contexts";
import { DocumentViewer, LoderSimmer } from "../../components";
import CloseIcon from "@mui/icons-material/Close";

const BookingHistory = ({ t = () => false, handleTopBarState }) => {
  const classes = AmenitiesBookingStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext)
  const [bookingList, setBookingList] = React.useState([])
  const [template, setTemplate] = React.useState("")
  const [viewInvoice, setViewInvoice] = React.useState(false);
  const [loader, setLoader] = React.useState(false)
  const [pdfLoader, setPdfLoader] = React.useState(false)
  const state = useLocation();

  // Get Booking List 
  const getBookingList = () => {
    setLoader(true)
    const payload = {
      property_facility_id: state?.state?.facilityId,
      current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
    }
    NetworkCall(
      `${config.api_url}/amenities_booking_v2/get_my_bookings`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setLoader(false)
        setBookingList(response?.data?.data?.bookings)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  React.useEffect(() => {
    handleTopBarState({
      text: state?.state?.amenities_name
    })
    getBookingList()
    // eslint-disable-next-line
  }, []);


  const handleView = (val) => {
    getPdf(val)
  }

  const getPdf = (val) => {
    setViewInvoice(true)
    setPdfLoader(true)
    const payload = {
      invoice_id: val?.invoice_id ?? undefined,
      is_template: true
    };
    NetworkCall(
      `${config?.api_url}/amenities_booking_v2/view_pdf`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setTemplate(response?.data?.data)
        setPdfLoader(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  return (
    <Container classes={classes.root} maxWidth="sm" sx={{ padding: "0" }}>
      {/* <TitleBar text="Swimming Pool" goBack={() => history.goBack()} /> */}
      {
        loader ?
          <LoderSimmer card count={6} size={100} />
          :
          <>
            {
              bookingList?.length > 0 ?
                <div
                  className={[classes.roots_history]}
                  style={{
                    height: size?.height - 55,
                  }}
                >
                  <div>
                    {bookingList?.map((val) => {
                      return (
                        <MonthlyInvoiceCard
                          t={t}
                          paid_amount={val?.paid_amount}
                          total_hours={val?.total_hours}
                          bookedFrom={val?.booking_start_time}
                          booking_end_time={val?.booking_end_time}
                          booked_date={val?.booked_date}
                          rate_per_slot={val?.rate_per_slot}
                          paid_on={val?.paid_on}
                          inv_payment_status={val?.inv_payment_status}
                          pdf_url={val?.pdf_url}
                          val={val}
                          handleView={handleView}
                          template={template}
                          viewInvoice={viewInvoice}
                          setViewInvoice={setViewInvoice}
                        />
                      )
                    })}
                  </div>
                  {/* <div>
          <Button className={classes.button} variant="contained" fullWidth>
            Pay Now ({props.price}.00)
          </Button>
        </div> */}
                </div> :
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', marginTop: "50%" }}>
                  <NoDataFound />
                </Box>
            }
          </>
      }


      <Hidden smDown>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={viewInvoice}
          className={classes.dialog}
          sx={{ padding: "0px" }}
        >
          <Box
            display="flex"
            p={1}
            alignItems="center"
            className={classes.titleroot}
          >
            <Box flexGrow={1}>
              <Typography className={classes.popupTitle}>
                {("View_Invoice")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                onClick={() => setViewInvoice(!viewInvoice)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <div style={{ height: size?.height - 115 }}>
            {
              pdfLoader ? 
              <LoderSimmer card count={6} size={100} /> 
              :
              <DocumentViewer url={`data:image/png;base64,${template}`} />
            }
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          maxWidth="sm"
          anchor="bottom"
          fullWidth
          open={viewInvoice}
          className={classes.drawer}
        >
          <div>
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.popupTitle}>
                  {("View_Invoice")}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => setViewInvoice(!viewInvoice)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div style={{ height: size?.height - 115 }}>
              {
                pdfLoader ? 
                <LoderSimmer card count={6} size={100} /> 
                :
                <DocumentViewer url={`data:image/png;base64,${template}`} />
              }
            </div>
          </div>
        </Drawer>
      </Hidden>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("bookingHistory")(BookingHistory), props);
