import React from "react";
import WelcomePage from "./welcomepage";

class WelcomePageParent extends React.Component {
    render() {
        return (
            <WelcomePage />


        )
    }
}

export default WelcomePageParent;

