import { Stack, Box, Avatar, Typography } from "@mui/material";
import { useStyles } from "./style";
import moment from "moment-timezone";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { BounceDown } from "../../../assets/bounceDown";
import { BounceUp } from "../../../assets/bounceUp";
import { statistics_category_icon } from "../../../utils";
import DefaultSpendingIcon from "../../../assets/defaultSpendingIcon";
// import { LocalStorageKeys } from "../../../utils";


const Activity = (props) => {
  const classes = useStyles();
  const size = useWindowDimensions();
  // const clientCurrency = JSON.parse(LocalStorageKeys.getItem(LocalStorageKeys.clientCurrency))
  // const currency = JSON.parse(localStorage.getItem(LocalStorageKeys.currencyId))\

  return (
    <>
      <Stack direction="row" alignItems={"center"} spacing={2} mb={"10px"} pt={2} onClick={() => props?.onClick(props?.data?.id)}>
          <Avatar variant="rounded" className={classes.transportation}>
            {statistics_category_icon[props?.data?.category_id]?.icon ?? <DefaultSpendingIcon />}
          </Avatar>
        <Stack direction="column" spacing={"5px"} flexGrow={1}>
          <div display={"flex"}>
            <div flexGrow={1}>
              <Box mb={"8px"}>
                <Stack direction="row" spacing={"12px"}>
                  <Typography className={classes.price}>
                    {props?.data?.amount}</Typography>
                  <Typography className={classes.activity}>
                    {props?.data?.category_name}
                  </Typography>
                </Stack>
              </Box>
              <Stack
                direction="row"
                spacing={size?.width > 320 ? 1 : "2px"}
                alignItems="center"
                divider={<Box className={classes.dot} />}
              // sx={{ maxWidth: size?.width - 465}}
              >
                {props?.type === "Spendings" &&
                  <>
                    <Typography noWrap className={classes.work}>{props?.data?.sub_category_name}</Typography>
                  </>}
                <Typography className={classes.work}>{props?.data?.sub_category_name}</Typography>
                <Typography className={classes.work} noWrap sx={{ maxWidth: size?.width - 20 }}>
                  {moment(props?.data?.date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY ")}
                </Typography>
              </Stack>
            </div>
          </div>
        </Stack>
        <div>
          <Stack direction={"column"} spacing={"9px"} textAlign={"right"} sx={{ maxWidth: size?.width - 20 }}>
            {props.data?.current_value > 0 && (
              <>
                <Typography className={props?.data?.current_value < props?.data?.amount ? classes.percentageL : classes.percentageP} noWrap>{props?.data?.percentage}{" "}{props?.data?.current_value < props?.data?.amount ? <BounceDown /> : <BounceUp />}</Typography>
                <Stack direction={"row"} alignItems={"center"} spacing={1} >
                  <Typography className={classes.work1} sx={{ maxWidth: size?.width - 20 }} noWrap>Cur. Value</Typography>
                  <Typography className={classes.price1} sx={{ maxWidth: size?.width - 20 }} noWrap>{props?.data?.current_value}</Typography>
                </Stack>
              </>
            )}
          </Stack>
        </div>
      </Stack>
    </>
  );
};

export default Activity;
