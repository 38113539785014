import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { MobileNumberInputComponent, TextBox } from "../../../components";
import { Routes } from "../../../router/routes";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import { useStyles } from "./styles";

const ContactSales = ({t = () => false}) => {
  const InitialState = {
    enquire: "Me",
    name: "",
    phoneNumber: "",
    mailId: "",
    description: "",
    error: {
      enquire: "",
      name: "",
      phoneNumber: "",
      mailId: "",
      description: "",
    },
  };
  const size = useWindowDimensions();
  let fullSize = size?.height - 290;
  const classes = useStyles();
  const history = useHistory();
  const [data, setdata] = React.useState({ ...InitialState });
  const updateState = (key, value) => {
    console.log(key, value);
    let error = data?.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };

  const isValidData = () => {
    let isValid = true;
    let error = data.error;
  
    if (data?.name?.length === 0) {
      isValid = false;
      error.name = t("Name is required");
    } else {
      isValid = true;
      error.name = "";
    }
  
    if (data?.phoneNumber?.length === 0) {
      isValid = false;
      error.phoneNumber = "Phone Number is required";
    } else {
      isValid = true;
      error.phoneNumber = "";
    }
     
    if (data?.mailId?.length === 0) {
      isValid = false;
      error.mailId = "Mail Id is required";
    } else {
      isValid = true;
      error.mailId = "";
    }
 
    if (data?.description?.length === 0) {
      isValid = false;
      error.description = t("Description/Requirement is required");
    } else {
      isValid = true;
      error.description = "";
    }

    setdata({ ...data, error });
    return isValid;
  };
  const submitData = () => {
    if (isValidData()) {
      history.push(Routes.propertySearch);
    }
  };
  return (
    <Box>
      <div
        className={classes.mainContainer}
        style={{ height: fullSize, overflow: "overlay" }}
      >
        <Typography class={classes.label} component="span">
          {t("This Enquire For")}
        </Typography>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Typography onClick={()=>updateState("enquire", "Me")} class={data.enquire === "Me" ? classes.chipActive : classes.chidDeactive}>{t("Me")}</Typography>
          <Typography onClick={()=>updateState("enquire", "Others")} class={data.enquire === "Others" ? classes.chipActive : classes.chidDeactive}>{t("Others")}</Typography>
        </Stack>
        <div className={classes.input}>
          <TextBox
            isRequired
            color
            labelColor={"#98A0AC"}
            label={t("Name")}
            placeholder={t("Enter Name")}
            value={data.name}
            onChange={(value) => updateState("name", value.target.value)}
            isError={data?.error?.name?.length > 0}
            errorMessage={data?.error?.name}
          />
        </div>
        <MobileNumberInputComponent
          isRequired
          label={t("Phone Number")}
          labelColor={"#98A0AC"}
          value={data?.phoneNumber}
          placeholder={t("Enter Phone Number")}
          handleChange={(value) => {
            updateState("phoneNumber", value);
          }}
          isError={data?.error?.phoneNumber?.length > 0}
          errorMessage={data?.error?.phoneNumber}
        />
        <div className={classes.input}>
          <TextBox
            isRequired
            color
            labelColor={"#98A0AC"}
            label={t("Mail Id")}
            placeholder={t("Enter Occupants Name")}
            value={data.mailId}
            onChange={(value) => updateState("mailId", value.target.value)}
            isError={data?.error?.mailId?.length > 0}
            errorMessage={data?.error?.mailId}
          />
        </div>
        <div className={classes.input}>
          <TextBox
            isRequired
            color
            labelColor={"#98A0AC"}
            label={t("Description/Requirement")}
            multiline={true}
            rows={3}
            placeholder={t("Enter Description/Requirement")}
            value={data.description}
            onChange={(value) => updateState("description", value.target.value)}
            isError={data?.error?.description?.length > 0}
            errorMessage={data?.error?.description}
          />
        </div>
      </div>
      <Box className={classes.btnroot}>
        <Button
          fullWidth
          className={classes.primary}
          variant="contained"
          onClick={() => submitData()}
        >
          {t("Submit")}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactSales;
