import * as React from "react";

const Residential = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={18} {...props}>
    <g transform="translate(-2 -3)" fill={props?.color ?? "#f6f6f6"}>
      <path
        data-name="Path 100141"
        d="M18 21H6a3 3 0 0 1-3-3v-8h2l7-5 7 5h2v8a3 3 0 0 1-3 3Z"
        opacity={0.35}
      />
      <path
        data-name="Path 100142"
        d="M20 3H4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h2.172a2 2 0 0 0 1.414-.586l2.782-2.782a2.319 2.319 0 0 1 3.273-.008l2.825 2.8a2 2 0 0 0 1.407.576H20a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Z"
      />
      <path data-name="Rectangle 56586" d="M10 16h4v5h-4z" />
      <circle
        data-name="Ellipse 129757"
        cx={2}
        cy={2}
        r={2}
        transform="translate(10 14)"
      />
      <path
        data-name="Path 100143"
        d="M18.5 17h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5Z"
      />
      <path
        data-name="Path 100144"
        d="M7.5 17h-2a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5Z"
      />
    </g>
  </svg>
);

export default Residential;
