import React from "react";

function Food() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#DB687B"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H21V18H0z"
            data-name="Rectangle 55522"
            transform="translate(81.699 184.39)"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 113453" transform="translate(-37.5 -162)">
        <rect
          width="42"
          height="42"
          fill="#FFECF2"
          data-name="Rectangle 57714"
          rx="4"
          transform="translate(37.5 162)"
        ></rect>
        <g data-name="Group 113440" transform="translate(6 -521)">
          <g
            clipPath="url(#clip-path)"
            data-name="Group 105883"
            transform="translate(-39.199 510.61)"
          >
            <path
              fill="#D98392"
              d="M18.73 6a7.7 7.7 0 00-2.816.434 5.953 5.953 0 011.5 1.1.67.67 0 01-.947.948 4.355 4.355 0 00-2.4-1.263 4.171 4.171 0 00-2.681.767 4.414 4.414 0 00-1.155 1.19 4.907 4.907 0 013.612 1.485.67.67 0 01-.947.947 3.56 3.56 0 00-3.25-1.036c0 .012-.007.024-.009.036a4.031 4.031 0 00-2.849 4.034 5.573 5.573 0 013.97 2.317.67.67 0 11-1.029.857c-1.4-1.676-2.642-1.861-3.2-1.843A6.343 6.343 0 006 18.73a3.236 3.236 0 001.116 2.734A1.851 1.851 0 008.454 22c1.864 0 4.6-2.254 8-5.6 3.187-3.194 5.625-5.808 5.625-7.944C22.08 6.919 20.377 6 18.73 6z"
              transform="translate(78.159 179.391)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Food;