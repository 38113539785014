import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { saveAs } from "file-saver";
import moment from "moment";
import React from "react";
import { withNamespaces } from "react-i18next";
import EntryLog from "../../assets/agreement/EntryLog";
import { ListIcon } from "../../assets/agreement/ListIcon";
import { Logins } from "../../assets/agreement/Logins";
import Statement from "../../assets/agreement/Statement";
import CommunityMemberModel from "../service/components/CommunityMemberModel";
import {
  AgreementDrawer,
  CardList,
  DialogDrawer,
  DocumentViewer,
  NoService,
  OptionMenu,
  SlideButton,
  TabsComponent,
} from "../../components";
import { AgreementSimmer } from "../../components/simmer/agreementSimmer";
import { config } from "../../config";
import { AlertContext, AuthContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, getTimeWithAmPm } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Invoicescreen from "../invoiceScreen";
import PaymentHistory from "../paymentHistory";
import PropertyCarousel from "./component/PropertyCarousel ";
import UnitDetails from "./component/UnitDetails";
import RenewalForm from "./component/renewalForm";
import TerminationForm from "./component/terminationForm";
// import { ServiceOrder } from "../../assets/agreement/ServiceOrder";
import { Avatar, Divider, Stack } from "@mui/material";
import { format } from "date-fns";
import BlueCity from "../../assets/blueCity";
import CheckedOut from "../../assets/checkedOut";
import { getAccess } from "../../utils";
import AmneityCheckoutCard from "./component/amenityCard";
import RequsetCard from "./component/requsetCard";
import { useStyles } from "./styles";
import { useHistory, useLocation } from "react-router-dom";

const AgreementNew = (props) => {
  const { t = () => false, i18n, handleTopBarState } = props
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const { state } = useLocation();
  const searchURL = useLocation().search;
  const origin = new URLSearchParams(searchURL).get("origin");

  const classes = useStyles({
    language: auth?.auth?.auth?.language,
    theme: props?.theme,
  });
  const [agreement, setAgreement] = React.useState([]);
  const [agreementList, setAgreementList] = React.useState([]);
  const [openList, setopenList] = React.useState(false);
  const [termination, settermination] = React.useState(false);
  const [checkoutDrawer, setCheckoutDrawer] = React.useState(false)
  const [renewl, setrenewl] = React.useState(false);
  const [pdf, setPdf] = React.useState(false);
  const [pdfData, setPdfData] = React.useState({ load: false, data: "" });
  const backdrop = React.useContext(BackdropContext);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [checkout, setCheckout] = React.useState([]);
  const [checkoutDetails, setCheckoutDetails] = React.useState({});
  const [isCheckedout, setIsCheckedout] = React.useState(false)
  const [loading, setLoading] = React.useState(true);
  const [toggleDrawerBottom, setToggleDrawerBottom] = React.useState(false);
  const size = useWindowDimensions();
  const [agreementData, setAgreementData] = React.useState({
    agreementRequest: [],
    assets: [],
    general: [],
    invoices: [],
    maintenance: [],
    property: [],
    agreement: {},
    lastes_requset: "",
  });
  const [noService, setNoService] = React.useState(false)

  const checkOut = (value) => {
    getCheckoutDetails(value)
  };

  const getAgreementData = (id, unit) => {
    setLoading(true);
    const data = {
      tenantId: `${config.tenantid}`,
      agreementId: id,
      units: unit,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/request-invoices`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      setAgreementData({
        agreement: response.data.data?.agreement,
        agreementRequest: response.data.data?.agreementRequest,
        assets: response.data.data?.assets,
        general: response.data.data?.general,
        invoices: response.data.data?.invoices,
        maintenance: response.data.data?.maintenance,
        property: response.data.data?.property,
        lastes_requset: response.data.data?.latestAgreementRequest,
      });
      setopenList(false);
      setLoading(false);
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
    });
  };

  // AMNEITY CHECKOUT API

  const getCheckout = () => {
    const payload = {
      current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
    };
    NetworkCall(
      `${config.api_url}/amenities_booking_v2/get_currentdate_checkins`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      setCheckout(response?.data?.data)
    });
  };

  // AMNEITY CHECKOUT DETAILS API

  const getCheckoutDetails = (val) => {
    const payload = {
      booking_id: val
    };
    NetworkCall(
      `${config.api_url}/amenities_booking_v2/get_amenity_check_log`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      setCheckoutDetails(response?.data?.data?.[0])
      setCheckoutDrawer(true)
    });
  };

  // AMNEITY CHECKEDOUT API

  const hasBeenCheckedOut = (bookingID) => {
    const payload = {
      booking_id: bookingID
    }
    NetworkCall(
      `${config.api_url}/amenities_booking_v2/amenity_check_in_out`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    ).then((response) => {
      getCheckout()
    });
  }

  const checkoutDrawerClose = () => {
    setCheckoutDrawer(false)
    if (origin) {
      switch (origin) {
        case "nudge":
          history.replace(Routes?.agreement);
          break;
        default:
          break;
      }
    }
  }

  //pdf
  const downloadPdf = () => {
    setPdfData({ ...pdfData, load: true });

    const payload = {
      id: agreement?.id,
    }
    NetworkCall(
      `${config.api_url}/agreement/template`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPdfData({ load: false, data: response?.data ?? "" });

      })
      .catch((error) => {
        setPdfData({ load: false });

        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Some thing went wrong"),
        });
      });
  };

  React.useEffect(() => {
    if (pdf) {
      downloadPdf()
    }
    // eslint-disable-next-line
  }, [pdf])

  React.useEffect(() => {
    handleNudge();
    handleTopBarState({
      t: t,
      note: true,
      i18n: i18n,
    });
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher `,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    ).then((response) => {
      let localAgreement = JSON.parse(
        localStorage.getItem(LocalStorageKeys.agreement)
      );
      let tempAgreement = localAgreement?.id
        ? localAgreement
        : response?.data?.data[0];
      setAgreement(tempAgreement);
      localStorage.setItem(
        LocalStorageKeys.agreement,
        JSON.stringify(tempAgreement)
      );
      setAgreementList(response?.data?.data);
      if (tempAgreement?.status !== "expired") {
        getAgreementData(
          tempAgreement?.id,
          tempAgreement?.agreement_unit?.map((val) => val?.id)
        );
      } else {
        setLoading(false);
        setSelectedTab(2);
      }
    });
    getCheckout();
    // eslint-disable-next-line
  }, []);

  const handleNudge = () => {
    if (state?.meta_data && origin) {
      switch (origin) {
        case "nudge":
          switch (state?.type) {
            case "amenities_pending":
              getCheckoutDetails(state?.meta_data?.booking_id);
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }
    }
  }

  const updateagrement = (data) => {
    setopenList(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: props?.t("switching_agreement"),
    });
    localStorage.setItem(LocalStorageKeys.agreement, JSON.stringify(data));
    setAgreement(data);
    getAgreementData(
      data?.id,
      data?.agreement_unit?.map((val) => val?.id)
    );
  };
  const renderAgreementPdf = () => {
    return (
      <div>
        <Box
          display="flex"
          alignItems="center"
          className={classes.titleroot}
          style={{ padding: "12px" }}
        >
          <Box flexGrow={1}>
            <Typography className={classes.bottomTitle}>
              {agreement?.agreement_no}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => {
              setPdfData({ data: "", load: false })
              setPdf(false)
            }
            }>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box p={1}>
          {pdfData?.load ? <AgreementSimmer height={"250px"} />
            :
            <>
              <div className={classes.agreementimg}>
                {pdfData?.data?.length > 0 ? (
                  <DocumentViewer url={`data:application/pdf;base64,${pdfData?.data}`} />
                ) : (
                  "No Pdf"
                )}
              </div>
              <Button
                variant="contained"
                fullWidth={true}
                sx={{ padding: "12px", borderRadius: "10px", marginTop: "12px" }}
                onClick={() =>
                  saveAs(`data:application/pdf;base64,${pdfData?.data}`, `${agreement?.id}.pdf`)
                }
              >
                {props.t("download")}
              </Button>
            </>
          }
        </Box>
      </div>
    );
  };
  const reload = () => {
    getAgreementData(agreement?.id, agreement?.units);
  };
  const quickAccessData = [
    {
      label_1: t("Amenities"),
      icon_url: "/images/dashboardIcons/amenities_icon.svg",
      data: agreement?.id,
      link: Routes.amenities,
      // amenities
      // amenitiesDetailsOne,
      show: getAccess('is_amenity_booking')

    },
    {
      label_1: t("Utilities"),
      icon_url: "/images/dashboardIcons/utilities_icon.svg",
      data: agreement?.id,
      link: "/utilities",
      show: true,
    },
    {
      label_1: t("Requests"),
      icon_url: "/images/dashboardIcons/request_icon.svg",
      data: agreement?.id,
      link: "/requestscreen",
      filter: {
        units: agreement?.agreement_unit ?? [],
      },
      show: true,
    },
    {
      label_1: t("Units"),
      icon_url: "/images/dashboardIcons/units_icon.svg",
      data: agreement?.id,
      link: "/units",
      show: true,
    },
    {
      label_1: t("Letters"),
      icon_url: "/images/dashboardIcons/letter_icon.svg",
      link: Routes.caseManagement,
      show: true,
    },
    {
      label_1: t("Parking"),
      icon_url: "/images/dashboardIcons/parking_icon.svg",
      link: "/agreementparking",
      data: agreement?.id,
      show: true,
    },
    {
      label_1: t("Vehicles"),
      icon_url: "/images/dashboardIcons/vehicle_icon.svg",
      link: "/vehicle",
      show: true,
    },
    {
      label_1: t("Directory"),
      icon_url: "/images/dashboardIcons/directory_icon.svg",
      link: "/directory",
      show: getAccess('is_my_neighbours'),
    },
  ];
  const unitDetailsData = [
    {
      name: t("statement"),
      svg: <Statement />,
      link: "/statementofaccount",
      data: agreement?.id,
      show: true,
    },
    {
      name: t("entry_log"),
      svg: <EntryLog />,
      link: "/entries",
      data: agreement?.id,
      show: true,
    },
    {
      name: t("logins"),
      svg: <Logins />,
      link: "/aggreement_unit_access",
      data: agreement?.id,
      show: true,
    },
    {
      name: t("Occupants"),
      svg: "images/occupantsIcon.svg",
      link: Routes?.occupants,
      data: agreement,
      is_image: true,
      show: true,
    },
    {
      name: t("Inspection"),
      svg: "images/inspectionOrder.svg",
      data: agreement,
      link: Routes?.inspectionOrder,
      is_image: true,
      show: true,
    },
    // {
    //   name: t("Maintenance"),
    //   svg: "images/serviceOrders.svg",
    //   data: agreement,
    //   link: Routes?.serviceRecordOrders,
    //   is_image: true,
    //   show: true,
    // },
    {
      name: t("Inventory"),
      svg: "images/inventoryIcon.svg",
      data: agreement?.id,
      link: Routes?.inventoryAndServices,
      is_image: true,
      show: true,
    },
    {
      name: t("KYC"),
      svg: "images/kycInfo.svg",
      link: Routes.kycInformation,
      data: agreement?.id,
      is_image: true,
      show: true,
    },
    {
      name: t("Change Request"),
      svg: "images/changeRequest.svg",
      // link: Routes.kycInformation,
      onClick: () => setToggleDrawerBottom(true),
      data: agreement?.id,
      is_image: true,
      show: true,
    },
    {
      name: t("Pantry Order"),
      svg: "images/pantryOrder.svg",
      // link: Routes.kycInformation,
      onClick: () => setToggleDrawerBottom(true),
      data: agreement?.id,
      is_image: true,
      show: true,
    },
    {
      name: t("Bills"),
      svg: "/images/dashboardIcons/bills_icon.svg",
      // link: Routes.dashboard,
      is_image: true,
      show: true,
      onClick: () => setToggleDrawerBottom(true),
    },
  ];

  const quickLinkClick = (item) => {
    if (item?.onClick) {
      item?.onClick()
    }
    if (item?.noavailable) {
      setNoService(true);
    } else if (item?.type === "method") {
      item?.link();
    }
    else if ([Routes?.amenities, Routes?.requestScreen, Routes?.units].includes(item?.link)) {
      history.push(item?.link + "?Id=" + item?.data);
    } else {
      history.push({
        pathname: item?.link,
        state: {
          dashboard: true,
          main: {
            agreement: item?.data,
          },
        },
      });
    }
  }


  return (
    <>
      {" "}
      {loading ? (
        <AgreementSimmer />
      ) : (
        <div className={classes.root}>
          {/* <TopNavBars
            t={props?.t}
            i18n={props?.i18n}
            note={true}
            count="4"
            handleClicknote={() => history.push(Routes.notification)}
            document={() => history.push(Routes?.fileManager)}
          /> */}
          {agreement?.is_primary && (
            <Grid container alignItems="center" className={classes.tabs}>
              <Grid item xs={11}>
                <TabsComponent
                  tab1Label={props.t("home")}
                  tab2Label={props.t("invoice")}
                  tab3Label={props.t("payment")}
                  selectedTab={selectedTab}
                  onTab1Clicked={() => setSelectedTab(0)}
                  onTab2Clicked={() => setSelectedTab(1)}
                  onTab3Clicked={() => setSelectedTab(2)}
                  agreementStatus={agreement?.status}
                />
              </Grid>
              <Grid item xs={1}>
                {agreement?.status !== "expired" ? (
                  <OptionMenu
                    onOpen={() => settermination(true)}
                    onOpenagree={() => setPdf(true)}
                    downlodeName={props.t("download")}
                    terminationName={props.t("agreement_termination")}
                    termination={
                      (agreementData?.agreementRequest[0]?.request_purpose === "Termination" &&
                        agreementData?.agreementRequest[0]?.status === "Pending")
                        ? false
                        : true
                    }
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.agreement}
                onClick={() => setopenList(true)}
              >
                <Box sx={{ marginInlineEnd: "8px" }} mt={1}>
                  <ListIcon />
                </Box>
                <Box flexGrow={1}>
                  <Typography className={classes.id} >
                    {agreementList?.map((val, index) => {
                      return (
                        <span key={index}>
                          {val?.id === agreement?.id
                            && val?.agreement_no}
                        </span>
                      );
                    })}
                  </Typography>
                </Box>
                <Box >
                  {agreementList?.map((val, index) => {
                    return (
                      <Box key={index}>
                        {val?.id === agreement?.id ? (
                          <>
                            {val?.expiry_on && (
                              <Typography className={classes.exporie} noWrap>
                                &nbsp;&nbsp;{props.t("expires_on")}&nbsp;
                                &#x202a;{moment(val?.expiry_on)
                                  .tz(moment.tz.guess())
                                  .format("DD MMM YY")}&#x202c;
                                &nbsp;&nbsp;
                              </Typography>
                            )}
                          </>
                        ) : (
                          <>
                            {agreement?.lease_end_date && (
                              <Typography className={classes.expired}>
                                &nbsp;&nbsp;{props.t("expired")}&nbsp;
                                {moment(agreement?.lease_end_date)
                                  .tz(moment.tz.guess())
                                  .format("DD MMM YY")}
                                &nbsp;&nbsp;
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>
                    );
                  })}
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => setopenList(true)}
                    style={{
                      color: "black",
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box
            style={{
              height: size?.height - (agreement?.is_primary ? 250 : 140),
            }}
          >
            {selectedTab === 0 && (
              <Box
                className={classes.agreementRoot}
                sx={{ paddingBottom: "40px" }}
              >
                <Box
                  padding={"4px 16px 16px 16px"}
                  sx={{ backgroundColor: "#f6f6f6" }}
                >
                  {/* Properties ( */}
                  {agreementData?.property?.length > 0 && (
                    <Typography className={classes.title}>
                      {props.t("banner_label")} (
                      {agreementData?.property?.length < 10 ? (
                        <>0{agreementData?.property?.length}</>
                      ) : (
                        <>{agreementData?.property?.length}</>
                      )}
                      )
                    </Typography>
                  )}
                  <PropertyCarousel data={agreementData?.property} />
                  {/* AMENITY CHECK-IN CHECK-OUT */}
                  <AmneityCheckoutCard t={t} data={checkout} onClick={checkOut} />
                  {agreementData?.agreementRequest?.[0]?.status !== "Approved" &&
                    <RequsetCard
                      agreementData={agreementData?.agreementRequest?.[0]}
                      agreementDatas={agreementData?.agreement}
                      status={agreementData?.lastes_requset}
                      openRenwel={() => setrenewl(true)}
                      language={auth?.auth?.auth?.language}
                      reload={reload}
                      nudge_state={state}
                      nudge_origin={origin}
                    />}
                  {/* <Box height="10px" /> */}
                  <Typography className={classes.title}>
                    {props.t("Quick Links")}
                  </Typography>
                  <Box sx={{ height: "12px" }} />
                  {/* <IconButtonCard data={quickAccessData} t={props.t} /> */}
                  <Box className={classes.quickLinkBox}>
                    <Grid container spacing={2} py={"12px"}>
                      {
                        quickAccessData?.map((data) => {
                          return (
                            data?.show &&
                            <Grid item xs={3}>
                              <Stack alignItems={"center"} rowGap={1} onClick={() => quickLinkClick(data)}>
                                <img src={data?.icon_url} alt="" className={classes.quickLinkImg} />
                                <Typography className={classes.quickLinkLabel}>{data?.label_1}</Typography>
                              </Stack>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Box>
                  <Box height="20px" />
                  {agreementData?.invoices?.length > 0 ||
                    agreementData?.units?.length > 0 ||
                    agreementData?.general?.length > 0 ||
                    agreementData?.maintenance?.length > 0 ||
                    agreementData?.invoices?.length > 0
                    ? (<><Box className={classes.invoiceContainer}>
                      <Box className={classes.invoiceTitleBlock}>
                        <Typography className={classes.title}>
                          {props.t("Action")}
                        </Typography>
                      </Box>
                      <div className={classes.invoice}>
                        <CardList
                          main={{
                            invoices: agreementData?.invoices,
                            units: agreementData?.units,
                            general: agreementData?.general,
                            maintenance: agreementData?.maintenance,
                            invoicetotal: agreementData?.invoices,
                          }}
                          primary={true}
                          t={t}
                        />
                      </div>
                    </Box></>) : (
                      <></>
                    )}
                  <Box height="20px" />
                  <Box className={classes.unitDetails}>
                    <Typography className={classes.title2}>
                      {props.t("Features and Services")}
                    </Typography>
                    <Box sx={{ marginTop: "10px", textAlign: "-webkit-center" }}>
                      <UnitDetails data={unitDetailsData} t={props.t} icon_bg={"#D9e6FE80"} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {selectedTab === 1 && (
              <Invoicescreen
                titlebar={true}
                id={agreement?.id}
                agreemntlist={agreementList}
                agreement={true}
                agreement_no={agreement?.agreement_no}
                reload={reload}
                main={agreementData}
              />
            )}
            {selectedTab === 2 && <PaymentHistory id={agreement?.id} />}
          </Box>
          {/* AgreementList Drawer */}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={openList}
              onClose={() => setopenList(false)}
            >
              <AgreementDrawer
                agreementList={agreementList}
                agreement={agreement}
                updateagrement={updateagrement}
                onclose={() => setopenList(false)}
                expiresOn={props.t("expires_on")}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={openList}
              onClose={() => setopenList(false)}
              className={classes.dialog}
            >
              <AgreementDrawer
                agreementList={agreementList}
                agreement={agreement}
                updateagrement={updateagrement}
                onclose={() => setopenList(false)}
                expiresOn={props.t("expires_on")}
              />
            </Dialog>
          </Hidden>
          {/* Agreement pdf drawer */}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={pdf}
              onClose={() => setPdf(false)}
            >
              {renderAgreementPdf()}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={pdf}
              onClose={() => setPdf(false)}
              className={classes.dialog}
            >
              {renderAgreementPdf()}
            </Dialog>
          </Hidden>
          {/* Termination Form Drawer*/}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={termination}
              onClose={() => settermination(false)}
            >
              <TerminationForm
                agreement_id={agreement?.id}
                onClose={() => settermination(false)}
                agreementData={agreementData?.agreement}
                reload={reload}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={termination}
              onClose={() => settermination(false)}
              className={classes.dialog}
            >
              <TerminationForm
                agreement_id={agreement?.id}
                onClose={() => settermination(false)}
                agreementData={agreementData?.agreement}
                reload={reload}
              />
            </Dialog>
          </Hidden>
          {/* Renewl Form Drawer*/}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={renewl}
              onClose={() => setrenewl(false)}
            >
              <RenewalForm
                settermination={() => setrenewl(false)}
                agreement_id={agreement?.id}
                onClose={() => setrenewl(false)}
                reload={reload}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={renewl}
              onClose={() => setrenewl(false)}
              className={classes.dialog}
            >
              <RenewalForm
                settermination={() => settermination(false)}
                agreement_id={agreement?.id}
                onClose={() => setrenewl(false)}
                reload={reload}
              />
            </Dialog>
          </Hidden>
        </div>
      )}
      <DialogDrawer
        open={toggleDrawerBottom}
        onClose={() => setToggleDrawerBottom(false)}
        onOpen={() => setToggleDrawerBottom(true)}
        isnotTitle={true}
        component={
          <CommunityMemberModel
            t={t}
            onClose={() => setToggleDrawerBottom(false)}
          />
        }
      />
      <DialogDrawer
        maxWidth="xs"
        open={checkoutDrawer}
        header={t("Checked Out")}
        height={"620px"}
        component={
          <>
            <div className={classes.whiteBackground}>
              {isCheckedout &&
                <Box p={2}>
                  <Stack spacing={1} className={classes.successBox} direction={"row"} alignItems={"center"} justifyContent={"center"} py={1}>
                    <CheckedOut />
                    <Typography className={classes.success}>{t("Checked out successfully")}</Typography>
                  </Stack>
                </Box>
              }
              <Box p={2}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography className={classes.details}>{t("SLOT DETAILS")}</Typography>
                  <Typography className={classes.date}>{`On ${moment(new Date(checkoutDetails?.booked_date)).format("DD MMM YY")}`}</Typography>
                </Stack>
              </Box>
              <Box px={4} pb={1}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={3}>
                    <Typography className={classes.time}>
                      {moment(new Date(`2023-12-06 ${checkoutDetails?.booked_slots?.[0]?.[0]}`)).format("hh:mm A")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign={"center"}>
                    <Stack justifyContent={"center"} direction={"row"} alignItems={"center"}>
                      <Box display={"flex"} className={classes.durationLine}></Box>
                      <Typography className={classes.duration}>{`${checkoutDetails?.total_hours}h 00m`}</Typography>
                      <Box display={"flex"} className={classes.durationLine}></Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography textAlign={"right"} className={classes.time}>
                      {getTimeWithAmPm(`${Number(checkoutDetails?.booked_slots?.[0]?.[1]?.slice(0, 2)) + 1}:00:00`)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box pb={"12px"} textAlign={"center"}>
                <Stack direction={"row"} textAlign={"center"} className={classes.propertyName}>
                  <BlueCity />
                  <Typography className={classes.propertyName}>{checkoutDetails?.property_name}</Typography>
                </Stack>
              </Box>
              <Box px={2}>
                <Box className={classes.bookingDetails}>
                  <Box pt={"16px"} px={"12px"}>
                    <Stack pb={"12px"} direction={"row"} spacing={"10px"}>
                      <Avatar variant="rounded" className={classes.avatar} src={checkoutDetails?.amenity_assets}>
                      </Avatar>
                      <Box flexGrow={1}>
                        <Stack direction={"column"} spacing={"6px"}>
                          <Typography className={classes.amenityName}>{checkoutDetails?.description}</Typography>
                          <Typography className={classes.specificInfo}>{checkoutDetails?.amenity_category ?? ""}</Typography>
                        </Stack>
                      </Box>
                      <Typography className={classes.amenityName}>${checkoutDetails?.paid_amount}</Typography>
                    </Stack>
                  </Box>
                  <Divider color="#E4E8EE" />
                  <Box py={"16px"} px={"12px"}>
                    {/* <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.specificInfo}>{t("Court ID")}</Typography>
                        <Typography className={classes.info}>{checkoutDetails?.court_id}</Typography>
                      </Stack> */}
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography className={classes.specificInfo}>{t("Booked On")}</Typography>
                      <Typography className={classes.info}>
                        {`${moment(new Date(checkoutDetails?.booked_date)).format("DD MMM YY, dddd")}`}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} pb={1} pt={"12px"} justifyContent={"space-between"}>
                      <Typography className={classes.specificInfo}>{t("Payment Status")}</Typography>
                      <Typography className={classes.paid}>{checkoutDetails?.payment_status}</Typography>
                    </Stack>
                    <Box className={classes.dashedLine}></Box>
                    <Stack direction={"row"} alignItems={"center"} pb={1} pt={"12px"} justifyContent={"space-between"}>
                      <Typography className={classes.specificInfo}>{t("Booking Unit")}</Typography>
                      <Typography className={classes.info}>{checkoutDetails?.agreement_units?.unit?.name}</Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
              <Box px={2} pt={"12px"}>
                <Typography pb={2} className={classes.heading}>{t("BOOKED BY")}</Typography>
                <Stack pb={"15px"} direction={"row"} spacing={"12px"}>
                  <Avatar className={classes.avatarImage} src={checkoutDetails?.booked_by_image}></Avatar>
                  <Stack direction={"column"} spacing={"4px"}>
                    <Typography className={classes.amenityName}>{checkoutDetails?.booked_by}</Typography>
                    <Typography className={classes.specificInfo}>{`${checkoutDetails?.booked_by_mobile_country_code} 
                      ${checkoutDetails?.booked_by_mobile}`}</Typography>
                  </Stack>
                </Stack>
                <Divider color="#E4E8EE" />
              </Box>
              <Box px={2} pb={"18px"} pt={"12px"}>
                <Grid container spacing={"6px"}>
                  <Grid item xs={6}>
                    <Typography className={classes.heading}>{t("CHECKED IN")}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.heading}>{t("CHECKED OUT")}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.heading2}>
                      {`By ${checkoutDetails?.check_logs?.[0]?.checkin_person?.first_name}, 
                      ${moment(new Date(checkoutDetails?.check_logs?.[0]?.checkin_date)).format("DD MMM")}, 
                      ${checkoutDetails?.check_logs?.[0]?.checkin_time}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.heading2}>
                      {isCheckedout ? `${t("By")} ${checkoutDetails?.check_logs?.[0]?.checkout_person?.first_name}, 
                      ${moment(new Date(checkoutDetails?.check_logs?.[0]?.checkout_date)).format("DD MMM")}, 
                      ${checkoutDetails?.check_logs?.[0]?.checkout_time}` : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {!isCheckedout &&
                <Box px={2} pb={2}>
                  <SlideButton
                    mainText={t("Swipe To Check Out")}
                    overlayText={t("Checked Out")}
                    classList={"my-class2"}
                    caretClassList={"my-caret-class2"}
                    overlayClassList={"my-overlay-class2"}
                    onSlideDone={() => {
                      hasBeenCheckedOut(checkoutDetails?.booking_id)
                      setIsCheckedout(true)
                      getCheckoutDetails()
                    }
                    }
                    disabled={props?.disabled}
                    textStyle={{ fontSize: "0.75rem" }}
                  />
                </Box>
              }
            </div>
          </>
        }
        onClose={checkoutDrawerClose}
      />
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={noService}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              mobile
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              main={t("This feature is either not enabled or not opted by your organization.")}
              sub={t("Please contact your administrator for details.")}
              buttonName={t("Close")}
              fullWidth
              onClose={() => setNoService(false)}
            />
          </>
        }
        onClose={() => setNoService(false)}
      />
    </>
  );
};
export default withNamespaces("agreement")(AgreementNew);
