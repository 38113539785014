import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useCorrespondanceStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "0px",
  },
  caseTxt: {
    color: "#091B29",
    fontSize: "1rem",
    fontWeight: "600",
  },
  caseCard: {
    cursor: "pointer",
    padding: "12px", 
    background: "#FFFFFF",
    boxShadow: "0px 3px 30px #4250682E",
    borderRadius: "12px",
    border:"1px solid #E4E8EE",
    "& a": {
      textDecoration: "none",
    },
  },
  corresType: {
    background: "#F2F4F7",
    padding: "2px 4px",
    borderRadius: "4px",
    "& p": {
      color: "#98A0AC",
      fontSize: "0.75rem",
    },
  },
  status: {
    height: "100%",
    borderRadius: "4px",
    padding: "3px 6px",
    "& p": {
      color: "#fff",
      fontSize: "0.75rem",
    },
  },
  dateDiv: {
    paddingLeft: 0,
    margin: "0 10px",
    alignItems: "center",
    display: "flex",
    marginLeft: "0",
    "& p:first-child": {
      listStyleType: "none",
      margin: "0px",
      padding: "0",
      color: "#98A0AC",
      fontSize: "0.75rem",
    },
    "& p": {
      listStyleType: "disc",
      padding: "0",
      color: "#98A0AC",
      fontSize: "0.75rem",
    },
  },
  stackItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  txt: {
    fontSize: "0.875rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold
  },
  priorityTxt:{
    fontSize: "0.75rem",
    textTransform:"Capitalize",
    color: "#091B29",
    fontFamily:FontFamilySwitch().bold,
  },
  heightOverflow: {
    overflow: "overlay",
    backgroundColor: "#F2F2F2",
  },
  dot: {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    background: "#E4E8EE",
    margin: "0 8px",
  },
  replyPoint: {
    display: "flex",
    alignItems: "center",
    "& p": {
      color: "#4E5A6B",
      fontSize: "0.75rem",
      fontFamily: FontFamilySwitch().bold,
      fontStyle: "italic",
    },
  },
  filterBlock: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "8px 14px",
    boxShadow: "#4354721F 0px 3px 30px",
    border: "1px solid #4354721F",
    cursor: "pointer",
  },
  noData: {
    color: "#091B29",
    textAlign: "center",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    padding: "10px 15px",
    backgroundColor: "#fff",
    margin:"10px ",
    borderRadius:"8px",
  },
}));
