import { Box, Tab, Typography, Container } from '@mui/material'
import React from 'react'
import { SlotDetailCard, ViewAmenitiesHeader } from './components'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import TabList from "@mui/lab/TabList";
import { tabsClasses } from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import moment from 'moment';
import { format, isEqual } from "date-fns";
import { useLocation } from "react-router-dom";
import { viewAmenitiesStyle } from './style'
import { eachDayOfInterval, addDays } from 'date-fns'
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import { AlertContext, AuthContext } from "../../contexts";
import { useHistory } from 'react-router-dom'
import { Routes } from '../../router/routes'
import { withNamespaces } from 'react-i18next';

const ViewAmenities = ({ t = () => false }) => {
    const state = useLocation()
    const auth = React.useContext(AuthContext)
    const classes = viewAmenitiesStyle({ language: auth?.auth?.auth?.language })
    const history = useHistory()
    const Dates =  eachDayOfInterval({
        start: new Date(),
        end: new Date(addDays(new Date(), 9))
    })

    const alert = React.useContext(AlertContext);
    const [value, setValue] = React.useState(Dates?.[0]);
    const [viewData, setViewDate] = React.useState([])

    //tabs handle change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        getViewAmenities(state?.state?.selectedProperty?.property_id, state?.state?.data?.amenity_category_id, moment(new Date()).format("YYYY-MM-DD"))
        // eslint-disable-next-line
    }, [])

    const getViewAmenities = (propertyId, catId, date) => {
        const data = {
            property_id: propertyId,
            amenity_category_id: catId,
            selected_date: date,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenities_for_category`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                setViewDate(response?.data?.data?.amenityByDate)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };
    const navigate = (amenityData) => {
        history.push({
            pathname: Routes.availableSlot,
            state: {
                amenity_data: amenityData,
                propertyId: state?.state?.selectedProperty?.property_id,
                property_images: state?.state?.property_images,
                selectedDate: value,
                unitData: state?.state?.unitData
            }
        })
    }

    return (
        <Container maxWidth="sm" sx={{ background: "#F5F7FA", padding: "0 !important" }}>
            <ViewAmenitiesHeader data={state?.state?.data} />
            <div className={classes.card}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider", paddingBottom: "8px" }}>
                        <TabList
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            aria-label="scrollable auto tabs example"
                            className={classes.tabroot}
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    "&.Mui-disabled": { opacity: 0.3 },
                                },
                            }}
                        >
                            {Dates?.map((x) => {

                                return (
                                    <Tab
                                        className={isEqual(value, x) ? classes.tabItemSelect : classes.tabItem}
                                        onClick={() => getViewAmenities(state?.state?.selectedProperty?.property_id, state?.state?.data?.amenity_category_id, moment(x).format("YYYY-MM-DD"))}
                                        label={<>
                                            <div
                                            >
                                                <Typography className={isEqual(value, x) ? classes.textSelect1 : classes.textNotSelect1}>
                                                    {state?.main?.period === "Daily" ? moment(x).format("MMM") : moment(x).format("ddd")}
                                                </Typography>
                                                <Typography className={isEqual(value, x) ? classes.textSelect2 : classes.textNotSelect2}>
                                                    {state?.main?.period === "Daily" ? moment(x).format("MM") : moment(x).format("DD")}
                                                </Typography>
                                                <Typography className={classes.dot} />
                                            </div>
                                        </>
                                        }
                                        value={x}

                                    />
                                )
                            })}
                        </TabList>
                    </Box>
                </TabContext>
            </div>
            <Box className={classes.body}>
                {
                    viewData.length === 0 ? <Typography className={classes.noDataFound}>{t("NoDataFound")}</Typography> :

                        viewData?.map((e) => {
                            return (
                                <div>
                                    <SlotDetailCard
                                        data={e}
                                        navigate={navigate}
                                    />
                                </div>
                            )
                        })

                }

            </Box>
        </Container>
    )
}

export default withNamespaces("amenitiesBooking")(ViewAmenities)