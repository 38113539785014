import * as React from "react"

const Commercial = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={23.154} {...props}>
    <path
      d="M10.636 0a.818.818 0 0 0-.818.818v3.891L5.847 3.518h-.938a1.649 1.649 0 0 0-1.636 1.637v8.182h1.636V5.155h.7L13.091 7.4v14.118H8.182v-4.909a1.636 1.636 0 0 0-1.636-1.636h-4.91v-1.637a.818.818 0 1 0-1.636 0v9.818h18v-13.09a1.636 1.636 0 0 0-1.636-1.636h-1.637V.818A.818.818 0 0 0 13.909 0a.818.818 0 0 0-.818.818v4.873L11.455 5.2V.818A.818.818 0 0 0 10.636 0ZM6.545 8.427v1.637h1.636V8.427Zm3.273 0v1.637h1.636V8.427Zm4.909 1.636h1.636V11.7h-1.636ZM6.545 11.7v1.636h1.636V11.7Zm3.273 0v1.636h1.636V11.7Zm0 3.273v1.636h1.636v-1.636Zm-8.182 1.636h1.637v1.636H1.636Zm13.091 0h1.636v1.636h-1.636Zm-4.909 1.636v1.636h1.636v-1.636Zm-4.909 1.637h1.636v1.636H4.909Z"
      fill={props?.color ?? "#f6f6f6"}
    />
  </svg>
)

export default Commercial