import React from "react";
import { withBottombar } from "../../HOCs";
import InventoryAndServicesContext from "./inventoryAndServiceContext";
import InventoryAndServices from "./inventoryAndServices";

class InventoryAndServicesParent extends React.Component {
    render() {
        return <InventoryAndServicesContext>
            <InventoryAndServices {...this?.props} />
        </InventoryAndServicesContext>
    }
}

const props = {
    top_bar: "title_bar",
}

export default withBottombar(InventoryAndServicesParent, props);