import * as React from "react"
const MyCompanyIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 114116" transform="translate(14640.75 -637.5)">
      <rect
        width={40}
        height={40}
        fill="#f6f1fe"
        data-name="Rectangle 57450"
        rx={20}
        transform="translate(-14640.75 637.5)"
      />
      <path
        fill="#896db3"
        d="M-14627.119 649.5a.632.632 0 1 0 0 1.263h.632v8.437a1.476 1.476 0 0 0-1.263 1.458v4.211a.632.632 0 0 0 .631.631h4.632a.632.632 0 0 0 .632-.632v-2.737a.421.421 0 0 1 .421-.421h1.684a.421.421 0 0 1 .421.421v2.737a.632.632 0 0 0 .632.632h4.632a.632.632 0 0 0 .632-.632v-4.21a1.476 1.476 0 0 0-1.265-1.458v-8.437h.632a.632.632 0 1 0 0-1.263Zm3.158 2.947h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .421-.421Zm2.947 0h.842a.421.421 0 0 1 .422.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .421-.421Zm2.947 0h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .422-.421Zm-5.895 2.947h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .422-.42Zm2.947 0h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .423-.42Zm2.947 0h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .423-.42Zm-5.895 2.947h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .423-.42Zm2.947 0h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .424-.42Zm2.947 0h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .424-.42Zm-7.155 3.369h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .421-.421Zm8.421 0h.842a.421.421 0 0 1 .421.421v.842a.421.421 0 0 1-.421.421h-.842a.421.421 0 0 1-.421-.421v-.842a.421.421 0 0 1 .421-.421Z"
        data-name="icons8-organization (2)"
      />
    </g>
  </svg>
)
export default MyCompanyIcon
