import * as React from "react"

export const ArrowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 105149" transform="translate(-6118 2183)">
      <circle
        data-name="Ellipse 129300"
        cx={12}
        cy={12}
        r={12}
        transform="translate(6118 -2183)"
        fill="#fff"
      />
      <path
        data-name="icons8-expand-arrow (2)"
        d="M6127.5-2164.938a.443.443 0 0 1 .123-.3l4.912-5.262-4.912-5.265a.444.444 0 0 1-.123-.307.429.429 0 0 1 .249-.4.381.381 0 0 1 .437.1l5.197 5.572a.45.45 0 0 1 0 .605l-5.2 5.566a.381.381 0 0 1-.442.1.43.43 0 0 1-.241-.409Z"
        fill="#091b29"
        stroke="#091b29"
        strokeWidth={0.8}
      />
    </g>
  </svg>
)
