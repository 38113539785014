/* eslint-disable array-callback-return */
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  Box,
  Button, CircularProgress, Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import html2canvas from 'html2canvas';
import moment from "moment-timezone";
import React from "react";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { CANCEL_DOMESTIC } from "../../graphql/mutations";
import { GET_DOMESTIC_DETAILS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, NetWorkCallMethods, enumSelect, enumCatagory, FontFamilySwitch, isExpired } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  rootCard: {
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 3px 16px #F2F5FA",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary,
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  expired: {
    fontSize: "0.75rem",
    color: "#000000",
    fontFamily: FontFamilySwitch().bold,
    background: "#F6F6F6",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#FF4B4B",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  round: {
    color: "#F2F5FA",
    fontSize: "40px",
  },
  border: {
    border: "1px solid #E4E8EE",
    marginTop: "-4px",
    backgroundColor: "#E4E8EE",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  name: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },
  qr: {
    fontSize: "0.75rem",
    color: theme.palette.success.dark,
    fontFamily: FontFamilySwitch().bold,
    margin: "0px 12px"
  },
  expire: {
    fontSize: "0.75rem",
    color: theme.palette.success.dark,
    fontFamily: FontFamilySwitch().semiBold,
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #5078E1",
    backgroundColor: "white",
    borderRadius: "4px",
    textTransform: "capitalize"
  },
  submitbtn: {
    borderRadius: "4px",
    padding: "12px 8px",
  },
  propertyname: {
    color: "#78B1FE",
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem"

  },
  reject_description_div: {
    display: "flex",
    backgroundColor: "#ffecec",
    alignItems: "center",
    justifyContent: "center",
    padding: "6px",
    marginBottom: "24px"
  },
  reject_text_style: {
    fontSize: "0.75rem",
    color: "#ff4b4b",
    fontFamily: FontFamilySwitch().semiBold,
  }
}));
const InitialState = {
  remarks: "",
  error: {
    remarks: "",
  },
};
const DomesticDetails = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const searchURL = useLocation().search;
  const [canceldata, setCancelData] = React.useState({ ...InitialState });
  const classes = useStyles();
  const exportRef = React.useRef();
  const passId = new URLSearchParams(searchURL).get("passId");
  const app = config?.app_x_build === "PG-TE-08" ? "Tenant2" : "Tenant"

  const logo = JSON.parse(localStorage.getItem("accessType")).filter((val) => val.name === app).find((item) => item?.company_logo !== null)
  const clientLogo = localStorage.getItem("clientLogo")
  const [cancel, setCancel] = React.useState(false);
  const [detailsData, setDetailsData] = React.useState([]);
  const [downlodeload, setDownloeload] = React.useState(false)
  const alert = React.useContext(AlertContext);
  const [enumValue, setenumValue] = React.useState({
    status: {},
    type: {}
  });
  const getDetails = () => {
    const params = {
      query: GET_DOMESTIC_DETAILS,
      variables: {
        domesticHelperRequestID: passId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setDetailsData({
          ...response.data.data.domestic_helper_request[0],
          is_request_expired: isExpired(response.data.data.domestic_helper_request[0]?.to, new Date().toISOString())
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const updateState = (key, value) => {
    let error = canceldata.error;
    error[key] = "";
    setCancelData({ ...canceldata, [key]: value, error });
  };
  const isIamValideTostep = () => {
    let isValid = true;
    let error = canceldata.error;
    //Checking Pass Type
    if (canceldata.remarks.length === 0) {
      isValid = false;
      error.remarks = t("RemarksisRequired");
    }

    setCancel({ ...canceldata, error });
    return isValid;
  };
  const cancelPass = () => {
    if (isIamValideTostep()) {
      const data = {
        query: CANCEL_DOMESTIC,
        variables: {
          Id: passId,
          data: {
            request_status: "Cancelled",
            remarks: canceldata?.remarks,
          },
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          getDetails();
          setCancel(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      return false;
    }
  };


  const renderForm = () => {
    return (
      <div style={{ padding: "12px" }}>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("CancelDomesticHelp")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setCancel(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box height="12px" />
        <TextBox
          color
          label={t("Remarks")}
          placeholder={t("EnterRemarks")}
          value={canceldata.remarks}
          multiline
          onChange={(value) => updateState("remarks", value.target.value)}
          isError={canceldata?.error?.remarks?.length > 0}
          errorMessage={canceldata?.error?.remarks}
        />
        <Box height="12px" />
        <Button
          fullWidth
          className={classes.submitbtn}
          variant="contained"
          onClick={cancelPass}
        >
          {t("Submit")}
        </Button>
      </div>
    );
  };
  const downloadTicket = async (element) => {
    setDownloeload(true)
    const node = document.getElementById("ticket");
    // useCors to draw image from different origin
    html2canvas(node, { useCORS: true }).then((canvas) => {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.download = `${element}.png`;
      a.href = canvas.toDataURL();
      a.click();
      setDownloeload(false)
    });
  }
  const getEnum = async (data) => {
    const result = await enumSelect([enumCatagory?.security_status_enum_type, enumCatagory?.security_request_type])
    let requestSourceMap = result?.security_request_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap).map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})

    let requestSourceMapStatus = result?.security_status_enum_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus).map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})


    setenumValue({ ...enumValue, status: requestTypeStatus, type: requestType })
  }
  React.useState(() => {
    handleTopBarState({
      text: "View Registered Worker Request"
    })
    getDetails();
    getEnum()
  }, []);

  return (
    <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
      {/* <TitleBar
        text="View Domestic Help Request"

        goBack={() => history.goBack()}
      /> */}
      <Grid
        container
        className={classes.screenScrollEffect}
        style={{
          height:
            detailsData?.request_status === "Pending"
              ? size?.height - 134
              : size?.height - 60,
          overflow: "scroll",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ padding: "12px 6px 12px 12px" }}
          ref={exportRef}
          id="ticket"
        >
          <div className={classes.rootCard}>
            {detailsData?.request_status === "Approved" && (
              <>
                <br />
                <center>
                  <div style={{ marginBottom: "8px" }}>
                    <img src={logo?.company_logo ?? clientLogo} alt="" style={{ height: "30px" }} />
                  </div>
                  <QRCode
                    value={`${detailsData?.domestic_helper_master?.qr_code}`}
                    size={220}
                  />
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                    <Box display="flex" alignItems="center" bgcolor={"#E8F9E8"} style={{ borderRadius: "4px" }}>
                      <Box style={{ overflow: "hidden" }}>
                        <CircleIcon
                          style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                        />
                      </Box>
                      <Box flexGrow={1} padding={"8px 36px"}>
                        <Typography className={classes.qr}>
                          {t("Passcode")}: {detailsData?.domestic_helper_master?.qr_code}
                        </Typography>
                      </Box>
                      <Box style={{ overflow: "hidden" }}>
                        <CircleIcon
                          style={{ marginRight: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                        />
                      </Box>
                    </Box>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }} >
                    <div style={{ backgroundColor: "#F1F7FF", padding: "8px 16px", borderRadius: "4px" }}>
                      <img src="/images/icons8-city.svg" alt="city" />
                      <span className={classes.propertyname}>{detailsData?.property?.name}</span>
                    </div>
                  </div>
                </center>
                <Box display="flex" alignItems="center">
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginLeft: "-20px" }}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Box className={classes.border} />
                  </Box>
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginRight: "-20px" }}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" p={2}>
                  <Box className={classes.img}>
                    <img
                      src="/images/workergate.svg"
                      alt=""
                      style={{ objectFit: "contain", marginTop: "4px" }}
                    />
                  </Box>
                  <Box flexGrow={1} sx={{ marginInlineStart: "14px" }}>
                    <Typography variant="subtitle2" className={classes.title}>
                      {t("Registered Worker")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.sub}>
                      {t("Requestedon")} &nbsp;&nbsp;
                      &#x202a;{moment(detailsData?.requested_at)
                        .tz(moment.tz.guess())
                        .format("DD MMM YYYY")}&#x202c;
                    </Typography>
                    {detailsData?.is_request_expired ?
                      <Typography variant="subtitle2" className={classes.expired}>
                        {t("Expired")}
                      </Typography> :
                      <>
                        {detailsData?.request_status === "Approved" && (
                          <Typography variant="subtitle2" className={classes.approved}>
                            {t("Approved")}
                          </Typography>
                        )}
                        {detailsData?.request_status === "Pending" && (
                          <Typography
                            variant="subtitle2"
                            noWrap
                            className={classes.progress}
                          >
                            {t("InProgress")}
                          </Typography>
                        )}
                        {detailsData?.request_status === "Rejected" && (
                          <Typography variant="subtitle2" className={classes.rejected}>
                            {t("Rejected")}
                          </Typography>
                        )}
                        {detailsData?.request_status === "Cancelled" && (
                          <Typography variant="subtitle2" className={classes.delete}>
                            {t("Cancelled")}
                          </Typography>
                        )}
                      </>}
                  </Box>
                  {detailsData?.request_status === "Approved" && (
                    <Box>
                      {
                        downlodeload ?
                          <CircularProgress />
                          :
                          <IconButton
                            onClick={() => downloadTicket()}
                          >
                            <DownloadOutlinedIcon color="primary" />
                          </IconButton>
                      }
                    </Box>
                  )}
                </Box>
                <Box flexGrow={1} margin="0px 20px">
                  <Box className={classes.border} />
                </Box>
              </>
            )}
            {detailsData?.request_status !== "Approved" && (
              <>
                <Box display="flex" alignItems="center" p={3}>
                  <Box className={classes.img}>
                    <img
                      src="/images/workergate.svg"
                      alt=""
                      style={{ objectFit: "contain", marginTop: "4px" }}
                    />
                  </Box>
                  <Box flexGrow={1} sx={{ marginInlineStart: "14px" }}>
                    <Typography variant="subtitle2" className={classes.title}>
                      {t("Registered Worker")}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.sub}>
                      {t("Requestedon")} &nbsp;&nbsp;
                      &#x202a;{moment(detailsData?.requested_at)
                        .tz(moment.tz.guess())
                        .format("DD MMM YYYY")}&#x202c;
                    </Typography>
                    {detailsData?.is_request_expired ?
                      <Typography variant="subtitle2" className={classes.expired}>
                        {t("Expired")}
                      </Typography> :
                      <>
                        {detailsData?.request_status === "Approved" && (
                          <Typography variant="subtitle2" className={classes.approved}>
                            {t("Approved")}
                          </Typography>
                        )}
                        {detailsData?.request_status === "Pending" && (
                          <Typography
                            variant="subtitle2"
                            noWrap
                            className={classes.progress}
                          >
                            {t("InProgress")}
                          </Typography>
                        )}
                        {detailsData?.request_status === "Rejected" && (
                          <Typography variant="subtitle2" className={classes.rejected}>
                            {t("Rejected")}
                          </Typography>
                        )}
                        {detailsData?.request_status === "Cancelled" && (
                          <Typography variant="subtitle2" className={classes.delete}>
                            {t("Cancelled")}
                          </Typography>
                        )}
                      </>}



                  </Box>
                  {detailsData?.request_status === "Approved" && (
                    <Box>
                      <IconButton onClick={() => downloadTicket("test")}>
                        <DownloadOutlinedIcon color="primary" />
                      </IconButton>
                    </Box>
                  )}


                </Box>
                {detailsData?.remarks && <div className={classes.reject_description_div}>
                  <Typography className={classes.reject_text_style}>
                    {detailsData?.remarks ?? ""}
                  </Typography>
                </div>}
                <Box display="flex" alignItems="center" marginTop="-20px">
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginLeft: "-20px" }}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Box className={classes.border} />
                  </Box>
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginRight: "-20px" }}
                    />
                  </Box>
                </Box>
              </>)}
            <Box p={3} marginTop="-24px">
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("REQUEST FROM")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                  &#x202a;{moment(detailsData?.from)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY")} &#x202c;, 12:00 AM
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("REFERENCENO")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {detailsData?.reference_id}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("WORKER NAME")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {detailsData?.domestic_helper_master?.name}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("UNIT")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {detailsData?.unit?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("REQUEST TO")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                  &#x202a;{moment(detailsData?.to)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY")} &#x202c;, 11:59 PM
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("CATEGORY")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {
                      detailsData?.domestic_helper_master?.profession_master
                        ?.name
                    }
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("APPROVEDBY")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {detailsData?.approved_by?.first_name}&nbsp;{" "}
                    {detailsData?.approved_by?.last_name}
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </Box>
          </div>
        </Grid>
      </Grid>
      {detailsData?.is_request_expired ?
        <></> :
        (detailsData?.request_status === "Pending" && (
          <div
            xs={12}
            style={{
              padding: "12px",
              backgroundColor: "white",
              boxShadow: "0px -1px 6px #00000021",
            }}
          >
            <Button
              fullWidth
              className={classes.cancelbtn}
              onClick={() => setCancel(true)}
            >
              {t("CancelService")}
            </Button>
          </div>
        ))}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("domesticHelp")(DomesticDetails), props)