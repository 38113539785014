import { Container, Drawer, Hidden, Dialog } from "@mui/material";
import { React, useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useAddOnStyles } from "./style";
import { addOnData } from "./addOnData"
import { UnitCard } from "./unitCard";
import { ServiceCard } from "./serviceCard";
import { UnitDrawer } from "./unitDrawer";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withBottombar } from "../../HOCs";

const AddOn = ({ t = () => false, handleTopBarState }) => {
    const size = useWindowDimensions()
    const classes = useAddOnStyles()
    const [state, setState] = useState([addOnData[0]])
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleDrawerChange = () => {
        setOpen(!open)
    }

    const handleUnitChange = (e, i) => {
        setState([e])
        setOpen(false)
    }

    useEffect(() => {
        handleTopBarState({
          text: "Add On Items & Services"
        })
        // eslint-disable-next-line
      }, []);

    return (
        <>
            <Container maxWidth="sm" className={classes.root}>
                {/* <TitleBar text={t("Add On Items & Services")} goBack={GoBack} /> */}
                <div className={classes.heightOverflow} style={{ height: size.height - 55 }}>
                    <UnitCard t={t} state={state} addOnData={addOnData} handleDrawerChange={handleDrawerChange} />
                    <ServiceCard t={t} state={state} />
                </div>
            </Container >
            <Hidden smUp>
                <Drawer
                    anchor={"bottom"}
                    open={open}
                    onClose={() => setOpen(true)}
                    className={classes.drawer}
                >
                    <UnitDrawer t={t} handleUnitChange={handleUnitChange} handleClose={handleClose} />
                </Drawer >
            </Hidden>

            <Hidden smDown >
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={open}
                    onClose={() => setOpen(true)}
                >
                    <UnitDrawer t={t} handleUnitChange={handleUnitChange} handleClose={handleClose} />
                </Dialog>
            </Hidden>
        </>

    )
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(withNamespaces("addOn")(AddOn), props)