import React from "react";

function Entertainment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <g data-name="Group 113447" transform="translate(-37 -236)">
        <rect
          width="42"
          height="42"
          fill="#edf7f8"
          data-name="Rectangle 57715"
          rx="4"
          transform="translate(37 236)"
        ></rect>
        <path
          fill="#6dafb3"
          d="M18.144 1.986a.692.692 0 00-.427.158l-4.166 3.333-2.794-2.4a.692.692 0 10-.9 1.051l1.809 1.551H8a3 3 0 00-3 3v8.3a3 3 0 003 3h11.533a3 3 0 003-3v-8.3a3 3 0 00-3-3h-4.024l3.073-2.458a.692.692 0 00-.438-1.239zm.928 8.311a.692.692 0 11-.692.692.692.692 0 01.692-.689zm0 3.23a.692.692 0 11-.692.692.692.692 0 01.692-.692z"
          transform="translate(44.234 246.014)"
        ></path>
      </g>
    </svg>
  );
}

export default Entertainment;