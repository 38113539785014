/* eslint-disable array-callback-return */
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  AddvisiterCard,
  MobileNumberInputComponent,
  TextBox,
} from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_PROOF } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import {
  AlertProps,
  allowed_file_size,
  FontFamilySwitch,
  generateImageUrl,
  img_size,
  NetWorkCallMethods,
  singleFileUpload,
} from "../../utils";
import { PassContext } from "./passContext";
import { withNamespaces } from "react-i18next";
import { AddCircleRounded } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "0px",
  },

  input: {
    marginTop: "12px",
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5078E1",
  },
  imgdiv: {
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
  },
  add: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5078E1",
    cursor: "pointer",
  },
  bottomBarTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },
  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  submitbtn: {
    borderRadius: "4px",
    padding: "12px 8px",
    textTransform: "capitalize",
  },
  type: {
    borderRadius: "4px",
    padding: "11px 14px",
    height: 46,
    border: "1px solid #E4E8EE",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#091B29",
    fontSize: "0.875rem",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  close: {
    position: "absolute",
    right: "0px",
    top: "0px",
    backgroundColor: "red",
    borderRadius: "50%",
    color: "white",
    fontSize: "1.25rem",
  },
  addContainer: {
    cursor: "pointer",
    borderRadius: "4px",
    border: "2px dashed #5078E1",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 30px #4250682E",
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  secondary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#F6F6F6",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#F6F6F6",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  unactive: {
    padding: "8px 16px",
    marginTop: "12px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#F2F4F7",
  },
  active: {
    padding: "8px 16px",
    marginTop: "12px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
  },
  title2: {
    fontSize: "1rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
  },
  subTitle: {
    fontSize: "0.875rem",
    color: "#404E61",
    fontFamily: FontFamilySwitch().bold,
  },
  borederBottom: {
    borderBottom: "1px solid #E4E8EE",
  },
  visitorcontainer: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #4250682E",
  },
  innerPadding: {
    padding: "12px",
  },
  innerPadding2: {
    padding: "6px 12px 12px 12px",
  },
  label: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    marginTop: "10px",
    fontFamily: FontFamilySwitch().bold,
  },
}));

const Step2 = ({ t = () => false }) => {
  const classes = useStyles();
  const {
    data,
    updateState,
    setData,
    addVisitor,
    validateVisitor
  } = React.useContext(PassContext);
  const [proofData, setProofData] = React.useState([]);
  const [proof, setProof] = React.useState({ isOpen: false, index: 0 });
  const [selectedImage, setSelectedImage] = React.useState(0);
  const [openViwer, setOpenviewer] = React.useState(false);
  const alert = React.useContext(AlertContext);

  const editDetails = (index) => {
    let temp_visitors = []

    for (let i = 0; i < data?.visitor_details?.length; i++) {
      temp_visitors = [
        ...temp_visitors,
        i === index ?
          { ...data?.visitor_details[i], previous_state: data?.visitor_details[i], view_type: "edit" } :
          { ...data?.visitor_details[i] }
      ]
    }
    setData({ ...data, visitor_details: temp_visitors });
  };
  const cancelVisitor = (index) => {
    if(typeof data?.visitor_details?.[index]?.previous_state === "object"){
    let temp_visitors = []

    for (let i = 0; i < data?.visitor_details?.length; i++) {
      temp_visitors = [
        ...temp_visitors,
        i === index ?
          { ...data?.visitor_details?.[i]?.previous_state, previous_state: "" } :
          { ...data?.visitor_details[i] }
      ]
    }
    setData({ ...data, visitor_details: temp_visitors });
  }else{
    const temp = data?.visitor_details?.filter((val,i)=>i!==index);
    setData({ ...data, visitor_details: temp });
  }
}
 
  const genderOption = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const deleteVisitor = (index) => {
    updateState("visitor_details", {
      _k: "is_deleted",
      _v: true,
      index: index,
    });
  }
  const uploadS3 = async (data, index) => {
    let uploaded_file = await singleFileUpload(
      data,
      { tenantId: `${config.tenantid}` },
      alert,
      allowed_file_size
    );
    if (uploaded_file?.[0]?.url) {
      updateState("visitor_details", {
        _k: "profileImg",
        _v: uploaded_file?.[0]?.url,
        index: index,
      });
      // setUrl(uploaded_file?.[0]?.url);
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    }
  };
  const getProofData = () => {
    const params = {
      query: GET_PROOF,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setProofData(response.data.data.Identification_master);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  React.useState(() => {
    getProofData();
  }, []);
 
  const submit = (index) => {
    if (validateVisitor(index)) {
      updateState("visitor_details", {
        _k: "view_type",
        _v: "view",
        index: index,
      });
    }
  };

  const renderFrom = () => {
    return (
      <div>
        {data?.visitor_details?.map((visitor, index) => (
          <>
            {visitor?.view_type === "edit" && (
              <>
                <Box className={classes.visitorcontainer}>
                  <div className={classes.innerPadding}>
                    <Typography className={classes.title2}>
                      Add Visitor
                    </Typography>
                  </div>
                  <div className={classes.borederBottom}></div>
                  <div className={classes.innerPadding2}>
                    <div className={classes.input}>
                      <center>
                        {visitor?.profileImg ? (
                          <div className={classes.imgdiv}>
                            <img
                              style={{
                                height: "100px",
                                width: "100px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                              alt=" "
                              src={generateImageUrl(visitor?.profileImg, img_size.small_square)}
                              className={classes.profileicons}
                            />

                            <CloseIcon
                              className={classes.close}
                              onClick={() => {
                                updateState("visitor_details", {
                                  _k: "profileImg",
                                  _v: "",
                                  index: index,
                                });
                              }}
                            />
                          </div>
                        ) : (
                          <IconButton className={classes.profileimg}>
                            <PersonOutlineIcon
                              className={classes.profileicon}
                            />
                            <label htmlFor="uplodebtn">
                              <Avatar
                                sx={{ width: 24, height: 24 }}
                                className={classes.profileimgs}
                                type="file"
                              >
                                <img
                                  src="/images/icons8-camera (1).svg"
                                  alt=" "
                                  className={classes.profileicons}
                                />
                              </Avatar>
                            </label>
                            <input
                              type="file"
                              name="img"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(value) => {
                                uploadS3(value.target.files[0], index);
                              }}
                              id="uplodebtn"
                            />
                          </IconButton>
                        )}
                      </center>
                    </div>
                    <Typography className={classes.subTitle}>
                      Visitor Details
                    </Typography>
                    <div className={classes.input}>
                      <TextBox
                        isRequired
                        color
                        label={t("VisitorName")}
                        placeholder="Enter Visitor Name"
                        value={visitor?.name}
                        onChange={(value) =>
                          updateState("visitor_details", {
                            _k: "name",
                            _v: value.target.value,
                            index: index,
                          })
                        }
                        isError={visitor?.error?.name?.length > 0}
                        errorMessage={visitor?.error?.name}
                      />
                    </div>
                    <div className={classes.input}>
                      <SelectBox
                        isRequired
                        label={t("ChooseGender")}
                        placeholder={t("ChooseGender")}
                        value={visitor?.gender}
                        onChange={(e) =>
                          updateState("visitor_details", {
                            _k: "gender",
                            _v: e,
                            index: index,
                          })
                        }
                        options={genderOption}
                        isError={visitor?.error?.gender?.length > 0}
                        errorMessage={visitor?.error?.gender}
                      />
                    </div>
                    <div className={classes.input}>
                      <MobileNumberInputComponent
                        isRequired
                        label={t("PhoneNumber")}
                        placeholder={t("PhoneNumber")}
                        value={visitor?.mobile}
                        handleChange={(value) =>
                          updateState("visitor_details", {
                            _k: "mobile",
                            _v: value,
                            index: index,
                          })
                        }
                        isError={visitor?.error?.mobile?.length > 0}
                        errorMessage={visitor?.error?.mobile}
                      />
                    </div>
                    <div className={classes.input}>
                      <TextBox
                        color
                        label={t("EnterMailID")}
                        placeholder={t("EnterMailID")}
                        value={visitor?.mail}
                        onChange={(value) =>
                          updateState("visitor_details", {
                            _k: "mail",
                            _v: value.target.value,
                            index: index,
                          })
                        }
                        isError={visitor?.error?.mail?.length > 0}
                        errorMessage={visitor?.error?.mail}
                      />
                    </div>

                    <div className={classes.input}>
                      <Typography className={classes.subTitle}>
                        Visitor Proof Type
                      </Typography>
                    </div>
                    <div className={classes.input}>
                      <Box
                        onClick={() =>
                          setProof({
                            isOpen: true,
                            index: index,
                            data: visitor?.idType ?? "",
                          })
                        }
                        className={classes.type}
                      >
                        {visitor?.idType?.label
                          ? visitor?.idType?.label
                          : t("SelectProofType")}
                      </Box>
                      {data?.error?.idType?.length > 0 && (
                        <Typography
                          style={{ marginTop: "6px" }}
                          color="error"
                          variant="caption"
                        >
                          {data?.error?.idType}
                        </Typography>
                      )}
                    </div>
                    <div className={classes.input}>
                      <TextBox
                        color
                        label={t("EnterIDNumber")}
                        placeholder={t("EnterIDNumber")}
                        value={visitor?.idproof}
                        onChange={(value) =>
                          updateState("visitor_details", {
                            _k: "idproof",
                            _v: value.target.value,
                            index: index,
                          })
                        }
                        isError={visitor?.error?.idproof?.length > 0}
                        errorMessage={visitor?.error?.idproof}
                      />
                    </div>
                    <Box height={"15px"} />
                  </div>
                  <div className={classes.borederBottom}></div>
                  <Box height={"5px"} />
                  <div className={classes.innerPadding}>
                    <Grid
                      container
                      className={classes.btnroot}
                      justifyContent="space-between"
                      alignItems={"center"}
                      spacing={0}
                    >
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          // disabled={step === 1 && true}
                          className={classes.secondary}
                          variant="contained"
                          onClick={() => cancelVisitor(index)}
                        >
                          {t("Cancel")}
                        </Button>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        sx={{
                          borderLeft: "1px solid #98A0AC",
                          padding: "14px 0",
                        }}
                      />
                      <Grid item xs={5}>
                        <Button
                          fullWidth
                          className={classes.primary}
                          variant="contained"
                          onClick={() => submit(index)}
                        >
                          {t("Save")}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
                <Box height={"16px"} />
              </>
            )}
          </>
        ))}
        {data?.visitor_details?.filter(({ view_type, is_deleted }) => view_type === "view" && is_deleted === false).length !== 0 &&
          <Box className={classes.visitorcontainer}>
            <div className={classes.innerPadding}>
              <Typography className={classes.title2}>Visitor Details</Typography>
            </div>
            {data?.visitor_details?.map((visitor, index) => (
              <>
                {(visitor?.view_type === "view") && (!visitor?.is_deleted) && (
                  <>
                    <React.Fragment key={index}>
                      <AddvisiterCard
                        data={visitor}
                        onDelete={deleteVisitor}
                        handleDetails={editDetails}
                        index={index}
                        setSelectedImage={setSelectedImage}
                        selectedImage={selectedImage}
                        setOpenviewer={setOpenviewer}
                        openViwer={openViwer}
                      />
                      {data?.visitor_details?.length - 1 !== index && (
                        <Divider sx={{ borderColor: "#E4E8EE" }} />
                      )}
                    </React.Fragment>
                  </>
                )}
              </>
            ))}
          </Box>
        }
        <Box height="16px" />
      </div>
    );
  };
  const getProof = () => {
    return (
      <>
        <div style={{ padding: "12px" }}>
          <Box display="flex" alignItems="center" className={classes.titleroot}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                className={classes.bottomBarTitle}
              >
                {t("IDProof")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                size="small"
                onClick={() => setProof({ isOpen: false, index: 0 })}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <div style={{ padding: "12px", overflow: "hidden" }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {proofData.map((val) => {
                  return (
                    <>
                      <FormControlLabel
                        value={data?.idType}
                        control={
                          <Radio
                            checked={
                              proof?.data?.value === val?.value ? true : false
                            }
                          />
                        }
                        label={val?.label}
                        onChange={() => {
                          updateState("visitor_details", {
                            _k: "idType",
                            _v: val ?? {},
                            index: proof?.index,
                          });
                          setProof({ isOpen: false, index: 0 });
                        }}
                      />
                      <Divider style={{ width: "100vh" }} />
                    </>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </>
    );
  };
  // const renderDetails = () => {
  //   return (
  //     <>
  //       <div style={{ padding: "12px" }}>
  //         <Grid container spacing={1}>
  //           <Grid item xs={12}>
  //             <Box
  //               display="flex"
  //               alignItems="center"
  //               className={classes.titleroot}
  //             >
  //               <Box flexGrow={1}>
  //                 <Typography
  //                   variant="subtitle2"
  //                   className={classes.bottomBarTitle}
  //                 >
  //                   {t("ViewVisitorDetail")}
  //                 </Typography>
  //               </Box>
  //               <Box>
  //                 <IconButton size="small" onClick={() => setDetails(false)}>
  //                   <CloseIcon />
  //                 </IconButton>
  //               </Box>
  //             </Box>
  //             <Box display="flex" alignItems="center" p={2}>
  //               <Box>
  //                 <img
  //                   src={
  //                     visiterDetails?.visitor_image
  //                       ? visiterDetails?.visitor_image
  //                       : "/images/icons8_person.svg"
  //                   }
  //                   alt=""
  //                   style={{
  //                     objectFit: "contain",
  //                     marginTop: "4px",
  //                     height: "47px",
  //                     width: "47px",
  //                     borderRadius: "50%",
  //                   }}
  //                 />
  //               </Box>
  //               <Box marginLeft="10px">
  //                 <Typography variant="subtitle2" className={classes.name}>
  //                   {visiterDetails?.name}
  //                 </Typography>
  //               </Box>
  //             </Box>
  //           </Grid>
  //           <Grid item xs={6}>
  //             {visiterDetails?.id_label && (
  //               <>
  //                 <Typography variant="subtitle2" className={classes.heading}>
  //                   {"IDPROOF"}
  //                 </Typography>
  //                 <Typography
  //                   variant="subtitle2"
  //                   className={classes.title}
  //                   style={{ color: "#091B29" }}
  //                 >
  //                   {visiterDetails?.id_label}
  //                 </Typography>
  //               </>
  //             )}
  //             <Typography variant="subtitle2" className={classes.heading}>
  //               {t("PHONENUMBER")}
  //             </Typography>
  //             <Typography
  //               variant="subtitle2"
  //               className={classes.title}
  //               style={{ color: "#091B29" }}
  //             >
  //               {visiterDetails?.mobile_country_code}
  //               &nbsp;{visiterDetails?.mobile_no}
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={6}>
  //             {visiterDetails?.identification_no && (
  //               <>
  //                 <Typography variant="subtitle2" className={classes.heading}>
  //                   {t("IDNUMBER")}
  //                 </Typography>
  //                 <Typography
  //                   variant="subtitle2"
  //                   className={classes.title}
  //                   style={{ color: "#091B29" }}
  //                 >
  //                   {visiterDetails?.identification_no}
  //                 </Typography>
  //               </>
  //             )}
  //             <Typography variant="subtitle2" className={classes.heading}>
  //               {t("MAILID")}
  //             </Typography>
  //             <Typography
  //               variant="subtitle2"
  //               className={classes.title}
  //               style={{ color: "#091B29" }}
  //             >
  //               {visiterDetails?.email_id}
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </div>
  //     </>
  //   );
  // };
  return (
    <div style={{ padding: "12px 10px 8px 12px" }}>
      {renderFrom()}{" "}
      {data?.visitor_details?.filter(({ view_type }) => view_type === "edit")
        .length <= 0 && (
          <>
            {/* visitList.length >= 1 && data.passType === "Individual" */}
            {(data?.request_details?.passType === "Individual" && data?.visitor_details?.length >= 1) && data?.visitor_details?.filter(({ is_deleted }) => is_deleted === true).length !== data?.visitor_details?.length ? null : (
              <Box className={classes.addContainer}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ padding: "12px 16px" }}
                  onClick={() => {
                    addVisitor();
                  }}
                >
                  <Box>
                    <Typography className={classes.title}>
                      {t("Add Visitors")}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box display={"flex"} justifyContent="center" alignItems={"center"}>
                    <AddCircleRounded sx={{ color: "#5078E1", fontSize: "40px" }} />
                  </Box>
                </Box>
              </Box>)}
          </>
        )}
      <Box height={"20px"} />
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={proof?.isOpen}
          onClose={() => setProof({ isOpen: false })}
        >
          {getProof()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={proof?.isOpen}
          onClose={() => setProof({ isOpen: false })}
        >
          {getProof()}
        </Drawer>
      </Hidden>
      {/* <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={detail}
          onClose={() => setDetails(false)}
        >
          {renderDetails()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer1}
          anchor="bottom"
          open={detail}
          onClose={() => setDetails(false)}
        >
          {renderDetails()}
        </Drawer>
      </Hidden> */}
    </div>
  );
};

export default withNamespaces("createPass")(Step2);
