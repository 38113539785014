import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Document } from "../../../assets";
import { DialogDrawer } from "../../../components";
import {
  TypeOfRenewal,
  CustomForm,
  AsPerAgreement,
  RequestForRenewal,
} from "../renewalComponents";
import { timeDiffCalc, dateDiffInDays, FontFamilySwitch } from "../../../utils";
import { useEffect } from "react";
import { Routes } from "../../../router/routes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  shedule: {
    backgroundColor: "transparent",
  },
  sheduleroot: {
    padding: "6px 6px",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    backgroundColor: "white",
    backgroundImage: `url("/images/renewbg.svg")`,
    cursor: "pointer",
    backgroundRepeatX: "no-repeat",
    fontFamily: FontFamilySwitch().semiBold,
    backgroundPositionX: "100%",
    border: "1px solid #E4E8EE",
    // marginBottom: "16px",
  },
  sheduleroot2: {
    backgroundColor: "white",
    padding: "6px 6px",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    backgroundImage: `url("/images/Mask Group violate 40.svg")`,
    // marginBottom: "16px",
    backgroundRepeatX: "no-repeat",
    fontFamily: FontFamilySwitch().semiBold,
    backgroundPositionX: "100%",
    border: "1px solid #E4E8EE",
  },
  arrow: {
    fontSize: "1.25rem",
    color: "black",
  },
  inprogress: {
    backgroundColor: theme.palette.success.main,
    padding: "0px 12px",
    fontSize: "0.75rem",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  renwelRequest: {
    color: theme?.typography?.color?.primary,
    fontSize: "0.9375rem",
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: "4px",
  },
  expiry: {
    color: theme?.typography?.color?.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  chip: {
    padding: "3px 6px",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
  },
  arrowIcon: {
    fontSize: "0.75rem",
  },
  card: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: "12px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
const RequsetCard = (props) => {
  const { nudge_state, nudge_origin } = props;
  const history = useHistory();
  const { t=()=>false } = props;
  const classes = useStyles();

  const [state, setState] = useState({
    dialog: false,
    step: 0,
    show: false
  });

  const d = new Date();
  let day = d.getDate();
  //let month = d.getMonth();
  // var year = d.getFullYear();
  // let leasemonth = props?.agreementDatas?.lease_end_date?.slice(6, 7);
  // let leaseyear = props?.agreementDatas?.lease_end_date?.slice(0, 4);
  let differenceDay = dateDiffInDays(new Date(), new Date(props?.agreementDatas?.lease_end_date))

  let difference_btn_date = timeDiffCalc(
    new Date(),
    new Date(props?.agreementDatas?.lease_end_date),
    day
  );

  useEffect(() => {
    if (nudge_state?.meta_data && nudge_origin) {
      switch (nudge_origin) {
        case "nudge":
          switch (nudge_state?.type) {
            case "agreement_renewal_in_progress":
              cardClick();
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [nudge_state, nudge_origin]);

  const updateState = (k, v) => {
    if (k === "dialog") {
      if (nudge_origin) {
        switch (nudge_origin) {
          case "nudge":
            history.replace(Routes?.agreement);
            break;

          default:
            break;
        }
      }
    }
    setState({ ...state, [k]: v });
  };
  const cardClick = () => {
    setState({ ...state, step: 0, dialog: true });
  };

  const handleClick = (value) => {
    setState({ ...state, step: value, dialog: true });
  };
  const component = (title, sub, chip, status, show, color) => {
    const language = localStorage.getItem("i18nextLng")
    return (
      <Box
        className={classes.card}
        onClick={() => (show ? cardClick("dialog", true) : false)}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Document />
          <Box width={"10px"} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box>
              <Typography className={classes.renwelRequest} noWrap>
                {title}
              </Typography>
              <Typography className={classes.expiry}>
                {sub === true
                  ? `${props.t("expire_in")} ${difference_btn_date} Days`
                  : sub}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
        display={"flex"}
        alignItems={"center"}>
          {chip && (
            <Box marginInlineEnd={1}>
              <Typography
                className={classes.chip}
                style={color}
              >
                {chip}
              </Typography>
            </Box>
          )}
          {language === "ar" ?
            <ArrowBackIosIcon className={classes.arrowIcon} /> :
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          }
        </Box>
      </Box>
    );
  };

  const reloadFunction = () => {
    props?.reload()
    updateState("show", true)
  }
  const drawerComponent = {
    0: (
      <TypeOfRenewal
        language={props?.language}
        t={props?.t}
        handleClick={handleClick}
      />
    ),
    2: (
      <CustomForm
        language={props?.language}
        t={props?.t}
        agreement_id={props?.agreementDatas?.id}
        agreement_data={props?.agreementDatas}
        reload={reloadFunction}
      />
    ),
    1: (
      <AsPerAgreement
        language={props?.language}
        t={props?.t}
        agreement_id={props?.agreementDatas?.id}
        reload={reloadFunction}
      />
    ),
    3: <RequestForRenewal language={props?.language} t={props?.t} agreement_id={props?.agreementDatas?.id} requestDetails={props?.agreementData} termination={props?.status === "Termination" ? true : false} />,
  };
  const drawerTitle = {
    0: props?.status === "Termination" ? t("Request for Termination") : t("Request for renewal"),
    2: t("Extension"),
    1: t("As Per Agreement"),
    3: t("Request for renewal"),
  };

  return (
    <div style={{ marginTop: "18px" }}>
      {props?.status === "Termination" && (
        <Box
        // display="flex"
        // alignItems="center"
        // className={classes.sheduleroot2}
        >
          {component(
            props.t("tentative_termination_on"),
            moment(props?.agreementData?.termination_date)
              .format("DD MMM YY"),
            props?.agreementData?.status === "Pending" ? props.t("inprogress") : difference_btn_date === 0
              ? props.t("expire_today")
              : props.t("Expiring Soon"),
            "",
            true,
            props?.agreementData?.status === "Pending" ? {
              color: "#FF9340",
              backgroundColor: "#FFF4EB",
            } : {
              backgroundColor: "#FFECEC",
              color: "#FF4B4B",
            },
          )}
        </Box>
      )}

      {(difference_btn_date < 30 && props?.status !== "Termination")
        ? differenceDay < 0
          ? component(
            `${props.t("agreement")} ${props.t("expired")}`,
            "",
            "Expired",
            "Rejected",
            false,
            {
              backgroundColor: "#FFECEC",
              color: "#FF4B4B",
            }
          )
          : component(
            props.t("renewal_request_in"),
            difference_btn_date === 0 ? props.t("expire_today") : true,
            props?.agreementData?.status === "Pending" ? props.t("inprogress") : difference_btn_date === 0
              ? props.t("expire_today")
              : props.t("Expiring Soon"),
            "",
            difference_btn_date < 0 ? false : true,
            props?.agreementData?.status === "Pending" ? {
              color: "#FF9340",
              backgroundColor: "#FFF4EB",
            } : {
              backgroundColor: "#FFECEC",
              color: "#FF4B4B",
            }
          )
        : ""}

      {/* RENEWAL REQUEST */}
      <DialogDrawer
        height={"auto"}
        open={state?.dialog}
        header={
          state?.show ? props?.status === "Termination" ? t("Tentative Termination") : t("Request for renewal") : drawerTitle[state?.step]
        }
        padding={"0px"}
        component={<>{drawerComponent[(props?.agreementData?.status === "Pending" || props?.status === "Termination") ? 3 : state?.step]}</>}
        onClose={() => updateState("dialog", false)}
      />
    </div>
  );
};
export default withNamespaces("agreement")(RequsetCard);
