import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { BarChart_Style } from './style';

export const BarCharts = ({
    data=[]
}) => {

    const classes = BarChart_Style();

    return (
        <ResponsiveContainer  >
            <BarChart className={classes?.name}  data={data?.list} margin={{ top: 0, right: 10, left: 0, bottom: 0 }}>
                {/* <defs>
                    <linearGradient
                        id="colorUv"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="100%"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset=".5" stopColor="#5078E1" />
                        <stop offset=".9" stopColor="#93A8DF" />
                    </linearGradient>
                </defs> */}

                <CartesianGrid strokeDasharray="0" horizontal={true} vertical={false} />
                <XAxis dataKey="month" />
                <YAxis allowDecimals={true} axisLine={false} tickLine={false} type="amount" />
                <Tooltip />
                <Bar className={classes?.root} dataKey="amount" fill="url(#colorUv)" radius={[25, 25, 0, 0]}  isAnimationActive={false}/>
            </BarChart>
        </ResponsiveContainer>
    );
};