import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },
  stepContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "100%",
  },
  steperLine: {
    height: "105%",
  },
  labelContainer: {
    marginLeft: "8px",
  },
  label: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
  },
  stepIcon: {
    width: "24px",
    height: "24px",
    objectFit: "cover",
    backgroundColor: "#78B1FE",
  },
  IconStepCount: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    color: theme.typography.color.contrastText,
  },
  stepIconHide: {
    width: "24px",
    height: "24px",
    objectFit: "cover",
    backgroundColor: "#98A0AC",
  },
  IconStepCountHide: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    color: "#FFFFFF",
  },
  stepContent: {
    marginLeft: "8px",
    flexGrow: 1,
    padding: "6px 0",
  },
  progress: {
    width: "20px !important",
    height: "20px !important",
    "& svg": {
      "& circle": {
        stroke: "#5078E1",
      },
    },
  },
  toolsIcon: {
    display: "flex",
    alignItems: "center",
    padding: "0 5px"
    , cursor: "pointer"
  },

  secondary: {
    padding: "11px 15px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#19274e",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#e6e7ec",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#e6e7ec",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  btnroot: {
    background: "#fff",
    padding: "14px 14px",
  },
  alertTitle: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().extraBold,
  },
  alertText: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
  }
}));
