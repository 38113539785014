import * as React from "react"
export const VideoTrack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    data-name="Group 112105"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#f2f4f7" data-name="Ellipse 39519" />
    <g data-name="Group 112104">
      <circle
        cx={12}
        cy={12}
        r={12}
        fill="#5078e1"
        data-name="Ellipse 39518"
        transform="translate(8 8)"
      />
      <path
        fill="#fff"
        d="M22.8 23.475a2.275 2.275 0 0 1-2.275 2.275h-5.25A2.275 2.275 0 0 1 13 23.475v-5.95a2.275 2.275 0 0 1 2.275-2.275h5.25a2.275 2.275 0 0 1 2.275 2.275Zm3.934-6.757a.525.525 0 0 0-.529.007L23.5 18.35v4.3l2.705 1.623a.525.525 0 0 0 .795-.45v-6.65a.525.525 0 0 0-.266-.455Z"
        data-name="icons8-video-call (3)"
      />
    </g>
  </svg>
)
