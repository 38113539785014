import React from "react"

export const RescheduleTextIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <g id="Group_117763" data-name="Group 117763" transform="translate(4888 -896)">
          <rect id="Rectangle_58554" data-name="Rectangle 58554" width="40" height="40" rx="4" transform="translate(-4888 896)" fill="#f1f7ff"/>
          <path id="icons8-user-clock" d="M10,3a4.651,4.651,0,0,0,0,9.3h.057a4.651,4.651,0,0,0,0-9.3H10Zm7.885,9.767A5.116,5.116,0,1,0,23,17.883,5.116,5.116,0,0,0,17.884,12.767Zm-13.152,1.4A1.8,1.8,0,0,0,3,16.013v.708a4.32,4.32,0,0,0,2.221,3.734A8.742,8.742,0,0,0,9.744,21.6a9.593,9.593,0,0,0,3.038-.484,6.019,6.019,0,0,1,.342-6.958Zm12.687.465a.465.465,0,0,1,.465.465v2.791h2.326a.465.465,0,0,1,0,.93H17.418a.465.465,0,0,1-.465-.465V15.093A.465.465,0,0,1,17.418,14.628Z" transform="translate(-4881 903)" fill="#78b1fe"/>
        </g>
      </svg>
    )
}