import React from "react";
import Box from "@mui/material/Box";
import { withNamespaces } from "react-i18next";
import { LoderSimmer } from "../../components";
import { useStyles } from "./styles";
import AddIcon from "@mui/icons-material/Add";
import { Container, Divider, Fab, Grid, Typography } from "@mui/material";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DialogDrawer } from "../../components";
import IdCardType from "./components/IdCardType";
import KycListCard from "./components/kycListCard";
import KycInformationDetails from "./components/kycInformationDetails";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../components/noDataFound/noDataFound";
import ImageAndPdfViewer from "../../components/assetViewer/imageAndPdfViewer";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../router/routes";

const KycInformationList = (props) => {
  const classes = useStyles(props);
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const { t = () => false, handleTopBarState } = props;
  const history = useHistory();
  const { state } = useLocation();
  const searchURL = useLocation().search;
  const origin = new URLSearchParams(searchURL).get("origin");
  const [drawer, setDrawer] = React.useState(false);
  const [drawerIdType, setDrawerIdType] = React.useState(false);
  const [kycList, setKycList] = React.useState([]);
  const [kycData, setKycData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [imageViewer, setImageViewer] = React.useState(false);
  const openAddOccupants = () => {
    setDrawer(true);
  };

  const addIdProof = () => {
    setDrawerIdType(true);
  };

  const getKycListResident = (offset, fetchMore) => {
    const payload = {
      limit: 20,
      offset: offset,
    };
    NetworkCall(
      `${config?.api_url}/kyc/list_resident_kyc`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (fetchMore) {
          setKycList((res) => [...res, ...response?.data?.data]);
        } else {
          setKycList(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setLoading(false);
      });
  };
  const fetchMoreData = () => {
    const tempOffset = offset + 20;
    setOffset(tempOffset);
    getKycListResident(tempOffset, true);
  };

  React.useEffect(() => {
    handleTopBarState({
      text: t("KYC Information")
    })
    getKycListResident(0);
    handleNudge();
    // eslint-disable-next-line
  }, []);

  const handleNudge = () => {
    if (state?.meta_data && origin) {
      switch (origin) {
        case "nudge":
          switch (state?.type) {
            case "kyc_expiring":
              setKycData(state?.meta_data);
              openAddOccupants();
              break;
            case "kyc_expired":
              setKycData(state?.meta_data);
              openAddOccupants();
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }
    }
  }

  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={t("KYC Information")} goBack={goBack} /> */}
      <div style={{ height: size?.height - 55, overflow: "auto" }}>
        <div className={classes.mainPadding}>
          <Typography className={classes.title}>
            {t("KYC")} ({kycList?.length})
          </Typography>
          <Box height={"13px"} />
          {loading ? (
            <Box p={1}>
              <LoderSimmer count={10} />
            </Box>
          ) : (
            <Box className={classes.listContainer}>
              {kycList?.length > 0 ? (
                <>
                  <InfiniteScroll
                    dataLength={kycList.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 123}
                  >
                    <Box height={"16px"} />
                    {kycList?.map((data, index) => (
                      <div key={index}>
                        <KycListCard
                          t={t}
                          data={data}
                          openDrawer={openAddOccupants}
                          setKycData={setKycData}
                        />
                        {kycList?.length - 0 !== index && (
                          <Divider sx={{ borderColor: "#E4E8EE" }} />
                        )}
                      </div>
                    ))}
                  </InfiniteScroll>
                  {/* <Box height={"16px"} /> */}
                </>
              ) : (
                <NoDataFound
                  height={size?.height - 160}
                  content={t("No Data Found!")}
                />
              )}
            </Box>
          )}
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Box sx={{ display: "flex", float: "right" }}>
            <Box>
              <Fab
                color="primary"
                className={classes.fab}
                aria-label="add"
                onClick={() => addIdProof()}
              >
                <AddIcon />
              </Fab>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DialogDrawer
        open={drawer}
        onClose={() => {
          if (origin) {
            switch (origin) {
              case "nudge":
                history.replace(Routes.kycInformation);
                break;
              default:
                break;
            }
          }
          setDrawer(false);
        }}
        onOpen={() => setDrawer(true)}
        isnotTitle={false}
        header={t("KYC Information")}
        component={
          <KycInformationDetails
            data={kycData}
            setImageViewer={setImageViewer}
          />
        }
      />
      <DialogDrawer
        open={drawerIdType}
        onClose={() => setDrawerIdType(false)}
        onOpen={() => setDrawerIdType(true)}
        isnotTitle={false}
        header={t("Add ID Proof")}
        component={<IdCardType />}
        height={size?.height - 200}
      />
      <DialogDrawer
        open={imageViewer}
        onClose={() => setImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            assets={kycData?.kyc_assets?.map((val) => {
              return {
                meta_data: {
                  title: val?.name,
                  file_type: val?.type,
                },
                file_url: val?.src,
              };
            })}
            onClose={() => setImageViewer(false)}
          />
        }
      />
    </Container>
  );
};

export default withNamespaces("kycInformation")(KycInformationList);
