import { Avatar, Box, Stack, Typography, Divider } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AuthContext } from '../../../contexts'
import { generateImageUrl, img_size } from '../../../utils'
import { SlotDetailCardStyle } from './style'
const SlotDetailCard = (
    {
        data = {},
        navigate = () => false,
        t = () => false
    }
) => {
    const auth = React.useContext(AuthContext)
    const classes = SlotDetailCardStyle({ language: auth?.auth?.auth?.language })
    return (
        <Stack ml={1} mr={1} mt={1}>
            <Box p={1}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                    onClick={() => navigate(data)}>
                    <Stack direction={"row"} spacing={1}>
                        <Avatar className={classes.avatar} src={generateImageUrl(data.assets[0], img_size.small_square)}></Avatar>
                        <Stack>
                            <Typography className={classes.name}>{data?.amenities_name}</Typography>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <div className={classes.slot}> {data?.free_slots} / {data?.total_slots}  {t("Slots")}</div>
                                <Box className={classes.dot}></Box>
                                {
                                    data?.free_slots !== 0 ?
                                        <Typography className={classes.available}> {t("Available")} </Typography> :
                                        <Typography className={classes.notavailable}> {t("NotAvailable")} </Typography>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    <Typography className={classes.name}> {data?.symbol} {data?.rate} </Typography>
                </Stack>
            </Box>
            <Box p={2} pt={1} pb={1}>
                <Divider />
            </Box>
        </Stack>
    )
}

export default withNamespaces("amenitiesBooking")(SlotDetailCard)