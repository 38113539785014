import { Stack, Typography, Box, Divider, Grid, Button } from "@mui/material";
import moment from "moment/moment";
import { withNamespaces } from "react-i18next";
import { Routes } from "../../../router/routes";
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
import { DialogDrawer } from "../../../components";
import ImageAndPdfViewer from "../../../components/assetViewer/imageAndPdfViewer";
import React from "react";
import AssetView from "../../../components/assetViewer/assetView";

const Drawer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [imageViewer, setImageViewer] = React.useState(false);
  const { t=()=>false } = props;

  const gotToEditPage = () => {
    history.push({
      pathname: props?.type === "Spendings" ? Routes?.createSpending : Routes?.createInvestment,
      state: { data: props?.data }
    })
  }

  const temp_assets = props?.data?.assets.map((i) => {
    return ({
      meta_data: {
        file_name: i?.file_meta?.name ?? "",
        file_size: i?.file_meta?.size ?? "",
        file_type: i?.file_meta?.type ?? "",
        file_ext: i?.file_meta?.ext ?? ""
      },
      file_url: i?.url
    })
  });


  return (
    <>
      {props?.data?.assets && (
        <AssetView
          isNormal
          title="Property View"
          asset={props?.data?.assets?.filter((x) => x?.asset_type !== 4).map((val) => val)}
          assetAll={temp_assets}
        />
      )}
      <Stack spacing={0.5} mb={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            {props?.type === "Spendings" ? (<>
              <Typography className={classes.totalAmount}>
                {t("Total Amount")}
              </Typography>
              <Typography className={classes.money}>{props?.data?.amount}</Typography></>) : (<>
                <Typography className={classes.totalAmount}>
                  {t("Current Value")}
                </Typography>
                <Typography className={classes.money}>{props?.data?.current_value}{" "}{props?.data?.percentage}</Typography></>)}
          </Box>
          <Box>
            <Typography className={classes.drawerActivity}>
              {props?.type === "Spendings" ? props?.data?.usage_type : props?.data?.sub_category_name}
            </Typography>
          </Box>
        </Stack>
        <Typography className={classes.descirption}>
          {props?.data?.description}
        </Typography>
      </Stack>
      <Divider className={classes.divider} />
      {/* CONTACT NAME */}
      <Stack mt={2} justifyContent="space-between" direction="row">
        <Typography className={classes.information}>{t("Contact Name")}</Typography>
        <Typography className={classes.assist}>{props?.data?.contact_name}</Typography>
      </Stack>
      {/* UNIT NUMBER */}
      <Stack mt={2} justifyContent="space-between" direction="row">
        {props?.type === "Spendings" ? (<>
          <Typography className={classes.information}>{t("Unit Number")}</Typography>
          <Typography className={classes.assist}>{props?.data?.unit_number}</Typography></>) : (<>
            <Typography className={classes.information}>{t("Date")}</Typography>
            <Typography className={classes.assist}>
              {moment(props?.data?.created_at)
                .tz(moment.tz.guess())
                .format("DD MMM,YY ")}</Typography></>)}
      </Stack>
      {/* CATEGORY */}
      <Stack mt={2} justifyContent="space-between" direction="row">
        {props?.type === "Spendings" ? (<>
          <Typography className={classes.information}>{t("Category")}</Typography>
          <Typography className={classes.assist}>{props?.data?.category_name}</Typography></>) : (<>
            <Typography className={classes.information}>{t("Invested Amount")}</Typography>
            <Typography className={classes.assist}>{props?.data?.amount}</Typography>
          </>)}
      </Stack>
      {/* DATE */}
      <Stack mt={2} justifyContent="space-between" direction="row">
        {props?.type === "Spendings" ? (<>
          <Typography className={classes.information}>{t("Date")}</Typography>
          <Typography className={classes.assist}>
            {moment(props?.data?.created_at)
              .tz(moment.tz.guess())
              .format("DD MMM,YY ")}</Typography></>) : (<>
                <Typography className={classes.information}>{t("Unit Number")}</Typography>
                <Typography className={classes.assist}>{props?.data?.unit_number}</Typography></>)}
      </Stack>
      {/* USE */}
      <Stack mt={2} mb={2} justifyContent="space-between" direction="row">
        {props?.type === "Spendings" ? (<>
          <Typography className={classes.information}>
            {t("Personal/Business")}
          </Typography>
          <Typography className={classes.assist}>
            {props?.data?.usage_type}
          </Typography></>) : (<>
            <Typography className={classes.information}>
              {t("Gain/Loss")}
            </Typography>
            <Typography className={classes.assist}>
              {props?.data?.category_name}
            </Typography>
          </>)}
      </Stack>
      <Divider className={classes.divider} />
      {props?.type === "Investments" &&
        <>
        <Box my={2}>
          <Typography className={classes.information}>
            {t("Notes")}
          </Typography>
          <Typography className={classes.information}>
            {props?.data?.notes}
          </Typography>
        </Box>
         <Divider className={classes.divider} />
         </>
      }
      <Grid container spacing={2} mt={1}>
        <Grid item lg={6} xs={6}>
          <Button
            fullWidth
            variant="outlined"
            className={classes.primaryButton}
          >
            {t("DELETE")}
          </Button>
        </Grid>
        <Grid item lg={6} xs={6}>
          <Button
            fullWidth
            variant="outlined"
            className={classes.secondaryButton}
            onClick={() => gotToEditPage()}
          >
            {t("Edit")}
          </Button>
        </Grid>
      </Grid>
      <DialogDrawer
        open={imageViewer}
        onClose={() => setImageViewer(false)}
        isnotTitle={true}
        padding={0}
        overflow={"overlay"}
        component={
          <ImageAndPdfViewer
            assets={props?.data?.img}
            onClose={() => setImageViewer(false)}
          />
        }
      />
    </>
  );
};
export default withNamespaces("statistics")(Drawer);
