import { Box, Stack, Typography, Tab } from '@mui/material'
import React from 'react'
import { DetailCardStyle } from './style'
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import moment from 'moment';
import { useLocation } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { AuthContext } from '../../../contexts';


const DetailCard = (
    {
        click = () => false,
        back = () => false,
        scroll = "",
        data = {},
        value = "",
        selecteDate = () => false,
        tabDate = {},
        t = () => false
    }
) => {
    const auth = React.useContext(AuthContext)
    const { state } = useLocation()
    const classes = DetailCardStyle({ language: auth?.auth?.auth?.language })
    console.log(value, "value")

    //tabs handle change
    const handleChange = (event, newValue) => {
        selecteDate(newValue)
    };

    const sameDate = (getDate) =>{
        if(moment(getDate).format("YYYY-MM-DD") === moment(value).format("YYYY-MM-DD")){
            return true
        }
        else return false
    }
    return (
        <Stack className={scroll ? classes.scorllBox : classes.box} p={1} spacing={2} onClick={click}>
            {data?.period !== "Daily" &&
                data?.period !== "Daily" &&
                <div className={classes.card}>
                    <TabContext value={value}>
                        <Box 
                        className={classes.tabList}
                        >
                            <TabList
                                onChange={handleChange}
                                variant="scrollable"
                                // scrollButtons
                                aria-label="scrollable auto tabs example"
                                className={classes.tabroot}
                            >
                                {tabDate?.map((x, index) => {
                                    return (
                                        <Tab
                                            className={sameDate(x) ? classes.tabItemSelect : classes.tabItem}
                                            label={<>
                                                <div
                                                >
                                                    <Typography className={sameDate(x) ? classes.textSelect1 : classes.textNotSelect1}>
                                                        {state?.main?.period === "Daily" ? moment(x).format("MMM") : moment(x).format("ddd")}
                                                    </Typography>
                                                    <Typography className={sameDate(x) ? classes.textSelect2 : classes.textNotSelect2}>
                                                        {state?.main?.period === "Daily" ? moment(x).format("MM") : moment(x).format("DD")}
                                                    </Typography>
                                                    <Typography className={classes.dot} />
                                                </div>
                                            </>
                                            }
                                            value={x}

                                        />
                                    )
                                })}
                            </TabList>
                        </Box>
                    </TabContext>
                </div>
            }
        </Stack>
    )
}

export default withNamespaces("amenitiesBooking")(DetailCard)