import React from "react";
import Utilities from "./utilities";
import { withBottombar } from "../../HOCs";

class UtilitiesParent extends React.Component {
  render() {
    return <Utilities {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}


export default withBottombar(UtilitiesParent, props);
