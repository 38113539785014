import React from "react";
import InvoiceScreen from "./invoiceScreen";

class InvoiceScreenParent extends React.Component {
  render(props) {
    return (
      <InvoiceScreen
        titlebar={this.props.titlebar}
        id={this.props.id}
        contactid={this.props.contactid}
        agreemntlist={this.props.agreemntlist}
        agreement={this.props.agreement}
        agreement_no={this.props.agreement_no}
        main={this.props.main}
      />
    );
  }
}
export default InvoiceScreenParent;
