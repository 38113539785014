import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  expiryCard: {
    padding: "16px", 
    backgroundColor: "#ffffff",
    borderRadius: "8px"
  },
  expiryDetails: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B"
  },
  update: {
    color: "#5078E1",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold
  }
 }))