import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #4250682E",
    backgroundColor: "#ffffff",
    "& .MuiCheckbox-root": {
      padding: "5px 4px 9px 12px !important",
    },
  },
  mainPadding: {
    padding: "12px",
  },
  input: {
    marginTop: "10px",
    marginBottom: "7px",
  },
  secondary: {
    padding: "11px 15px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#19274e",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#e6e7ec",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#e6e7ec",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  btnroot: {
    background: "#fff",
    padding: "14px 14px",
  },
  label: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },

  labelError: {
    marginTop:"5px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#d32f2f",
  },
  borderBottom: {
    borderBottom: "1px solid #E4E8EE",
  },

  close: {
    position: "absolute",
    right: "0px",
    top: "0px",
    backgroundColor: "red",
    borderRadius: "50%",
    color: "white",
    fontSize: "1.25rem",
    cursor: "pointer",
  },
  imgdiv: {
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
  },
  profileicon: {
    fontSize: "80px",
    padding: "7px",
    width: "100px",
    height: "95px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },
  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profilesection: {
    marginTop: "14px",
  },
  endAdornment: {
    display: "flex",
    borderLeft: "1px solid #E4E8EE",
    width: "55px",
    height: "42.13px",
    alignItems: "center",
    cursor: "pointer",
  },
  timeVariant: {
    padding: "6px 12px",
    borderBottom: "1px solid #E4E8EE",
    cursor: "pointer",
    fontSize: "1.3rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  time: {
    padding: "16px 2px 12px 9px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  groupInputBox: {
    display: "flex",
    alignItems: "center",
    padding: "6px 12px",
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline, .MuiInputBase-root, .MuiInputBase-input":
      {
        borderWidth: "0 !important",
        fontFamily: FontFamilySwitch().bold + "!important",
        fontSize: "0.875rem !important",
      },
  },
  addContainer: {
    border: "0.12em dashed #5078E1",
    backgroundColor: "#ECF0FB",
    borderRadius: "8px",
    padding: "11px",
    cursor:"pointer",
  },
  add: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5078E1",
  },
}));
