import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AuthContext } from '../../../contexts'
import { BillSummaryStyle } from './style'
import moment from 'moment'
import ElectricIcon from '../../../assets/electricIcon'

const BillSummary = (
    {
        data = {},
        bookingSlots = {},
        t = () => false,
        value = {},
        amountDetail = {},
        slots = [],
        pcCount = "",
        taxPercentage=""
    }) => {
    const auth = React.useContext(AuthContext)
    const classes = BillSummaryStyle({ language: auth?.auth?.auth?.language })
    return (
        <Stack spacing={1}>
            <Typography className={classes.title}>{t("Bill Summary")}</Typography>
            <Stack spacing={0.5}>
                <Stack>
                    {
                        slots?.map((slot, index, length) => {
                            return (
                                <Stack direction={"row"} justifyContent={"space-between"} mt={"10px"}>
                                    <Stack direction={"row"} spacing={1} >
                                        <Typography className={classes.secondary}>
                                            Slot {data?.period === "Daily" ?
                                                moment(slot?.value?.check_out_date?.date).format("Do MMM YY") :
                                                moment(slot?.value?.check_in_time, "HH:mm").format("hh:mm A")} - {data?.period === "Daily" ? moment(slot?.value?.check_in_date?.date).format("Do MMM YY") :
                                                    moment(slot?.value?.check_out_time, "HH:mm").format("hh:mm A")}
                                            {value?.response?.amenity?.[0]?.is_overbooking && ` X ${pcCount}`}
                                        </Typography>
                                        {
                                            slot?.value?.slot_detail?.is_peak &&
                                            <Box className={classes.peakBox}>
                                                <ElectricIcon />
                                            </Box>
                                        }
                                    </Stack>
                                    <Typography className={classes.secondary}>
                                        {data?.symbol ?? data?.currency_symbol} {
                                            data?.period === "Hourly" ?
                                                value?.response?.amenity?.[0]?.is_overbooking ? (Number(slot?.value?.slot_detail?.rate) * Number(pcCount)).toFixed(2) : Number(slot?.value?.slot_detail?.rate).toFixed(2) :
                                                value?.response?.amenity?.[0]?.is_overbooking ? (Number(value?.response?.amenity?.[0]?.rate) * Number(pcCount)).toFixed(2) : Number(value?.response?.amenity?.[0]?.rate).toFixed(2)}
                                    </Typography>
                                </Stack>
                            )
                        })
                    }
                </Stack>
                {/* <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className={classes.secondary}>{t("InvoiceAmount")}</Typography>
                    <Typography className={classes.secondary}>{data?.symbol} {amountDetail?.invoiceAmount}</Typography>
                </Stack> */}
                <Stack direction={"row"} justifyContent={"space-between"} mt={"10px"}>
                    <Typography className={classes.secondary}>{t("Taxes")} {taxPercentage ? `${taxPercentage}%` : ""}</Typography>
                    <Typography className={classes.secondary}>{data?.symbol ?? data?.currency_symbol} {amountDetail?.taxAmount}</Typography>
                </Stack>
            </Stack>
            <Box className={classes.dot_divider}></Box>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography className={classes.primary}>{t("TotalAmountToPay")}</Typography>
                <Typography className={classes.primary}>{data?.symbol ?? data?.currency_symbol} {amountDetail?.totalAmount} </Typography>
            </Stack>
            <Box className={classes.dot_divider}></Box>
        </Stack>
    )
}

export default withNamespaces("amenitiesBooking")(BillSummary)