import { Box, Stack, Typography } from "@mui/material"
import { useHistory } from "react-router-dom";
import { useStyles } from "../styles";

export const OtherFeatures = ({
    icon = "",
    title = "",
    onClick = () => false,
    router = "",
}) => {

    const history = useHistory();
    const classes = useStyles();
    const handleClick = () => {
        if (router) {
            history.push(router);
        }
        else{
            onClick()
        }
    };
    return (
        <Box onClick={handleClick} className={classes?.featureCard}>
            <Stack alignItems={"center"} direction={"row"}>
                <img src={icon} alt="Other Features" />
                <Typography className={classes?.title}>{title}</Typography>
            </Stack>
        </Box>
    )
}