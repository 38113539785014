import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { simpleAccordionStyle } from './style';

export const SimpleAccordion = ({
    headText="",
    content="",
    defaultExpanded=false,
    dividerColor,
    count = null,
    onClick = () => false
}) => {
    const classes = simpleAccordionStyle();
  return (
    <div>
      <Accordion className={classes?.root} defaultExpanded={defaultExpanded} onClick={onClick}>
        {/* Accordian Heading */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ color: dividerColor[headText] }} className={classes?.headText}>{headText} {count?.toString()?.length > 0 && `(${count})`}</Typography>
        </AccordionSummary>
        {/* Accordian Content */}
        <AccordionDetails>
          <Typography>
            {content}
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}