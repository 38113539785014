import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { FontFamilySwitch } from "../../utils/common";

const useStyles = makeStyles((theme) => ({
  propertyName: {
    color: "white",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1.125rem",
    marginLeft: "10px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  propertyDetail: {
    fontSize: "0.75rem",
    color: "#fff",
    textTransform: "capitalize",
 
    fontFamily: FontFamilySwitch().bold, bottom: "20px",
  },
  propertyDetailsroot: {
    backgroundImage: (props) => `url(${props.data.asset_url})`,
    backgroundPosition: "100% 100%",
    backgroundSize: "cover",
    height:"180px",
    [theme.breakpoints.up("sm")]: {
      width: "442px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "342px",
    },
    borderRadius: theme.palette.borderRadius2,
    position:"relative"
  },
  productDetails: {
    background: "linear-gradient(#091B2900, #000000C4)", 
    width: "100%",
    height:"100%",
    position:'absolute',
    borderRadius: theme.palette.borderRadius2,
  },
  propertyimage: {
    objectFit: "contain",
    borderRadius: "20px",
  },
  till: {
    position: "absolute",
    color: "#fff",
    bottom: "16px",
    left:"16px"
  },
}));

export const AgreementCard = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.propertyDetailsroot}>
      <div className={classes.productDetails}>
        <Box className={classes.till}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.propertyName}>
              {props.data.property_name}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop="18px"
          p={1}
        >
          <Box>
            <center>
              <img src="/images/units.svg" alt="units" />
              <Typography className={classes.propertyDetail}>
                {props.data.units}&nbsp;{props?.unitName}
              </Typography>
            </center>
          </Box>
          <Box>
            <center>
              <img src="/images/sqft.svg" alt="sqft" />
              <Typography className={classes.propertyDetail}>
                {props.data.total_area}&nbsp;{props?.data?.uom_name}
              </Typography>
            </center>
          </Box>
          <Box>
            <center>
              <img src="/images/rent.svg" alt={props.data.type} />
              <Typography className={classes.propertyDetail}>
                {props.data.lease_period}
              </Typography>
            </center>
          </Box>
        </Box>
        </Box>
      </div>
    </div>
  );
};
