import * as React from "react"
const EntryLog = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={24}
    height={24}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={0.5}
        x2={0.5}
        y1={1.047}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#11408a" />
        <stop offset={1} stopColor="#103f8f" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.5}
        x2={0.5}
        y1={0.009}
        y2={1.003}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#1d59b3" />
        <stop offset={1} stopColor="#195bbc" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.331}
        x2={0.669}
        y1={25.285}
        y2={26.214}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#32bdef" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={0.29}
        x2={0.972}
        y1={-15.76}
        y2={-18.875}
      />
      <linearGradient
        id="f"
        x1={0.5}
        x2={0.5}
        y2={1.054}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#33bef0" />
        <stop offset={1} stopColor="#0a85d9" />
      </linearGradient>
      <radialGradient
        id="e"
        cx={0.5}
        cy={0.5}
        r={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
    </defs>
    <g data-name="Group 108941" transform="translate(-3.914 -3.33)">
      <path
        fill="url(#b)"
        d="M29.729 26.66H15.567a.567.567 0 0 1-.567-.566V5.133A1.133 1.133 0 0 1 16.133 4H28.6a1.133 1.133 0 0 1 1.133 1.133Z"
        data-name="Path 101051"
        transform="translate(-3.901)"
      />
      <path
        fill="url(#c)"
        d="M0 0h12.463v21.527H0z"
        data-name="Rectangle 58336"
        transform="translate(12.232 5.133)"
      />
      <path
        fill="url(#a)"
        d="M11.266 10.532a2.266 2.266 0 1 1 2.266-2.266 2.273 2.273 0 0 1-2.266 2.266"
        data-name="Path 101052"
        transform="translate(-1.3 -.867)"
      />
      <path
        fill="url(#d)"
        d="M11.1 16H8.833A2.833 2.833 0 0 0 6 18.833v5.948a.85.85 0 0 0 .85.85h.283v5.038a1.174 1.174 0 0 0 .943 1.177A1.133 1.133 0 0 0 9.4 30.729v-5.1a.567.567 0 1 1 1.133 0v5.1a1.134 1.134 0 0 0 1.323 1.118 1.175 1.175 0 0 0 .943-1.178v-5.038h.283a.85.85 0 0 0 .85-.85v-5.948A2.833 2.833 0 0 0 11.1 16Z"
        data-name="Path 101053"
        transform="translate(0 -5.202)"
      />
      <circle
        cx={1.133}
        cy={1.133}
        r={1.133}
        fill="url(#e)"
        data-name="Ellipse 130917"
        transform="translate(21.012 15.897)"
      />
      <circle
        cx={0.85}
        cy={0.85}
        r={0.85}
        fill="url(#f)"
        data-name="Ellipse 130918"
        transform="translate(21.296 15.897)"
      />
    </g>
  </svg>
)
export default EntryLog
