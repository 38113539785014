/* eslint-disable array-callback-return */
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { DatePickerNew, TextBox } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { GET_GATES } from "../../graphql/queries";
import { FontFamilySwitch } from "../../utils";
import { PassContext } from "./passContext";
import { withNamespaces } from "react-i18next";
import moment from "moment";
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "##F6F6F6",
    padding: "0px",
  },

  input: {
    marginTop: "10px",
    marginBottom: "7px",
  },
  unactive: {
    padding: "8px 16px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#F2F4F7",
  },
  active: {
    padding: "8px 16px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "0.875rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
  },
  visiterJourney: {
    borderRadius: "4px",
    boxShadow: "0px 6px 30px #0eeeee11",
    backgroundColor: "#fff",
  },
  title: {
    fontSize: "1rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
  },
  innerPadding: {
    padding: "12px",
  },
  innerPadding2: {
    padding: "6px 12px 12px 12px",
  },
  borederBottom: {
    borderBottom: "1px solid #E4E8EE",
  },
  label: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    marginTop: "10px",
    fontFamily: FontFamilySwitch().bold,
  },
  stayContainer: {
    border: "1px solid #E4E8EE",
    borderRadius: "6px",
    padding: "6px 12px 12px 12px",
    marginTop: "6px",
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  secondary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#071741",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    backgroundColor: "#F6F6F6",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#F6F6F6",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  imgContainer: {
    width: "42px",
    height: "42px",
    backgroundColor: "#f6f6f6",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  unitName: {
    fontSize: "1rem",
    color: "#4E5A6B",
    marginBottom: "6px",
    fontFamily: FontFamilySwitch().bold,
  },
  gate: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold,
    backgroundColor: "#F5F7FA",
    borderRadius: "4px",
    padding: "3px 6px",
  },
  details: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize"
  },
}));

const Step1 = ({ t = () => false, menuPlacement = "bottom" }) => {
  const classes = useStyles();
  const { data, validateRequest, setData, updateState, getUnitIDS, agreementList, property,   setOpenConfirmationDrawer  } =
    React.useContext(PassContext);
  const [loading1, setLoading1] = React.useState(null);
  const client = useApolloClient();

  const anyOption = {
    value: "Any",
    label: "Any",
  };
  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "gate":
        if (!data?.request_details?.unit?.value?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GET_GATES(),
          variables: {
            property_id: property?.id,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options:
            offset === 0
              ? [anyOption, ...result?.data?.access_gates_master]
              : [...result?.data?.access_gates_master],
          hasMore:
            array?.length + result?.data?.access_gates_master?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };

  React.useEffect(() => {
    getUnitIDS();
    // eslint-disable-next-line
  }, []);

  const submit = () => {
    if (validateRequest()) {
      updateState("request_details", { _k: "view_type", _v: "view" })
    }
  }
  const cancelRequest = () => {
    if(data?.request_details?.is_updated && typeof data?.request_details?.previous_state === "string"){
      setOpenConfirmationDrawer(true);
    }
    // else {
    //   history?.goBack(-1)
    // }
     if (typeof data?.request_details?.previous_state === "object") {
      setData({ ...data, request_details: { ...data?.request_details?.previous_state } });
    } 
    // else {
    //   history?.push(Routes?.visitorGatePass)
    // }
  }
  const editRequestDetails = () => {
    setData({ ...data, request_details: { ...data?.request_details, previous_state: data?.request_details, view_type: "edit" } });
  }
  const entryType = [
    {
      value: "single",
      label: "Single",
    },
    {
      value: "multiple",
      label: "Multiple",
    },
  ];
  const stayType = [
    {
      value: "Short",
      label: "One Day",
    },
    {
      value: "Long",
      label: "Period",
    },
  ];
  const passType = [
    {
      value: "Individual",
      label: "Individual",
    },
    {
      value: "Group",
      label: "Group",
    },
  ];

  return (
    <div style={{ padding: "12px 7px 12px 12px" }}>
      <Box className={classes.visiterJourney}>
        <div className={classes.innerPadding}>
          <Box display={"flex"} flexGrow={1}>
            <Box flexGrow={1}>
              <Typography className={classes.title}>
                {t("Visitor Journey")}
              </Typography>
            </Box>
            {data?.request_details?.view_type === "view" && (
              <Box>
                <IconButton size="medium" onClick={() => editRequestDetails()}>
                  <img src="/images/Outline.svg" alt="" />
                </IconButton>
              </Box>
            )}
          </Box>
        </div>
        <div className={classes.borederBottom}></div>
        {data?.request_details?.view_type === "edit" ? (
          <>
            <div className={classes.innerPadding2}>
              <Box className={classes.input}>
                <SelectBox
                  isRequired
                  label={t("ChooseUnit")}
                  placeholder={t("SelectUnit")}
                  value={data?.request_details?.unit}
                  options={agreementList}
                  onChange={(value) => {
                    updateState("request_details", { _k: "unit", _v: value });
                  }}
                  isError={data?.request_details?.error?.unit?.length > 0}
                  errorMessage={data?.request_details?.error?.unit}
                />
              </Box>
              <Box className={classes.input}>
                <Typography className={classes.label}>{t("Entry Type")}</Typography>
                <Grid container spacing={1} sx={{ marginTop: "-26px" }}>
                  {entryType?.map((type) => {
                    return (
                      <Grid item>
                        <Typography
                          onClick={() => {
                            updateState("request_details", { _k: "entryType", _v: type?.value })
                          }}
                          className={
                            data?.request_details?.entryType === type?.value
                              ? classes.active
                              : classes.unactive
                          }
                        >
                          {type?.label}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
                {data?.request_details?.error?.entryType?.length > 0 && (
                  <Typography
                    style={{ marginTop: "6px" }}
                    color="error"
                    variant="caption"
                  >
                    {data?.request_details?.error?.entryType}
                  </Typography>
                )}
              </Box>
              <Box className={classes.input}>
                <Typography className={classes.label}>{t("Stay Type")}</Typography>
                <Box className={classes.stayContainer}>
                  <Grid
                    container
                    justifyContent={"space-evenly"}
                    spacing={2}
                    sx={{ marginTop: "-26px" }}
                  >
                    {stayType?.map((value) => {
                      return (
                        <Grid item xs={6}>
                          <Typography
                            onClick={() =>
                              updateState("request_details", { _k: "stayType", _v: value?.value })
                            }
                            className={
                              data?.request_details?.stayType === value?.value
                                ? classes.active
                                : classes.unactive
                            }
                          >
                            {value?.label}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {data?.request_details?.error?.stayType?.length > 0 && (
                    <Typography
                      style={{ marginTop: "6px" }}
                      color="error"
                      variant="caption"
                    >
                      {data?.request_details?.error?.stayType}
                    </Typography>
                  )}
                  <Grid container spacing={1} sx={{ marginTop: "4px" }}>
                    <Grid item xs={data?.request_details?.stayType !== "Short" ? 6 : 12} >
                      <DatePickerNew
                        isRequired
                        dateFormat={"dd-MMM-yy"}
                        placeholder={t("VisitStartDate")}
                        value={data?.request_details?.startData ? data?.request_details?.startData : new Date()}
                        onChange={(value) => updateState("request_details", { _k: "startData", _v: value })}
                        isError={data?.request_details?.error?.startData?.length > 0}
                        errorMessage={data?.request_details?.error?.startData}
                        minDate={new Date()}
                      />
                      <Box height={"8px"} />
                    </Grid>
                    {data?.request_details?.stayType !== "Short" && (
                      <Grid item xs={6}>
                        <DatePickerNew
                          isRequired
                          dateFormat={"dd-MMM-yy"}
                          placeholder={t("VisitEndDate")}
                          value={data?.request_details?.endDate ? data?.request_details?.endDate : new Date()}
                          minDate={new Date(data?.request_details?.startData)}
                          onChange={(value) => updateState("request_details", { _k: "endDate", _v: value })}
                          isError={data?.request_details?.error?.endDate?.length > 0}
                          errorMessage={data?.request_details?.error?.endDate}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
              <Box className={classes.input}>
                <Grid container spacing={1} >
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.label}>Pass Type</Typography>
                    <Grid container spacing={1} sx={{ marginTop: "-20px" }}>
                      {passType?.map((value) => {
                        return (
                          <Grid item>
                            <Typography
                              onClick={() => updateState("request_details", { _k: "passType", _v: value?.value })}
                              className={
                                data?.request_details?.passType === value?.value
                                  ? classes.active
                                  : classes.unactive
                              }
                            >
                              {value?.label}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {data?.request_details?.error?.passType?.length > 0 && (
                      <Typography
                        style={{ marginTop: "6px" }}
                        color="error"
                        variant="caption"
                      >
                        {data?.request_details?.error?.passType}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {data?.request_details?.passType === "Group" && (
                      <Box className={classes.input}>
                        <TextBox
                          isRequired
                          color
                          type={"number"}
                          label={t("Count")}
                          placeholder={t("Enter Count")}
                          value={data?.request_details?.groupCount}
                          onChange={(value) =>
                            updateState("request_details", { _k: "groupCount", _v: value.target.value })
                          }
                          isError={data?.request_details?.error?.groupCount?.length > 0}
                          errorMessage={data?.request_details?.error?.groupCount}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.input}>
                <Grid container spacing={1} sx={{ marginTop: "4px" }}>
                  <Grid item xs={12}>
                    <SelectBox
                      isRequired
                      label={t("Preferred Access Gate")}
                      placeholder={t("ChooseGateEntry")}
                      value={data?.request_details?.gate}
                      isPaginate
                      key={JSON.stringify(data?.request_details?.unit)}
                      loadOptions={(search, array) =>
                        loadOptions(search, array, "gate")
                      }
                      loading={loading1 === "gate"}
                      debounceTimeout={3000}
                      onChange={(value) => updateState("request_details", { _k: "gate", _v: value })}
                      isError={data?.request_details?.error?.gate?.length > 0}
                      errorMessage={data?.request_details?.error?.gate}
                      menuPlacement={menuPlacement}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container spacing={1} sx={{ marginTop: "-26px" }}>
              {preferredAccessGate?.map((value) => {
                return (
                  <Grid item>
                    <Typography
                      onClick={() => updateState("stayType", value?.value)}
                      className={
                        data?.stayType === value?.value
                          ? classes.active
                          : classes.unactive
                      }
                    >
                      {value?.label}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            {data?.error?.stayType?.length > 0 && (
              <Typography
                style={{ marginTop: "6px" }}
                color="error"
                variant="caption"
              >
                {data?.error?.stayType}
              </Typography>
            )} */}
              </Box>
            </div>
            <div className={classes.borederBottom}></div>
            <Box className={classes.innerPadding}>
              <Box className={classes.innerPadding2}>
                <Grid
                  container
                  className={classes.btnroot}
                  justifyContent="space-between"
                  alignItems={"center"}
                  spacing={0}
                >
                  <Grid item xs={5}>
                    <Button
                      fullWidth
                      disabled={!data?.request_details?.is_updated}
                      className={classes.secondary}
                      variant="contained"
                      onClick={() => cancelRequest()}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Divider
                    orientation="vertical"
                    sx={{ borderLeft: "1px solid #98A0AC", padding: "14px 0" }}
                  />
                  <Grid item xs={5}>
                    <Button
                      fullWidth
                      // disabled={disable}
                      className={classes.primary}
                      variant="contained"
                      onClick={() => submit()}
                    >
                      {t("Save")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box display={"flex"} alignItems={"center"} p={2} gap={2}>
              <Box className={classes.imgContainer}>
                <img src="/images/addVisitor.svg" alt="" />
              </Box>
              <Box flexDirection={"column"} flexGrow={1}>
                <Box display={"flex"} flexGrow={1}>
                  <Box flexGrow={1}>
                    <Typography className={classes.unitName}>
                      {data?.request_details?.unit?.label}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.gate}>{data?.request_details?.gate?.label}</Typography>
                  </Box>
                </Box>
                <Box flexGrow={1}>
                  <Typography className={classes.details}>
                    {data?.request_details?.entryType} <Rounded /> {data?.request_details?.stayType === "Short" ? t("One Day" ): t("Period")} <Rounded />
                    {moment(data?.request_details?.startData).format("DD MMM YY")} <Rounded />
                    {data?.request_details?.passType}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
};
const Rounded = () => {
  return (
    <Box
      sx={{
        width: "7px",
        height: "7px",
        backgroundColor: "#CED3DD",
        borderRadius: "50%",
        display: "inline-block",
        margin: "0 3px",
      }}
    ></Box>
  );
};
export default withNamespaces("createPass")(Step1);
