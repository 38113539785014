import React from "react"

export const ApprovalStartIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
  <g id="Group_117717" data-name="Group 117717" transform="translate(6340 1515)">
    <rect id="Rectangle_58550" data-name="Rectangle 58550" width="28" height="28" rx="14" transform="translate(-6340 -1515)" fill="#b3a16d"/>
    <g id="Group_117716" data-name="Group 117716" transform="translate(-93 -67)">
      <g id="Rectangle_58549" data-name="Rectangle 58549" transform="translate(-6240 -1441)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="14" height="14" stroke="none"/>
        <rect x="0.5" y="0.5" width="13" height="13" fill="none"/>
      </g>
      <path id="icons8-pass-fail" d="M17.625,5.4H16.382A1.571,1.571,0,0,0,14.825,4h-2.45a1.571,1.571,0,0,0-1.557,1.4H9.575A1.577,1.577,0,0,0,8,6.975v9.45A1.577,1.577,0,0,0,9.575,18h8.05A1.577,1.577,0,0,0,19.2,16.425V6.975A1.577,1.577,0,0,0,17.625,5.4Zm-5.25-.35h2.45a.525.525,0,0,1,0,1.05h-2.45a.525.525,0,0,1,0-1.05Zm.7,9.45h-2.8a.525.525,0,0,1,0-1.05h2.8a.525.525,0,0,1,0,1.05Zm0-3.5h-2.8a.525.525,0,0,1,0-1.05h2.8a.525.525,0,0,1,0,1.05ZM17.3,14.654a.525.525,0,0,1-.743.743l-.5-.5-.5.5a.525.525,0,1,1-.743-.742l.5-.5-.5-.5a.525.525,0,0,1,.742-.742l.5.5.5-.5a.525.525,0,0,1,.742.742l-.5.5Zm0-4.507-1.4,1.4a.526.526,0,0,1-.743,0l-.7-.7A.525.525,0,1,1,15.2,10.1l.329.329L16.553,9.4a.525.525,0,0,1,.743.742Z" transform="translate(-6246.6 -1445)" fill="#fff"/>
    </g>
  </g>
</svg>

    )
}