import * as React from "react"
export const ProrityTrack = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    data-name="Group 112105"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#f2f4f7" data-name="Ellipse 39519" />
    <g data-name="Group 112104">
      <circle
        cx={12}
        cy={12}
        r={12}
        fill="#5078e1"
        data-name="Ellipse 39518"
        transform="translate(8 8)"
      />
      <path
        fill="#fff"
        d="m26.441 18.626-5.173-5.09a1.868 1.868 0 0 0-2.642.021l-5.09 5.174a1.868 1.868 0 0 0 .021 2.642l5.173 5.09a1.856 1.856 0 0 0 1.31.536h.017a1.856 1.856 0 0 0 1.317-.558l5.09-5.173a1.868 1.868 0 0 0-.022-2.642ZM19.5 16.833a.5.5 0 0 1 1 0v4a.5.5 0 0 1-1 0Zm.5 6.833a.667.667 0 1 1 .667-.667.666.666 0 0 1-.667.667Z"
      />
    </g>
  </svg>
)
