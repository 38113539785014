import React from "react";
import KycInformationList from "./kycInformationList";
import { withBottombar } from "../../HOCs";

class KycInformationParent extends React.Component {
  render() {
    return <KycInformationList {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(KycInformationParent, props);
