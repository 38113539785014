import * as React from "react";

const Communicate = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.759"
      height="16"
      viewBox="0 0 17.759 16"
    >
      <path
        id="icons8-communicate"
        d="M12.3,4a1.4,1.4,0,0,0-1.4,1.4v6a.6.6,0,0,0,1.024.424L13.745,10H18.3a1.4,1.4,0,0,0,1.4-1.4V5.4A1.4,1.4,0,0,0,18.3,4ZM6.362,8.5a2.775,2.775,0,1,0,2.775,2.775A2.778,2.778,0,0,0,6.362,8.5ZM3.33,14.8a1.352,1.352,0,0,0-1.392,1.3v1.233C1.937,18.855,3.84,20,6.362,20s4.425-1.146,4.425-2.664V16.1A1.351,1.351,0,0,0,9.4,14.8Z"
        transform="translate(-1.937 -4)"
        fill="#f17360"
      />
    </svg>
  );
};

export default Communicate;
