import { Box, Grid } from "@mui/material";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Routes } from "../../router/routes";
import ActionCard from "../actionCarddashboard";
export const CardList = (props) => {
  const { main , t} = props;
  const history = useHistory();
  const openSuccess = () => {
    history.push({
      pathname: Routes.success,
      state: {
        msg: t("Payment Successful"),
      },
    });
  };

  return (
    <div>
      <ScrollContainer className="scroll-container">
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex">
              {main?.invoices && props?.primary ? (
                <>
                  {main?.invoices.map((item, index) => {
                    return (
                      <Box key={index} marginRight="8px">
                        <ActionCard
                          names={props?.names}
                          cardData={item}
                          type="invoice"
                          onPay={openSuccess}
                        />
                      </Box>
                    );
                  })}
                </>
              ) : (
                ""
              )}
              {main?.maintenance && (
                <>
                  {main?.maintenance.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        marginRight="8px"
                        onClick={() => {
                          history.push(
                            Routes.requestDetails +
                              "?requestId=" +
                              item.id +
                              "&requestNo=" +
                              item.id +
                              "&requestType=Maintenance" +
                              "&status=" +
                              item.status
                          );
                        }}
                      >
                        <ActionCard
                          names={props?.names}
                          cardData={item}
                          type="maintenance"
                        />
                      </Box>
                    );
                  })}
                </>
              )}
              {main?.general && (
                <>
                  {main?.general.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        marginRight="8px"
                        onClick={() => {
                          history.push(
                            Routes.requestDetails +
                              "?requestId=" +
                              item.id +
                              "&requestNo=" +
                              item.id +
                              "&requestType=General" +
                              "&status=" +
                              item.status
                          );
                        }}
                      >
                        <ActionCard
                          names={props?.names}
                          cardData={item}
                          type="general"
                        />
                      </Box>
                    );
                  })}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </ScrollContainer>
    </div>
  );
};
