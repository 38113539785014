import { Box, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
 
export const InspectionUnitCompletedSimmer = (props) => {
  const size = useWindowDimensions();
 
  return (
    <div style={{ height: size.height - 55, padding: "16px" }}>
      <Box
        height={220}
        sx={{ borderRadius: "4px", backgroundColor: "#fff", padding: "12px" }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          mb={2}
        >
          <Grid item xs={6}>
            <Skeleton
              animation="wave"
              height={22}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={18} width={"80%"} />
          </Grid>
          <Grid item xs={"auto"}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
        </Grid>
        <Skeleton animation="wave" height={60} style={{ marginBottom: 16 }} />
        <Grid container spacing={2} alignItems="center" mb={2}>
          <Grid item xs={"auto"}>
            <Skeleton variant="rounded" width={40} height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              animation="wave"
              height={22}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={18} width={"80%"} />
          </Grid>
        </Grid>
      </Box>
      <Box height={18} />
      <Box
        height={250}
        sx={{ borderRadius: "4px", backgroundColor: "#fff", padding: "12px" }}
      >
        <Skeleton animation="wave" height={20} width={"50%"} style={{marginBottom:"10px"}} />
        <Grid container spacing={2} alignItems="center" mb={2}>
          <Grid item xs={"auto"}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              animation="wave"
              height={22}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={18} width={"80%"} />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" mb={2} p={1}>
          <Grid item xs={"auto"}>
            <Skeleton variant="rounded" width={30} height={30} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              animation="wave"
              height={18}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={16} width={"80%"} />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" mb={2} p={1}>
          <Grid item xs={"auto"}>
            <Skeleton variant="rounded" width={30} height={30} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              animation="wave"
              height={18}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={16} width={"80%"} />
          </Grid>
        </Grid>
      </Box>
      <Box height={18} />
    </div>
  );
};
