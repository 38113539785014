import React from "react";
import PaymentHistory from "./paymentHistory";

class PaymentHistoryParent extends React.Component {
  render(props) {
    return <PaymentHistory id={this.props.id} />;
  }
}

export default PaymentHistoryParent;
