import React from "react";
import { withBottombar } from "../../HOCs";
import UtilitiesDetails from "./details";

class UtilitiesDetailsParent extends React.Component {
  render() {
    return <UtilitiesDetails {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(UtilitiesDetailsParent, props);
