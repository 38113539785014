export const ArrowWithCircleIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <g id="Group_108787" data-name="Group 108787" transform="translate(-314 -148)">
    <g id="Ellipse_39585" data-name="Ellipse 39585" transform="translate(314 148)" fill="#f2f4f7" stroke="#e4e8ee" stroke-width="1">
      <circle cx="16" cy="16" r="16" stroke="none"/>
      <circle cx="16" cy="16" r="15.5" fill="none"/>
    </g>
    <path id="icons8-expand-arrow" d="M14.025,12.153,9,16.741,3.976,12.153a.61.61,0,0,0-.808,0,.49.49,0,0,0,0,.738L8.6,17.847a.61.61,0,0,0,.808,0l5.429-4.957a.49.49,0,0,0,0-.738A.61.61,0,0,0,14.025,12.153Z" transform="translate(315 173) rotate(-90)" fill="#071741" stroke="#071741" stroke-width="0.5"/>
  </g>
</svg>

    )
}