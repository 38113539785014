import { Box, Divider, Stack, Typography } from "@mui/material"
import { useStyles } from "./style";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import GetSupportIcon from "../../assets/getSuppportIcon";


export const AccordianCard = ({
    title = "",
    icons = "",
    onClick = () => false,
    bottomText = "",
    detailed = false,
    details = ""
}) => {

    const classes = useStyles()
    const language = localStorage.getItem("i18nextLng")

    return (
        <>
            {detailed && detailed ?
                //detailed true
                <Box
                    className={classes?.boxStyles}
                >
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems="center" >
                        <Stack direction={"row"} alignItems="center">
                            {/* icons  */}
                            {icons && icons ? icons : <GetSupportIcon />}
                            {/* texts */}
                            <Typography sx={{ marginInlineStart: "10px" }} className={classes?.text}>
                                {title}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography className={classes?.bottomTextDetailed}>{bottomText}</Typography>

                    <Divider className={classes?.divider} />

                    <Typography className={classes?.contentText}>{details}</Typography>
                </Box>
                :

                //detailed false
                <Box
                    className={classes?.boxStyles}
                    onClick={onClick && onClick ? onClick : false}
                >
                    <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems="center" >
                        <Stack direction={"row"} alignItems="center">
                            {/* icons  */}
                            {icons && icons ? icons : <GetSupportIcon />}
                            {/* texts */}
                            <Typography sx={{ marginInlineStart: "10px" }} className={classes?.text}>
                                {title}
                            </Typography>
                        </Stack>

                        <Stack>
                            {language === "ar" ?
                                <ArrowBackIosNewIcon className={classes?.arrowIcon} /> :
                                <ArrowForwardIosIcon className={classes?.arrowIcon} />
                            }
                        </Stack>
                    </Stack>

                    <Divider className={classes?.divider} />

                    <Typography className={classes?.bottomText}>{bottomText}</Typography>
                </Box>
            }

        </>
    )
}