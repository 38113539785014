import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  imageViewer: {
    height: "178px",
    width: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  },
  title: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  chipActive: {
    cursor:"pointer",
    borderRadius: "18px",
    padding: "6px 12px",
    border: "1px solid #071741",
    backgroundColor: "#071741",
    color: "#FFFFFF",
    fontSize: "0.75rem",
    fontFamily:FontFamilySwitch().bold,
  },
  chidDeactive: {
    cursor:"pointer",
    borderRadius: "18px",
    padding: "6px 12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "#fff",
    color: "#071741",
    fontSize: "0.75rem",
    fontFamily:FontFamilySwitch().bold,
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  secondary: {
    padding: "11px 15px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#19274e",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#e6e7ec",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#e6e7ec",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  btnroot: {
    zIndex: 1000,
    background: "#fff",
    padding: "14px 14px",
  },
  input: {
    margin: "10px 0 16px 0",
  },
  propertyCard: {
    borderBottom: "1px solid #E4E8EE",
    padding: "10px 0 10px 0",
    width: "100%",
  },
  mainPadding2: {
    padding: "14px 14px 16px 16px",
  },
  checkContainer: {
    margin: "1px 0",
  },
  chceckBox: {
    "& .MuiTypography-root ": {
      color: "#4E5A6B",
      fontFamily: FontFamilySwitch().semiBold,

      fontSize: "0.875rem"
    },
    "& svg": {
    fill:"#CED3DD"
    },
    "& .Mui-checked svg": {
      fill:"#1F74EA"
      }
    
  },
  moreTex: {
    color: "#1F74EA",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    marginLeft:"10px",
  },
}));
