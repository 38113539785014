export const LanguageIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="15 15 30 30"
        {...props}
    >
        <path
            fill="#4e5a6b"
            d="M23.056 20.001A3.069 3.069 0 0 0 20 23.057v11.111a3.069 3.069 0 0 0 3.056 3.056h.334a3.042 3.042 0 0 0 3 2.778h10.554A3.068 3.068 0 0 0 40 36.945V25.834a3.068 3.068 0 0 0-3.056-3.056h-.334a3.042 3.042 0 0 0-3-2.778l-10.554.001Zm0 1.667h10.555A1.377 1.377 0 0 1 35 23.057v.556c0 .46.373.833.833.833h1.111a1.377 1.377 0 0 1 1.389 1.389v11.11a1.377 1.377 0 0 1-1.389 1.389H26.389A1.377 1.377 0 0 1 25 36.945v-.556a.833.833 0 0 0-.833-.833h-1.111a1.377 1.377 0 0 1-1.389-1.389v-11.11a1.377 1.377 0 0 1 1.389-1.389Zm3.32 1.1a.832.832 0 0 0-.82.845v.278h-1.945a.834.834 0 0 0 0 1.667h2.641c.09.015.181.015.27 0h.6a3.581 3.581 0 0 1-.522 1.533 2.475 2.475 0 0 1-.726-.283 4.105 4.105 0 0 1-.568-.451.832.832 0 1 0-1.178 1.178c.236.212.483.411.741.595a4.658 4.658 0 0 1-1.263.205.834.834 0 0 0 0 1.667 5.391 5.391 0 0 0 3.42-1.114.832.832 0 0 0 .7-.514c.026-.031.076-.043.1-.075a5.032 5.032 0 0 0 .962-2.741h.375a.833.833 0 1 0 0-1.667h-.974a.816.816 0 0 0-.27 0h-.7v-.279a.833.833 0 0 0-.846-.845l.003.001Zm6.68 2.233a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666Zm-2.222 2.778a.833.833 0 1 0 .833.833.833.833 0 0 0-.834-.833h.001Zm2.457 1.668a.832.832 0 0 0-.716.49l-2.5 5.556a.833.833 0 1 0 1.519.684l.216-.481h3.049l.216.481a.833.833 0 1 0 1.519-.684l-2.5-5.556a.832.832 0 0 0-.804-.49h.001Zm-4.679 1.11a.833.833 0 1 0 .833.833.833.833 0 0 0-.834-.833h.001Zm4.722 1.752.774 1.72H32.56l.774-1.72Zm-6.944 1.025a.833.833 0 1 0 .833.833.833.833 0 0 0-.834-.833h.001Z"
            data-name="icons8-translation (1)"
        />
    </svg>
)