import React from "react";
import Managment from "./management";
import { withBottombar } from "../../HOCs";

class ManagmentParent extends React.Component {
  render() {
    return <Managment {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(ManagmentParent, props);
