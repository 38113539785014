import { Box, Grid, Typography, IconButton } from "@mui/material"
import { useStyles } from "../styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export const DetailCard = (props) => {
  const {
    t = () => false,
    symbol = "",
    isChartOpen = false,
    openChart = () => false,
    total = 0
  } = props;
  const classes = useStyles()
  const language = localStorage.getItem("i18nextLng")
  return (
    <Box className={classes.paymentHistoryCard}>
      <Grid container direction="column">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          direction={"row"}
        >
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: "0.75rem", color: "#091B29" }}
              className={classes.bold}
            >
              {t("history_des")}
            </Typography>
            <Box height="4px" />
            <Typography
              style={{
                fontSize: "1.375rem",
                color: "#091B29",
              }}
              className={classes.bold}
            >
              {symbol} {total}
              {/* {Intl.NumberFormat(, {
                              minimumFractionDigits: 0,
                            }).format(Math.round(total))} */}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                {!isChartOpen && (
                  <Typography
                    onClick={openChart}
                    className={classes.viewChart}
                  >
                    {t("view_chart")} &nbsp;
                  </Typography>
                )}
              </Box>
              <Box>
                <IconButton className={classes.arrrow} onClick={openChart}>
                  {language === "ar" ?
                    <ArrowBackIosIcon sx={{ fontSize: "0.875rem" }} /> :
                    <ArrowForwardIosIcon sx={{ fontSize: "0.875rem" }} />
                  }
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}