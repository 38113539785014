import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react'
import { UpcomingBookingCardStyle } from './style'
import ConformedIcon from '../utils/comfomedIcon'
import DateIcon from '../utils/dateIcon'

export const UpcomingBookingCard = (props) => {
    const classes = UpcomingBookingCardStyle(props)
    return (
        <Stack className={classes.box} direction={"row"} columnGap={2}>
            <Avatar className={classes.avatar}></Avatar>
            <Stack spacing={1}>
                <Typography className={classes.title}>{props?.data?.title}</Typography>
                <Stack spacing={"4px"}>
                    <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                        <DateIcon />
                        <Typography className={classes.subTitle}>{props?.data?.date}</Typography>
                    </Stack>
                    <Stack direction={"row"} columnGap={1} alignItems={"center"}>
                        <ConformedIcon />
                        <Typography className={classes.subTitle}>{props?.data?.status}</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}