import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch, remCalculate } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(161deg, #2C465A, #091B29)",
    height: (props) => props?.height,
    padding: "0px"
  },
  logo: {
    borderRadius: "4px",
    height: "24px",
    width: "24px"
  },
  subtitle1: {
    fontFamily: FontFamilySwitch().regular,
    color: "white",
    fontSize: remCalculate(12)
  },
  avatar: {
    height: "200px",
    width: "200px",
    objectFit: "cover",
    marginTop: "20px"
  },
  personName: {
    fontFamily: FontFamilySwitch().semiBold,
    color: "white",
    fontSize: remCalculate(20),
    marginTop: "14px"
  },
  address: {
    fontFamily: FontFamilySwitch().regular,
    color: "white",
    fontSize: remCalculate(14),
    marginTop: "8px"
  },
  clearIcon: {
    backgroundColor: "#FF4B4B",
    "&:hover": {
      backgroundColor: "#FF4B4B",
    }
  },
  doneIcon: {
    backgroundColor: "#5AC782",
    "&:hover": {
      backgroundColor: "#5AC782",
    }
  },
  btnRoot: {
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: "10px",
    whiteSpace: "nowrap",
  },
  alreadyText: {
    fontFamily: FontFamilySwitch().bold,
    color: "white",
    fontSize: remCalculate(34),
  },
  allowBtn: {
    padding: "18px",
    borderRadius: "30px",
    fontFamily: FontFamilySwitch().regular,
    border: "none",
    boxShadow: "none",
    color: "white",
    textTransform: "capitalize",
    width: "100%"
  },
  guestName: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalculate(14),
  },
  guestMobile: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().regular,
    fontSize: remCalculate(12),
    marginTop: "2px"
  },
  reason: {
    color: "#091B29",
    fontFamily: FontFamilySwitch().italic,
    fontSize: remCalculate(12),
  },
  guestDetailRoot: {
    // boxShadow:"0px 3px 30px #4354721F",
    backgroundColor: "#F5F7FA",
    borderRadius: "12px",
    cursor: "pointer"
  },
  videoBottomRoot: {
    backgroundColor: "white",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
  },
  btnCard: {
    color: "white",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalculate(12),
    borderRadius: "4px",
    padding: "12px",
    textTransform: "capitalize"
  },
  callingTitle:{
    color: "white",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: remCalculate(20),
    textAlign:"center",
    marginTop:"12px"
  },
  callingWait:{
    color: "white",
    fontFamily: FontFamilySwitch().regular,
    fontSize: remCalculate(14), 
    textAlign:"center",
    marginTop:"4px"

  }
}));