import { makeStyles } from "@mui/styles";

export const DrawerCompStyle = makeStyles((theme) => ({
    // drawer:{
    //     borderTopLeftRadius:8,
    //     borderTopRightRadius:8
    // } 
    drawer: {
        // "& div": {
        //     borderTopLeftRadius: 12,
        //     borderTopRightRadius: 12
        // }
    }
}))