import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TextBox, DatePickerNew } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall/index";
import { Routes } from "../../../router/routes";
import { AlertProps, NetWorkCallMethods, enumCatagory, enumSelect, LocalStorageKeys, FontFamilySwitch, keyMapSource } from "../../../utils";
import { INVOICEREQUSET } from "../../../graphql/mutations";
import useWindowDimensions from "../../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
    bottomTitle: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().extraBold,
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "6px 8px",
    },
    root: {
        padding: "4px 8px",
    },
    btn: {
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
        textTransform: "capitalize"
    },
}));
const InitialState = {
    type: "",
    amount: "",
    date: "",
    bankname: "",
    transation: "",
    company: "",
    notes: "",
    error: {
        type: "",
        amount: "",
        date: "",
        bankname: "",
        transation: "",
        company: "",
    },
};
const AlreadyPaidForm = (props) => {
    const {
        t = () => false,
        handleClose = () => false,
        invoice = {},
    } = props
    const classes = useStyles();
    const [data, setdata] = React.useState({ ...InitialState });
    const [disable, setDisable] = React.useState(null);
    const alert = React.useContext(AlertContext);
    const history = useHistory();
    const [paymentMode, setPaymentMode] = React.useState([])
    const size = useWindowDimensions();
    const clientCurrency = JSON.parse(localStorage.getItem(LocalStorageKeys.clientCurrency))
    const isIamValide = () => {
        let isValid = true;
        let error = data.error;
        //Checking Pass Type
        if (data.type.length === 0) {
            isValid = false;
            error.type = t("Type is Required");
        }
        //Checking unit
        if (data.amount.length === 0) {
            isValid = false;
            error.amount = t("Amount is Required");
        }
        //Checking Stay Type
        if (data.date.length === 0) {
            isValid = false;
            error.date = t("Date is Required");
        }
        //Checking Start Date
        if (data.bankname.length === 0) {
            isValid = false;
            error.bankname = t("Bank Name is Required");
        }
        //Checking End Date
        if (data.transation.length === 0) {
            isValid = false;
            error.transation = t("Transation ID is Required");
        }

        setdata({ ...data, error });
        return isValid;
    };
    const updateState = (key, value) => {
        let error = data.error;
        error[key] = "";
        setdata({ ...data, [key]: value, error });
    };
    const submit = () => {
        if (isIamValide()) {
            setDisable(true);
            let paramsArray =
            {
                paid_on: data?.date,
                amount_paid: data?.amount,
                payment_mode: data?.type,
                bank: data?.bankname,
                trx_no: data?.transation,
                invoice_id: invoice?.id,
                is_active: true,
                created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                notes: data?.notes
            }

            const mutated_params = {
                query: INVOICEREQUSET,
                variables: {
                    insert: paramsArray,
                },
            };
            NetworkCall(
                `${config.graphql_url}`,
                NetWorkCallMethods.post,
                mutated_params,
                null,
                true,
                false
            )
                .then((response) => {
                    handleClose();

                    history.push({
                        pathname: Routes.success,
                        state: {
                            customLabel: t("Reconciliation request submitted successfully")
                        }
                    });
                    setDisable(false);
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("Some thing went wrong"),
                    });
                    setDisable(false);
                });
        }
    };

    //get Enum
    const getEnum = async () => {
        const result = await enumSelect([enumCatagory.payment_mode])
        const filterData = await keyMapSource({ enumType: result?.payment_mode })
        setPaymentMode(result?.payment_mode?.filter((x) => !Boolean([filterData?.type5, filterData?.type4].includes(x?.value))))
    }

    React.useEffect(() => {
        getEnum()
    }, [])

    return (
        <div className={classes.root}  >
            <Box display="flex" alignItems="center" className={classes.titleroot}>
                <Box flexGrow={1}>
                    <Typography className={classes.bottomTitle}>
                        {t("Already Paid")}
                    </Typography>
                </Box>
                <Box>
                    <IconButton size="small" onClick={() => handleClose(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box p={1}>
                <div style={{ height: size?.height - 200, overflow: "overlay" }}>
                    <Box height="6px" />
                    <FormControl component="fieldset">
                        <RadioGroup>
                            <Grid container>
                                {paymentMode?.map((val) => {
                                    return (
                                        <Grid item xs={6} sm={4}>
                                            <FormControlLabel
                                                value={val?.value}
                                                control={<Radio checked={val?.value === data?.type} />}
                                                label={<Typography noWrap>{val?.label}</Typography>}
                                                onChange={() => updateState("type", val?.value)}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                    <br />
                    {data?.error?.type?.length > 0 && (
                        <Typography variant={"caption"} color={"error"}>
                            {data?.error?.type}
                        </Typography>
                    )}
                    <TextBox
                        label={t("Amount Paid")}
                        placeholder={t("Enter Already Paid")}
                        type="number"
                        value={data.amount}
                        onKeyPress={(e) => {
                            if (e.key === "e") {
                                e.preventDefault();
                            }
                        }}
                        onChange={(value) => updateState("amount", value.target.value)}
                        startAdornment={
                            <InputAdornment>
                                <Typography>{clientCurrency?.symbol}</Typography>
                            </InputAdornment>
                        }
                        isError={data?.error?.amount?.length > 0}
                        errorMessage={data?.error?.amount}
                    />
                    <Box height="6px" />
                    <DatePickerNew
                        label={t("Date")}
                        value={data.date ?? null}
                        onChange={(value) => updateState("date", value)}
                        isError={data?.error?.date?.length > 0}
                        errorMessage={data?.error?.date}
                    />
                    <Box height="8px" />
                    <TextBox
                        label={t("Bank Name/Location")}
                        placeholder={t("Enter Bank Name/Location")}
                        value={data.bankname}
                        onChange={(value) => updateState("bankname", value.target.value)}
                        isError={data?.error?.bankname?.length > 0}
                        errorMessage={data?.error?.bankname}
                    />
                    <Box height="6px" />
                    <TextBox
                        label={t("Transaction ID")}
                        placeholder={t("Enter Transaction ID")}
                        value={data.transation}
                        onChange={(value) => updateState("transation", value.target.value)}
                        isError={data?.error?.transation?.length > 0}
                        errorMessage={data?.error?.transation}
                    />
                    <Box height="6px" />
                    <TextBox
                        label={t("Notes")}
                        placeholder={t("Enter Notes")}
                        value={data.notes}
                        onChange={(value) => updateState("notes", value.target.value)}
                    />
                    <Box height="8px" />
                </div>
                <Box height="12px" />
                <Button
                    variant="contained"
                    fullWidth
                    className={classes.btn}
                    onClick={submit}
                    disabled={disable}
                >
                    {t("Reconcile")}
                </Button>
            </Box>
        </div>
    );
};
export default withNamespaces("invoice")(AlreadyPaidForm);
