import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { WorkerContext } from "../../context";
import { generateImageUrl, img_size } from "../../../../utils";
import moment from "moment/moment";
import { addDays, eachHourOfInterval } from "date-fns";

const UnitAndScheduleCard = () => {
  const classes = useStyles();
  const { t = () => false, data } =
    React.useContext(WorkerContext);

  const timeDuration = () => {
    let from = ""
    let to = ""
    if(data?.unit_and_schedule?.workFromTime === "PM") {
      from = `${moment(new Date()).format("YYYY-MM-DD")} ${data?.unit_and_schedule?.workFrom}:00 ${data?.unit_and_schedule?.workFromTime}` 
      to = `${moment(addDays(new Date(),1)).format("YYYY-MM-DD")} ${data?.unit_and_schedule?.workTo}:00 ${data?.unit_and_schedule?.workToTime}`
    } else {
      from = `${moment(new Date()).format("YYYY-MM-DD")} ${data?.unit_and_schedule?.workFrom}:00 ${data?.unit_and_schedule?.workFromTime}` 
      to = `${moment(new Date()).format("YYYY-MM-DD")} ${data?.unit_and_schedule?.workTo}:00 ${data?.unit_and_schedule?.workToTime}`
    }
    console.log(from, to, "hi")
    let time = eachHourOfInterval({
      start: new Date(from),
      end: new Date(to)
    })
    return time.length - 1
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.mainPadding}>
        <Stack direction={"row"} className={classes.infoCard}>
          <img
            className={classes.propertyImg}
            src={data?.unit_and_schedule?.unit?.profile ? generateImageUrl(data?.unit_and_schedule?.unit?.profile, img_size.small_square) : "/images/profileBlue.svg"}
            alt=""
          />
          <Box flexGrow={1}>
            <Typography className={classes.unitName} noWrap>
              {data?.unit_and_schedule?.unit?.label}
            </Typography>
            <Typography className={classes.subTitle} noWrap>
              {data?.unit_and_schedule?.unit?.email}
            </Typography>
              <Typography className={classes.subTitle} noWrap>
                {data?.unit_and_schedule?.unit?.name}
              </Typography>
              {/* <Typography className={classes.subTitle} noWrap>
              {data?.unit_and_schedule?.unit?.mobile}
              </Typography> */}
          </Box>
        </Stack>
      </Box>
      <Box className={classes.borderBottom} />
      <Box className={classes.mainPadding}>
        <Stack direction={"row"} justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography className={classes.unitName} noWrap>
            {data?.unit_and_schedule?.entryType?.label}
          </Typography>
          {data?.unit_and_schedule?.stayType?.label === "Period" ?
          (<>
          <Typography className={classes.subTitle}>
          {moment(data?.unit_and_schedule?.startDate)
          .tz(moment.tz.guess())
          .format("DD MMM YY")}
          {" "}-{" "}
          {moment(data?.unit_and_schedule?.endDate)
          .tz(moment.tz.guess())
          .format("DD MMM YY")}
          </Typography>
          </>) : 
          (<>
           <Typography className={classes.subTitle} noWrap>
            {`${timeDuration()} hrs`} 
          </Typography>
          </>)}
         
        </Stack>
        <Stack
          mt={0.6}
          direction="row"
          alignItems={"center"}
          spacing={1}
          divider={<Box className={classes.dotIcon} />}
        >

          <Typography className={classes.subTitle} noWrap>
            {data?.unit_and_schedule?.group === true ?
              <>{t("Group Count")} <Typography component={"span"}>{data?.unit_and_schedule?.groupCount}</Typography>
              </> :
              "Individual"
            }
          </Typography>
          <Typography className={classes.subTitle} noWrap>
            {t("Deputing Workers")}  <Typography component={"span"}>{data?.unit_and_schedule?.group === true ? "Yes" : "No"}</Typography>
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

export default UnitAndScheduleCard;
