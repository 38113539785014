import * as React from "react"

export const FileDetails = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43.588}
    height={33.21}
    {...props}
  >
    <path
      data-name="Path 97708"
      d="M39.229 4.168H19.615L15.256 0H4.359A4.279 4.279 0 0 0 0 4.168v8.337h43.588V8.337a4.279 4.279 0 0 0-4.359-4.169Z"
      fill="#ffb73f"
    />
    <path
      data-name="Path 97709"
      d="M39.229 4.168H4.359A4.27 4.27 0 0 0 0 8.317v20.744a4.27 4.27 0 0 0 4.359 4.149h34.87a4.27 4.27 0 0 0 4.359-4.149V8.317a4.27 4.27 0 0 0-4.359-4.149Z"
      fill="#ffe9a6"
    />
  </svg>
)
