import {
  Box, Container, Grid, Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TitleBar } from "../../components";
import { config } from "../../config";
import { BackdropContext } from "../../contexts";
import { DOMESTIC_SERVICE_VENDOR_DETAILS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import SwiftInOutCard from "./swiftInOutCard";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    margin: "12px 10px 8px 14px",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  title: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#98A0AC",
  },
  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  subtitle: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    paddingLeft: "12px",
    marginTop: "12px"
  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
}));

const SwiftInOutDetails = (props) => {
  const {t = () => false} = props
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [detail, setDetail] = React.useState([]);
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const backdrop = React.useContext(BackdropContext);
  const getPassDetails = () => {
    const data = {
      query: DOMESTIC_SERVICE_VENDOR_DETAILS,
      variables: {
        id: passId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetail(response?.data?.data?.swift_in_out_log[0]);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  React.useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    getPassDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      {detail?.visitor_type === "DH" && (
        <TitleBar
          text={t("DomesticHelper")}

          goBack={() => history.goBack()}
        />
      )}
      {detail?.visitor_type === "SP" && (
        <TitleBar
          text={t("ServiceProvider")}

          goBack={() => history.goBack()}
        />
      )}
      {detail?.visitor_type === "VE" && (
        <TitleBar
          text={t("VendorEntry")}

          goBack={() => history.goBack()}
        />
      )}
      <div
        className={classes.root}
        style={{ height: size.height - 60, overflow: "scroll" }}
      >
        <div className={classes.cardContent}>
          <Box display="flex" alignItems="center">
            <Box className={classes.img}>
              <img
                src="/images/swiftinout.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginLeft="14px">
              <Typography className={classes.title}>
                {detail?.visitor_type === "DH" && "Domestic Helper"}
                {detail?.visitor_type === "SP" && "Service Provider"}
                {detail?.visitor_type === "VE" && "Vendor Entry"}
              </Typography>
              <Typography className={classes.sub}>
                {t("Requestedon")} {moment(detail?.created_at).format("DD MMM ")}
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {"HELPER NAME"}
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {detail?.visitor_type === "DH" && (
                  <>
                    {`${detail?.domestic_helper_request?.helper?.name}
                    (${detail?.domestic_helper_request?.profession[0]?.name})`}
                  </>
                )}
                {detail?.visitor_type === "SP" &&
                  detail?.service_providers_master?.name}
                {detail?.visitor_type === "VE" && detail?.vendor_master?.name}
              </Typography>
              <br />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                {t("GATEENTRY")}
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {detail?.access_gates_master?.name}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ margin: "8px 4px 4px 8px" }}>
          <Typography className={classes.subtitle}>
            {detail?.domestic_helper_request
              ? "UNIT DETAILS"
              : "CHECK IN & CHECK OUT DETAILS"}
          </Typography>
          <Box height="10px" />
          <>
            <SwiftInOutCard data={detail} />
            <Box height="10px" />
          </>
        </div>
      </div>

    </Container>
  );
};
export default withNamespaces("swiftInOutDetails")(SwiftInOutDetails)