//react/jsx-no-target-blank
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { saveAs } from "file-saver";
import React from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DocumentViewer } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { KeyboardArrowUp } from "@mui/icons-material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useStyles } from "./styles";
import AssetView from "../../components/assetViewer/assetView";
import PhoneIconBlue from "../../assets/phoneIcon";

const UnitDetails = (props) => {
  const { t = () => false } = props;
  const size = useWindowDimensions();
  const classes = useStyles(props);
  const { unitdetails, setunitDrawer, handBookPdf } = props;
  const [phone, setPhone] = React.useState(false);
  const [calldata, setCalldata] = React.useState({});
  const [handbook, setHandbook] = React.useState(false);
  const [ownerDescription, setOwnerDescription] = React.useState(false);
  // eslint-disable-next-line
  const handlePhone = (data) => {
    setCalldata({
      phone: `${data.business_country_code}${data.business_phone}`,
      business: `${data.mobile_country_code}${data.mobile_phone}`,
    });
    setHandbook(false);
    setPhone(true);
  };
  // eslint-disable-next-line
  const goToGoogleMap = (data) => {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" +
      data.latitude +
      "," +
      data.longitude,
      "_blank"
    );
    // console.log(data.latitude, data.longitude); +','+  data.property_name
  };
  const returnCall = () => {
    return (
      <Container maxWidth="sm">
        <Grid className={classes.screen} item xs={12}>
          <Grid className={classes.topNavBarStyle}>
            {/* TopNavbar */}
            <Box
              display="flex"
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {t("Dial")}
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setPhone(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.screenPadding}>
            <a
              href={`tel:${calldata?.business}`}
              style={{ textDecoration: "none" }}
              className={classes.call}
              target="_blank" rel="noreferrer"
            >
              {/* {
                data?.type === "warehose" ? data?.description:data?.type
              } */}
              <Box
                display="flex"
                alignItems="center"
                p={1}
                className={classes.callbox}
              >
                <Box>
                  <Typography className={classes.call}>
                    {calldata?.business}
                  </Typography>
                </Box>
                <Box marginLeft="8px">
                  <IconButton
                    size="small"
                    className={classes.phone}
                    onClick={() => setPhone(false)}
                  >
                    <PhoneIcon color="primary" />
                  </IconButton>
                </Box>
              </Box>
            </a>
            <a href={`tel:${calldata?.phone}`} className={classes.call}  target="_blank" rel="noreferrer">
              <Box
                display="flex"
                alignItems="center"
                p={1}
                className={classes.callbox}
              >
                <Box>
                  {" "}
                  <Typography className={classes.call}>
                    {calldata?.phone}
                  </Typography>{" "}
                </Box>
                <Box marginLeft="8px">
                  <IconButton
                    size="small"
                    className={classes.phone}
                    onClick={() => setPhone(false)}
                  >
                    <PhoneIcon color="primary" />
                  </IconButton>
                </Box>
              </Box>
            </a>
          </Grid>
        </Grid>
      </Container>
    );
  };
  const returnhandbook = () => {
    return (
      <>
        <Container maxWidth="sm">
          <Grid className={classes.screen} item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {t("Handbook")}
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setHandbook(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Grid
              className={classes.topNavBarStyle}
              style={{ height: size?.height - 200 }}
            >
              {handBookPdf ? (
                <>
                  <DocumentViewer url={handBookPdf} />
                  <Box height={"16px"} />
                  <Grid container className={classes.btncontainer} spacing={1}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        className={classes.applybtn}
                        onClick={() => {
                          downloadURI(handBookPdf, unitdetails?.unit_name);
                        }}
                      >
                        {t("Download")}
                      </Button>
                    </Grid>
                  </Grid>
                  <Box height={"16px"} />
                </>
              ) : (
                t("NoDocumentFound")
              )}
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };
  const downloadURI = (url, name) => {
    saveAs(url, `Handbook #${name}`);
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid
          className={classes.screen}
          style={{ height: size?.height - 130 }}
          item
          xs={12}
        >
          <Grid className={classes.topNavBarStyle}>
            {/* TopNavbar */}
            <Box
              display="flex"
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {handbook ? t("Handbook") : t("unit")}
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setunitDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          {/* {props.data.unit_c} */}
          <Grid
            className={classes.screenPadding}
            style={{
              height: size?.height - 200,
              paddingLeft: "5px",
              overflow: "auto",
            }}
          >
            <Box height={"12px"} />
            <div style={{ padding: "10px" }}>
              {unitdetails?.assets?.length ? (
                <AssetView
                  title={t("Property View")}
                  asset={unitdetails?.assets?.filter((x) => !Boolean([4, 3].includes(x?.asset_type)))}
                  assetAll={unitdetails?.assets}
                />
              ) : (
                // <Slider dots={false} autoplay={true}>
                //   {unitdetails?.assets
                //     ?.filter((x) => x?.asset_type !== 4)
                //     .map((val) => {
                //       return (
                //         <img
                //           src={val.url}
                //           className={classes.unitImg}
                //           alt="unitimg"
                //         />
                //       );
                //     })}
                // </Slider>
                <img
                  src={"/images/propertyImagePlaceHolder.svg"}
                  className={classes.unitImg}
                  alt="unitimg"
                />
              )}
              <Box height={"12px"} />
              <Box
                style={{ flexFlow: "wrap !important" }}
                display="flex"
                p={1}
                alignItems="center"
                className={classes.titleroot2}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.bottomTitle}>
                    {unitdetails?.unit_name} &nbsp;
                    <span className={classes.categoryTitle}>
                      {t(unitdetails?.unit_category_name)}
                    </span>{" "}
                    &nbsp;
                    <span className={classes.typeTitle}>
                      {unitdetails?.unit_type_name}
                    </span>
                  </Typography>
                  <Typography className={classes.bottomsub}>
                    {unitdetails?.block_name}{" "}
                    {unitdetails?.block_name?.length > 0 &&
                      unitdetails?.floor_name?.length > 0
                      ? ","
                      : ""}
                    {unitdetails?.floor_name}
                    {/* {unitdetails?.property_name} */}
                  </Typography>
                </Box>
                <Box>
                <a
          href={`tel:${unitdetails?.mobile_country_code ?? ""}${unitdetails?.mobile_phone ?? ""}`}
          target="_blank"
          style={{ textDecoration: "none", color: "#071741" }} rel="noreferrer"
        >
                  <PhoneIconBlue />
                  </a>
                </Box>
              </Box>
              {unitdetails?.unit_category === "Residential Unit" ? (
                <>
                  <Grid
                    container
                    display="flex"
                    flexWrap={"wrap"}
                    p={2}
                    spacing={1}
                    alignItems="center"
                    className={classes.titleroot2}
                  >
                    <Grid item lg={4} md={4} sm={4} xs={4} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/bedIcon.svg" alt="bedroom" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp; &nbsp;{" "}
                            {unitdetails?.unit_type
                              ? unitdetails?.unit_type
                              : "0"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/bathsIcon.svg" alt="furnished" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.total_baths
                              ? unitdetails?.total_baths + t("Baths")
                              : "0" + t("Baths")}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/homeIcon.svg" alt="home" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.total_bed_rooms
                              ? unitdetails?.total_bed_rooms + t("Room")
                              : "0" + t("Bed")}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} alignItems="center">
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginTop={"10px"}
                      >
                        <Box>
                          <img src="/images/Furnished.svg" alt="furnished" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.furnishing_type
                              ? unitdetails?.furnishing_type
                              : "0"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      alignItems="center"
                      marginTop={"10px"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/SQ_FT.svg" alt="sqft" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.total_area
                              ? unitdetails?.total_area
                              : "0"}{" "}
                            <span className={classes.sqft}>{t("Sqft")}</span>
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Stack py={2} px={"5px"} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <img src="/images/homeIcon.svg" alt="home" />
                      <Box>
                        <span className={classes.bottomDetails}>
                          &nbsp;{" "}
                          {t(unitdetails?.unit_category_name)
                            ? t(unitdetails?.unit_category_name)
                            : "-"}
                        </span>
                      </Box>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <img src="/images/SQ_FT.svg" alt="sqft" />
                      <Box>
                        <span className={classes.bottomDetails}>
                          &nbsp;{" "}
                          {unitdetails?.total_area
                            ? unitdetails?.total_area
                            : "0"}
                          &nbsp;
                          {unitdetails?.uom_name ?? ""}
                        </span>
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack py={2} px={"5px"} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <img src="/images/bedIcon.svg" alt="bed" />
                      <Box>
                        <span className={classes.bottomDetails}>
                          &nbsp;{" "}
                          {unitdetails?.total_bed_rooms
                            ? `${unitdetails?.total_bed_rooms} ${unitdetails?.total_bed_rooms === 1 ? t("Bed") : t("Beds")}`
                            : `0 ${t("Beds")}`}
                        </span>
                      </Box>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <img src="/images/bathsIcon.svg" alt="bath" />
                      <Box>
                        <span className={classes.bottomDetails}>
                          &nbsp;{" "}
                          {unitdetails?.total_baths
                            ? `${unitdetails?.total_baths} ${unitdetails?.total_baths === 1 ? t("Bath") : t("Baths")}`
                            : "0" + t("Baths")}
                        </span>
                      </Box>
                    </Stack>
                  </Stack>
                </>
              )}
              <Box
                display="flex"
                p={2}
                alignItems="center"
                justifyContent="space-evenly"
                className={classes.titleroot}
              >
                <Box flexGrow={1} display="flex" alignItems="center">
                  <img src="/images/Handbook.svg" alt="handbook" />
                  <Typography className={classes.bottomDetails}>
                    &nbsp; &nbsp;{t("Handbook")}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexWrap={"wrap"}
                  // p={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {handBookPdf ? (
                    <>
                      <Box display={"flex"} alignItems="center">
                        <Typography
                          className={classes.viewHandbook}
                          onClick={() => {
                            setHandbook(true);
                            setPhone(false);
                          }}
                        >
                          {t("View")}
                        </Typography>
                      </Box>
                      &nbsp;/&nbsp;
                      <Box display={"flex"} alignItems="center">
                        <Typography
                          className={classes.viewHandbook}
                          onClick={() => {
                            setHandbook(true);
                            setPhone(false);
                          }}
                        >
                          {t("Download")}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Typography className={classes.bottomDetails}>
                      {t("NoDocumentFound")}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                p={3}
                alignItems="center"
                justifyContent="space-evenly"
                className={classes.titleroot}
              >
                <Box
                  flexGrow={1}
                  display="flex"
                  alignItems="center"
                  gap={"12px"}
                >
                  <img
                    className={classes.mapImg}
                    src="/images/map2.png"
                    alt="map"
                  />
                  <Typography className={classes.bottomDetails}>
                    {unitdetails?.block_name}
                    {unitdetails?.block_name && ", "}
                    {unitdetails?.floor_name}
                    {unitdetails?.floor_name && ", "}
                    {unitdetails?.street_1}
                    {unitdetails?.street_1 && ", "}
                    {unitdetails?.street_2}
                    {unitdetails?.street_2 && ", "}
                    {unitdetails?.city}
                    {unitdetails?.city && ", "}
                    {unitdetails?.state}
                    {unitdetails?.state && ", "}
                    {unitdetails?.country}
                    {unitdetails?.country && ", "}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexWrap={"wrap"}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display={"flex"} alignItems="center">
                    <Typography
                      className={classes.viewHandbook}
                      onClick={() => goToGoogleMap(unitdetails)}
                    >
                      {t("Map")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box height={"20px"} />
              {unitdetails?.owner_id && (
                <>
                  <Box className={classes.ownerDetails}>
                    <Typography className={classes.ownerDetailsTxt}>
                      {t("OwnerDetails")}
                    </Typography>
                    <Box height={"10px"} />
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <Box
                        flexGrow={1}
                        display="flex"
                        alignItems="flex-start"
                        gap={"8px"}
                      >
                        <img
                          className={classes.ownerProfile}
                          src="/images/profile_pic.png"
                          alt="profile"
                        />
                        <div>
                          <Typography className={classes.ownerName}>
                            {unitdetails?.owner_name}
                          </Typography>
                          <Typography className={classes.ownerContact}>
                            {unitdetails?.owner_email +
                              ", " +
                              unitdetails?.owner_mobile_no_country +
                              " " +
                              unitdetails?.owner_mobile_no}
                          </Typography>
                          {ownerDescription ? (
                            <Typography className={classes.ownerDescription}>
                              {unitdetails?.description}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </Box>
                      <Box>
                        <Box width={"40px"} />
                        {unitdetails?.description && (
                          <Typography
                            onClick={() => setOwnerDescription((a) => !a)}
                          >
                            {ownerDescription ? (
                              <KeyboardArrowUp sx={{ fontSize: 30 }} />
                            ) : (
                              <KeyboardArrowDown sx={{ fontSize: 30 }} />
                            )}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box height={"10px"} />
                </>
              )}
              {/* ):<Box height={'10px'}/>} */}
              {/* {unitdetails?.latitude && unitdetails?.longitude && (
                <>
                  <SimpleMap
                    lat={parseFloat(unitdetails?.latitude)}
                    lng={parseFloat(unitdetails?.longitude)}
                    center={{
                      lat: parseFloat(unitdetails?.latitude),
                      lng: parseFloat(unitdetails?.longitude),
                    }}
                    zoom={15}
                  />
                </>
              )} */}
            </div>
          </Grid>
        </Grid>
      </Container>
      <Hidden smDown>
        <Dialog
          open={phone}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
          onClose={() => setPhone(false)}
        >
          {returnCall()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={phone}
          anchor="bottom"
          className={classes.drawer}
          onClose={() => setPhone(false)}
        >
          {returnCall()}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          open={handbook}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
          onClose={() => setHandbook(false)}
        >
          {returnhandbook()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={handbook}
          anchor="bottom"
          className={classes.drawer}
          onClose={() => setHandbook(false)}
        >
          {returnhandbook()}
        </Drawer>
      </Hidden>
    </div>
  );
};
export default withNamespaces("units")(UnitDetails);
