import { Avatar, Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import PassDetails from "../../assets/passDetails"
import RegisteredWorker from "../../assets/unregisteredWorker"
import UnregisteredWorker from "../../assets/unregisteredWorker2"
import { DialogDrawer, MobileNumberInputComponent, TextBox } from "../../components"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { generateImageUrl, img_size } from "../../utils"
import { SelectBox } from "../../components/dropDown"
import BulkImageUpload from "../../components/bulkImageUpload"
import Trash from "../../assets/trash"
import CloseIcon from "@mui/icons-material/Close";
import { AddWorkerPassContext } from "./addWorkerPassContext"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';


const Step2 = () => {
    const {
        t = () => false,
        classes,
        data,
        checkWorkerListEmpty,
        isWorkerCategoryOpen,
        setIsWorkerCategoryOpen,
        openWorkerForm,
        closeWorkerForm,
        isAddWorkerFormOpen,
        workerFormData,
        updateWorkerFormState,
        profileImageUpload,
        proofTypeList,
        updateProofAssets,
        addWorkerFormData,
        deleteWorker,
        openRegisteredWorker,
        isAddRegisteredWorkerOpen,
        getOptions,
        isOptionsLoading,
        registeredWorkerList,
        addRegisteredWorkerList,
        deleteRegisteredWorker,
        checkRegisteredWorkerListEmpty,
        closeRegisteredWorker,
        addBulkRegisteredWorker,
        openWorkerDetails,
        isWorkerDetails,
        closeWorkerDetails,
        viewWokerDetails
    } = React.useContext(AddWorkerPassContext);
    const language = localStorage.getItem("i18nextLng")
    return (
        <>
            <div className={classes.step_root}>
                <Box className={classes.visiterJourney}>
                    <div className={classes.innerPadding}>
                        {/* PASS DETAILS */}
                        <Stack mb={"16px"} direction={"row"}>
                            <Avatar variant="rounded" className={classes.avatar}>
                                <PassDetails />
                            </Avatar>
                            <Stack sx={{ marginInlineStart: "12px" }} direction={"column"} spacing={"4px"}>
                                <Typography className={classes.title}>{t("Worker Details")}</Typography>
                                <Typography className={classes.step}>{t("STEP")} 2/3</Typography>
                            </Stack>
                        </Stack>
                        <div className={classes.borederBottom2}></div>

                        {/* USE THIS OF WORKER LENGTH GREATER THAN ZERO */}

                        <Box>
                            {data?.worker_details?.map((_) => {
                                return (_?.is_deleted ?
                                    <></> :
                                    <Box className={classes.workerBox}>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <img style={{
                                                height: "40px",
                                                width: "40px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                            }} src={generateImageUrl(_?.profile_image ? _?.profile_image : "/images/profile_image.png", img_size?.medium_square)} alt="" />
                                            <Box sx={{ marginInlineStart: "8px" }} flexGrow={1}>
                                                <Stack direction={"column"} spacing="6px">
                                                    <Typography className={classes.workerName}>{_?.name}</Typography>
                                                    <Stack direction={"row"} divider={<Box sx={{ marginInline: "8px" }} className={classes.line} />}>
                                                        {(_?.profession || _?.proof_type?.label) && <Typography className={classes.desc}>{_?.registered_worker_id ? _?.profession : _?.proof_type?.label}</Typography>}
                                                        {/* {(_?.registered_worker_ref_no || _?.proof_id) && <Typography className={classes.desc}>{_?.registered_worker_id ? _?.registered_worker_ref_no : _?.proof_id}</Typography>} */}
                                                        {<Typography onClick={() => openWorkerDetails(_)} className={classes.viewDetails}>{t("View Details")}</Typography>}
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            <div className={classes.trash}
                                                onClick={() => deleteWorker(_)}>
                                                <Trash />
                                            </div>
                                        </Stack>
                                    </Box>)
                            })}
                        </Box>

                        {checkWorkerListEmpty() &&
                            <Box mt={4} mb={3} textAlign={"center"}>
                                <img src="/images/visitorDetails.svg" alt="" />
                            </Box>}
                        <Box p={"16px"}>
                            {checkWorkerListEmpty() &&
                                <>
                                    <Typography mb={"8px"} textAlign={"center"} className={classes.title}>{t("Add Visitor Details")}</Typography>
                                    <Typography mb={"16px"} textAlign={"center"} className={classes.info}>{t("Update simple details about the visitor to complete the process")}</Typography>
                                </>}
                            <Button
                                className={classes.addButton}
                                variant="outlined"
                                onClick={() => setIsWorkerCategoryOpen(true)}
                                fullWidth>
                                {t("Add")}
                            </Button>
                        </Box>
                    </div>
                </Box>
            </div>
            {/* SELECTION DRAWER */}
            <DialogDrawer
                open={isWorkerCategoryOpen}
                onClose={() => setIsWorkerCategoryOpen(false)}
                maxWidth="sm"
                header={t("Add Worker Category")}
                component={
                    <>
                        <div className={classes.workerBox}
                            onClick={openWorkerForm}>
                            <Stack direction={"row"} alignItems="center">
                                <Avatar className={classes.regAvatar}>
                                    <UnregisteredWorker />
                                </Avatar>
                                <Box sx={{ marginInlineStart: "12px" }} flexGrow={1}>
                                    <Stack direction={"column"} spacing={"6px"}>
                                        <Typography className={classes.regHeader}>{t("Un-Registered Worker")}</Typography>
                                        <Typography className={classes.regdescription}>{t("You need to update the required details about them")}</Typography>
                                    </Stack>
                                </Box>
                                {language === "ar" ?
                                    <KeyboardArrowLeftIcon /> :
                                    <KeyboardArrowRightIcon />
                                }
                            </Stack>
                        </div>
                        <div className={classes.workerBox}
                            onClick={openRegisteredWorker}>
                            <Stack direction={"row"} alignItems="center">
                                <Avatar className={classes.regAvatar2}>
                                    <RegisteredWorker />
                                </Avatar>
                                <Box sx={{ marginInlineStart: "12px" }} flexGrow={1}>
                                    <Stack direction={"column"} spacing={"6px"}>
                                        <Typography className={classes.regHeader}>{t("Registered Worker")}</Typography>
                                        <Typography className={classes.regdescription}>{t("Choose the one from already registered list")}</Typography>
                                    </Stack>
                                </Box>
                                {language === "ar" ?
                                    <KeyboardArrowLeftIcon /> :
                                    <KeyboardArrowRightIcon />
                                }
                            </Stack>
                        </div>
                    </>
                }
            />
            {/* ADD UNREGISTERED WORKER  */}
            <DialogDrawer
                open={isAddWorkerFormOpen}
                onClose={closeWorkerForm}
                maxWidth="sm"
                header={t("Add Worker Details")}
                component={
                    <>
                        <div style={{ height: "400px", overflow: "scroll" }}>
                            {/* WORKER IMAGE */}
                            <Box textAlign={"center"}>
                                {workerFormData?.profile_image ? (
                                    <div className={classes.imgdiv}>
                                        <img
                                            style={{
                                                height: "100px",
                                                width: "100px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                            }}
                                            alt=" "
                                            src={generateImageUrl(workerFormData?.profile_image, img_size.small_square)}
                                            className={classes.profileicons}
                                        />

                                        <CloseIcon
                                            className={classes.close}
                                            onClick={() => {
                                                updateWorkerFormState("profile_image", "");
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <IconButton className={classes.profileimg}>
                                        <PersonOutlineIcon
                                            className={classes.profileicon}
                                        />
                                        <label htmlFor="uplodebtn">
                                            <Avatar
                                                sx={{ width: 24, height: 24 }}
                                                className={classes.profileimgs}
                                                type="file"
                                            >
                                                <img
                                                    src="/images/icons8-camera (1).svg"
                                                    alt=" "
                                                    className={classes.profileicons}
                                                />
                                            </Avatar>
                                        </label>
                                        <input
                                            type="file"
                                            name="img"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            onChange={(value) => profileImageUpload(value.target.files[0])}
                                            id="uplodebtn"
                                        />
                                    </IconButton>
                                )}
                                {workerFormData?.error?.profile_image?.length > 0 &&
                                    <Typography className={classes.labelError}>
                                        {workerFormData?.error?.profile_image}
                                    </Typography>}
                            </Box>
                            {/* WORKER NAME */}
                            <div className={classes.input}>
                                <TextBox
                                    isRequired
                                    color
                                    label={t("Worker Name")}
                                    placeholder={t("Enter Worker Name")}
                                    value={workerFormData?.name}
                                    onChange={(value) => updateWorkerFormState("name", value?.target?.value)}
                                    isError={workerFormData?.error?.name?.length > 0}
                                    errorMessage={workerFormData?.error?.name} />
                            </div>
                            {/* PHONE NUMBER */}
                            <div className={classes.input}>
                                <MobileNumberInputComponent
                                    isRequired
                                    label={t("Phone Number")}
                                    placeholder={t("Phone Number")}
                                    value={workerFormData?.mobile}
                                    handleChange={(value) => updateWorkerFormState("mobile", value)}
                                    isError={workerFormData?.error?.mobile?.length > 0}
                                    errorMessage={workerFormData?.error?.mobile} />
                            </div>
                            {/* MAIL ID */}
                            <div className={classes.input}>
                                <TextBox
                                    isRequired
                                    color
                                    label={t("Mail ID")}
                                    placeholder={t("EnterMailID")}
                                    value={workerFormData?.email_id}
                                    onChange={(value) => updateWorkerFormState("email_id", value?.target?.value)}
                                    isError={workerFormData?.error?.email_id?.length > 0}
                                    errorMessage={workerFormData?.error?.email_id} />
                            </div>
                            {/* WORKER PROOF */}
                            <div className={classes.input}>
                                <Typography pb={"8px"} className={classes.subTitle}>
                                    {t("Worker Proof Type")}
                                </Typography>
                                <SelectBox
                                    placeholder={t("Select Worker Proof")}
                                    value={workerFormData?.proof_type}
                                    options={proofTypeList}
                                    onChange={(value) => updateWorkerFormState("proof_type", value)}
                                    isError={workerFormData?.error?.proof_type?.length > 0}
                                    errorMessage={workerFormData?.error?.proof_type} />
                            </div>
                            {/* ID PROOF NUMBER */}
                            <div className={classes.input}>
                                <TextBox
                                    color
                                    label={t("ID Number")}
                                    placeholder={t("EnterIDNumber")}
                                    value={workerFormData?.proof_id}
                                    onChange={(value) => updateWorkerFormState("proof_id", value?.target?.value)}
                                    isError={workerFormData?.error?.proof_id?.length > 0}
                                    errorMessage={workerFormData?.error?.proof_id} />
                            </div>
                            {/* PROOF IMAGE */}
                            <div className={classes.input}>
                                <Typography pb={"8px"} className={classes.subTitle}>
                                    {t("Visitor Proof ID Picture")}
                                </Typography>
                                <BulkImageUpload
                                    bulkUrl={workerFormData?.proof_assets}
                                    setBulkUrl={updateProofAssets} />
                                {workerFormData?.error?.proof_assets?.length > 0 &&
                                    <Typography className={classes.labelError}>
                                        {workerFormData?.error?.proof_assets}
                                    </Typography>}
                            </div>
                        </div>
                        {/* BUTTON */}
                        <Box sx={{ padding: "12px", backgroundColor: "#ffffff" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        className={classes.addButton2}
                                        onClick={addWorkerFormData}>
                                        {t("Add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>} />
            {/* SELECT REGISTERED WORKER */}
            <DialogDrawer
                open={isAddRegisteredWorkerOpen}
                onClose={closeRegisteredWorker}
                maxWidth="sm"
                header={t("Add Worker Details")}
                component={
                    <>
                        <Box className={classes.input}>
                            <SelectBox
                                isRequired
                                label=""
                                placeholder={t("Search Registered Worker")}
                                value={""}
                                onChange={(value) => addRegisteredWorkerList(value)}
                                isPaginate
                                key={registeredWorkerList?.length}
                                loadOptions={(search, array) => getOptions(search, array, "registered_worker")}
                                loading={isOptionsLoading === "registered_worker"}
                                debounceTimeout={800}
                                customOptionComponent={({ data }) => {
                                    return <Stack direction={"row"} alignItems={"center"}>
                                        <img style={{
                                            height: "40px",
                                            width: "40px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                        }} src={generateImageUrl(data?.helper_image ?? "/images/profile_image.png", img_size?.medium_square)} alt="" />
                                        <Box sx={{ marginInlineStart: "8px" }} flexGrow={1}>
                                            <Stack direction={"column"} spacing="6px">
                                                <Typography className={classes.workerName}>{data?.name}</Typography>
                                                <Stack direction={"row"} divider={<Box sx={{ marginInline: "8px" }} className={classes.line} />}>
                                                    <Typography className={classes.desc}>{data?.profession_name}</Typography>
                                                    <Typography className={classes.desc}>{data?.reference_id}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                }}
                            />
                        </Box>
                        <div style={{ height: "400px", overflow: "scroll" }}>
                            {registeredWorkerList?.map((_) => {
                                return (_?.is_deleted ?
                                    <></> :
                                    <Box className={classes.workerBox}>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <img style={{
                                                height: "40px",
                                                width: "40px",
                                                objectFit: "cover",
                                                borderRadius: "50%",
                                            }} src={generateImageUrl(_?.profile_image ?? "/images/profile_image.png", img_size?.medium_square)} alt="" />
                                            <Box sx={{ marginInlineStart: "8px"}} flexGrow={1}>
                                                <Stack direction={"column"} spacing="6px">
                                                    <Typography className={classes.workerName}>{_?.name}</Typography>
                                                    <Stack direction={"row"} divider={<Box sx={{ marginInline: "8px" }} className={classes.line} />}>
                                                        <Typography className={classes.desc}>{_?.profession}</Typography>
                                                        <Typography className={classes.desc}>{_?.registered_worker_ref_no}</Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                            <div className={classes.trash}
                                                onClick={() => deleteRegisteredWorker(_)}>
                                                <Trash />
                                            </div>
                                        </Stack>
                                    </Box>)
                            })}
                        </div>
                        {/* BUTTON */}
                        <Box sx={{ padding: "12px", backgroundColor: "#ffffff" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        disabled={checkRegisteredWorkerListEmpty()}
                                        className={classes.addButton2}
                                        onClick={addBulkRegisteredWorker}>
                                        {t("Add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
            />
            <DialogDrawer
                height={"330px"}
                maxWidth={"sm"}
                open={isWorkerDetails}
                header={t("View Detail")}
                onClose={closeWorkerDetails}
                component={
                    <>
                        <Stack pb={"16px"} direction={"row"} alignItems={"center"}>
                            <img style={{
                                height: "40px",
                                width: "40px",
                                objectFit: "cover",
                                borderRadius: "50%",
                            }} src={generateImageUrl(viewWokerDetails?.profile_image ? viewWokerDetails?.profile_image : "/images/profile_image.png", img_size?.medium_square)} alt="" />
                            <Typography sx={{ marginInlineStart: '12px' }} className={classes.name}>{viewWokerDetails?.name}</Typography>
                        </Stack>
                        {/* <Typography className={classes.description} pb={"6px"}>{t("WORKER TYPE & ID")}</Typography>
                        <Typography className={classes.information} pb={"16px"}>-</Typography> */}
                        <Typography className={classes.description} pb={"6px"}>{t("PHONE NUMBER")}</Typography>
                        <Typography className={classes.information} pb={"16px"}>{viewWokerDetails?.mobile?.mobile_code}{" "}{viewWokerDetails?.mobile?.mobile}</Typography>
                        <Typography className={classes.description} pb={"6px"}>{t("MAIL ID")}</Typography>
                        <Typography className={classes.information} pb={"16px"}>{viewWokerDetails?.email_id ?? "-"}</Typography>
                        <Typography className={classes.description} pb={"6px"}>{t("ID PROOF")}</Typography>
                        <Typography className={classes.information} pb={"16px"}>{viewWokerDetails?.proof_type ?? "-"}</Typography>
                        <Typography className={classes.description} pb={"6px"}>{t("ID NUMBER")}</Typography>
                        <Typography className={classes.information} pb={"16px"}>{viewWokerDetails?.proof_id ?? "-"}</Typography>
                    </>
                }
            />
        </>
    )
}

export default Step2;