import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    noDataText: {
      fontSize: "1.125rem",
      color: "#091B29",
      fontFamily: FontFamilySwitch().bold,
    },
  }));