export const CookiePolicy = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 108907" transform="translate(-108 -267)">
            <rect
                width={40}
                height={40}
                fill="#f1f7ff"
                data-name="Rectangle 55935"
                rx={4}
                transform="translate(108 267)"
            />
            <path
                fill="#6a68cc"
                stroke="#6a68cc"
                strokeLinecap="round"
                strokeWidth={0.2}
                d="M127.962 277a10 10 0 1 0 10 11.247.714.714 0 0 0-.807-.8 2.166 2.166 0 0 1-.3.023 2.127 2.127 0 0 1-1.616-.739.714.714 0 0 0-.86-.17 4.042 4.042 0 0 1-5.283-5.69.714.714 0 0 0-.068-.83 2.124 2.124 0 0 1-.389-2.09.714.714 0 0 0-.679-.952Zm4.6.473a2.606 2.606 0 0 0-1.963.835 2.752 2.752 0 0 0 0 3.568 2.724 2.724 0 0 0 3.926 0 2.752 2.752 0 0 0 0-3.568 2.606 2.606 0 0 0-1.96-.836Zm-5.45 1.049c0 .049-.024.091-.024.141a3.5 3.5 0 0 0 .6 1.889 5.381 5.381 0 0 0-.6 2.4 5.488 5.488 0 0 0 5.477 5.477 5.38 5.38 0 0 0 2.026-.427 3.478 3.478 0 0 0 1.788.8 8.549 8.549 0 1 1-9.267-10.28Zm5.453.38a1.087 1.087 0 0 1 .895.355 1.327 1.327 0 0 1 0 1.671 1.087 1.087 0 0 1-.895.355 1.087 1.087 0 0 1-.895-.355 1.327 1.327 0 0 1 0-1.671 1.087 1.087 0 0 1 .895-.356Zm-8.096 3.333a.714.714 0 1 0 .714.714.714.714 0 0 0-.714-.714Zm10.478 1.429a.714.714 0 1 0 .714.714.714.714 0 0 0-.714-.714Zm-9.049 3.334a.714.714 0 1 0 .714.714.714.714 0 0 0-.714-.714Zm-3.81.953a.714.714 0 1 0 .714.714.714.714 0 0 0-.715-.715Zm10 1.905a.714.714 0 1 0 .714.714.714.714 0 0 0-.713-.714Zm-5.715 1.905a.714.714 0 1 0 .714.714.714.714 0 0 0-.713-.714Zm3.81.953a.714.714 0 1 0 .714.714.714.714 0 0 0-.713-.715Z"
            />
        </g>
    </svg>
);
