import notification from "./assets/notification.png";
import kyc_expire from "./assets/kyc_expire.png";
import kyc_expired from "./assets/kyc_expired.png";
import agreement_expiring from "./assets/agreement_expiring.png";
import agreement_expired from "./assets/agreement_expired.png";
import agreement_renewal_in_progress from "./assets/agreement_renewal_in_progress.png";
import agreement_renewal_accepted from "./assets/agreement_renewal_accepted.png";
import agreement_renewal_rejected from "./assets/agreement_renewal_rejected.png";
import agreement_renewed from "./assets/agreement_renewed.png";
import amenity_checkout_pending from "./assets/amenity_checkout_pending.png";
import delivery_order_received_at_gate from "./assets/delivery_order_received_at_gate.png";
import feedback_pending from "./assets/feedback_pending.png";
import agreement_termination_in_progress from "./assets/agreement_termination_in_progress.png";
import agreement_termination_accepted from "./assets/agreement_termination_accepted.png";
import agreement_termination_rejected from "./assets/agreement_termination_rejected.png";
import agreement_terminated from "./assets/agreement_terminated.png";
import { Routes } from "../../router/routes";
import { timeZoneConverter } from "../../utils";

export const nudges_card_constants = ({
    t = () => false,
    nudge = {},
}) => {

    let _ = nudge?.nudge?.meta_data?.nudge_data_points ?? [];
    let meta_data = nudge?.nudge?.meta_data ?? "";

    return {
        weather: {
            type: "weather",
            title: t("Weather"),
            background: "transparent linear-gradient(71deg, #00A2FF 0%, #60EFFF 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#0a82bf",
            light_dash_color: "#71d0ff",
        },
        amenities_pending: {
            type: "amenities_pending",
            title: _?.length > 0
                ? `${t("Amenities")} - ${_?.[0]}`
                : `${t("Amenities")}`,
            subject: t("Amenity checkout pending"),
            icon: amenity_checkout_pending,
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            route: Routes?.agreement,
            onClick_text: t("checkout"),
            meta_data: meta_data,
        },
        unread_notification: {
            type: "unread_notification",
            title: _?.length > 0
                ? `${t("Notification")} - ${_?.[0]}`
                : `${t("Notification")}`,
            subject: t("You have unread notification"),
            icon: notification,
            background: "transparent linear-gradient(71deg, #00A2FF 0%, #60EFFF 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#0a82bf",
            light_dash_color: "#71d0ff",
            route: Routes?.notification,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        1: {
            type: "kyc_expiring",
            title: _?.length > 0
                ? `${t("KYC")} - ${_?.[0]}`
                : `${t("KYC")}`,
            subject: t("Your KYC Document is about to expire"),
            icon: kyc_expire,
            background: "transparent linear-gradient(251deg, #F9E852 0%, #FFAF0F 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#bf830b",
            light_dash_color: "#ffcf6f",
            route: Routes?.kycInformation,
            onClick_text: t("view"),
            meta_data: meta_data,

        },
        2: {
            type: "kyc_expired",
            title: _?.length > 0
                ? `${t("KYC")} - ${_?.[0]}`
                : `${t("KYC")}`,
            subject: t("Your KYC Document is expired"),
            icon: kyc_expired,
            background: "transparent linear-gradient(254deg, #EE821A 0%, #F9655B 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#b9503a",
            light_dash_color: "#f8ac88",
            route: Routes?.kycInformation,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        3: {
            type: "agreement_expiring",
            title: _?.length > 0
                ? `${t("Agreement")} - ${_?.[0]}`
                : `${t("Agreement")}`,
            subject: t("Your Agreement about to expire"),
            icon: agreement_expiring,
            background: "transparent linear-gradient(72deg, #983AF5 0%, #C384FF 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#7633b8",
            light_dash_color: "#ca98fb",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        4: {
            type: "agreement_expired",
            title: _?.length > 0
                ? `${t("Agreement")} - ${_?.[0]}`
                : `${t("Agreement")}`,
            subject: t("Your Agreement has expired"),
            icon: agreement_expired,
            background: "transparent linear-gradient(254deg, #EE821A 0%, #F9655B 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#b9503a",
            light_dash_color: "#f8ac88",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        5: {
            type: "agreement_renewal_in_progress",
            title: _?.length > 0
                ? `${t("Agreement Renewal")} - ${_?.[0]}`
                : `${t("Agreement Renewal")}`,
            subject: t("Your Agreement renewal is in progress"),
            icon: agreement_renewal_in_progress,
            background: "transparent linear-gradient(72deg, #5778A7 0%, #82B1E8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#466084",
            light_dash_color: "#a5bcda",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        6: {
            type: "agreement_renewal_accepted",
            title: _?.length > 0
                ? `${t("Agreement Renewal")} - ${_?.[0]}`
                : `${t("Agreement Renewal")}`,
            subject: t("Your Agreement renewal has been accepted"),
            icon: agreement_renewal_accepted,
            background: "transparent linear-gradient(71deg, #207045 0%, #3ABA76 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#1a5b38",
            light_dash_color: "#81c09e",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        7: {
            type: "agreement_renewal_rejected",
            title: _?.length > 0
                ? `${t("Agreement Renewal")} - ${_?.[0]}`
                : `${t("Agreement Renewal")}`,
            subject: t("Your Agreement renewal has been rejected"),
            icon: agreement_renewal_rejected,
            background: "transparent linear-gradient(71deg, #BB233C 0%, #FF3E5D 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#941d31",
            light_dash_color: "#eb8394",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        8: {
            type: "agreement_renewed",
            title: _?.length > 0
                ? `${t("Agreement")} - ${_?.[0]}`
                : `${t("Agreement")}`,
            subject: t("Your Agreement has been renewed"),
            icon: agreement_renewed,
            background: "transparent linear-gradient(254deg, #EE821A 0%, #F9655B 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#b9503a",
            light_dash_color: "#f8ac88",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        9: {
            type: "amenity_checkout_pending",
            title: _?.length > 0
                ? `${t("Amenity")} - ${_?.[0]}`
                : `${t("Amenity")}`,
            subject: t("Amenity checkout pending"),
            icon: amenity_checkout_pending,
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        10: {
            type: "delivery_order_received_at_gate",
            title: _?.length > 0
                ? `${t("Delivery Order")} - ${_?.[0]}`
                : `${t("Delivery Order")}`,
            subject: t("Delivery order received at gate"),
            icon: delivery_order_received_at_gate,
            background: "transparent linear-gradient(71deg, #C10068 0%, #FF61A5 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#970a54",
            light_dash_color: "#f38ebd",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        11: {
            type: "feedback_pending",
            title: _?.length > 0
                ? `${t("Feedback")} - ${_?.[0]}`
                : `${t("Feedback")}`,
            subject: t("feedbacks pending"),
            icon: feedback_pending,
            background: "transparent linear-gradient(71deg, #00A2FF 0%, #60EFFF 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#0a82bf",
            light_dash_color: "#71d0ff",
            route: Routes?.feedback,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        12: {
            type: "agreement_termination_in_progress",
            title: _?.length > 0
                ? `${t("Agreement Termination")} - ${_?.[0]}`
                : `${t("Agreement Termination")}`,
            subject: t("Your Agreement termination is in progress"),
            icon: agreement_termination_in_progress,
            background: "transparent linear-gradient(72deg, #5778A7 0%, #82B1E8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#466084",
            light_dash_color: "#a5bcda",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        13: {
            type: "agreement_termination_accepted",
            title: _?.length > 0
                ? `${t("Agreement Termination")} - ${_?.[0]}`
                : `${t("Agreement Termination")}`,
            subject: t("Your Agreement termination has been accepted"),
            icon: agreement_termination_accepted,
            background: "transparent linear-gradient(71deg, #207045 0%, #3ABA76 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#1a5b38",
            light_dash_color: "#81c09e",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        14: {
            type: "agreement_termination_rejected",
            title: _?.length > 0
                ? `${t("Agreement Termination")} - ${_?.[0]}`
                : `${t("Agreement Termination")}`,
            subject: t("Your Agreement termination has been rejected"),
            icon: agreement_termination_rejected,
            background: "transparent linear-gradient(71deg, #BB233C 0%, #FF3E5D 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#941d31",
            light_dash_color: "#eb8394",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        15: {
            type: "agreement_terminated",
            title: _?.length > 0
                ? `${t("Agreement")} - ${_?.[0]}`
                : `${t("Agreement")}`,
            subject: t("Your Agreement has been terminated"),
            icon: agreement_terminated,
            background: "transparent linear-gradient(254deg, #EE821A 0%, #F9655B 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#b9503a",
            light_dash_color: "#f8ac88",
            route: Routes?.agreement,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        18: {
            type: "maintenance_request_inspector_assigned",
            title: _?.length > 0
                ? `${t("Maintenance Request")} - ${_?.[0]}`
                : `${t("Maintenance Request")}`,
            subject: `${_?.[1]} ${t("will visit you at")} ${timeZoneConverter(_?.[2], "DD MMM YY, h:mm a")}`,
            image: _?.[3],
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            route: Routes?.requestDetails,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        19: {
            type: "maintenance_request_inspector_arrival",
            title: _?.length > 0
                ? `${t("Maintenance Request")} - ${_?.[0]}`
                : `${t("Maintenance Request")}`,
            subject: `${_?.[1]} ${t("will be there in few minutes")}`,
            image: _?.[2],
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            route: Routes?.requestDetails,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        20: {
            type: "maintenance_request_inspector_late",
            title: _?.length > 0
                ? `${t("Maintenance Request")} - ${_?.[0]}`
                : `${t("Maintenance Request")}`,
            subject: `${_?.[1]} ${t("will be late by")} ${_?.[2]} ${t("hrs")}`,
            image: _?.[3],
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            route: Routes?.requestDetails,
            onClick_text: t("view"),
            meta_data: meta_data,
        },
        21: {
            type: "inspector_assigned",
            title: _?.length > 0
                ? `${_?.[4]} - ${_?.[0]}`
                : `${t("Inspector Assigned")}`,
            subject: `${_?.[1]} ${t("will visit you at")} ${timeZoneConverter(_?.[2], "DD MMM YY, h:mm a")}`,
            image: _?.[3],
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            meta_data: meta_data,
        },
        22: {
            type: "inspector_arrival",
            title: _?.length > 0
                ? `${_?.[4]} - ${_?.[0]}`
                : `${t("Inspector Arrival")}`,
            subject: `${_?.[1]} ${t("will be there in few minutes")}`,
            image: _?.[2],
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            meta_data: meta_data,
        },
        23: {
            type: "inspector_late",
            title: _?.length > 0
                ? `${_?.[4]} - ${_?.[0]}`
                : `${t("Inspector Late")}`,
            subject: `${_?.[1]} ${t("will be late by")} ${_?.[2]} ${t("hrs")}`,
            image: _?.[3],
            background: "transparent linear-gradient(73deg, #4F687E 0%, #6997C8 100%) 0% 0% no-repeat padding-box",
            dark_dash_color: "#3e5367",
            light_dash_color: "#9fb6cd",
            meta_data: meta_data,
        },
    }
};

export const AgreementNudgeTypes = [
    "agreement_expiring",
    "agreement_expired",
    "agreement_renewal_in_progress",
    "agreement_renewal_accepted",
    "agreement_renewal_rejected",
    "agreement_renewed",
    "agreement_termination_in_progress",
    "agreement_termination_accepted",
    "agreement_termination_rejected",
    "agreement_terminated",
];

export const MaintanenceRequestsNudgeTypes = [
    "maintenance_request_inspector_assigned",
    "maintenance_request_inspector_arrival",
    "maintenance_request_inspector_late",
];