import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import TermsAndConditionSvg from '../../../assets/termsAndCondition'
import { makeStyles } from '@mui/styles'
import { FontFamilySwitch, remCalculate } from '../../../utils'
import { Slider } from '../../../components/slider'
import { DialogDrawer } from '../../../components/dialogDrawer'
import { Document } from '../../../assets/document'

const useStyles = makeStyles((theme) => ({
    tcText: {
        fontSize: "1rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold
    },
    imgContainer: {
        width: "100%",
        borderRadius: "12px",
        overflow: "hidden",
        boxShadow: "0px 0px 4px #00000029",
        height: "125px",
        margin: "4px"
    },
    img: {
        objectFit: "cover",
        height: "125px",
        width: "100%",
        borderRadius: 12,
    },

    detailsBox: {
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        backgroundColor: theme?.palette?.background?.background5
    },
    pdfText: {
        padding: 4,
        color: "#fff",
        fontFamily: FontFamilySwitch().bold,
        fontSize: remCalculate(12),
        background: theme.palette.primary.main,
        borderRadius: 8
    },
    pdfName: {
        fontSize: remCalculate(12),
        fontFamily: FontFamilySwitch().bold,
        wordBreak: "break-all",
        whiteSpace: "nowrap",
        width: '95%',
        textOverflow: "ellipsis",
        overflow: "hidden"
    }
}))

export const TermsAndCondition = ({ termsCondition = {}, t = () => false }) => {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState("")

    const handleClick = (val) => {
        setOpen(!open)
        setSelected(val)
    }

    return (
        <Box>
            <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
                <TermsAndConditionSvg />
                <Typography className={classes.tcText}>{t("Terms & Conditions")}</Typography>
            </Stack>
            <div dangerouslySetInnerHTML={{
                __html: termsCondition?.terms_conditions,
            }} ></div>
            <Box mt={2}>
                <Grid container>
                    {termsCondition?.docs?.map((val) => {
                        return (
                            <Grid item xs={3.7} className={classes.imgContainer}>
                                {
                                    val?.asset_type === 4 || val?.file_meta?.type === "pdf" ?
                                        <Stack>
                                            <Stack rowGap={1} onClick={() => handleClick(val)} className={classes.detailsBox}>
                                                <Document />
                                                <Typography className={classes.pdfText}>PDF</Typography>
                                            </Stack>
                                            <Box sx={{ width: "100%", display: 'flex', justifyContent: "center", marginTop: "6px" }}>
                                                <Typography className={classes.pdfName}>{val?.file_meta?.name ?? "-"}</Typography>
                                            </Box>
                                        </Stack>
                                        :
                                        <div
                                            style={{ position: "relative", cursor: "pointer" }}
                                            onClick={() => handleClick(val)}
                                        >
                                            <img
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                alt="not fount"
                                                width={"100%"}
                                                src={val?.url}
                                                className={classes.img}

                                            />
                                        </div>
                                }


                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <DialogDrawer
                open={open}
                onClose={() => setOpen(!open)}
                header={t("View Document")}
                maxWidth="sm"
                height="530px"
                component={
                    <Box>
                        <Slider assets={selected} uploadImage />
                    </Box>
                }
            />
        </Box>
    )
}