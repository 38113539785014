import {
  Typography,
  Stack,
  Grid,
  Box,
  Divider,
  Button,
} from "@mui/material";
import CalendarSvg from "../../../assets/calendar";
import HistoryBill from "../../../assets/historyBill";
import Time from "../../../assets/time";
import Hour from "../../../assets/hour";
import TickCalendar from "../../../assets/tickCalendar";
import { monthlyInvoiceCardStyles } from "./styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import moment from "moment/moment";

import React from "react";
// import useWindowDimensions from "../../../utils/useWindowDimensions";
// import { AlertContext } from "../../../contexts";

const MonthlyInvoiceCard = (props) => {
  const { t=()=>false } = props
  const classes = monthlyInvoiceCardStyles();
  // const alert = React.useContext(AlertContext)
  // const size = useWindowDimensions();
  const [open, setOpen] = React.useState(true)

  return (
    <div className={classes.header_div}>
      <div className={classes.invoice_card}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          pb={open ? 2 : 0}
          onClick={() => setOpen(open ? false : true)}
        >
          <Box display="flex" alignItems="center" gap="8px">
            <Typography className={classes.month}>{t("This month")}</Typography>
            {props?.inv_payment_status && <Typography className={classes.due}>{props?.val?.inv_payment_status}</Typography>}
          </Box>
          <ExpandLessIcon />
        </Stack>
        {open &&
          <>
            <div className={classes.bill_div}>
              <Grid container>
                <Grid item xs={6}>
                  <Stack direction="row" alignItems="center">
                    <HistoryBill />
                    <Stack sx={{ marginInlineStart: "16px" }} direction="column">
                      <Typography className={classes.label}>{t("Total Bill")}</Typography>
                      <Typography className={classes.text_large}>
                        {props?.val?.paid_amount}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={6}>
                  <Stack direction="row" alignItems="center">
                    <Time />
                    <Stack sx={{ marginInlineStart: "16px" }} direction="column">
                      <Typography className={classes.label}>
                        {t("Number of Hours")}
                      </Typography>
                      <Typography className={classes.text_large}>
                        {props?.val?.total_hours}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </div>

            <Grid container mb={2} mt={1} spacing={2}>
              <Grid item xs={6}>
                <div className={classes.bill_div}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.label} mb={1}>
                        {t("Booked From")}
                      </Typography>
                      <Typography className={classes.text_small}>
                      &#x202a;{moment(props?.val?.booking_start_time).format("DD MMM YYYY")}&#x202c;
                      </Typography>
                    </Box>
                    <CalendarSvg />
                  </Stack>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.bill_div}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.label} mb={1}>
                        {t("Booked To")}
                      </Typography>
                      <Typography className={classes.text_small}>
                      &#x202a;{moment(props?.val?.booking_end_time).format("DD MMM YYYY")}&#x202c;
                      </Typography>
                    </Box>
                    <CalendarSvg />
                  </Stack>
                </div>
              </Grid>
            </Grid>

            <div className={classes.bill_div}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography className={classes.label} mb={1}>
                    {t("Booked On Date")}
                  </Typography>
                  <Typography className={classes.text_small}>
                  &#x202a;{moment(props?.val?.booked_date).format("DD MMM YYYY")}&#x202c;
                  </Typography>
                </Box>
                <TickCalendar />
              </Stack>
            </div>

            <Grid container mt={1} spacing={2}>
              <Grid item xs={6}>
                <div className={classes.bill_div}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.label} mb={1}>
                        {t("Per Hour Price")}
                      </Typography>
                      <Typography className={classes.text_small}>
                        {props?.val?.rate_per_slot ?? "-"} {props?.val?.rate_per_slot ? props?.val?.currency_symbol : ''}
                      </Typography>
                    </Box>
                    <Hour />
                  </Stack>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.bill_div}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.label} mb={1}>
                        {t("Paid on")}
                      </Typography>
                      <Typography className={classes.text_small}>
                        {props?.val?.paid_on === null ? '-' : moment(props?.val?.paid_on).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                    <CalendarSvg />
                  </Stack>
                </div>
              </Grid>
            </Grid>
            <Box my={2} sx={{ padding: "0" }}>
              <Divider />
            </Box>
            <Box textAlign="center">
              <Button className={classes.viewinvoice}
                disabled={props?.val?.invoice_id === null}
                onClick={() => {
                  props?.handleView(props?.val)
                }}
              >
                {t("View Invoice")}
              </Button>
            </Box>
          </>
        }
      </div>
      

      {/* <div className={classes.invoice_card}>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap="8px">
            <Typography className={classes.month}>Last month</Typography>
            <Typography className={classes.over_due}>OVER DUE</Typography>
          </Box>
          <ExpandMoreIcon />
        </Stack>
      </div> */}
    </div>
  );
};

export default MonthlyInvoiceCard;
