import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
  asPerAgreement: {
    color: theme?.typography?.color?.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem"
  },
  asPerAgreementSub: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.Tertiary,
    fontSize: "0.75rem",
    marginTop: "6px"
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    borderRadius: "4px"
  },
  btn: {
    padding: "10px",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
  },
  btnText: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "0.75rem"
  },
  customerCard: {
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    padding: "16px",
    borderRadius: "4px"
  },
  customerIcon: {
    borderRadius: "4px"
  },
  customerTitle: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.typography?.color?.primary,
  },
  customerSub: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
  },
  dot: {
    backgroundColor: theme?.palette?.border?.primary,
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    margin: "0px 6px"
  },
  Yes: {
    fontFamily: FontFamilySwitch().regular,
    color: "white",
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: "white",
    },
    padding: "8px 12px",
  },
  card1: {
    borderRadius: "4px",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    boxShadow: '0px 3px 30px #5C86CB2E'
  },
  asPerAgreementSub1: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.Tertiary,
    fontSize: "0.75rem",
    marginTop: "6px",
    maxWidth: "275px",
    width: "100%"
  },
  requestStatusTitle: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
    fontSize: "0.75rem"
  },
  requestStatusSubTitle: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.Tertiary,
    fontSize: "0.75rem"
  },
  statusBtn: {
    width: "100%",
    display: "flex",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
    padding: "6px",
    justifyContent: "center"
  },
  cardFlexBox: {
    display: "flex",
    alignItems: "center",
    padding: "12px",
  },
  treeBox: {
    maxWidth: "700px",
    width: "100%",
    marginLeft: "37px",
  },
  border: {
    borderLeft: `2px dashed ${theme?.palette?.primary?.main}`,
    padding: "0px 20px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  avatars1: {
    position: "absolute",
    top: 0,
    left: '-11px',
    height: "16px",
    width: "16px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "9px",
    border: `1px solid ${theme?.palette?.primary?.main}`
  },
  image: {
    backgroundColor: theme?.palette?.primary?.main,
    borderRadius: "50%",
    padding: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  createnewsectionlog1: {
    padding: "14px 20px 30px 26px",
    height: `calc(100vh - 394px)`,
    overflow: "auto",
    backgroundColor: "white",
  },
  title: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
  },
  notesTimeLine: {
    color: theme?.typography?.color?.secondary,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
  },
  treeInnerBox: {
    backgroundColor: theme?.palette?.info?.light,
    padding: "4px 6px",
    borderRadius: "20px",
    display: 'inline-flex',
    alignItems: "center",
  },
  treeAvater: {
    width: "16px",
    height: "16px",
  },
  treeTitle: {
    color: theme?.palette?.primary?.main,
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginLeft: "5px"
  }
}));

export const amenityCard = makeStyles((theme) => ({
  checkoutCard: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    marginTop: "20px",
    minWidth: "343px",
    marginRight: "8px",
    cursor: "pointer",
  },
  check: {
    backgroundColor: "#FF5C0014",
    color: "#FF5C00",
    padding: "4px 8px",
    display: "inline-block",
    borderRadius: "0px 0px 6px 6px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold
  },
  avatar: {
    height: "40px",
    width: "40px"
  },
  amenityName: {
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    fontSize: "0.875rem",
  },
  time: {
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
    color: theme.typography.color.secondary
  },
}));
