import React from "react";
import { withBottombar } from "../../HOCs";
import  AmenitiesBooking  from "./amenitiesBooking";

class AmenityImageParent extends React.Component {
    render() {
        return <AmenitiesBooking {...this?.props} />
    }
}

const props = {
    top_bar: "title_bar",
  }
  
export default withBottombar(AmenityImageParent, props);