import * as React from "react"
const BackupRestoreErrorSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={239.864}
    height={160.526}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          fill="none"
          d="M0 0h239.864v160.526H0z"
          data-name="Rectangle 58087"
        />
      </clipPath>
    </defs>
    <g data-name="Group 115524">
      <g clipPath="url(#a)" data-name="Group 115523">
        <path
          fill="#f9f8f8"
          d="M238 121.741h-25.22a98.661 98.661 0 0 0-7.88-68.107h17.885a1.863 1.863 0 0 0 0-3.725h-19.892A98.294 98.294 0 0 0 53.408 23.625H23.146a1.863 1.863 0 0 0 0 3.725H49.3q-3.133 3.005-5.994 6.279H1.862a1.863 1.863 0 1 0 0 3.725h38.346a98.407 98.407 0 0 0-14.178 97.372h-4.906a1.863 1.863 0 0 0 0 3.725h6.485q1.016 2.266 2.142 4.47H7.715a1.863 1.863 0 0 0 0 3.725h24.041a98.413 98.413 0 0 0 7.7 11.6H195.19a98.153 98.153 0 0 0 13.04-22.56h18.278a1.863 1.863 0 0 0 0-3.725h-16.832q1.166-3.2 2.114-6.491H238a1.863 1.863 0 1 0 0-3.725"
          data-name="Path 100907"
        />
        <path
          fill="#f2f2f2"
          d="M158.63 158.237h-11.906a1.557 1.557 0 0 1 1.556-1.557h8.793a1.556 1.556 0 0 1 1.556 1.557"
          data-name="Path 100908"
        />
        <path
          fill="#f2f2f2"
          d="M155.809 156.68h-6.265l2.036-58.034 1.368.91Z"
          data-name="Path 100909"
        />
        <path
          fill="#f2f2f2"
          d="M157.744 158.237h-11.906a1.557 1.557 0 0 1 1.556-1.557h8.793a1.557 1.557 0 0 1 1.557 1.557"
          data-name="Path 100910"
        />
        <path
          fill="#f2f2f2"
          d="m151.791 99.16 29.957 17.3-.327-1.7a1.405 1.405 0 0 0-.52-.846l-19.572-15.15a1.405 1.405 0 0 0-1.262-.235l-3.926 1.173a1.405 1.405 0 0 1-1.1-.13l-2.609-1.507Z"
          data-name="Path 100911"
        />
        <path
          fill="#f2f2f2"
          d="m151.155 98.059-29.957 17.3 1.635.567a1.414 1.414 0 0 0 1-.027l22.9-9.372a1.413 1.413 0 0 0 .839-.981l.945-3.98a1.412 1.412 0 0 1 .668-.9l2.607-1.505Z"
          data-name="Path 100912"
        />
        <path
          fill="#f2f2f2"
          d="M152.426 98.059V63.471l-1.31 1.134a1.41 1.41 0 0 0-.474.876l-3.334 24.521a1.409 1.409 0 0 0 .429 1.214l2.976 2.811a1.408 1.408 0 0 1 .442 1.024v3.011Z"
          data-name="Path 100913"
        />
        <path
          fill="#f2f2f2"
          d="M154.665 156.68h-5.75l2.036-58.034h1.678Z"
          data-name="Path 100914"
        />
        <path
          fill="#f2f2f2"
          d="M153.263 98.647a1.473 1.473 0 1 1-1.473-1.473 1.473 1.473 0 0 1 1.473 1.473"
          data-name="Path 100915"
        />
        <path
          fill="#f2f2f2"
          d="M153.035 98.56a1.343 1.343 0 1 1-1.343-1.343 1.343 1.343 0 0 1 1.343 1.343"
          data-name="Path 100916"
        />
        <path
          fill="#f2f2f2"
          d="m121.729 115.539 28.619-16.979-.026-.02-29.124 16.815Z"
          data-name="Path 100917"
        />
        <path
          fill="#f2f2f2"
          d="M148.929 156.303h5.737v.377h-5.737z"
          data-name="Rectangle 58084"
        />
        <path
          fill="#f2f2f2"
          d="m152.001 63.836.394 33.509.03-.012V63.472Z"
          data-name="Path 100918"
        />
        <path
          fill="#f2f2f2"
          d="m181.642 115.904-29.014-16.295v.032l29.124 16.815Z"
          data-name="Path 100919"
        />
        <path
          fill="#ececec"
          d="M197.744 158.237h-19.061a2.492 2.492 0 0 1 2.492-2.492h14.077a2.492 2.492 0 0 1 2.492 2.492"
          data-name="Path 100920"
        />
        <path
          fill="#ececec"
          d="M193.228 155.745h-10.03l3.26-92.91 2.191 1.456Z"
          data-name="Path 100921"
        />
        <path
          fill="#f2f2f2"
          d="M196.326 158.237h-19.061a2.492 2.492 0 0 1 2.492-2.492h14.077a2.492 2.492 0 0 1 2.492 2.492"
          data-name="Path 100922"
        />
        <path
          fill="#f2f2f2"
          d="m186.795 63.656 47.961 27.69-.584-3.039a1.407 1.407 0 0 0-.52-.847l-31.928-24.715a1.406 1.406 0 0 0-1.264-.235l-7.049 2.106a1.407 1.407 0 0 1-1.106-.129l-4.492-2.594Z"
          data-name="Path 100923"
        />
        <path
          fill="#f2f2f2"
          d="m185.778 61.893-47.961 27.69 2.923 1.013a1.408 1.408 0 0 0 .995-.027l38.036-15.566 2.008-8.451 5.017-2.9Z"
          data-name="Path 100924"
        />
        <path
          fill="#f2f2f2"
          d="M187.811 61.894V6.514l-2.339 2.025a1.41 1.41 0 0 0-.474.876l-5.439 40.005a1.409 1.409 0 0 0 .429 1.215l5.347 5.05a1.41 1.41 0 0 1 .442 1.025v5.186Z"
          data-name="Path 100925"
        />
        <path
          fill="#f2f2f2"
          d="M191.399 155.745h-9.206l3.26-92.91h2.681Z"
          data-name="Path 100926"
        />
        <path
          fill="#ececec"
          d="M189.153 62.834a2.358 2.358 0 1 1-2.357-2.358 2.358 2.358 0 0 1 2.357 2.358"
          data-name="Path 100927"
        />
        <path
          fill="#f2f2f2"
          d="M188.783 62.696a2.15 2.15 0 1 1-2.15-2.15 2.15 2.15 0 0 1 2.15 2.15"
          data-name="Path 100928"
        />
        <path
          fill="#ececec"
          d="m138.667 89.879 45.818-27.183-.041-.032-46.627 26.92Z"
          data-name="Path 100929"
        />
        <path
          fill="#ececec"
          d="M182.214 155.141h9.185v.604h-9.185z"
          data-name="Rectangle 58085"
        />
        <path
          fill="#ececec"
          d="m187.132 7.103.631 53.648.048-.02V6.513Z"
          data-name="Path 100930"
        />
        <path
          fill="#ececec"
          d="m234.582 90.462-46.45-26.088-.007.052 46.627 26.92Z"
          data-name="Path 100931"
        />
        <path
          fill="#f2f2f2"
          d="M45.083 113.251a11.191 11.191 0 0 1-11.159-11.159V76.404a11.159 11.159 0 0 1 22.317 0v25.685a11.191 11.191 0 0 1-11.158 11.159"
          data-name="Path 100932"
        />
        <path
          fill="#e4e4e4"
          d="M45.691 158.292h-1.218v-75.2a.609.609 0 0 1 1.218 0Z"
          data-name="Path 100933"
        />
        <path
          fill="#e4e4e4"
          d="m45.691 93.288-3.385-5.863a.609.609 0 1 0-1.054.609l3.385 5.863Z"
          data-name="Path 100934"
        />
        <path
          fill="#e4e4e4"
          d="m45.527 100.301 3.385-5.863a.609.609 0 1 0-1.054-.609l-3.385 5.863Z"
          data-name="Path 100935"
        />
        <path
          fill="#f2f2f2"
          d="M73.577 101.03a14.211 14.211 0 0 1-14.17-14.17V54.244a14.17 14.17 0 0 1 28.339 0v32.617a14.211 14.211 0 0 1-14.17 14.17"
          data-name="Path 100936"
        />
        <path
          fill="#e4e4e4"
          d="M74.35 158.224h-1.546V62.737a.773.773 0 1 1 1.546 0Z"
          data-name="Path 100937"
        />
        <path
          fill="#e4e4e4"
          d="m74.35 75.679-4.3-7.445a.773.773 0 1 0-1.339.773l4.3 7.445Z"
          data-name="Path 100938"
        />
        <path
          fill="#e4e4e4"
          d="m74.143 84.584 4.3-7.445a.773.773 0 0 0-1.339-.773l-4.3 7.445Z"
          data-name="Path 100939"
        />
        <path
          fill="#e4e4e4"
          d="M51.966 154.07c.65-.611.948-1.309.691-1.8-.317-.608-1.379-.7-2.513-.273q.087-.222.161-.457c.6-1.929.259-3.755-.771-4.077-1-.314-2.288.915-2.911 2.765-.517-2.119-1.843-3.613-2.98-3.345-1.071.252-1.6 1.976-1.251 3.952-1.569-.693-3.082-.638-3.565.2-.5.865.278 2.269 1.76 3.292a1.633 1.633 0 0 0-1.252 1.206c-.081.663.488 1.317 1.39 1.721a.642.642 0 0 0-.034.384c.094.4.6.647 1.264.673h8.476v-.007a.372.372 0 0 0 .326-.2.3.3 0 0 0-.027-.241.965.965 0 0 0 .881-.508.817.817 0 0 0-.155-.745c1.144-.077 2.024-.578 2.052-1.223.025-.568-.616-1.081-1.542-1.316"
          data-name="Path 100940"
        />
        <path
          fill="#f2f2f2"
          d="M69.585 150.514c1.2-1.124 1.742-2.407 1.271-3.31-.584-1.118-2.537-1.289-4.621-.5q.16-.407.3-.839c1.111-3.548.476-6.9-1.417-7.5-1.846-.578-4.207 1.683-5.354 5.084-.95-3.9-3.389-6.643-5.481-6.151-1.969.463-2.934 3.634-2.3 7.268-2.885-1.274-5.669-1.172-6.556.364-.918 1.59.512 4.172 3.237 6.054a3 3 0 0 0-2.3 2.218c-.149 1.22.9 2.423 2.556 3.166a1.18 1.18 0 0 0-.062.705c.173.736 1.1 1.19 2.324 1.238h15.589v-.013a.685.685 0 0 0 .6-.369.543.543 0 0 0-.05-.443 1.775 1.775 0 0 0 1.62-.934 1.5 1.5 0 0 0-.285-1.37c2.1-.141 3.722-1.063 3.773-2.25.045-1.043-1.134-1.988-2.836-2.42"
          data-name="Path 100941"
        />
        <path
          fill="#eaeaea"
          d="M174.383 151.317c-1.065-1-1.552-2.143-1.132-2.948.52-1 2.259-1.148 4.115-.447q-.142-.363-.263-.748c-.989-3.159-.424-6.148 1.262-6.677 1.644-.515 3.746 1.5 4.767 4.527.846-3.47 3.018-5.916 4.88-5.478 1.753.412 2.613 3.236 2.049 6.472 2.569-1.134 5.048-1.044 5.837.324.818 1.416-.456 3.715-2.882 5.391a2.675 2.675 0 0 1 2.05 1.975c.133 1.086-.8 2.157-2.276 2.819a1.05 1.05 0 0 1 .056.628c-.154.655-.984 1.059-2.069 1.1h-13.881v-.012a.611.611 0 0 1-.535-.329.484.484 0 0 1 .045-.395 1.582 1.582 0 0 1-1.443-.832 1.338 1.338 0 0 1 .254-1.22c-1.874-.125-3.314-.947-3.36-2-.04-.929 1.01-1.77 2.526-2.155"
          data-name="Path 100942"
        />
        <path
          fill="#e4e4e4"
          d="M190.224 155.462c-.43-.4-.627-.866-.457-1.191.21-.4.913-.464 1.663-.181q-.057-.146-.106-.3c-.4-1.276-.171-2.484.51-2.7.664-.208 1.514.606 1.926 1.829.342-1.4 1.219-2.39 1.972-2.213.708.167 1.056 1.307.828 2.615 1.038-.458 2.039-.422 2.358.131.331.572-.184 1.5-1.165 2.178a1.08 1.08 0 0 1 .828.8c.054.439-.322.872-.919 1.139a.425.425 0 0 1 .022.254c-.062.265-.4.428-.836.445h-5.609a.247.247 0 0 1-.216-.133.2.2 0 0 1 .018-.16.639.639 0 0 1-.583-.336.539.539 0 0 1 .1-.493c-.757-.051-1.339-.383-1.358-.81-.016-.375.408-.715 1.021-.871"
          data-name="Path 100943"
        />
        <path
          fill="#f2f2f2"
          d="M122.727 151.718c-1-.943-1.463-2.02-1.067-2.778.49-.938 2.129-1.082 3.879-.421q-.134-.342-.248-.7c-.932-2.978-.4-5.8 1.19-6.293 1.549-.485 3.531 1.413 4.494 4.267.8-3.271 2.845-5.576 4.6-5.163 1.653.389 2.463 3.05 1.931 6.1 2.422-1.069 4.758-.984 5.5.305.771 1.335-.43 3.5-2.717 5.082a2.52 2.52 0 0 1 1.932 1.862c.125 1.024-.753 2.034-2.145 2.657a.991.991 0 0 1 .052.592c-.145.617-.927 1-1.95 1.039h-13.084v-.011a.575.575 0 0 1-.5-.31.456.456 0 0 1 .042-.372 1.49 1.49 0 0 1-1.36-.784 1.26 1.26 0 0 1 .239-1.15c-1.767-.118-3.124-.892-3.167-1.888-.038-.876.952-1.669 2.381-2.031"
          data-name="Path 100944"
        />
        <path
          fill="#e4e4e4"
          d="M137.258 154.557c-.569-.534-.828-1.144-.6-1.574.277-.532 1.206-.613 2.2-.239q-.076-.194-.141-.4c-.528-1.687-.226-3.283.674-3.564.878-.275 2 .8 2.545 2.417.452-1.853 1.611-3.158 2.606-2.924.936.22 1.395 1.728 1.094 3.455 1.372-.605 2.7-.557 3.117.173.437.756-.244 1.984-1.539 2.878a1.427 1.427 0 0 1 1.094 1.055c.071.58-.426 1.152-1.215 1.505a.56.56 0 0 1 .03.335c-.082.35-.525.566-1.1.588h-7.411v-.006a.326.326 0 0 1-.285-.175.258.258 0 0 1 .024-.211.844.844 0 0 1-.77-.444.714.714 0 0 1 .135-.651c-1-.067-1.769-.505-1.794-1.069-.021-.5.539-.945 1.349-1.151"
          data-name="Path 100945"
        />
        <path
          fill="#f2f2f2"
          d="M144.615 66.353a.875.875 0 0 0-.262.04v-.132a1.847 1.847 0 0 0-3.624-.5 1.2 1.2 0 1 0-.326 2.348H144.611a.877.877 0 0 0 0-1.754"
          data-name="Path 100946"
        />
        <path
          fill="#f2f2f2"
          d="M112.378 46.187a2.646 2.646 0 0 0-1.365.377 4.022 4.022 0 0 0-4.422-3.087 5.7 5.7 0 0 0-10.9 2.33v.1a2.94 2.94 0 1 0-1.256 5.6h17.943a2.66 2.66 0 0 0 0-5.319"
          data-name="Path 100947"
        />
        <path
          fill="#f2f2f2"
          d="M85.03 109.516a1.74 1.74 0 0 1 .9.248 2.645 2.645 0 0 1 2.909-2.031 3.749 3.749 0 0 1 7.171 1.533v.066a1.934 1.934 0 1 1 .826 3.683h-11.8a1.75 1.75 0 1 1 0-3.5"
          data-name="Path 100948"
        />
        <path
          fill="#e4e4e4"
          d="m202.43 147.791-.028.106a3.194 3.194 0 0 1-.255.624 2.29 2.29 0 0 1-2.249 1.327 3.215 3.215 0 0 1-.67-.079l-.106-.027.028-.106a3.218 3.218 0 0 1 .255-.624 2.29 2.29 0 0 1 2.249-1.327 3.241 3.241 0 0 1 .67.079Zm-3.034 1.791a2.239 2.239 0 1 0 1.037-1.4 2.674 2.674 0 0 0-1.037 1.4"
          data-name="Path 100949"
        />
        <path
          fill="#f2f2f2"
          d="M200.911 150.337a1.173 1.173 0 1 1 1.526-1.506 3.173 3.173 0 0 1-1.526 1.506"
          data-name="Path 100950"
        />
        <path
          fill="#e4e4e4"
          d="M198.743 135.624v.136a3.969 3.969 0 0 1-.119.83 2.849 2.849 0 0 1-2.311 2.282 3.978 3.978 0 0 1-.832.108h-.136v-.136a3.97 3.97 0 0 1 .119-.83 2.848 2.848 0 0 1 2.312-2.282 3.969 3.969 0 0 1 .832-.108Zm-3.119 3.079a2.786 2.786 0 1 0 .829-2 3.327 3.327 0 0 0-.829 2"
          data-name="Path 100951"
        />
        <path
          fill="#f2f2f2"
          d="M197.677 139.157a1.46 1.46 0 1 1 1.385-2.28 3.949 3.949 0 0 1-1.385 2.28"
          data-name="Path 100952"
        />
        <path
          fill="#e4e4e4"
          d="m58.288 132.058.086.074a3.313 3.313 0 0 1 .467.52 2.374 2.374 0 0 1 .22 2.7 3.327 3.327 0 0 1-.376.589l-.073.087-.086-.074a3.317 3.317 0 0 1-.467-.52 2.374 2.374 0 0 1-.22-2.7 3.341 3.341 0 0 1 .376-.589Zm.3 3.64a2.321 2.321 0 1 0-.834-1.6 2.771 2.771 0 0 0 .834 1.6"
          data-name="Path 100953"
        />
        <path
          fill="#f2f2f2"
          d="M59.977 134.622a1.216 1.216 0 1 1-.714-2.1 3.29 3.29 0 0 1 .714 2.1"
          data-name="Path 100954"
        />
        <path
          fill="#e4e4e4"
          d="m36.757 144.958.1-.006a2.82 2.82 0 0 1 .586.039 2 2 0 0 1 1.716 1.494 2.79 2.79 0 0 1 .12.575l.007.1-.1.006a2.815 2.815 0 0 1-.586-.039 2 2 0 0 1-1.716-1.494 2.8 2.8 0 0 1-.12-.575Zm2.316 2.016a1.951 1.951 0 1 0-1.441-.473 2.33 2.33 0 0 0 1.441.473"
          data-name="Path 100955"
        />
        <path
          fill="#f2f2f2"
          d="M39.281 145.513a1.023 1.023 0 1 1-1.666-.847 2.765 2.765 0 0 1 1.666.847"
          data-name="Path 100956"
        />
        <path
          fill="#e4e4e4"
          d="M170.19 142.053h.107a3.147 3.147 0 0 1 .656.071 2.239 2.239 0 0 1 1.856 1.753 3.112 3.112 0 0 1 .108.651v.107h-.107a3.126 3.126 0 0 1-.656-.07 2.24 2.24 0 0 1-1.856-1.753 3.133 3.133 0 0 1-.108-.65Zm2.5 2.366a2.19 2.19 0 1 0-1.594-.6 2.616 2.616 0 0 0 1.594.6"
          data-name="Path 100957"
        />
        <path
          fill="#f2f2f2"
          d="M172.994 142.791a1.147 1.147 0 1 1-1.829-1.026 3.1 3.1 0 0 1 1.829 1.026"
          data-name="Path 100958"
        />
        <path
          fill="#5078e1"
          d="M140.52 151.71H91.044l.9-3.259 1.76-6.366 1.065-3.853 1.57-5.681 3.026-10.952 1.57-5.681 11.03-39.933h7.633l4.044 14.637 6.99 25.3 1.57 5.681 3.026 10.952 1.57 5.681 2.823 10.217Z"
          data-name="Path 100959"
        />
        <path
          fill="#5078e1"
          d="M80.921 151.71h69.722v6.629H80.921z"
          data-name="Rectangle 58086"
        />
        <path
          fill="#232851"
          d="M140.52 151.71H91.044l.9-3.259h47.676Z"
          data-name="Path 100960"
        />
        <path
          fill="#fff"
          d="M132.202 121.602H99.358l1.57-5.681h29.7Z"
          data-name="Path 100961"
        />
        <path
          fill="#fff"
          d="M136.797 138.234h-42.03l1.57-5.681h38.891Z"
          data-name="Path 100962"
        />
        <path
          fill="#242850"
          d="M160.886 160.527H69.705a1.12 1.12 0 0 1-1.12-1.121v-.033a1.121 1.121 0 0 1 1.12-1.12h91.181a1.121 1.121 0 0 1 1.12 1.12v.033a1.12 1.12 0 0 1-1.12 1.121"
          data-name="Path 100963"
        />
        <path
          fill="#242850"
          d="M71.434 160.527H56.666a1.12 1.12 0 0 1-1.121-1.121v-.033a1.121 1.121 0 0 1 1.121-1.12h14.765a1.121 1.121 0 0 1 1.121 1.12v.033a1.12 1.12 0 0 1-1.121 1.121"
          data-name="Path 100964"
        />
        <path
          fill="#242850"
          d="M49.898 160.527H34.385a1.12 1.12 0 0 1-1.121-1.121v-.033a1.121 1.121 0 0 1 1.121-1.12h15.513a1.121 1.121 0 0 1 1.121 1.12v.033a1.121 1.121 0 0 1-1.121 1.121"
          data-name="Path 100965"
        />
        <path
          fill="#242850"
          d="M180.572 160.527h-12.734a1.12 1.12 0 0 1-1.12-1.121v-.033a1.121 1.121 0 0 1 1.12-1.12h12.734a1.12 1.12 0 0 1 1.12 1.12v.033a1.12 1.12 0 0 1-1.12 1.121"
          data-name="Path 100966"
        />
        <path
          fill="#242850"
          d="M197.218 160.527h-9.686a1.121 1.121 0 0 1-1.121-1.121v-.033a1.121 1.121 0 0 1 1.121-1.12h9.686a1.121 1.121 0 0 1 1.12 1.12v.033a1.12 1.12 0 0 1-1.12 1.121"
          data-name="Path 100967"
        />
      </g>
    </g>
  </svg>
)
export default BackupRestoreErrorSVG
