import * as React from "react"

const ThumbsDown = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26.619} height={26} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.162}
        y1={-0.004}
        x2={0.831}
        y2={0.932}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f15a29" />
        <stop offset={1} stopColor="#ffa385" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.153}
        y1={0.963}
        x2={0.665}
        y2={-0.022}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
    </defs>
    <path
      data-name="Path 99715"
      d="M9.364 31.819H4.273A1.272 1.272 0 0 1 3 30.546V15.273A1.272 1.272 0 0 1 4.273 14h5.091Z"
      transform="translate(-3 -14)"
      fill="url(#a)"
    />
    <path
      data-name="Path 99716"
      d="M30.8 17.333h-9.207l.614 2.476a4.651 4.651 0 0 1 1.841 3.714A2.466 2.466 0 0 1 21.593 26L13 17.333V0h15.169a2.46 2.46 0 0 1 2.4 1.957L33.2 14.338a2.471 2.471 0 0 1-2.4 2.995Z"
      transform="translate(-6.636)"
      fill="url(#b)"
    />
  </svg>
)

export default ThumbsDown
