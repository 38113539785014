import React from "react";
import MyNeighbours from "./myNeighbours";
import { withBottombar } from "../../HOCs";


class MyNeighboursParent extends React.Component {
  
  render() {
    return <MyNeighbours {...this?.props} />;
  }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(MyNeighboursParent, props);
