import React from "react";

function CheckedOut() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g data-name="Group 113424" transform="translate(-.058 1.942)">
        <circle
          cx="9"
          cy="9"
          r="9"
          fill="#5ac782"
          data-name="Ellipse 2"
          transform="translate(.058 -1.942)"
        ></circle>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4.103 7.176l3.5 3.5 6.5-6.5"
          data-name="Path 944"
        ></path>
      </g>
    </svg>
  );
}

export default CheckedOut;