import {
    Box, IconButton, Container, Typography,
    Stack, Avatar, Hidden, Dialog, Drawer, Grid, Button
} from '@mui/material'
import React from 'react'
import { MyBookingDetailsStyle } from './style'
import { useHistory } from 'react-router-dom'
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { BookingCard, CancelBooking, DetailCard } from './components';
import QRCode from 'react-qr-code';
import CircleIcon from "@mui/icons-material/Circle";
import CitySvg from '../../assets/city';
// import ChairSvg from '../../assets/chair';
import AmenityPass from '../../assets/amenityPass';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { NetworkCall } from '../../networkcall';
import { AlertProps, generateImageUrl, img_size, NetWorkCallMethods } from '../../utils';
import { config } from '../../config';
import { AlertContext, AuthContext, BackdropContext } from '../../contexts';
import { withNamespaces } from 'react-i18next';
import useWindowDimensions from '../../utils/useWindowDimensions';
import html2canvas from 'html2canvas';
import { format } from 'date-fns';
import { ShareIcons } from '../../assets';
import { Routes } from '../../router/routes';


const MyBookingDetails = ({ t = () => false, handleTopBarState }) => {
    const auth = React.useContext(AuthContext)
    const language = localStorage.getItem("i18nextLng")
    const history = useHistory()
    const size = useWindowDimensions()
    const classes = MyBookingDetailsStyle({ language: auth?.auth?.auth?.language, size })
    const state = useLocation()?.state
    const alert = React.useContext(AlertContext)
    // const [open, setOpen] = React.useState(false)
    const [pdOpen, setCBOpen] = React.useState(false)
    const [remark, setRemark] = React.useState("")
    // const [dontShow, setDontShow] = React.useState(false)
    const [bookingDetail, setBookingDetail] = React.useState([])
    const backdrop = React.useContext(BackdropContext);
    // Set remark
    const setRemarkValue = (value) => {
        setRemark(value)
    }
    //Close popup
    const closeDrawer = () => {
        setCBOpen(false)
    }


    //Cancel Booking
    const submit = () => {
        cancelBooking()
    }
    const cancelBooking = () => {
        const data = {
            booking_id: bookingDetail?.booking_id,
            remarks: remark,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/cancel_booking`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === "success") {
                    setCBOpen(false)
                    history.goBack()
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: ("SomethingWentwrong"),
                });
            });
    };

    // const endTime = 


    const getTimeWithAmPm = (timeString) => {
        const [hour] = timeString.split(":")
        if (hour === "00") {
            return `12:00 AM`
        }
        else if (hour === "24") {
            return `12:00 PM`
        }
        else {
            if (hour >= 12) {
                if (hour % 12 === 0) return `12:00 PM`
                else return `${hour % 12}:00 PM`.padStart(8, '0')
            } else return `${hour}:00 AM`.padStart(8, '0')
        }
    }

    React.useEffect(() => {
        handleTopBarState({
            text: t("Booking Details"),
            customBackpath: Routes.mybooking
        })
        getBookingDetail()
        // eslint-disable-next-line
    }, [])

    //base 64 to normal file
    function urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }
    //share ticket
    const sharePass = async (fileName) => {
        // const removedContent = document.querySelector("download")
        // removedContent.style.display = "none"
        // setDontShow(true)
        const node = document.getElementById("ticket");
        // useCors to draw image from different origin
        html2canvas(node, { useCORS: true }).then(async (canvas) => {

            let base64File = canvas.toDataURL();
            let file2 = await urltoFile(base64File, `${fileName}.png`, "image/png")
            // setDontShow(false)
            let fileArray = [file2];
            if (navigator.canShare && navigator.canShare({ files: fileArray })) {
                try {
                    await navigator
                        .share({
                            files: fileArray
                        })

                    /*
                      Show a message if the user shares something
                    */
                    console.log(t("Thanks for Sharing!"));
                } catch (err) {
                    /*
                       This error will appear if the user cancels the action of sharing.
                     */
                    console.log(`${t("Couldn't share")} ${err}`);
                }


            }

        })
    }

    const getBookingDetail = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        const payload =
        {
            property_facility_id: state?.property_facility_id,
            booking_id: state?.booking_id,
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss")
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_booked_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setBookingDetail(response?.data?.data?.[0] ?? [])
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: t("SomethingWentwrong"),
                });
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };
    return (
        <Container maxWidth="sm" sx={{ background: "#F5F7FA", padding: "0 !important" }}>
            {
                <Box>
                    <Box 
                    className={
                        // (bookingDetail?.booking_status === "Booked" && !bookingDetail?.is_expired &&
                        //     !bookingDetail?.is_slot_started && bookingDetail?.check_logs?.length > 0)
                        (bookingDetail?.booking_status === "Booked" &&
                            !bookingDetail?.is_expired &&
                            !bookingDetail?.is_slot_started &&
                            bookingDetail?.check_logs?.length === 0)
                            ? classes.canceledBody : classes.body}
                        id="ticket">
                        <Box className={classes.detailBox} p={0}>
                            <Box>
                                <DetailCard data={bookingDetail} />
                            </Box>
                        </Box>




                        <Stack className={classes.content_box} spacing={1.5} alignItems="center">
                            <Stack direction={"row"} alignItems={"center"}>
                                <Box sx={{ marginInlineEnd: "16px" }} className={classes.diamond}></Box>
                                <Typography className={classes.your_booking}>{t("YOURBOOKINGPASS")}</Typography>
                                <Box sx={{ marginInlineStart: "16px" }} className={classes.diamond}></Box>
                            </Stack>
                            <Box className={classes.passCard} p={2}>
                                <Box className={classes.boxBorder} pb={2}>
                                    <Box mr={"18px"} ml={"4px"}>
                                        {/* <Stack direction="row" justifyContent={"space-between"}
                                            className={classes.pass_card} alignItems="center" sx={{ width: "100%" }}>
                                            <Stack p={2}>
                                                <Typography className={classes.pass_title}>{t("AmenitiesPass")}</Typography>
                                                <Typography className={classes.pass_content}>{t("Passforallamenitiesbooking")}</Typography>
                                            </Stack>
                                            <AmenityPass />
                                        </Stack> */}
                                        <Stack direction="row" justifyContent={"space-between"}
                                            className={classes.pass_card} alignItems="center" sx={{ width: "100%", margin: language === "ar" ? "0px -7px 0px 8px" : "0px 16px 0px 8px" }}>
                                            <Stack p={2}>
                                                <Typography className={classes.pass_title}>{t("AmenitiesPass")}</Typography>
                                                <Typography className={classes.pass_content}>{t("Passforallamenitiesbooking")}</Typography>
                                            </Stack>
                                            <Box className={classes.AmenityPassBox} style={{ overflow: "hidden" }}>
                                                <AmenityPass />
                                            </Box>
                                        </Stack>

                                    </Box>
                                    <center>
                                        <Stack spacing={3} alignItems={"center"} mt={2}>
                                            <img className={classes.company_logo} src={generateImageUrl(bookingDetail?.company_logo, img_size.small_square)} alt="" />
                                            {bookingDetail?.booking_id && <QRCode
                                                value={bookingDetail?.booking_id ?? ""}
                                                size={150}
                                            />}
                                        </Stack>
                                    </center>

                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                                        <Box display="flex" alignItems="center" bgcolor={"#E8F9E8"} style={{ borderRadius: "4px" }}>
                                            <Box style={{ overflow: "hidden" }}>
                                                <CircleIcon
                                                    style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                                                />
                                            </Box>
                                            <Box flexGrow={1} padding={"8px 36px"}>
                                                <Stack alignItems={"center"}>
                                                    {
                                                        bookingDetail?.booked_slots === null ?
                                                            <Typography className={classes.qrTime}>
                                                                &#x202a;{moment(bookingDetail?.booked_date).format("YYYY-MM-DD")}&#x202c;
                                                            </Typography>
                                                            :
                                                            <Typography className={classes.qrTime}>
                                                                &#x202a;{
                                                                    bookingDetail?.period === "Daily" ?
                                                                        moment(bookingDetail?.booking_start_time).format("DD MMM YY") :
                                                                        moment(bookingDetail?.booked_slots?.[0]?.[0], "HH:mm").format("hh:mm A")}&#x202c;
                                                                {bookingDetail?.period === "Hourly" ? ` - ` : (bookingDetail?.period === "Daily" && moment(bookingDetail?.booking_start_time).format("DD MMM YY") < moment(bookingDetail?.booking_end_time).format("DD MMM YY")) && ` - `}
                                                                {
                                                                    // moment(addSeconds(new Date(moment(new Date()).format("YYYY-MM-DD") + " " +
                                                                    (bookingDetail?.period === "Daily" && moment(bookingDetail?.booking_start_time).format("DD MMM YY") < moment(bookingDetail?.booking_end_time).format("DD MMM YY")) ?
                                                                        moment(bookingDetail?.booking_end_time).format("DD MMM YY") :
                                                                        bookingDetail?.period === "Hourly" && bookingDetail?.booked_slots && getTimeWithAmPm(`${Number((bookingDetail?.booked_slots?.[bookingDetail?.booked_slots?.length - 1]?.[1]).slice(0, 2)) + 1}:00:00`.padStart(7, '0'))
                                                                    // moment(, "HH:mm:ss").format("hh:mm A")), 60)).format("hh:mm A")
                                                                }
                                                                {/* - &#x202a;{moment(bookingDetail?.booking_start_time).format("DD MMM YY")}&#x202c; */}
                                                            </Typography>
                                                    }
                                                    <Typography className={classes.qrDetail}>{t("SlotDetails")}</Typography>
                                                </Stack>
                                            </Box>
                                            <Box style={{ overflow: "hidden" }}>
                                                <CircleIcon
                                                    style={{ marginRight: "-7px", marginTop: "8px", fontSize: "1rem", color: "white" }}
                                                />
                                            </Box>
                                        </Box>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                                        <Stack className={classes.city_box} direction={"row"} spacing={1} alignItems={"center"}>
                                            <CitySvg />
                                            <Typography className={classes.city_content}>{bookingDetail?.property_name}</Typography>
                                        </Stack>
                                    </div>
                                    <Box sx={{ position: "absolute", bottom: "-21px", left: "-16px", zIndex: 999 }}>
                                        <CircleIcon
                                            style={{ fontSize: "3rem", color: "#f5f7fa" }}
                                        />
                                    </Box>
                                    <Box sx={{ position: "absolute", bottom: "-21px", right: "-16px", zIndex: 999 }}>
                                        <CircleIcon
                                            style={{ fontSize: "3rem", color: "#f5f7fa" }}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.boxBorder} pb={2} pt={2}>

                                    <Stack padding={"4px 20px"} sx={{ marginTop: "0 !important" }}>
                                        <Stack direction="row" alignItems={"center"}>
                                            <Stack width={"90%"} direction={"row"}>
                                                <Avatar className={classes.avatar} src={generateImageUrl(bookingDetail?.amenity_assets, img_size.small_square)}></Avatar>
                                                <Stack width={"80%"}>
                                                    <Stack>
                                                        <Typography className={classes.amenity_name} noWrap>{bookingDetail?.amenities_name}</Typography>
                                                        <Typography className={classes.amenity_content}>{t("Bookedon")}
                                                            &#x202a;{moment(bookingDetail?.booking_start_time).format("DD MMM YY")}&#x202c;
                                                        </Typography>
                                                    </Stack>
                                                    <Typography className={bookingDetail?.is_expired ? classes.not_booked :
                                                        bookingDetail?.booking_status === "Booked" ? classes.booked :
                                                            bookingDetail?.booking_status === "Cancelled" ? classes.cancelled :
                                                                classes.not_booked}
                                                    >
                                                        {bookingDetail?.is_expired ? "Expired" : bookingDetail?.booking_status}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                            {/* {
                                    !dontShow && */}
                                            <IconButton onClick={() => sharePass("test")} id="download">
                                                {/* <ShareIcon /> */}
                                                {/* <ShareIcon color="primary" /> */}
                                                <ShareIcons />
                                            </IconButton>
                                            {/* } */}
                                        </Stack>
                                    </Stack>

                                    <Box sx={{ position: "absolute", bottom: "-23px", left: "-12px", zIndex: 999 }}>
                                        <CircleIcon
                                            style={{ fontSize: "3rem", color: "#f5f7fa" }}
                                        />
                                    </Box>
                                    <Box sx={{ position: "absolute", bottom: "-23px", right: "-12px", zIndex: 999 }}>
                                        <CircleIcon
                                            style={{ fontSize: "3rem", color: "#f5f7fa" }}
                                        />
                                    </Box>
                                </Box>

                                <Stack spacing={2} sx={{ marginTop: "0 !important" }} pt={2}>
                                    <Stack>
                                        <BookingCard header={t("booked unit details")}
                                            url={bookingDetail?.unit_logo}
                                            name={bookingDetail?.unit_name}
                                            content={bookingDetail?.unit_no} />
                                    </Stack>
                                    <Stack>
                                        <BookingCard header={t("BOOKED BY")}
                                            url={bookingDetail?.user_profile_picture}
                                            name={bookingDetail?.user_first_name}
                                            content={bookingDetail?.mobile_country_code
                                                + " " + bookingDetail?.user_mobile_no} />
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                        <Box>
                            {
                                bookingDetail?.check_logs?.length > 0 && <>
                                    <Stack justifyContent={"center"} my={2} direction={"row"} spacing={2} alignItems={"center"}>
                                        <Box className={classes.diamond}></Box>
                                        <Typography className={classes.your_booking}>{t("YOUR ENTRIES")}</Typography>
                                        <Box className={classes.diamond}></Box>
                                    </Stack>
                                    <Box mx={1} mb={1} className={classes.checkBox}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Stack direction={"column"} spacing={"6px"}>
                                                    <Typography className={classes.checkinout}>{t("CHECKED IN")}</Typography>
                                                    <Typography className={classes.time}>
                                                        {
                                                            bookingDetail?.check_logs?.[0]?.checkin_date ?
                                                                bookingDetail?.check_logs?.[0]?.checkin_person?.first_name ?
                                                                    `By ${bookingDetail?.check_logs?.[0]?.checkin_person?.first_name},
                                            ${moment(new Date(bookingDetail?.check_logs?.[0]?.checkin_date)).format("DD MMM")},
                                            ${bookingDetail?.check_logs?.[0]?.checkin_time}` :
                                                                    "-" : "-"
                                                        }
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack direction={"column"} spacing={"6px"}>
                                                    <Typography className={classes.checkinout}>{t("CHECKED OUT")}</Typography>
                                                    <Typography className={classes.time}>
                                                        {bookingDetail?.check_logs?.[0]?.checkout_date ?
                                                            bookingDetail?.check_logs?.[0]?.checkin_person?.first_name ?
                                                                `By ${bookingDetail?.check_logs?.[0]?.checkout_person?.first_name},
                                            ${moment(new Date(bookingDetail?.check_logs?.[0]?.checkout_date)).format("DD MMM")},
                                            ${bookingDetail?.check_logs?.[0]?.checkout_time}
                                            ` :
                                                                "-" : "-"}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            }
                        </Box>

                        <Hidden smDown>
                            <Dialog
                                className={classes.dialog}
                                maxWidth="sm"
                                fullWidth
                                open={pdOpen}
                                onClose={closeDrawer}
                            >
                                <div>{CancelBookingPopup(closeDrawer, submit, remark, setRemarkValue, t)}</div>
                            </Dialog>
                        </Hidden>
                        <Hidden smUp>
                            <Drawer
                                PaperProps={{ borderRadius: '4px' }}
                                anchor="bottom"
                                className={classes.drawer}
                                open={pdOpen}
                                onClose={closeDrawer}
                            >
                                <div>{CancelBookingPopup(closeDrawer, submit, remark, setRemarkValue, t)}</div>
                            </Drawer>
                        </Hidden>
                    </Box >
                    {
                        (bookingDetail?.booking_status === "Booked" &&
                            !bookingDetail?.is_expired &&
                            !bookingDetail?.is_slot_started &&
                            bookingDetail?.check_logs?.length === 0) &&
                        <Box p={2} className={classes.cancel_btn}>
                            <Button variant="outlined" className={classes.cancelButton} fullWidth onClick={() => setCBOpen(true)}>
                                {t("CancelBooking")}
                            </Button>
                        </Box>
                    }
                </Box>
            }
        </Container >

    )
}


const CancelBookingPopup = (closeDrawer, submit, remark, setRemarkValue, t) => {
    const classes = MyBookingDetailsStyle()
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className={classes.flexWithPadding}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.modalTitle}>
                            {t("CancelBooking")}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={closeDrawer}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} height="auto">
                <CancelBooking submit={submit} remark={remark} setRemarkValue={setRemarkValue} />
            </Grid>
        </Grid>
    )
}


export default withNamespaces("amenitiesBooking")(MyBookingDetails)