import React from "react";

function RealEstate() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H21V18H0z"
            data-name="Rectangle 55520"
            transform="translate(81.699 184.39)"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 113452" transform="translate(-37.5 -88)">
        <rect
          width="42"
          height="42"
          fill="#fef2e6"
          data-name="Rectangle 57713"
          rx="4"
          transform="translate(37.5 88)"
        ></rect>
        <g data-name="Group 113441" transform="translate(6 -521)">
          <g
            clipPath="url(#clip-path)"
            data-name="Group 105881"
            transform="translate(-39.199 436.61)"
          >
            <path
              fill="#f5b977"
              d="M19.961 9.424l-.06-.047V6.053a.632.632 0 00-.632-.632h-1.685a.632.632 0 00-.632.632v1l-2-1.578a2.2 2.2 0 00-2.736 0L7.2 9.424A3.141 3.141 0 006 11.905v7.62A1.476 1.476 0 007.474 21h12.217a1.476 1.476 0 001.474-1.474v-7.62a3.141 3.141 0 00-1.204-2.482zm-8.061 6.95a.421.421 0 01-.421.421H9.791a.421.421 0 01-.421-.421v-2.528a.421.421 0 01.421-.421h1.685a.421.421 0 01.421.421zm5.9 0a.421.421 0 01-.421.421h-1.69a.421.421 0 01-.421-.421v-2.528a.421.421 0 01.421-.421h1.685a.421.421 0 01.421.421z"
              transform="translate(78.781 180.39)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RealEstate;