import * as React from "react"

const TickImageComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 7092">
      <g data-name="Group 7097">
        <rect
          data-name="Rectangle 45755"
          width={32}
          height={32}
          rx={16}
          fill="#5078e1"
        />
        <path
          d="M22.2 11.554a.711.711 0 0 0-.489.215l-8.737 8.736-3.761-3.762a.711.711 0 1 0-1 1.005l4.264 4.264a.711.711 0 0 0 1 0l9.238-9.238a.711.711 0 0 0-.515-1.22Z"
          fill="#fff"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </g>
    </g>
  </svg>
)

export default TickImageComponent