import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Profile } from "../../assets";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
  },
  Cardcontent: {
    padding: "8px 8px",
    borderBottom: `1px dashed ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "0.75rem",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
}));

export const VisiterCard = (props) => {
  const classes = useStyles(props);
  const {t}=props;

  return (
    <>
      <div className={classes.card} onClick={() => props.onClick(props.data)}>
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box>
              {
                props?.data?.is_registered_worker ?
                  (
                    props?.data?.domestic_helpers?.visitor_image ?
                      <img
                        src={generateImageUrl(props?.data?.domestic_helpers?.visitor_image, img_size.small_square)}
                        alt=""
                        style={{ objectFit: "cover", marginTop: "4px", borderRadius: "50%", height: "47px", width: "47px" }}
                      />
                      : <Profile />) :
                  (
                    props?.data?.visitor_image ?
                      <img
                        src={generateImageUrl(props?.data?.visitor_image, img_size.small_square)}
                        alt=""
                        style={{ objectFit: "cover", marginTop: "4px", borderRadius: "50%", height: "47px", width: "47px" }}
                      />
                      : <Profile />)
              }
            </Box>
            <Box flexGrow={1} sx={{ marginInlineStart: "10px" }}>
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.is_registered_worker ? props?.data?.domestic_helpers?.name : props?.data?.name}
              </Typography>
              <Box alignItems="center" display="flex" marginTop="4px">
                {props?.data?.is_registered_worker ?
                  <Box flexGrow={1}>
                    {props?.data?.domestic_helpers?.id_type ||
                      (props?.data?.domestic_helpers?.identification_no && (<>
                        <Typography variant="subtitle2" className={classes.proof}>
                          {props?.data?.domestic_helpers?.id_type && props?.data?.domestic_helpers?.id_type}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.proof}
                          noWrap
                        >
                          {props.data.domestic_helpers?.identification_no && (
                            props.data.domestic_helpers?.identification_no
                          )}
                        </Typography>
                      </>
                      ))}
                  </Box> :
                  <Box flexGrow={1}>
                    {props.data.idtype ||
                      (props.data.identification_no && (<>
                        <Typography variant="subtitle2" className={classes.proof}>
                          {props.data.idtype && props.data.idtype}
                          {props.data.id_type && props.data.id_type}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.proof}
                          noWrap
                        >
                          {props.data.identification_no && (
                            props.data.identification_no
                          )}
                        </Typography>
                      </>
                      ))}
                  </Box>}
                <Box display={props?.downlodeload && "none"}>
                  <Typography
                    variant="subtitle2"
                    className={classes.view}
                    noWrap
                  >
                    {t("View Details")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
