export const getSupportAccordian = [
    {
        accordianTitle:"Properties",
        accordianContent:[
            {
                contentTitle:"What is Price Book?",
                contentDescription:"Price Book is collection of following pricing information used in Property Management Solutio..."
            },
            {
                contentTitle:"What is Price Book?",
                contentDescription:"Price Book is collection of following pricing information used in Property Management Solutio..."
            },
            {
                contentTitle:"What is Price Book?",
                contentDescription:"Price Book is collection of following pricing information used in Property Management Solutio..."
            },
            {
                contentTitle:"What is Price Book?",
                contentDescription:"Price Book is collection of following pricing information used in Property Management Solutio..."
            },

        ]

    },
    {
        accordianTitle:"Lease and Sales",
        accordianContent:[
            {
                contentTitle:"What is Price Book?",
                contentDescription:"Price Book is collection of following pricing information used in Property Management Solutio..."
            }

        ]

    },
    {
        accordianTitle:"Solutions",
        accordianContent:[
            {
                contentTitle:"What is Price Book?",
                contentDescription:"Price Book is collection of following pricing information used in Property Management Solutio..."
            }

        ]

    },
]
