import { Avatar, Box, Divider, Grid, Radio, Stack, Typography } from "@mui/material"
import React from "react";
import PassDetails from "../../assets/passDetails"
import PassUnits from "../../assets/passUnits";
import { DatePickerNew, DialogDrawer, TextBox } from "../../components";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AddVisitorPassContext } from "./addVisitorPassContext";
import NoDataFound from "../../components/noDataFound/noDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import { entryType } from "./utils";
import { withNamespaces } from "react-i18next";

const Step1 = () => {
    const {
        t = () => false,
        agreementList,
        updateState,
        data,
        gateList,
        fetchMoreGateList,
        unitDrawer,
        setUnitDrawer,
        gateDrawer,
        setGateDrawer,
        chooseGate,
        classes,
    } = React.useContext(AddVisitorPassContext);

    return (
        <>
            <div style={{ padding: "12px 7px 0px 12px" }}>
                <Box className={classes.visiterJourney}>
                    <div className={classes.innerPadding}>
                        {/* PASS DETAILS */}
                        <Stack direction={"row"}>
                            <Avatar variant="rounded" className={classes.avatar}>
                                <PassDetails />
                            </Avatar>
                            <Stack sx={{ marginInlineStart: "12px" }} direction={"column"} spacing={"4px"}>
                                <Typography className={classes.title}>{t("Pass Details")}</Typography>
                                <Typography className={classes.step}>{t("STEP")} 1/2</Typography>
                            </Stack>
                        </Stack>
                        <div className={classes.borederBottom}></div>
                        {/* UNIT NUMBER */}
                        <Box className={classes.input}>
                            <div className={classes.unitBox}>
                                <Stack direction={"row"} alignItems="center">
                                    <Avatar variant="rounded" className={classes.unitAvatar}>
                                        <PassUnits />
                                    </Avatar>
                                    <Box sx={{ marginInlineStart: "12px" }} flexGrow={1}>
                                        <Stack direction={"column"} spacing={"4px"}>
                                            {console.log("data", data)}
                                            <Typography className={classes.unitNumber}>{data?.request_details?.unit?.unit_no}</Typography>
                                            <Typography className={classes.unitName}>{data?.request_details?.unit?.unit_type} {"•"} {data?.request_details?.unit?.property_name}</Typography>
                                        </Stack>
                                    </Box>
                                    {agreementList?.length > 1 &&
                                        <Typography onClick={setUnitDrawer} className={classes.change}>{t("Change")}</Typography>}
                                </Stack>
                            </div>
                        </Box>
                        {/* VISITOR ENTRY TYPE */}
                        <Box className={classes.input}>
                            <Typography mb={"8px"} className={classes.label}>{t("Visitor Entry Type")}</Typography>
                            <Stack direction={"column"} className={classes.stayContainer}
                                divider={<Divider variant="fullWidth" />}>
                                {entryType.map((_) => {
                                    return (
                                        <Stack direction={"row"} alignItems="start" padding="16px 12px" style={{ display: "flex", width: "inherit" }}>
                                            <Radio
                                                checked={data?.request_details?.entry_type?.value === _?.value}
                                                onClick={() => updateState("request_details", { _k: "entry_type", _v: { label: _?.label, value: _?.value } })}
                                                style={{ padding: "0px" }} size="small" />
                                            <Stack sx={{ marginInlineStart: "8px" }} direction={"column"} spacing={"4px"} width="100%">
                                                <Typography className={classes.label}>{t(_?.label)}</Typography>
                                                <Typography className={classes.info}>{t(_?.description)}</Typography>
                                                {data?.request_details?.entry_type?.value === _?.value &&
                                                    <Box flexGrow={1}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={6}>
                                                                <DatePickerNew
                                                                    isRequired
                                                                    dateFormat={"dd-MMM-yy"}
                                                                    placeholder={_?.value === "single" ? t("Select Visit Date") : t("Start Date")}
                                                                    value={data?.request_details?.start_date ? data?.request_details?.start_date : new Date()}
                                                                    onChange={(value) => updateState("request_details", { _k: "start_date", _v: value })}
                                                                    isError={data?.request_details?.error?.start_date?.length > 0}
                                                                    errorMessage={data?.request_details?.error?.start_date}
                                                                    minDate={new Date()}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <DatePickerNew
                                                                    isRequired
                                                                    dateFormat={"dd-MMM-yy"}
                                                                    placeholder={t("VisitEndDate")}
                                                                    value={data?.request_details?.end_date ? data?.request_details?.end_date : new Date()}
                                                                    minDate={new Date(data?.request_details?.start_date)}
                                                                    onChange={(value) => updateState("request_details", { _k: "end_date", _v: value })}
                                                                    isError={data?.request_details?.error?.end_date?.length > 0}
                                                                    errorMessage={data?.request_details?.error?.end_date}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                }
                                            </Stack>
                                        </Stack>
                                    )
                                })}
                            </Stack>
                        </Box>

                        {/* ACCESS GATE */}
                        <Box className={classes.input}>
                            <Typography my={"8px"} className={classes.label}>
                                {t("Preferred Access Gate")}
                            </Typography>
                            <Stack direction={"row"}>
                                <Typography
                                    onClick={() => updateState("request_details", { _k: "is_any_gate", _v: true })}
                                    className={data?.request_details?.is_any_gate ?
                                        classes.gateButtonActive : classes.gateButtonInactive}>
                                    <Stack direction={"row"} spacing={"5px"} alignItems={"center"}>
                                        {t("Any Gate")}
                                    </Stack>
                                </Typography>
                                <Typography
                                    sx={{ marginInlineStart: "8px" }}
                                    onClick={chooseGate}
                                    className={data?.request_details?.gate?.value ?
                                        classes.gateButtonActive : classes.gateButtonInactive}>
                                    <Stack direction={"row"} spacing={"5px"} alignItems={"center"}>
                                        {data?.request_details?.gate?.label ?? t("Choose Gate")}
                                        {<KeyboardArrowDownIcon />}
                                    </Stack>
                                </Typography>
                            </Stack>
                        </Box>
                        {/* NUMBER OF VISITORS */}
                        <Typography mt={"16px"} mb={"8px"} className={classes.label}>
                            {t("Number Of Visitors")}
                        </Typography>
                        <Grid container>
                            <Grid item sm={3} xs={4}>
                                <TextBox
                                    onChange={(v) => updateState("request_details", { _k: "no_of_visitors", _v: v?.target?.value })}
                                    endAdornment={t("Person")}
                                    color="#4E5A6B"
                                    type="number"
                                    value={data?.request_details?.no_of_visitors}
                                    isError={data?.request_details?.error?.no_of_visitors?.length > 0}
                                    errorMessage={data?.request_details?.error?.no_of_visitors} />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                {/* UNIT DRAWER */}
                <DialogDrawer
                    open={unitDrawer}
                    onClose={() => setUnitDrawer(false)}
                    maxWidth="sm"
                    header={t("Unit Switcher")}
                    component={
                        <>
                            <Box className={classes.box}>
                                {agreementList?.map((value) => {
                                    return (
                                        <>
                                            <Stack my={"12px"} direction={"row"} alignItems="center">
                                                <Avatar variant="rounded" className={classes.unitAvatar}>
                                                    <PassUnits />
                                                </Avatar>
                                                <Box sx={{ marginInlineStart: "12px" }} flexGrow={1}>
                                                    <Stack direction={"column"} spacing={"4px"}>
                                                        <Typography className={classes.unitNumber}>{value?.label}</Typography>
                                                        <Stack direction={"row"} alignItems="center" spacing={"6px"} divider={<Box className={classes.dot}></Box>}>
                                                            <Typography className={classes.unitName}>{value?.property_name}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                                <Radio
                                                    checked={data?.request_details?.unit?.value === value?.value}
                                                    onClick={() => {
                                                        updateState("request_details", { _k: "unit", _v: value })
                                                        setUnitDrawer(false)
                                                    }
                                                    } sx={{ color: "#CED3DD" }} value={value} />
                                            </Stack>
                                            <Box className={classes.border} />
                                        </>
                                    )
                                })}
                            </Box>
                        </>
                    }
                />
                {/* GATE DRAWER */}
                <DialogDrawer
                    open={gateDrawer}
                    onClose={() => setGateDrawer(false)}
                    maxWidth="sm"
                    header={t("Choose Gate")}
                    component={
                        <Box className={classes.listContainer}>
                            {gateList?.length > 0 ? (
                                <InfiniteScroll
                                    dataLength={gateList.length}
                                    next={fetchMoreGateList}
                                    hasMore={true}
                                    height={500}>
                                    <Box height={"16px"} />
                                    <Box className={classes.box}
                                    >
                                        {gateList?.map((gate) => (
                                            <Stack direction={"row"} alignItems="center" justifyContent="space-between">
                                                <Typography>{gate?.label}</Typography>
                                                <Radio checked={data?.request_details?.gate?.value === gate?.value}
                                                    onClick={() => {
                                                        updateState("request_details", { _k: "gate", _v: gate })
                                                        setGateDrawer(false)
                                                    }
                                                    }
                                                    sx={{ color: "#CED3DD" }} />
                                            </Stack>
                                        ))}
                                    </Box>
                                </InfiniteScroll>
                            ) : (
                                <NoDataFound
                                    height={245}
                                    content={t("No Data Found!")}
                                />
                            )}
                        </Box>
                    }
                />
            </div>
        </>
    )
}


export default withNamespaces("visitorPass")(Step1);