
import { Box } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../../config";
import { QUOTATIONSTATUS } from "../../../graphql/mutations";
import { NetworkCall } from "../../../networkcall/index";
import { Routes } from "../../../router/routes";
import { NetWorkCallMethods } from "../../../utils";
import { Body } from "./formgenerator";
const initialState = {
    date: "",
    text: "",
}
export const StatusFrom = ({ t = () => false, details = {}, quotation_id = "", headers = {} }) => {
    const [data, setData] = React.useState({ ...initialState })
    const history = useHistory()
    const updateState = (key, value) => {
        setData({ ...data, [key]: value });
    };

    const statusSubmit = (key) => {
        const variable = {
            query: QUOTATIONSTATUS,
            variables: {
                id: quotation_id,
                update:
                {

                    notes: data?.text,
                    quote_status: details?.value,
                    updated_at: new Date().toISOString(),
                },
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            variable,
            headers,
            false,
            false
        )
            .then((response) => {
                history.push({
                    pathname: Routes.success,
                    state: {
                        customLabel: t("Request Processed"),
                    },
                });
            })
            .catch((error) => {
                console.log(error)
            });
    };

    const formData = [
        {
            isActive: details?.id === 2 ? true : false,
            component: "date",
            label: t("Visit Date and Time"),
            value: data?.date,
            onChange: (value) => updateState("date", value),
            placeholder: t("Visit Date and Time"),
        },
        {
            isActive: true,
            component: "text",
            label: t("Remarks (Optional)"),
            value: data?.text,
            multiline: true,
            onChange: (value) => updateState("text", value.target.value),
            placeholder: t("Remarks"),
        },
        {
            isActive: true,
            component: "button",
            label: t("Submit"),
            onClick: () => statusSubmit()
        },


    ]

    return (
        <Box>
            <Body components={formData} />
        </Box>
    )
}