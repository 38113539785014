import { useApolloClient } from "@apollo/client";
import React from "react";
import { withNamespaces } from "react-i18next";
import { config } from "../../../config";
import { AlertContext, BackdropContext } from "../../../contexts";
import { GET_GATES, GET_PROPERTY_UNITID } from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { useHistory, useLocation } from "react-router-dom";
// import { config } from "../../../config";
// import { AlertContext } from "../../../contexts";
// import { NetworkCall } from "../../../networkcall";
// import { AlertProps, NetWorkCallMethods } from "../../../utils";
export const WorkerContext = React.createContext();
const select_workers_initial = {
  workerType: {
    label: "New Worker",
    value: "New Worker",
  },
  workerProfile: "",
  workerIdProof: [],
  workerName: "",
  workerGender: "",
  workerId: "",
  idNumber: "",
  reg_worker_profiles:[],
  groupMember: true,
  is_edited: false,
  is_updated:false,
  is_selected: true,
  view_type: "edit",
  previous_state: "",
  error: {
    workerProfile: "",
    workerIdProof: "",
    workerName: "",
    workerGender: "",
    workerId: "",
    idNumber: "",
    groupMember: "",
  },
};
const InitialState = {
  unit_and_schedule: {
    unit: "",
    entryType: {
      value: "single",
      label: "Single",
    },
    stayType: {
      value: "Short",
      label: "One Day",
    },
    startDate: new Date(),
    endDate: new Date(),
    workFrom: "02",
    workTo: "10",
    workFromTime: "AM",
    workToTime: "PM",
    group: false,
    groupCount: "",
    registeredWorker: true,
    is_edited: false,
    is_selected: true,
    view_type: "edit",
    previous_state: "",
    error: {
      unit: "",
      entryType: "",
      stayType: "",
      startDate: "",
      endDate: "",
      workFrom: "",
      workTo: "",
      group: "",
      registeredWorker: "",
    },
  },
  //select wprkers
  select_workers: [],
  select_workers_object: {
    view_type: "hide",
  },
  //worker info
  worker_info: {
    workDescription: "",
    gateAccess: {
      value: "Any",
      label: "Any",
    },
    tenantImprovement: true,
    facilittiesStaff: true,
    individualAccess: false,
    noisy: false,
    hazardous: true,
    flames: true,
    vendorSafty: true,
    is_edited: false,
    is_selected: true,
    is_updated:false,
    view_type: "hide",
    previous_state: "",
    error: {
      workDescription: "",
      gateAccess: "",
      tenantImprovement: "",
      facilittiesStaff: "",
      individualAccess: "",
      noisy: "",
      hazarodus: "",
      flames: "",
      vendorSafty: "",
    },
  },
};
const WorkerContextProvider = (props) => {
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const client = useApolloClient();
  const history = useHistory();
  const { t=()=>false } = props;
  const [data, setData] = React.useState(InitialState);
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const [activeStep, setActiveStep] = React.useState(0);
  const [agreementList, setAgreementLists] = React.useState([]);
  const [regWorkerList, setRegWorkerLists] = React.useState([]);
  const [property, setProperty] = React.useState({});
  const [openConfirmationDrawer, setOpenConfirmationDrawer] = React.useState(false);
  const [gates, setGates]= React.useState([{
    value: "Any",
    label: "Any",
  }]);
  const [progress, setProgress] = React.useState({
    unitAndScheduleProgress: 0,
    selectWorkerProgress: 0,
    workerInfoProgress: 0,
  });

  React.useEffect(() => {
    if (passId) {
      getWorkerDetails(passId);
    }

    // eslint-disable-next-line
  }, [passId])


  const [completedSteps, setCompletedSteps] = React.useState([]);
  const [editStep, setEditStep] = React.useState(0);
  // const alert = React.useContext(AlertContext);
  const updateWorkerArray = (array, index, k, v) => {
    let temp_visitors = [];

    for (let i = 0; i < array?.length; i++) {
      temp_visitors = [
        ...temp_visitors,
        i === index
          ? { ...array[i], [k]: v, error: { ...array[i]?.error, [k]: "" } }
          : { ...array[i] },
      ];
    }

    return temp_visitors;
  };
  const updateState = (object, key, value, index) => {
    switch (object) {
      case "unit_and_schedule":
        {
          if (key === "unit") {
            getpropertyUnitid(value)
          }
          let error = data?.unit_and_schedule?.error;
          error[key] = "";
          setData({
            ...data,
            unit_and_schedule: {
              ...data?.unit_and_schedule,
              [key]: value, is_updated:true,
              error,
            },
          });

          setProgress({
            ...progress,
            unitAndScheduleProgress: getProgress({
              ...data?.unit_and_schedule,
              [key]: value,
            }),
          });
        }
        break;

      case "select_workers":
        {
          let temp_selected_worker = data?.select_workers;

          let temp_selected_worker_state = updateWorkerArray(
            temp_selected_worker,
            index,
            key,
            value
          );
          setData({ ...data, [object]: temp_selected_worker_state });
          setProgress({
            ...progress,
            selectWorkerProgress: getProgress({
              ...data?.select_workers[index],
              [key]: value,
            }),
          });
        }
        break;
      case "worker_info":
        {
          let error = data?.worker_info?.error;
          error[key] = "";
          setData({
            ...data,
            worker_info: { ...data?.worker_info, [key]: value,  is_updated:true, error },
          });
          setProgress({
            ...progress,
            workerInfoProgress: getProgress({
              ...data?.worker_info,
              [key]: value,
            }),
          });
        }
        break;
      default:
        break;
    }
  };
  const getProgress = (args) => {
    const total = Object.keys(args).length;
    let progress = 0;
    Object.values(args).map((value) => {
      if (value) {
        progress++;
      }
      return progress;
    });
    const totalProgress = (progress / total) * 100;
    return totalProgress;
  };
  const addWorker = () => {
    setData({
      ...data,
      select_workers_object: { view_type: "edit" },
      select_workers: [...data?.select_workers, select_workers_initial],
    });
  };
  const handelselectStep = (id) => {
    if (id === "unit_and_schedule") {
      updateState("unit_and_schedule", "view_type", "edit");
    } else if (id === "select_workers") {
      // updateState("select_workers_object", "view_type", "edit");
      addWorker();
    } else {
      updateState("worker_info", "view_type", "edit");
    }
  };
  //form validations
  const unitAndScheduleFormValidation = () => {
    let isValid = true;
    let error = data?.unit_and_schedule?.error;
    const unitSchedule = data?.unit_and_schedule;

    if (unitSchedule?.unit?.length === 0) {
      isValid = false;
      error.unit = t("Unit is Required");
    }

    if (unitSchedule?.entryType?.length === 0) {
      isValid = false;
      error.entryType = t("Entry Type is Required");
    }

    if (unitSchedule?.stayType?.length === 0) {
      isValid = false;
      error.stayType = t("Stay Type is Required");
    }

    if (unitSchedule?.workFrom?.length === 0) {
      isValid = false;
      error.workFrom = t("Work From is Required");
    }
    if (unitSchedule?.workTo?.length === 0) {
      isValid = false;
      error.workTo = t("Work To is Required");
    }
    if (unitSchedule?.group?.length === 0) {
      isValid = false;
      error.group = t("Group is Required");
    }
    //Checking Start Date
    if (unitSchedule?.startDate?.length === 0) {
      isValid = false;
      error.startDate = t("Start Date is Required");
    }
    // //Checking End Date
    if (unitSchedule?.stayType?.value === "Long") {
      if (unitSchedule?.endDate?.length === 0) {
        isValid = false;
        error.endDate = t("End Date is Required");
      }
    } else {
      error.endDate = "";
    }

    if (unitSchedule?.group) {
      if (Number(unitSchedule?.groupCount) <= 1) {
        isValid = false;
        error.groupCount = t("Group Count is Required");
      }
    } else {
      error.groupCount = "";
    }

    //Checking Gate

    // let initialDate = moment(unitSchedule.startDate);
    // if (unitSchedule?.stayType === "Period") {
    //   let endDate = moment(unitSchedule.endDate);
    //   if (endDate?.diff(initialDate) >= 0) {
    //   } else {
    //     isValid = false;
    //     error.endDate = t("EndDateisMorethenStartDate");
    //   }
    // }
    setData({
      ...data,
      unit_and_schedule: { ...data.unit_and_schedule, error },
    });
    return isValid;
  };
  const selectWorkerFormValidation = (index) => {
    let isValid = true;

    let temp_worker_details = data?.select_workers;
    let selectWorkers = temp_worker_details?.[index];
    let error = selectWorkers?.error;

    // if (selectWorkers?.workerProfile?.length === 0) {
    //   isValid = false;
    //   error.workerProfile = t("Worker Profile is Required");
    // }

    // if (selectWorkers?.workerIdProof?.length === 0) {
    //   isValid = false;
    //   error.workerIdProof = t("Worker Proof Id is Required");
    // }
  if(selectWorkers?.workerTyp?.value === "New Worker"){
    if (selectWorkers?.workerName?.length === 0) {
      isValid = false;
      error.workerName = t("Worker Name is Required");
    }

    if (selectWorkers?.workerGender.length === 0) {
      isValid = false;
      error.workerGender = t("Worker Gender is Required");
    }
    if (selectWorkers?.workerId?.length === 0) {
      isValid = false;
      error.workerId = t("Work ID Type is Required");
    }
    if (selectWorkers?.idNumber?.length === 0) {
      isValid = false;
      error.idNumber = t("ID Number is Required");
    }
  }
    setData({
      ...data,
      select_workers: { ...data.select_workers, error },
    });

    let temp_visitors = updateWorkerArray(
      temp_worker_details,
      index,
      "error",
      error
    );

    setData({ ...data, visitor_details: temp_visitors });

    return isValid;
  };
  const workerInfoFormValidation = () => {
    let isValid = true;
    let error = data?.worker_info?.error;
    const workerInfo = data?.worker_info;

    if (workerInfo?.workDescription?.length === 0) {
      isValid = false;
      error.workDescription = t("Worker Description is Required");
    }

    if (workerInfo?.gateAccess?.length === 0) {
      isValid = false;
      error.gateAccess = t("Gate Access is Required");
    }

    setData({
      ...data,
      worker_info: { ...data.worker_info, error },
    });
    return isValid;
  };
  const UnitAndScheduleFormSubmit = () => {
    if (unitAndScheduleFormValidation()) {
      updateState("unit_and_schedule", "view_type", "view");
    }
  };
  const selectWorkerFormSubmit = (index) => {
    if (selectWorkerFormValidation(index)) {
      updateState("select_workers", "view_type", "view", index);
      // setCompletedSteps([...completedSteps, "unit_and_schedule"]);
    }
  };
  const workerInfoFormSubmit = () => {
    if (workerInfoFormValidation()) {
      updateState("worker_info", "view_type", "view");
    } 
  };
  const submitWorker = () => {
    createWorker(getPayload());
  };
  const editSelectedWorker = (index) => {
    let selectedWorker = [];

    for (let i = 0; i < data?.select_workers?.length; i++) {
      selectedWorker = [
        ...selectedWorker,
        i === index
          ? {
            ...data?.select_workers[i],
            previous_state: data?.select_workers[i],
            view_type: "edit",
          }
          : { ...data?.select_workers[i] },
      ];
    }
    setData({ ...data, select_workers: selectedWorker });
  };
  const getPayload = () => {
    let temp_payload = {
      tenantId: `${config.tenantid}`,
      pass_type: "WGR",
      visitor_type: "Worker",
      stay_type: data?.unit_and_schedule?.stayType?.value,
      start_date: data?.unit_and_schedule?.startDate,
      end_date: data?.unit_and_schedule?.stayType?.value === "Long" ? data?.unit_and_schedule?.endDate : data?.unit_and_schedule?.startDate,
      work_hour_end: conver12HrTo24Hr(`${data?.unit_and_schedule?.workFrom}:00 ${data?.unit_and_schedule?.workFromTime}`),// data?.unit_and_schedule?.workTo,
      work_hour_start:conver12HrTo24Hr(`${data?.unit_and_schedule?.workTo}:00 ${data?.unit_and_schedule?.workToTime}`), //data?.unit_and_schedule?.workFrom,
      entry_type: data?.unit_and_schedule?.entryType?.value,
      gate_no: data?.worker_info?.gateAccess?.value === "Any" ? undefined : data?.worker_info?.gateAccess?.value,
      new_worker_profiles: data?.select_workers?.filter(({workerType})=>workerType?.value === "New Worker").map((worker) => {
        return {
          name: worker?.workerName,
          gender: worker?.gender?.value,
          mobile_no: "",
          mobile_country_code: "",
          email_id: worker?.gender,
          visitor_image: worker?.workerProfile,
          id_type:worker?.workerId?.value,
          identification_no: worker?.idNumber,
          worker_proof: worker?.workerIdProof,
        }
      }),
      reg_worker_profiles:  data?.select_workers?.filter(({workerType})=>workerType?.value === "Registered Worker").map((reg_worker) => {
        return reg_worker?.reg_worker_profiles?.[0]?.helper_id
      }),
      property_id: property?.id,
      unit_id: data?.unit_and_schedule?.unit?.unit_id,
      agreement_unit_id: data?.unit_and_schedule?.unit?.value,
      pass_class: data?.unit_and_schedule?.group === true ? "Group" : "Individual",
      group_count: data?.groupCount,
      work_info: [
        {
          description: data?.worker_info?.workDescription,
          qna:{
            tenantImprovement: data?.worker_info?.tenantImprovement,
            facilittiesStaff: data?.worker_info?.facilittiesStaff,
            individualAccess: data?.worker_info?.individualAccess,
            noisy: data?.worker_info?.noisy,
            hazardous: data?.worker_info?.hazardous,
            flames: data?.worker_info?.flames,
            vendorSafty: data?.worker_info?.vendorSafty,
          }
        },
      ],
    };
    return temp_payload;
  };
  const getUnitIDS = () => {
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        // let data = response?.data?.data?.map((x) =>{ ...x?.agreement_unit, }).flat();
        const _tempUnitList =  response?.data?.data?.map((_uitem) => {
          let _u;
          _u = {
            label: _uitem?.agreement_unit?.[0]?.name ?? "",
            value: _uitem?.agreement_unit?.[0]?.id ?? "",
            unit_id: _uitem?.agreement_unit?.[0]?.unit_id ?? "",
            email:_uitem?.unit_contact?.email_id ?? "",
            mobile:`${_uitem?.unit_contact?.mobile_no_country_code} ${_uitem?.unit_contact?.mobile_no}` ,
            name:_uitem?.unit_contact?.first_name ?? "",
            profile:_uitem?.agreement_unit?.[0]?.unit_assets?.[0] ?? "",
          };

          return _u;
        });
        setAgreementLists(_tempUnitList);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getpropertyUnitid = (v) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    client
      .query({
        query: GET_PROPERTY_UNITID(),
        fetchPolicy: "network-only",
        variables: {
          unit_id: [v?.unit_id],
          offset: 0,
          limit: 10,
          search: "",
        },
      })
      .then((res) => {
        setProperty(res?.data?.unit?.[0]?.property);
        getGate(res?.data?.unit?.[0]?.property);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch(() => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const createWorker = (payload) => {

    NetworkCall(
      `${config.api_url}/security/upsert_security_worker`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Pass Successfully Updated"),
        });
        history.goBack(-1)
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  // GET WORKER DETAILS

  const getWorkerDetails = (passId) => {
    const payload = {
      tenant: `${config.tenantid}`,
      request_id: passId
    }
    NetworkCall(
      `${config.api_url}/security/view_worker_request`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        const temp = response?.data?.data[0];
        console.log(temp, "temp")
        updateAllForms(temp);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };



  const updateAllForms = (temp) => {
    const InitialState_request = {
      unit_and_schedule: {

        unit: {
          label: temp?.unit?.[0]?.name,
          value: temp?.unit_contact?.[0]?.id,
          unit_id: temp?.unit?.[0]?.id,
          email: temp?.unit_contact?.[0]?.email_id,
          mobile: temp?.unit_contact?.[0]?.mobile_no,
          name: temp?.unit_contact?.[0]?.first_name,
          profile: temp?.unit?.[0]?.unit_assets?.[0]?.url
        },
        entryType: {
          value: temp?.entry_type,
          label: temp?.entry_type,
        },
        stayType: {
          value: temp?.stay_type,
          label: temp?.stay_type,
        },
        startDate: new Date(temp?.request_from),
        endDate: new Date(temp?.request_to),
        workFrom: "02",
        workTo: "10",
        workFromTime: "PM",
        workToTime: "AM",
        group: temp?.group,
        groupCount: temp?.groupCount,
        registeredWorker: true,
        is_edited: false,
        is_selected: true,
        view_type: "view",
        previous_state: "",
        error: {
          unit: "",
          entryType: "",
          stayType: "",
          startDate: "",
          endDate: "",
          workFrom: "",
          workTo: "",
          group: "",
          registeredWorker: "",
        },
      },
      //select workers
      select_workers_object: {
        view_type: "view",
      },
      //worker info
      worker_info: {
        workDescription: temp?.work_info?.[0]?.description,
        gateAccess: {
          value: "Any",
          label: "Any",
        },
        tenantImprovement: temp?.work_info?.[0]?.qna?.tenantImprovement,
        facilittiesStaff: temp?.work_info?.[0]?.qna?.facilittiesStaff,
        individualAccess: temp?.work_info?.[0]?.qna?.individualAccess,
        noisy: temp?.work_info?.[0]?.qna?.noisy,
        hazardous: temp?.work_info?.[0]?.qna?.hazardous,
        flames: temp?.work_info?.[0]?.qna?.flames,
        vendorSafty: temp?.work_info?.[0]?.qna?.vendorSafty,
        is_edited: false,
        is_selected: true,
        is_updated: false,
        view_type: "view",
        previous_state: "",
        error: {
          workDescription: "",
          gateAccess: "",
          tenantImprovement: "",
          facilittiesStaff: "",
          individualAccess: "",
          noisy: "",
          hazarodus: "",
          flames: "",
          vendorSafty: "",
        },
      },
      select_workers: temp?.security_visitors?.map((worker) => {
        console.log(worker, "worker")
        return {
          workerType: {
            label: worker?.identification?.label,
            value: worker?.identification?.value,
          },
          workerProfile: worker?.registered_worker,
          workerIdProof: [],
          workerName: worker?.name,
          workerGender: worker?.gender,
          workerId: worker?.id,
          idNumber: worker?.identification_no,
          reg_worker_profiles: [],
          groupMember: true,
          is_edited: false,
          is_updated: false,
          is_selected: true,
          view_type: "view",
          previous_state: "",
          error: {
            workerProfile: "",
            workerIdProof: "",
            workerName: "",
            workerGender: "",
            workerId: "",
            idNumber: "",
            groupMember: "",
          },
        }
      }),
    }
    const allState = { ...InitialState_request }
    setData(allState);
  }

  const get_reg_worker_profiles = ({ search, offset, is_concate, propertyId }) => {

    let payload = {
      property_id: [propertyId],
      search: search ?? "",
      limit: 10,
      offset: offset ?? 0,
    }
    NetworkCall(
      `${config.api_url}/domestic_helper/helper_masters_list`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (is_concate) {
          setRegWorkerLists([{ ...regWorkerList, ...response?.data?.data?.helpers }]);
        } else {
          setRegWorkerLists(response?.data?.data?.helpers);
        }
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };




const conver12HrTo24Hr = (normalTime)=>{
//   const timeFrom = `${data?.unit_and_schedule?.workFrom}:00 ${data?.unit_and_schedule?.workFromTime}`;
// const timeTo =  `${data?.unit_and_schedule?.workTo}:00 ${data?.unit_and_schedule?.workToTime}`;
 
   const [time, modifier] = normalTime.split(' ');
   let [hours, minutes] = time.split(':');
   if (hours === '12') {
      hours = '00';
   }
   if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
   }
   return `${hours}:${minutes}:00`;
 
}
const getGate = async (property)=>{
  const result = await client.query({
     query: GET_GATES(),
     variables: {
       property_id: property?.id,
       offset:0,
       limit: 10,
       search:"",
     },
   });
   setGates([{
     value: "Any",
     label: "Any",
   }, ...result?.data?.access_gates_master])

 }
  return (
    <WorkerContext.Provider
      value={{
        t,
        data,
        setData,
        updateState,
        activeStep,
        setActiveStep,
        completedSteps,
        setCompletedSteps,
        editStep,
        setEditStep,
        UnitAndScheduleFormSubmit,
        selectWorkerFormSubmit,
        workerInfoFormSubmit,
        progress,
        submitWorker,
        handelselectStep,
        addWorker,
        editSelectedWorker,
        getUnitIDS,
        agreementList,
        property,
        regWorkerList, setRegWorkerLists,
        setAgreementLists,openConfirmationDrawer, setOpenConfirmationDrawer,
        get_reg_worker_profiles,
        gates,
      }}
    >
      {props.children}
    </WorkerContext.Provider>
  );
};

export default withNamespaces("workerPermit")(WorkerContextProvider);
