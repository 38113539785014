import React from "react";
import  AmenitiesDetails from "./amenitiesDetails";
import { withBottombar } from "../../HOCs";

class AmenitiesDetailsParent extends React.Component {
    render() {
        return <AmenitiesDetails {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(AmenitiesDetailsParent,props);
