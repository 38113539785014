import * as React from "react"

export const Property = (props) => (
    <svg
        height={512}
        viewBox="0 0 59 59"
        width={512}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="#000" fillRule="nonzero">
            <path d="m43.954 48.208 4.242-4.242 2.828 2.828-4.242 4.242zM58.12 53.88l-5.67-5.67-4.24 4.24 5.67 5.67a3 3 0 0 0 4.24 0 3 3 0 0 0 0-4.24zM46.79 42.55l-4.24 4.24-2.42-2.42a25.77 25.77 0 0 0 4.24-4.24zM13 34V21.5l-.4.3a1 1 0 0 1-1.2-1.6l12-9a1 1 0 0 1 1.2 0l12 9a1 1 0 0 1-1.2 1.6l-.4-.3V34h5.13A19 19 0 1 0 5 24c0 3.536.995 7.001 2.87 10z" />
            <path d="M33 34V20l-9-6.75L15 20v14h3v-9a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v9z" />
            <path d="M20 25h4v9h-4zM9.29 36a19 19 0 0 0 29.42 0z" />
            <path d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24A24 24 0 0 0 24 0zm0 45C12.402 45 3 35.598 3 24S12.402 3 24 3s21 9.402 21 21-9.402 21-21 21z" />
        </g>
    </svg>
)