import * as React from "react";

const CheckIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g
        id="Group_107152"
        data-name="Group 107152"
        transform="translate(-28 -273)"
      >
        <rect
          id="Rectangle_55704"
          data-name="Rectangle 55704"
          width="32"
          height="32"
          rx="4"
          transform="translate(28 273)"
          fill="#f1f7ff"
        />
        <path
          id="icons8-user-clock_2_"
          data-name="icons8-user-clock (2)"
          d="M8.6,3a3.721,3.721,0,0,0,0,7.442h.046A3.721,3.721,0,0,0,8.645,3H8.6Zm6.308,7.814A4.093,4.093,0,1,0,19,14.907,4.093,4.093,0,0,0,14.907,10.814ZM4.385,11.93A1.437,1.437,0,0,0,3,13.41v.566a3.456,3.456,0,0,0,1.777,2.987,6.994,6.994,0,0,0,3.618.92,7.674,7.674,0,0,0,2.43-.387A4.815,4.815,0,0,1,11.1,11.93Zm10.15.372a.372.372,0,0,1,.372.372v2.233h1.86a.372.372,0,0,1,0,.744H14.535a.372.372,0,0,1-.372-.372v-2.6A.372.372,0,0,1,14.535,12.3Z"
          transform="translate(33 278)"
          fill="#78b1fe"
        />
      </g>
    </svg>
  );
};

export default CheckIn;
