import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";



export const ContactUs = ({
    icon = "",
    title = "",
    subTitle = "",
    btnText = "",
    onClick = () => false,
    number = "",
    call = false
}) => {

 const classes= useStyles();
    return (

        <Box className={classes?.drawerCompBox}>
            {call && call ?
            // with call
                <Stack>
                    <Stack direction={"row"} alignItems={"center"} >
                        {icon}
                        <Stack sx={{ marginInlineStart: "16px" }}>
                            <Typography className={classes?.drawerCompTitle}>{title}</Typography>
                            <Typography className={classes?.drawerCompsubTitle}>{subTitle}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} marginTop={"18px"}>
                        <Typography className={classes?.numberText}>{number}</Typography>
                        <Button className={classes?.containedBtn} variant="contained" onClick={onClick ? onClick : false}>{btnText}</Button>
                    </Stack>
                </Stack> 
                :
                // without call
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Stack direction={"row"} alignItems={"center"}>
                        {icon}
                        <Stack sx={{ marginInlineStart: "16px" }}>
                            <Typography className={classes?.drawerCompTitle}>{title}</Typography>
                            <Typography className={classes?.drawerCompsubTitle}>{subTitle}</Typography>
                        </Stack>
                        
                    </Stack>
                    <Button className={classes?.containedBtn} variant="contained" onClick={onClick ? onClick : false}>{btnText}</Button>
                    
                </Stack>
            }

        </Box>

    );
};
