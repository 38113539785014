import { LocalStorageKeys, AlertProps, NetWorkCallMethods } from "../utils";
import { NetworkCall } from "../networkcall";
import { config } from "../config";
import moment from "moment";
import { add } from "date-fns";
import React from "react";
import Compressor from "compressorjs";
import { updateDeviceToken } from "../graphql/mutations";
import Transportation from "../assets/transportation";
import Debt from "../assets/debt";
import StatsClothing from "../assets/statsClothing";
import Education from "../assets/education";
import Entertainment from "../assets/entertainment";
import Food from "../assets/food";
import Gift from "../assets/gift";
import Household from "../assets/household";
import RealEstate from "../assets/realEstate";
import StatsInsurance from "../assets/statsInsurance";
import Medical from "../assets/medical";
import Retirement from "../assets/retirement";
import Bonds from "../assets/bonds";
import MutualFund from "../assets/mutualFund";
import FixedDeposit from "../assets/fixedDeposit";
import StocksAndEquities from "../assets/stocksAndEquities";

export const mapResult = (map) => {
  let updateData = {
    latitude: map?.lat,
    longitude: map?.lng,
  };
  if (map) {
    let keys = [
      "doorNo",
      "addressLineOne",
      "addressLineTwo",
      "landmark",
      "area",
      "district",
      "city",
      "state",
      "country",
      "pincode",
      "zipcode",
    ];
    let findAddress = map?.result?.address_components;
    keys?.map((_) => {
      switch (_) {
        case "doorNo":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) => ["premise"]?.includes(type))
            )?.long_name ?? "";
          break;
        case "addressLineOne":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) =>
                ["establishment", "street_number"]?.includes(type)
              )
            )?.long_name ?? "";
          break;
        case "addressLineTwo":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.some((type) =>
                ["neighborhood", "route"]?.includes(type)
              )
            )?.long_name ?? "";
          break;
        case "landmark":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("landmark"))
              ?.long_name ?? "";
          break;
        case "area":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("political", "locality")
            )?.long_name ?? "";
          break;
        case "district":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_2")
            )?.long_name ?? "";
          break;
        case "city":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_2")
            )?.long_name ?? "";
          break;
        case "state":
          updateData[_] =
            findAddress?.find((_) =>
              _?.types?.includes("administrative_area_level_1")
            )?.long_name ?? "";
          break;
        case "country":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("country"))
              ?.long_name ?? "";
          break;
        case "pincode":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("postal_code"))
              ?.long_name ?? "";
          break;
        case "zipcode":
          updateData[_] =
            findAddress?.find((_) => _?.types?.includes("postal_code"))
              ?.long_name ?? "";
          break;
        default:
          return null;
      }
      return _;
    });
  }

  return updateData;
};

/**
 * File Upload
 * @param {File} file - Single file object
 * @param {*} extra_param - Object contains any key and value
 * @param {context} alert - Alert Context to show snackbar
 * @returns {object} - Uploaded file name and S3 url
 */
export const fileUpload = async (file, extra_param, alert) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      file && formData.append("files", file);
      extra_param?.companyId && formData.append("companyId", `${extra_param?.companyId}`);
      extra_param?.type && formData.append("type", `${extra_param?.type}`);
      extra_param?.tenantId && formData.append("tenantId", `${extra_param?.tenantId}`);

      let uploaded_file_res = await NetworkCall(
        `${config.api_url}/file-upload`,
        NetWorkCallMethods.post,
        formData, null, true, false
      );

      resolve(uploaded_file_res?.data?.fileUrls)
    } catch (error) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
    }
  });
};

/**
 * Single File Upload
 * @param {File} file - Single file object
 * @param {*} extra_param - Object contains any key and value
 * @param {context} alert - Alert Context to show snackbar
 * @param {int} allowed_image_size - Allowed image size
 * @returns {object} - Uploaded file name and S3 url
 */
export const singleFileUpload = async (file, extra_param, alert, allowed_image_size) => {
  return new Promise(async (resolve, reject) => {
    try {
      let uploaded_file = []
      let file_type = file?.type.split("/")?.[0]
      if (file_type === "image") {
        new Compressor(file, {
          quality: 0.8,
          async success(result) {
            let bytes = result?.size
            let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
            if (finalSize <= allowed_image_size) {
              uploaded_file = await fileUpload(result, extra_param, alert)
              resolve(uploaded_file)
            } else {
              alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: "Please upload less then 5 MB image",
              });
              resolve(null)
            }
          },
          error(err) {
            alert.setSnack({
              ...alert, open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
            });
          },
        })
      } else {
        let bytes = file?.size
        let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
        if (finalSize <= allowed_image_size) {
          uploaded_file = await fileUpload(file, extra_param, alert)
          resolve(uploaded_file)
        } else {
          alert.setSnack({
            ...alert, open: true,
            severity: AlertProps.severity.error,
            msg: "Please upload less then 5 MB file",
          });
          resolve(null)
        }
      }
    } catch (error) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
    }
  });
};

/**
 * Multi File Upload
 * @param {FileList} files - Array of file object
 * @param {*} extra_param - Object contains any key and value
 * @param {context} alert - Alert Context to show snackbar
 * @param {int} allowed_image_size - Allowed image size
 * @param {Boolean} priority - Boolean to add asset type to each object - default - true
 * @returns {[Object]} - Array of object
 */
export const multiFileUpload = async (files, { companyId, file_meta }, alert, allowed_image_size) => {
  return new Promise(async (resolve, reject) => {
    try {
      let uploaded_files = []
      for (let i = 0; i < files.length; i++) {
        let uploaded_file = await singleFileUpload(files[i], { companyId }, alert, allowed_image_size)
        if (uploaded_file?.[0]?.url) {
          if (!file_meta) {
            uploaded_files = [
              ...uploaded_files,
              {
                url: uploaded_file?.[0]?.url,
              }
            ]
          }
          else {
            uploaded_files = [
              ...uploaded_files,
              {
                url: uploaded_file?.[0]?.url,
                name: files?.name?.split(".")?.[0],
                size: formatSizeUnits(files?.size ?? 0),
                type: files?.name?.split(".")?.[1],
              }
            ]
          }
        }
      }
      resolve(uploaded_files)
    } catch (error) {
      alert.setSnack({
        ...alert, open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
    }
  });
};

// access companies
export const companyDetails = JSON.parse(localStorage.getItem("accessType"));

/**
 * Function to get ENUM dynamically
 * @param enumTypes `[String]`
 * @returns `Object`
 */
export const enumSelect = async (enumTypes, fullResponse = false) => {
  let result;

  const payload = { enumName: enumTypes };

  await NetworkCall(
    `${config.api_url}/enum`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((res) => {
      result = res?.data?.data;
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};

export const enumCatagory = {
  notifications_content_type: "notifications_content_type",
  security_status_enum_type: "security_status_enum_type",
  security_request_type: "security_request_type",
  delivery_status_type: "delivery_status_type",
  general_maintenance_type: "general_maintenance_type",
  swift_type: "swift_type",
  payment_mode: "payment_mode",
  relationship_type: "relationship_type",
  sex_type: "sex_type",
  urgent_type: "urgent_type",
  emergency_relationship_type: "emergency_relationship_type",
  invoice_charge_type: "invoice_charge_type",
};

// get types
export const keyMapSource = ({ enumType }) => {
  return enumType
    ?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value,
      };
    })
    .reduce((old, item) => ({ ...old, ...item }), {});
};

export const FontFamilySwitch = () => {
  const language = localStorage.getItem("i18nextLng")
  switch (language) {
    case "en":
      return {
        extraBold: "IBMPlexSans-All-ExtraBold",
        bold: "IBMPlexSans-All-Bold",
        semiBold: "IBMPlexSans-All-SemiBold",
        regular: "IBMPlexSans-All-Regular",
        italic: "IBMPlexSans-All-Italic",
      };
    case "es":
      return {
        extraBold: "IBMPlexSans-All-ExtraBold",
        bold: "IBMPlexSans-All-Bold",
        semiBold: "IBMPlexSans-All-SemiBold",
        regular: "IBMPlexSans-All-Regular",
        italic: "IBMPlexSans-All-Italic",
      };
    case "ar":
      return {
        extraBold: "IBMPlexSans-Arabic-ExtraBold",
        bold: "IBMPlexSans-Arabic-Bold",
        semiBold: "IBMPlexSans-Arabic-SemiBold",
        regular: "IBMPlexSans-Arabic-Regular",
        italic: "IBMPlexSans-Arabic-Italic",
      };
    case "fr":
      return {
        extraBold: "IBMPlexSans-All-ExtraBold",
        bold: "IBMPlexSans-All-Bold",
        semiBold: "IBMPlexSans-All-SemiBold",
        regular: "IBMPlexSans-All-Regular",
        italic: "IBMPlexSans-All-Italic",
      };
    default:
      return {
        extraBold: "IBMPlexSans-All-ExtraBold",
        bold: "IBMPlexSans-All-Bold",
        semiBold: "IBMPlexSans-All-SemiBold",
        regular: "IBMPlexSans-All-Regular",
        italic: "IBMPlexSans-All-Italic",
      };
  }
};
// get size of image
export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
}
export const returnMomentFormat = (val) =>
  moment(val).tz(moment.tz.guess()).format("DD MMM YY");

export const getDate = (type, date, value) => {
  let copyDate = new Date(JSON.parse(JSON.stringify(date)));
  switch (type) {
    case "Day":
      return add(new Date(copyDate), { days: value });
    case "Month":
      return add(new Date(copyDate), { months: value });
    case "Year":
      return add(new Date(copyDate), { years: value });
    case "Monthly":
      return add(new Date(copyDate), { months: value });
    case "Yearly":
      return add(new Date(copyDate), { years: value });
    default:
      return "not edited";
  }
};

export const getDateValue = (value, sendEndDate, gracePeriod = 0, subtract) => {
  const { period = null, input = null, date = null } = value ?? {};
  if (period && input && date) {
    let val = getDate(period, date, Number(input));
    if (gracePeriod && gracePeriod > -1) {
      val = getDate("Day", val, Number(gracePeriod));
    }
    if (sendEndDate) {
      if (subtract) {
        return moment(val).subtract(1, "days")?._d;
      } else {
        return val;
      }
    }
    return `${returnMomentFormat(date)} - ${returnMomentFormat(
      moment(val).subtract(1, "days")
    )}`;
  }
  return "";
};

export const getTotal = (data) => {
  // get total count
  let totalCountofParkingslot = data.reduce(function (prev, current) {
    return prev + +current.value;
  }, 0);

  return totalCountofParkingslot;
};

// find a difference between two days

export function timeDiffCalc(dateFuture, dateNow, day) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? days : days;
  }

  difference +=
    hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} minutes` : `${minutes} minutes`;
  if (day) {
    return days === 1 ? days ?? 0 : days;
  } else {
    return difference;
  }
}

export function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const enumName = {
  post_dated_cheques: "Post Dated Cheques",
};

export const vehicleTypeBGSVG = {
  "FOUR-WHEELER": "/images/parkingSlotCarBGSVG.svg",
  "TWO-WHEELER": "/images/parkingSlotBikeBGSVG.svg",
  "BI-CYCLE": "/images/parkingSlotBicycleBGSVG.svg",
};

export const vehicleTypeBGSVGArabic = {
  "FOUR-WHEELER": "/images/parkingFourWheeler.svg",
  "TWO-WHEELER": "/images/parkingTwoWheeler.svg",
  "BI-CYCLE": "/images/parkingCycle.svg",
};

export const vehicleTypeSVG = {
  "FOUR-WHEELER": "/images/carSVG.svg",
  "TWO-WHEELER": "/images/bikeSVG.svg",
  "BI-CYCLE": "/images/bicycleSVG.svg",
};

export const timeZoneConverter = (date, format = "DD MMM YYYY", now) => {
  // time zone for company
  let company = JSON.parse(
    localStorage.getItem(LocalStorageKeys.selectedCompany)
  );

  if (!date) {
    return "-";
  }
  if (now) {
    if (company?.[0]?.timezone) {
      return moment(date).tz(company?.[0]?.timezone).fromNow();
    } else {
      return moment(date).tz(moment.tz.guess()).fromNow();
    }
  }
  if (company?.[0]?.timezone) {
    return moment(date)
      .tz(company?.[0]?.timezone)
      .format(format ?? "DD MMM YYYY");
  } else {
    return moment(new Date(date))
      .tz(moment.tz.guess())
      .format(format ?? "DD MMM YYYY");
  }
};

export const assestType = {
  Facility_Images: 1,
  General_Images: 2,
  Videos: 3,
  Documents: 4,
  Three_sixty_Images: 5,
  Thumbnails: 6,
  Floor_Plan: 7,
};
export const constructAddress = (address, address_key, connector = ",", for_empty_address = " - ") => {
  //for example arg passing
  //address={floor_name:"Floor Name",block_name:"Block Name",property_name:"Property Name"}
  //address_key=["floor_name","block_name","property_name"]
  let address_array = []
  for (let i = 0; i < address_key.length; i++) {
    if (address[address_key[i]]?.length > 0) {
      address_array.push(address[address_key[i]])
    }
  }
  let temp_final_address = address_array.join(`${connector} `)
  let final_address = temp_final_address?.length > 0 ? temp_final_address : for_empty_address
  return final_address
}


export const getAccess = (type) => {
  const companyDetails = JSON.parse(localStorage.getItem("accessType"))
  return companyDetails?.some((x) => x[type] === true)
}

export const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Nov", "Dec"]

/**
* Generate Image URL
* @param {String} image_url - S3 Image URL
* @param {int} width - Required width
* @param {int} height - Required height
* @returns String - Converted image size URL
*/
export const generateImageUrl = (image_url, { width, height }) => {
  let image_url_split = (image_url && image_url?.length > 0) ? image_url?.split('/') : []
  if (image_url_split?.length > 0 && Boolean(image_url_split?.[image_url_split?.length - 2]?.includes("X"))) {
    image_url_split[image_url_split?.length - 2] = `${width}X${height}`
    return image_url_split?.join('/')
  } else {
    return image_url
  }
}

export const isExpired = (d1, d2) => {
  let date1 = new Date(moment(d1).format("yyyy-MM-DD")).getTime();
  let date2 = new Date(moment(d2).format("yyyy-MM-DD")).getTime();

  if (date1 < date2) {
    return true;
  } else {
    return false;
  }
};


export const isExpiredDateTime = (d1, d2) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return true;
  } else {
    return false;
  }
};



// Update device token in database
export const SetDeviceToken = (token = localStorage.getItem(LocalStorageKeys.deviceToken), val = localStorage.getItem(LocalStorageKeys.userProfileId), client = localStorage.getItem(LocalStorageKeys.clientID),device) => {
  const variables = {
    query: updateDeviceToken,
    variables: {
      insert: [
        {
          user_profile_id: val,
          token: token,
          is_active: true,
          client: client,
          app_build: config.app_x_build,
          platform:device
        },
      ],
    },
  };

  NetworkCall(
    `${config.graphql_url}`,
    NetWorkCallMethods.post,
    variables,
    null,
    true,
    false
  )
    .then((response) => {
      // backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
      localStorage.setItem(LocalStorageKeys.deviceTokenUpdate, "true")
    })
    .catch((error) => {
      // backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
      console.log(error)
    });
};

export const statistics_category_icon = {
  1: {
    "name": "Clothing",
    "type": "Spendings",
    "icon": <StatsClothing />
  },
  2: {
    "name": "Debt",
    "type": "Spendings",
    "icon": <Debt />
  },
  3: {
    "name": "Education",
    "type": "Spendings",
    "icon": <Education />
  },
  4: {
    "name": "Entertainment ",
    "type": "Spendings",
    "icon": <Entertainment />
  },
  5: {
    "name": "Food",
    "type": "Spendings",
    "icon": <Food />
  },
  6: {
    "name": "Gifts/Donations",
    "type": "Spendings",
    "icon": <Gift />
  },
  7: {
    "name": "Household Items/Supplies",
    "type": "Spendings",
    "icon": <Household />
  },
  8: {
    "name": "Housing",
    "type": "Spendings",
    "icon": <RealEstate />
  },
  9: {
    "name": "Insurance",
    "type": "Spendings",
    "icon": <StatsInsurance />
  },
  10: {
    "name": "Medical/Healthcare",
    "type": "Spendings",
    "icon": <Medical />
  },
  11: {
    "name": "Personal",
    "type": "Spendings",
    "icon": <MutualFund />
  },
  12: {
    "name": "Retirement",
    "type": "Spendings",
    "icon": <Retirement />
  },
  14: {
    "name": "Transportation",
    "type": "Spendings",
    "icon": <Transportation />
  },
  15: {
    "name": "Utilities",
    "type": "Spendings",
    "icon": <Household />
  },
  16: {
    "name": "Bonds",
    "type": "Investments",
    "icon": <Bonds />
  },
  17: {
    "name": "Certificates of Deposit (CDs)",
    "type": "Investments",
    "icon": <Bonds />
  },
  18: {
    "name": "Debt mutual funds",
    "type": "Investments",
    "icon": <MutualFund />
  },
  19: {
    "name": "Fixed Deposits",
    "type": "Investments",
    "icon": <FixedDeposit />
  },
  20: {
    "name": "Provident Funds",
    "type": "Investments",
    "icon": <FixedDeposit />
  },
  21: {
    "name": "Real Estate",
    "type": "Investments",
    "icon": <RealEstate />
  },
  22: {
    "name": "Retirement Plan",
    "type": "Investments",
    "icon": <Retirement />
  },
  23: {
    "name": "Stocks and equities",
    "type": "Investments",
    "icon": <StocksAndEquities />
  }
}

export const constructOptionObject = (option) => {
  let temp_object = {
    array: option,
    object: {},
  }
  for (let i = 0; i < option?.length; i++) {
    temp_object.object[option?.[i]?.value] = option?.[i]?.label
  }
  return temp_object
}

export const getTimeWithAmPm = (timeString) => {
  const [hour] = timeString.split(":")
  if (hour === "00") {
    return `12:00 AM`
  }
  else if (hour === "24") {
    return `12:00 PM`
  }
  else {
    if (hour >= 12) {
      if (hour % 12 === 0) return `12:00 PM`
      else return `${hour % 12}:00 PM`.padStart(8, '0')
    } else return `${hour}:00 AM`.padStart(8, '0')
  }
}

export const randomColor = (name) => {
  var colors = ['#5AC782', '#5078E1', '#b05f6d', '#ffc153', '#47b39d'];

  return colors[((name ? name?.length : 0) % colors.length)]
}
export const alphabetColors = {
  A: '#C24B23',
  B: '#647D18',
  C: '#2C7E4E',
  D: '#3275A5',
  E: '#5E3B77',
  F: '#773B54',
  G: '#A1652A',
  H: '#366D00',
  I: '#387C65',
  J: '#55678C',
  K: '#9636A9',
  L: '#A93666',
  M: '#867339',
  N: '#497639',
  O: '#228078',
  P: '#464D9F',
  Q: '#994092',
  R: '#890A3F',
  S: '#616116',
  T: '#267D26',
  U: '#3E6C75',
  V: '#453B77',
  W: '#773B63',
  X: '#CB2C6E',
  Y: '#6F2E2E',
  Z: '#962CCB',
};

export const stringAvatar = (name) => {
  let capName = name ? name.toUpperCase() : undefined
  return {
    sx: {
      bgcolor: alphabetColors[capName?.[0]],
    },
    children: capName && (capName.indexOf(' ') >= 0 ? `${capName.split(' ')[0][0]}${capName.split(' ')[1][0]}` : `${capName.split(' ')[0][0]}`),
  };
}

export const removeDuplicatesByKey = (arr, key) => {
  const uniqueKeys = new Set();
  const resultArray = [];

  for (const obj of arr) {
    const keyValue = obj[key];

    if (!uniqueKeys.has(keyValue)) {
      uniqueKeys.add(keyValue);
      resultArray.push(obj);
    }
  }

  return resultArray;
}

export const concat_string = (object = {}, object_key = [], connector = ", ", if_empty = " - ") => {
  let array = []
  for (let i = 0; i < object_key.length; i++) {
    if (object[object_key[i]]?.length > 0) {
      array.push(object[object_key[i]])
    }
  }
  let temp_string = array.join(`${connector} `)
  let final_string = temp_string?.length > 0 ? temp_string : if_empty
  return final_string
}

export const getTotalReduce = (data, value) => {
  // get total count
  let total = data?.reduce(function (prev, current) {
    return prev + +current[value];
  }, 0);

  return total;
};
export const remCalculate = (val) => {
  return `${(val < 12 ? (12 / 16) : (val / 16))}rem`
}

export const RetainLocalStorage = () => {
  let i18nextLng = localStorage.getItem(LocalStorageKeys?.i18nextLng);
  let alert_msg = localStorage.getItem(LocalStorageKeys?.alert_msg);
  localStorage.clear();
  localStorage.setItem(LocalStorageKeys?.i18nextLng, i18nextLng ?? "en-US");
  alert_msg?.length > 0 && localStorage.setItem(LocalStorageKeys?.alert_msg, alert_msg);
}
