import React, { useEffect, useState } from "react";
import { Button, Box, Divider, Avatar, Typography } from "@mui/material";
import { useStyles } from "./style";
import { TextBox } from "../../../components";
import { AsPerAgreementIcon, SuccessRequest } from "../../../assets";
import { useApolloClient } from "@apollo/client";
import { GET_AGREEMENT_CONTACT_DETAILS } from "../../../graphql/queries";
import { addDays } from "date-fns";
import moment from "moment";
import { getDate, NetWorkCallMethods ,AlertProps} from "../../../utils";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall/index";
import { AlertContext } from "../../../contexts";
import { subDays } from "date-fns";

export const AsPerAgreement = ({ t = () => false, agreement_id , reload=()=>false }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const [remark, setRemark] = useState("");
    // context
    const alert = React.useContext(AlertContext);
  const [details, setDetils] = useState({
    loading: true,
    data: "",
    sumbit: false,
  });
  const getDetails = () => {
    client
      .query({
        query: GET_AGREEMENT_CONTACT_DETAILS,
        fetchPolicy: "network-only",
        variables: {
          id: agreement_id,
        },
      })
      .then((res) => {
        let start_date = addDays(
          new Date(res?.data?.agreement?.[0]?.lease_end_date),
          1
        );
        let end_date = getDate(
          res?.data?.agreement?.[0]?.lease_period,
          start_date,
          res?.data?.agreement?.[0]?.lease_duration
        );
        const date = subDays(end_date, 1)
        const result = {
          ...res?.data?.agreement?.[0],
          lease_start_date: start_date,
          lease_end_date: date,
        };
        setDetils({
          data: result,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setDetils({
          ...details,
          loading: false,
        });
      });
  };
  const sumbit = () => {
    const data = {
      agreement_id: agreement_id,
      remarks: remark,
      contact_id: details?.data?.contact_id,
      renewal_type: "As Per Agreement",
      period: details?.data?.lease_period,
      start_date: details?.data?.lease_start_date ?? null,
      end_date: details?.data?.lease_end_date ?? null,
      "request_purpose":"Renewal",
      "status":"Pending",
    };
    NetworkCall(
      `${config.api_url}/agreement_request/create_agreement_request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      reload()
      setDetils({
        ...details,
        sumbit: true,
      });
    }).catch((error) => {
      setDetils({
        ...details,
        sumbit: false,
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some thing Went wrong.",
      });
    });
  };
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement_id]);

  return (
    <Box p={2}>
      {details?.sumbit ? (
        <Box textAlign={'center'} p={2}>
          <SuccessRequest />
          <Box height={'5px'}/>
          <Typography className={classes.sucessTitle}>{t("Request Submitted Successfully")}</Typography>
        </Box>
      ) : (
        <>
          {/* customer card */}
          <Box className={classes.customerCard}>
            <Box display="flex" alignItems={"center"}>
              <Avatar
                src={"https://mui.com/static/images/avatar/2.jpg"}
                className={classes.customerIcon}
              />
              <Box width={"10px"} />
              <Box>
                <Typography className={classes.customerTitle} noWrap>
                  {details?.data?.contactByID?.first_name ?? ""}{" "}
                  {details?.data?.contactByID?.last_name ?? ""}
                </Typography>
                <Box height={"8px"} />
                <Box display="flex" alignItems={"center"}>
                  <Typography className={classes.customerSub} noWrap>
                    {details?.data?.contactByID?.mobile_no_country_code ?? ""}{" "}
                    {details?.data?.contactByID?.mobile_no ?? ""}
                  </Typography>
                  <Box className={classes.dot} />
                  <Typography className={classes.customerSub} noWrap>
                    {details?.data?.contactByID?.email_id ?? ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box height={"16px"} />
            <Divider />
            <Box height={"16px"} />
            <Box display="flex" alignItems={"center"}>
              <AsPerAgreementIcon />
              <Box width={"10px"} />
              <Box>
                <Typography className={classes.customerTitle}>
                  New Agreement
                </Typography>
                <Box height={"8px"} />
                <Typography className={classes.customerSub}>
                  {moment(details?.data?.lease_start_date).format(
                    "DD MMM YYYY"
                  )}{" "}
                  -{moment(details?.data?.lease_end_date).format("DD MMM YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box height={"24px"} />
          <TextBox
            label={t("Remarks (optional)")}
            placeholder={t("Remarks (optional)")}
            value={remark ?? ""}
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            multiline
          />
          <Box height={"24px"} />
          <Button className={classes.Yes} onClick={sumbit} disabled={remark?.length > 0 ? false : true}>
            {t("Submit")}
          </Button>
        </>
      )}
    </Box>
  );
};
