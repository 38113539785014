import { Drawer } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      borderRadius: theme.palette.borderRadius,
    },
  },
  DrawerClass: {
    borderRadius: theme.palette.borderRadius,

    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  }
}));
export function DrawerComponent(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <React.Fragment>
        <Drawer
          onClose={props?.onClose}
          anchor={"bottom"}
          open={props?.open}
          className={classes.DrawerClass}
        >
          {props?.component}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
