import * as React from "react"
export const NoAmenitySvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={374}
    height={251}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.559}
        x2={0.562}
        y1={-0.799}
        y2={1.394}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f5f7fa" />
        <stop offset={1} stopColor="#f5f7fa" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={0.56}
        x2={0.559}
        y1={1}
        y2={0}
      />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={0.56}
        x2={0.559}
        y1={0}
        y2={1}
      />
      <linearGradient
        xlinkHref="#a"
        id="e"
        x1={0.553}
        x2={0.559}
        y1={0.328}
        y2={1}
      />
      <clipPath id="b">
        <path
          fill="#fff"
          stroke="#707070"
          d="M219.476 96.387h374v251h-374z"
          data-name="Rectangle 55527"
          opacity={0}
        />
      </clipPath>
    </defs>
    <g data-name="Group 105924">
      <g
        clipPath="url(#b)"
        data-name="Mask Group 102254"
        transform="translate(-219.476 -96.387)"
      >
        <g fill="none" stroke="#e4e8ee" data-name="Group 105921">
          <path d="M274.617 124.495v188.489" data-name="Line 1481" />
          <path d="M424.74 124.495v188.489" data-name="Line 1482" />
          <path d="M349.679 124.495v188.489" data-name="Line 1483" />
          <path d="M499.802 124.495v188.489" data-name="Line 1484" />
          <path d="M274.617 86.819v261.359" data-name="Line 1485" />
          <path d="M424.74 86.819v261.359" data-name="Line 1486" />
          <path d="M349.679 86.819v261.359" data-name="Line 1487" />
          <path d="M499.802 86.819v261.359" data-name="Line 1488" />
          <path d="M312.148 86.819v261.359" data-name="Line 1489" />
          <path d="M462.271 86.819v261.359" data-name="Line 1490" />
          <path d="M387.21 86.819v261.359" data-name="Line 1491" />
          <path d="M537.332 86.819v261.359" data-name="Line 1492" />
          <path d="M237.274 124.537h337.777" data-name="Line 1493" />
          <path d="M237.087 199.974h337.777" data-name="Line 1494" />
          <path d="M237.087 275.265h337.777" data-name="Line 1495" />
          <path d="M236.899 162.256h337.777" data-name="Line 1496" />
          <path d="M236.899 86.819h337.777" data-name="Line 1497" />
          <path d="M236.899 237.693h337.777" data-name="Line 1498" />
          <path d="M236.899 312.983h337.777" data-name="Line 1499" />
          <path d="M236.899 350.556h337.777" data-name="Line 1500" />
        </g>
        <g data-name="Group 105923">
          <g data-name="Group 105922">
            <path
              fill="#ced3dd"
              d="m348.886 135.965-8.434-2.144-2.015-8.434h-.205l-1.9 8.434-8.439 2.144v.263l8.434 1.717 1.888 8.434h.239l2-8.434 8.434-1.7ZM485.395 229.953l-5.194-1.32-1.24-5.194h-.126l-1.173 5.194-5.194 1.32v.162l5.194 1.057 1.162 5.194h.144l1.23-5.194 5.194-1.045Z"
              data-name="icons8-plus-math (1)"
            />
            <g transform="translate(354.43 156.73)">
              <ellipse
                cx={56.38}
                cy={2.339}
                fill="#e4e8ee"
                data-name="Ellipse 129532"
                opacity={0.47}
                rx={56.38}
                ry={2.339}
                transform="translate(0 59.67)"
              />
              <path
                fill="#717b89"
                d="M34.17 53.99h-5.155a.871.871 0 0 1-.838-1.109l2.985-10.45a.873.873 0 0 1 .838-.632h5.155a.871.871 0 0 1 .838 1.109l-2.985 10.45a.873.873 0 0 1-.838.632Z"
                data-name="Path 99253"
              />
              <path
                fill="#717b89"
                d="M70.316 53.99h5.155a.871.871 0 0 0 .838-1.109l-2.985-10.45a.873.873 0 0 0-.838-.632h-5.155a.871.871 0 0 0-.838 1.109l2.985 10.45a.873.873 0 0 0 .838.632Z"
                data-name="Path 99254"
              />
              <path
                fill="#959ca6"
                d="M78.367 5.225A5.224 5.224 0 0 0 73.143 0H57.468a5.226 5.226 0 0 0-5.225 5.225v24.382h26.124Z"
                data-name="Path 99255"
              />
              <path
                fill="#959ca6"
                d="M52.243 5.225A5.224 5.224 0 0 0 47.019 0H31.344a5.226 5.226 0 0 0-5.225 5.225v24.382h26.124Z"
                data-name="Path 99256"
              />
              <path
                fill="#465160"
                d="M78.367 34.833H26.119v-6.967a3.484 3.484 0 0 1 3.483-3.483h45.282a3.484 3.484 0 0 1 3.483 3.483Z"
                data-name="Path 99257"
              />
              <path
                fill="#b8bdc4"
                d="M26.119 45.282h-1.742a6.967 6.967 0 0 1-6.966-6.967v-22.64a1.742 1.742 0 0 1 1.742-1.742h5.225a1.742 1.742 0 0 1 1.742 1.742Z"
                data-name="Path 99258"
              />
              <path
                d="M73.324 42.431a.873.873 0 0 0-.838-.632h-5.155a.871.871 0 0 0-.838 1.109l1.176 4.115h6.966Z"
                data-name="Path 99259"
                opacity={0.05}
              />
              <path
                d="M73.324 42.431a.873.873 0 0 0-.838-.632h-5.155a.871.871 0 0 0-.838 1.109l.927 3.245h6.966Z"
                data-name="Path 99260"
                opacity={0.07}
              />
              <path
                d="M31.197 42.431a.871.871 0 0 1 .838-.632h5.155a.871.871 0 0 1 .838 1.109l-1.176 4.115h-6.966Z"
                data-name="Path 99261"
                opacity={0.05}
              />
              <path
                d="M31.198 42.431a.871.871 0 0 1 .838-.632h5.155a.871.871 0 0 1 .838 1.109l-.928 3.245h-6.967Z"
                data-name="Path 99262"
                opacity={0.07}
              />
              <path
                fill="#b8bdc4"
                d="M85.334 13.933h-5.223a1.742 1.742 0 0 0-1.742 1.742v19.158H17.411v3.483a6.967 6.967 0 0 0 6.966 6.966h55.734a6.967 6.967 0 0 0 6.966-6.966V15.675a1.742 1.742 0 0 0-1.743-1.742Z"
                data-name="Path 99263"
              />
            </g>
          </g>
        </g>
        <path
          fill="url(#a)"
          d="M0 0h191v40H0z"
          data-name="Rectangle 55522"
          transform="rotate(90 248.544 373.931)"
        />
        <path
          fill="url(#c)"
          d="M0 0h374v63H0z"
          data-name="Rectangle 55523"
          transform="translate(219.476 284.387)"
        />
        <path
          fill="url(#d)"
          d="M0 0h374v63H0z"
          data-name="Rectangle 55524"
          transform="translate(219.475 96.387)"
        />
        <path
          fill="url(#c)"
          d="M0 0h244v65H0z"
          data-name="Rectangle 55525"
          transform="rotate(-90 437.431 -90.044)"
        />
        <path
          fill="url(#e)"
          d="M0 0h244v63H0z"
          data-name="Rectangle 55526"
          transform="rotate(-90 283.431 63.956)"
        />
      </g>
      <text
        fill="#4e5a6b"
        data-name="No Amenity Found"
        fontFamily="NunitoSans-ExtraBold, Nunito Sans"
        fontSize={14}
        fontWeight={800}
        transform="translate(125.999 177)"
      >
        <tspan x={0} y={0}>
          {"No Amenity Found"}
        </tspan>
      </text>
      <text
        fill="#98a0ac"
        data-name="No amenity found to this property"
        fontFamily="NunitoSans-SemiBold, Nunito Sans"
        fontSize={14}
        fontWeight={600}
        transform="translate(186.999 200)"
      >
        <tspan x={-107.639} y={0}>
          {"No amenity found to this property"}
        </tspan>
      </text>
    </g>
  </svg>
)