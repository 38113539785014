import React from "react";
import { Typography, Box } from "@mui/material";
import { useStyles } from "./styles";
import { WorkerContext } from "../../context";
const WorkerInformationCard = () => {
  const classes = useStyles();
  const { t = () => false, data } = React.useContext(WorkerContext);
  return (
    <Box className={classes.root}>
      <Box className={classes.mainPadding}>
        <Typography className={classes.label} noWrap>
        { t("Work Description")}
        </Typography>
        <Box height={"8px"} />
        <Typography className={classes.description}>
          {data?.worker_info?.workDescription}
        </Typography>
      </Box>
    </Box>
  );
};

export default WorkerInformationCard;
