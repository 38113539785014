import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    dialog: {
        width: "100%",
        maxWidth: "auto",
        padding: "0 !important",
        "& .MuiPaper-root": {
            width: "100% !important",
            borderRadius: "4px",
            backgroundImage: (props) => props?.noService && `url("/images/noserviceweb.svg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: (props) => props?.noService && "288px"

        },
        "& .MuiDialogContent-root": {
            padding: "0px !important",
            position: 'relative'
        },
        '& .MuiDialog-container': {
            padding: (props) => props?.padding ?? '',

        }
    },
    header: {
        border: (props) => props?.noService ? null : "1px solid #E4E8EE",
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().extraBold,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            backgroundImage: (props) => props?.noService && `url("/images/noservicemob.svg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "center"


        },
        "& .MuiContainer-root": {
            padding: "0px",
            "&::-webkit-scrollbar": {
                display: "none",
              },
        },
    },
    component: {
        overflow: "auto"
    }
}));