import * as React from "react"
const PhoneIconBlue = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Group 96752">
      <g data-name="Group 96751">
        <g
          fill="#fff"
          stroke="#e4e8ee"
          data-name="Rectangle 53040"
          transform="translate(0 -.001)"
        >
          <rect width={32} height={32} stroke="none" rx={8} />
          <rect width={31} height={31} x={0.5} y={0.5} fill="none" rx={7.5} />
        </g>
        <path
          fill="#5078e1"
          d="m20.27 21.368-.585.533a2.282 2.282 0 0 1-2.209.461q-2.812-.862-5.253-4.857t-1.82-6.711a2.114 2.114 0 0 1 1.536-1.581l.784-.209a1.625 1.625 0 0 1 1.858.864l.665 1.38a1.424 1.424 0 0 1-.315 1.703l-1.038.914a.535.535 0 0 0-.18.494 6.135 6.135 0 0 0 .736 1.988 5.714 5.714 0 0 0 1.327 1.598.53.53 0 0 0 .486.092l1.486-.44a1.622 1.622 0 0 1 1.739.588l.929 1.23a1.444 1.444 0 0 1-.146 1.953Z"
          data-name="icons8-call (1)"
        />
      </g>
    </g>
  </svg>
)
export default PhoneIconBlue;
