import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  noPadding: {
    padding: 0
  },
  root_create: {
    backgroundColor: "#F8F8F8",
    padding: ""
  },
  heading: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
  },
  activity: {
    backgroundColor: "#F1F7FF",
    color: "#5078E1",
    padding: "4px 5px",
    fontSize: "0.75rem",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().bold,
  },
  work: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",  
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem", 
  },
  },
  work1: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: 1,   
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem", 
  },
  },
  totalAmount: {
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.secondary,
    fontSize: "0.875rem",
  },
  money: {
    color: theme.typography.color.primary,
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  descirption: {
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "0.75rem",
  },
  drawerActivity: {
    backgroundColor: "#FFE5DC",
    color: "#F15A29",
    padding: "8px 12px",
    fontSize: "0.75rem",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  information: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.tertiary,
  },
  assist: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.tertiary,
  },
  label: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
  },
  textArea: {
    width: "100%",
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
  },
  primaryButton: {
    padding: "15px 60px",
    border: "1px solid #CED3DD",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    borderRadius: "8px",
    backgroundColor: "#F8F8F8"
  },
  secondaryButton: {
    padding: "15px 60px",
    backgroundColor: "#5078e1",
    border: "none",
    color: "white",
    fontFamily: FontFamilySwitch().bold,
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: "#5078e1"
    }
  },
  selectedButton: {
    padding: "10px 12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    borderRadius: "24px",
    backgroundColor: "#F8FAFC",
    border: "none",
    "&:hover": {
      backgroundColor: "none",
      border: "none",
    }
  },
  unselectedButton: {
    padding: "10px 12px",
    border: "1px solid #5078E1",
    backgroundColor: "#F1F7FF",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().bold,
    borderRadius: "24px",
    "&:hover": {
      backgroundColor: "none",
    }
  },
  uploadButton: {
    border: "2px dashed #E4E8EE",
    borderRadius: "4px",
    padding: "25px",
    backgroundColor: "white",
    color: theme.typography.color.primary,
  },
  field: {
    [`& fieldset`]: {
      borderRadius: "4px",
      border: "1.5px solid  #E4E8EE",
      boxShadow: "none",
      backgroundColor: "white",
    },
  },
  imgdiv: {
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
  },
  close: {
    position: "absolute",
    right: "0px",
    top: "0px",
    backgroundColor: "red",
    borderRadius: "50%",
    color: "white",
    fontSize: "1.25rem",
  },
  buttonCard: {
    boxShadow: "0px -1px 6px #00000021",
    width: "100%",
  },
  divider: {
    color: "#F2F4F7", 
    borderBottomWidth: "2px" ,
    opacity: 0.5
  },
  divider2: {
    color: "#E4E8EE",
    borderBottomWidth: "1px"
  },
  currentValue: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
  },
  percentageP: {
    fontSize: "0.75rem",
    color: "#00B542"
  },
  percentageL: {
    fontSize: "0.75rem", 
    color: "#F60000"
  },
  image: {
    objectFit: "cover"
  },
  dot: {
    width: "7px",
    height: "7px",
    borderRadius:'50%',
    backgroundColor: "#E4E8EE"
  },
  price: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
  },
  price1: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem", 
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      // flex: 1,
  },
  },
  whiteBox: {
    backgroundColor: "#fff", 
    borderRadius: "12px"
  },
  borderBottom: {
    borderBottom: "1px solid #d7dbe3"
  },
  transportation: {
    backgroundColor: "#E6F9FF"
  },
}));

export const revenueCardUseStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.palette.borderRadius2,
  },
  header_div: {
    backgroundColor: "#F6F9FF",
    borderRadius: `${theme.palette.borderRadius2} ${theme.palette.borderRadius2} 0px 0px`,
    padding: "8px 16px",
  },
  heading: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    color: theme.typography.color.primary_1,
  },
  period_toggle_div: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "18px",
    padding: "4px",
    cursor: "pointer",
  },
  period_toggle_div_2: {
    display: "flex",
    backgroundColor: "#F8FAFC",
    borderRadius: "18px",
    padding: "4px",
    cursor: "pointer",
    paddingLeft: "10px",
    [theme.breakpoints.down('sm')]: {
      paddingRight: "122px",
      position: "relative",
      right: "60px"
  },
  },
  selected_button: {
    fontFamily: FontFamilySwitch().semiBold,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "8px 12px",
    fontSize: "0.75rem",
    borderRadius: "15px",
  },
  unselected_button: {
    fontFamily: FontFamilySwitch().semiBold,
    backgroundColor: "white",
    color: theme.typography.color.secondary,
    padding: "8px 12px",
    fontSize: "0.75rem",
    borderRadius: "15px",
  },
  unselected_button_2: {
    fontFamily: FontFamilySwitch().semiBold,
    backgroundColor: "#F8FAFC",
    color: theme.typography.color.secondary,
    padding: "8px 12px",
    fontSize: "0.75rem",
    borderRadius: "15px",
  },
  toggle_button_text: {
    fontSize: "0.75rem",
  },
  content_div: {
    backgroundColor: "white",
    borderRadius: `0px 0px ${theme.palette.borderRadius2} ${theme.palette.borderRadius2}`,
    padding: "8px 16px",
  },
  total_amount: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
    textAlign: "center",
  },
  total: {
    fontSize: "0.75rem",
    color: theme.typography.color.tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    textAlign: "center",
  },
  graph: {
    position: "absolute", 
    top: "40%", 
    left: "45%",
    [theme.breakpoints.down('sm')]: {
      top: "30%", 
      left: "32%",
    },
  },
  padding: {
    paddingRight: "14px",
    cursor: "pointer",
    "& div": {
      "& input":{
          cursor:"pointer"
      }
    }
  }, 
  monthYear: {
    fontSize: "0.875rem"
  }, 
  border: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px"
  }
}));

export const revenueListCardUseStyles = makeStyles((theme) => ({
  revenueListCard: {
    padding: "16px 26px",
  },
  percentage: {
    color: "white",
    fontSize: "0.75rem",
    padding: "2px 9px",
    borderRadius: "4px",
  },
  tenantId: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
    fontSize: "0.875rem",
  },
  price: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
  },
}));

export const spendingListCardUseStyles = makeStyles((theme) => ({
  spendingListCard: {
    padding: "0 16px 0 16px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: "12px",
    backgroundColor: "white",
    // borderBottom: "1px solid #E4E8EE"
  },
  spendingList: {
    marginBottom: "12px",
    cursor: "pointer",
  },
  divider: {
    color: "#F2F4F7", 
    borderBottomWidth: "3px" ,
    opacity: 1
  },
  divider2: {
    color: "#E4E8EE",
    borderBottomWidth: "1px"
  },
}));

export const InvestmentListCardUseStyles = makeStyles((theme) => ({
  investmentListCard: {
  padding: "0 16px 0 16px",
  boxShadow: "0px 3px 30px #5C86CB2E",
  backgroundColor: "white",
  borderRadius: "12px",
  },
  investmentList: {
    borderBottom: "1px solid #E4E8EE",
    cursor: "pointer",
  },
  divider2: {
    color: "#E4E8EE",
    borderBottomWidth: "1px"
  },
}));
