import { Avatar, Box, Container, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material'
import React from 'react'
import { ServiceGotoStyle } from './style'
import { TextBox } from '../../components'
import SearchIcon from '../../assets/searchIcon'
import ScrollContainer from 'react-indiana-drag-scroll'
import { UpcomingBookingCard } from './components/upcomingBookingCard'
import useWindowDimensions from '../../utils/useWindowDimensions'
import { PopularServiceCard } from './components/popularServiceCard'
import CompanyIcon from './utils/companyIcon'
import { ReadNotificationIcon } from '../../components/topnavbar/assets/readNotificationIcon'
import { LanguageIcon } from '../../components/topnavbar/assets/languageIcon'
import HomeIcon from './utils/homeIcon'
import ServiceIcon from './utils/searchIcon'
import BookingIcon from './utils/bookingIcon'
import AccountIcon from './utils/accountIcon'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom'
import { Routes } from '../../router/routes'

export const ServiceGoto = () => {
    const classes = ServiceGotoStyle()
    const [search, setSearch] = React.useState("")
    const dimension = useWindowDimensions()
    const history = useHistory()

    const upComing = [
        {
            title: "Fridge full cleaning",
            date: "10:30 AM, 22 Jan 24",
            status: "Confirmed"
        },
        {
            title: "New Split AC Fixing",
            date: "10:30 AM, 22 Jan 24",
            status: "Confirmed"
        },
        {
            title: "Fridge full cleaning",
            date: "10:30 AM, 22 Jan 24",
            status: "Confirmed"
        }
    ]

    const popularService = [
        {
            url: "/images/p1.svg",
            title1: "Air",
            title2: "Conditioner"
        },
        {
            url: "/images/p2.svg",
            title1: "Bathroom",
            title2: "Cleaning"
        },
        {
            url: "images/p3.svg",
            title1: "Washing",
            title2: "Machine"
        },
        {
            url: "/images/p4.svg",
            title1: "House",
            title2: "Cleaning"
        },
        {
            url: "/images/p5.svg",
            title1: "Car",
            title2: "Wash"
        }
    ]

    const services = [
        {
            icon: "/images/serviceGotoIcons/acIcon.png",
            title1: "AC",
            title2: "Repair"
        },
        {
            icon: "/images/serviceGotoIcons/houseCleaning.png",
            title1: "House",
            title2: "Cleaing"
        },
        {
            icon: "/images/serviceGotoIcons/plumbingIcon.png",
            title1: "Plumbing",
            title2: "Repair"
        },
        {
            icon: "/images/serviceGotoIcons/paintingIcon.png",
            title1: "House",
            title2: "Painting"
        },
        {
            icon: "/images/serviceGotoIcons/carpendarIcon.png",
            title1: "Carpenter",
            title2: "Repair"
        },
        {
            icon: "/images/serviceGotoIcons/carwashicon.png",
            title1: "Car",
            title2: "Wash"
        },
        {
            icon: "/images/serviceGotoIcons/tvicon.png",
            title1: "TV",
            title2: "Repair"
        },
        {
            icon: "/images/serviceGotoIcons/electricityicon.png",
            title1: "Electrical",
            title2: "Repair"
        }
    ]

    return (
        <>
            <Container maxWidth="sm" sx={{ background: "#F2F4F7", padding: "0px !important" }}>
                <Box sx={{ height: dimension?.height - 65, overflow: "auto", marginRight: "-5px !important" }}>
                    <Box className={classes.header}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack spacing={1} direction={"row"} alignItems={"center"}>
                                <IconButton onClick={()=> history.push(Routes.dashboard)}>
                                    <KeyboardArrowLeftIcon />
                                </IconButton>
                                <CompanyIcon />
                            </Stack>
                            <Stack direction={"row"} spacing={1}>
                                <ReadNotificationIcon />
                                <LanguageIcon />
                                <Avatar src="/images/profile_image.png" className={classes.headerAvatar}></Avatar>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box className={classes.body}>
                        <Stack alignItems={"center"} p={"24px 16px"} className={classes.topBox}>
                            <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={"/images/serviceGotoBack.svg"} alt='' className={classes.backgroundImage} />
                                <Box sx={{ position: "absolute", bottom: "15px", left: "0px", width: "100%" }}>
                                    <Box p={2}>
                                        <TextBox
                                            label={""}
                                            value={search}
                                            placeholder={"Search service"}
                                            onChange={(val) => setSearch(val.target.value)}
                                            startAdornment={
                                                <InputAdornment>
                                                    <SearchIcon />
                                                </InputAdornment>
                                            }
                                            color
                                            borderColor="transparent"
                                            autoComplete
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>
                        <Box p={2}>
                            <Typography className={classes.title} p={"24px 0px 12px 0px"}>Upcoming Booking</Typography>
                            <Box py={"12px"}>
                                <ScrollContainer className="scroll-container">
                                    <Stack direction={"row"} spacing={2}>
                                        {
                                            upComing.map((e) => {
                                                return <UpcomingBookingCard dimension={dimension} data={e} />
                                            })
                                        }
                                    </Stack>
                                </ScrollContainer>
                            </Box>
                        </Box>
                        <Box sx={{ background: "#fff" }} p={"32px 12px"}>
                            <Typography className={classes.title} p={"24px 0px 12px 0px"}>Popular Service</Typography>

                            <ScrollContainer>
                                <Stack direction={"row"} spacing={2} py={"12px"}>
                                    {
                                        popularService?.map((e) => {
                                            return <PopularServiceCard data={e} />
                                        })
                                    }
                                </Stack>
                            </ScrollContainer>

                            <Typography className={classes.title} p={"24px 0px 12px 0px"}>Service at your door step</Typography>

                            <Grid container spacing={"10px"}>
                                {
                                    services?.map((e) => {
                                        return (
                                            <Grid item xs={3} spacing={1}>
                                                <Stack alignItems={"center"} className={classes.serviceBox} p={"8px 14px"}>
                                                    <img src={e.icon} alt="" />
                                                    <Stack alignItems={"center"}>
                                                        <Typography className={classes.serviceTitle}>{e?.title1}</Typography>
                                                        <Typography className={classes.serviceTitle}>{e?.title2}</Typography>
                                                    </Stack>
                                                </Stack>

                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>

                            <Typography className={classes.title} p={"24px 0px 12px 0px"}>Offers</Typography>
                            <img src='/images/serviceGotoIcons/offerCard.png' alt='' style={{ width: "100%" }} />
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.footer}>
                    <Stack direction={"row"} justifyContent={"space-evenly"}>
                        <Stack alignItems={"center"} spacing={"4px"}>
                            <HomeIcon />
                            <Typography className={classes.activeText}>Home</Typography>
                        </Stack>
                        <Stack alignItems={"center"} spacing={"4px"}>
                            <ServiceIcon />
                            <Typography className={classes.inactiveText}>Search</Typography>
                        </Stack>
                        <Stack alignItems={"center"} spacing={"4px"}>
                            <BookingIcon />
                            <Typography className={classes.inactiveText}>Bookings</Typography>
                        </Stack>
                        <Stack alignItems={"center"} spacing={"4px"}>
                            <AccountIcon />
                            <Typography className={classes.inactiveText}>Accounts</Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Container>
        </>
    )
}