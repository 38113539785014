import React from "react"

export const CompletionIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
  <g id="Group_117717" data-name="Group 117717" transform="translate(6340 1515)">
    <rect id="Rectangle_58550" data-name="Rectangle 58550" width="28" height="28" rx="14" transform="translate(-6340 -1515)" fill="#6dafb3"/>
    <g id="Group_117716" data-name="Group 117716" transform="translate(-93 -67)">
      <g id="Rectangle_58549" data-name="Rectangle 58549" transform="translate(-6240 -1441)" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">
        <rect width="14" height="14" stroke="none"/>
        <rect x="0.5" y="0.5" width="13" height="13" fill="none"/>
      </g>
      <path id="icons8-verified-badge_1_" data-name="icons8-verified-badge (1)" d="M17.725,11.366a1.638,1.638,0,0,0-.466-2.644l-.7-.324a.562.562,0,0,1-.322-.558l.067-.764a1.639,1.639,0,0,0-2.057-1.726l-.741.2a.565.565,0,0,1-.606-.221l-.44-.628a1.638,1.638,0,0,0-2.685,0l-.44.628a.565.565,0,0,1-.606.221l-.741-.2A1.639,1.639,0,0,0,5.937,7.075L6,7.839a.564.564,0,0,1-.322.559l-.7.324a1.638,1.638,0,0,0-.466,2.644l.542.542a.563.563,0,0,1,.112.635l-.325.7a1.639,1.639,0,0,0,1.343,2.326l.764.067a.564.564,0,0,1,.494.414l.2.741a1.639,1.639,0,0,0,2.524.918l.628-.44a.564.564,0,0,1,.645,0l.628.44a1.639,1.639,0,0,0,2.523-.918l.2-.741a.562.562,0,0,1,.494-.414l.764-.067A1.639,1.639,0,0,0,17.4,13.238l-.325-.7a.563.563,0,0,1,.112-.635ZM14.1,9.945l-3.59,3.59a.539.539,0,0,1-.762,0L7.953,11.74a.538.538,0,1,1,.761-.761l1.414,1.414,3.209-3.209a.539.539,0,0,1,.762.761Z" transform="translate(-6244.206 -1445)" fill="#fff"/>
    </g>
  </g>
</svg>

    )
}