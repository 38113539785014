import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";
import { DeleteAssetIcon } from "../../assets/deleteAsset";
import { UploadAssetIcon } from "../../assets/uploadAsset";
import { Upload } from "../../components/upload/fileupload";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps, allowed_file_size, generateImageUrl, img_size, multiFileUpload } from "../../utils";
import { FontFamilySwitch } from '../../utils/common';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";

const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined
  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

const Step3 = (props) => {
  const { t = () => false, handleTopBarState } = props
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  // const [uploaddocc, setuploaddocc] = React.useState(
  //   props?.request?.selectedImages ? props.request.selectedImages : []
  // );


  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const uploadImageS3 = async (file) => {
    try {
      let propsData = { companyId: props?.request?.unit?.property?.company?.value, file_meta: true }
      const Images = await multiFileUpload(file, propsData, alert, allowed_file_size)
      if (Images?.length > 0) {
        props.updateFunction(Images)
        props?.request?.uploadedImages?.push(...Images);
        props?.setRequest(props.request);
      }
    }
    catch (err) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    }

  }

  const handleUpload = async (e) => {
    if (e.target.files && props?.request?.selectedImages?.length < 5) {
      backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading..." })
      let files = e.target.files;
      const filesArray = Array.from(e.target.files).map((val, i) => {
        return {
          url: URL.createObjectURL(val),
          is_active: true,
          file_meta: {
            name: files?.[0]?.name,
            size: files?.[0]?.size,
            type: files[0]?.type
          }
        }
      });
      if (filesArray?.length <= 5 && props.request.selectedImages.concat(filesArray)?.length <= 5) {
        props.request.selectedImages =
          props.request.selectedImages.concat(filesArray);
        uploadImageS3(files)

        props.setRequest(props.request);
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        Array.from(e.target.files).map(
          (file) => URL.revokeObjectURL(file) // avoid memory leak
        );
      }
      else{
        backdrop.setBackDrop({ ...backdrop, open: false, message: "Loading..." })
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("You can't upload more than 5 images"),
        });
      }
    }
    else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("5 / 5 images are Uploaded , limit reached"),
      });
    }
  };
  // const handleDelete = (item) => {
  //   // props.request.selectedImages = props.request.selectedImages?.filter((index) => index !== i)
  //   // props.request.uploadedImages = props.request.uploadedImages?.filter((index) => index !== i)
  //   // props.setRequest(props.request);
  //   // setuploaddocc(uploaddocc?.filter(( index) => index !== i));
  //   props?.handleDelete(item) 
  //   // setuploaddocc(uploaddocc?.filter((item,index) => index !== i))
  // };
  // const onRemoveAll = () => {
  //   // props.request.selectedImages = [];
  //   // props.request.uploadedImages = [];
  //   // props.setRequest(props.request);
  //   props?.onRemoveAll()
  //   // setuploaddocc([]);
  // };

  React.useEffect(() => {
    handleTopBarState({
      text: t("New Maintenance Request"),
      customBackHandle: () => props?.goBack()
    })
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        {/* <TitleBar

          text={t("NewMaintenanceRequest")}
          goBack={goBack}
        /> */}
        <Box className={classes.content}>
          <Box p={2}>
            <Stack direction="row" alignItems="center">
              <Box style={{ marginInlineEnd: "8px" }}><UploadAssetIcon /></Box>
              <Box>
                <Typography className={classes.serviceTitle}>{t("Upload Images")}</Typography>
                <Typography className={classes.steptitle}>{t("STEP")} {props?.step} / 4</Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box p={2}>
            <Upload
              onChange={handleUpload}
              single={props?.single}
              state={props?.images}
              // disabled={props?.request?.selectedImages?.length>=5?true:false}
              btnText={t("Upload Images Here")}
              backgroundColor="#F1F7FF"
              padding={0}
            />

            {props?.request?.selectedImages?.length > 0 && (

              <div>
                <Box mt={2} display="flex" justifyContent={"space-between"}>
                  <Typography
                    style={{
                      color: "#404E61",
                      fontSize: "1rem",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("Uploaded")} {props?.request?.selectedImages?.length} / 5 {t("images")}
                  </Typography>
                  <Typography
                    onClick={() => props?.onRemoveAll()}
                    style={{
                      cursor: "pointer",
                      color: "#5078E1",
                      fontSize: "0.875rem",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {t("RemoveAll")}
                  </Typography>
                </Box>
                <Box mt={1.5} style={{ maxHeight: size?.height - 490, overflow: "scroll" }}>
                  {props?.request?.selectedImages?.map((item, i) => {
                    return (
                      <>
                        <Box display="flex" justifyContent={"space-between"} alignItems="center">
                          <Stack direction="row" alignItems="center">
                            <Box style={{ marginInlineEnd: "8px" }}>
                              <img
                                src={generateImageUrl(item?.url, img_size.small_rectangle) ?? ""}
                                alt="upload"
                                className={classes.uploadedImg}
                              />
                            </Box>
                            <Box>
                              <Typography className={classes.imgName} noWrap>{item?.file_meta?.name}</Typography>
                              <Typography className={classes.productDetailsTitle}>{item?.file_meta?.size}{item?.file_meta?.type}</Typography>
                            </Box>
                          </Stack>
                          <Box className={classes.deleteIcon} onClick={() => props?.handleDelete(item, i)}><DeleteAssetIcon /></Box>
                        </Box>
                        {props?.request?.selectedImages?.length - 1 !== i &&
                          <Box mt={2} mb={2}><Divider /></Box>
                        }
                      </>
                    )
                  })}
                </Box>
              </div>
            )}
          </Box>
        </Box>
        {/* <Grid container className={classes.screen} xs={12}>
          <div style={{ zIndex: 999, width: "100%" }}>
            <Grid
              container
              className={classes.stepperBody}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={1}>
                <div className={classes.circle}>
                  <div className={classes.stepper}>3</div>
                </div>
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.steps}>{t("Step")} 3 / 4</Typography>
                <Typography className={classes.details}>
                  {t("UploadImages")}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={75}

              />
            </Box>
          </div>
        </Grid>
        <Grid
          container
          className={classes.screenPadding}
          style={{ height: size?.height - 200 }}
        >
          <Grid item xs={12}>
            <br />
            <div className={classes.root} noValidate>
              <Grid>
                <Grid item>
                  <Upload
                    onChange={handleUpload}
                    single={props?.single}
                    state={props?.images}
                    btnText={t("Upload Images Here")}
                  />
                </Grid>

                {props?.images?.length > 0 && (
                  <div>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.Container}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography
                              style={{
                                color: "#404E61",
                                fontSize: "1rem",
                                fontFamily: FontFamilySwitch().semiBold,
                              }}
                            >
                              {t("Uploaded")} {props?.images?.length} {t("images")}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              onClick={() => props?.onRemoveAll()}
                              style={{
                                cursor: "pointer",
                                color: "#5078E1",
                                fontSize: "0.875rem",
                                fontFamily: FontFamilySwitch().semiBold,
                              }}
                            >
                              {t("RemoveAll")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      {props?.images?.map((item) => {
                        return (
                          item !== false &&
                          <Grid item xs={3} style={{ position: "relative" }}>
                            <img
                              src={generateImageUrl(item?.url, img_size.small_rectangle) ?? ""}
                              alt="upload"
                              style={{
                                width: "100%",
                                height: "75.6px",
                                borderRadius: '4px',
                              }}
                            ></img>
                            {!props?.isReadonly && (
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "8px",
                                  right: "-8px",
                                }}
                                onClick={() => props?.handleDelete(item)}
                                aria-label="settings"
                                disabled={props?.isReadonly}
                              >
                                <img
                                  src="/images/Group 96059.svg"
                                  alt="gruop"
                                />
                              </IconButton>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid> */}
        <Container maxWidth="sm" className={classes.btnRoot}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btnPrevious}
                onClick={() => {
                  props.previous(2);
                }}
              >
                {t("Previous")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btn}
                onClick={() => {
                  props.next(4);
                }}
              >
                {t("Next")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>

    </div>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("maintance")(Step3), props)
