import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalculate } from "../../../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:"white",
    overflow: "hidden",
    borderRadius:"8px",
    padding:"16px"
  },
  first_name:{
    fontSize:remCalculate(14),
    color:theme.typography.color.primary,
    fontFamily:FontFamilySwitch()?.semiBold
  },
  posted_at:{
    fontSize:remCalculate(12),
    color:theme.typography.color.secondary,
    fontFamily:FontFamilySwitch()?.regular
  },
  title:{
    fontSize:remCalculate(14),
    color:"#071741",
    fontFamily:FontFamilySwitch()?.semiBold,
    textWrap:"noWrap",
    textOverflow:"ellipsis",
    overflow:"hidden"
  },
  description:{
    fontSize:remCalculate(12),
    color:theme.typography.color.secondary,
    fontFamily:FontFamilySwitch()?.regular,
    margin:"0px !important"
  },
  seeMoreLessTextStyle: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch()?.semiBold,
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  attachFile:{
    fontSize:remCalculate(10),
    color:theme.typography.color.secondary,
    fontFamily:FontFamilySwitch()?.semiBold
  },
  attachBox:{
    backgroundColor:"#F2F4F7",
    borderRadius:"10px",
    padding:"4px 12px",
    width:"fit-content"
  },
  dot:{
    borderRadius:"50%",
    backgroundColor:"#E4E8EE",
    width:"6px",
    height:"6px"
  },
  likeIcon:{
    fontSize:remCalculate(14),
    color:theme.typography.color.secondary,
    fontFamily:FontFamilySwitch()?.semiBold,
    marginLeft:"5px"
  },
  bottomFix:{
    position:"absolute",
    bottom:"10px"
  },
  bottomImagefix:{
    position:"absolute",
    bottom:0,
    left:0,
    right:0,
    padding:"16px"
  },
}))