import React from "react";
import CommunityDiscussion from "./communityDiscussion";

class CommunityDiscussionParent extends React.Component {
  render() {
    return <CommunityDiscussion {...this?.props} />;
  }
}


export default CommunityDiscussionParent;
