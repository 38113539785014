import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
import { FontFamilySwitch, generateImageUrl, img_size, isExpired } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { VisitorPass } from "../../assets/community/VisitorPass";
import VisitorImage from "../../assets/visitorImage";


const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: "8px 8px 0px 0px",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    padding: "12px",
    borderBottom: "1px solid #E4E89EE"
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  expired: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#98A0AC",
    padding: "2px 8px",
    borderRadius: "0px 0px 8px 8px",
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "2px 8px",
    borderRadius: "0 0 12px 12px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "0 0 12px 12px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0 0 12px 12px",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
    borderRadius: "0 0 12px 12px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "70px",
    width: "70px",
    borderRadius: "10px",
    backgroundColor: "#f1f7ff",
    // objectFit: "cover",
    // [theme.breakpoints.down(321)]: {
    //   height: "140px",
    // },
  },
  bold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem"
    },
  },
  semiBold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,

  },
  bottomText: {
    bottom: 0,
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px"
  },
  flag: {
    backgroundColor: (props) => props?.data?.request_type === "VGR" ? "#6D6DB3" : "#5078E1",
    padding: "2px 8px",
    position: "absolute",
    top: "4px",
    right: "-10px",
    borderRadius: "4px",
    boxShadow: '0px 3px 6px #00000029'
  },
  flagChild: {
    height: "10px",
    width: "11px",
    backgroundColor: (props) => props?.data?.request_type === "VGR" ? "#6D6DB3" : "#5078E1",
    bottom: "-8px",
    right: "-8px",
    position: "absolute",
    borderBottomRightRadius: '195px'
  },
  flagName: {
    color: "white",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  bottomCard: {
    backgroundColor: "#fff",
    borderRadius: "0px 0px 8px 8px",
    padding: "10px 14px",
    margin: "auto 4px",
    borderTop: "1px solid #E4E8EE"
  }
}));

export const PassCard = (props) => {
  const classes = useStyles(props);
  const { t=()=>false } = props
  const history = useHistory();
  const size = useWindowDimensions();
  const pass_type = {
    WGR: t("Worker Pass"),
    VGR: t("Visitor Pass")
  }

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Stack direction={"row"} className={classes.card}
          onClick={() => {
            props?.data?.request_type === "VGR" ?
              history.push(Routes.visitorGatePassDetails + "?passId=" + props?.data?.id) :
              history.push(Routes.workerGatePassDetails + "?passId=" + props?.data?.id)
          }}
          alignItems="center"
        >
          <Box style={{ position: "relative" }}>
            {(props?.data?.visitor_image || props?.data?.visitors?.[0]?.visitor_image) ?
              <img src={generateImageUrl(props?.data?.visitor_image, img_size.small_square) ??
                generateImageUrl(props?.data?.visitors?.[0]?.visitor_image, img_size.small_square)} alt="" className={classes.avatar} /> :
              <Avatar className={classes.avatar} variant="square">
                <VisitorPass />
              </Avatar>
            }
            {!props?.isEntries &&
            <Box style={{ position: "absolute" }} className={classes.bottomText1}>
              {isExpired(props?.data?.request_to, new Date().toISOString()) ?
                <Typography
                  variant="subtitle2"
                  noWrap
                  className={classes.expired}
                >
                  {t("Expired")}
                </Typography> :
                <>
                  {props?.data?.status === "Pending" && (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      className={classes.progress}
                    >
                      {t("In Progress")}
                    </Typography>
                  )}
                  {props?.data?.status === "Approved" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.approved}
                    >
                      {t("Approved")}
                    </Typography>
                  )}
                  {props?.data?.entries_status === "Yet To Checkout" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.progress}
                    >
                      {t("Yet To Checkout")}
                    </Typography>
                  )}
                  {props?.data?.status === "Rejected" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.rejected}
                    >
                      {t("Rejected")}
                    </Typography>
                  )}
                  {props?.data?.entries_status === "Checkout" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.rejected}
                    >
                      {t("Checkout")}
                    </Typography>
                  )}
                  {props?.data?.status === "Cancelled" && (
                    <Typography variant="subtitle2" className={classes.delete}>
                      {t("Cancelled")}
                    </Typography>
                  )}
                </>}
            </Box>}
          </Box>
          <Box flexGrow={1}>
            <Stack sx={{ marginInlineStart: "16px" }} direction={"column"} spacing={"6px"}>
              <Typography className={classes.title} noWrap>
              {props?.isEntries
                ?(props?.data?.visitors?.[0]?.name ?? "-")
              :(props?.data?.visitors_name ?? "-")}
              </Typography>
              <Stack direction="row" divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" flexItem />}>
                <Typography
                  variant="subtitle2"
                  className={classes.sub}
                  noWrap
                >
                  {props?.isEntries ? (pass_type[props?.data?.request_type]) : props?.data?.unit?.[0]?.name}
                </Typography>
                <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                    sx={{ maxWidth: size?.width - 155 }}
                  >
                    {props?.isEntries 
                    ?props?.data?.request_no
                    :(props?.data?.stay_type === "Short" 
                    ? t("Single Access")
                  :t("Multiple Access"))}
                  </Typography>
              </Stack>
              {/* <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                {(props?.data?.visitor_mobile || props?.data?.visitors?.[0]?.mobile_no) &&
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {props?.data?.visitor_mobile ? `${props?.data?.mobile_country_code ?? ""} ${props?.data?.visitor_mobile ?? ""}` :
                      `${props?.data?.visitors?.[0]?.mobile_country_code ?? ""} ${props?.data?.visitors?.[0]?.mobile_no ?? ""}`}
                  </Typography>}
                <Typography
                  variant="subtitle2"
                  className={classes.sub}
                  noWrap
                >
                  {props?.data?.pass_type === "Individual" ?
                    props?.data?.pass_type :
                    <>
                      {props?.data?.pass_type} {" "}
                      {props?.data?.visitor_group_count > 1 ?
                        <>
                          {"+" + (props?.data?.visitor_group_count - 1)}
                        </>
                        :
                        <>
                          {props?.data?.visitor_group_count}
                        </>
                      }
                    </>
                  }
                </Typography>
              </Stack> */}
              {!props?.isEntries &&
              <Typography
                variant="subtitle2"
                className={classes.sub}
                noWrap
                sx={{ maxWidth: size?.width - 155 }}
              >
                {props?.data?.property_name}
              </Typography>}
            </Stack>
          </Box>
        </Stack>
        <Stack className={classes.bottomCard} justifyContent={"space-around"} direction="row" alignItems={"center"} spacing={1} marginTop="6px" 
        divider={<Divider orientation="vertical" flexItem />} >
          <Box display={"flex"} alignItems={"center"} gap={1} width={"50%"} justifyContent={"center"}>
            <DateIcon />
            {props?.isEntries
            ?<Typography
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            &#x202a;{props?.data?.visitors?.[0]?.check_in_time?moment(props?.data?.visitors?.[0]?.check_in_time)
              .format("DD MMM YY, hh:mm a"):"-"}&#x202c;
            
          </Typography>
            :<Typography
              variant="subtitle2"
              className={classes.bold}
              noWrap
            >
              &#x202a;{moment(props?.data?.request_from)
                .format("DD MMM YY")}&#x202c;
              &nbsp;
              {props?.data?.stay_type === "Short" ? "" :
                <>
                  -&nbsp;
                  &#x202a;{moment(props?.data?.request_to)
                    .format("DD MMM YY")}&#x202c;
                </>
              }
            </Typography>}
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} width={"50%"} justifyContent={"center"}>
            {props?.isEntries
              ?<>
              <DateIcon />
              {props?.data?.visitors?.[0]?.check_out_time
              ?<Typography
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            &#x202a;{moment(props?.data?.visitors?.[0]?.check_out_time)
              .format("DD MMM YY, hh:mm a")}&#x202c;
            
          </Typography>
          :<Typography
          variant="subtitle2"
          className={classes.bold}
          noWrap
        >
          {t("Yet To Checkout")}
          
        </Typography>}
              </>
              :<>
            <VisitorImage />
            <Typography
              variant="subtitle2"
              className={classes.bold}
              noWrap
            >
              {props?.data?.visitors_count}{" "}{props?.data?.request_type === "VGR" ? t("Visitors") : t("Workers")}
            </Typography>
            </>}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

