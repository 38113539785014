import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import { DatePickerNew, TextBox } from "../../../components";
import ButtonGroupSelect from "../../../components/ButtonGroupSelect";
import { SelectBox } from "../../../components/dropDown";
import { WorkerContext } from "../context";
import { useStyles } from "./styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UnitAndScheduleCard from "./cards/unitAndScheduleCard";
const UnitAndSchedule = () => {
  const { t = () => false, data,setData, updateState, UnitAndScheduleFormSubmit, getUnitIDS, agreementList, setOpenConfirmationDrawer } =
    React.useContext(WorkerContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  React.useEffect(() => {
    getUnitIDS();
    // eslint-disable-next-line
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (state) => {
    if (state) {
      updateState("unit_and_schedule", "workFromTime", state);
    }
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = (state) => {
    if (state) {
    updateState("unit_and_schedule", "workToTime", state);
    }
    setAnchorEl2(null);
  };

  const updateObject = (key, value) => {
    updateState("unit_and_schedule", key, value);
  };
  const cancelForm = ()=>{
    if(data?.unit_and_schedule?.is_updated && typeof data?.unit_and_schedule?.previous_state === "string"){
      setOpenConfirmationDrawer(true);
    }
    // else {
    //   history?.goBack(-1)
    // }
     if (typeof data?.unit_and_schedule?.previous_state === "object") {
      setData({ ...data, unit_and_schedule: { ...data?.unit_and_schedule?.previous_state } });
    } 
  }
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  return (
    data?.unit_and_schedule?.view_type !== "hide" &&
    <>
      {data?.unit_and_schedule?.view_type === "edit" &&
        <Box className={classes.root}>
          <Box className={classes.mainPadding}>
            <Box className={classes.input}>
              <SelectBox
                isRequired
                label={t("Select Unit")}
                placeholder={t("Select Unit")}
                labelColor={"#4E5A6B"}
                value={data?.unit_and_schedule?.unit}
                options={agreementList}
                onChange={(value) => {
                  updateState("unit_and_schedule", "unit", value);
                }}
                isError={data?.unit_and_schedule?.error?.unit?.length > 0}
                errorMessage={data?.unit_and_schedule?.error?.unit}
              />
            </Box>
            <Box className={classes.input}>
              <ButtonGroupSelect
                labelColor={"#4E5A6B"}
                isRequired
                label={t("Entry Type")}
                data={entryType}
                state={data?.unit_and_schedule}
                stateName={"entryType"}
                updateState={updateObject}
              />
            </Box>
            <Box className={classes.input}>
              <ButtonGroupSelect
                labelColor={"#4E5A6B"}
                isRequired
                label={t("Stay Type")}
                data={stayType}
                state={data?.unit_and_schedule}
                stateName={"stayType"}
                updateState={updateObject}
              />
            </Box>
            <Box className={classes.input}>
              <Grid container spacing={2}>
                <Grid item xs={data?.unit_and_schedule?.stayType?.value === "Long" ? 6 : 12}>
                  <DatePickerNew
                    isRequired
                    dateFormat={"dd-MMM-yy"}
                    placeholder={t("Start Date")}
                    value={data?.unit_and_schedule?.startData ? data?.unit_and_schedule?.startData : new Date()}
                    onChange={(value) =>
                      updateState("unit_and_schedule", "startDate", value)
                    }
                    isError={data?.unit_and_schedule?.error?.startDate?.length > 0}
                    errorMessage={data?.unit_and_schedule?.error?.startDate}
                    minDate={new Date()}
                  />
                </Grid>
                {data?.unit_and_schedule?.stayType?.value === "Long" &&
                  <Grid item xs={6}>
                    <DatePickerNew
                      isRequired
                      dateFormat={"dd-MMM-yy"}
                      placeholder={t("End Date")}
                      value={data?.unit_and_schedule?.endDate ? data?.unit_and_schedule?.endDate : new Date()}
                      onChange={(value) =>
                        updateState("unit_and_schedule", "endDate", value)
                      }
                      isError={data?.unit_and_schedule?.error?.endDate?.length > 0}
                      errorMessage={data?.unit_and_schedule?.error?.endDate}
                      minDate={data?.unit_and_schedule?.startDate ?? new Date()}
                    />
                  </Grid>
                }
              </Grid>
            </Box>
            <Box className={classes.input}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={5}>
                  <TextBox
                    isRequired
                    color
                    type={"number"}
                    labelColor={"#4E5A6B"}
                    label={t("Hours of Work From")}
                    placeholder=" "
                    value={data?.unit_and_schedule?.workFrom}
                    onChange={(value) =>
                      updateState(
                        "unit_and_schedule",
                        "workFrom",
                        value.target.value
                      )
                      }
                    isError={data?.unit_and_schedule?.error?.workFrom?.length > 0}
                    errorMessage={data?.unit_and_schedule?.error?.workFrom}
                    endAdornment={
                      <Box onClick={handleClick} className={classes.endAdornment}>
                        <Typography className={classes.time} noWrap>
                          {data?.unit_and_schedule?.workFromTime}
                        </Typography>
                        <KeyboardArrowDownIcon sx={{ fontSize: "0.875rem", marginTop: "4px" }} />
                      </Box>
                    }
                  />
                  <Popover
                    id={"1"}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={()=>handleClose()}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography
                      onClick={() => handleClose("AM")}
                      className={classes.timeVariant}
                    >
                      AM
                    </Typography>
                    <Typography
                      onClick={() => handleClose("PM")}
                      className={classes.timeVariant}
                    >
                      PM
                    </Typography>
                  </Popover>
                </Grid>
                <Grid item xs={6} sm={5}>
                  <TextBox
                    isRequired
                    color
                    type={"number"}
                    labelColor={"#4E5A6B"}
                    label={t("Hours of Work To")}
                    placeholder=" "
                    value={data?.unit_and_schedule?.workTo}
                    onChange={(value) =>
                      updateState("unit_and_schedule", "workTo", value.target.value)
                    }
                    isError={data?.unit_and_schedule?.error?.workTo?.length > 0}
                    errorMessage={data?.unit_and_schedule?.error?.workTo}
                    endAdornment={
                      <Box onClick={handleClick2} className={classes.endAdornment}>
                        <Typography className={classes.time} noWrap>
                          {data?.unit_and_schedule?.workToTime}
                        </Typography>
                        <KeyboardArrowDownIcon sx={{ fontSize: "0.875rem", marginTop: "4px" }} />
                      </Box>
                    }
                  />
                  <Popover
                    id={"2"}
                    open={open2}
                    anchorEl={anchorEl2}
                    onClose={()=>handleClose2()}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography
                      onClick={() => handleClose2("AM")}
                      className={classes.timeVariant}
                    >
                      AM
                    </Typography>
                    <Typography
                      onClick={() => handleClose2("PM")}
                      className={classes.timeVariant}
                    >
                      PM
                    </Typography>
                  </Popover>
                </Grid>
              </Grid>
            </Box>
            {/* /sourec/compo/filter/minMaxtextwith line 155*/}
            <Box height={"8px"} />
            <Box className={classes.input}>
              <Box className={classes.groupInputBox}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.unit_and_schedule?.group}
                        onChange={() =>
                          updateState(
                            "unit_and_schedule",
                            "group",
                            !data?.unit_and_schedule?.group
                          )
                        }
                        name="group"
                        icon={
                          <RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />
                        }
                        checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                      />
                    }
                    label={
                      <Typography component={"span"} className={classes.label}>
                        Group
                      </Typography>
                    }
                  />
                </FormGroup>
                {data?.unit_and_schedule?.group && (
                  <>
                    {" "}
                    <Divider
                      orientation="vertical"
                      sx={{
                        borderLeft: "1px solid #E4E8EE",
                        padding: "16px 0",
                        marginRight: "8px",
                      }}
                    />
                    <Box flexGrow={1} >
                      <Typography sx={{}} className={classes.label} noWrap>
                        Enter Group Count
                      </Typography>
                    </Box>
                    <Box width="60px" ml={1}>
                      <TextBox
                        isRequired
                        color
                        type={"number"}
                        placeholder=" "
                        value={data?.unit_and_schedule?.groupCount}
                        onChange={(value) =>
                          updateState(
                            "unit_and_schedule",
                            "groupCount",
                            value.target.value
                          )
                        }
                        isError={
                          data?.unit_and_schedule?.error?.groupCount?.length > 0
                        }

                      />
                    </Box>
                  </>
                )}
              </Box>
              <Typography className={classes.labelError}  >
                {data?.unit_and_schedule?.error?.groupCount}
              </Typography>
            </Box>
            <Box className={classes.input}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.unit_and_schedule?.registeredWorker}
                      onChange={() =>
                        updateState(
                          "unit_and_schedule",
                          "registeredWorker",
                          !data?.unit_and_schedule?.registeredWorker
                        )
                      }
                      name="registeredWorker"
                      icon={<RadioButtonUncheckedIcon sx={{ color: "#CED3DD" }} />}
                      checkedIcon={<CheckCircleIcon sx={{ color: "#5078E1" }} />}
                    />
                  }
                  label={
                    <Typography component={"span"} className={classes.label}>
                      Are you deputing Registered Workers
                    </Typography>
                  }
                />
              </FormGroup>
            </Box>
          </Box>
          <Box className={classes.borderBottom} />
          <Grid
            container
            className={classes.btnroot}
            justifyContent="space-between"
            alignItems={"center"}
            spacing={0}
          >
            <Grid item xs={5}>
              <Button
                fullWidth
                className={classes.secondary}
                variant="contained"
                disabled={!data?.unit_and_schedule?.is_updated}
              onClick={() => cancelForm()}
              >
                {t("Cancel")}
              </Button>
            </Grid>
            <Divider
              orientation="vertical"
              sx={{ borderLeft: "1px solid #98A0AC", padding: "14px 0" }}
            />
            <Grid item xs={5}>
              <Button
                fullWidth
                className={classes.primary}
                variant="contained"
                onClick={() => UnitAndScheduleFormSubmit()}
              >
                {t("Next")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
      {data?.unit_and_schedule?.view_type === "view" &&
        <UnitAndScheduleCard />
      }
      <Box height={"12px"} />
    </>
  );
};

export default UnitAndSchedule;

const entryType = [
  {
    value: "single",
    label: "Single",
  },
  {
    value: "multiple",
    label: "Multiple",
  },
];

const stayType = [
  {
    value: "Short",
    label: "One Day",
  },
  {
    value: "Long",
    label: "Period",
  },
];
