import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    minHeight: "94px",
    display: "flex"
  },
  Cardcontent: {
    padding: "12px 16px",
    flex: "auto",
    [theme.breakpoints.down(321)]: {
      padding: "8px",

    },
  },
  title: {
    fontSize: "0.75rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  count: {
    fontSize: "1.25rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },

  image: {
    width: 46,
    height: 46,
    backgroundColor: (props) => props?.data?.color,
  },

  sideBorder: {
    backgroundColor: (props) => props?.data?.borderColor,
    margin: " 3px 0px",
    borderRadius: " 0px 10px 10px 0px",
    border: (props) => `3px solid ${props?.data?.borderColor}`
  }
}));

export const HomepageCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <div
        className={classes.card}
        onClick={() => {
          history.push({
            pathname: props.data.link,
            state: props.data.main,
          });
        }}
      >
        <Box className={classes.sideBorder} />
        <div className={classes.Cardcontent}>
          <Grid container justifyContent={"space-between"}>
            <Grid
              item
              direction={"column"}
              xs={7}
              justifyContent={"space-between"}
            >
              <Typography variant="body1" className={classes.count}>
                {props.data.count}
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {props.data.name}
              </Typography>
            </Grid>
            <Grid item xs={5} textAlign="right">
              <Box height="2px" />
              {props.data.image}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
