import React from "react";

function FixedDeposit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            stroke="#707070"
            strokeWidth="1"
            d="M0 0H21V18H0z"
            data-name="Rectangle 55527"
            transform="translate(81.699 184.39)"
          ></path>
        </clipPath>
      </defs>
      <g data-name="Group 113456" transform="translate(-37 -308)">
        <rect
          width="42"
          height="42"
          fill="#ffe5dc"
          data-name="Rectangle 57716"
          rx="4"
          transform="translate(37 308)"
        ></rect>
        <g data-name="Group 113442" transform="translate(5 -523)">
          <g
            clipPath="url(#clip-path)"
            data-name="Group 105888"
            transform="translate(-38.699 658.61)"
          >
            <path
              fill="#f15a29"
              d="M6.556 6A1.566 1.566 0 005 7.556v7.556a1.566 1.566 0 001.556 1.556h13.779a1.566 1.566 0 001.556-1.556V7.556A1.566 1.566 0 0020.335 6zm1.111 1.333h11.557a1.332 1.332 0 001.333 1.333V14a1.332 1.332 0 00-1.333 1.333H7.667A1.332 1.332 0 006.333 14V8.667a1.332 1.332 0 001.334-1.334zm5.778.889a2.918 2.918 0 00-2.667 3.111 2.918 2.918 0 002.667 3.111 2.918 2.918 0 002.667-3.111 2.918 2.918 0 00-2.667-3.111zm-5.334 2.223a.889.889 0 10.889.889.889.889 0 00-.889-.889zm10.668 0a.889.889 0 10.889.889.889.889 0 00-.889-.889zM6.353 17.556a1.533 1.533 0 001.472 1.333 26.482 26.482 0 0110.335 2.643c.342.154.637.286.88.385a1.1 1.1 0 001.517-1.017v-3.34h-1.333v1.778a1.326 1.326 0 00-1.164.694 26.94 26.94 0 00-10.188-2.475H6.353z"
              transform="translate(78.754 179.39)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FixedDeposit;