import { Box, Typography } from "@mui/material";
// import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { FontFamilySwitch } from "../../utils/common";
const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E8EE",
    padding: '12px',
    cursor: "pointer"
  },
  list2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E8EE",
    cursor: "pointer",
    padding: '12px',

  },
  Tittle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
  },
  contant: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
  },
  expires: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
    backgroundColor: "#F2F4F7",
    padding: "5px",
    marginInlineStart: "10px",
    borderRadius: theme.palette.borderRadius
  },
  lease: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  Appartment: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  root: {
    marginTop: (props) => props?.agreementList && "12px",
  },
  box: {
    display: "flex", marginTop: "5px"
  },
  agreementImg: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "6px 6px 0px 6px"
  }
}));
export const AgreementList = (props) => {
  moment.defaultFormat = "YYYY-MM";
  const classes = useStyles(props);
  // const history = useHistory();
  return (
    <div className={classes.root}>
      {props?.agreementList ?
        props?.agreementList?.map((val) => {
          return (
            <>
              <Box
                className={classes.list}
                onClick={() => props?.updateagrement(val)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* agreement image */}
                  <Box className={classes.agreementImg}>
                    <img src="/images/agreementnew.svg" alt="agreement_img" />
                  </Box>
                  {/* Agreement number */}
                  <Box style={{ marginInlineStart: "10px" }}>
                    <div>
                      <Typography className={classes.Tittle}>
                        {val.agreement_no}
                        {val.id ===
                          props?.agreement?.id && val?.expiry_on && (
                            <span className={classes.expires}>
                              {props.expiresOn}&nbsp;
                              &#x202a;{moment(val?.expiry_on)
                                .tz(moment.tz.guess())
                                .format("DD MMM YY")}&#x202c;
                            </span>
                          )}
                      </Typography>
                      {val?.expiry_on &&
                        <Box className={classes.box}>
                          <Typography className={classes.lease}>
                            {"Lease Start :"}&nbsp;
                            {val?.lease_start_date &&
                              moment(val?.lease_start_date)
                                .format("DD MMM YY")
                            }
                          </Typography>
                          <Box className={classes.dot} />
                          <Typography className={classes.lease}>
                            {"Lease End :"}&nbsp;
                            {moment(val?.expiry_on)
                              .format("DD MMM YY")}
                          </Typography>
                        </Box>
                      }
                    </div>
                  </Box>
                </Box>
                <Box>
                  {val.id ===
                    props?.agreement?.id ? (
                    <img src="/images/ticknew.svg" alt="tick_img" />
                  ) : (
                    <img src="/images/arrownew.svg" alt="agreement_img" />
                  )}
                </Box>
              </Box>
            </>
          );
        }) :
        props?.languageOptions?.map((item) => {
          return (
            <>
              <Box
                className={classes.list2}
                onClick={() => props?.updateLanguage(item)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* language name */}
                  <Box>
                    <div>
                      <Typography className={classes.Tittle}>
                        {item.label}
                      </Typography>
                    </div>
                  </Box>
                </Box>
                <Box>
                  {item.value ===
                    props?.selectedLanguage?.value && (
                      <img src="/images/ticknew.svg" alt="tick_img" />
                    )}
                </Box>
              </Box>
            </>
          )
        })


      }

    </div>
  );
};
