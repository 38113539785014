import { Badge, Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { InspectionCard } from "../../components/inspectionCard";
import { DialogDrawer, LoderSimmer } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import { Routes } from "../../router/routes";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import { config } from "../../config";
import NoDataFound from "../../components/noDataFound/noDataFound";
import Filter from "../../components/filter";
import { withBottombar } from "../../HOCs";
const InspectionOrders = ({ t = () => false, type = null, title = null, handleTopBarState }) => {
  const typeList = [
    "move-in",
    "move-out",
    "service",
    "general",
    "unit_readiness",
    "unit_handover",
    "site_visit",
    "delivery-order",
  ];
  const list_limit = 10;
  const [offset, setOffset] = React.useState(0);
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const history = useHistory();
  let fullSize = size?.height - 55;
  const [loading, setLoading] = React.useState(true);
  const [inspection_state, set_inspection_state] = React.useState({
    list: [],
    total: 0,
  });
  const [openFilter, setOpenFilter] = React.useState(false);
  // eslint-disable-next-line
  const [filterData, setFilterData] = React.useState({
    type: [
      {
        label: "Move In",
        value: "move-in",
      },
      {
        label: "Move Out",
        value: "move-out",
      },
      {
        label: "Service",
        value: "service",
      },
      {
        label: "General",
        value: "general",
      },
      {
        label: "Unit Readiness",
        value: "unit_readiness",
      },
      {
        label: "Unit Handover",
        value: "unit_handover",
      },
      {
        label: "Site Visit",
        value: "site_visit",
      },
      {
        label: "Delivery Order",
        value: "delivery-order",
      },
    ],
  });
  const [selectedFilter, setSelectedFilter] = React.useState({});

  const onClick = (value) => {
    history.push({
      pathname: "/inspectionUnitCompleted",
      state: {
        value,
      },
    });
  };
  const { state } = useLocation();
  if (!state) {
    history.replace(Routes.agreement);
  }
  React.useEffect(() => {
    handleTopBarState({
      text: t("Inspection Orders")
    })
    setLoading(true);
    getInspectionOrder(0);
    setSelectedFilter({ type: typeList });
    // eslint-disable-next-line
  }, []);
  const fetchMoreData = () => {
    const tempOffset = offset + list_limit;
    setOffset(tempOffset);
    getInspectionOrder(tempOffset, true);
  };
  const applyFilter = (val) => {
    setSelectedFilter(val);
    getInspectionOrder(0, false, val.type);
    setOpenFilter(false);
  };
  const myFilterData = [
    {
      id: "1",
      title: t("Type"),
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: filterData?.type,
    },
  ];
  const getInspectionOrder = (myOffset, fetchMore = false, myTypes) => {
    let types = [];
    if (type === "ServiceRecordOrders") {
      types = ["service", "general"];
    } else {
      // types = ["move-in", "move-out"];
      if (!myTypes) {
        types = typeList;
      } else {
        types = myTypes;
      }
    }
    const payload = {
      agreement_ids: [state?.main?.agreement?.id],
      type: types,
      start: myOffset,
      length: list_limit,
    };
    NetworkCall(
      `${config?.api_url}/move-in-out/tenant`,
      NetWorkCallMethods.post, payload, null, true, false
    ).then((response) => {
      if (fetchMore) {
        set_inspection_state({
          list: [...inspection_state?.list, ...response?.data?.data?.data],
          count: response?.data?.data?.count,
        });
      } else {
        set_inspection_state({
          list: response?.data?.data?.data ?? [],
          count: response?.data?.data?.count,
        });
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Something Went wrong"),
      });
    });
  };
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box sx={{ backgroundColor: "#f6f6f6" }}>
        <Box>
          {/*tab */}
          {/* <TitleBar
            text={title ? title : "Inspection Orders"}
            goBack={() => history.goBack(-1)}
          /> */}
          {/*title */}
          <Container maxWidth="sm">
            <div style={{ height: fullSize, overflow: "overlay" }}>
              <Stack
                my={2}
                spacing={1}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Typography className={classes.heading}>
                  {t("Inspection")} {"(" + (inspection_state?.count ?? "0") + ")"}
                </Typography>
                <Box
                  className={classes.filterBlock}
                  onClick={() => setOpenFilter(true)}
                >
                  <Badge color="primary" variant="dot">
                    <img src="/images/funnel.svg" alt="" />
                  </Badge>
                </Box>
              </Stack>
              {/* <Stack
          mt={1}
          mb={1}
          pr={2}
          pl={2}
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography className={classes.caseTxt}>
            {t("Case Management")} {"(" + caseManagementList?.length + ")"}
          </Typography>
          <Box
            className={classes.filterBlock}
            onClick={() => setOpenFilter(true)}
          >
            <Badge color="primary" variant="dot">
              <img src="/images/funnel.svg" alt="" />
            </Badge>
          </Box>
        </Stack> */}
              {/*list */}
              <Box sx={{ boxShadow: "0px 3px 30px #5C86CB2E" }}>
                {loading ? (
                  <Box p={2}>
                    <LoderSimmer count={6} />
                  </Box>
                ) : (
                  <>
                    {inspection_state?.list?.length > 0 ? (
                      <InfiniteScroll
                        dataLength={inspection_state?.list?.length}
                        next={fetchMoreData}
                        hasMore={true}
                        height={size?.height - 130}
                      >
                        <Box>
                          {inspection_state?.list?.map((val) => {
                            return (
                              <Box>
                                <InspectionCard value={val} onClick={onClick} t={t} />
                              </Box>
                            );
                          })}
                        </Box>
                      </InfiniteScroll>
                    ) : (
                      <Box mt={2} className={classes.noDataFound}>
                        <NoDataFound
                          height={size?.height - 160}
                          content={t("No Data Found!")}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
              <Box height={"16px"} />
            </div>
          </Container>
        </Box>
      </Box>
      <DialogDrawer
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onOpen={() => setOpenFilter(true)}
        isnotTitle={true}
        padding={0}
        height={size?.width <= 600 ? size?.height : "auto"}
        component={
          <Filter
            filterData={myFilterData}
            selectedList={myFilterData[0]}
            filters={{
              type: selectedFilter.type,
            }}
            onApply={applyFilter}
            onGoBack={() => setOpenFilter(false)}
            onClose={() => setOpenFilter(false)}
          />
        }
      />
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}


export default withBottombar(withNamespaces("inspection")(InspectionOrders), props);
