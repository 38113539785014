import React from "react";

function Info() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g data-name="Group 114373" transform="translate(2388 17504)">
        <path
          fill="#5ac782"
          d="M12 4a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 1.2A6.8 6.8 0 115.2 12 6.791 6.791 0 0112 5.2zM12 8a.8.8 0 10.8.8.8.8 0 00-.8-.8zm-.009 2.791a.6.6 0 00-.591.609v4.4a.6.6 0 101.2 0v-4.4a.6.6 0 00-.609-.609z"
          data-name="icons8-info (7)"
          transform="translate(-2390 -17506)"
        ></path>
      </g>
    </svg>
  );
}

export default Info;