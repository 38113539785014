import React from "react";
import { Box, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { BottomNavbar, DialogDrawer, TitleBar, TopNavBars } from "../components";
import { config } from "../config";
import { AlertContext } from "../contexts";
import { NetworkCall } from "../networkcall";
import { Routes } from "../router/routes";
import CommunityMemberModel from "../screens/service/components/CommunityMemberModel";
import { AlertProps, NetWorkCallMethods } from "../utils";
import useWindowDimensions from "../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
  },

  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    backgroundColor: "#F2F5FA",
  },
  screen: {
    position: "relative",
  },
  bottomNavbar: {
    position: "fixed",
    bottom: "0px",
    left: "0px",
    right: "0px",
    zIndex: 1,
  },
  topBar: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 999
  }
}));

const withBottombar = (Component, params) => (props) => {
  const alert = React.useContext(AlertContext);
  const [toggleDrawerBottom, setToggleDrawerBottom] = React.useState(false);
  const [topBarState, setTopBarState] = React.useState({
    text: "",
    note: false,
    i18n: {},
    t: {},
    new: "",
    data: "",
    entries: false,
    handleClick: () => false
  });
  const classes = useStyles({ props });
  const history = useHistory();
  const size = useWindowDimensions();

  const handleTopBarState = (value) => {
    setTopBarState(value);
  }

  const onTab1Clicked = () => {
    history.push(Routes.dashboard);
  };
  const onTab2Clicked = () => {
    history.push(Routes.agreement);
  };
  const onTab3Clicked = () => {
    getagreementUnitid();
  };
  const onTab4Clicked = () => {
    history.push(Routes.requestScreen);
  };
  const onTab5Clicked = () => {
    history.push(Routes.profileView);
  };
  const getagreementUnitid = () => {
    NetworkCall(
      `${config?.api_url}/community/community_units_filter`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.agreement_units?.length > 0) {
          history.push(Routes.service);
        } else {
          setToggleDrawerBottom(true);
        }
      })
      .catch((error) => {
        if (error?.code === 503) {
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
          });
        }
      });
  };

  const TopBarSwitch = (type) => {
    switch (type) {
      case "title_bar":
        return (
          <TitleBar
            data={topBarState?.data}
            entries={topBarState?.entries}
            handleClick={topBarState?.handleClick}
            text={topBarState?.text}
            component={topBarState?.component}
            isUtilities={topBarState?.isUtilities}
            goBack={topBarState?.customBackpath ? () => history.push(topBarState?.customBackpath) : topBarState?.customBackHandle?topBarState?.customBackHandle:() => history.goBack()} 
            onClear={topBarState?.onClear}/>
        )

      case "top_nav_bar":
        return (
          <TopNavBars
            t={topBarState?.t}
            new={topBarState?.new}
            document={() => history.push(Routes?.fileManager)}
            note={topBarState?.note}
            handleClicknote={() => history.push(Routes.notification)}
            i18n={topBarState?.i18n} />
        )

      default:
        return <></>
    }
  }

  const BottomBarSwitch = (type) => {
    switch (type) {
      case "bottom_nav_bar":
        return (
          <Box className={classes.bottomNavbar}>
            <BottomNavbar
              onTab1Clicked={onTab1Clicked}
              onTab2Clicked={onTab2Clicked}
              onTab3Clicked={onTab3Clicked}
              onTab4Clicked={onTab4Clicked}
              onTab5Clicked={onTab5Clicked}
            />
          </Box>
        )
      default:
        return <></>
    }
  }

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <Container maxWidth="sm" style={{ padding: "0px" }}>
        <Box className={classes.screen}>
          {params?.top_bar && <Box className={classes.topBar}>
            {TopBarSwitch(params?.top_bar)}
          </Box>}
          <Box className={classes.screenPadding}
            style={{
              height: size?.height - (params?.top_bar === "title_bar" ? 55 : params?.top_bar === "top_nav_bar" ? 126 : 62),
              overflow: "auto",
              margin: (params?.top_bar === "title_bar" ? "55px auto auto auto" : params?.top_bar === "top_nav_bar" ? "71px auto auto auto" : "auto")
            }}>
            <Component {...props} handleTopBarState={handleTopBarState}>{props.children}</Component>
          </Box>
          {params?.bottom_bar && BottomBarSwitch(params?.bottom_bar)}
        </Box>
        <DialogDrawer
          open={toggleDrawerBottom}
          onClose={() => setToggleDrawerBottom(false)}
          onOpen={() => setToggleDrawerBottom(true)}
          isnotTitle={true}
          component={
            <CommunityMemberModel
              onClose={() => setToggleDrawerBottom(false)}
            />
          }
        />
      </Container>
    </div>
  );
};

export default withBottombar;
