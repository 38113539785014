import React from "react"

export const InvoiceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <g id="Group_117534" data-name="Group 117534" transform="translate(-76 -121.5)">
                <rect id="Rectangle_58388" data-name="Rectangle 58388" width="40" height="40" rx="6" transform="translate(76 121.5)" fill="#e6efff" />
                <g id="icons8-bill_12_" data-name="icons8-bill (12)" transform="translate(76 122.5)">
                    <path id="Path_101868" data-name="Path 101868" d="M30.581,5h-.744a.744.744,0,0,1-1.488,0H26.86a.744.744,0,0,1-1.488,0H23.884A.744.744,0,0,1,22.4,5H20.907a.744.744,0,0,1-1.488,0H17.93a.744.744,0,1,1-1.488,0H14.953a.744.744,0,0,1-1.488,0H11.977a.744.744,0,1,1-1.488,0H9.744A.744.744,0,0,0,9,5.744V32.535a.744.744,0,0,0,.744.744h.744a.744.744,0,1,1,1.488,0h1.488a.744.744,0,0,1,1.488,0h1.488a.744.744,0,1,1,1.488,0h1.488a.744.744,0,1,1,1.488,0H22.4a.744.744,0,1,1,1.488,0h1.488a.744.744,0,0,1,1.488,0h1.488a.744.744,0,1,1,1.488,0h.744a.744.744,0,0,0,.744-.744V5.744A.744.744,0,0,0,30.581,5Z" transform="translate(0 0)" fill="#fff" />
                    <path id="Path_101869" data-name="Path 101869" d="M29,14.233H13.372A.372.372,0,0,1,13,13.86V12.372A.372.372,0,0,1,13.372,12H29a.372.372,0,0,1,.372.372V13.86A.372.372,0,0,1,29,14.233Z" transform="translate(-1.023 -1.791)" fill="#78a2e9" />
                    <path id="Path_101870" data-name="Path 101870" d="M33.86,21.488H32.372A.372.372,0,0,1,32,21.116v-.744A.372.372,0,0,1,32.372,20H33.86a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,33.86,21.488Z" transform="translate(-5.884 -3.837)" fill="#78a2e9" />
                    <path id="Path_101871" data-name="Path 101871" d="M23.047,21.488H13.372A.372.372,0,0,1,13,21.116v-.744A.372.372,0,0,1,13.372,20h9.674a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,23.047,21.488Z" transform="translate(-1.023 -3.837)" fill="#78a2e9" />
                    <path id="Path_101872" data-name="Path 101872" d="M33.86,25.488H32.372A.372.372,0,0,1,32,25.116v-.744A.372.372,0,0,1,32.372,24H33.86a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,33.86,25.488Z" transform="translate(-5.884 -4.86)" fill="#78a2e9" />
                    <path id="Path_101873" data-name="Path 101873" d="M20.814,25.488H13.372A.372.372,0,0,1,13,25.116v-.744A.372.372,0,0,1,13.372,24h7.442a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,20.814,25.488Z" transform="translate(-1.023 -4.86)" fill="#78a2e9" />
                    <path id="Path_101874" data-name="Path 101874" d="M33.86,29.488H32.372A.372.372,0,0,1,32,29.116v-.744A.372.372,0,0,1,32.372,28H33.86a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,33.86,29.488Z" transform="translate(-5.884 -5.884)" fill="#78a2e9" />
                    <path id="Path_101875" data-name="Path 101875" d="M23.047,29.488H13.372A.372.372,0,0,1,13,29.116v-.744A.372.372,0,0,1,13.372,28h9.674a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,23.047,29.488Z" transform="translate(-1.023 -5.884)" fill="#78a2e9" />
                    <path id="Path_101876" data-name="Path 101876" d="M33.349,35.488H30.372A.372.372,0,0,1,30,35.116v-.744A.372.372,0,0,1,30.372,34h2.977a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,33.349,35.488Z" transform="translate(-5.372 -7.419)" fill="#78a2e9" />
                    <path id="Path_101877" data-name="Path 101877" d="M24.07,35.488h-6.7A.372.372,0,0,1,17,35.116v-.744A.372.372,0,0,1,17.372,34h6.7a.372.372,0,0,1,.372.372v.744A.372.372,0,0,1,24.07,35.488Z" transform="translate(-2.047 -7.419)" fill="#78a2e9" />
                </g>
            </g>
        </svg>

    )
}