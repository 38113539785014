import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, remCalculate } from "../../utils";

export const ServiceGotoStyle = makeStyles((theme) => ({
    topBox: {
        padding: "24px 16px",
        background: "#fff",
        marginTop: 4
    },
    backgroundImage: {
        width: 500,
        [theme.breakpoints.only("xs")]: {
            width: "calc(100vw - 40px)"
        }
    },
    serviceBox: {
        background: theme.palette.background.secondary,
        borderRadius: 8
    },
    serviceTitle: {
        fontSize: remCalculate(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    header: {
        height: 46,
        boxShadow: "0px 2px 10px #43547224",
        background: "#fff",
        padding: "8px 16px"
    },
    headerAvatar: {
        height: 30,
        width: 30
    },
    body: {
        height: "calc(100vh - 106px)",
        overflow: "auto",
        marginRight: "-5px !important"
    },
    activeText: {
        fontSize: remCalculate(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.palette.primary.main
    },
    inactiveText: {
        fontSize: remCalculate(12),
        fontFamily: FontFamilySwitch().semiBold,
        color: theme.typography.color.primary
    },
    footer:{
        background: '#fff',
        borderRadius: "8px 8px 0px 0px",
        padding: 8,
        boxShadow: "0px -8px 15px #43547226"
    },
    offerCard:{
        borderRadius: 8,
        boxShadow: "0px 4px 10px #43547224"
    },
    title:{
        fontSize: remCalculate(16),
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary
    }
}))