import * as React from "react"

export const DeleteIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={62} height={62} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        y1={250.588}
        x2={0.509}
        y2={250.127}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#4997e8" />
      </linearGradient>
    </defs>
    <g data-name="Group 105877" transform="translate(-157 -624)">
      <circle
        data-name="Ellipse 129511"
        cx={31}
        cy={31}
        r={31}
        transform="translate(157 624)"
        fill="#edfbfe"
      />
      <path
        data-name="Path 97716"
        d="M200.558 648.263v21.053a1.661 1.661 0 0 1-1.637 1.684h-21.284a1.661 1.661 0 0 1-1.637-1.684v-28.632a1.661 1.661 0 0 1 1.637-1.684h13.916l2.456 6.737Z"
        fill="#acf3ff"
      />
      <path
        data-name="Path 97717"
        d="M28 5v7.579a1.661 1.661 0 0 0 1.638 1.684h7.373Z"
        transform="translate(163.547 634)"
        fill="url(#a)"
      />
      <path
        data-name="Path 97718"
        d="M195.233 655h-13.909a.415.415 0 0 1-.409-.421v-.842a.415.415 0 0 1 .409-.421h13.909a.415.415 0 0 1 .409.421v.842a.415.415 0 0 1-.409.421Z"
        fill="#1a9cc6"
      />
      <path
        data-name="Path 97719"
        d="M193.607 658.368h-12.29a.416.416 0 0 1-.41-.421v-.842a.416.416 0 0 1 .41-.421h12.29a.416.416 0 0 1 .41.421v.842a.416.416 0 0 1-.41.421Z"
        fill="#1a9cc6"
      />
      <path
        data-name="Path 97720"
        d="M195.233 661.737h-13.909a.415.415 0 0 1-.409-.421v-.842a.415.415 0 0 1 .409-.421h13.909a.415.415 0 0 1 .409.421v.842a.415.415 0 0 1-.409.421Z"
        fill="#1a9cc6"
      />
      <path
        data-name="Path 97721"
        d="M193.607 665.105h-12.29a.416.416 0 0 1-.41-.421v-.842a.416.416 0 0 1 .41-.421h12.29a.416.416 0 0 1 .41.421v.842a.416.416 0 0 1-.41.421Z"
        fill="#1a9cc6"
      />
    </g>
  </svg>
)
