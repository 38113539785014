import React from "react";
import { DetailCardStyle } from "./styles";
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import AmenityViewBooking from "../../../assets/amenityViewBooking";
import { generateImageUrl, img_size } from "../../../utils";
import Slider from "react-slick";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ShowMoreText from "react-show-more-text";
import StandardRateIcon from "../utils/standardRate";
import SlotDuration from "../utils/slotDuration";
import CapacityIcon from "../utils/capacity";
import PeakHourIcon from "../utils/peakHour";
import NoImageAmenityIcon from "../../../assets/noImageAmenityIcon";
import AmenitiesReplace from "../../../assets/amenitiesReplace";

export const AmenityDetailCard = ({
    mybookingNavigate = () => false,
    data = {},
    isPopup = false,
    state = {},
    onCloseDetailOpen = () => false,
    t = () => false
}) => {
    const classes = DetailCardStyle()

    const settings = {
        dots: true,
        infinite: true,
        slidesToScroll: 1,
        nextArrow: <ArrowCircleRightIcon />,
        prevArrow: <ArrowCircleLeftIcon />,
        arrows: true,
        autoplay: true,
        speed: 2000
    };

    return (
        <>
            <Box className={classes.root} p={isPopup ? 0 : 2}>
                {!isPopup &&
                    <Stack direction={"row"} pb={2}>
                        {state?.data?.facility_assets?.[0]?.url ? <Avatar className={classes.avatar} src={generateImageUrl(state?.data?.facility_assets?.[0]?.url, img_size.small_square)}></Avatar>
                            :
                            <Box sx={{ marginInlineEnd: "16px"}}>
                                <AmenitiesReplace />
                            </Box>}
                        <Stack spacing={1}>
                            <Stack>
                                <Typography className={classes.amenityText} noWrap>{state?.data?.description}</Typography>
                                <Typography className={classes.amenityCategory}>{`${state?.data?.amenities_name??""} (${state?.data?.amenity_category ?? state?.data?.amenities_category?.name})`}</Typography>
                            </Stack>
                            <Typography className={classes.viewMoreText} onClick={() => onCloseDetailOpen()}>{t("View More")}</Typography>
                        </Stack>
                    </Stack>
                }
                {!isPopup && <Divider></Divider>}
                {
                    isPopup &&
                    <Box p={2}>
                        <Slider {...settings} arrows>
                            {state?.data?.facility_assets ?
                                state?.data?.facility_assets?.length === 0 ?
                                    <Box className={classes.noImageBox}>
                                        <Box className={classes.noImageIcon}>
                                            <NoImageAmenityIcon />
                                        </Box>
                                    </Box> :
                                    state?.data?.facility_assets?.map((link) => {
                                        return (
                                            <Avatar className={classes.cardImage} src={state?.from === "amenities" ? link?.url : link?.url}></Avatar>
                                        )
                                    }) :
                                state?.data?.facility_type?.assets?.length === 0 ?
                                    <Box className={classes.noImageBox}>
                                        <Box className={classes.noImageIcon}>
                                            <NoImageAmenityIcon />
                                        </Box>
                                    </Box> :
                                    state?.data?.facility_type?.assets?.map((link) => {
                                        return (
                                            <Avatar className={classes.cardImage} src={state?.from === "amenities" ? link?.url : link?.url}></Avatar>
                                        )
                                    })
                            }
                        </Slider>

                        <Stack spacing={1} pt={2}>
                            <Stack spacing={0.5}>
                                <Typography className={classes.amount}>{state?.data?.description}</Typography>
                                <Typography className={classes.amenityCategory}>{state?.data?.amenity_category}</Typography>
                            </Stack>
                            <ShowMoreText
                                lines={2}
                                more="Show More"
                                less="Show Less"
                                className={classes.desc}
                                anchorClass={classes.seeMoreLessTextStyle}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                            >
                                <Typography className={classes.seeMoreLessTextStyle}>
                                    {state?.data?.amenity_description}
                                </Typography>
                            </ShowMoreText>
                        </Stack>
                    </Box>
                }
                {isPopup && <Divider sx={{ borderBottom: "4px solid #F2F4F7" }}></Divider>}
                <Stack spacing={1.5} p={isPopup ? "16px 16px" : "16px 0px"}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <StandardRateIcon />
                            <Typography sx={{ marginInlineStart: "8px" }} className={classes.label}>{t("Standard Rate")}</Typography>
                        </Stack>
                        <Typography className={classes.amount}>{state?.data?.amount ?? state?.data?.rate} {state?.data?.symbol ?? state?.data?.currency_symbol}<span className={classes.slotText}> / {state?.data?.period === "Daily" ? t("Day") : t("Slot")}</span></Typography>
                    </Stack>
                    {state?.data?.peak_rate?.length > 0 &&
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <PeakHourIcon />
                                <Typography sx={{ marginInlineStart: "8px" }} className={classes.label}>{t("Peak Rate")}</Typography>
                            </Stack>
                            <Typography className={classes.amount}>{state?.data?.peak_rate} {state?.data?.symbol ?? state?.data?.currency_symbol}<span className={classes.slotText}> / {state?.data?.period === "Daily" ? t("Day") : t("Slot")}</span></Typography>
                        </Stack>
                    }
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <CapacityIcon />
                            <Typography sx={{ marginInlineStart: "8px" }} className={classes.label}>{t("Capacity")}</Typography>
                        </Stack>
                        <Typography className={classes.amount}>{t("Upto")} {state?.data?.participants_count} {t("Members")}<span className={classes.slotText}> / {state?.data?.period === "Daily" ? t("Day") : t("Slot")}</span></Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <SlotDuration />
                            <Typography sx={{ marginInlineStart: "8px" }} className={classes.label}>{t("Slot Duration")}</Typography>
                        </Stack>
                        <Typography className={classes.amount}>{state?.data?.slot_partition} Hr<span className={classes.slotText}> / {state?.data?.period === "Daily" ? t("Day") : t("Slot")}</span></Typography>
                    </Stack>
                </Stack>
                {
                    !isPopup &&
                    <Stack className={classes.viewBookingBox} alignItems={"center"} mt={2} onClick={() => mybookingNavigate()}>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <AmenityViewBooking />
                            <Typography className={classes.viewBookingLabel}>{t("View Booking History")}</Typography>
                        </Stack>
                    </Stack>
                }
            </Box >
        </>
    )
}