import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const viewAmenitiesStyle = makeStyles((theme) => ({
    tabroot: {
        display: "flex",
        alignItems: "center",
        "& .MuiTabs-indicator": {
            display: "none"
        }
    },
    tabItemroot: {

        "& .MuiButtonBase-root-MuiTab-root": {
        },

    },
    tabItem: {
        borderRadius: "4px",
        padding: "8px",
        minHeight: "auto",
        minWidth: "auto",
        maxHeight: "68px",
        margin: "0px 14px",
        "& .MuiButtonBase-root-MuiTab-root": {
            borderRadius: "4px",
        },

    },
    tabItemSelect: {

        backgroundColor: "#263B85",
        borderRadius: "4px",
        padding: "8px",
        minHeight: "auto",
        minWidth: "auto",
        maxHeight: "68px",
        margin: "0px 14px",
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: 0,
            borderRadius: "4px",

        },
    },
    textSelect1: {
        color: "#fff",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem"
    },
    textSelect2: {
        color: "#fff",
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().regular,
    },
    textNotSelect1: {
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().bold,
        fontSize: "0.75rem"
    },
    textNotSelect2: {
        color: theme.typography.color.secondary,
        fontSize: "1.125rem",
        fontFamily: FontFamilySwitch().regular,
    },
    panel: {
        padding: "12px !important"
    },
    box: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        padding: "8px",
        borderRadius: "4px",
        marginBottom: "11px",
        cursor: "pointer"
    },
    card: {
        backgroundColor: "white",
        borderRadius: "4px",
    },
    body: {
        height: `calc(100vh - 140px)`,
        overflow: "auto"
    },
    noDataFound: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        color: theme.typography.color.Tertiary
    }
}))