export const AgreementSelected = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 94883">
            <g data-name="Group 7567">
                <path data-name="Rectangle 49471" fill="none" d="M0 0h24v24H0z" />
            </g>
            <path
                data-name="icons8-rental-house-contract (1)"
                d="M6.25 2A2.253 2.253 0 0 0 4 4.25v15.5A2.253 2.253 0 0 0 6.25 22h11.5A2.253 2.253 0 0 0 20 19.75V10h-5.75A2.253 2.253 0 0 1 12 7.75V2Zm7.25.439V7.75a.751.751 0 0 0 .75.75h5.311ZM12 11.515a2.159 2.159 0 0 1 1.334.448l2.344 1.846A2.147 2.147 0 0 1 16.5 15.5v2.8a1.7 1.7 0 0 1-1.687 1.7H9.188A1.7 1.7 0 0 1 7.5 18.3v-2.8a2.145 2.145 0 0 1 .822-1.694l2.344-1.846A2.162 2.162 0 0 1 12 11.515ZM11.25 15a.75.75 0 0 0-.75.75v1.5a.75.75 0 0 0 .75.75h1.5a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75Z"
                fill="#5078e1"
            />
        </g>
    </svg>
)
