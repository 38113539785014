import { Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";
import FileViewer from "react-file-viewer";

export const AssetCard = ({
    x = {},
    onClick=()=>false
}) => {
    const classes = useStyles();
    const [showText, setShowtext] = React.useState(false);
   
    return (
        <>
        <Box sx={{ cursor: "pointer" }} onClick={onClick}>

            <Box onMouseOver={() => setShowtext(true)}
                width={"100%"}
                onMouseLeave={() => setShowtext(false)} position={"relative"} height={'148px'}>

                {x?.file_meta?.type === "application/pdf" ?
                    <FileViewer  fileType="pdf" filePath={x?.url} />
                    :
                    <img src={x?.url} className={`${classes.selectedImg}`} alt="" />

                }
                {showText === true && (
                    <Box className={classes.hoverCard}>
                   

                        <Box className={`${classes.imgtext}`}>
                            <Typography className={`${classes.imgName}`}>
                                {x?.file_meta?.name}
                            </Typography>
                            <Box display={"flex"}>
                                <Typography className={`${classes.textName}`}>
                                    {x?.file_meta?.size}
                                </Typography>
                                <div className={`${classes.dot}`}></div>
                                <Typography className={`${classes.textName}`}>{x?.file_meta?.type}</Typography>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
        </>
    )
}