import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { RadioButton, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { withBottombar } from "../../HOCs";
import { NetworkCall } from "../../networkcall";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { componentsUseStyles } from "./components/styles";
import { useStyles } from "./styles";
import WhiteFeedback from "../../assets/whiteFeedback";

const FeedbackForm = (props) => {
    const { t = () => false, handleTopBarState } = props;
    const classes = useStyles(props);
    const componentClasses = componentsUseStyles(props);
    const { state } = useLocation();
    const alert = useContext(AlertContext);
    const [feedbackFormList, setFeedbackFormList] = useState([]);
    const [open, setOpen] = useState("");
    const size = useWindowDimensions();
    const history = useHistory();

    const [details, setDetails] = React.useState({})

    // EDIT API
    // const UpdateFeedbackForm = () => {
    //     const payload = {
    //         replay_id: state?.data?.id,
    //         upsert_fields: feedbackFormList?.map((item) => (
    //             {
    //                 id: item?.id,
    //                 value: item?.value
    //             }
    //         ))
    //     };
    //     NetworkCall(
    //         `${config?.api_url}/customer_feedback_values/upsert`,
    //         NetWorkCallMethods.post,
    //         payload,
    //         null,
    //         true,
    //         false
    //     )
    //         .then((response) => {
    //             history.goBack(-1);
    //             alert.setSnack({
    //                 ...alert,
    //                 open: true,
    //                 msg: t("Feedback Form Updated")
    //             })
    //         })
    //         .catch((error) => {
    //             alert.setSnack({
    //                 ...alert,
    //                 open: true,
    //                 severity: AlertProps.severity.error,
    //                 msg: t("SomethingWentwrong"),
    //             });
    //         });
    // };

    // CREATE API

    const CreateFeedbackForm = () => {
        const result = feedbackFormList?.filter((val) => { return val?.is_mandatory })
        const isValidate = result?.length>0?result.some((arr) => { return arr.value !== null }):true
        if (isValidate) {
            const payload = {
                replay_id: state?.data?.id,
                upsert_fields: feedbackFormList?.map((item) => (
                    {
                        id: item?.id,
                        value: item?.type === "check_box" ? JSON.stringify(item?.value) : item?.value
                    }
                ))
            };
            NetworkCall(
                `${config?.api_url}/customer_feedback_values/upsert`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    history.goBack(-1);
                    alert.setSnack({
                        ...alert,
                        open: true,
                        msg: t("Feedback Form Created")
                    })
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                    });
                });
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Please fill all mandatory fields"),
            });
        }
    };

    // GET API
    const GetFeedbackForm = (id) => {
        const payload = {
            replay_id: id
        }
        NetworkCall(
            `${config?.api_url}/customer_feedback_values/get_values`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let temp_response = (response?.data?.data)
                temp_response.sort((a, b) => {
                    return a?.order - b?.order
                })
                setFeedbackFormList(temp_response)
                setDetails(response?.data?.replies)

            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    // DELETE API

    // const DeleteFeedbackForm = () => {
    //     const payload = {
    //         insert_fields: {
    //             replay_id: state?.data?.id,
    //             is_delete: true
    //         }
    //     }
    //     NetworkCall(
    //         `${config?.api_url}/customer_feedback_replies/upsert`,
    //         NetWorkCallMethods.post,
    //         payload,
    //         null,
    //         true,
    //         false
    //     )
    //         .then((response) => {
    //             history.goBack(-1);
    //             alert.setSnack({
    //                 ...alert,
    //                 open: true,
    //                 severity: AlertProps.severity.error,
    //                 msg: t("Feedback Form Deleted")
    //             })
    //         })
    //         .catch((error) => {
    //             alert.setSnack({
    //                 ...alert,
    //                 open: true,
    //                 severity: AlertProps.severity.error,
    //                 msg: t("SomethingWentwrong"),
    //             });
    //         });
    // };


    useEffect(() => {
        handleTopBarState({
            text: t("Feedback")
        })
        GetFeedbackForm(state?.data?.id)
        setOpen(state?.data?.status)
        // eslint-disable-next-line 
    }, []);


    const marginTypes = ["boolean", "check_box"]



    const UpdateAnswer = (feedbackId, value) => {
        const UpdateFeedback = feedbackFormList.map((item) => {
            if (feedbackId === item.id) {
                item.value = item?.type === "check_box" ? (item.value !== null ? [...item?.value, value] : [value]) : value;
            }
            return item;
        });
        setFeedbackFormList(UpdateFeedback);
    }

    return (
        <Container className={classes.root} maxWidth="sm">
            {/* <TitleBar text={t("Feedback")} goBack={goBack} /> */}
            <div
                className={classes.mainPadding}
                style={{
                    height: open === "Open" ? size?.height - 140 : size?.height - 55,
                    overflow: "overlay",
                    backgroundColor: "#F8F8F8",
                }}
            >
                <Box className={classes.listRootForm}>
                    <Box className={classes.card}>
                        <Stack direction={"row"} alignItems="center">
                            {/* <img
                                className={componentClasses.feedbackImage}
                                src="/images/feedbackIcon.svg"
                                alt=""
                            /> */}
                            <WhiteFeedback />
                            <Box sx={{ marginInlineStart: "16px" }} flexGrow={1}>
                                <Typography
                                    className={componentClasses.title}
                                    sx={{ maxWidth: size?.width - 150 }}
                                    noWrap
                                >
                                    {details?.title}
                                </Typography>
                                <Box height={"4px"} />
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    divider={<Box className={componentClasses.dot} />}
                                >
                                    {details?.req_no && 
                                    <Typography className={componentClasses.label}>{details?.req_no}</Typography>}
                                    {details?.type && 
                                    <Typography className={componentClasses.label}>{details?.type}</Typography>}

                                </Stack>
                                <Box height={"4px"} />
                            </Box>
                        </Stack>
                    </Box>
                    <Box height={"16px"} />
                    {feedbackFormList.map((val, index) => (
                        <>
                            <Stack direction="row" spacing={0}>
                                <Typography className={classes.questions}>{val?.order}.{" "}{val?.title}</Typography>
                                <Typography color="red">{val?.is_mandatory && "*"}</Typography>
                            </Stack>
                            {
                                !marginTypes?.includes(val?.type) &&
                                <Box height="16px" />

                            }
                            <Box className={classes.response}>
                                {val?.type === "rating" && <>
                                    {open === "Open" ? (<>
                                        <Stack direction={"row"} spacing={1}>
                                            {val?.data_type_options?.map((number) => (
                                                <Box
                                                    contained
                                                    onClick={() => UpdateAnswer(val?.id, number?.value)}
                                                    className={number?.value === val?.value ? classes.selectedRating : number?.value < val?.value ? classes.rating : classes.bigRating}>
                                                    {number?.value}
                                                </Box>
                                            ))}
                                        </Stack></>) : (<>
                                            <Stack direction={"row"} spacing={1}>
                                                {val?.data_type_options?.map((number) => (
                                                    <>
                                                    <Button
                                                        disabled
                                                        contained
                                                        onClick={() => UpdateAnswer(val?.id, number?.value)}
                                                        // className={number?.value === val?.value ? classes.selectedRating : number?.value <= val?.value ? classes.rating : classes.bigRating}>
                                                         // eslint-disable-next-line
                                                        className={number?.value == val?.value ? classes.rating : classes.bigRating}
                                                        >
                                                        {number?.value}
                                                    </Button>
                                                    </>
                                                ))}
                                            </Stack></>)}
                                    {/* <Stack direction={"row"} justifyContent="space-between">
                                        <Typography className={classes.level}>Not Satisfied</Typography>
                                        <Typography className={classes.level}>Very Satisfied</Typography>
                                    </Stack> */}
                                </>
                                }
                                {val?.type === "boolean" &&
                                    <Box className={classes.response}>
                                        {open === "Open" ? (<>
                                            <Stack direction={"row"} >

                                                <RadioButton
                                                    option={val?.data_type_options}
                                                    handleChange={(e) => UpdateAnswer(val?.id, e?.value)}
                                                    value={{ value: val?.value, label: val?.value }}

                                                />

                                            </Stack></>) : (<>
                                                <Stack direction={"row"} >
                                                    <RadioButton
                                                        option={val?.data_type_options}
                                                        handleChange={(e) => UpdateAnswer(val?.id, e?.value)}
                                                        value={{ value: val?.value, label: val?.value }}
                                                        isReadonly
                                                    />


                                                </Stack></>)}
                                    </Box>}
                                {val?.type === "text" &&
                                    <>
                                        <TextBox
                                            onChange={(value) =>
                                                UpdateAnswer(val?.id, value.target.value)
                                            }
                                            value={val?.value}
                                            disabled={state?.data?.status !== "Submitted" ? false : true}
                                            multiline />
                                    </>
                                }
                                {val?.type === "check_box" &&
                                    val?.data_type_options?.map((option) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked={state?.data?.status === "Submitted" && Array?.isArray(JSON.parse(val.value)) && JSON.parse(val.value).includes(option?.value)}
                                                        onChange={(value) =>
                                                            UpdateAnswer(val?.id, value.target.name)
                                                        }
                                                        name={option?.value}
                                                        disabled={state?.data?.status !== "Submitted" ? false : true}
                                                    />
                                                }
                                                label={<Typography className={classes.checklabel}>{option.label}</Typography>}
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    ))
                                }
                            </Box>
                        </>
                    ))}
                </Box>
            </div>
            {open === "Open" && (<>
                <Grid
                    container
                    className={classes.btnroot}
                    justifyContent="space-between"
                    alignItems={"center"}
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            className={classes.primary}
                            variant="contained"
                            onClick={CreateFeedbackForm}
                        >
                            {t("Submit")}
                        </Button>
                    </Grid>
                </Grid>
            </>)}
        </Container>
    );
};

const props = {
    top_bar: "title_bar",
}

export default withBottombar(withNamespaces("feedback")(FeedbackForm), props);
