import * as React from "react"

const AmenityPass = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={161} height={72} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 55140"
          transform="translate(-13736 -4253)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h161v72H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path data-name="Rectangle 55096" fill="none" d="M0 0h3.559v6.087H0z" />
      </clipPath>
      <clipPath id="c">
        <path
          data-name="Rectangle 55097"
          fill="none"
          d="M0 0h15.157v18.613H0z"
        />
      </clipPath>
      <clipPath id="d">
        <path
          data-name="Rectangle 55098"
          fill="none"
          d="M0 0h15.085v11.837H0z"
        />
      </clipPath>
      <clipPath id="e">
        <path data-name="Rectangle 55099" fill="none" d="M0 0h2.867v3.917H0z" />
      </clipPath>
      <clipPath id="f">
        <path data-name="Rectangle 55100" fill="none" d="M0 0h1.93v2.467H0z" />
      </clipPath>
      <clipPath id="g">
        <path
          data-name="Path 97316"
          d="M398.547 292.276c-2.144.8-3.053 3.694-2.027 6.46s3.606 4.371 5.75 3.575 3.054-3.694 2.027-6.461c-.848-2.287-2.757-3.779-4.6-3.779a3.278 3.278 0 0 0-1.145.2"
          transform="translate(-396.098 -292.072)"
          fill="none"
        />
      </clipPath>
      <clipPath id="h">
        <path
          data-name="Rectangle 55139"
          transform="translate(.17 -.475)"
          fill="none"
          d="M0 0h169v126H0z"
        />
      </clipPath>
      <clipPath id="i">
        <path
          data-name="Rectangle 55123"
          fill="none"
          d="M0 0h14.709v13.357H0z"
        />
      </clipPath>
      <clipPath id="j">
        <path data-name="Rectangle 55124" fill="none" d="M0 0h8.325v4.68H0z" />
      </clipPath>
      <clipPath id="k">
        <path data-name="Rectangle 55125" fill="none" d="M0 0h4.123v3.326H0z" />
      </clipPath>
      <clipPath id="l">
        <path data-name="Rectangle 55126" fill="none" d="M0 0h2.74v5.738H0z" />
      </clipPath>
      <clipPath id="m">
        <path data-name="Rectangle 55127" fill="none" d="M0 0h5.154v9.683H0z" />
      </clipPath>
      <clipPath id="n">
        <path
          data-name="Rectangle 55128"
          fill="none"
          d="M0 0h11.261v7.683H0z"
        />
      </clipPath>
      <clipPath id="o">
        <path data-name="Rectangle 55129" fill="none" d="M0 0h4.222v7.528H0z" />
      </clipPath>
      <clipPath id="p">
        <path data-name="Rectangle 55130" fill="none" d="M0 0h2.768v5.891H0z" />
      </clipPath>
      <clipPath id="q">
        <path data-name="Rectangle 55131" fill="none" d="M0 0h3.366v4.729H0z" />
      </clipPath>
      <clipPath id="r">
        <path data-name="Rectangle 55132" fill="none" d="M0 0h2.868v5.601H0z" />
      </clipPath>
      <clipPath id="s">
        <path
          data-name="Rectangle 55133"
          fill="none"
          d="M0 0h14.16v16.582H0z"
        />
      </clipPath>
      <clipPath id="t">
        <path data-name="Rectangle 55134" fill="none" d="M0 0h3.17v4.687H0z" />
      </clipPath>
      <clipPath id="u">
        <path data-name="Rectangle 55135" fill="none" d="M0 0h8.416v7.934H0z" />
      </clipPath>
      <clipPath id="v">
        <path data-name="Rectangle 55136" fill="none" d="M0 0h1.186v.859H0z" />
      </clipPath>
      <clipPath id="w">
        <path data-name="Rectangle 55137" fill="none" d="M0 0h3.476v1.243H0z" />
      </clipPath>
      <clipPath id="x">
        <path data-name="Rectangle 55138" fill="none" d="M0 0h7.339v8.084H0z" />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 6443"
      transform="translate(13736 4253)"
      clipPath="url(#a)"
    >
      <g data-name="Group 103425">
        <g data-name="Group 103424">
          <g data-name="Group 103371">
            <g data-name="Group 103370">
              <path
                data-name="Path 97277"
                d="M-13682.119-4191.154s-6.072-15.225-7.482-18.792c-1.756-4.444-5.386-10.123-3.137-11.4 2.539-1.446 3.405 4.977 5.145 11.265.725 2.62 6.313 18.388 6.357 18.52.11.328-.711.843-.882.412"
                fill="#f07d18"
              />
              <path
                data-name="Path 97278"
                d="M-13683.687-4193.462c-.031.066-3.365 2.113-4.062 1.973s.474-2.537.544-2.579 1.519-1.024 1.519-1.024 2.437.716 2 1.63"
                fill="#ffb9a0"
              />
              <path
                data-name="Path 97279"
                d="M-13684.749-4193.482a4.219 4.219 0 0 1-1.088-2.869 12.339 12.339 0 0 1 .83-2.813c.085-.294.606-.305.649-.041.031.2.041 1.22.041 1.22l1.283-.418.739 3.876s-1.645 1.77-2.453 1.045"
                fill="#ffe5cf"
              />
              <g data-name="Group 103357" opacity={0.5}>
                <g data-name="Group 103356">
                  <g
                    data-name="Group 103355"
                    clipPath="url(#b)"
                    transform="translate(-13685.855 -4199.395)"
                  >
                    <path
                      data-name="Path 97280"
                      d="M1.106 5.913A4.219 4.219 0 0 1 .018 3.044 12.339 12.339 0 0 1 .848.231c.085-.294.606-.305.649-.041.031.2.041 1.22.041 1.22L2.821.992l.739 3.876s-1.645 1.77-2.453 1.045"
                      fill="#ffb9a0"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97281"
                d="M-13683.347-4195.969s-.53.251-.632.483.13.465.465.437a9.456 9.456 0 0 0 1.506-.335c.325-.112.176-.734-.14-.762a5.022 5.022 0 0 0-1.2.177"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97282"
                d="M-13684.096-4196.368s-.5.318-.552.568.229.425.55.324a15.545 15.545 0 0 0 1.859-.672c.292-.18 0-.8-.314-.8a3.789 3.789 0 0 0-1.543.579"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97283"
                d="M-13684.263-4197.218s-.513.322-.563.574.233.428.559.326a15.942 15.942 0 0 0 1.893-.682c.3-.183.022-.85-.3-.851a4.006 4.006 0 0 0-1.587.633"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97284"
                d="M-13684.444-4198.024s-.4.3-.427.55.266.4.579.274a11.663 11.663 0 0 0 1.569-.693c.278-.205-.05-.84-.369-.814a3.171 3.171 0 0 0-1.351.683"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97285"
                d="M-13725.937-4147.702a7.474 7.474 0 0 0-1.185 1.32c-.449.686.029 2.212.435 3.032a6.939 6.939 0 0 0 3.411 2.429c.743 0 .455-2.415.681-3.324a14.7 14.7 0 0 1 .514-1.642 4.261 4.261 0 0 0-3.857-1.815"
                fill="#070540"
              />
              <path
                data-name="Path 97286"
                d="M-13723.276-4140.92a6.939 6.939 0 0 1-3.411-2.429 4.579 4.579 0 0 1-.538-2.808 4.576 4.576 0 0 0-.178.25c-.449.686.029 2.212.435 3.031a6.945 6.945 0 0 0 3.411 2.429c.215 0 .343-.2.423-.51a.294.294 0 0 1-.143.036"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97287"
                d="M-13691.18-4144.259a2.564 2.564 0 0 0 .169.781 15.335 15.335 0 0 0 3.932 1.063c1.32.128 3.31-.125 3.422-.441s-.33-1.228-.775-1.657a19.785 19.785 0 0 0-2.93-1.87c-.65-.318-3.741 1.139-3.819 2.124"
                fill="#070540"
              />
              <path
                data-name="Path 97288"
                d="M-13687.079-4142.414a15.335 15.335 0 0 1-3.932-1.063.518.518 0 0 0 .087.372 14.366 14.366 0 0 0 3.843 1.085c1.32.128 3.309-.125 3.422-.441a.672.672 0 0 0-.02-.365c-.222.3-2.123.535-3.4.411"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97289"
                d="M-13710.401-4182.79s-2.565 3.68-3.067 6.078-1.524 12.018-1.692 12.9c-.041.218-2.295 1.533-3.308 3.03a148.546 148.546 0 0 0-7.6 13.015c-.055.39 3.4 1.019 3.888 2.208.871-.72 10.628-13.957 11.782-15.256 1.233-1.388 5.576-7.672 5.576-7.672s8.554 8.341 8.978 8.955c.138.2.1 2.148.651 4.257a78.707 78.707 0 0 0 4.015 10.967s2.416-2.1 3.81-2.138c0 0-3.234-12.194-4.071-15.707s-7.528-15.622-8.253-16.538-10.011-4.039-10.707-4.095"
                fill="#6b78b7"
              />
              <g
                data-name="Group 103360"
                opacity={0.33}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103359">
                  <g
                    data-name="Group 103358"
                    clipPath="url(#c)"
                    fill="#6b78b7"
                    transform="translate(-13713.794 -4182.79)"
                  >
                    <path
                      data-name="Path 97290"
                      d="M14.102 4.095C13.377 3.179 4.091.056 3.395 0c0 0-2.565 3.68-3.067 6.078-.093.447-.2 1.144-.325 1.987 0 0 2.5-4.1 15.157-2.234-.528-.921-.913-1.554-1.058-1.737"
                    />
                    <path
                      data-name="Path 97291"
                      d="M11.757 10.381c-.892 1.125-2.788 3.918-2.788 3.918s2.183 2.128 4.4 4.314c-2.331-2.986-.717-9.356-1.609-8.232"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97292"
                d="M-13687.12-4188.645s.781.307 1.464-.85a15.093 15.093 0 0 0 1.157-3.611l-3.178.878s-.865 2.551.557 3.583"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97293"
                d="M-13699.289-4200.481c-1.479.015-5.265.988-6.558 2.876-1.9 2.77-1.18 7.287-2.547 9.35s-2.954 3.653-2.7 5.409 9.589 4.922 12.684 4.068c2.549-.7 4.962-9.3 5.968-11.708a24.988 24.988 0 0 0 5.323 1.84 4.486 4.486 0 0 1 .014-4c1.032-2.342 1.421-2.445 1.421-2.445s-1.3.062-4.2-1.806-5.543-3.623-9.409-3.583"
                fill="#e6e8f4"
              />
              <g
                data-name="Group 103363"
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103362">
                  <g
                    data-name="Group 103361"
                    clipPath="url(#d)"
                    transform="translate(-13700.77 -4200.482)"
                  >
                    <path
                      data-name="Path 97294"
                      d="M10.89 3.583C7.99 1.715 5.347-.041 1.481 0 .786.007-.919-.1.637 1.876c2.374 3.008 8.8 4.078 7.688 8.12a25 25 0 0 0 5.325 1.84 4.487 4.487 0 0 1 .014-4c1.032-2.342 1.421-2.445 1.421-2.445s-1.3.062-4.2-1.806"
                      fill="#e6e8f4"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97295"
                d="M-13700.613-4186.521s.444.33 3.665-2.821-1.645-2.579-2.9-1.59-2.368 3.1-.765 4.41"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97296"
                d="M-13695.272-4192.27s8.813-.26 9.417-.353 1.911-2.366 2.234-2.679 2.121-.063 2.344 0 .118.621.052.732c0 0 .506.186.613.4.079.154-.084.651-.084.651s.344.13.4.31a1.509 1.509 0 0 1-.093.452s.4.167.358.5c-.026.213-.549.775-.788.717a1.836 1.836 0 0 0-1.161.074.591.591 0 0 0-.286.64c.049.155-.623.874-1.459.846a6.292 6.292 0 0 0-3.806.46 26.421 26.421 0 0 1-7.631 1.59c-1.617.014-3.917-1.59-3.945-1.59s2.194-2.7 3.836-2.746"
                fill="#ffe5cf"
              />
              <g data-name="Group 103366" opacity={0.5}>
                <g data-name="Group 103365">
                  <g
                    data-name="Group 103364"
                    clipPath="url(#e)"
                    transform="translate(-13682.897 -4195.456)"
                  >
                    <path
                      data-name="Path 97297"
                      d="M2.507 2.694a1.5 1.5 0 0 0 .093-.452c-.055-.18-.4-.31-.4-.31s.162-.5.084-.651c-.107-.209-.613-.395-.613-.395.066-.112.171-.669-.052-.732A8.522 8.522 0 0 0 0 .001c.152.013.766.088.894.244s-.024.6.129.843a16.228 16.228 0 0 1 .861 1.575c.1.293.056 1.169-.383 1.191a2.609 2.609 0 0 1 .577.058c.239.057.761-.5.788-.717.042-.335-.358-.5-.358-.5"
                      fill="#ffb9a0"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97298"
                d="M-13695.272-4192.269a10.158 10.158 0 0 1-3.613-2.482c-2.035-2.008-4.963-2.888-6.3-1.974a3.719 3.719 0 0 0-.07 5.766c3.123 2.788 3.848 4.438 4.644 4.438 0 0-.713-.158 1.434-2.138s3.142-2.467 3.906-3.611"
                fill="#f3f4fa"
              />
              <path
                data-name="Path 97299"
                d="M-13698.162-4197.509s.586.273.865.117-.2-1.893-.657-2.225-.4 1.809-.208 2.108"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97300"
                d="M-13696.315-4201.814a1.579 1.579 0 0 1-1.3.916c-.946.065.039-2.466.039-2.466h1.48a11.576 11.576 0 0 1-.221 1.549"
                fill="#070540"
              />
              <g
                data-name="Group 103369"
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103368">
                  <g
                    data-name="Group 103367"
                    clipPath="url(#f)"
                    transform="translate(-13698.023 -4203.364)"
                  >
                    <path
                      data-name="Path 97301"
                      d="M1.708 1.55a1.579 1.579 0 0 1-1.3.916C-.538 2.531.447 0 .447 0h1.48a11.576 11.576 0 0 1-.221 1.549"
                      fill="#070540"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97302"
                d="M-13701.656-4203.419a4.772 4.772 0 0 1 .293-2.235c.4-.609 3.817-.882 4.418.124.8 1.337-.057 3.879-.057 3.879s-4.207 2.407-4.653-1.768"
                fill="#d14122"
              />
              <path
                data-name="Path 97303"
                d="M-13700.427-4199.525s-.053.568-.04.672a15.536 15.536 0 0 0 2.305 1.345s1.21-4.047 1.158-4.034-3.423 2.017-3.423 2.017"
                fill="#ffb9a0"
              />
              <path
                data-name="Path 97304"
                d="M-13701.656-4203.419c0 .057.187 3.721.236 4.131s1.15.553 1.658.328a8.64 8.64 0 0 0 1.853-1.288 2.775 2.775 0 0 0 .235-.742s-.02.27.371.189a1.207 1.207 0 0 0 .79-.92c.049-.895-.888-1.038-1.152.179-.026.122-.36.189-.351.095s.1-1.083.106-1.259-.37-.371-.38-.537a10.745 10.745 0 0 0 .205-1.493c-.078-.186-1.31.371-2.377.137s-1.222.215-1.194 1.181"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97305"
                d="M-13701.577-4201.878c.994-.251 1.97.948 3.585.154.029-.334.074-.859.082-.982.011-.176-.37-.371-.379-.537a10.755 10.755 0 0 0 .2-1.493c-.078-.185-1.31.371-2.377.136s-1.222.215-1.194 1.181c0 .025.036.722.079 1.54"
                fill="#ffb9a0"
              />
              <path
                data-name="Path 97306"
                d="M-13696.314-4201.814s.167-1.052-.526-.966c-1.546.191-3.742-1.205-5-.961a2.733 2.733 0 0 1 1.041-2.537c1.379-.846 4.135-.341 4.73 1.366a4.052 4.052 0 0 1-.241 3.1"
                fill="#070540"
              />
              <path
                data-name="Path 97307"
                d="M-13697.573-4202.511a3.167 3.167 0 0 1-1.641.294c-.86-.107-1.82-1-3.645-.937l-.075-.156s.879-.093 1.09-.151a3.514 3.514 0 0 1 1.77.1c.719.219 2.5.575 2.5.575Z"
                fill="#070540"
              />
              <path
                data-name="Path 97308"
                d="M-13697.573-4202.791a2.9 2.9 0 0 1-1.641.357c-.86-.107-1.895-.937-3.719-.878 0 0 .774-.342 1.09-.43a3.509 3.509 0 0 1 1.77.1c.8.244 2.095.769 2.5.853"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97309"
                d="M-13680.934-4191.539a.835.835 0 1 0-.487.9.736.736 0 0 0 .487-.9"
                fill="#ff946e"
              />
              <path
                data-name="Path 97310"
                d="m-13592.992-4184.292-1.062.394 1.932 5.207 1.062-.394 1.062-.394-1.932-5.207Z"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97311"
                d="m-13591.059-4179.086-1.547.574.359.968 1.547-.574 1.547-.574-.359-.968Z"
                fill="#070540"
              />
              <path
                data-name="Path 97312"
                d="m-13593.351-4185.261-1.309.486.359.968 1.309-.486 1.31-.486-.359-.968Z"
                fill="#070540"
              />
              <path
                data-name="Path 97313"
                d="m-13601.352-4193.249-.006-.006.033.082a6.477 6.477 0 0 0-.027-.076"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97314"
                d="M-13592.336-4196.101a7.01 7.01 0 0 0-.093-.266c-1.139-3.071-4.047-4.824-6.495-3.916s-3.508 4.134-2.368 7.2c.033.088.068.175.1.262 1.664 4.485 5.646 4.678 6.862 7.914l.552-.2-.232-.51a8.6 8.6 0 0 0-2.9-2.991 34.576 34.576 0 0 1-.843-.638 3.965 3.965 0 0 0 5.153-1.912c-.069.349-.146.692-.223 1.033a8.594 8.594 0 0 0-.251 4.161l.161.536.548-.2c-1.189-3.246 1.7-5.99.04-10.475Zm-2.662 6.4c-2.145.8-4.724-.808-5.75-3.575s-.117-5.665 2.027-6.461 4.724.808 5.75 3.575.118 5.665-2.027 6.46"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97315"
                d="M-13592.496-4196.535a.061.061 0 0 1 0-.008l.028.083-.029-.075"
                fill="#6b78b7"
              />
            </g>
          </g>
          <g data-name="Group 103373">
            <g
              data-name="Group 103372"
              clipPath="url(#g)"
              fill="#070540"
              transform="translate(-13601.171 -4199.94)"
            >
              <path
                data-name="Rectangle 55101"
                d="M-1.582 2.74 7.16-.503l.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55102"
                d="M-1.333 3.41 7.409.167l.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55103"
                d="M-1.085 4.081 7.657.838l.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55104"
                d="m-.836 4.751 8.742-3.243.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55105"
                d="m-.587 5.421 8.742-3.243.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55106"
                d="m-.339 6.091 8.742-3.243.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55107"
                d="m-.09 6.762 8.742-3.243.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55108"
                d="m.159 7.432 8.742-3.243.074.2L.233 7.633z"
              />
              <path
                data-name="Rectangle 55109"
                d="m.407 8.102 8.742-3.243.074.2L.481 8.303z"
              />
              <path
                data-name="Rectangle 55110"
                d="m.656 8.772 8.742-3.243.074.2L.73 8.974z"
              />
              <path
                data-name="Rectangle 55111"
                d="m.905 9.442 8.742-3.243.074.2L.98 9.643z"
              />
              <path
                data-name="Rectangle 55112"
                d="M1.153 10.113 9.895 6.87l.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55113"
                d="m1.402 10.783 8.742-3.243.074.2-8.742 3.244z"
              />
              <path
                data-name="Rectangle 55114"
                d="m-1.406-.858.2-.074 5.279 14.226-.2.075z"
              />
              <path
                data-name="Rectangle 55115"
                d="m-.677-1.128.2-.074 5.279 14.226-.2.075z"
              />
              <path
                data-name="Rectangle 55116"
                d="m.051-1.398.2-.074L5.53 12.754l-.2.075z"
              />
              <path
                data-name="Rectangle 55117"
                d="m.78-1.668.2-.074L6.26 12.484l-.2.075z"
              />
              <path
                data-name="Rectangle 55118"
                d="m1.508-1.939.2-.074 5.279 14.226-.2.075z"
              />
              <path
                data-name="Rectangle 55119"
                d="m2.237-2.209.2-.074 5.279 14.226-.2.075z"
              />
              <path
                data-name="Rectangle 55120"
                d="m2.965-2.479.2-.074 5.279 14.226-.2.075z"
              />
              <path
                data-name="Rectangle 55121"
                d="m3.693-2.749.2-.074 5.279 14.226-.2.075z"
              />
              <path
                data-name="Rectangle 55122"
                d="m4.422-3.02.2-.074 5.279 14.226-.2.075z"
              />
            </g>
          </g>
          <g data-name="Group 103423">
            <g
              data-name="Group 103422"
              transform="translate(-13736.171 -4252.525)"
              clipPath="url(#h)"
            >
              <path
                data-name="Path 97317"
                d="M111.868 100.78s-.647 1.6-1.2 1.6a18.359 18.359 0 0 0-3.543-.43c-1.418.122-7.087 3.018-9.984 2.987l-2.034 4.684a40.172 40.172 0 0 1 6.779-1.972c2.927-.462 11.432-.678 12.819-1.171s4.314-6.964 4.314-6.964Z"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97318"
                d="M111.868 100.78a6.77 6.77 0 0 1-.534 1.029c.4-.12 1.366.389 2.444 1.483.819.83 1.632 1.51 2.512 1.4a50.184 50.184 0 0 0 2.727-5.172Z"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97319"
                d="M96.913 104.748s1.4.431 5.583-1.331a3.329 3.329 0 0 1 .847 4.166 31.655 31.655 0 0 0-4.766 1.068c-1.89.657-3.7 1.191-3.7 1.191Z"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97320"
                d="M96.482 103.804s1.407 1.027 1.448 2.136c.036.974-1.083 1.361-1.546 2.317s-.046 1.433-1.371 1.895-2.619.17-4.36 1.156a4.551 4.551 0 0 1-3.806 0Z"
                fill="#070540"
              />
              <path
                data-name="Path 97321"
                d="M90.462 110.512c2.466-1.693 2.758-2.943 3.821-3.652s2.373-1.895 2.219-2.943c-.041-.278-.746-.869-.93-.995-.508-.349-9.4 7.8-9.392 8.27a8.069 8.069 0 0 0 .708.461c.674.273 2.167-.175 3.574-1.142"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97322"
                d="M89.882 110.121c2.466-1.693 2.758-2.943 3.821-3.652s2.373-1.895 2.219-2.943-1.51-.973-2.65-.094-.832 1.7-2.989 2.374a6.555 6.555 0 0 0-3.79 3.3c-.221.415-.768 1.673-.385 2.034.526.5 2.2.062 3.775-1.017"
                fill="#fff"
              />
              <path
                data-name="Path 97323"
                d="M131.305 94.538s4.478.041 5.793.246 2.177 3.082 3.369 3.944 3.575 1.479 6.04 4.643 2.3 3.533 2.3 3.533l3.7-.288s-12.531-15.654-13.558-16.476-6.2-1.972-6.2-1.972-1.068 5.259-1.438 6.368"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97324"
                d="M136.219 92.071c.19-.7.762-2.137.441-2.873-1.8-.555-3.92-1.027-3.92-1.027s-1.068 5.259-1.438 6.368c0 0 2.011.019 3.734.093a10.437 10.437 0 0 0 1.183-2.561"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97325"
                d="M148.402 106.022c.458.754.4.883.4.883l3.7-.288-.477-.6Z"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97326"
                d="M143.676 100.805c-.055-.077 2.129-2.136 2.581-2.116s4.581 5.711 5.115 6.266-2.976.959-3.419.644-1.243-1.651-2.415-2.842c-1.381-1.4-1.746-1.787-1.862-1.951"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97327"
                d="M147.385 104.977a2.71 2.71 0 0 0 2.339.428c1.309-.4.256-1.545.192-2.072s.277-.559.765-.071a31.763 31.763 0 0 0 6.253 2.882c1.14.262 1.464 1.048 1.418 1.387s-2.465.6-4.961.755a35.152 35.152 0 0 1-6.455-.077c-.154-.185-.038-3.172.449-3.232"
                fill="#070540"
              />
              <path
                data-name="Path 97328"
                d="M158.289 107.616c-.386.3-2.606.532-4.9.673a38.512 38.512 0 0 1-6.43-.06.9.9 0 0 0-.005.568 33.68 33.68 0 0 0 6.435.114c2.5-.154 4.915-.416 4.962-.755a1.406 1.406 0 0 0-.062-.538"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97329"
                d="M112.402 87.595s-1.109 1.725-.452 4.971.205 7.854-.616 8.59c0 0 1.192 1.359 3.657.534s4.15-1.934 5.629-2.057a40.583 40.583 0 0 0 .329-5.013s9.2 1.561 10.025 1.479 4.408-5.177 4.408-7.272c0 0-6.377-2.589-10.117-3.821s-12.861 2.588-12.861 2.588"
                fill="#5bcfde"
              />
              <g
                data-name="Group 103376"
                opacity={0.4}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103375">
                  <g
                    data-name="Group 103374"
                    clipPath="url(#i)"
                    fill="#5bcfde"
                    transform="translate(111.742 84.762)"
                  >
                    <path
                      data-name="Path 97330"
                      d="M13.524.246C9.784-.987.663 2.834.663 2.834S-.446 4.559.211 7.805a17.03 17.03 0 0 1 .3 2.7c.481-1.91 14.594-.895 14.193-9.86-.413-.145-.81-.28-1.18-.4"
                    />
                    <path
                      data-name="Path 97331"
                      d="M9.037 13.361a24.722 24.722 0 0 0 .168-3.5s-2.517-.411-3.739-.534 3.73 2.518 3.57 4.032"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97332"
                d="M133.839 71.972s7.472-.662 8.118-.863 1.865-1.88 2.08-2.034a6.283 6.283 0 0 1 1.962 0c.257.051.072.955.072.955a8.987 8.987 0 0 1 .462 1.587c0 .228-.226.847-.462.817s-.113-.329-.36-.38-.606.853-.9 1.017a2.156 2.156 0 0 1-1.356.1c-.657-.092-4.977 2.08-6.224 2.28a37.309 37.309 0 0 1-5.023-.108c-.277 0 .586-3.279 1.634-3.374"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97333"
                d="m134.197 71.944-.358.033c-1.048.095-1.911 3.374-1.634 3.374.131 0 .981.069 1.973.121-.142-.245.451-1.764.018-3.527"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97334"
                d="M146.072 72.435c.236.031.46-.589.462-.817a8.994 8.994 0 0 0-.462-1.587s.185-.9-.072-.955a9.451 9.451 0 0 0-1.643-.068c.133-.009.553-.011.544.336a4.051 4.051 0 0 0 .719 1.572 1.219 1.219 0 0 1-.125 1.27c.078-.09.152-.145.218-.131.247.051.123.349.36.38"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97335"
                d="M123.783 66.806c.085-.164 4.314-1.2 4.684-1.407s.657-1.084.919-1.438 1.453-1.249 1.556-1.506.119-.461.314-.461c.22 0 .365.454.3.933s-.752 1.042-.829 1.188a10.491 10.491 0 0 1 1.807-.016c.27.1.143.377-.02.437-.263.1-1.544.156-1.613.241s1.889-.017 2.074.051a.271.271 0 0 1-.04.511c-.224.046-1.577.156-1.7.2s1.877-.057 2.108.005.224.478-.077.532-2 .193-2.088.262 1.982.308 2.112.429.029.434-.279.465-1.834-.115-1.964-.092a7.083 7.083 0 0 1-1.641.485 5.537 5.537 0 0 0-.777-.082c-1.093.2-4.244 1.715-4.43 1.715a4.76 4.76 0 0 1-.411-2.455"
                fill="#ff946e"
              />
              <path
                data-name="Path 97336"
                d="M127.497 65.71c-1.363.387-3.651.977-3.713 1.1a4.759 4.759 0 0 0 .411 2.455c.159 0 2.462-1.1 3.819-1.546a4.475 4.475 0 0 1-.516-2.006"
                fill="#f07d18"
              />
              <path
                data-name="Path 97337"
                d="M121.954 63.047a3.552 3.552 0 0 0-3.739-1.053c-2.239.76-3.287 3.847-3.348 7.935-.05 3.308.575 9.747-.349 11.873s-3.02 4.931-3.359 6.194c0 0 3.287 5.226 8.659 3.944 7.364-1.756 5.762-11.555 6.584-15.971 1.518.121 4.17.81 5.125.9s2.589-3.513 2.312-4.9a45.441 45.441 0 0 1-6.091-2.39 9.588 9.588 0 0 1-3.979-3.6Z"
                fill="#6b78b7"
              />
              <g
                data-name="Group 103379"
                opacity={0.33}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103378">
                  <g
                    data-name="Group 103377"
                    clipPath="url(#j)"
                    transform="translate(121.469 65.849)"
                  >
                    <path
                      data-name="Path 97338"
                      d="M.002.531C2.916 3.81 6.408 4.876 8.325 4.652c-.719-.3-1.383-.6-2-.9A9.654 9.654 0 0 1 2.301.131l-.083-.134A18.94 18.94 0 0 1 .002.528"
                      fill="#6b78b7"
                    />
                  </g>
                </g>
              </g>
              <g
                data-name="Group 103382"
                opacity={0.44}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103381">
                  <g
                    data-name="Group 103380"
                    clipPath="url(#k)"
                    transform="translate(118.16 61.831)"
                  >
                    <path
                      data-name="Path 97339"
                      d="M3.794 1.216A3.8 3.8 0 0 0 1.502 0c.606.115 1.053 1.44.124 1.636a3.858 3.858 0 0 0-1.563.629c1.1-.452 2.231-.5 1.676-.288s-.895.585-.721.551c1.043-.2 1.8.006 1.611.193a5.76 5.76 0 0 0 .456.605c.02 0 .511-.689 1.037-1.437Z"
                      fill="#6b78b7"
                    />
                  </g>
                </g>
              </g>
              <g
                data-name="Group 103385"
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103384">
                  <g
                    data-name="Group 103383"
                    clipPath="url(#l)"
                    transform="translate(123.662 75.893)"
                  >
                    <path
                      data-name="Path 97340"
                      d="M.072.168c-.557.286 2.308 3.1 2.235 5.569.154-2.152.161-4.2.433-5.662A7.63 7.63 0 0 0 .072.168"
                      fill="#6b78b7"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97341"
                d="M120.79 64.551a1.7 1.7 0 0 0 1.165-.707l4.681 2.358a3.308 3.308 0 0 0-2.8 1.51c-.468.527-3.672-1.887-3.047-3.16"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97342"
                d="M122.801 64.27s-.641.68-.781.627-.341-.572-.381-.691a3.1 3.1 0 0 0 .5-1.93 23.918 23.918 0 0 1-.108-3.354c.108-.27 5.313.062 5.623.23a18.615 18.615 0 0 1-.177 2.328 30.245 30.245 0 0 1-2.6 3.419Z"
                fill="#070540"
              />
              <path
                data-name="Path 97343"
                d="M123.118 63.05c-.607.026-.512.673-.4 1.028.156.485.653.956.92.654a5.919 5.919 0 0 0 .338 1.064c.216.475 2.373 1.409 2.948 1.107.432-.227.4-2.711.46-3.2s.3-.741.309-.949a13.244 13.244 0 0 0-.252-1.423c-.057-.273-.654.266-1.445 0s-2.035-.956-1.963-.827c.052.094 1.15.575 1.215.791a7.55 7.55 0 0 1-.338 1.308c0 .144-.467.151-.517.3s-.1 1.107-.115 1.222-.388.05-.4-.05-.106-1.056-.767-1.028"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97344"
                d="M60.209 52.87s.637 1.649 6.765 3.406 6.97-1.92 6.97-1.92l-.207-4.171s-11.694-1.6-11.772-1.7a9.082 9.082 0 0 0-1.755 4.385"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97345"
                d="M60.271 70.21a50.743 50.743 0 0 0-.364 5.967c.155 1.226 6.931 7.394 7.982 7.919 0 0 2.415-2.308 2.294-2.616s-4.762-4.966-5.209-5.61.212-4.619.212-4.619Z"
                fill="#450603"
              />
              <path
                data-name="Path 97346"
                d="M70.099 81.179c.648.926 3.169 5.194 4.6 6.6 0 0-.415 1.962-1.311 2.532-.192.121-1.088-.528-1.245-.484a28.474 28.474 0 0 0-1.966-2.918 31.945 31.945 0 0 0-2.61-2.9c-.4-.391 2.272-3.2 2.533-2.831"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97347"
                d="M77.446 88.676a30.889 30.889 0 0 1-3.19 6.381 7.3 7.3 0 0 1-1.782 1.618 2.42 2.42 0 0 1-.3-1.259 25.5 25.5 0 0 0 .369-3.829 4.171 4.171 0 0 0-1.3-2.175 3.153 3.153 0 0 1 .581-.016c.388.017 1.2.684 1.243.563.057-.167-.608-1.065-.646-1.521s1.463-1.54 1.211-1.936c.208-.217.454.059 1.2.777.344.33 2.094.571 2.615 1.4"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97348"
                d="M74.256 95.057a32.658 32.658 0 0 0 3.187-6.32 1.015 1.015 0 0 1 .351.4 30.8 30.8 0 0 1-3.19 6.381 7.294 7.294 0 0 1-1.782 1.618 2.294 2.294 0 0 1-.211-.516 10.336 10.336 0 0 0 1.645-1.567"
                fill="#070540"
              />
              <g
                data-name="Group 103388"
                opacity={0.4}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103387">
                  <g
                    data-name="Group 103386"
                    clipPath="url(#m)"
                    transform="translate(71.24 87.465)"
                  >
                    <path
                      data-name="Path 97349"
                      d="M.581 1.931c.388.016 1.2.685 1.243.563.057-.167-.608-1.065-.646-1.52-.019-.224.336-.6.67-.973A8.039 8.039 0 0 0 5.154 4.94a21.2 21.2 0 0 1-1.79 3.118 7.3 7.3 0 0 1-1.782 1.618 2.289 2.289 0 0 1-.211-.516c-.075.049-.125.071-.137.051a2.421 2.421 0 0 1-.3-1.259 25.5 25.5 0 0 0 .369-3.829 4.168 4.168 0 0 0-1.3-2.175 3.139 3.139 0 0 1 .581-.016"
                      fill="#6b78b7"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97350"
                d="M69.417 74.903a12.487 12.487 0 0 0 .064 1.929c.188.712 2.492 7.6 2.638 10.759l-3.473.764s-4.365-9.774-4.565-10.986a33.5 33.5 0 0 1-.2-3.8Z"
                fill="#691813"
              />
              <path
                data-name="Path 97351"
                d="M64.006 76.77c-.125-1.305-.126-3.194-.126-3.194l5.537 1.329c.067.123-.089 1.024.022 1.722a7.762 7.762 0 0 0-5.433.143"
                fill="#450603"
              />
              <path
                data-name="Path 97352"
                d="M72.427 87.326a76.149 76.149 0 0 0 1.036 9.636 5.089 5.089 0 0 1-2.559 1.623c-.478.047-1.137-1.017-1.439-1.1a35.04 35.04 0 0 0-.205-4.326 39.327 39.327 0 0 0-.9-4.717c-.173-.668 4.027-1.673 4.063-1.115"
                fill="#fff"
              />
              <path
                data-name="Path 97353"
                d="M75.149 99.552a23.279 23.279 0 0 1-2.508 2.377 11.512 11.512 0 0 1-4.158 3.245 5.407 5.407 0 0 1-3.043-.269 2.974 2.974 0 0 1 .486-1.518 31.426 31.426 0 0 0 2.834-3.793 5.023 5.023 0 0 0-.272-3.075 2.645 2.645 0 0 1 1.32.165c.4.265.949 1.377 1.07 1.276.166-.14.04-1.509.291-2.013s2.016-.4 1.933-.965c.393-.142.489.308.819 1.541.152.567 1.207 1.828 1.228 3.029"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97354"
                d="M68.484 105.173a11.52 11.52 0 0 0 4.158-3.244c1.071-1.042 2.251-1.946 2.469-2.286a2.85 2.85 0 0 1 .085.815 35.555 35.555 0 0 1-2.7 2.506 11.513 11.513 0 0 1-4.158 3.244 5.125 5.125 0 0 1-2.966-.265 4.888 4.888 0 0 1 .073-1.04 5.408 5.408 0 0 0 3.044.268"
                fill="#070540"
              />
              <path
                data-name="Path 97355"
                d="M73.943 54.356a10.742 10.742 0 0 0 0 2.156s-9.435 1.359-10-2.937a28.212 28.212 0 0 0-.315-3.262s9.942 1.873 10.313 4.043"
                fill="#691813"
              />
              <path
                data-name="Path 97356"
                d="M63.903 53.03a16.445 16.445 0 0 0-.272-2.717s9.941 1.872 10.313 4.043c0 0-.013.154-.028.383-2.85-1.969-10.013-1.71-10.013-1.71"
                fill="#450603"
              />
              <path
                data-name="Path 97357"
                d="M63.904 53.064c0 2-2.676 8.7-4.686 17.69.331 1.262 3.518 1.328 3.518 1.328s-.137 1.309-.169 1.958c.677 1.561 6.4 2.056 7.254 1.283.408-1.35 1.548-6.611 1.939-8.018s2.3-3.83 2.719-6.1.018-5.083-.6-5.585c-.176 1.244-9.789.119-9.977-2.552"
                fill="#6b78b7"
              />
              <g
                data-name="Group 103391"
                opacity={0.3}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103390">
                  <g
                    data-name="Group 103389"
                    clipPath="url(#n)"
                    transform="translate(63.393 53.064)"
                  >
                    <path
                      data-name="Path 97358"
                      d="M0 2.578A11.537 11.537 0 0 0 .512 0c.188 2.671 9.8 3.8 9.977 2.552.576.469.966 2.952.67 5.131C9.998 5.783 4.491 2.903 0 2.577"
                      fill="#6b78b7"
                    />
                  </g>
                </g>
              </g>
              <g
                data-name="Group 103394"
                opacity={0.3}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103393">
                  <g
                    data-name="Group 103392"
                    clipPath="url(#o)"
                    transform="translate(59.598 64.554)"
                  >
                    <path
                      data-name="Path 97359"
                      d="M.005 6.737c1.687.571 4.479-8.032 4.2-6.57-.441 2.334-1.064 7.36-1.064 7.36a6.73 6.73 0 0 1-3.139-.79"
                      fill="#6b78b7"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97360"
                d="M74.392 12.648c-1.453-.105.323-4.431.417-5.3.046-.423-.994-.134-1.616.285s-.656 1.635-.656 1.635l-.5 3.765s2.215-.033 2.349-.387"
                fill="#450603"
              />
              <g
                data-name="Group 103397"
                opacity={0.5}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103396">
                  <g
                    data-name="Group 103395"
                    clipPath="url(#p)"
                    transform="translate(72.043 7.143)"
                  >
                    <path
                      data-name="Path 97361"
                      d="M2.349 5.505c-1.453-.105.323-4.431.417-5.3.046-.423-.994-.134-1.616.285S.494 2.125.494 2.125l-.5 3.765s2.215-.033 2.349-.387"
                      fill="#450603"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97362"
                d="M72.359 6.996a5.157 5.157 0 1 1 4.366 5.841 5.157 5.157 0 0 1-4.366-5.841"
                fill="#f07d18"
              />
              <path
                data-name="Path 97363"
                d="M74.971 5.001a6.607 6.607 0 0 1 .826-1 8.9 8.9 0 0 1-1.133-.6 5.224 5.224 0 0 1 .483-.277 8.5 8.5 0 0 0 1.047.517 5.556 5.556 0 0 1 .935-.624 5.121 5.121 0 0 1 .994-.4l.076.009a5.165 5.165 0 0 1 1.1.287 4.658 4.658 0 0 0-1.945.531 5.084 5.084 0 0 0-.629.4 15.855 15.855 0 0 0 2.167.525 7.143 7.143 0 0 1 3.236 1.176 5.13 5.13 0 0 1 .353 1.017c-1.01-1.207-2.235-1.442-3.68-1.717a15 15 0 0 1-2.515-.641 5.967 5.967 0 0 0-.835.95 13.681 13.681 0 0 1 6.659 4.812 5.1 5.1 0 0 1-.258.472 13.115 13.115 0 0 0-6.666-4.884 8.727 8.727 0 0 0-.927 2.176 6.63 6.63 0 0 1 2.008 1.83 10.4 10.4 0 0 0 3.32 2.872 5.139 5.139 0 0 1-.591.227 11.342 11.342 0 0 1-3.1-2.787 6.679 6.679 0 0 0-1.76-1.672 14.278 14.278 0 0 0-.354 3.151 5.173 5.173 0 0 1-.47-.551 14.212 14.212 0 0 1 .375-2.793 3.209 3.209 0 0 0-1.382-.1c-.005-.16 0-.322.008-.485a3.7 3.7 0 0 1 1.5.13 9.294 9.294 0 0 1 .9-2.138 9.419 9.419 0 0 0-1.681-.317 5.192 5.192 0 0 1 .295-.441 10.617 10.617 0 0 1 1.644.349"
                fill="#070540"
              />
              <path
                data-name="Path 97364"
                d="M71.25 28.26s-.847-1.565-1.131-2.135 3.394-11.076 3.681-11.5c.728-1.087 2.662-1.3 2.743-1.836.048-.311-.187-.427-.534-.471a4.7 4.7 0 0 0-1.6.223c-.363.072-1.322.2-1.578-.039-.41-.381-.345-2.024-.324-2.456s.093-1.192-.089-1.33c-.2-.152-.539.109-.683 1.105s-.124 4.019-.329 4.571-2.777 5.45-3.29 6.414a42.929 42.929 0 0 0-1.821 4.5c-.138.52 2.575 5.88 2.92 6.511s2.289-2.961 2.039-3.556"
                fill="#450603"
              />
              <g
                data-name="Group 103400"
                opacity={0.3}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103399">
                  <g
                    data-name="Group 103398"
                    clipPath="url(#q)"
                    transform="translate(67.91 27.161)"
                  >
                    <path
                      data-name="Path 97365"
                      d="m2.754 0 .59 1.1c.25.594-1.695 4.186-2.039 3.555-.145-.265-.71-1.37-1.3-2.6A6.046 6.046 0 0 0 2.759-.002"
                      fill="#450603"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97366"
                d="M74.792 32.09a23.516 23.516 0 0 1-3.6-4.193 6.5 6.5 0 0 0-2.353 3.142c-.521 1.886 4.8 4.78 6.021 4.94s1.641-3.211-.064-3.889"
                fill="#ffb718"
              />
              <path
                data-name="Path 97367"
                d="M80.346 33.483a3.854 3.854 0 0 1 1.858-1.322 2.276 2.276 0 0 0 1.6-2.82 3.89 3.89 0 0 0-2.651-2.693 1.3 1.3 0 0 0-1.132.529s-1.744 5.2.33 6.308"
                fill="#330300"
              />
              <path
                data-name="Path 97368"
                d="M75.911 30.26c-.1.436.781 1.174.805 1.307a11.86 11.86 0 0 1-.476 1.443s3.469 1.787 3.586 1.881c0 0 .262-.834.519-1.407a1.845 1.845 0 0 1 .834-1.98c.61-.444-.035-.607-.035-.607s-4.638-1.513-5.234-.637"
                fill="#450603"
              />
              <path
                data-name="Path 97369"
                d="M79.785 31.179c.25.3 1.172.762 1.64.088s-.223-.995-1.084-.84c-.136.025.839-1.018.849-1.127.007-.072 0-.473.12-.6.267-.283.6-.426.5-.7a1.505 1.505 0 0 0-1.272-.905 5.187 5.187 0 0 0-2.361.956 17.67 17.67 0 0 0-2.267 2.211c.445-.364 1.419.717 2.526 1.128a1.346 1.346 0 0 0 1.349-.208"
                fill="#691813"
              />
              <path
                data-name="Path 97370"
                d="M81.098 36.769s-.418 2-3.364 7.736c-1.893 3.687-3.716 7.184-3.79 9.851.119-.516-13.278-4.224-13.734-1.486a9.194 9.194 0 0 1 1.733-6.168c2.21-3.23 8.3-12.189 10.985-13.321s8.17 3.388 8.17 3.388"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97371"
                d="M78.212 39.251c-5.321-.646-9.047 2.732-12.454 2.01 2.543-3.482 5.507-7.179 7.169-7.88 2.119-.893 5.98 1.733 7.514 2.88-.05.01-1.121 3.124-2.229 2.99"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97372"
                d="M70.838 37.064s-.691-.027-.809-2.66 4.542 1.175 4.542 1.175-2.223 2.024-3.733 1.484"
                fill="#ffb718"
              />
              <path
                data-name="Path 97373"
                d="M71.596 31.383a7.314 7.314 0 0 1-2.256-.871c-.538-.482-4.009-8.021-4.224-8.581s.654-1.26.9-1.682a7.257 7.257 0 0 1 1.475-1.3c.271-.221-.245-.557-.553-.484s-1.311.672-1.57.666-.39-1.177-.416-1.648.214-3.062.225-3.351a.292.292 0 0 0-.569-.082c-.112.347-.512 2.749-.606 2.9s-.244.11-.25.005.084-3.091.1-3.328-.444-.448-.559-.081-.394 3.16-.471 3.263-.895-2.714-.979-2.923-.559-.153-.539.213.806 2.971.737 3.158-1.326-1.385-1.492-1.523-.622-.007-.38.4 1.1 1.6 1.259 1.915.286 2.117.472 2.694a4.968 4.968 0 0 0 .845 1.191c.148.2 2.432 9.453 2.893 10.428s6.815 4.114 7.32 4.5 1.225-4.6-1.357-5.478"
                fill="#691813"
              />
              <g data-name="Group 103403" opacity={0.5}>
                <g data-name="Group 103402">
                  <g
                    data-name="Group 103401"
                    clipPath="url(#r)"
                    transform="translate(70.577 31.281)"
                  >
                    <path
                      data-name="Path 97374"
                      d="M.616 0c.24.065.4.1.4.1 2.582.881 1.861 5.863 1.357 5.478-.187-.142-1.179-.664-2.376-1.313 1.18-.61.639-4.2.616-4.267"
                      fill="#450603"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97375"
                d="M81.149 35.519c-.316-1.369-1.584-2.611-4.216-3.038s-5.337-1.1-5.337-1.1c1.321 1.095 1.126 6.335-.759 5.681 0 0 5.2 2.044 7.374 2.186s3.205-2.569 2.937-3.732"
                fill="#feda00"
              />
              <path
                data-name="Path 97376"
                d="M81.194 94.052s-.472.1-.654.149c-.107.026-.11.5-.042.613s.755.2.755.2.051-.912-.06-.961"
                fill="#070540"
              />
              <path
                data-name="Path 97377"
                d="m81.218 95.308-.639.2c-.1.035-.067.51.011.614s.769.135.769.135-.027-.913-.141-.953"
                fill="#070540"
              />
              <path
                data-name="Path 97378"
                d="M81.684 99.64h-.628c-.1 0-.2.437-.161.552s.651.334.651.334.229-.824.138-.891"
                fill="#070540"
              />
              <path
                data-name="Path 97379"
                d="M81.389 101.844s-.363.159-.5.224c-.082.038-.009.422.064.5s.639.04.639.04-.1-.742-.2-.764"
                fill="#070540"
              />
              <path
                data-name="Path 97380"
                d="M81.719 103.111s-.218.331-.3.461c-.048.076.247.346.361.345a2.308 2.308 0 0 0 .523-.274s-.491-.566-.584-.532"
                fill="#070540"
              />
              <path
                data-name="Path 97381"
                d="M93.311 88.748c.266 1.49-6.411 5.1-8.23 8.816a9.7 9.7 0 0 1-1.405 2.218c-.857.341-1.3-.957-1.373-1.452-.192-1.332-.026-4.223 1.315-5.22a17.157 17.157 0 0 0 3.847-5.216c.653-1.312 2.456-3.862 3.824-3.885s2.022 4.739 2.022 4.739"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97382"
                d="M88.779 85.867c.108-.136.218-.269.331-.4-.012.042-.025.083-.035.13-.481 2.458.888 4.908 2.041 5.514a1.432 1.432 0 0 0 .2.088l-.219.185c-.034-.015-.069-.03-.1-.048-1.171-.616-2.55-3.008-2.219-5.47"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97383"
                d="M84.917 97.739c.386-.822-.624-1.553-1.423-.617l-.076 1.889s1.045.237 1.026.208.473-1.481.473-1.481"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97384"
                d="M83.618 93.11c-.4.235-1.661-.138-2.457.594-.7.64.431 4.077.282 4.89a8.415 8.415 0 0 0-.459 3.443 2.228 2.228 0 0 0 2.47 2.167c.769-.158.777-2.7 1.036-4.294a5.634 5.634 0 0 1 .98-2.587s-.853-.037-1.4 1.349a1.6 1.6 0 0 0-.356-1.71 2.9 2.9 0 0 1-.1-3.852"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97385"
                d="M90.352 77.048s.864.5 4.92.285 2.491-2.22.779-2.369-5.731.977-5.7 2.084"
                fill="#070540"
              />
              <path
                data-name="Path 97386"
                d="M100.18 77.091a35.653 35.653 0 0 1-2.228 5.9c-1.084 1.885-1.278 3.09-1.906 3.7-.51.494-1.327 1.005-2.845 2.553-.834.851-2.925-1.959-2.437-4.451.192-.983 1.254-.867 1.4-1.8s-.193-5.944-.26-6.949 7.78-2.23 8.273 1.044"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97387"
                d="M92.064 78.538c-.064-1.117-.131-2.1-.156-2.489-.068-1.005 7.78-2.229 8.273 1.044 0 0-.1.34-.26.867-2.228-1.192-6.912-.048-7.856.578"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97388"
                d="M99.337 61.195s1.91 6.288 1.78 9.465a30.148 30.148 0 0 1-.958 6.522c.012-.107.278-1.107-2.861-1.251-3.571-.164-6.81.484-6.946 1.116-.775-.081 1.178-3.014.714-7.94a8.25 8.25 0 0 0-4.1-6.674s.937-.9.631-1.832c0 0 9.411 1.708 11.742.594"
                fill="#6b78b7"
              />
              <g
                data-name="Group 103406"
                opacity={0.3}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103405">
                  <g
                    data-name="Group 103404"
                    clipPath="url(#s)"
                    transform="translate(86.964 60.601)"
                  >
                    <path
                      data-name="Path 97389"
                      d="M12.373.594s1.91 6.288 1.78 9.465a30.148 30.148 0 0 1-.958 6.522c.012-.107.278-1.107-2.861-1.251-3.571-.164-6.81.484-6.946 1.116-.775-.081 1.178-3.014.714-7.94a8.25 8.25 0 0 0-4.1-6.674S.939.932.633 0c0 0 9.411 1.708 11.742.594"
                      fill="#6b78b7"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97390"
                d="M91.839 75.046s1.39 1.046 4.535-2.347c3.308-3.569-3.245-3.69-3.394-3.669s-2.606 4.206-1.141 6.017"
                fill="#070540"
              />
              <path
                data-name="Path 97391"
                d="M100.167 66.355s5.214 4.286 6.447 6.036c.4.573-.229 3.295-.318 6.247a15.93 15.93 0 0 0-5.438.239c.246-1.3.741-2.4.739-2.71 0-.5-6.513-3.207-8.283-4.416s1.513-7.4 6.853-5.4"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97392"
                d="M100.167 66.355s.208.171.548.456c-.023.007-.046.012-.07.02-2.874.971-4.048 3.73-4.359 6.509a28.5 28.5 0 0 1-2.972-1.589c-1.77-1.209 1.513-7.4 6.853-5.4"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97393"
                d="M100.167 66.354c.613-.5-10.791-7.041-12.978-5.869-1.881 1.009-.946 5.37-.3 7.458 1.1 3.577 3.344 4.751 4.955 7.1-.406-.484-.324-1.71 1.773-3.475 2.392-2.013 2.639-5.19 6.554-5.212"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97394"
                d="M101.919 89.448c-.187-2.146-1.213-7.016-1.2-10.64a6.957 6.957 0 0 1 5.659-.255c-.169 4.111-.739 8.906-.877 12.185a18.013 18.013 0 0 0 .643 3.627s-2.354 1.671-4.778-2.449c.226-.188.708-.629.549-2.468"
                fill="#e6e8f4"
              />
              <path
                data-name="Path 97395"
                d="m106.306 80.042-.016.262a7.922 7.922 0 0 0-5.51.21 24.988 24.988 0 0 1-.017-.273 8.2 8.2 0 0 1 5.543-.2"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97396"
                d="M106.807 96.062a15.021 15.021 0 0 1-.453-2.918c0-.363-.814-.025-.781.724.027.6 1.437 3.031 1.234 2.194"
                fill="#6b78b7"
              />
              <path
                data-name="Path 97397"
                d="M102.021 95.507a5.448 5.448 0 0 1-.714.414c-.148.082-.543-.315-.474-.447s.335-.822.425-.817a2.736 2.736 0 0 1 .763.849"
                fill="#070540"
              />
              <path
                data-name="Path 97398"
                d="M104.56 98.084a4.923 4.923 0 0 1-.7.244c-.145.047-.426-.371-.342-.476s.437-.669.515-.649a2.468 2.468 0 0 1 .528.881"
                fill="#070540"
              />
              <path
                data-name="Path 97399"
                d="M105.553 99.006a4.421 4.421 0 0 1-.557.373c-.115.074-.456-.227-.407-.337s.228-.684.3-.684a2.22 2.22 0 0 1 .663.648"
                fill="#070540"
              />
              <path
                data-name="Path 97400"
                d="M106.76 100.083a3.912 3.912 0 0 1-.464.373c-.1.075-.421-.166-.386-.267s.149-.622.214-.628a1.97 1.97 0 0 1 .636.522"
                fill="#070540"
              />
              <path
                data-name="Path 97401"
                d="M108.08 100.457a3.656 3.656 0 0 1-.317.453c-.067.092-.419-.038-.414-.138s-.027-.594.028-.616a1.83 1.83 0 0 1 .7.3"
                fill="#070540"
              />
              <path
                data-name="Path 97402"
                d="M101.187 94.537a5.479 5.479 0 0 1-.714.414c-.148.082-.543-.315-.474-.447s.335-.822.425-.817a2.738 2.738 0 0 1 .763.849"
                fill="#070540"
              />
              <path
                data-name="Path 97403"
                d="M107.229 97.145c-.539-1.462-1.076-2.632-1.265-3.3-.172-.6-1.908.2-2.787-.169a2.191 2.191 0 0 1-1.541-2.055 2.346 2.346 0 0 0-1.486 2.031.478.478 0 0 0 .113.3 23.625 23.625 0 0 0 2.008 2.083c1.482 1.389 2.474 3.076 4.017 4.129s2.395.63 2.556.312-1.285-2.44-1.616-3.338"
                fill="#acb3d8"
              />
              <path
                data-name="Path 97404"
                d="M81.503 47.596s-1.825.67-2.252.862-1.345 1.719-2.493 2.838a14.652 14.652 0 0 0-2.727 3.225c-.177.58.263 1.784.125 2.326a18.738 18.738 0 0 1-.745 2.07c-.117.232-.543.172-.716-.2 0 0-1.129.223-1.541-.286a4.47 4.47 0 0 1-.446-1.68 15.92 15.92 0 0 1 1.486-3.017c.293-.5 3.595-6.574 4.528-7.248a17.3 17.3 0 0 1 2.932-1.305s1.813 1.81 1.85 2.411"
                fill="#ffd2b3"
              />
              <path
                data-name="Path 97405"
                d="M71.153 58.435a1.843 1.843 0 0 1-.249-.587 2.572 2.572 0 0 0 .9-.052c.717-.171 1.373-1.69 1.03-.722a2.877 2.877 0 0 0-.143 1.647s-1.129.223-1.541-.286"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97406"
                d="M79.652 45.185s1.814 1.81 1.85 2.411c0 0-1.825.67-2.252.862a1.78 1.78 0 0 0-.477.448c.495-.626-.241-3.011-1.092-2.946.635-.272 1.29-.444 1.97-.774"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97407"
                d="M102.405 48.738s.9.069 1.182-1.558-3.781-2.778-3.781-2.778 1.252 3.7 2.6 4.336"
                fill="#00b09d"
              />
              <path
                data-name="Path 97408"
                d="M103.437 43.362s4.445 2.152 5.113 2.329 1.521 3.878 1.131 3.858a65.94 65.94 0 0 1-8.7-2.569c-.217-.161.4-3.972 2.451-3.618"
                fill="#ffd2b3"
              />
              <g data-name="Group 103409" opacity={0.4}>
                <g data-name="Group 103408">
                  <g
                    data-name="Group 103407"
                    clipPath="url(#t)"
                    transform="translate(106.61 44.862)"
                  >
                    <path
                      data-name="Path 97409"
                      d="M0 0c.89.41 1.687.761 1.94.828.668.177 1.521 3.878 1.131 3.859a18.855 18.855 0 0 1-2.911-.69c1.053.055.2-3.135-.161-4"
                      fill="#ffb98b"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97410"
                d="m103.437 43.362.54.26c-.588.079-1.166 3.611-1.064 4.041-1.074-.354-1.857-.631-1.927-.683-.217-.161.4-3.972 2.451-3.618"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97411"
                d="M109.96 45.874a3.526 3.526 0 0 0 .707 1.21 1.591 1.591 0 0 1-.49 2.382 1.878 1.878 0 0 1-2.174-1.347 7.052 7.052 0 0 1-.311-1.949Z"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97412"
                d="M110.393 44.156a7.4 7.4 0 0 1-.4 2.159 1.786 1.786 0 0 1-2.395.4 3.063 3.063 0 0 1-.97-1.99c-.008-.31.109-.465.594-.56s3.168-.005 3.168-.005"
                fill="#ffb98b"
              />
              <path
                data-name="Path 97413"
                d="M109.538 44.657s-.115.561.053.766.493.1.626-.225a6.684 6.684 0 0 0 .289-1.283c.05-.355-.525-.669-.7-.389a4.115 4.115 0 0 0-.267 1.13"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97414"
                d="M108.671 45.341s0 .464.325.547c.256.065.5-.1.562-.444a14.64 14.64 0 0 0 .24-1.951c-.029-.357-.7-.486-.853-.192a5.724 5.724 0 0 0-.275 2.04"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97415"
                d="M107.778 45.147s0 .586.394.68c.261.062.449-.179.508-.53a16.527 16.527 0 0 0 .263-2.081c-.028-.364-.779-.424-.932-.124a5.655 5.655 0 0 0-.233 2.055"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97416"
                d="M107.011 44.654s.087.512.309.657.5-.056.528-.408a12.092 12.092 0 0 0 .1-1.785c-.059-.357-.8-.352-.93-.043a3.311 3.311 0 0 0-.005 1.579"
                fill="#ffe5cf"
              />
              <path
                data-name="Path 97417"
                d="M95.861 34.644s.3-2.1-2.394-2.447a2.945 2.945 0 0 0-3.519 2.993c.011 1.373-.351 1.564-2.043 1.227s-3.839.562-3.861 2.62c-.038 3.512 1.556 4.471 5.217 5.157s5.86-3.767 5.287-5.753.168-3.917 1.312-3.8"
                fill="#f07d18"
              />
              <path
                data-name="Path 97418"
                d="M99.337 61.195s1.9-.806 1.521-2.125-2.2-4.457-1.722-7.324.911-2.292.34-4.737a10.949 10.949 0 0 0 2.929 1.727s-.87-.5-.658-2.328a3.92 3.92 0 0 1 1.691-3.048 34.171 34.171 0 0 1-4.284-2.167c-1.507-.995-3.442-.741-5.783-.4-3.142.46-6.772-.212-8.781.435s-3.779 2.994-5.949 4.366c-.526.332 1.931 3.675 3.941 2.908.362-.138 1.836-1.127 2.1-1.285s1.766.886 2.348 4.48a31.534 31.534 0 0 1-.1 7.638c-.265.813-.051 1.139.673 1.263s7.267 2.435 11.742.594"
                fill="#5bcfde"
              />
              <g data-name="Group 103412" opacity={0.5}>
                <g data-name="Group 103411">
                  <g
                    data-name="Group 103410"
                    clipPath="url(#u)"
                    fill="#00b09d"
                    transform="translate(93.211 40.494)"
                  >
                    <path
                      data-name="Path 97419"
                      d="M8.253 7.856a16.5 16.5 0 0 1-1.988-1.34 14.1 14.1 0 0 1-.63-2.8c.023-.75 1.837 3.741 2.618 4.139"
                    />
                    <path
                      data-name="Path 97420"
                      d="m8.35 7.907-.072-.038c.172.076.174.082.072.038"
                    />
                    <path
                      data-name="Path 97421"
                      d="M.119.307h.04A14.547 14.547 0 0 1 4.224.076C3.635.015 1.94.129 3.203.72c1.359.636 2.16 2.413 1.073 2.79S.167 2.357.167 2.357A3.74 3.74 0 0 1 .119.312"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97422"
                d="M95.859 40.028a5.273 5.273 0 0 1-1.413.32 10.581 10.581 0 0 1 .157 2.391c-.161 1.02-3.871-1.044-3.738-1.776a5.293 5.293 0 0 0 .627-3.179s4.764 2.064 4.367 2.243"
                fill="#ffb98b"
              />
              <g
                data-name="Group 103415"
                opacity={0.33}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103414">
                  <g
                    data-name="Group 103413"
                    clipPath="url(#v)"
                    transform="translate(91.493 37.788)"
                  >
                    <path
                      data-name="Path 97423"
                      d="M1.186.526A.47.47 0 0 0 .929.478a1.4 1.4 0 0 0-.853.38 2.636 2.636 0 0 0-.075-.86S.513.22 1.187.524"
                      fill="#ffb98b"
                    />
                  </g>
                </g>
              </g>
              <g
                data-name="Group 103418"
                opacity={0.33}
                style={{
                  mixBlendMode: "multiply",
                  isolation: "isolate",
                }}
              >
                <g data-name="Group 103417">
                  <g
                    data-name="Group 103416"
                    clipPath="url(#w)"
                    transform="translate(92.402 39.109)"
                  >
                    <path
                      data-name="Path 97424"
                      d="M.004 0s3.777.778 3.457.923a5.24 5.24 0 0 1-1.413.32A3.835 3.835 0 0 1 .004 0"
                      fill="#ffb98b"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97425"
                d="M95.742 34.267c.429.856.4 5.142.34 5.572-.036.275-.792.315-1.325.2a8.954 8.954 0 0 1-2.278-.862c-.236-.167-.317-.758-.343-1.069-.22.154-.7-.085-.841-.581-.042-.211.562-.407.822-.328a1.7 1.7 0 0 1 .361.58c-.24-1.789 1.255-1.93 1.038-3.308-.106-.672 1.387-.253 1.532-.329s.265-.733.694.123"
                fill="#ffd2b3"
              />
              <g data-name="Group 103421" opacity={0.5}>
                <g data-name="Group 103420">
                  <g
                    data-name="Group 103419"
                    clipPath="url(#x)"
                    transform="translate(84.679 45.943)"
                  >
                    <path
                      data-name="Path 97426"
                      d="M3.196 4.51c.953 1.4 4.057 2.925 4.142 3.478s-4.7-1.532-4.822-.022a17.947 17.947 0 0 0-.168-2.209C1.766 2.162.262 1.119 0 1.277 0 1.277 1.734-.031 2.2 0s.044 3.111 1 4.51"
                      fill="#00b09d"
                    />
                  </g>
                </g>
              </g>
              <path
                data-name="Path 97427"
                d="m98.85 26.107.624-1.609-1.057-.79-1.695.621-.582 1.427 1.047.917Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97428"
                d="m96.241 25.666.508-1.246a.11.11 0 0 1 .063-.061l1.538-.563a.109.109 0 0 1 .1.015l.88.658a.109.109 0 0 1 .037.126l-.549 1.415a.108.108 0 0 1-.066.064l-1.5.509a.109.109 0 0 1-.107-.021l-.882-.773a.109.109 0 0 1-.029-.123"
                fill="#fff"
              />
              <path
                data-name="Path 97429"
                d="m96.139 25.755-.939 1.2.433 1.581 1.21-.141.342-1.724Z"
                fill="#070540"
              />
              <path
                data-name="Path 97430"
                d="m99.473 24.498 1.732.254.382-.436a4.028 4.028 0 0 0-2.794-1l-.377.391Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97431"
                d="M98.865 23.375a3.964 3.964 0 0 1 2.549.874.109.109 0 0 1 .013.157l-.206.236a.11.11 0 0 1-.1.036l-1.6-.235a.113.113 0 0 1-.049-.021l-.869-.649a.109.109 0 0 1-.013-.163l.194-.2a.111.111 0 0 1 .078-.034"
                fill="#fff"
              />
              <path
                data-name="Path 97432"
                d="m98.416 23.707.376-.39a4.033 4.033 0 0 0-2.294.8l.223.206Z"
                fill="#070540"
              />
              <path
                data-name="Path 97433"
                d="m96.139 25.755.582-1.427-.223-.206a4.047 4.047 0 0 0-1.586 2.738l.288.1Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97434"
                d="M95.065 26.847a.11.11 0 0 1-.074-.12 3.96 3.96 0 0 1 .937-1.995 4.031 4.031 0 0 1 .492-.473.109.109 0 0 1 .143.005l.03.029a.109.109 0 0 1 .027.121l-.529 1.3a.111.111 0 0 1-.015.026l-.85 1.088a.109.109 0 0 1-.121.036Z"
                fill="#fff"
              />
              <path
                data-name="Path 97435"
                d="m95.2 26.956-.288-.1a4.045 4.045 0 0 0 .719 2.841v-1.162Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97436"
                d="M95.366 29.144a3.973 3.973 0 0 1-.414-2.064.109.109 0 0 1 .144-.094.108.108 0 0 1 .07.074l.4 1.472a.12.12 0 0 1 0 .029v.534a.109.109 0 0 1-.206.05"
                fill="#fff"
              />
              <path
                data-name="Path 97437"
                d="m98.166 29.554-.661-.579-.661-.579-1.21.141V29.7a4.027 4.027 0 0 0 1.4 1.228l1.153-.155Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97438"
                d="M96.3 30.35a4 4 0 0 1-.587-.64.112.112 0 0 1-.022-.066v-.956a.109.109 0 0 1 .1-.108l.986-.115a.11.11 0 0 1 .085.026l1.209 1.059a.11.11 0 0 1 .037.08l.015.992a.108.108 0 0 1-.094.11l-.948.128a.109.109 0 0 1-.068-.013 3.982 3.982 0 0 1-.712-.5"
                fill="#fff"
              />
              <path
                data-name="Path 97439"
                d="m100.176 27.263-.661-.579-.661-.579-1.664.566-.342 1.724.661.579.661.579 1.664-.566Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97440"
                d="m96.922 28.313.3-1.532a.109.109 0 0 1 .072-.082l1.479-.5a.11.11 0 0 1 .107.021l1.175 1.03a.109.109 0 0 1 .035.1l-.3 1.532a.11.11 0 0 1-.072.082l-1.479.5a.108.108 0 0 1-.107-.021l-1.175-1.029a.109.109 0 0 1-.035-.1"
                fill="#fff"
              />
              <path
                data-name="Path 97441"
                d="m100.172 27.264 1.513-.83.923.944-.393 1.761-1.338.765-1.047-.917Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97442"
                d="m99.908 28.904.307-1.55a.109.109 0 0 1 .055-.074l1.331-.73a.108.108 0 0 1 .13.019l.768.786a.109.109 0 0 1 .028.1l-.356 1.6a.108.108 0 0 1-.052.071l-1.168.668a.108.108 0 0 1-.126-.013l-.882-.773a.109.109 0 0 1-.035-.1"
                fill="#fff"
              />
              <path
                data-name="Path 97443"
                d="m101.206 24.752-1.732-.254-.623 1.609.661.579.661.579 1.513-.83Z"
                fill="#070540"
              />
              <path
                data-name="Path 97444"
                d="m100.877 29.906-1.068 1.089-1.624-.222-.019-1.218 1.664-.566Z"
                fill="#070540"
              />
              <path
                data-name="Path 97445"
                d="m101.685 26.435-.48-1.683.382-.436a4.027 4.027 0 0 1 1.358 2.639l-.338.424Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97446"
                d="m101.732 26.378-.443-1.553a.109.109 0 0 1 .023-.1l.207-.236a.109.109 0 0 1 .157-.008 3.962 3.962 0 0 1 1.2 2.412.11.11 0 0 1-.023.082l-.174.219a.109.109 0 0 1-.163.008l-.758-.776a.107.107 0 0 1-.027-.046"
                fill="#fff"
              />
              <path
                data-name="Path 97447"
                d="m102.607 27.379.338-.425a4.035 4.035 0 0 1-.5 2.381l-.234-.195Z"
                fill="#070540"
              />
              <path
                data-name="Path 97448"
                d="m100.877 29.905 1.338-.765.234.195a4.048 4.048 0 0 1-2.505 1.933l-.134-.273Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97449"
                d="m99.936 30.95.967-.986a.114.114 0 0 1 .024-.018l1.215-.7a.11.11 0 0 1 .124.011l.032.027a.11.11 0 0 1 .024.141 4.012 4.012 0 0 1-.4.55 3.958 3.958 0 0 1-1.854 1.191.109.109 0 0 1-.129-.057l-.019-.038a.109.109 0 0 1 .02-.124"
                fill="#fff"
              />
              <path
                data-name="Path 97450"
                d="m99.814 30.994.135.273a4.047 4.047 0 0 1-2.911-.339l1.152-.156Z"
                fill="#f4f3f3"
              />
              <path
                data-name="Path 97451"
                d="m98.2 30.835 1.512.206a.109.109 0 0 1 .008.215 3.973 3.973 0 0 1-2.1-.138.11.11 0 0 1 .021-.212l.528-.071a.1.1 0 0 1 .029 0"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default AmenityPass
