import React from "react"

export const JobIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
  <g id="Group_117695" data-name="Group 117695" transform="translate(-31 -530)">
    <rect id="Rectangle_58546" data-name="Rectangle 58546" width="40" height="40" rx="20" transform="translate(31 530)" fill="#e4e8ee"/>
    <path id="icons8-maintenance" d="M7.477,6a.661.661,0,0,0-.4.19l-.886.886a.664.664,0,0,0-.091.827l1.708,2.68a1.539,1.539,0,0,0,1.139.719,1.609,1.609,0,0,0,.178.01A1.529,1.529,0,0,0,10.058,11l3.254,3.254a2.28,2.28,0,0,0,.363,2.717l4.383,4.358A2.262,2.262,0,0,0,19.666,22h.009a2.278,2.278,0,0,0,1.618-3.881l-4.378-4.353a2.263,2.263,0,0,0-1.609-.676h-.01a2.257,2.257,0,0,0-1.016.249L11,10.056a1.537,1.537,0,0,0-.4-2.242L7.907,6.1A.666.666,0,0,0,7.477,6Zm10.1,0a4.772,4.772,0,0,0-1.283.1,4.645,4.645,0,0,0-3.582,3.772,4.833,4.833,0,0,0-.058.579L14.5,12.309a3.04,3.04,0,0,1,.793-.106,3.155,3.155,0,0,1,2.249.938l1.729,1.719A4.637,4.637,0,0,0,21.72,9.206a.662.662,0,0,0-1.095-.241l-1.977,1.976a1.207,1.207,0,0,1-1.7.029,1.15,1.15,0,0,1,.02-1.605L19,7.333a.663.663,0,0,0-.239-1.095A4.592,4.592,0,0,0,17.574,6.007Zm-6.308,7.451L6.657,18.09a2.266,2.266,0,0,0,1.608,3.862h.009a2.249,2.249,0,0,0,1.6-.67l3.425-3.44-.247-.245a3.175,3.175,0,0,1-.777-3.13Z" transform="translate(37 536.001)" fill="#98a0ac"/>
  </g>
</svg>

    )
}