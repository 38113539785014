import * as React from "react"
import { FontFamilySwitch } from "../utils/common";

const AvailableSlotSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={343.242}
    height={16}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={1.017}
        y1={0.5}
        x2={0}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ced3dd" />
        <stop offset={1} stopColor="#ced3dd" stopOpacity={0} />
      </linearGradient>
      <linearGradient id="b" x1={-0.017} x2={1} xlinkHref="#a" />
    </defs>
    <g data-name="Group 97172">
      <text
        data-name="Available Slots"
        transform="translate(123 11)"
        fill="#ced3dd"
        stroke="rgba(0,0,0,0)"
        fontSize={10}
        fontFamily={FontFamilySwitch().bold}
        fontWeight={700}
        letterSpacing=".062em"
      >
        <tspan x={0} y={0}>
          {"AVAILABLE SLOTS"}
        </tspan>
      </text>
      <g data-name="Group 97170" transform="translate(-18 -459.257)">
        <path
          data-name="Rectangle 53255"
          transform="translate(18 467)"
          fill="url(#a)"
          d="M0 0h104v1H0z"
        />
        <rect
          data-name="Rectangle 53256"
          width={6}
          height={6}
          rx={1}
          transform="rotate(45 -496.7 382.517)"
          fill="#ced3dd"
        />
      </g>
      <g data-name="Group 97171" transform="translate(232 4)">
        <path
          data-name="Rectangle 53255"
          transform="translate(5.243 3.743)"
          fill="url(#b)"
          d="M0 0h106v1H0z"
        />
        <rect
          data-name="Rectangle 53256"
          width={6}
          height={6}
          rx={1}
          transform="rotate(135 3.364 3.879)"
          fill="#ced3dd"
        />
      </g>
    </g>
  </svg>
)

export default AvailableSlotSVG
