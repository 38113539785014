import { Avatar, Box, Button, Grid, IconButton, Popover, Radio, Stack, Typography } from "@mui/material"
import React, { useRef, useState } from "react"
import PassDetails from "../../assets/passDetails"
import { DialogDrawer, MobileNumberInputComponent, TextBox } from "../../components"
import BulkImageUpload from "../../components/bulkImageUpload"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { generateImageUrl, img_size } from "../../utils"
import { AddVisitorPassContext } from "./addVisitorPassContext"
import { SelectBox } from "../../components/dropDown"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pencil from "../../assets/pencil"
import Bin from "../../assets/bin"
import CloseIcon from "@mui/icons-material/Close";
import { visitor_initial_state } from "./utils"

const Step2 = (props) => {
    const {
        t = () => false,
        data,
        visitorFormData,
        updateVisitorFormState,
        submitVisitorForm,
        setVisitorData,
        handleClick,
        handleClose,
        addDetails,
        setAddDetails,
        proofData,
        anchorEl,
        visitorProofDrawer,
        setVisitorProofDrawer,
        updateProofImage,
        editOpen,
        uploadS3,
        isOptionPopoverOpen,
        optionPopoverID,
        deleteVisitor,
        classes,
        isEditable
    } = React.useContext(AddVisitorPassContext)

    const visitor_list_card_ref = useRef(null)
    const [visitor_list_card_width, set_visitor_list_card_width] = useState(null)
    const visitor_list_card_number_ref = useRef(null)
    const [visitor_list_card_number_width, set_visitor_list_card_number_width] = useState(null)
    React.useEffect(() => {
        set_visitor_list_card_width(visitor_list_card_ref?.current?.offsetWidth ?? 0)
        set_visitor_list_card_number_width(visitor_list_card_number_ref?.current?.offsetWidth ?? 0)
    }, [visitor_list_card_ref?.current?.offsetWidth, visitor_list_card_number_ref?.current?.offsetWidth, data]);

    return (

        <>
            <div style={{ padding: "12px 7px 0px 12px" }}>
                <Box className={classes.visiterJourney}>
                    {/* PASS DETAILS */}
                    <div className={classes.innerPadding}>
                        <Stack direction={"row"}>
                            <Avatar variant="rounded" className={classes.avatar}>
                                <PassDetails />
                            </Avatar>
                            <Stack sx={{ marginInlineStart: '12px' }} direction={"column"} spacing={"4px"}>
                                <Typography className={classes.title}>{t("Pass Details")}</Typography>
                                <Typography className={classes.step}>{t("STEP")} 2/2</Typography>
                            </Stack>
                        </Stack>
                    </div>
                    <div className={classes.borederBottom2}></div>
                    {data?.visitor_details?.length > 0 ?
                        <>{data?.visitor_details?.map((_, i) => {
                            return <>
                                {_?.is_deleted ? <></> :
                                    <Box px={"16px"} mb={"20px"}>
                                        <Box my={"8px"}>
                                            <div ref={visitor_list_card_ref} className={classes.visitorList}>
                                                <Stack direction={"row"} alignItems="center">
                                                    <img
                                                        style={{
                                                            height: "40px",
                                                            width: "40px",
                                                            objectFit: "cover",
                                                            borderRadius: "50%",
                                                        }}
                                                        alt=" "
                                                        src={_?.profile_image ? _?.profile_image : "/images/profile_image.png"}
                                                        className={classes.profileicons}
                                                    />
                                                    <Box sx={{ marginInlineStart: "8px" }} flexGrow={1}>
                                                        <Stack direction={"column"}>
                                                            <Typography className={classes.visitorName}>{_?.name}</Typography>
                                                            <Stack direction={"row"} divider={<Box className={classes.dot}></Box>} alignItems="center" spacing={"6px"}>
                                                                {_?.mobile?.mobile && <Typography ref={visitor_list_card_number_ref}
                                                                    className={classes.visitorNumber} noWrap>
                                                                    {_?.mobile?.mobile_code + "-" + _?.mobile?.mobile}
                                                                </Typography>}
                                                                {_?.mail && <Typography className={classes.visitorNumber} noWrap
                                                                    sx={{ maxWidth: visitor_list_card_width - (visitor_list_card_number_width + 120), }}>
                                                                    {_?.mail}
                                                                </Typography>}
                                                            </Stack>
                                                        </Stack>
                                                    </Box>
                                                    <Box onClick={(e) => handleClick(e, _, i)} sx={{ cursor: "pointer" }}>
                                                        <MoreVertIcon />
                                                    </Box>
                                                </Stack>
                                                <Popover
                                                    id={optionPopoverID}
                                                    open={isOptionPopoverOpen}
                                                    anchorEl={anchorEl?.target}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <Box className={classes.popper}>
                                                        <Stack direction={"column"} spacing={"15px"}>
                                                            <Stack direction={"row"} sx={{ cursor: "pointer" }} onClick={() => editOpen(anchorEl?.v)}>
                                                                <Pencil />
                                                                <Typography sx={{ marginInlineStart: "10px" }} className={classes.popperText}>{t("Edit Visitor")}</Typography>
                                                            </Stack>
                                                            <Stack direction={"row"} sx={{ cursor: "pointer" }} onClick={() => deleteVisitor(anchorEl?.i)}>
                                                                <Bin />
                                                                <Typography sx={{ marginInlineStart: "10px" }} className={classes.popperText}>{t("Delete Visitor")}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Box>
                                                </Popover>
                                            </div>
                                        </Box>
                                    </Box>}
                            </>
                        })}
                            <Box px={"16px"} pb={"8px"}>
                                <Button
                                    className={classes.addButton}
                                    variant="outlined"
                                    onClick={() => setAddDetails(true)}
                                    fullWidth>
                                    {t("Add")}
                                </Button>
                            </Box></>
                        :
                        <>
                            <Box mt={4} mb={3} textAlign={"center"}>
                                <img src="/images/visitorDetails.svg" alt="" />
                            </Box>
                            <Box p={"16px"}>
                                <Typography mb={"8px"} textAlign={"center"} className={classes.title}>{t("Add Visitor Details")}</Typography>
                                <Typography mb={"16px"} textAlign={"center"} className={classes.info}>{t("Update simple details about the visitor to complete the process")}</Typography>
                                <Button
                                    className={classes.addButton}
                                    variant="outlined"
                                    onClick={() => {
                                        setAddDetails(true)
                                    }}
                                    fullWidth>
                                     {t("Add")}
                                </Button>
                            </Box>
                        </>
                    }
                </Box>
                {/* ADD VISITOR DETAILS */}
                <DialogDrawer
                    open={addDetails}
                    height={"500px"}
                    onClose={() => {
                        setVisitorData({ ...visitor_initial_state, error: {} })
                        setAddDetails(false)
                    }}
                    maxWidth="sm"
                    header={visitorFormData?.index === null ? t("Add Visitor Details") : t("Edit Visitor Details")}
                    component={
                        <>
                            <>
                                {/* VISITOR PICTURE  */}
                                <div style={{ height: "400px", overflow: "scroll" }}>
                                    <Box textAlign={"center"}>
                                        {visitorFormData?.profile_image ? (
                                            <div className={classes.imgdiv}>
                                                <img
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                        objectFit: "cover",
                                                        borderRadius: "50%",
                                                    }}
                                                    alt=" "
                                                    src={generateImageUrl(visitorFormData?.profile_image, img_size.small_square)}
                                                    className={classes.profileicons}
                                                />

                                                <CloseIcon
                                                    className={classes.close}
                                                    onClick={() => updateVisitorFormState("profile_image", "")}
                                                />
                                            </div>
                                        ) : (
                                            <IconButton className={classes.profileimg}>
                                                <PersonOutlineIcon
                                                    className={classes.profileicon}
                                                />
                                                <label htmlFor="uplodebtn">
                                                    <Avatar
                                                        sx={{ width: 24, height: 24 }}
                                                        className={classes.profileimgs}
                                                        type="file"
                                                    >
                                                        <img
                                                            src="/images/icons8-camera (1).svg"
                                                            alt=" "
                                                            className={classes.profileicons}
                                                        />
                                                    </Avatar>
                                                </label>
                                                <input
                                                    type="file"
                                                    name="img"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={(value) => {
                                                        uploadS3(value.target.files[0]);
                                                    }}
                                                    id="uplodebtn"
                                                />
                                            </IconButton>
                                        )}
                                    </Box>
                                    {/* VISITOR NAME */}
                                    <div className={classes.input}>
                                        <TextBox
                                            isRequired
                                            color
                                            label={t("Visitor Name")}
                                            placeholder={t("Enter Visitor Name")}
                                            onChange={(value) => updateVisitorFormState("name", value.target.value)}
                                            value={visitorFormData?.name}
                                            isError={visitorFormData?.error?.name?.length > 0}
                                            errorMessage={visitorFormData?.error?.name}
                                        />
                                    </div>
                                    {/* PHONE NUMBER */}
                                    <div className={classes.input}>
                                        <MobileNumberInputComponent
                                            isRequired
                                            label={t("Phone Number")}
                                            placeholder={t("Phone Number")}
                                            handleChange={(value) => updateVisitorFormState("mobile", value)}
                                            value={visitorFormData?.mobile}
                                            isError={visitorFormData?.error?.mobile?.length > 0}
                                            errorMessage={visitorFormData?.error?.mobile}
                                        />
                                    </div>
                                    {/* MAIL ID */}
                                    <div className={classes.input}>
                                        <TextBox
                                            color
                                            label={t("Mail ID")}
                                            placeholder={t("Enter Mail ID")}
                                            value={visitorFormData?.mail}
                                            onChange={(value) => updateVisitorFormState("mail", value.target.value)}
                                            isError={visitorFormData?.error?.mail?.length > 0}
                                            errorMessage={visitorFormData?.error?.mail}
                                        />
                                    </div>
                                    {/* VISITOR PROOF */}
                                    <div className={classes.input} >
                                        <SelectBox
                                            label={t("Select Visitor Proof")}
                                            value={visitorFormData?.proof_type}
                                            placeholder={t("Select Visitor Proof")}
                                            onChange={(value) => updateVisitorFormState("proof_type", value)}
                                            options={proofData}
                                            isError={visitorFormData?.error?.proof_type?.length > 0}
                                            errorMessage={visitorFormData?.error?.proof_type}
                                        />
                                        {/* </div> */}
                                    </div>
                                    <div className={classes.input}>
                                        <TextBox
                                            color
                                            label={t("ID Number")}
                                            placeholder={t("EnterIDNumber")}
                                            onChange={(value) => updateVisitorFormState("proof_number", value.target.value)}
                                            value={visitorFormData?.proof_number}
                                            isError={visitorFormData?.error?.proof_number?.length > 0}
                                            errorMessage={visitorFormData?.error?.proof_number}
                                        />
                                    </div>
                                    {/* ID PICTURE */}
                                    <div className={classes.input}>
                                        <Typography pb={"8px"} className={classes.subTitle}>
                                            {t("Visitor Proof ID Picture")}
                                        </Typography>
                                        <BulkImageUpload
                                            bulkUrl={visitorFormData?.proof_images}
                                            setBulkUrl={updateProofImage}
                                        />
                                    </div>
                                </div>
                            </>
                            {/* BUTTON */}
                            <Box sx={{ padding: "12px", backgroundColor: "#ffffff" }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Button
                                            fullWidth
                                            className={classes.addButton2}
                                            onClick={submitVisitorForm}>
                                            {isEditable === false ? t("Add") : t("Edit")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    }
                />
                <DialogDrawer
                    open={visitorProofDrawer}
                    onClose={() => setVisitorProofDrawer(false)}
                    maxWidth="sm"
                    header="ID Proof"
                    component={
                        <>
                            <Box className={classes.box}
                            >
                                {proofData?.map((data) => (
                                    <Stack direction={"row"} alignItems="center" justifyContent="space-between">
                                        <Typography>{data?.label}</Typography>
                                        <Radio
                                            onChange={(value) => {
                                                updateVisitorFormState("proof_number", value.target.value)
                                                setVisitorProofDrawer(false)
                                            }
                                            }
                                            sx={{ color: "#CED3DD" }} />
                                    </Stack>
                                ))}
                            </Box>                        </>
                    }
                />
            </div>
        </>
    )
}


export default Step2;