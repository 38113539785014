import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { FontFamilySwitch } from "../../utils";

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        borderRadius: "4px",
        padding: "12px",
        backgroundColor: "#FFFFFF",
        maxWidth: 'none',
        color: "#4E5A6B",
        border: "1px solid #E4E8EE",
        boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.04)",
        fontSize: "14px",
        fontFamily: FontFamilySwitch()?.semiBold,
    },
}));