import * as React from "react"

const RegisteredWorker = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...props}>
    <g data-name="Group 108759" transform="translate(4341.75 10930)">
      <rect
        data-name="Rectangle 55778"
        width={68}
        height={68}
        rx={8}
        transform="translate(-4341.75 -10930)"
        fill="#edf7f8"
      />
      <g fillRule="evenodd">
        <path
          data-name="Path 100548"
          d="M-4319.496-10874a4.223 4.223 0 0 1-4.254-4.191v-35.618a4.223 4.223 0 0 1 4.254-4.191h23.393a4.22 4.22 0 0 1 4.253 4.191v35.618a4.22 4.22 0 0 1-4.254 4.191Z"
          fill="#6dafb3"
        />
        <path
          data-name="Path 100549"
          d="M-4316.75-10890.8h17.9v2h-17.9Zm0 4h17.9v2h-17.9Zm0 4h11.933v2h-11.933Z"
          fill="#eceff1"
        />
        <path
          data-name="Path 100550"
          d="M-4316.75-10911.04h17.9v15h-17.9Z"
          fill="#edf7f8"
        />
        <path
          data-name="Path 100551"
          d="M-4307.8-10897.466c-1.676 0-1.75-2.465-1.75-2.465v-2.1h3.5v2.1s-.073 2.465-1.75 2.465Z"
          fill="#f90"
        />
        <path
          data-name="Path 100552"
          d="M-4304.65-10905.252a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.7Zm-6.3 0a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.7Z"
          fill="#ffa800"
        />
        <path
          data-name="Path 100553"
          d="M-4304.65-10906.816c0-2.672-6.3-1.738-6.3 0v2.452a3.15 3.15 0 0 0 6.3 0v-2.452Z"
          fill="#ffb840"
        />
        <path
          data-name="Path 100554"
          d="M-4307.8-10910.194a3.565 3.565 0 0 0-3.5 3.852v.8l.7.6v-1.752l4.2-1.4 1.4 1.4v1.75l.7-.609v-.789c0-1.41-.363-2.805-2.1-3.152l-.351-.7Z"
          fill="#424242"
        />
        <path
          data-name="Path 100555"
          d="M-4306.4-10904.889a.352.352 0 1 0 .352.352.352.352 0 0 0-.352-.352Zm-2.8 0a.352.352 0 1 0 .35.352.351.351 0 0 0-.35-.352Z"
          fill="#7a4712"
        />
        <path
          data-name="Path 100556"
          d="M-4306.047-10899.695a2.54 2.54 0 0 1-3.5 0s-3.853.696-3.853 4.551h11.2c0-3.84-3.847-4.551-3.847-4.551Z"
          fill="#c33400"
        />
      </g>
    </g>
  </svg>
)

export default RegisteredWorker;
