import { FontFamilySwitch } from "../../utils";
import makeStyles from "@mui/styles/makeStyles";

 export const useStyles = makeStyles((theme) => ({
    dropdown: {
        display: "flex",
        width: "165px",
        height: "220px",
        justifyContent: "space-around",
    },
    selectedtext: {
        cursor: "pointer",
        color: "#5078E1",
        padding: "3px 0",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem",
        textAlign: "center"
    },
    unselectedtext: {
        cursor: "pointer",
        padding: "4px 0",
        color: "",
        fontFamily:  FontFamilySwitch().semiBold,
        fontSize: "0.875rem",
        textAlign: "center"
    }
  }));