export const DetailsIcon = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
  <g id="Group_116629" data-name="Group 116629" transform="translate(-32 -78)">
    <rect id="Rectangle_57270" data-name="Rectangle 57270" width="48" height="48" rx="8" transform="translate(32 78)" fill="#f1f7ff"/>
    <path id="icons8-property_2_" data-name="icons8-property (2)" d="M8.474,6A3.479,3.479,0,0,0,5.019,9.12h0A3.439,3.439,0,0,0,5,9.474V24.947a3.809,3.809,0,0,0,3.789,3.789H25.211A3.809,3.809,0,0,0,29,24.947V9.474A3.479,3.479,0,0,0,25.526,6Zm-.947,6.316H26.474V24.947a1.245,1.245,0,0,1-1.263,1.263H8.789a1.245,1.245,0,0,1-1.263-1.263Zm2.842,2.526a.947.947,0,1,0,0,1.895h1.263a.947.947,0,1,0,0-1.895Zm5.053,0a.947.947,0,1,0,0,1.895h8.211a.947.947,0,1,0,0-1.895Zm-5.053,3.789a.947.947,0,1,0,0,1.895h1.263a.947.947,0,1,0,0-1.895Zm5.053,0a.947.947,0,1,0,0,1.895h8.211a.947.947,0,1,0,0-1.895Zm-5.053,3.789a.947.947,0,1,0,0,1.895h1.263a.947.947,0,1,0,0-1.895Zm5.053,0a.947.947,0,1,0,0,1.895h8.211a.947.947,0,1,0,0-1.895Z" transform="translate(39 84.632)" fill="#78b1fe"/>
  </g>
</svg>

    )
}