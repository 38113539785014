import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
    root: { height: (props) => props?.size?.height - 135, overflow: "scroll" },
    visiterJourney: {
        borderRadius: "4px",
        boxShadow: "0px 6px 30px #0eeeee11",
        backgroundColor: "#fff",
    },
    step_root: {
        padding: "12px 7px 0px 12px"
    },
    innerPadding: {
        padding: "16px",
    },
    avatar: {
        backgroundColor: "#F1F7FF",
        height: "48px",
        width: "48px"
    },
    title: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29"
    },
    step: {
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#98A0AC"
    },
    borederBottom: {
        borderBottom: "1px solid #E4E8EE",
        marginTop: "12px",
        marginBottom: "12px"
    },
    input: {
        marginTop: "10px",
        marginBottom: "16px"
    },
    unitAvatar: {
        backgroundColor: "#ebdfff",
        height: "40px",
        width: "40px"
    },
    unitBox: {
        backgroundColor: "#F6F1FE",
        borderRadius: "6px",
        padding: "12px"
    },
    unitName: {
        fontFamily: FontFamilySwitch().regular,
        color: "#98A0AC",
        fontSize: "0.75rem"
    },
    unitNumber: {
        fontSize: "0.875rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().bold,
    },
    change: {
        color: "#5078E1",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer"
    },
    box: {
        border: "1px solid #CED3DD",
        borderRadius: "12px",
        padding: "4px 16px 0px 16px"
    },
    label: {
        fontSize: "0.875rem",
        color: "#071741",
        fontFamily: FontFamilySwitch().bold,
    },
    info: {
        color: "#4E5A6B",
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().regular
    },
    stayContainer: {
        border: "1px solid #E4E8EE",
        borderRadius: "6px",
        // padding: "6px 12px 12px 12px",
    },
    btnroot: {
        zIndex: 1000,
        backgroundColor: "#ffffff",
        boxShadow: "0px -1px 6px #00000021",
        padding: "16px"
    },
    primary: {
        padding: "12px 16px",
        textAlign: "center",
        borderRadius: "8px",
        fontSize: "0.875rem",
        boxShadow: "none",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer",
        backgroundColor: "#5078E1",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#5078E1",
            opacity: 0.9,
            boxShadow: "none",
        },
    },
    secondary: {
        padding: "12px 16px",
        textAlign: "center",
        borderRadius: "8px",
        fontSize: "0.875rem",
        boxShadow: "none",
        color: "#071741",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: "#F6F6F6",
            opacity: 0.9,
            boxShadow: "none",
        },
    },
    addButton: {
        padding: "15px 142px",
        backgroundColor: "#F1F7FF",
        borderRadius: "8px"
    },
    borederBottom2: {
        borderBottom: "1px solid #E4E8EE",
        marginBottom: "12px"
    },
    workerBox: {
        border: "1px solid #CED3DD",
        borderRadius: "12px",
        padding: "16px",
        marginBottom: "16px"
    },
    border: {
        borderTop: "12px",
        border: "1px solid #CED3DD"
      },
    regAvatar: {
        backgroundColor: "#fff4eb",
        padding: "16px",
        borderRadius: "6px",
        height: "68px",
        width: "68px"
    },
    regAvatar2: {
        backgroundColor: "#edf7f8",
        padding: "1px",
        borderRadius: "6px",
        height: "68px",
        width: "68px"
    },
    regHeader: {
        color: "#091B29",
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().extraBold
    },
    regdescription: {
        color: "#4E5A6B",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold
    },
    profileimg: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "relative",
    },
    profileicon: {
        fontSize: "80px",
        padding: "14px",
    },
    profileimgs: {
        backgroundColor: theme.palette.background.tertiary,
        color: "#E4E8EE",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        border: "2px solid white",
        borderRadius: "50%",
    },
    subTitle: {
        fontSize: "0.875rem",
        color: "#404E61",
        fontFamily: FontFamilySwitch().bold,
    },
    questions: {
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "0.875rem"
    },
    endAdornment: {
        display: "flex",
        borderLeft: "1px solid #E4E8EE",
        width: "55px",
        height: "42.13px",
        alignItems: "center",
        cursor: "pointer",
    },
    time: {
        padding: "16px 2px 12px 9px",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
    },
    timeVariant: {
        padding: "6px 12px",
        borderBottom: "1px solid #E4E8EE",
        cursor: "pointer",
        fontSize: "1.3rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
    },
    groupWorkers: {
        border: "1px solid #CED3DD",
        borderRadius: "8px",
        padding: "15px 12px"
    },
    dot: {
        backgroundColor: "#CED3DD",
        borderRadius: "50%",
        width: "6px",
        height: "6px"
    },
    trash: {
        border: "1px solid #E4E8EE",
        borderRadius: "50%",
        height: "26px",
        width: "26px",
        textAlign: "center"
    },
    workerName: {
        fontSize: "0.875rem",
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold
    },
    desc: {
        color: "#4E5A6B",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular
    },
    line: {
        backgroundColor: "#CED3DD",
        width: "1px",
        height: "18px"
    },
    error: {
        color: "#FF4B4B",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold
    },
    gateButtonActive: {
      padding: "15px 12px",
      backgroundColor: "#F1F7FF",
      border: "1px solid #5078E1",
      color: "#5078E1",
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().semiBold,
      borderRadius: "8px",
      cursor: "pointer"
    },
    gateButtonInactive: {
      padding: "15px 12px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #CED3DD",
      color: "#4E5A6B",
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().semiBold,
      borderRadius: "8px",
      cursor: "pointer"
    },
    addButton2: {
      padding: "12px 145px",
      backgroundColor: "#5078E1",
      borderRadius: "8px",
      color: "#ffffff",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "#5078E1",
        opacity: 0.8
      },
      "&:disabled": {
        backgroundColor: "#E4E8EE",
      },
    },
    viewDetails: {
        color: "#5078E1",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().regular
    },
    name: {
        color:  theme.typography.color.primary,
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().semiBold
    },
    description: {
        color: "#98A0AC",
        fontSize: "0.75rem",
        fontFamily: FontFamilySwitch().semiBold
    },
    information: {
        color: theme.typography.color.primary,
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().semiBold,
        direction: "ltr",
        textAlign: "match-parent"
    }
}))