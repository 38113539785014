/* eslint-disable array-callback-return */
import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { Filter, LoderSimmer, PassCard } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  NetWorkCallMethods,
  enumSelect,
  enumCatagory,
  keyMapSource,
  FontFamilySwitch,
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { withBottombar } from "../../HOCs";
// import {withBottombar} from "../../HOCs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
    overflow: "hidden",
  },
  menuText: {
    fontSize: "1rem",
    color: theme.typography.color.secondary,
    marginTop: "30%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "0.875rem",
    },
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },
    position: "absolute",
    bottom: "35px",
    right: "35px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "'4px'",
      padding: "0px",
    },
  },
  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const WorkerGatePass = ({ t = () => false, type, title, handleTopBarState }) => {
  const size = useWindowDimensions();
  const classes = useStyles(); 
  const history = useHistory();
  const [filter, setFilter] = React.useState(false);
  const [gatelist, setGateList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const alert = React.useContext(AlertContext);
  const [unitsfil, setUnitsfil] = React.useState([]);
  const [statusType, setStatusType] = React.useState([]);

  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const filterData = [
    // {
    //   id: "1",
    //   title: "Type",
    //   key: "type",
    //   showSearch: true,
    //   filterType: "CHECKBOX",
    //   values: [
    //     {
    //       label: "Worker Gate Pass",
    //       value: securityType?.type?.type2,
    //     },
    //     {
    //       label: "Visitor Gate Pass",
    //       value: securityType?.type?.type1,
    //     },
    //   ],
    // },
    {
      id: "2",
      title: t("Status"),
      key: "status",

      filterType: "CHECKBOX",
      values: statusType ?? [],
    },
    {
      id: "3",
      title: t("Unit"),
      key: "unit",
      filterType: "CHECKBOX",
      values: unitsfil,
    },
    // {
    //   id: "4",
    //   title: "Date",
    //   key: "date",
    //   filterType: "DATE",
    //   values: [],
    // },
  ];
  const getPassList = (unit, status, date, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: [type],
      status: status,
      offset: offset,
      limit: 20,
      agreement_unit_id: unit,
      start_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.startDate).toISOString()
          : undefined,
      end_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.endDate).toISOString()
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setGateList(response?.data?.data);
        } else {
          setGateList(gatelist?.concat(response?.data?.data));
        }
        setFilter(false);
        setLoading(false);
        setTotal(response?.data?.totalRecords);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getagreementUnitid = () => {
    NetworkCall(
      `${config.api_url}/community/community_units_filter`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        getEnum(response?.data?.data?.agreement_units);
        const allunits = response?.data?.data?.agreement_units?.map(
          (val, index) => {
            let _d;
            try {
              _d = {
                label: val?.unit?.name,
                value: String(val?.id),
              };
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            }

            return _d;
          }
        );
        setUnitsfil(allunits.flat());
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([
      enumCatagory?.security_status_enum_type,
    ]);
    const statusType = keyMapSource({
      enumType: stayType?.security_status_enum_type,
    });

    // let requestSourceMap = stayType?.security_request_type.flatMap((item, index) => {
    //   return {
    //     [`type${index + 1}`]: item?.value
    //   }
    // })

    // let requestType = Object.keys(requestSourceMap).map(key => requestSourceMap[key]).reduce((old, item) => (
    //   { ...old, ...item }
    // ), {})

    setStatusType(
      stayType?.security_status_enum_type?.filter(
        (x) => ![statusType?.type4, statusType?.type5].includes(x?.value)
      )
    );

    // setSecurityType({ ...securityType, type: requestType })

    getPassList(
      data?.map((x) => x?.id),
      stayType?.security_status_enum_type
        ?.filter((x) => ![statusType?.type4, statusType?.type5].includes(x?.value))
        ?.map((val) => val?.value)
    );

    setSelectedFilter({
      unit: data?.map((x) => x?.id),
      status: stayType?.security_status_enum_type
        ?.filter((x) => ![statusType?.type4, statusType?.type5].includes(x?.value))
        ?.map((val) => val?.value),
    });
  };
  React.useEffect(() => {
    handleTopBarState({
      text: t(title)
    })
    getagreementUnitid();
    // eslint-disable-next-line
  }, []);

  const applyFilter = (data) => {
    if (data?.type?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonetype"),
      });
      return false;
    }
    if (data?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonestatus"),
      });
      return false;
    }
    if (data?.unit?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    if (dateFilter?.startDate > dateFilter?.endDate) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Enddatemustbegreaterthanstartdate"),
      });
      return false;
    }
    setOffset(0);
    setSelectedFilter(data);
    getPassList(data?.unit, data?.status, dateFilter, filter, 0);
  };
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(
      selectedFilter?.unit,
      selectedFilter?.status,
      "",
      false,
      offset + 20
    );
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* <TitleBar text={t(title)} goBack={goBack} /> */}

      <Grid container height={size?.height - 55} style={{overflow:"overlay"}}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" px={2} pt={1}>
            <Box flexGrow={1}>
              <Typography className={classes.total}>
                {t(title)} ({total})
              </Typography>
            </Box>
            <Box marginLeft="10px">
              <IconButton
                className={classes.filter}
                onClick={() => setFilter(true)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <div style={{ marginRight: "-6px", paddingBottom: "15%" }}>
            {loading ? (
              <LoderSimmer count={10} />
            ) : (
              <>
                {gatelist?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={gatelist?.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 107}
                  >
                    <Box padding={"16px 16px 16px 13px"}>
                      {gatelist?.map((item) => {
                        return (
                          <>
                            <Box marginBottom={"12px"}>
                              <PassCard t = { t } data={item} />
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </InfiniteScroll>
                ) : (
                  <Typography className={classes.menuText} textAlign="center">
                    {t("Norecordsfound")}
                  </Typography>
                )}
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <Box sx={{ display: "flex", float: "right" }}>
            <Box>
              <Fab
                color="primary"
                className={classes.fab}
                aria-label="add"
                onClick={() =>
                  history.push(
                    type === "WGR" ? Routes.addWorkerPass : Routes.addVisitorPass
                  )
                }
              >
                <AddIcon />
              </Fab>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter?.type,
              status: selectedFilter?.status,
              unit: selectedFilter?.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            disable={true}
            minDate={new Date()}
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter?.type,
              status: selectedFilter?.status,
              unit: selectedFilter?.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("workerGatepass")(WorkerGatePass), props);
