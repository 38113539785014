import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
    box: {
      borderRadius: "14px"
    },
    icon: {
    // backgroundColor: "#FEEAEA",
    borderRadius: "100%",
    padding: "8px 10px",
    marginInlineEnd: "10px",
    },
    count: {
      fontSize: "0.875rem",
      color: theme.typography.color.primary,
      fontFamily: FontFamilySwitch().semiBold,
    },
    title: {
      color: theme.typography.color.secondary,
      fontSize: "0.875rem",
      fontFamily: FontFamilySwitch().bold
    }
}));
