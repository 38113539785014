import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import ThumbsDown from "../../assets/thumbsDown";
import ThumbsUpIcon from "../../assets/thumbsUpIcon";
import { AccordianCard } from "../../components/accordianCard";
import { useStyles } from "./style";
import { withNamespaces } from "react-i18next";

const GetSupportDetailed = (props) => {

    const { handleTopBarState, t = () => false } = props
    const classes = useStyles();

    React.useEffect(() => {
        handleTopBarState({
            text: "Get Support Details"
        })
        // eslint-disable-next-line
    }, []);


    return (
        <Container
            sx={classes.container}
            maxWidth="sm"
        >
            {/* topNavbar */}
            {/* <TitleBar text="Get Support Details" goBack={() => history.goBack(-1)} /> */}

            {/* GetSupportDetailed */}
            <Box className={classes.root}>

                <Box >
                    {/* AccordianCard */}
                    <AccordianCard
                        detailed={true}
                        title={t("contentTitle")}
                        bottomText={t("contentDescription")}
                        details={t("Price Book is collection of following pricing information used in Property Management Solution. Pricing Component - An important element in defining the pricing table. There are 5 different type of components, namely Primary, Secondary, One Time, Refundables and Item Based. Long Term Pricing Table - maintains Price configuration based on a Montly Rate. During the quote process, the system auto-calculates prices based on the Leasing Period in Quarterly, Monthly or Yearly. Short Term Rental Pricing Table - maintains Price configuration based on a daily Rates. During the quote process, the system auto-calculates prices based on short term rental period usually in days or weeks. Promotional or Override Pricing Table RERA Pricing Table Price Appraisals Delivery Milestones Milestone Templates Vacant Period Viewer")}
                    />
                </Box>
                <Box>
                    {/* Thumbs up and Down Text */}
                    <Typography className={classes?.likeText}>
                        {t("Did this answer your question?")}
                    </Typography>
                    {/* Thumbs up and Down Icons */}
                    <Box className={classes?.likeIconsBox}>
                        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                            <Stack sx={{ marginInlineEnd: "16px"}} className={classes?.likeIcon}>
                                <ThumbsUpIcon />
                            </Stack>
                            <Stack className={classes?.likeIcon} >
                                <ThumbsDown />
                            </Stack>

                        </Stack>
                    </Box>
                </Box>



            </Box>


        </Container>

    );
};
export default withNamespaces("getSupport")(GetSupportDetailed);
