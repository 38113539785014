import { Avatar, Box, Button, Stack, Typography, Container } from "@mui/material"
import { withNamespaces } from "react-i18next"
import { useStyles } from "./styles";
import { TitleBar } from "../../components"
import useWindowDimensions from "../../utils/useWindowDimensions";

const RingingScreen = ({ t = () => false, details = {}, goBack = () => false, cancelCall = () => false, live_user = false,setIsVideo=()=>false,handleAction=()=>false }) => {

    const size = useWindowDimensions()
    const classes = useStyles({ height: "100%" });



    return (
        <Container maxWidth="sm" style={{ padding: "0px" }}>
            <Box class={classes.root} >
                <TitleBar goBack={goBack} transparent color="transparent" text={t("Video call request")} />


                <Box display={"flex"} alignItems="center" justifyContent="center" height={size?.height - 162}>
                    <Box>
                        <Stack alignItems="center" justifyContent="center" direction="row" spacing={1}>
                            <img alt="" className={classes.logo} src="/images/icons/36x36.png" />
                            <Typography className={classes.subtitle1}>{t("Requesting Video call")}</Typography>

                        </Stack>
                        <center>
                            <Avatar
                                className={classes.avatar}
                                src={details?.vendor_image ?? null}
                            />
                            <Typography className={classes.personName}>{details?.person_name}</Typography>
                            <br />
                            <Typography className={classes.subtitle1} sx={{ marginBottom: "10px" }}>Reason: {details?.reason}</Typography>
                        </center>

                    </Box>
                </Box>

                {live_user ?
                    <center>
                        <Typography className={classes.alreadyText}>User Already In Call</Typography>
                    </center>
                    :

                    <Box className={classes.btnRoot}>

                        <Button onClick={() => handleAction("Rejected")} style={{ backgroundColor: "#FF4B4B", "&:hover": { backgroundColor: "#FF4B4B" } }} className={classes.allowBtn}>Do not allow</Button>
                        <Button onClick={() => handleAction("Video")} style={{ backgroundColor: "#78B1FE", "&:hover": { backgroundColor: "#78B1FE" } }} className={classes.allowBtn}>Video</Button>
                        <Button onClick={() => handleAction("Approved")} style={{ backgroundColor: "#5AC782", "&:hover": { backgroundColor: "#5AC782" } }} className={classes.allowBtn}>Allow</Button>

                    </Box>
                }


            </Box>
        </Container>

    )
}
export default withNamespaces("walkInRequest")(RingingScreen)