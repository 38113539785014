export const RightChevronIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={4.805}
        height={9.11}
        {...props}
    >
        <path
            fill="#fff"
            d="M.176 8.908a.686.686 0 0 1 0-.916l3.137-3.437L.176 1.119A.674.674 0 0 1 0 .661.674.674 0 0 1 .176.2a.6.6 0 0 1 .9 0l3.555 3.9a.689.689 0 0 1 0 .917L1.074 8.908a.6.6 0 0 1-.9 0Z"
        />
    </svg>
)
