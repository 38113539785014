import React from 'react'
import { PopularServiceCardStyle } from './style'
import { Box, Stack, Typography } from '@mui/material'

export const PopularServiceCard = ({ data = "" }) => {
    const classes = PopularServiceCardStyle(data)
    return (
        <Box className={classes.box}>
            <Stack alignItems={"center"} className={classes.textBox}>
                <Typography className={classes.text}>{data?.title1}</Typography>
                <Typography className={classes.text}>{data?.title2}</Typography>
            </Stack>
        </Box>
    )
}