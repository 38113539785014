import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
import { FontFamilySwitch, generateImageUrl, img_size } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: "8px 8px 0px 0px",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    padding: "13px 14px 12px 14px"
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "2px 8px",
    // borderRadius: "4px",
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "70px",
    width: "70px"
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "70px",
    width: "70px",
    objectFit: "cover",
  },
  bold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem"
    },
  },
  semiBold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,

  },
  // bottomText: {
  //   bottom: 0,
  //   borderRadius: "4px",
  //   backgroundColor: "#071741",
  //   color: "white",
  //   width: "110px",
  //   padding: "4px",
  //   textAlign: "center"
  // },
  bottomText1: {
    position: "absolute",
    bottom: "0",
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px",
    borderRadius: "0px 0px 8px 8px"
  },
  bottomCard: {
    backgroundColor: "#fff",
    borderRadius: "0px 0px 8px 8px",
    padding: "10px 14px",
    margin: "auto 4px",
    borderTop: "1px solid #E4E8EE"
  }
}));

export const ParkingCard = (props) => {
  const { t = () => false } = props
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();

  return (
    <Box sx={{ position: "relative" }}>
      <Stack direction={"row"} className={classes.card}
        onClick={() => {
          if (props?.walkinout) {
            history.push(Routes.walkinDetails + "?passId=" + props?.data?.id)
          } else {
            history.push(Routes.parkingDetails + "?passId=" + props?.data?.id)
          }
        }}
      >
        <Box style={{ position: "relative" }}>
          {props?.data?.vehicle_image ?
            <img src={generateImageUrl(props?.data?.vehicle_image, img_size.small_square)} alt="" className={classes.avatar} /> :
            <Avatar className={classes.avatar} src={
              props?.parking
                ? "/images/parkingslot.svg"
                : "/images/workergate.svg"
            } variant="square" />
          }
          <Box style={{ position: "absolute" }} className={classes.bottomText1}>
            {props?.data?.status === "Pending" && (
              <Typography
                variant="subtitle2"
                noWrap
                className={classes.progress}
              >
                {t("In Progress")}
              </Typography>
            )}
            {props?.data?.status === "Approved" && (
              <Typography
                variant="subtitle2"
                className={classes.approved}
              >
                {t("Approved")}
              </Typography>
            )}
            {props?.data?.status === "Rejected" && (
              <Typography
                variant="subtitle2"
                className={classes.rejected}
              >
                {t("Rejected")}
              </Typography>
            )}
            {props?.data?.status === "Cancelled" && (
              <Typography variant="subtitle2" className={classes.delete}>
                {t("Cancelled")}
              </Typography>
            )}
          </Box>
        </Box>
        <Stack sx={{ marginInlineStart: "16px" }} direction={"column"} spacing={"4px"}>
          <Typography noWrap className={classes.title} sx={{ maxWidth: size?.width - 145 }}>
            { props?.walkinout ? props?.data?.person_name : props?.data?.parking_slot ?? t("Parking Slot Requirement")}
          </Typography>
          <Stack direction={"row"} divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" flexItem />}>
            <Typography
              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.walkinout ? props?.data?.property_name ?? "" : props?.data?.vechicle_type}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.walkinout ? props?.data?.unit_no ?? "" : props?.data?.vechicle_number}
            </Typography>
          </Stack>
          <Stack direction={"row"} divider={<Divider sx={{ marginInline: "8px" }} orientation="vertical" flexItem />}>
            <Typography
              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.walkinout ? props?.data?.reason ?? "" : props?.data?.property_name}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack justifyContent={"space-around"} className={classes.bottomCard} direction="row" alignItems={"center"}
        divider={<Divider orientation="vertical" flexItem />} pt={"3px"}>
        <Stack direction="row">
          <DateIcon />
          <Typography
            sx={{ marginInlineStart: "8px" }}
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            &#x202a;{moment(props?.walkinout ? props?.data?.check_in_time : props?.data?.request_from)
              .tz(moment.tz.guess())
              .format("DD MMM YY")}&#x202c;
            &nbsp;-&nbsp;
            &#x202a;{
            props?.walkinout ? 
            props?.data?.check_out_time ? 
            moment(props?.data?.check_out_time)
              .tz(moment.tz.guess())
              .format("DD MMM YY ") : ""
              :
            moment(props?.data?.request_to)
              .tz(moment.tz.guess())
              .format("DD MMM YY ")}&#x202c;
          </Typography>
        </Stack>
        <Box display={"flex"} alignItems="center">
          <img src="/images/unitss.svg" alt="units" />
          <Typography
            sx={{ marginInlineStart: "8px" }}
            variant="subtitle2"
            className={classes.bold}
            noWrap
          >
            {props?.walkinout ? props?.data?.unit_no ?? "" : props?.data?.unit?.[0]?.name}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
