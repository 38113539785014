/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogDrawer } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { PassContext } from "./passContext";
import Step1 from "./step1";
import Step2 from "./step2";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
import Close from "@mui/icons-material/Close";
import { withBottombar } from "../../HOCs";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F6F6F6",
    padding: "0px",
  },

  heading: {
    fontSize: "0.75rem",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  name: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },

  input: {
    marginTop: "10px",
  },

  btnroot: {
    zIndex: 1000,
    backgroundColor: "#ffffff",
    padding: "14px 0",
    boxShadow: "0px -1px 6px #00000021",
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  secondary: {
    padding: "11px 15px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#071741",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#FFF",
    border: "1px solid #CED3DD",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFF",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  alertTitle: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().extraBold,
  },
  alertText: {
    fontSize: "0.875rem",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().semiBold,
  }
}));

const AddPass = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const history = useHistory();
  const classes = useStyles();
  const { data, next, CreateVisitor, openConfirmationDrawer, setOpenConfirmationDrawer } = React.useContext(PassContext);
  let boxParent = document.querySelector("#createDiv")?.offsetHeight;
  let fullSize = size?.height - 220;

  React.useEffect(() => {
    handleTopBarState({
      text: "Add Visitor Pass"
    })
    // eslint-disable-next-line
  }, []);


  return (
    <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
      {/* <TitleBar
        text={t("Add Visitor Pass")}
        goBack={() => history.goBack(-1)}
      /> */}
      <Grid container spacing={0} style={{ height: size?.height - 132, overflow: "auto" }}>
        <Grid item xs={12}>
          <div id="createDiv">
            <Box height={"7px"} />
            <Step1 next={next}
              menuPlacement={boxParent + 10 > fullSize ? "top" : "bottom"}
            />
            <Step2
              menuPlacement={boxParent + 10 > fullSize ? "top" : "bottom"}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.btnroot}
        justifyContent="space-around"
        spacing={0}
      >
        <Grid item xs={5}>
          <Button
            fullWidth
            className={classes.secondary}
            variant="contained"
            onClick={data?.request_details?.is_updated ? () => setOpenConfirmationDrawer(true): ()=>history.goBack(-1)}
          >
            {t("Cancel")}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            fullWidth
            disabled={(data?.visitor_details?.filter(({ view_type, is_deleted }) => view_type === "view" && is_deleted === false).length === 0 || data?.request_details?.view_type === "edit")}
            className={classes.primary}
            variant="contained"
            onClick={() => CreateVisitor()}
          >
            {t("Submit Now")}
          </Button>
        </Grid>
      </Grid>
      <DialogDrawer
        // height={"250px"}
        isnotTitle
        open={openConfirmationDrawer}
        padding={"0px"}
        component={<>
          <Box display={"flex"} m={1}>
            <Box flexGrow={1} />
            <Close onClick={() => setOpenConfirmationDrawer(false)} sx={{ color: "#7C8594" }} />
          </Box>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"} px={2}>
            <img src={"/images/info.svg"} alt="" />
            <Box height={"8px"} />
            <Typography className={classes.alertTitle}>
              {t("Are you sure you want to cancel?")}
            </Typography>
            <Typography className={classes.alertText}>
              {t("Any changes made will not be saved.")}
            </Typography>
          </Box>
          <Box height={"28px"} />
          <Grid
            container
            // className={classes.btnroot}
            justifyContent="space-around"
            spacing={0}
          >
            <Grid item xs={5}>
              <Button
                fullWidth
                className={classes.secondary}
                variant="contained"
                onClick={() => setOpenConfirmationDrawer(false)}
              >
                {t("No, Back")}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                fullWidth
                className={classes.primary}
                variant="contained"
                onClick={() => history.goBack(-1)}
              >
                {t("Yes, Cancel")}
              </Button>
            </Grid>
          </Grid>
          <Box height={"16px"} />
        </>}
        onClose={() => setOpenConfirmationDrawer(false)}
      />
    </Container>
  );
};

const props = {
  top_bar: "title_bar",
}

export default withBottombar(withNamespaces("createPass")(AddPass), props);
