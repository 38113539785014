import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#f3f5fb",
  },
  scroll: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  heading: {
    fontFamily: FontFamilySwitch().bold,
    fontSize: "1rem",
    color: theme.typography.color.primary,
  },
  listRoot: {
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  filter: {
    backgroundColor: "white",
    padding: "12px",
    borderRadius: "8px",
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  drawer: {
    "& .MuiPaper-root-MuiDrawer-paper": {
      // "background-color": "transparent",
      // "box-shadow": "none"
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    [theme.breakpoints.up("sm")]: {
      minHeight: "68vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "75vh",
    },
  },
  noDataFound: {
    padding: "12px",
    backgroundColor: "#fff",
    borderRadius: "8px",
  },
  filterBlock: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "4px 9px",
    boxShadow: "#4354721F 0px 3px 30px",
    border: "1px solid #4354721F",
    cursor: "pointer",
  },
}));
