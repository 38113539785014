import { Stack, Typography, Box } from "@mui/material";
import { useAddOnStyles } from "./style";

export const UnitCard = (props) => {
    const { t=()=>false } = props

    const classes = useAddOnStyles()
    return (
        <>
            {
                props.state?.map(e => {
                    return (
                        <Box m={2} className={classes.firstBox}>
                            <Stack p={1} direction="row" alignItems={"center"} justifyContent={"space-between"}>
                                <Box className={classes.imgDiv}>
                                    <Box className={classes.svgBox}>{e?.img}</Box>
                                    <Box>
                                        <Typography className={classes.unitName}>{e?.unit}</Typography>
                                        <Typography className={classes.propertyName}>{e?.location}</Typography>
                                    </Box>
                                </Box>
                                <Box onClick={() => props?.handleDrawerChange()}>
                                    <Typography
                                        className={classes.changeTxt}>{t("Change")}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    )
                })
            }
        </>
    )
}