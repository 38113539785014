import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  title: {
    fontSize: "1rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right"
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right"
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px",
  },
  rejected: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
  },
  boxes: {
    flexFlow: "wrap !important",
  },
  avatar: {
    height: "122px",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down(321)]: {
      height: "142px",
    },
  },
  bold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  semiBold: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
  },
  bottomText: {
    bottom: 0,
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center",
  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px",
  },
}));

export const UnitCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Box sx={{ position: "relative" }}>
      <Grid
        container
        className={classes.card}
        onClick={() =>
          history.push(
            Routes.requestDetails +
              "?requestId=" +
              props?.data?.id +
              "&requestNo=" +
              props?.data?.id +
              "&requestType=" +
              props?.data?.request_type +
              "&status=" +
              props?.data?.request_status
          )
        }
      >
        <Grid item xs={4} md={3}>
          <Box style={{ position: "relative" }}>
            <img
              src={
                props?.data?.image_url?.length > 0
                  ? props?.data?.image_url?.substring(
                      props?.data?.image_url?.lastIndexOf(".") + 1
                    ) !== "mp4"
                    ? props?.data?.image_url
                    : "/images/unitplaceholder.svg"
                  : "/images/unitplaceholder.svg"
              }
              alt=""
              className={classes.avatar}
            />
            <Box
              style={{ position: "absolute" }}
              className={classes.bottomText1}
            >
              {props?.data?.request_status === "Closed" && (
                <Typography variant="subtitle2" className={classes.delete}>
                  Closed
                </Typography>
              )}
              {props?.data?.request_status === "Open" && (
                <Typography variant="subtitle2" className={classes.approved}>
                  Open
                </Typography>
              )}
              {props?.data?.request_status === "Cancelled" && (
                <Typography variant="subtitle2" className={classes.progress}>
                  Cancelled
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} md={9} alignItems="center" sx={{ padding: "12px" }}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            {/* <Typography noWrap className={classes.title} >
                            {props?.data?.category}
                        </Typography> */}
            <Typography className={classes.title} noWrap>
              {props?.data?.sub_category}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            marginTop="6px"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={1}
          >
            <Typography noWrap variant="subtitle2" className={classes.sub}>
              {props?.data?.description}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            marginTop="6px"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Typography variant="subtitle2" className={classes.sub} noWrap>
              {props?.data?.property_name}
            </Typography>
            <Typography className={classes.sub} noWrap>
              {props?.data?.units?.unit_no}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            marginTop="6px"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Typography className={classes.sub} noWrap>
              {props?.data?.request_type}
            </Typography>
            <Typography variant="subtitle2" className={classes.sub} noWrap>
              {moment(props?.data?.raised_on)
                .tz(moment.tz.guess())
                .format("DD MMM YY")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
