import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1rem",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
  },
  title2: {
    fontSize: "0.875rem",
    color: "#98A0AC",
    fontFamily: FontFamilySwitch().bold,
  },
  container:{
    padding:"16px",
    cursor:"pointer",
    border: "1px solid #E4E8EE",
    borderRadius: "8px",
    margin :"8px 0"
  },
  ActiveContainer:{
    cursor:"pointer",
    padding:"16px",
    border: "2px solid #5078E1",
    borderRadius: "8px",
    margin :"8px 0"
  }
}));
