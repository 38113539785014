import {
    Box, Container, Grid, TextField, InputAdornment,
    Typography, Stack, Avatar, Divider, IconButton, Hidden, Dialog, Drawer
} from '@mui/material'
import React from 'react'
import { MyBookingStyle } from './style'
import SearchIcon from '@mui/icons-material/Search';
import FilterSvg from '../../assets/filterSvg';
import { AlertProps, generateImageUrl, getTimeWithAmPm, img_size, NetWorkCallMethods } from '../../utils';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useHistory } from 'react-router-dom';
import CircleIcon from "@mui/icons-material/Circle";
import { AlertContext, AuthContext } from '../../contexts';
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import moment from 'moment';
import { Routes } from '../../router/routes';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { UseDebounce } from '../../components';
import { withNamespaces } from 'react-i18next';
import { format } from 'date-fns';
import Filter from '../../components/filter';

const MyBooking = ({ t = () => false }) => {
    const auth = React.useContext(AuthContext)
    const language = localStorage.getItem("i18nextLng")
    const classes = MyBookingStyle({ language: auth?.auth?.auth?.language })
    const [search, setSearch] = React.useState("")
    const [myBookingData, setMyBookingData] = React.useState([])
    const [filter, setFilter] = React.useState(false);
    const [filterData, setFilderData] = React.useState({})
    const history = useHistory()
    const alert = React.useContext(AlertContext);
    const size = useWindowDimensions();
    const debounce = UseDebounce()
    // const state = useLocation()?.state
    const [offset, setOffset] = React.useState(0)

    const back = () => {
        history.push(Routes.dashboard)
    }

    const navigate = (value) => {
        history.push({
            pathname: Routes.myBookingDetails,
            state: {
                // bookingDetail: value,        
                property_facility_id: value?.property_facility_id,
                booking_id: value?.booking_id
            }
        })
    }

    React.useEffect(() => {
        getAmenitiesBooking(0, "", true)
        // eslint-disable-next-line
    }, [])

    const getAmenitiesBooking = (offsetNum, search, searchBool, filter_data = filterData) => {
        const payload =
        {
            property_facility_id: null,
            limit: 10,
            offset: offsetNum,
            search: search ?? "",
            current_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            filters: {
                is_booked: filter_data?.status?.includes("Expired") ? false : true,
                is_expired: filter_data?.status?.includes("Expired") ? true : false
            }
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_my_bookings`,
            NetWorkCallMethods.post,
            payload,
            { "x-build-code": config.app_x_build },
            true,
            false
        )
            .then((response) => {
                if (searchBool) {
                    setMyBookingData(response?.data?.data?.bookings ?? [])
                }
                else {
                    setMyBookingData(myBookingData.concat(response?.data?.data?.bookings))
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };


    const loadmore = () => {
        setOffset(offset + 10);
        let off = offset + 10
        getAmenitiesBooking(off, "", false)
    }

    const handleSearch = (value) => {
        setSearch(value)
        debounce(() => getAmenitiesBooking(0, value, true), 800)

    }

    //   const checkExpiry = (booking_end_time, booked_slots) => {
    //     let temp_end_slot = booked_slots?.[booked_slots?.length - 1]?.[1]?.split(":")
    //     let is_expired = isExpiredDateTime(new Date(new Date(booking_end_time).setHours(temp_end_slot?.[0], temp_end_slot?.[1], temp_end_slot?.[2])), new Date().toISOString())
    //     return (
    //         is_expired
    //     )
    //   }

    const updateState = (key, value) => {
        setFilderData({ ...filterData, [key]: value });
    };

    const applyFilter = (data) => {
        let value = {
            status: data.status,
        }
        setFilderData(value);
        getAmenitiesBooking(0, "", true, value)
        setFilter(false)
    };

    const filterArray = [
        {
            id: "1",
            title: t("Status"),
            key: "status",

            filterType: "CHECKBOX",
            values: [
                {
                    label: "Expired", value: "Expired"
                }
            ] ?? [],
        }
    ]

    return (
        <Container maxWidth="sm" className={classes.container}>
            <Box className={classes.navigate}>
                <IconButton sx={{ left: language === "ar" ? "10px" : "30px" }} className={classes.backbtn} onClick={back}>
                    {language === "ar" ?
                        <KeyboardArrowRightIcon /> :
                        <KeyboardArrowLeftIcon />
                    }
                </IconButton>
            </Box>
            <Box className={classes.topCard}>
                <img className={classes.topImgae} src='images/myBookingDetails.png' alt="" />
                <Box className={classes.searchBox} p={0}>
                    <TextField
                        size="medium"
                        variant="outlined"
                        placeholder={t("Search Booking Here")}
                        fullWidth
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }}
                        className={classes.textfiled}
                    />
                </Box>
            </Box>

            <Grid container sx={{ marginTop: "40px", display: "block" }} pb={2}>
                <Grid item sm={12}>
                    <Stack direction={"row"} justifyContent={"space-between"} sx={{ padding: "8px 16px 0px 16px" }}>
                        <Typography className={classes.bookingTitle}>{myBookingData?.length} {t("Bookings")}</Typography>
                        <Box onClick={() => setFilter(true)}>
                            <FilterSvg />
                        </Box>
                    </Stack>
                    <InfiniteScroll
                        dataLength={myBookingData?.length ?? 10}
                        next={loadmore}
                        hasMore={true}
                        height={size?.height - 326}
                        className={classes.scroll}
                    >
                        {
                            myBookingData?.map((e) => {
                                return (
                                    <Box p={1} className={classes.statusCard} m={2} onClick={() => navigate(e)}>
                                        <Stack direction={"row"} spacing={2} height={"55px"} alignItems="center" divider={
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }} >
                                                <Box display="flex" alignItems="center" style={{ borderRadius: "4px", marginLeft: "-12px" }} flexDirection="column">
                                                    <Box style={{ overflow: "hidden" }}>
                                                        <CircleIcon
                                                            style={{ marginTop: "14px", fontSize: "1rem", color: "#F5F7FA" }}
                                                        />
                                                    </Box>
                                                    <Divider className={classes.divider} />
                                                    <Box style={{ overflow: "hidden" }}>
                                                        <CircleIcon
                                                            style={{ marginBottom: "6px", fontSize: "1rem", color: "#F5F7FA" }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </div>
                                        }>
                                            <Avatar className={classes.avatar} src={generateImageUrl(e?.property_facility?.facility_assets?.[0]?.url, img_size.small_square)}></Avatar>
                                            <Stack direction={"row"} justifyContent={"space-between"} width="100%" alignItems={"center"}>
                                                <Stack sx={{ marginLeft: "-16px" }}>
                                                    <Typography className={classes.title} noWrap > {e?.description} </Typography>
                                                    <Typography className={classes.content} noWrap sx={{ width: 100, marginTop: "6px" }}> {e?.booking_id ?? "-"} </Typography>
                                                </Stack>
                                                <Stack alignItems={"center"} sx={{ marginInlineStart: "-60px" }}>
                                                    <Typography className={classes.title1}> {moment(e?.booking_start_time).format("DD MMM")} </Typography>
                                                    {
                                                        e?.period === "Daily" ?
                                                            <Typography className={classes.content1} marginTop={"6px"}> {moment(e?.booking_start_time).format("DD MMM YY")} - {moment(e?.booking_end_time).format("DD MMM YY")}</Typography>
                                                            :
                                                            <Typography className={classes.content1} marginTop={"6px"}>
                                                                {moment(e?.booked_slots?.[0], "HH:mm").format("hh:mm A") + " - " + getTimeWithAmPm(`${Number((e?.booked_slots?.[e?.booked_slots?.length - 1]?.[1]).slice(0, 2)) + 1}:00:00`)}
                                                                {/* {moment(e?.booked_slots[e?.booked_slots?.length - 1], "HH:mm:ss")} */}
                                                                {/* {moment(e?.booked_slots[e?.booked_slots?.length - 1], "HH:mm:ss").format("hh:mm A")), 1)).format("hh:mm A")} */}
                                                            </Typography>
                                                    }
                                                </Stack>
                                                <Stack sx={{ marginLeft: "-8px" }}>
                                                    {e?.is_expired ?
                                                        <Typography className={classes.not_booked}>{t("Expired")}</Typography>
                                                        :
                                                        <Typography className={e?.booking_status === "Booked" ? classes.booked : classes.canceled}>
                                                            {e?.booking_status}
                                                        </Typography>}
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                )
                            })
                        }
                    </InfiniteScroll>

                </Grid>
            </Grid>

            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={filter}
                    onClose={() => setFilter(false)}
                >
                    <Filter
                        t={t}
                        filterData={filterArray}
                        selectedList={filterArray?.[0]}
                        filters={{
                            status: filterData.status,
                        }}
                        setSelectedFilter={setFilderData}
                        onGoBack={() => setFilter(false)}
                        onApply={applyFilter}
                        onClose={() => setFilter(false)}
                        updateState={updateState}
                        data={filterData}
                    />
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer open={filter} onClose={() => setFilter(false)}>
                    <Filter
                        t={t}
                        filterData={filterArray}
                        selectedList={filterArray?.[0]}
                        filters={{
                            status: filterData.status,
                        }}
                        setSelectedFilter={setFilderData}
                        onGoBack={() => setFilter(false)}
                        onApply={applyFilter}
                        onClose={() => setFilter(false)}
                        updateState={updateState}
                        data={filterData}
                    />
                </Drawer>
            </Hidden>
        </Container>
    )
}

export default withNamespaces("amenitiesBooking")(MyBooking)