import React from "react";

function BigGreenTick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
    >
      <g data-name="Group 3727" transform="translate(-.494 1.506)">
        <circle
          cx="35"
          cy="35"
          r="35"
          fill="#5ac782"
          data-name="Ellipse 2"
          transform="translate(.494 -1.506)"
        ></circle>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="7"
          d="M20.233 32.739l10.788 10.788 20.068-20.068"
          data-name="Path 944"
        ></path>
      </g>
    </svg>
  );
}

export default BigGreenTick;