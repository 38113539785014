
import RingingScreen from "./ringingScreen";
import { useSocket } from "./socket";
import { useState, useEffect, useContext } from "react";
import { Container } from "@mui/material";
import { LocalStorageKeys, NetWorkCallMethods, AlertProps } from "../../utils";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Peer from 'peerjs';
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { LoadingSection } from "../../components";
import VideoContainer from "./videoCall";
import { Routes } from "../../router/routes";
import { AlertContext } from "../../contexts";
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
const VideoCall = ({ t = () => false }) => {


    const socket = useSocket();
    const history = useHistory();
    const searchURL = useLocation().search;
    const request_id = new URLSearchParams(searchURL).get("id");
    const status = new URLSearchParams(searchURL).get("status");
    const statusAvoid=["accept","rejected"];

    const [isvideo, setIsVideo] = useState(false);
    const alert = useContext(AlertContext);

    const [details, setDetails] = useState({
        data: null,
    });
    const [loading,setLoading]=useState(true)
    const [live_user, setLiveUser] = useState(false);
    const [seconds, setSeconds] = useState(60);
    const [peer, setPeer] = useState(null);
    const [partnerPeerId, setPartnerPeerId] = useState('');
    const [controls, setControls] = useState({
        audio: true
    })
    const [partnerControl, setPartnerControl] = useState({
        audio: false,
        video: false
    })
    const [partnerAccept, setPartnerAccept] = useState(false)

    
    const request_status={
        "rejected":{
            msg:"Rejecting",
            icon:<CancelIcon style={{color:"red",fontSize:"40px"}}/>,
            key:"Rejected"
        },
        "accept":{
            msg:"Approving",
            icon:<VerifiedIcon style={{color:"green",fontSize:"40px"}}/>,
            key:"Approved"
        }
    }

    const disconnect = () => {

        if (peer) {
            peer.destroy();
        }

        if (socket) {
            socket.emit('disconnect_trigger', localStorage.getItem(LocalStorageKeys.userProfileId), details?.data?.created_by);
        }

        history.push(Routes.dashboard)

    }

    useEffect(() => {

        if (socket && details?.data) {

            socket.emit('users', {
                token: localStorage.getItem(LocalStorageKeys?.authToken),
            })

            socket.on("user_connected", (data) => {

                console.log(data?.connected_users, 'user_connected');

                const isSubset = [details?.data?.created_by, localStorage.getItem(LocalStorageKeys.userProfileId)].every(value => data?.current_active_user?.includes(value));

                setLiveUser(isSubset ? true : false);

                

            })

            socket.on('accpet-resident', () => {
                setPartnerAccept(true)
            })

            socket.on("disconnect_trigger", () => {
                disconnect()
            })

            if(status==="video"){
                handleAction("Video")
            }

        }
        // eslint-disable-next-line
    }, [socket, details]);

    const updateStatus=(res)=>{
        const payload = {
            is_swift_in: true,
            is_walk_in: true,
            swift_in_param: {
                id: res?.data?.id,
                status: request_status[status]?.key,
                updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                updated_at: new Date()
            },
            walk_in_param: {
                status:  request_status[status]?.key,
                request_id: res?.data?.id,
                created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                agreement_unit_user: res?.data?.notes?.[0]?.agreement_unit_user,
                security_guard_id: res?.data?.created_by,
                message: request_status[status]?.key
            }
        }

        NetworkCall(
            `${config.api_url}/walkInout/upsert_walk_in_log`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((response) => {
            goBack()
            changeStatusTrigger(payload)
        })
    }

    useEffect(() => {

        if (request_id) {

            NetworkCall(
                `${config.api_url}/walkInout/details`,
                NetWorkCallMethods.post,
                { id: request_id },
                null,
                true,
                false
            ).then((res) => {


                if(statusAvoid?.includes(status)){
                    updateStatus(res)
                }else{
                    setPartnerPeerId(res?.data?.created_by)
                    setDetails({
                        data:res?.data
                    })
                    if(status!=="video"){
                    setLoading(false)
                }
                   
                }


               
            }).catch((err) => {
                console.log(err)
            })
        }
       
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!statusAvoid?.includes(status)){

        const peerInstance = new Peer(localStorage.getItem(LocalStorageKeys.userProfileId), {
            key: "peerjs",
            debug: 2,
            secure: true
        });

        setPeer(peerInstance);

        peerInstance.on('open', (id) => {
            console.log('My peer ID is: ' + id);
        });

        peerInstance.on('call', (call) => {
            // Answer the call and send your stream
            const userMediaConstraints = {
                video: false,
                audio: controls?.audio,
            };
          
            navigator.mediaDevices
                .getUserMedia(userMediaConstraints)
                .then((stream) => {
                
                    call.answer(stream);
                    call.on('stream', (partnerStream) => {
                        console.log(partnerStream,"partnerStream")
                      

                        const isVideoEnabled = partnerStream.getVideoTracks().length > 0;
                        const isAudioEnabled = partnerStream.getAudioTracks().length > 0;

                        setPartnerControl({
                            audio: isAudioEnabled,
                            video: isVideoEnabled
                        })

                        // Handle the partner's stream
                        const partnerVideo = document.getElementById('partner-video');
                        if (partnerStream) {
                            partnerVideo.srcObject = partnerStream;
                        }


                    });
                })
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Unable to Access User Media",
                    });
                    console.error('Error accessing user media:', error);
                });
        });

        return () => {
            // Clean up Peer.js when the component is unmounted
            if (peer && socket) {
                disconnect()
            }
        };
      }


        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        let timer;

        if (seconds > 0 && !partnerAccept) {
            timer = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }

        // Cleanup the timer when the component unmounts or when seconds reach 0
        return () => clearInterval(timer);

        // eslint-disable-next-line
    }, [seconds]);

    useEffect(() => {

        if (seconds === 0 && isvideo) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: "Security Guard Not Responding",
            });

            disconnect()
        }

        // eslint-disable-next-line
    }, [seconds])



    const goBack = () => {
        if (live_user && !isvideo) {
            history.push(Routes.dashboard)
        } else {
            disconnect()
        }

    }

    const cancelCall = () => {
        disconnect()
    }

    const accessControl = (controls) => {

        setControls(controls)


        if (isvideo) {

            navigator.mediaDevices
                .getUserMedia(controls)
                .then((stream) => {
                    peer.call(partnerPeerId, stream);
                })
                .catch((error) => {
                    console.error('Error accessing user media:', error);
                });

        }
    }

    const changeStatusTrigger = (det) => {
        
        socket.emit('change_request_status', det, request_id);


        alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: `You have ${det?.status === "Approved"?"approved":"denied"} ${details?.data?.person_name}'s entry`,
        });

    }


    const handleAction = (type) => {


        let payload;

        if (type === "Video") {

            payload = {
                request_id: details?.data?.id,
                security_guard_id: details?.data?.created_by,
            }

        } else {

            payload = {
                is_swift_in: true,
                is_walk_in: true,
                swift_in_param: {
                    id: details?.data?.id,
                    status: type,
                    updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                    updated_at: new Date()
                },
                walk_in_param: {
                    status: type,
                    request_id: details?.data?.id,
                    created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
                    agreement_unit_user: details?.data?.notes?.[0]?.agreement_unit_user,
                    security_guard_id: details?.data?.created_by,
                    message: type

                }
            }
        }

        NetworkCall(
            `${config.api_url}/walkInout${type === "Video" ? `/trigger_security` : `/upsert_walk_in_log`}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        ).then((res) => {
            
            if (type === "Video") {
                setIsVideo(true)
                setLoading(false)
              

            } else {
                changeStatusTrigger(payload)
                cancelCall()
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <Container maxWidth="sm" style={{ padding: "0px" }}>
            {
               loading ?
                    <LoadingSection 
                    controls={controls} 
                    message={statusAvoid?.includes(status)? `Request ${request_status?.[status]?.msg} Please Wait Few Mins...`:"Loading"} 
                    icon={request_status?.[status]?.icon}
                    top={"20vh"} />
                    :
                    <>

                        {isvideo ?
                            <VideoContainer  changeStatusTrigger={changeStatusTrigger} partnerAccept={partnerAccept} partnerControl={partnerControl} controls={controls} accessControl={accessControl} goBack={goBack} details={details?.data} cancelCall={cancelCall}/>
                            :
                            <RingingScreen  handleAction={handleAction} changeStatusTrigger={changeStatusTrigger} setIsVideo={setIsVideo} controls={controls} accessControl={accessControl} live_user={live_user} cancelCall={cancelCall} goBack={goBack} details={details?.data} />
                        }
                    </>
            }

        </Container>

    )
}
export default VideoCall