import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const propertyStyles = makeStyles((theme) => ({
    root: {
        padding: "0px",
        backgroundColor: "#F2F5FA",
        overflow: "hidden",
    },
    roots: {
        margin: "0px 12px",
        padding: "0px 4px",
    },
    image: {
        objectFit: "cover",
        width: "70px",
        height: "70px",
        borderRadius: "12px",
    },
    card: {
        backgroundColor: "#fff",
        marginBottom: "12px",
        borderRadius: theme.palette.borderRadius2,
        boxShadow: "0px 0px 4px #00000029",
        cursor: "pointer",
        padding:"12px"
    },
    name: {
        fontSize: "0.875rem",
        fontFamily: FontFamilySwitch().bold,
        margin:"0",
        marginInlineStart: "12px",
    },
    total: {
        fontSize: "1rem",
        fontFamily: FontFamilySwitch().bold,
        color: "#071741",
        marginTop: "12px",
    },
    arrow: {
        border: "1px solid #E4E8EE",
        backgroundColor: "#F2F4F7",
        fontSize: "0.75rem",
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    sub: {
        fontSize: "0.75rem",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
        direction: "ltr"
    },
    details: {
        flexFlow: 'wrap !important',
        display: "flex",
        margin: "5px 0px 0px 0px",
        alignItems: "center",
        marginInlineStart: "12px"
    },
    bottomText: {
        position: "absolute",
        bottom: 0,
        borderRadius: "0px 0px 0px 4px",
        backgroundColor: "#071741",
        color: "#fff",
        padding: "4px",
        textAlign: "center",
        width: "100%"
    },
    residents: {
        fontSize: "0.75rem",
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().regular,
        backgroundColor: "#F2F4F7",
        padding: "3px 6px",
        borderRadius: "4px"
    },
}));