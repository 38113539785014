
import { Avatar, Box, Typography, Grid } from "@mui/material"
import { returnTrackIcon } from "../utils";
import moment from "moment";
import { useStyles } from "./style";

export const GuestDetailCard = ({ status = "requested", t = () => false, details = {},onSwipe=()=>false,disabled=false }) => {

    const classes = useStyles();


    // const swipeBtn=()=>{
    //  if(details?.check_in_time){
    //     onSwipe("check_out")
    //  }else{
    //     onSwipe("check_in")
    //  }
    // }

    return (
        <Box className={classes.guestDetailRoot}>

            <Box display="flex" p={2}>
                <Box>
                    <Avatar src={details?.vendor_image} sx={{height:50,width:50}}/>
                </Box>
                <Box sx={{ marginInlineStart: "10px" }}>
                    <Typography className={classes.guestName}>{details?.person_name}</Typography>
                    {details?.mobile_no &&
                    <Typography className={classes.guestMobile}>{details?.mobile_no_country_code} {details?.mobile_no}</Typography>
                    }
                    {details?.identification?.label &&
                    <Typography className={classes.guestMobile}>{details?.identification?.label} | {details?.identification_no}</Typography>
                    }
                    {
                        details?.reason &&
                    <Typography className={classes.reason}>
                        {t("Reason")}: {details?.reason}
                    </Typography>
                    }

                </Box>
            </Box>
            {
                status === "Approved" &&

                <Box sx={{ borderTop: "1px solid #E4E8EE" }} p={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography className={classes.checkInTitle}>{t("Checked In")}</Typography>
                            <Typography className={classes.checkInTitle}>{details?.check_in_time? moment(details?.check_in_time).format("DD MMM YYYY hh:mm A"):"-"}</Typography>

                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.checkInTitle}>{t("Checked Out")}</Typography>
                            <Typography className={classes.checkInTitle}>{details?.check_out_time ? moment(details?.check_out_time).format("DD MMM YYYY hh:mm A"): "-"}</Typography>

                        </Grid>
                    </Grid>
                    {/* {!details?.check_out_time && 
                    <Box marginTop={"12px"}>
                        <SlideButton
                            mainText={ details?.check_in_time ? `${t("Swipe to Check Out")}`:`${t("Swipe to Check Out")}`}
                            overlayText={ details?.check_in_time ?t("Checked Out"): t("Checked In")}
                            classList={"my-class1"}
                            caretClassList={"my-caret-class1"}
                            overlayClassList={"my-overlay-class1"}
                            onSlideDone={swipeBtn}
                            disabled={disabled}
                        />
                    </Box>
                    } */}
                </Box>
            }

        </Box>
    )
}

export const TrackCard = ({ status = "requested", t = () => false, details = {}, paddingBottom = "20px",showBorder=false }) => {

    const classes = useStyles({showBorder})
    return (
        <Box className={classes.trackCardRoot} paddingBottom={paddingBottom}>
            <Box sx={{ position: "absolute", top: "0px", left: "-20px" }}>
                {returnTrackIcon(details?.status)}
            </Box>
            <Box sx={{ marginInlineStart: "34px" }}>
                <Typography className={classes.trackTitle}>{details?.message}</Typography>
                <Typography className={classes.trackDes}>{moment(details?.created_at).format("DD MMM YYYY, hh:mm A")}, by {details?.created_by}</Typography>

            </Box>

        </Box>
    )
}