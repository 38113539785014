export const PrivacyPolicy = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
        <g data-name="Group 108907" transform="translate(-108 -267)">
            <rect
                width={40}
                height={40}
                fill="#f1f7ff"
                data-name="Rectangle 55935"
                rx={4}
                transform="translate(108 267)"
            />
            <path
                fill="#6a68cc"
                stroke="#6a68cc"
                strokeLinecap="round"
                strokeWidth={0.2}
                d="M127.813 275.5a.733.733 0 0 0-.514.188s-3.67 3.234-8.066 3.234a.733.733 0 0 0-.733.733v5.994c0 3.265 1.51 8.637 9 11.793a.733.733 0 0 0 .569 0c7.494-3.156 9-8.528 9-11.793v-5.994a.733.733 0 0 0-.733-.733c-4.4 0-8.066-3.234-8.066-3.234a.733.733 0 0 0-.457-.188Zm-.024 1.676a14.689 14.689 0 0 0 7.822 3.152v5.321c0 2.807-1.079 7.336-7.822 10.3-6.743-2.963-7.822-7.492-7.822-10.3v-5.322a14.689 14.689 0 0 0 7.822-3.152Zm0 4.191a4.889 4.889 0 1 0 4.889 4.889 4.891 4.891 0 0 0-4.889-4.89Zm0 1.467a.732.732 0 0 1 .733.733v1.956h1.467a.734.734 0 1 1 0 1.467h-2.2a.732.732 0 0 1-.733-.733v-2.691a.732.732 0 0 1 .733-.733Z"
                data-name="Group 115812"
            />
        </g>
    </svg>
);
