import { Box, Typography } from "@mui/material"
import { useStyles } from "./style"

export const SelectPopover = ({
    yearOption = [],
    monthOption = [],
    year = "",
    month = "",
    handleChangePeriod = () => false
}) => {

    const classes = useStyles()
    return (
        <Box className={classes?.dropdown}>
            <Box>
                {
                    monthOption?.map(( e, i) => {
                        return <Typography className={e?.value === month?.value ? classes?.selectedtext : classes?.unselectedtext}
                            onClick={() => handleChangePeriod("month", e)}>
                            {e?.label}</Typography>
                    })
                }
            </Box>
            <Box>
                {
                    yearOption?.map((e, i) => {
                        return <Typography className={e?.value === year?.value ? classes?.selectedtext : classes?.unselectedtext}
                            onClick={() => handleChangePeriod("year", e)}>
                            {e?.label}</Typography>
                    })
                }
            </Box>

        </Box >
    )
}