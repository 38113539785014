import { Backdrop, Box, Button, CircularProgress, Container, Dialog, Divider, Drawer, Hidden, IconButton, Stack, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { CheckIcon } from "./assets/checkIcon"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { withNamespaces } from "react-i18next"
import useWindowDimensions from "../../utils/useWindowDimensions"
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, concat_string } from "../../utils"
import { useStyles } from "./style"
import { config } from "../../config"
import { BackendRoutes } from "../../router/routes"
import { NetworkCall } from "../../networkcall"
import CloseIcon from "@mui/icons-material/Close"
import { DashboardSimmer } from "../../components/simmer/dashboardSimmer"
import { DocumentViewer } from "../../components"
import PayNowInvoiceList from "./component/payNowInvoiceList"

const PayRequestInvoiceList = (props) => {
    const { t = () => false, handleTopBarState } = props;
    const limit = 40;
    const size = useWindowDimensions();
    const currency = localStorage?.getItem(LocalStorageKeys?.clientCurrency) ? JSON?.parse(localStorage?.getItem(LocalStorageKeys?.clientCurrency)) : "";
    const classes = useStyles({ size });
    const { state } = useLocation();
    const id = state?.id;

    const [invoice_state, set_invoice_state] = useState({
        total_invoice_count: 0,
        total_invoice_amount: 0,
        invoice_list: [],
    });
    const [pay_now_invoice_list_state, set_pay_now_invoice_list_state] = useState([]);
    const [is_invoice_list_open, set_is_invoice_list_open] = React.useState(false);
    const [offSet, set_offSet] = useState(0);
    const [loading, setLoading] = useState(true);
    const [is_pdf_open, set_is_pdf_open] = React.useState(false);
    const [is_pay_now_disabled, set_is_pay_now_disabled] = React.useState(false);
    const [pdf_state, set_pdf_state] = useState({
        load: false,
        data: ""
    });

    useEffect(() => {
        getInvoiceDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInvoiceDetails = (offset = offSet, is_fetch_more = false) => {
        const payload = { id, offset, limit, }

        NetworkCall(
            `${config.api_url}${BackendRoutes?.invoice_maintenance_invoice}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            if (response?.data?.invoice?.length > 0) {
                let temp_invoice_state = {
                    total_invoice_count: response?.data?.count?.[0]?.count ?? 0,
                    total_invoice_amount: response?.data?.total_invoice_amount ?? 0,
                }

                if (is_fetch_more) {
                    set_invoice_state({
                        ...temp_invoice_state,
                        invoice_list: [
                            ...invoice_state?.invoice_list,
                            ...response?.data?.invoice?.map?.(_ => {
                                return {
                                    ..._,
                                    is_checked: true,
                                }
                            })
                        ],
                    })
                } else {
                    set_invoice_state({
                        ...temp_invoice_state,
                        invoice_list: response?.data?.invoice?.map?.(_ => {
                            return {
                                ..._,
                                is_checked: true,
                            }
                        }),
                    })
                }
            }
            setLoading(false);
            handleTopBarState({
                text: t("Invoices")
            })
        }).catch((err) => {
            console.log(err)
        })

    }

    const handlePayNow = () => {
        set_pay_now_invoice_list_state(invoice_state?.invoice_list);
        set_is_invoice_list_open(true);
    }

    const handleInfiniteScrollNext = () => {
        let new_offset = offSet + limit;
        getInvoiceDetails(new_offset, true);
        set_offSet(new_offset);
    }

    const handleInvoiceCheckBox = (e, _) => {
        e?.stopPropagation();
        let temp_invoice_list = invoice_state?.invoice_list?.map?.((i) => {
            return {
                ...i,
                is_checked: i?.id === _?.id
                    ? !i?.is_checked
                    : i?.is_checked,
            }
        });
        set_invoice_state({
            ...invoice_state,
            invoice_list: temp_invoice_list,
        })
        set_is_pay_now_disabled(handlePayNowActive(temp_invoice_list));
    }

    const handleInvoiceCard = (_) => {
        set_is_pdf_open(true)
        set_pdf_state({ load: true })
        const payload = {
            invoice_id: _?.id,
        }
        NetworkCall(
            `${config.api_url}${BackendRoutes?.invoice_template}`,
            NetWorkCallMethods.post, payload, null, true, false
        ).then((response) => {
            set_pdf_state({ data: response?.data ?? "", load: false });
        }).catch((error) => {
            set_pdf_state({ load: false })
            alert.setSnack({
                ...alert, open: true,
                severity: AlertProps.severity.error,
                msg: t("Some thing went wrong"),
            });
        });
    };

    const handlePayNowActive = (invoice_list) => {
        let is_checked_count = 0;
        for (let i = 0; i < invoice_list?.length; i++) {
            if (invoice_list?.[i]?.is_checked) {
                is_checked_count = is_checked_count + 1;
            }
        }

        return !(is_checked_count > 0);
    }

    const handleIsInvoiceListOpen = (invoice_list) => {
        let is_checked_count = 0;
        for (let i = 0; i < invoice_list?.length; i++) {
            if (invoice_list?.[i]?.is_checked) {
                is_checked_count = is_checked_count + 1;
            }
        }

        return is_checked_count > 0;
    }

    const handlePayNowInvoiceListDelete = (_) => {
        let temp_invoice_list = pay_now_invoice_list_state?.map?.((i) => {
            return {
                ...i,
                is_checked: i?.id === _?.id
                    ? !i?.is_checked
                    : i?.is_checked,
            }
        });

        set_is_invoice_list_open(handleIsInvoiceListOpen(temp_invoice_list));
        set_pay_now_invoice_list_state(temp_invoice_list)
    }

    return (<div>
        <Container className={classes.root}>
            {loading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <>
                    <Box className={classes.body}>
                        <Typography className={classes.list_header}>{t("SUMMARY")}</Typography>
                        <Box className={classes.list_section}>
                            <InfiniteScroll
                                dataLength={invoice_state?.invoice_list?.length ?? null}
                                next={handleInfiniteScrollNext}
                                hasMore={true}
                                height={size?.height - 199}>
                                <Stack direction={"column"} divider={<Divider className={classes.invoice_list_divider} />}>
                                    {
                                        invoice_state?.invoice_list?.map?.((_) => {
                                            return (
                                                <Stack className={classes.invoice_card} direction={"row"} columnGap={"12px"}
                                                    justifyContent={"space-between"} alignItems={"center"}
                                                    onClick={() => handleInvoiceCard(_)}>
                                                    <Stack direction={"row"} alignItems={"center"}
                                                        spacing={"12px"}>
                                                        <Box className={_?.is_checked
                                                            ? classes.checked_box
                                                            : classes.unchecked_box}
                                                            onClick={(e) => handleInvoiceCheckBox(e, _)}>
                                                            {_?.is_checked && <CheckIcon />}
                                                        </Box>
                                                        <Stack direction={"column"} spacing={"4px"}>
                                                            {_?.invoice_total_amount?.toString() &&
                                                                <Typography className={classes.amount_text}>
                                                                    {
                                                                        concat_string(
                                                                            {
                                                                                amount: _?.invoice_total_amount?.toString(),
                                                                                currency_code: currency?.code,
                                                                            },
                                                                            ["amount", "currency_code"],
                                                                            " ",
                                                                        )
                                                                    }
                                                                </Typography>
                                                            }
                                                            {(_?.quotaion_classification || _?.invoice_no) &&
                                                                <Stack direction={"row"} alignItems={"center"} columnGap={"2px"}
                                                                    divider={<Typography className={classes.other_details_text}>{"|"}</Typography>}>
                                                                    {_?.quotaion_classification && <Typography className={classes.other_details_text}>
                                                                        {_?.quotaion_classification}
                                                                    </Typography>
                                                                    }
                                                                    {_?.invoice_no &&
                                                                        <Typography className={classes.other_details_text}>
                                                                            {_?.invoice_no}
                                                                        </Typography>
                                                                    }
                                                                </Stack>
                                                            }
                                                        </Stack>
                                                    </Stack>
                                                    <KeyboardArrowRightIcon htmlColor="#98A0AC" />
                                                </Stack>
                                            )
                                        })
                                    }
                                </Stack>
                            </InfiniteScroll>
                        </Box>
                    </Box>
                    <Box className={classes.footer}>
                        <Button fullWidth className={classes.pay_now_button}
                            disabled={is_pay_now_disabled}
                            onClick={handlePayNow}>
                            {t("Pay Now")}
                        </Button>
                    </Box>
                </>
            )}
        </Container>


        {/* Invoice List Section */}
        <Hidden smUp>
            <Drawer
                className={classes.drawer}
                open={is_invoice_list_open}
                anchor="bottom"
                onClose={() => set_is_invoice_list_open(false)}
            >
                <Container maxWidth="sm">
                    <Box
                        display="flex"
                        p={1}
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                                {t("Payment")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                size="small"
                                onClick={() => set_is_invoice_list_open(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <div className={classes.contentBottom}>
                        <PayNowInvoiceList
                            invoice_list={pay_now_invoice_list_state}
                            currency={currency}
                            handleDelete={handlePayNowInvoiceListDelete}
                            handleViewInvoice={handleInvoiceCard}
                        // total={total}
                        // onDelete={handleDelete}
                        // viewinvoice={viewinvoice}
                        // onPay={openSuccess}
                        // activeinvoice={activeinvoice}
                        />
                    </div>
                </Container>
            </Drawer>
        </Hidden>
        <Hidden smDown>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={is_invoice_list_open}
                className={classes.dialog}
                sx={{ padding: "0px" }}
                onClose={() => set_is_invoice_list_open(false)}
            >
                <Container
                    maxWidth="sm"
                    style={{
                        overflow: "hidden",
                        padding: "2px 8px 8px 8px",
                    }}
                >
                    <Box
                        display="flex"
                        p={1}
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                                {t("Payment")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                size="small"
                                onClick={() => set_is_invoice_list_open(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <div style={{ maxHeight: size?.height - 115 }}>
                        <PayNowInvoiceList
                            invoice_list={pay_now_invoice_list_state}
                            currency={currency}
                            handleDelete={handlePayNowInvoiceListDelete}
                            handleViewInvoice={handleInvoiceCard}
                        // total={total}
                        // onDelete={handleDelete}
                        // viewinvoice={viewinvoice}
                        // onPay={openSuccess}
                        // activeinvoice={activeinvoice}
                        />
                    </div>
                </Container>
            </Dialog>
        </Hidden>

        {/* PDF Section */}
        <Hidden smDown>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={is_pdf_open}
                className={classes.dialog}
                onClose={() => set_is_pdf_open(false)}
            >
                <Box
                    display="flex"
                    p={1}
                    alignItems="center"
                    className={classes.titleroot}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.bottomTitle}>
                            {t("Invoice")}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={() => {
                            set_pdf_state({ data: "", load: false })
                            set_is_pdf_open(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <div style={{ height: size?.height - 115 }}>
                    {pdf_state?.load ?
                        <DashboardSimmer />
                        :
                        pdf_state?.data?.length > 0 ? (
                            <DocumentViewer url={`data:application/pdf;base64,${pdf_state?.data}`} />
                        ) : (
                            <>
                                <center className={classes.noData}>
                                    <Typography className={classes.bottomTitle}>
                                        {t("No Data")}
                                    </Typography></center></>
                        )}

                </div>
            </Dialog>
        </Hidden>
        <Hidden smUp>
            <Drawer
                maxWidth="sm"
                anchor="bottom"
                fullWidth
                open={is_pdf_open}
                className={classes.drawer}
                onClose={() => set_is_pdf_open(false)}
            >
                <Box
                    display="flex"
                    p={1}
                    alignItems="center"
                    className={classes.titleroot}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.bottomTitle}>
                            {t("Invoice")}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton size="small" onClick={() => {
                            set_pdf_state({ data: "", load: false })
                            set_is_pdf_open(false)
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <div style={{ height: size?.height - 115 }}>
                    {pdf_state?.load ?
                        <DashboardSimmer />
                        :
                        pdf_state?.data?.length > 0 ? (
                            <DocumentViewer url={`data:application/pdf;base64,${pdf_state?.data}`} />
                        ) : (
                            <>
                                <center className={classes.noData}>
                                    <Typography className={classes.bottomTitle}>
                                        {t("No Data")}
                                    </Typography></center></>
                        )}
                </div>

            </Drawer>
        </Hidden>
    </div>
    )
}

export default withNamespaces("request")(PayRequestInvoiceList)