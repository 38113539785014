import { Grid, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import { BarCharts } from "../../../components/barChartNew";
import { withNamespaces } from "react-i18next";
import { SelectBox } from "../../../components/dropDown";
import { revenueCardUseStyles } from "./style";
import NoDataShow from "../../../assets/noDataFound";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectPopover } from "../../../components/selectPopover";

const SpendingCard = ({
  intervalOption = [],
  duration = {},
  setDuration = () => false,
  yearOption = [],
  monthOption = [],
  t = () => false,
  loadOptions = () => { },
  dropDownloading,
  handleCategory = () => { },
  handleMonth = () => { },
  handleYear = () => { },
  handleDuration = () => { },
  selectedCategory = {},
  handleChangePeriod = () => false,
  month = {},
  year = {},
  data = [],
  isMonthly
}) => {

  const classes = revenueCardUseStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleIconClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <Grid className={classes.header_div} container alignItems={"center"}>
        <Grid sm={7} xs={5}>
          <Typography className={classes.heading}>{t("Spending Graph")}</Typography>
        </Grid>
        <Grid sm={5} xs={7}>
          <SelectBox
            loadOptions={(search, array) => loadOptions(search, array, "selectedCategory")}
            isPaginate
            value={selectedCategory}
            onChange={(value) => { handleCategory(value) }}
            debounceTimeout={800}
            loading={dropDownloading === "selectedCategory"}
            placeholder={t("Select Category")}
          />
        </Grid>
      </Grid>
      <div className={classes.content_div}>
        <Grid container alignItems="center">
          {isMonthly ?
            (<>
              <Grid item xs={6} sm={4}>
                <Stack
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  direction={"row"} alignItems={"center"}
                  justifyContent={"space-between"} p={"8px"}
                  className={classes.border}
                >
                  <Typography className={classes.monthYear}>
                    {`${month?.label} ${year?.label}`}
                  </Typography>
                  <KeyboardArrowDownIcon htmlColor="#cccccc" />
                </Stack>
              </Grid>
              <Popover
                id='simple-popover'
                open={open}
                anchorEl={anchorEl}
                className={classes?.popOver}
                onClose={() => {
                  handleIconClose()
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}>
                <SelectPopover
                  yearOption={yearOption}
                  monthOption={monthOption}
                  year={year}
                  month={month}
                  handleChangePeriod={handleChangePeriod}
                />
              </Popover>
              <Grid item xs={3} sm={5}></Grid>
            </>)
            : (<>
              <Grid item xs={4} sm={3}>
                <SelectBox
                  options={yearOption}
                  value={year}
                  onChange={(value) => {
                    handleYear(value)
                  }}
                />
              </Grid>
              <Grid item xs={5} sm={6}></Grid>
            </>)}
          <Grid item xs={3} sm={3}>
            <div className={classes.period_toggle_div_2}>
              {intervalOption?.map((i) => {
                return (
                  <div
                    className={
                      duration?.value === i?.value
                        ? classes.selected_button
                        : classes.unselected_button_2
                    }
                    onClick={() => {
                      handleDuration(i)
                    }}
                  >
                    <Typography className={classes.toggle_button_text}>
                      {i?.label}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent={"center"}>
              {data?.list?.length > 0 ?
                <div style={{ position: "relative", width: '100%', height: "300px" }}>
                  <BarCharts data={data} />
                </div> :
                <NoDataShow />
              }

            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withNamespaces("statistics")(SpendingCard);
