import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer } from 'recharts';
import { useStyles } from './style';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { FontFamilySwitch, LocalStorageKeys } from '../../utils';

export const currencyFormatted =(amount)=>  {
  if(!!amount) {
      const value=amount.toFixed(2)
      return Intl.NumberFormat('en-US').format(value)
  }
  return 0


}
export const ToolTipCustomBar = ({ active, payload, label , state , category}) => {
  const currency = localStorage.getItem(LocalStorageKeys?.clientCurrency) ? JSON.parse(localStorage.getItem(LocalStorageKeys?.clientCurrency))?.symbol : ''

  return (
      <Box sx={{
        backgroundColor:"#fff",
        height:'auto',
        width:'auto',
        padding:'12px',
        borderRadius:'12px',
        boxShadow: "0px 3px 30px #5C86CB2E",
        zIndex:999
    }}>
      {
          state === 1 ? 
              <Box sx={{display:"block"}}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{
        borderRadius: '50%',
        backgroundColor: 'primary.main',
        padding: '4px',
       // margin: '5px'
    }} style={{ backgroundColor: payload?.[0]?.stroke }}></Box>
                      <Box sx={{ paddingLeft: '7.5px' }}>
                          <Typography sx={{
        fontFamily:FontFamilySwitch()?.bold,
        fontSize: "0.75rem",
    }}>{currency} {currencyFormatted(payload?.[0]?.payload?.amount)} </Typography>
                      </Box>

                  </Box>
                  <Box>
<Typography sx={{
        color:"#4E5A6B",
        fontFamily:FontFamilySwitch()?.semiBold,
        fontSize: "0.75rem",
        paddingLeft: '7.5px'
    }}> {!!category ? payload?.[0]?.payload?.sub_category : payload?.[0]?.payload?.category} </Typography>

</Box>
              </Box>
      :

      <Box>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{
        borderRadius: '50%',
        backgroundColor: 'primary.main',
        padding: '4px',
       // margin: '5px'
    }} style={{backgroundColor:payload?.[1]?.stroke}}></Box>
          <Box sx={{ paddingLeft: '7.5px' }}>
              <Typography sx={{
     fontFamily:FontFamilySwitch()?.bold,
     fontSize: "0.75rem",
 }}>{currency} {currencyFormatted(payload?.[0]?.payload?.current_value)}</Typography>
          </Box>
          <Box>

</Box>
      </Box>
<Typography sx={{
        color:"#4E5A6B",
        fontFamily:FontFamilySwitch()?.semiBold,
        fontSize: "0.75rem",
        paddingLeft: '7.5px'
    }}> {!!category ? payload?.[0]?.payload?.sub_category : payload?.[0]?.payload?.category} </Typography>

      </Box>
      }
      
      
  </Box>
  )
}

export const LineCharts = ({
  data=[],
  category={}
}) => {
  const mediaQuery = useMediaQuery('(max-width:522px)')


  const [state , setState]=React.useState(null)
  
const maxValue = data?.list?.reduce((max, obj) => (obj.current_value > max ? obj.current_value : max), -Infinity)
const classes = useStyles();
  return (
    <div style={{ width: '100%', height: mediaQuery ? 250 : 300, position: "relative" }}>
    <ResponsiveContainer>
    <LineChart
      // height={300}
      data={data?.list}
      margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
      className={classes.name}
    >
      <CartesianGrid strokeDasharray="0" horizontal={true} vertical={false} />
      <XAxis dataKey="month" axisLine={false} />
      <YAxis allowDecimals={true} axisLine={false} dataKey="amount" tickLine={false} type="number" domain={[0,  maxValue]}  />
      <Tooltip content={<ToolTipCustomBar data={data} state={state} category={category}/>} cursor={{ fill: "transparent" }} />
      <Line type="monotone" dataKey={"amount"} isAnimationActive={false} stroke="#FF6D65"
      activeDot={{ r: 5 , 
        onMouseOver: ()=>setState(1),
        onClick: ()=>setState(1),
        onMouseLeave: ()=>setState(1),
        }} />
          
        <Line type="monotone" dataKey="current_value" stroke="#686AF3" 
        activeDot={{ r: 5 , 
         onMouseOver: ()=>setState(2),
         onClick: ()=>setState(2),
         onMouseLeave: ()=>setState(2)
        }} isAnimationActive={false} />
    </LineChart>
    </ResponsiveContainer>
    </div>
  );
}
