import { Box, Divider, Stack, Typography } from "@mui/material";
import { revenueListCardUseStyles } from "./style";


const RevenueListCard = ({data={}}) => {
    const classes = revenueListCardUseStyles();
  return (
    <>
      <Box className={classes.revenueListCard}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{ backgroundColor: data?.color }}
              className={classes.percentage}
            >
              {data?.percentage}
            </Box>
            <Typography className={classes.tenantId}>{data?.tenantId}</Typography>
          </Stack>
          <Typography className={classes.price}>{data?.price}</Typography>
        </Stack>
      </Box>
      <Divider />
    </>
  );
};

export default RevenueListCard;
