import { Container, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  second: {
    backgroundColor: "white",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    padding: "12px",
  },
}));
export const InvoiceSimmer = (props) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  return (
    <Container maxWidth="sm" sx={{ padding: "0px" }}>
      {window.location.pathname === "/invoiceScreen" && (
        <Skeleton variant="rectangular" height={50} />
      )}
      <div style={{ height: size.height }}>
        <Grid item style={{ padding: "12px" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Skeleton
                variant="rectangular"
                height={90}
                style={{ borderRadius: "4px" }}
              />
            </Grid>
          </Grid>
          <br />
        </Grid>
        <div className={classes.second}>
          <br />
          <Skeleton
            variant="rectangular"
            height={400}
            style={{ borderRadius: "4px" }}
          />
        </div>
      </div>
    </Container>
  );
};
