import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { generateImageUrl, img_size, stringAvatar } from "../../../utils";
import { useStyles } from "./style";

export const AmenitiesCard = (props) => {
  const classes = useStyles();
  const language = localStorage.getItem("i18nextLng")
  // const history = useHistory();
  // const name = props?.data?.name
  //   ? props?.data?.name
  //   : props?.data?.facility_type?.amenities_name;
  // const isBookingTrue = () => {
  //   history.push({
  //     pathname: Routes.amenitiesBookingDetails,
  //     state: {
  //       main: {
  //         id: props?.data?.id,
  //         name: name,
  //         type: props?.type,
  //         rate: `${props?.data?.currencyByID?.symbol ?? ""} ${props?.data?.rate ?? ""
  //           }`,
  //         period: props?.data?.period,
  //         description: props?.data?.description,
  //         companyId: props?.data?.company?.[0]?.id ?? props?.companyId,
  //         propertyId: props?.propertyId,
  //         agreementId: props?.agreementId,
  //       },
  //     },
  //   });
  // };
  // const isBookingFalse = () => {
  //   history.push(
  //     Routes.amenitiestype +
  //     "?Id=" +
  //     props?.data?.facility_id +
  //     "&name=" +
  //     name +
  //     "&type=" +
  //     props?.type
  //   );
  // };
  return (
    <div className={classes.root}>
      <div
        className={classes.roots}
        // onClick={() => isBookingFalse()}
        onClick={() => props?.onClick()}
      >
        <Stack direction="row">
          <Avatar className={classes.avatar}
            {...stringAvatar(props?.data?.facility_type?.amenities_name)}
            src={generateImageUrl(props?.data?.facility_assets?.[0]?.url, img_size.small_square)}
          >
          </Avatar>
          <Stack
            direction={"row"}
            spacing="6px"
            flexGrow={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.name} noWrap>
                {props?.data?.description &&
                  props?.data?.description}
                {props?.data?.name && props?.data?.name}
              </Typography>
              <Typography className={classes.call} noWrap>
                {props?.data?.operational_status === "Open" ?
                  "Available Today" :
                  "Under Maintenance"
                }
              </Typography>
            </Box>
            <Stack direction={"column"} justifyContent={"center"} spacing="30px">
              {!props?.data?.is_chargeable && (
                <Typography sx={{ left: language === "ar" && "10px", right: language !== "ar" && "10px" }} className={classes.tagStyle}>{"Free"}</Typography>
              )}
              {props?.data?.is_chargeable && (
                <Box display={"flex"} alignItems="flex-end" gap={"5px"}>
                  <Typography className={classes.tagStyle2}>
                    {props?.data?.currency_symbol ?? ""} {props?.data?.rate}
                  </Typography>
                  <Typography className={classes.perHr}>
                    {props?.data?.period === "Daily" ?
                      (" /Day") : (" /Slot")
                    }
                  </Typography>
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
      </div>
    </div>
  );
};
