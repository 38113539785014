import React from "react";
import Statistics from "./statistics";
import { withBottombar } from "../../HOCs";


class StatisticsParent extends React.Component {
    render() {
        return <Statistics {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(StatisticsParent, props);