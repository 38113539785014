import React from "react";
import {
  Stack,
  Typography,
  Grid,
  Button,
  Hidden,
  Drawer,
  Dialog,
  Box,
  Container,
} from "@mui/material";
import { withNamespaces } from "react-i18next";
import { useCorrespondanceStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DocumentViewer, LoderSimmer, DialogDrawer } from "../../components";
import DrawerComp from "./drawerComp";
import RplySvg from "../../components/svg/rplysvg";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AlertContext } from "../../contexts";
import { config } from "../../config";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import moment from "moment";
import { withBottombar } from "../../HOCs";
import { AssetCard } from "../../components/assetView";
import {Slider} from "../../components/assetView/slider";

const CorrespondanceType = ({ t = () => false, handleTopBarState }) => {
  const size = useWindowDimensions();
  const history = useHistory();
  const { state } = useLocation();
  const alert = React.useContext(AlertContext);
  const [data, setData] = React.useState({});
  const classes = useCorrespondanceStyles();
  const [open, setOpen] = React.useState(false);
  const [rply, setRply] = React.useState("");
  const [template, setTemplate] = React.useState("")
  const [loader, setLoader] = React.useState(false)
  const [isDisableBtn, setIsDisableBtn] = React.useState(false)
  const [selectedAsset, setSelectedAsset] = React.useState({
    asset:0,
    bool:false
  })

  React.useEffect(() => {
    if (!state) {
      history.push(Routes.caseManagement);
    } else {
      setData(state?.data);
      getCaseManagement(state?.data?.correspondence_id);
      getPdf()
    }
    // eslint-disable-next-line
  }, []);

  // React.useEffect(() => {
  //   // getPdf()
  //   // eslint-disable-next-line
  // }, [])

  const getCaseManagement = (id) => {
    const payload = {
      correspondence_id: id,
    };
    NetworkCall(
      `${config?.api_url}/correspondences/list_for_resident`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setData(response?.data?.data?.list?.[0]);
        handleTopBarState({
          text: response?.data?.data?.list?.[0]?.type
        })
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong"),
        });
      });
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleChange = (value) => {
    setRply(value);
  };

  const handleSubmitReply = () => {
    setOpen(false);
    replyComment();
  };

  const markAsRead = () => {
    const payload = {
      correspondence_id: data?.correspondence_id,
    };
    NetworkCall(
      `${config?.api_url}/correspondences/mark_as_read`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Marked As Read"),
        });
        // setData({ ...data, is_read: true });
        getCaseManagement(data?.correspondence_id);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong"),
        });
      });
  };
  const replyComment = () => {
    setIsDisableBtn(true)
    const payload = {
      correspondence_id: data?.correspondence_id,
      comment: rply,
    };
    NetworkCall(
      `${config?.api_url}/correspondences/comment`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Replied successfully"),
        });
        // setData({ ...data, is_replied: true, comment: rply });
        getCaseManagement(data?.correspondence_id);
        setIsDisableBtn(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong"),
        });
        setIsDisableBtn(false)
      });
  };
  const getPdf = () => {
    setLoader(true)
    const payload = {
      correspondence_id: state?.data?.correspondence_id,
      is_template: true
    };
    NetworkCall(
      `${config?.api_url}/correspondences/view_pdf`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setTemplate(response?.data?.data)
        setLoader(false)
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("Something Went wrong"),
        });
      });
  };

  const onClickAsset = (val) => {
    setSelectedAsset({
      bool: !selectedAsset?.bool,
      data: val,
    });
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      {
        loader ?
          <Box>
            <LoderSimmer card count={1} size={500} />
            <LoderSimmer card count={2} size={100} />
          </Box>
          :
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              {/* <TitleBar text={data?.type} goBack={GoBack} /> */}
            </Grid>
            <div
              className={classes.heightOverflow}
              style={{ height: size.height - 134, overflow: "overlay" }}
            >
              <Box className={classes.container}>
                {template ? (
                  <DocumentViewer url={
                    `data:image/png;base64,${template}`
                  }
                  // {data?.file_url}
                  />
                ) : (
                  <Typography
                    mt={3}
                    component={"h6"}
                    align="center"
                    className={classes.rplyText}
                  >
                    {t("Document not Found")}
                  </Typography>
                )}
                <Box height={"16px"} />
                {data?.comment && (
                  <Grid item xs={12}>
                    <Box className={classes.rplyBox}>
                      <Stack
                        direction="row"
                        alignItems="self-start"
                        justifyContent="space-between"
                      >
                        <Box className={classes.rplyhead}>
                          <RplySvg />
                          <Typography>{t("REPLY MESSAGE")}</Typography>
                        </Box>
                        <Typography className={classes.rplyDate}>
                          Replied on{" "}
                          {moment(data?.replied_at || Date.now())
                            .tz(moment.tz.guess())
                            .format("DD MMM YY ")}
                        </Typography>
                      </Stack>
                      <Typography className={classes.rplyText}>
                        {data?.comment}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {
                  data?.assets?.length > 0 &&

                  <Grid container spacing={1}>
                    <Grid item xs={12} >
                      <Typography>{t("Attachment")}</Typography>
                    </Grid>
                    {
                      data?.assets?.map((val,index) => {
                        return (
                          <Grid item xs={6} md={4} lg={3}>
                            <AssetCard
                              onClick={()=>onClickAsset(index)}
                              x={val}
                            />
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                }
              </Box>

            </div>

            <Grid item xs={12} className={classes.btnGrp}>
              <Grid container p={2} spacing={1}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    disabled={data?.is_read ? true : false}
                    variant="outlined"
                    className={classes.btn}
                    onClick={markAsRead}
                  >
                    <Typography className={classes.btnTxt}>
                      {t("Mark As Read")}
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={data?.is_replied}
                    className={classes.btn}
                    onClick={handleDrawerOpen}
                  >
                    <Typography className={classes.btnTxt}>
                      {t("Reply To This")}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      }
      <Hidden smUp>
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={() => setOpen(true)}
          className={classes.drawer}
        >
          <DrawerComp
            t={t}
            rply={rply}
            handleChange={handleChange}
            handleClose={handleClose}
            handleSubmitReply={handleSubmitReply}
            isDisableBtn={isDisableBtn}
          />
        </Drawer>
      </Hidden>

      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={open}
          onClose={() => setOpen(true)}
        >
          <DrawerComp
            t={t}
            rply={rply}
            handleChange={handleChange}
            handleClose={handleClose}
            handleSubmitReply={handleSubmitReply}
            isDisableBtn={isDisableBtn}
          />
        </Dialog>
      </Hidden>

      <DialogDrawer
      maxWidth="sm"
      open={selectedAsset?.bool}
      onClose={() =>onClickAsset(0)}
      header={t("Attachment")}

        component={
          <Box>
          <Slider
          height="550px"
            onClick={onClickAsset}
            assets={data?.assets?.map((val)=>{
              return{
                type:val?.file_meta?.type,
                src:val?.url,
                name:val?.file_meta?.name
              }
            }) ?? []}
            backgroundColor={"#000"}
            pageNumber
            padding={"0px"}
            isDownload
          />
          </Box>
        }

      />

    </Container>
  );
};


const props = {
  top_bar: "title_bar",
}


// To Be Refracted In Apple Screen

export default withBottombar(withNamespaces("CorrespondanceType")(CorrespondanceType), props);
