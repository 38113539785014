 
import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles= makeStyles((theme) => ({
    label: {
        fontSize: "0.75rem", 
        fontFamily: FontFamilySwitch().bold,
        marginBottom: "8px",
      },
    unactive: {
        padding: "9px 16px",
        marginTop: "3px",
        textAlign: "center",
        borderRadius: "4px",
        fontSize: "0.875rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        backgroundColor: "#F2F4F7",
        textTransform:"capitalize",
        "&:hover":{
        backgroundColor: "#F2F4F7",
        color: "#4E5A6B",
        }
      },
      active: {
        padding: "9px 16px",
        marginTop: "3px",
        textAlign: "center",
        borderRadius: "4px",
        fontSize: "0.875rem",
        color: "#FFFFFF",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        backgroundColor: "#5078E1",
        textTransform:"capitalize","&:hover":{
          backgroundColor: "#5078E1",
          color: "#FFFFFF",
          }
      },
      unactive2: {
        padding: "9px 16px",
        marginTop: "3px",
        textAlign: "center",
        borderRadius: "8px",
        fontSize: "0.875rem",
        color: "#4E5A6B",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        backgroundColor: "#F2F4F7",
        border:"1px solid #F2F4F7",
        textTransform:"capitalize",
        "&:hover":{
        backgroundColor: "#F2F4F7",
        color: "#4E5A6B",
        }
      },
      active2: {
        padding: "9px 16px",
        marginTop: "3px",
        textAlign: "center",
        borderRadius: "8px",
        fontSize: "0.875rem",
        color: "#5078E1",
        fontFamily: FontFamilySwitch().semiBold,
        cursor: "pointer",
        backgroundColor: "#EFF3FF",
        border:"1px solid #5078E1",
        textTransform:"capitalize","&:hover":{
          backgroundColor: "#EFF3FF",
          color: "#5078E1",
          }
      },
}));