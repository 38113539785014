import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import MemberCountIcon from '../../assets/memberCountIcon'
import { TextBox } from '../../components'
import { BookingPreviewStyle } from './style'
import { AuthContext } from '../../contexts'
import CloseIcon from '@mui/icons-material/Close';


export const ParticipantCount = ({
    onCLoseDrwPC = () => false,
    pcCount = false,
    setpcCount = () => false,
    validateCount = () => false,
    t = () => false
}) => {
    const auth = React.useContext(AuthContext)
    const classes = BookingPreviewStyle({ language: auth?.auth?.auth?.language })
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className={classes.flexWithPadding}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.modalTitle}>
                            {t("Enter Member Count")}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => onCLoseDrwPC()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} p={"16px 0px 0px"}>
                <Stack direction={"row"} px={2} alignItems={"center"} spacing={1}>
                    {/* <Stack direction={"row"} spacing={1}> */}
                    <MemberCountIcon />
                    <Box flexGrow={1}>
                        <Typography className={classes.pcTitle}>{t("Enter participant member count")}</Typography>
                        {/* <Typography className={classes.pcSubTitle}>This entered count members only allowed</Typography> */}
                    </Box>
                    {/* </Stack> */}
                    <Box sx={{ width: 50 }}>
                        <TextBox
                            value={pcCount}
                            label=""
                            placeholder={""}
                            onChange={(e) => (e?.target?.value === "" || e?.target?.value > 0) && setpcCount(e?.target?.value)}
                            type={"number"}
                            borderColor={"#78B1FE"} />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12} p={2}>
                <Button fullWidth variant="contained"
                    disabled={pcCount?.length === 0}
                    className={classes.confirmBtn}
                    onClick={() => validateCount()}>
                    {t("Continue")}
                </Button>
            </Grid>
        </Grid>
    )
}