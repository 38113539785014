import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useCorrespondanceStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "0px",
  },
  btnGrp: {
    display: "flex",
    background: "#fff",
  },
  btn: {
    padding: "12px 15px",
    borderRadius: "4px",
    boxShadow: "none",
  },
  btnTxt: {
    textTransform: "capitalize",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
  },
  drawerHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    margin: "12px auto",
    "& p": {
      fontSize: "1.125rem",
      fontFamily: FontFamilySwitch().bold,
      color: "#091B29",
    },
  },
  rplyTxt: {
    margin: "16px 0",
    width: "100%",
    "& div": {
      height: "110px",
      padding: "0 12px",
    },
  },
  heightOverflow: {
    overflow: "auto",
    backgroundColor: "#F2F2F2",
    paddingBottom: "45px",
    width: "100%",
  },
  rplyBox: {
    padding: "12px 14px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    width: "100%",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  rplyhead: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    "& p": {
      color: "#98A0AC",
      fontSize: "0.75rem",
      marginLeft: "5px",
      textTranform: "uppercase",
    },
  },
  rplyDate: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    "& p": {
      color: "#091B29",
      fontSize: "0.875rem",
    },
  },
  rplyText:{
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  container: {
    padding: "16px",
  },
}));
