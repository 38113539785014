import { Box, Container, Grid, Typography, IconButton, Hidden, Dialog, Drawer } from '@mui/material'
import React from 'react'
import { PropertyDetails } from './components'
import { useHistory } from 'react-router-dom'
import { amenitiesBookingStyle } from './style'
import CloseIcon from '@mui/icons-material/Close';
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { AlertContext, AuthContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
import { Routes } from '../../router/routes'
import useWindowDimensions from '../../utils/useWindowDimensions'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'

const AmenityBookingNew = ({ t = () => false, closeServicePopup = () => false }) => {
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const classes = amenitiesBookingStyle({ language: auth?.auth?.auth?.language })
    const alert = React.useContext(AlertContext);
    const size = useWindowDimensions()

    // States
    const [data, setData] = React.useState({
        selectedProperty: {},
        unitDetail: [],
        amenities: [],
        pdOpen: true,
        unitPopup: false,
        properties: []
    })
    const [offset, setOffSet] = React.useState(0)
    const [unitoffset, setUnitOffSet] = React.useState(0)

    React.useEffect(() => {
        getproperty()
        // eslint-disable-next-line
    }, [])

    const closeDrawer = () => {
        setData({
            ...data,
            pdOpen: false
        })
        closeServicePopup()
    }

    const closeUnitPopup = () => {
        setData({
            ...data,
            unitPopup: false,
            pdOpen: true,
        })
        setUnitOffSet(0)
        getproperty()
    }

    // Get amenities agaist property
    const getAmenitiesBooking = (propertydata) => {
        const data = {
            property_id: propertydata?.property_id
        };
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenity_categories`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                getUnitids(propertydata?.property_id, propertydata, response?.data?.data, 0, false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    // Get unit agaist property
    const getUnitids = (id, propertydata, amenities, offset, bool) => {
        const payload = {
            property_id: id,
            date: moment(new Date()).format("YYYY-MM-DD"),
            offset: offset,
            limit: 5
        };
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_agreementunits_and_units`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (bool) {
                    setData({
                        ...data,
                        selectedProperty: propertydata,
                        unitDetail: {
                            agreementUnits: data?.unitDetail?.agreementUnits?.concat(response?.data?.data?.agreementUnits),
                            count: response?.data?.data?.count
                        },
                        amenities: amenities,
                        unitPopup: true,
                        pdOpen: true,
                    })
                }
                else {
                    if (response?.data?.data?.agreementUnits?.length === 1) {
                        history.push({
                            pathname: Routes.amenitiesdetailsOne,
                            state: {
                                data: amenities,
                                selectedProperty: propertydata,
                                unitData: response?.data?.data?.agreementUnits?.[0]
                            },
                        });
                    } else {
                        setData({
                            ...data,
                            selectedProperty: propertydata,
                            unitDetail: response?.data?.data,
                            amenities: amenities,
                            unitPopup: true,
                            pdOpen: true,
                        })
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            });
    };


    // Get property 
    const getproperty = (offset, bool) => {
        const payload = {
            offset: offset,
            limit: 10,
            is_pagination: true
        }
        NetworkCall(
            `${config.api_url}/agreement-property-info/get_community`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (bool) {
                    setData({
                        ...data,
                        properties: data?.properties?.concat(response?.data?.data?.property_detail),
                        unitPopup: false
                    })
                } else {
                    setData({
                        ...data,
                        properties: response?.data?.data?.property_detail,
                        unitPopup: false
                    })
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    // set Property details 
    const setPropertyDetail = (value) => {
        // getUnitids(data?.property_id)
        getAmenitiesBooking(value)
    }

    // select unit 

    const selectedUnitDetail = (value) => {
        history.push({
            pathname: Routes.amenitiesdetailsOne,
            state: { data: data?.amenities, selectedProperty: data?.selectedProperty, unitData: value },
        });
    }

    const fetchMoreData = () => {
        setOffSet(offset + 10);
        getproperty(offset + 10, true);
    };

    const fetchMoreDataforUnit = () => {
        setUnitOffSet(unitoffset + 5);
        getUnitids(data?.selectedProperty?.property_id, data?.selectedProperty, data?.amenities, unitoffset + 5, true)
    };
    return (
        <Container maxWidth="sm">
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={data?.pdOpen}
                    onClose={closeDrawer}
                >
                    <div>
                        {/* {AmenityBookingPopup(closeDrawer, data?.properties, setPropertyDetail)} */}
                        <Grid container>
                            <Grid item xs={12}>
                                <Box
                                    className={classes.flexWithPadding}
                                >
                                    <Box flexGrow={1}>
                                        <Typography className={classes.modalTitle}>
                                            {t("Property Details")}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            onClick={closeDrawer}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={"16px 16px 0px"}>
                                <Box sx={{ width: "100% !important", marginTop: "8px", height: "300px" }}>
                                    <InfiniteScroll
                                        dataLength={data?.properties?.length}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        height={size?.height - 495}
                                    >
                                        {
                                            data?.properties?.map((e) => {
                                                return (
                                                    <PropertyDetails t={t} value={e} setPropertyDetail={setPropertyDetail} type={"property"} />
                                                )
                                            })
                                        }
                                        {/* <PropertyDetails t={t} value={data?.properties} setPropertyDetail={setPropertyDetail} type={"property"} /> */}
                                    </InfiniteScroll>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    className={classes.drawer}
                    open={data?.pdOpen}
                    onClose={closeDrawer}
                >
                    <div>
                        {/* {AmenityBookingPopup(closeDrawer, data?.properties, setPropertyDetail)} */}
                        <Grid container>
                            <Grid item xs={12}>
                                <Box
                                    className={classes.flexWithPadding}
                                >
                                    <Box flexGrow={1}>
                                        <Typography className={classes.modalTitle}>
                                            {t("Property Details")}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            onClick={closeDrawer}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} p={"16px 16px 0px"}>
                                {/* <PropertyDetails properties={properties} setPropertyDetail={setPropertyDetail} /> */}
                                <Box sx={{ width: "100% !important", marginTop: "8px", height: "300px" }}>
                                    <InfiniteScroll
                                        dataLength={data?.properties?.length}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        height={size?.height - 350}
                                    >
                                        {
                                            data?.properties?.map((e) => {
                                                return (
                                                    <PropertyDetails t={t} value={e} setPropertyDetail={setPropertyDetail} type={"property"} />
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
            </Hidden>

            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={data?.unitPopup}
                    onClose={closeUnitPopup}
                >
                    <div>{UnitPopup(t, closeUnitPopup, data?.unitDetail, selectedUnitDetail, fetchMoreDataforUnit)}</div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    className={classes.drawer}
                    open={data?.unitPopup}
                    onClose={closeUnitPopup}
                >
                    <div>{UnitPopup(t, closeUnitPopup, data?.unitDetail, selectedUnitDetail, fetchMoreDataforUnit)}</div>
                </Drawer>
            </Hidden>

        </Container>
    )
}

// const AmenityBookingPopup = (closeDrawer, properties, setPropertyDetail) => {
//     const classes = amenitiesBookingStyle()
//     return (
//         <Grid container>
//             <Grid item xs={12}>
//                 <Box
//                     className={classes.flexWithPadding}
//                 >
//                     <Box flexGrow={1}>
//                         <Typography className={classes.modalTitle}>
//                             Property Details
//                         </Typography>
//                     </Box>
//                     <Box>
//                         <IconButton
//                             onClick={closeDrawer}
//                         >
//                             <CloseIcon />
//                         </IconButton>
//                     </Box>
//                 </Box>
//             </Grid>
//             <Grid item xs={12} p={"16px 16px 0px"}>
//                 <PropertyDetails properties={properties} setPropertyDetail={setPropertyDetail} />
//             </Grid>
//         </Grid>
//     )
// }

const UnitPopup = (t = () => false, closeDrawer, properties, setPropertyDetail, fetchMoreDataforUnit) => {
    const classes = amenitiesBookingStyle()
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className={classes.flexWithPadding}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.modalTitle}>
                            {t("Unit Details")}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={closeDrawer}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} p={"16px 16px 0px"}>

                <Box sx={{ height: 300 }}>
                    <InfiniteScroll
                        dataLength={properties?.agreementUnits?.length}
                        next={fetchMoreDataforUnit}
                        hasMore={true}
                        height={300}
                    >
                        {
                            properties?.agreementUnits?.map((e) => {
                                return (
                                    <PropertyDetails t={t} value={e} setPropertyDetail={setPropertyDetail} />
                                )
                            })
                        }
                    </InfiniteScroll>
                </Box>
            </Grid>
        </Grid>
    )
}

export default withNamespaces("amenitiesBooking")(AmenityBookingNew)