import * as React from "react"
const ConformedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14.167} height={14} {...props}>
    <path
      fill="#58cf84"
      d="m13.687 6.634-.542-.542a.563.563 0 0 1-.112-.635l.324-.695a1.639 1.639 0 0 0-1.343-2.326l-.764-.066a.563.563 0 0 1-.494-.415l-.2-.741A1.639 1.639 0 0 0 8.034.298l-.628.44a.563.563 0 0 1-.644 0l-.628-.44a1.639 1.639 0 0 0-2.524.919l-.2.741a.563.563 0 0 1-.494.414l-.764.066A1.638 1.638 0 0 0 .811 4.762l.324.7a.563.563 0 0 1-.112.635l-.542.542a1.638 1.638 0 0 0 .466 2.644l.7.324a.562.562 0 0 1 .322.558l-.067.764a1.638 1.638 0 0 0 2.057 1.726l.741-.2a.563.563 0 0 1 .606.22l.44.628a1.641 1.641 0 0 0 2.689 0l.44-.629a.564.564 0 0 1 .606-.22l.741.2a1.639 1.639 0 0 0 2.057-1.726l-.067-.764a.563.563 0 0 1 .322-.558l.695-.324a1.638 1.638 0 0 0 .467-2.644Zm-3.622-1.048-3.59 3.59a.539.539 0 0 1-.762 0L3.914 7.381a.538.538 0 0 1 .761-.761l1.414 1.414 3.209-3.209a.539.539 0 0 1 .762.761Z"
      data-name="icons8-verify (1)"
    />
  </svg>
)
export default ConformedIcon
