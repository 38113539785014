import React from "react";
import RequestDetails from './requestDetails';
import { withBottombar } from "../../HOCs";

class RequestDetailsParent extends React.Component {
    render() {
        return <RequestDetails {...this?.props} />;
    }
}

const props = {
  top_bar: "title_bar",
}

export default withBottombar(RequestDetailsParent, props);
