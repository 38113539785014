import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import { useStyles } from './style'

export const ViewCard = ({
    viewData = {},
    t = () => false
}) => {
    const classes = useStyles()
    return (
        <Box>
            <Box>
                <Box className={classes.avatarImgBox} >
                    <Avatar className={classes.avatarImg} src={viewData?.avatar} variant="circular" />
                </Box>
                <Typography className={classes.name}>{viewData?.name}</Typography>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} className={classes.subtitles}>
                    {viewData?.jobTitle && <Typography className={classes.drawertitle}>{viewData?.jobTitle}</Typography>}
                    {viewData?.department && <Typography className={classes.department}>{viewData?.department}</Typography>}
                </Stack>
                <Typography className={classes.reportingname}>{t("Email Id")} : {viewData?.email ?? "-"}</Typography>
                <Typography className={classes.reportingname}>{t("Mobile No")} : {`${viewData?.mobile_no_country_code ?? ""} ${viewData?.mobile_no ?? "-"}`}</Typography>
                <Typography className={classes.reportingname}>{t("Business Phone")} : {viewData?.phone ?? "-"}</Typography>
                <Typography className={classes.reportingname}>{t("Reporting to")} : {viewData?.reportingTo}</Typography>
                <Stack direction={"row"} columnGap={1} justifyContent={"center"} alignItems={"center"} mt={2}>
                    <a
                        href={`mailto:${viewData?.email}`}
                        style={{ textDecorationLine: "none" }}
                    >
                        <MailIcon sx={{ color: "#bd2c2c", fontSize: "20px" }} />
                    </a>
                    <a
                        href={`tel:${viewData?.mobile_no_country_code ?? ""}${viewData?.mobile_no}`}
                        style={{ textDecorationLine: "none" }}
                        target="_blank" rel="noreferrer"
                    >
                        <PhoneIcon sx={{ color: "#bd2c2c", fontSize: "20px" }} />
                    </a>
                </Stack>
            </Box>
        </Box>
    )
}