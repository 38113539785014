import React from "react";
import { withBottombar } from "../../HOCs";
import PaymentScreen from "./paymentScreen";
class PaymentScreenParent extends React.Component {
    render() {
        return <PaymentScreen {...this?.props} />;
    }
}

const props = {
    top_bar: "title_bar",
  }

export default withBottombar(PaymentScreenParent, props);
