import * as React from "react"

const MyBookingSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} {...props}>
    <g data-name="Group 97035">
      <path data-name="Rectangle 53215" fill="none" d="M0 0h18v18H0z" />
      <path
        data-name="Path 9291"
        d="m13.179 2.032-1.393 1.467-1.394-1.467-1.393 1.467-1.394-1.467-1.393 1.467-1.394-1.467-1.393 1.467-1.394-1.467v11.552h11.148Z"
        fill="#90caf9"
      />
      <path
        data-name="Path 9292"
        d="M4.121 5.333h4.181v.733H4.121Zm5.226 0h1.742v.733H9.347ZM4.121 6.8h2.09v.733h-2.09Zm3.136 0h3.832v.733H7.257ZM4.121 8.267h1.394V9H4.121Zm2.439 0h2.09V9H6.56Zm3.136 0h1.394V9H9.695Z"
        fill="#1976d2"
      />
      <path
        data-name="Path 9293"
        d="M14.061 11.2H3.954l-.015 4.767h10.1a2.189 2.189 0 0 0 1.924-2.377 2.189 2.189 0 0 0-1.902-2.39Z"
        fill="#bbdefb"
      />
      <path
        data-name="Path 9294"
        d="M4.121 9.733h3.484v.733H4.121Zm4.181 0h2.787v.733H8.302Z"
        fill="#1976d2"
      />
      <path
        data-name="Path 9295"
        d="M3.955 11.2a2.189 2.189 0 0 0-1.924 2.377 2.189 2.189 0 0 0 1.908 2.39 2.189 2.189 0 0 0 1.924-2.377 2.189 2.189 0 0 0-1.908-2.39Z"
        fill="#42a5f5"
      />
      <path
        data-name="Path 9296"
        d="M3.95 12.3c-.411 0-.874.547-.874 1.281a1.541 1.541 0 0 0 .339 1 .746.746 0 0 0 .527.287c.41 0 .871-.547.874-1.281S4.363 12.3 3.951 12.3Z"
        fill="#1976d2"
      />
    </g>
  </svg>
)

export default MyBookingSvg
