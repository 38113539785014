import * as React from "react"

const CallerSupport = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} {...props}>
    <g data-name="Group 109112" transform="translate(-163 -100)">
      <circle
        data-name="Ellipse 129870"
        cx={25}
        cy={25}
        r={25}
        transform="translate(163 100)"
        fill="#d3ddf8"
      />
      <path
        data-name="Path 99712"
        d="m194.416 137.336-7.477-.069v-1.466l7.477.073a1.314 1.314 0 0 0 1.323-1.319v-2.419h1.466v2.419a2.784 2.784 0 0 1-2.789 2.781Z"
        fill="#5078E1"
      />
      <path
        data-name="Path 99713"
        d="M187.7 112c-6.435 0-11.7 4.451-11.7 9.892v6.045h2.6v-6.045c0-4.232 4.095-7.692 9.1-7.692s9.1 3.462 9.1 7.694v6.045h2.6v-6.047c0-5.441-5.265-9.892-11.7-9.892"
        fill="#5078E1"
      />
      <path
        data-name="Path 99714"
        d="M196.8 133.606h-2.6v-8.8h2.6a2.789 2.789 0 0 1 2.6 2.932v2.932a2.789 2.789 0 0 1-2.6 2.936m-18.2 0h2.6v-8.8h-2.6a2.789 2.789 0 0 0-2.6 2.932v2.932a2.789 2.789 0 0 0 2.6 2.936m11.7 2.932a1.4 1.4 0 0 0-1.3-1.47h-1.95a1.477 1.477 0 0 0 0 2.932H189a1.4 1.4 0 0 0 1.3-1.466Z"
        fill="#37474f"
      />
    </g>
  </svg>
)

export default CallerSupport
