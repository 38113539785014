import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8F8F8",
    // overflow: "hidden",
  },
  divider2: {
    color: "#E4E8EE",
    borderBottomWidth: "1px"
  },
  widgetsCard: {
    backgroundColor: "#FBE1BA",
    boxShadow: "0px 2px 6px #c1c1c1",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
  },
  text: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary,
  },
  amount: {
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.palette.secondary.main,
  },
  // total: {
  //   fontSize: "1rem",
  //   fontFamily: FontFamilySwitch().bold,
  // },

  bottomScreen: {
    backgroundColor: "white",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 8px 24px #1C1C1C40",
    [theme.breakpoints.up("sm")]: {
      margin: "0px 11px",
    },
  },
  paynow: {
    backgroundColor: "#091B29",
    color: "white",
    borderRadius: "4px",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    paddingTop: "2px",
    paddingRight: "3px",
    paddingLeft: "4px",
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center",
    cursor: "pointer",
  },
  arrow: {
    marginLeft: "8px",
    padding: "8px",
    backgroundColor: "#55BC7E",
    borderRadius: "4px",
    cursor: "pointer",
  },
  btn: {
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    width: "93px",
    height: "40px",
  },
  // content: {
  //   borderTopLeftRadius: "4px",
  //   borderTopRightRadius: "4px",
  //   boxShadow: "0px 3px 24px #00000014",
  //   padding: "16px 16px 0px 16px",
  //   backgroundColor: "white",
  // },
  bottombar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    left: "0px",
    right: "0px",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
  },
  contentBottom: {
    padding: "12px",
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
  },
  active: {
    fontSize: "0.75rem",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      width: "-webkit-fill-available",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "-webkit-fill-available",
    },
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    overflow: "hidden",
    backgroundColor: "transparent",
  },
  screenPadding: {
    overflow: "overlay",
    padding: "12px",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  cardsub: {
    color: "#703D00",
    fontSize: "0.75rem",
  },
  cardsub2: {
    color: theme.typography.color.primary,
    fontSize: "0.75rem",
  },
  cardtitle: {
    color: theme.typography.color.primary,
    fontSize: "1.25rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  dashboardCard: {
    padding: "12px",
    //backgroundImage: `url("/images/cardbgdashboard.png")`,
    //backgroundRepeat: "no-repeat",
    //backgroundSize: "100% 100%",
    backgroundColor: "#FBE1BA",
    boxShadow: "0px 2px 6px #c1c1c1",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
  },
  paymant: {
    backgroundColor: theme.palette.dashboard.primary,
    padding: "12px",
  },
  container: {
    padding: "16px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 6px #5C86CB2E",
    borderRadius: theme.palette.borderRadius2,
  },
  container2: {
    padding: "16px 16px 0px 16px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 6px #5C86CB2E",
    borderRadius: theme.palette.borderRadius2,
  },
  invoiceQuickPay: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.Tertiary,
    marginTop: "17px",
    marginBottom: "9px",
  },
  invoiceTitle: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
  },
}));
