import * as React from "react"

export const EllipseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    {...props}
  >
    <g data-name="Group 102742" transform="translate(6603.666 -19121.334)">
      <circle
        data-name="Ellipse 129438"
        cx={3.833}
        cy={3.833}
        r={3.833}
        transform="translate(-6599.833 19125.166)"
        fill={props?.color??"#e4e8ee"}
      />
      <g
        data-name="Ellipse 129439"
        transform="translate(-6603.666 19121.334)"
        fill="none"
        stroke={props?.color??"#e4e8ee"}
        strokeLinecap="round"
        // strokeDasharray="3 2"
      >
        <circle cx={7.666} cy={7.666} r={7.666} stroke="none" />
        <circle cx={7.666} cy={7.666} r={7.166} />
      </g>
    </g>
  </svg>
)


