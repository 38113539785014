import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0",
  },
  mainPadding: {
    padding: "16px"
  },
  filterBlock: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "4px 8px",
    boxShadow: "#4354721F 0px 3px 30px",
    border: "1px solid #4354721F",
    cursor: "pointer",
  },
  heading: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
  },
  listRoot: {
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    padding: "12px 15px",
  },
  card: {
    backgroundColor: "#F5F7FA",
    borderRadius: "8px",
    padding: "12px"
  },
  listRootForm: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "#5C86CB2E 0px 3px 30px",
    padding: "12px",
  },
  listRootForm2: {
    backgroundColor: "#ffffff",
    borderRadius: "0px 0px 12px 12px",
    boxShadow: "#5C86CB2E 0px 3px 30px",
    padding: "12px",
  },
  questions: {
    color: "#404E61",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    
  },
  checklabel: {
    color: "#404E61",
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
  },
  rating: {
    padding: "8px 16px",
    backgroundColor: "#F2F4F7",
    color: "#4E5A6B",
    fontSize: "0.75rem",
    "&:disabled": {
      color: "#ffffff",
      backgroundColor: "#A4B2C1"
    },
    [theme.breakpoints.down('sm')]: {
      padding: "6px 10px",
      minWidth: "40px"
    },
  },
  selectedRating: {
    padding: "8px 16px",
    backgroundColor: "#5078E1",
    color: "#FFFFFF",
    fontSize: "0.75rem",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:disabled": {
      color: "#ffffff",
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: "4px",
      // padding: "6px 10px",
      minWidth: "40px"
    },
  },
  bigRating: {
    padding: "8px 16px",
    backgroundColor: "#F2F4F7",
    color: "#4E5A6B",
    fontSize: "0.75rem",
    borderRadius: "4px",
    "&:disabled": {
      color: "#4E5A6B",
      backgroundColor: "#F2F4F7",
      borderRadius: "4px",
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: "4px",
      // padding: "6px 10px",
      minWidth: "40px"
    },
  },
  response: {
    // paddingLeft: "19px",
    // paddingRight: "19px",
    // marginBottom: "16px",
    maxWidth: "600px",
    overflowX: "scroll"
  },
  rating1: {
    padding: "8px 34px",
    backgroundColor: "#F2F4F7",
    textTransform: "capitalize",
    color: "#4E5A6B",
    fontSize: "0.75rem",
  },
  selectedRating1: {
    padding: "8px 34px",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    color: "#ffffff",
    fontSize: "0.75rem",
    "&:disabled": {
      color: "#ffffff",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  bigRating1: {
    padding: "8px 34px",
    backgroundColor: "#F2F4F7",
    textTransform: "capitalize",
    color: "#4E5A6B",
    fontSize: "0.75rem",
    "&:disabled": {
      color: "#ffffff",
      backgroundColor: "#A4B2C1"
    },
  },
  level: {
    color: "#98A0AC",
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "8px"
  },
  secondary: {
    padding: "11px 15px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#19274e",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#e6e7ec",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#e6e7ec",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  primary: {
    padding: "12px 16px",
    textAlign: "center",
    borderRadius: "8px",
    fontSize: "0.875rem",
    boxShadow: "none",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: "#5078E1",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#5078E1",
      opacity: 0.9,
      boxShadow: "none",
    },
  },
  btnroot: {
    background: "#fff",
    padding: "14px 14px",
  },
}));