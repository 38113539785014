import { makeStyles } from "@mui/styles";

export const useAddOnStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F2F2F2",
        padding: "0px",
    },
    addOnmenuHeadTxt: {
        color: "#091B29",
        fontSize: "1rem",
        fontWeight: "bold",
        padding: "12px 0",
        background: "#fff",
        marginLeft: "60px"
    },
    addOnmenuHead: {
        background: "#fff",
        boxShadow: "0px 2px 6px #00000014",
        "& div": {
            alignItems: "center",
            "& svg": {
                width: "18px",
                height: "18px",
                marginLeft: "20px",
                fill: "#091B29"
            }
        }
    },
    firstBox: {
        boxShadow: "0px 3px 30px #4250682E",
        background: "#fff",
        borderRadius: "4px",
    },
    unitName: {
        color: "#091B29",
        fontSize: "0.875rem",
        fontWeight: "600",
        marginBottom: "4px"

    },
    propertyName: {
        color: "#4E5A6B",
        fontSize: "0.75rem"
    },
    imgDiv: {
        display: "flex",
        alignItems: "center",
        "& img": {
            marginRight: "12px",
            width: "40px",
            height: "40px"
        }
    },
    changeTxt: {
        color: "#5078E1",
        fontSize: "0.75rem",
        fontWeight: "bold",
        cursor: "pointer"
    },
    serviceHead: {
        color: "#98A0AC",
        fontSize: "0.75rem",
        fontWeight: "semi-bold",
        padding: "6px 16px 8px 16px"
    },
    servicelist: {
        background: "#fff",
        borderRadius: "4px",
        padding: "8px 8px 0 8px",
        margin: "0 16px 6px 16px",
        boxShadow: "0px 3px 30px #4250682e"
    },
    Qty: {
        color: "#98A0AC",
        fontSize: "0.75rem"
    },
    amount: {
        color: "#091B29",
        fontSize: "0.75rem",
        fontWeight: "bold"
    },
    available: {
        background: "#F1F7FF",
        borderRadius: "4px",
        padding: "3px",
        "& p": {
            color: "#78B1FE",
            fontSize: "0.75rem"
        }
    },
    cardRight: {
        textAlign: "center"
    },
    imgBox: {

        "& img": {
            display: "flex",
            borderRadius: "4px"
        }
    },
    svgBox: {
        background: " #F2F4F7",
        padding: "8px",
        borderRadius: "4px",
        marginRight: "9px",
        "& svg": {
            display: "flex",
        }
    },
    drawerHead: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        margin: "12px auto",
        "& p": {
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: "#091B29"
        }
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            padding: "0px",
        },
    },
    closeIcon: {
        cursor: "pointer",
        "& svg": {
            fill: "#4E5A6B"
        }
    },
    drawerBox: {
        background: "#fff",
        borderRadius: "4px",
        border: "1px solid #E4E8EE"
    },
    unitId: {
        background: "#E4E8EE",
        marginLeft: "7px",
        padding: "3px",
        height: "100%",
        borderRadius: "4px",
        "& p": {
            fontSize: "0.75rem",
            color: "#4E5A6B"
        }
    },
    unitdetails: {
        display: "flex"
    },
    heightOverflow: {
        overflow: "auto",
        backgroundColor: "#F2F2F2",
        paddingBottom: "45px"
    }
}));