import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { LocalStorageKeys } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: "20%",
  },
}));

export const Home = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const onLogOut = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
    history.push(Routes.home);
  };

  return (
    <div className={classes.root}>
      <Typography variant="body1">Your are logged in!!!</Typography>
      <Button variant={"contained"} color={"primary"} onClick={onLogOut}>
        Logout
      </Button>
    </div>
  );
};
