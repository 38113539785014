export const entryType = [
    {
        label: "Single",
        value: "single",
        description: "This Pass can be used once during visit",
        start_date: true,
        end_date: true,
        start_date_placeholder: "Select Visit Date",
    },
    {
        label: "Multiple",
        value: "multiple",
        description: "This Pass can be used multiple times during the visit in specified date",
        start_date: true,
        end_date: true,
        start_date_placeholder: "Start Date",
        end_date_placeholder: "End Date",
    },
]

export const visitor_initial_state = {
    index: null,
    id: null,
    profile_image: "",
    name: "",
    mobile: "",
    mail: "",
    proof_type: "",
    proof_number: "",
    proof_images: [],
    is_deleted: false,
    is_edited: false,
    error: {
        profile_image: "",
        name: "",
        mobile: "",
        mail: "",
        proof_type: "",
        proof_number: "",
        proof_images: "",
    }
}

const current_date = new Date()

export const request_initial_state = {
    request_details: {
        unit: "",
        entry_type: {
            label: "Single",
            value: "single"
        },
        start_date: current_date,
        end_date: current_date,
        is_any_gate: true,
        gate: "",
        no_of_visitors: "1",
        error: {
            unit: "",
            entry_type: "",
            start_date: "",
            end_date: "",
            gate: "",
            no_of_visitors: "",
        }
    },
    visitor_details: []
};