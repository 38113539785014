import {
  Stack,
  Typography,
  Grid,
  Button,
  Divider,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCorrespondanceStyles } from "./style";
import { TextBox } from "../../components";

const DrawerComp = (props) => {
  const {t = () => false} = props;
  const classes = useCorrespondanceStyles();
  return (
    <>
      <Box>
        <Stack direction="row" className={classes.drawerHead}>
          <Typography>{t("Reply")}</Typography>
          <Box
            className={classes.closeIcon}
            onClick={() => props?.handleClose()}
          >
            <CloseIcon />
          </Box>
        </Stack>
      </Box>
      <Divider />
      <Grid container p={2}>
        <Grid item xs={12}>
          <TextBox
            multiline
            fullWidth
            rows={4}
            className={classes.rplyTxt}
            value={props?.rply?.txt}
            onChange={(e) => props?.handleChange(e.target.value)}
            id="outlined-basic"
            variant="outlined"
          />
          <Box height={"16px"} />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            className={classes.btn}
            onClick={(e) => props?.handleSubmitReply(props?.rply, e)}
            disabled={props?.isDisableBtn}
          >
            <Typography className={classes.btnTxt}>{t("Submit")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DrawerComp;
