import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  root: {
    // margin: "0px 0px 16px 0px",
    borderRadius: "8px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    backgroundColor: "#FFFFFF",
  },
  categoryTitle: {
    fontSize: "0.75rem",
    backgroundColor: "#F1E6FE",
    padding: "4px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5D427F",
    float: "right",
    marginRight: "5px",
  },

  typeTitle: {
    fontSize: "0.75rem",
    backgroundColor: "#F0F4FF",
    padding: "4px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5078E1",
  },
  topCard: {
    // padding: "12px",
    display: "flex",
  },
  bottomCard: {
    padding: "12px",
    // backgroundColor:"red",
  },

  subTitle1: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    textAlign: "left",
    textTransform: "capitalize",
  },
  subTitle2: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    textAlign: "left",
    textTransform: "capitalize",
  },

  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "4px",
    padding: "3px 10px 3px 10px",
  },
  tagTextStyle: {
    fontSize: "0.75rem",
    color: "#091B29",
    fontWeight: "bold",
    textAlign: "left",
  },
  extraDetailsStyle: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
  },
  horizontalDividerStyle: {
    backgroundColor: "#E4E8EE",
    height: "1px",
  },
  verticalDividerStyle: {
    backgroundColor: "#E4E8EE",
    width: "1px",
    height: "20px",
  },
  iconButtonStyle: {
    backgroundColor: "#F2F4F7",
    borderColor: "#E4E8EE",
    height: "32px",
    width: "32px",
  },

  total: {
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #c1c1c1",
    color: "black",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    height: "78vh",
  },
  active: {
    fontSize: "0.75rem",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "1rem",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  bottomTitle: {
    fontSize: "1.125rem",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  unitImg: {
    height: "250px",
    width: "100%",
    borderRadius: "4px",
    objectFit: "cotain",
  },
  titleroot2: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "8px",
    fontSize: "0.75rem",
    color: theme.typography.color.secondary,
  },
  phone: {
    borderRadius: "4px",
    border: "1px solid #c1c1c1",
  },
  bottomDetails: {
    fontSize: "0.875rem",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },
  bottomText: {
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    // width: "101px",
    padding: "4px",
    textAlign: "center",
    float: "right",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "0.75rem",
  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    // backgroundColor: "#071741",
    color: "white",
    width: "101px",
    // padding: "4px",
    textAlign: "center",
  },
  progress: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right"
  },
  approved: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right"
  },
  delete: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right",
  },
  cancel: {
    fontSize: "0.75rem",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right",
  },
  relative: {
    position: "relative",
  },
  bottomCardUnit: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "101px",
    padding: "4px",
    textAlign: "center",
  },
  unitRoot: {
    borderRadius: "8px",
    boxShadow: "0px 3px 30px #4354721F",
    backgroundColor: "#fff",
    cursor: "pointer",
  },
  cardDetails: {
    padding: "13px 14px 12px 14px",
    borderBottom: "1px solid #E4E8EE",
  },
  cardImage: {
    height: "70px",
    width: "70px",
    objectFit: "cover",
    borderRadius: "8px",
    marginInlineEnd: "16px"
  },
  title: {
    fontSize: "0.875rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
    textTransform: "capitalize",
  },
  subTitle: {
    fontSize: "0.75rem",
    color: "#4E5A6B",
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().regular,
  },
  rightIcon: {
    borderRadius: "50%",
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    width: "36px",
    height: "36px",
    padding: "9px 12px 9px 9px",
    alignItems: "center",
    "& svg": {
      width: "9px",
      height: "18px",
    },
    cardTap: {
      padding: "13px 14px",
    },
  },
  typeCard: {
    position: "absolute",
    bottom: "0",
    width: "70px",
    backgroundColor: "#F1E6FE",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    padding: "2px 4px",
  },
  typeName: {
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().bold,
    color: "#5D427F",
    textTransform: "uppercase",
    textAlign: "center",
  },
  cardTap: {
    padding: "8px 14px",
  },
  iconText: {
    display: "flex",
    gap:"8px",
  },
  iconName:{
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
  },
  iconName1:{
    fontSize: "0.75rem",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#4E5A6B",
    maxWidth: "100px"
  }
}));
