import gql from "graphql-tag";

export const GETALL = `query($quotation_no: Int){
  quotation(where:{id:{eq:$quotation_id}}){
    id
    quotation_no
    quote_start_date
        lease_period
        lease_duration
        lease_end_date
        lease_start_date
        lease_payment_period
        quotation_expiry_date
        grace_period
         description
        total_tax
        quote_status
        updated_at
        created_at
        user:created_by{
          first_name
          last_name
        }
        total_amount
  asset:quotation_assets {
      id
      url
    }
    currency_master{
      id
      name
      symbol
    }
    quotation_status{
     remarks
      quotation_status_id
          created_at
    }

  }
  invoice(where:{quotation_id:{eq:$quotation_id}}){
    id
    invoice_no
    invoice_master{
      type
    }
    payment_due_date
    currency_master{
      name
      symbol
    }
    company{
      id
      currency_id
    }
    payment_status
    invoice_due_amount
     description
  }
}
  `;
export const GETALLSTATUS = `
query{
 quotation_status_master{
  quotation_status
  id
}
}`;

export const GETCURRENCY = `
query {
  currency_master {
    id
    name
    symbol
}
}`

export const INVOICE = `
query($id:string){
  invoice(where:{agreement_id:{eq:$id}}){
    id
    invoice_no
    invoice_total_tax_amount
    invoice_master{
      type
    }
    payment_due_date
    currency_master{
      name
      symbol
    }
    company{
      id
      currency_id
    }
    payment_status
    invoice_due_amount
     description
  }
}
`;
export const INVOICEQ = `
query($quotation_id:string){
  invoice(where:{quotation_id:{eq:$quotation_id}}){
   id
    invoice_no
    invoice_due_amount
    currency_master{
      id
      name
      symbol
    }
    description
    payment_status
    payment_due_date
  }
}
`;
export const USER = `
query($id: String){
  user_profiles(where:{id:{eq:$id}}){
    first_name
    last_name
  }
}`;
export const GETCONTACT = `
query($id:String){
  user_profiles(where:{user_id:{eq:$id}}){
    user_id
    contact_id
  }
}`;

export const GETAGGREMENT = `
query($id:String){
  agreement(where:{contact_id:{eq:$id}})
  {
    id
    contact_id
    account_id
    agreement_no
      agreement_units{

    unit{

      id
      name


    }
  }

  }
}`;
export const GETAGGREMENTAGGREENT = `
query($id:String){
  agreement(where:{account_id:{in:$id}})
  {
    id
    contact_id
    account_id
    agreement_no
      agreement_units{

    unit{

      id
      name


    }
  }

  }
}
`;
export const AGREEMENTDATA = `
query($id:String){
  agreementByID(id:$id){
    lease_end_date
    lease_start_date
      agreement_no
      id
  }
}`;

export const PAYMENTHISTORY = `
query($id: String) {
  payment_history(
    where: { agreement_id: { eq: $id } }
    order_by: { payment_date: asc }
  ) {
    id
    payment_date
    payment_mode
    payment_amount
    origin
    transaction_reference_no
    payment_response
    reciepts {
      id
      reciepts_no
    }
  }
}
`;
export let PAYMENTHISTORYFILTER = (id, responses, responsess) => gql`
query{
  payment_history ${id || responses || responsess
    ? `(where:{
    ${id ? `agreement_id:{eq:"${id}"}` : ``}
    ${responses ? `payment_response:{eq:"${responses}"}` : ``}
    ${responsess ? `payment_response:{eq:"${responsess}"}` : ``}
  })`
    : ``
  }{
     id
     payment_date
    payment_mode
    payment_amount
    origin
    transaction_reference_no
    payment_response
    reciepts {
      id
      reciepts_no
    }
  }
}
`;
export let PAYMENTFILTER = (agreementId, paymentDate, paymentResponse) => {
  let _status = ``;
  for (let i = 0; i < paymentResponse.length; i++) {
    _status = `${_status} "${paymentResponse[i]}" `;
  }
  return gql`
query {
  payment_history
  ${agreementId || paymentDate !== "" || paymentResponse
      ? `(where: {
    ${agreementId ? `agreement_id:{eq:"${agreementId}"}` : ``}
    ${paymentDate !== ""
        ? `payment_date: { greater_or_equals: "${paymentDate}" }`
        : ``
      }
    ${_status !== "" ? `payment_response:{in: [${_status}]}` : ``}
    })`
      : ``
    }
 {
    payment_amount
    currency_master{
      id
      name
      symbol
    }
    payment_date
    payment_mode
    origin
    agreement_id
    transaction_reference_no
    payment_response
    reciepts {
      id
      reciepts_no
    }
  }
}
`;
};
export let PAYMENTFILTERS = (agreementID, status) => {
  let _status = ``;
  for (let i = 0; i < status.length; i++) {
    _status = `${_status} "${status[i]}" `;
  }

  return gql`
  query{
    payment_history ${_status !== ""
      ? `(where:{

      ${_status !== "" ? `payment_response:{in: [${_status}]}` : ``}
    })`
      : ``
    }{
      payment_amount
    payment_date
    payment_mode
    origin
    agreement_id
    transaction_reference_no
    payment_response
    reciepts {
      id
      reciepts_no
    }
    }
  }
`;
};

export const GETALLINVOICE = `
query($id:string){
  invoice(where:{agreement_id:{eq:$id}}){
    id
    invoice_no
    invoice_master{
      type
    }
    payment_due_date
    currency_master{
      name
      symbol
    }
    company{
      id
      currency_id
    }
    payment_status
    invoice_due_amount
     description
  }
}
`;

export let FILTERINVOICE = (agreement, status) => {
  let _agreement = ``;
  for (let i = 0; i < agreement.length; i++) {
    _agreement = `${_agreement} "${agreement[i]}" `;
  }

  let _status = ``;
  for (let i = 0; i < status.length; i++) {
    _status = `${_status} "${status[i]}" `;
  }

  return gql`
  query{
    invoice ${_agreement !== "" || _status !== ""
      ? `(where:{
      ${_agreement !== ""
        ? `agreement: { agreement_no:{in: [${_agreement}]}}`
        : ``
      }
      ${_status !== "" ? `payment_status:{in: [${_status}]}` : ``}
    })`
      : ``
    }{
      invoice_no
      id
      invoice_no
      payment_due_date
      currency_master{
        name
        symbol
      }
      payment_status
      invoice_due_amount
       description
    }
  }
`;
};

export let ALLINVOICE = (id, due) => gql`
query{
  invoice ${id || due
    ? `(where:{
    ${id ? `contact_id:{eq:"${id}"}` : ``}
    ${due ? `payment_status:{eq:"${due}"}` : ``}
  })`
    : ``
  }{
    invoice_no
    id
    invoice_no
    payment_due_date
    payment_status
    invoice_due_amount
     description
  }
}
`;

export const GETPROFILEDETAILS = `
query Profile($userID: String, $roleID: String) {
  user_profiles(where: { id: { eq: $userID } }, is_active: true) {
    id
    first_name
    mobile_no_country_code
    last_name
    contact_id
    contactByID {
      id
      street_1
      street_2
      street_3
      district
      country
      state
      zipcode
      latitude
      longitude
      state
    }
    image_url
    email_id
    mobile_no
    mobile_no_country_code
    alternative_mobile_no
    alternative_mobile_no_country_code
    alternative_email_id
  }
  role: client_user_roles(
    where: {
      user_profile_id: { eq: $userID }
      roles_id: { eq: $roleID }
      is_active: { eq: true }
    }
  ) {
    id
    user_profile_id
    client_role_no
    is_active
  }
}

`;

export const GETCONTACTDETAILS = `
query($contactId: ID) {
  contact(where: { id: { eq: $contactId } }) {
    id
    street_1
    street_2
    street_3
    district
    city
    country
    state
    zipcode
    latitude
    longitude
  }
}
`;

export const GETUNITHANDBOOK = `
query($unitId: String, $assets_master_id: Int) {
  asset: unit_assets(
    where: {unit_id: {eq: $unitId}, is_active: true, asset_type: $assets_master_id}
  ) {
    id
    url
    asset_type
  }
}
`;

export const GETPROFILEIMAGE = `
query($referenceId: String) {
  assets(
    where: {
      reference_id: { eq: $referenceId }
      priority: { eq: 1 }
      assets_master: { id: { eq: 2 } }
      is_active: true
    }
  ) {
    id
    reference_id
    url
  }
}
`;

export const GETCLIENTUSERROLE = `
query($userProfileId: String) {
  client_user_roles(where: { user_profile_id: { eq: $userProfileId } }) {
    id
    user_profile_id
    client_role_no
  }
}
`;

export const CHECKAGREEMENTREQUSET = `
query($id:String){
  agreement_request(where:{agreement_id:{eq:$id}}){
    id
    agreement_id
    tentative_termination_date
    remarks
    contact_id
    is_active
    created_by
   updated_by
   created_at
   updated_at
  request_purpose
  }
}`;

export const GETAGREEMENTPDF = `
query($id:String){
  assets(where:{assets_master_type:4 reference_id:{eq:$id} priority:{eq:1}})
{
  url
}
}`;
export const MAINTANANCE = `
query{
 maintenance_status_master{
    type
    id
  }
}
`;
export const GENERAL = `
query{
  general_status_master{
    type
    id
  }
}
`;
export const FILTERREQUSET = `
query($id:String){
  agreement(where:{contact_id:{eq:$id}})
  {
    id
    agreement_no
  agreement_units{
    unit{

      id
      name


    }
  }

  }
}`;

export const GETSINGLEGENERALREQUESTBYGENERALREQUESTNO = `
query($generalRequestNo: ID) {
  general_request(where: { id: { eq: $generalRequestNo } }) {
    id
    general_request_no
    actual_expense
    general_category_master {
      id
     type:name
    }
    general_sub_category_master {
      id
      type:name
    }
    user_profilesByID{
      id,
      first_name
    }
    subject
    description
    problem_since
    raised_on
    costing_status
    is_acknowleged
    visiting_charge_request{
      amount
      notes
      costing_status
      created_at
      created_by
      currency_id
      currency_masterByID{
        name
        symbol
        id
        code
      }
    }
    general_request_units {
      id
      unit {
        name
        unit_typeByID {
          name
      }
      }
      propertyByID{
        id,
        name
      }
    }
    preferred_time
    contact_name
    contact_mobile
    contact_alternative_mobile
    general_status  (
      order_by: { updated_at: desc }
    ) {
      id
      created_at
      remarks
      closed_on
      type
      closed_byByID{
        first_name
 last_name
}
      general_status_master {
        id
      type:name
      }
      notes
    }
  }
}`

export const GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO = `
query($maintenanceRequestNo: ID, $referenceId: String) {
  maintenance_request(
    where: { id: { eq: $maintenanceRequestNo } }
  ) {
    id
    maintenance_request_no
    actual_expense
    slot_id
    maintenance_category_master {
      id
      type:name
    }
    maintenance_sub_category_master {
      id
     type:name
    }
    subject
    description
    problem_since
    costing_status
    is_acknowleged
    is_frozen
    visiting_charge_request{
      amount
      costing_status
      notes
      created_at
      created_by
      currency_id
      currency_masterByID{
        name
        symbol
        id
        code
      }
    }
    location{
      id
      name
    }
    inspection_item_mapping{
      id
      name
      manufacturer{
        id
        name
      }
      model_number
      serial_number
      item_condition
      assets
   
    }
    last_service:maintenance_request(limit:1,orderBy:desc){
      id
     created_at
      maintenance_request_no
    }
    raised_on
    created_by
    user_profilesByID{
      id,first_name
    }
    maintenance_request_units {
      id
      unit {
        name
        unit_no
        unit_typeByID {
            name
        }
      }
      property_id,
       propertyByID{
      id,name
    }
    }
    preferred_time
    contact_name
    contact_mobile
    contact_alternative_mobile
    maintenance_status(
      order_by: { updated_at: desc }
    )  {
      id
      created_at
      remarks
       closed_on
       closed_byByID{
        first_name
 last_name
}
       type
      maintenance_status_master {
        id
        type:name
      }
      notes
    }
  }
  assets:maintenance_request_assets(where: { maintenance_request_id: { eq: $referenceId } }) {
    id
    priority
    url
  }
}`

export const DASHBOARDWIDGET = `
query($id:String){
  invoice(where:{agreement_id:{in:$id} payment_status:{eq:"due"}})
  {
     id
    invoice_no
    payment_due_date
        invoice_master{
      type
    }
    currency_master{
      name
      symbol
    }
    payment_status
    invoice_due_amount
    description
    count_invoice_total_amount
     company{
      id
      currency_id
    }
    invoice_total_tax_amount
  }
}`;

// export const GETAGREEMENTANDGENERALCATEGORY = () => {
//   return gql`
//     query ($userID: String) {
//       user_profiles(
//         where: { user_id: { eq: $userID } }
//         is_active: true
//         offset: $offset
//         limit: $limit
//       ) {
//         contact {
//           agreement {
//             id
//             agreement_no
//           }
//         }
//       }
//       general_category_master{
//         value: id
//         label: name
//       }
//     }
//   `;
// };

export const GETAGREEMENTANDGENERALCATEGORY = (id) => {
  return gql`
    query ($userID: String) {
      count: 
        general_category_master(where: { 
          name: { iregex: $search } 
          is_active: true
          is_delete:{eq:false}
          ${id?.length > 0
      ? `client: { eq:"${id}" }`
      : ""
    }
        })
         {
          count: count_id
        }

      user_profiles(
        where: { user_id: { eq: $userID } }
        is_active: true
        offset: $offset
        limit: $limit
      ) {
        contact {
          agreement {
            id
            agreement_no
          }
        }
      }
      general_category_master(
        where: { 
          name: { iregex: $search }  
          is_active: true
          is_delete:false
          ${id?.length > 0
      ? `client: { eq:"${id}" }`
      : ""
    }
        }
        ) {
        value: id
        label: name
      }
    }
  `;
};
export const GETAGREEMENTANDMAINTANCECATEGORY = () => {
  return gql`
    query ($userID: String) {
      count: user_profiles(
        where: { user_id: { eq: $userID } }
        is_active: true
      ) {
        maintenance_category_master(where: { name: { iregex: $search }  }) {
          count: count_id
        }
      }
      maintenance_category_master(where: {
        name: { iregex: $search } 
        offset: $offset
        limit: $limit
      }) {
        value: id
        label: name
      }
    }
  `;
};
export const GETUNITSFROMAGREEMENTID = `
query($agreementId: String) {
  agreement_units(where: { agreement_id: { eq: $agreementId } }) {
    unit {
      id
      name
    }
  }
}
`;

// export const GETGENERALSUBCATEGORYMASTERFROMGENERALCATEGORYMASTERID = `
// query($general_category_id: Int) {
//   general_sub_category_master(
//     where: { general_category_id: { eq: $general_category_id } }
//     is_active: true
//         offset: $offset
//         limit: $limit
//   ) {
//     id
//    type:name
//   }
// }
// `;

export const GETGENERALSUBCATEGORYMASTERFROMGENERALCATEGORYMASTERID = () => {
  return gql`
    query ($userID: Int) {
      count: general_sub_category_master(
        where: { 
          general_category_id: { eq: $general_category_id } 
          is_active: true
          is_delete:false
        }
       
      ) {
        count: count_id
      }
      general_sub_category_master(
        where: { 
          general_category_id: { eq: $general_category_id } 
          name: { iregex:$search } 
          is_active: true
          is_delete:false
        }
       
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
      }
    }
  `;
};
export const GETMAINTANANCESUBCATEGORYMASTERFROMGENERALCATEGORYMASTERID = (id) => {
  return gql`
    query ($userID: Int) {
      count: maintenance_sub_category_master(
        where: { 
          maintenance_category_id: { eq: $maintanace_category_id } 
          is_active: true
          is_delete:false
          ${id?.length > 0
      ? `client: { eq:"${id}" }`
      : ""
    }
        }
      ) {
        count: count_id
      }
      maintenance_sub_category_master(
        where: { 
          maintenance_category_id: { eq: $maintanace_category_id  } 
          name: { iregex: $search }
          is_active: true
          is_delete:false
          ${id?.length > 0
      ? `client: { eq:"${id}" }`
      : ""
    }
        }
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
      }
    }
  `;
};
export const GETMAINTANANCECATEGORY = (id) => {
  return gql`
    query ($userID: Int) {
      count: maintenance_category_master(
        where: { 
          name: { iregex: $search }
          is_delete:false
          is_active: true
          ${id?.length > 0
      ? `client: { eq:"${id}" }`
      : ""
    }
         }
        ) {
        count: count_id
      }
      maintenance_category_master(
        where: { 
          name: { iregex: $search } 
          is_delete:false 
        is_active: true
        ${id?.length > 0
      ? `client: { eq:"${id}" }`
      : ""
    }
       }
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
      }
    }
  `;
};
export const GETSUBMAINTANANCE = () => {
  return gql`
    query ($userID: Int) {
      count: maintanace_sub_category_master(
        where: { maintanace_category_id: { eq: $maintanace_category_id } }
        is_active: true 
        is_delete:false 
      ) {
        maintanace_sub_category_master(where: { name: { iregex: "" } }) {
          count: count_id
        }
      }
      maintanace_sub_category_master(
        where: { maintanace_category_id: { eq: $maintanace_category_id } }
        is_active: true
        is_delete:false 
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
      }
    }
  `;
};
export const GETCONTACTFROMUSERPROFILEBYUSERID = `
query($userId: String) {
  user_profiles(where: { user_id: { eq: $userId } }) {
    contact_id
  }
}
`;

export const GETAGREEMENTBYCONTACTID = `
query ($contactId:String,$leaseEndDate:String){
  agreement(
    where: {
      contact_id: { eq: $contactId }
      lease_end_date: { lt: $leaseEndDate }
      client:$client
    }
  ) {
    id
    agreement_no
    lease_start_date
    lease_end_date
  }
}
`;

export let GETUNITSFOREACHAGREEMENTFROMAGREEMENTUNITBYAGREEMENTID = (
  agreementList
) => gql`
query {
    ${agreementList.map((item, index) => {
  return `agreement_units_${index}:agreement_units(
    where: { agreement_id: { eq: "${item.id}" } }
  ) {
    id
    unit {
      id
      name
    }
  }`;
})}
  }
`;

export const TEMPQUERY1 = `
query {
  property_1: property(
    where: { unit: { name: { in: ["Zenith B-203", "Zenith C - 303"] } } }
  ) {
    id
    name
    unit {
      id
      name
    }
  }
}
`;

export let GETPROPERTYBYUNITNAME = (unitList) => {
  let _unitNameList;
  try {
    _unitNameList = unitList.map((item, index, array) => {
      let _unn = ``;
      if (item.length === 0) {
        _unn = null;
      } else {
        for (let i = 0; i < item.length; i++) {
          _unn = `${_unn} "${item[i]?.unit?.name}" `;
        }
      }
      return _unn;
    });
  } catch (err) { }

  return gql`
query {
    ${_unitNameList.map((item, index) => {
    return `property_${index}:property(
      where: { unit: { name: { in: [${item}] } } }
      ) {
        id
        name
        unit {
          id
          name
        }
      }`;
  })}
  }
`;
};

export let GETAGREEMENTDOCFROMASSETBYAGREEMENTID = (agreementList) => gql`
query {
    ${agreementList.map((item, index) => {
  return `assets_${index}:assets(
    where: {
      reference_id: { eq: "${item.id}" }
      assets_master_type: { id: { eq: 4 } }
    }
  ) {
    reference_id
    url
    assets_master_type {
      id
       type
    }
  }`;
})}
  }
`;

export const GET_MAINTENANCE_CATEGORY_MASTER = `
query {
  maintenance_category_master(where:{is_active:{eq:true}}) {
    id
    type:name
  }
}
`;

export const GET_MAINTENANCE_SUB_CATEGORY_MASTER_BY_MAINTENANCE_CATEGORY_ID = `
query($maintenanceCategoryId: Int) {
  maintenance_sub_category_master(
    where: { maintenance_category_id: { eq: $maintenanceCategoryId } }
  ) {
    id
     type:name
  }
}
`;

export const GET_DETAILS_FOR_PROFILE_SCREEN = `
query getDetailsForProfileScreen(
  $referenceID: String
  $userProfileID: String
  $contactID: ID
) {
  assets(
    where: {
      reference_id: { eq: $referenceID }

      assets_master: { id: { eq: 2 } }
      is_active: true
    }
  ) {
    id
    reference_id
    url
    priority
    assets_master_type
    is_active
  }
  client_user_roles(
    where: { user_profile_id: { eq: $userProfileID }, is_active: { eq: true } }
  ) {
    id
    user_profile_id
    client_role_no
    is_active
  }
  contact(where: { id: { eq: $contactID }, is_active: { eq: true } }) {
    id
    street_1
    street_2
    street_3
    district
    city
    country
    state
    zipcode
    latitude
    longitude
    is_active
  }
}
`;

export const GET_PAYMENT_RECEIPT_BY_RECEIPT_ID = `
query($referenceID: String) {
  assets(
    where: {
      reference_id: { eq: $referenceID }
      assets_master: { id: { eq: 4 } }
      priority: { eq: 1 }
      is_active: { eq: true }
    }
  ) {
    id
    reference_id
    assets_master_type
    priority
    is_active
    url
  }
}`;

// dropdown
export const GET_CONTACT_DROPDOWN_VIEW = () => {
  return gql`
query{
  profession_master(
    where:{is_active:{eq:true}}
    ){
    value:id
    label:name
  }
}
`}
export const GET_CONTACT_DROPDOWN = () => {
  return gql`
    query {
      count: profession_master(
        where: { is_active: { eq: true }, name: { iregex: $search } }
      ) {
        count: count_id
      }
      profession_master(
        where: { is_active: { eq: true }, name: { iregex: $search } }
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
      }
    }
  `;
};
export const GET_PROPERTY_BY_UNITID_VIEW = `
query($unit_id) {
  unit(where: {id:{in:$unit_id},is_active:{eq:true}}) {
   id
   name
   company_id
    property {
      id
      name
    }
  }
}
 `;
export const GET_PROPERTY_BY_UNITID = () => {
  return gql`
    query GET_PROPERTY_BY_UNITID {
      count: unit(
        where: {
          name: { in: $unit_id }
          is_active: { eq: true }
          name: { iregex: $search }
        }
      ) {
        count: count_id
      }
      unit(
        where: {
          name: { in: $unit_id }
          is_active: { eq: true }
          name: { iregex: $search }
        }
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
        property {
          id
          name
        }
      }
    }
  `;
};

export const GET_UNIT_FOR_ADDING_VEHICLE = () => {
  return gql`query GET_UNIT_FOR_ADDING_VEHICLE(
    $agreementIDs: [String]
    $unitCategoryID: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    count: agreement_units(
      where: {
        agreement_id: { in: $agreementIDs }
        unit: { unit_category_id: { eq: $unitCategoryID } }
        or: {
          unit: { name: { iregex: $search } }
          unit: { unit_no: { iregex: $search } }
        }
      }
    ) {
      count: count_id
      __typename
    }
    agreement_units(
      where: {
        agreement_id: { in: $agreementIDs }
        unit: { unit_category_id: { eq: $unitCategoryID } }
        or: {
          unit: { name: { iregex: $search } }
          unit: { unit_no: { iregex: $search } }
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      agreement_id
      agreementByID {
        id
        lease_start_date
        lease_end_date
      }
      unit_id
      unitByID {
        id
        name
        unit_no
        property_id
        propertyByID {
          id
          company_id
        }
      }
    }
  }`;
};

export const GET_PARKING_SPACE_FOR_FOUR_WHEELER = () => {
  return gql`query GET_VEHICLES {
    parking_slot(
      where: {
        is_active: { eq: true }
        agreement_id: { in: $agreementIDs }
        parking_area_id:{ is_null: true }
        parking_type: { eq: "Reserved" }
      }
      offset: 0
      limit: 10000
    ) {
      id
      parking_id
      agreement_unit_id
      parking_masterByID {
        id
        parking_no
        parking_status
      }
    }
  }`;
};

export const GET_PARKING_SPACE_FOR_TWO_WHEELER_BI_CYCLE = () => {
  return gql`
  query GET_PARKING_ {
    parking_area(
      where: { is_active: { eq: true }, property_id: { eq: $propertyID }, parking_area_type: { eq: $parkingAreaType } }
      offset: 0
      limit: 10000
    ) {
      value: id
      name: vehicle_parking_area
      is_active
    }
  }`;
};




// details of contact
export const GET_DETAILS_OF_CONTACT = `
  query($ID: String){
    community_contacts(where: { id: { eq: $ID }, is_active: { eq: true } }){
      id
      name
      contact_number
      country_code
      gender
      profession_id
    profession_master{
        name
        id
      }
      is_share
      agreement_units{
        id
    }
     unit{
        id
        name
        property_id
        property{
          id
          name
      }
      }
    }
  }
  `;
export const GET_THREAD = `
  query($threadId: String) {
    community_discussion(
      where: { id: { eq: $threadId }, is_active: { eq: true } }
    ) {
      id
      title
      description
      created_at
      assets
    created_by {
        id
        first_name
        last_name
        get_assets_url_id:image_url
      }


    property {
        name
        id
      }
    unit {
        name
        id
        unit_no
      }
      community_discussion_comments(where: { is_active: { eq: true } }) {
        id
        comment
        created_by
        created_at
      unit {
          unit_no
          name
        }
      user_profiles {
          first_name
          last_name
          image_url
        }
      }
      community_discussion_reactions(where: { is_active: { eq: true } }) {
        id
        reactions
        created_by
        created_at
      user_profiles {
          first_name
          last_name
        }
      }
    }
  }
  `;

export const GET_GATE_VIEW = `
query GET_GATES{
    access_gates_master(
      where: {
      property_id: { eq: $property_id }
      is_active: { eq: true }
    }
    ) {
      value: id
      label: name
    company {
        currency_id
      }
    }
  } `


export const GET_GATES = () => {
  return gql`
    query GET_GATES{
    count: access_gates_master(
      where: {
      property_id: { eq: $property_id }
          is_active: { eq: true }
          name: { iregex: $search }
    }
    ) {
      count: count_id
    }
    access_gates_master(
      where: {
      property_id: { eq: $property_id }
          is_active: { eq: true }
          name: { iregex: $search }
    }
        offset: $offset
        limit: $limit
    ) {
      value: id
      label: name
        company {
        currency_id
      }
    }
  }
  `;
};
export const GET_PROOF = `
query GET_PROOF($client: String, $proof_type: String) {
  Identification_master(
    where: {
      proof_type: { eq: $proof_type }
      is_active: { eq: true }
      client: { eq: $client }
    }
  ) {
    value: id
    label: type
  }
}`;

export const GET_IDENTIFICATION_MASTER = () => {
  return gql`
query GET_IDENTIFICATION_MASTER($client: String) {
  Identification_master(where: { client: { eq: $client } is_active: true }) {
    value: id
    label: type
  }
}
`
}
export const CANCEL_WORKER_PASS = `
  mutation($Id: ID, $data: security_requestInput) {
    security_request(
      where: { id: { eq: $Id } }
    update: $data

    ) {
      id

    }
  } `;
export const HELPER_DETAILS = `
  query($id: [String], $search: String, $offset: Int, $limit: Int) {
    domestic_helper_master(
      where: {
      property_id: { in: $id }
      is_active: { eq: true }
      is_delete: { eq: false }
      name: { iregex: $search }
    }
    offset: $offset
    limit: $limit
    ) {
      name
      id
      helper_image
    profession_master {
        name
      }
    }
  }


  `;
export const GET_DOMESTIC_DETAILS = `
  query($domesticHelperRequestID: ID) {
    domestic_helper_request(where: { id: { eq: $domesticHelperRequestID } }) {
      id
      from 
      to
      request_status
      reference_id
      remarks
    domestic_helper_master {
        id
        name
        qr_code
      profession_master {
          id
          name
        }
      }
    unit {
        id
        name
      }
    property{
        id
        name
      }
      requested_at: created_at
      requested_by: created_byByID {
        first_name
        last_name
      }
      approved_at: updated_at
      approved_by: approved_byByID {
        first_name
        last_name
      }
    }
  }
  `;
export const GET_CONTACT_PROPERTY = `
  query($id: String, $offset: Int, $limit: Int) {
    emergency_master(
      where: { property_id: { eq: $id }, 
      is_active: { eq: true }
      is_delete: { eq: false } 
      client:{eq:$client}
    }
    order_by: { created_at: desc }
    offset: $offset
    limit: $limit
    ) {
      id
      name
      country_code
      contact_number
      profession: department
    }
  }


  `;

export const GET_UNITS_BY_ID = () => {
  return gql`
    query GET_UNITS_BY_ID {
    count: unit(where: { id: { in: $id }, name: { iregex: $search },is_active:true }) {
      count: count_id
    }
    unit(
      where: { id: { in: $id }, name: { iregex: $search },is_active:true }
        offset: $offset
        limit: $limit

    ) {
      value: id
      label: name
        property {
        value: id
          company {
          value: id
        }
      }
    }
  }
  `;
};

export const GET_UNITS_BY_AGREEMENT_ID = `
  query($id: String){
    agreement_units(where: { agreement_id: { in: $id } }
      offset: $offset
      limit: $limit
      )
      {
        agreement_id:id
        unit{
      value: id
      label: name
      unit_no
      name
     property{
        value: id
        name
      company{
          value: id
        }
      }
      }
    }
  } `;

export let GET_PROPERTY_BASED_ON_COMPANY = (
  offset,
  limit,
  searchText,
  id = []
) => gql`
query GET_PROPERTY_BASED_ON_COMPANY{
    count: unit(
      where: {
      id: { in: [${id}] }
      name: { iregex: "${searchText}" }
      is_active: { eq: true }
    }
    ) {
      count: count_id
    }
    unit(
      where: {
      id: { in: [${id}] }
        name: { iregex: "${searchText}" }
        is_active: { eq: true }
    }
      offset: ${offset}
      limit: ${limit}
    ){
      label: name
      value: id
    }
  }
  `;

// export let GET_UNITS_BY_ID = (
//   offset,
//   limit,
//   searchText,
// ) => {
//   return gql`
//   query GET_UNITS_BY_ID{
//     count: unit(
//       where:{
//         id:{in:$id}
//         name:{iregex:"${searchText}"}
//         is_active:{eq:true}
//       }
//     ) {
//       count:count_id
//     }
//     unit(
//       where:{
//         id:{in:$id}
//         name:{iregex:"${searchText}"}
//         is_active:{eq:true}
//       }
//       offset:${offset}
//       limit:${limit}
//     ){
//         label:name
//         value:id
//       }
//   }`;
// }

export const GET_NOTIFICATION = `
query($id: Int, $type: String) {
  notifications(
    where: { company_id: { in: $id }, is_active: true }
    limit: 1000
    offset: 0
  ) {
    id
    title
    description
    notifications_type
    content_type
    triggered_by
    created_at
    user_profilesByID {
      first_name
      last_name
    }
    get_assets_url_id
  }
}
`;

export const GET_ANNOUNCEMENT = `
query($id: Int, $type: String) {
  notifications(
    where: {
      company_id: { eq: $id }
      content_type: { eq: $type }
      is_active: true
    }
  ) {
    id
    title
    description
    notifications_type
    content_type
    triggered_by
    created_at
    user_profilesByID {
      first_name
      last_name
    }
  }
}
`;
export const GET_ANNOUNCEMENT_BY_ID = `
query($id: ID) {
  notifications(where: { id: { eq: $id } }) {
    id
    title
    description
    notifications_type
    content_type
    triggered_by
    created_at
    category
    created_by {
      id
      first_name
      last_name
      get_assets_url_id
    }
  }
}`;
export const GET_COMPANY = `
query($id:String){
  company_master(where:{contact_id:$id}){
    id
    name
  }
}`;

export const GET_AGREEMENTUNITACESS_DETAILS = `
query($id:String){
  agreement_access(where:{id:{eq:$id}}){
    account{
      id
      account_no
    }
    unit{
      id
      unit_no
      name
    }
     agreement{
      id
    }
    name
    mobile_no
    email_id
    request_status
    created_at
    approved_on
    request_type
    remarks
  }
}
`;
export const GETBANKDETAILS = `
query($compayId:ID) {
  company_master(where:{id:{in:$compayId}}){
    cash_collection_office
    iban
    bank_swift_code:bank_routing_code
    financial_system_code
    primary_bank_name
    cheque_name
    is_gateway
  }
}
`;
//check mobile exist query

export const CHECK_MOBILENO = `
query($mobile_no:String){
   user_profiles(where:{mobile_no:{eq:$mobile_no}}) {
    id
  }
}`;
export const GET_UNIT_BY_PROPERTY = `
query($id:String){
  property(where:{id:{eq:$id}}){
    unit{
      value:id
      label:unit_no
      agreement_units{
        id
    }
    }
  }
}`;

export const DOMESTIC_SERVICE_VENDOR_DETAILS = `
query GET_ENTRIES_FOR_DOMESTICT_HELPER_VENDOR_SERVICE_PROVIDER_BY_VISITOR_TYPE(
  $id: String
) {
  swift_in_out_log(where: { id: { eq: $id }, is_active: { eq: true } }) {
    id
    visitor_type
    person_name
    vendor_image
    vehicle_image
    reference_id
    access_gates_master {
      gate_no
      name
    }
    check_in_byByID(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    check_in_by
    check_in_time
    check_out_byByID(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    check_out_time
    domestic_helper_request(where: { is_active: { eq: true } }) {
      id
      helper(where: { is_active: { eq: true } }) {
        id
        name
        profession_master {
          description
        }
        mobile_country_code
        mobile_no
        created_by(where: { is_active: { eq: true } }) {
          id
          first_name
          last_name
        }
      }
      created_at
      request_status
      unit(where: { is_active: { eq: true } }) {
        id
        unit_no
      }
      created_by(where: { is_active: { eq: true } }) {
        id
        first_name
        last_name
      }
      profession(where: { is_active: { eq: true } }) {
        id
        name
      }
      reference_id
      remarks
    }
    property(where: { is_active: { eq: true } }) {
      id
      property_no
      name
    }
    vendor_master(where: { is_active: { eq: true } }) {
      id
      name
    }
    service_providers_master(where: { is_active: { eq: true } }) {
      id
      name
    }
  }
  swift_in_out_log_units(where:{swift_log_id:{eq:$id},is_active:{eq:true}}){
    unit{
     id
      name
      unit_no
    }
  }

}
`;

export const GET_SWIFT_IN_OUT_LIST = `
query GET_SWIFT_IN_OUT(
  $visitorType: [String]
  $unit_id: [String]
  $offset: Int
  $limit: Int
) {
  swift_in_out_log(
    where: {
      visitor_type: { in: $visitorType }
      swift_in_out_log_units: { unit_id: { in: $unit_id } }
      is_active: { eq: true }
      client:{eq:$client}
    }
    offset: 0
    limit: 10
  ) {
    id
    swift_in_out_log_units {
      id
      unit_id
      swift_log_id
    }
    visitor_type
    person_name
    reference_id
    access_gates_master {
      gate_no
      name
    }
    check_in_byByID(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    check_in_time
    check_out_byByID(where: { is_active: { eq: true } }) {
      id
      first_name
      last_name
    }
    check_out_time
    domestic_helper_request(where: { is_active: { eq: true } }) {
      id
      helper(where: { is_active: { eq: true } }) {
        id
        name
        profession_master {
          description
        }
        mobile_country_code
        mobile_no
        created_by(where: { is_active: { eq: true } }) {
          id
          first_name
          last_name
        }
      }
      created_at
      request_status
      unit(where: { is_active: { eq: true } }) {
        id
        unit_no
      }
      created_by(where: { is_active: { eq: true } }) {
        id
        first_name
        last_name
      }
      profession(where: { is_active: { eq: true } }) {
        id
        name
      }
      reference_id
      remarks
    }
    property(where: { is_active: { eq: true } }) {
      id
      property_no
      name
    }
    vendor_master(where: { is_active: { eq: true } }) {
      id
      name
      image_url
    }
    service_providers_master(where: { is_active: { eq: true } }) {
      id
      name
      image_url
    }
  }
}

`;

export const UTILITIES_DROPDOWN_UNITS = `
query($property_id:$String,unit_id:[$String]){
  unit(where:{
      property_id:{eq:$property_id}
      id:{in:$unit_id}
  }){
      value:id
      label:unit_no
      unit_name:name

  }
}
`
export const METRE_DROPDOWN = `
query($unit_id:String,$utility_id:String){
    unit_utilties(where:{unit_id:{eq:$unit_id}utility_id:{eq:$utility_id}}){
        value:id
        label:meter_serial_no
      }

}
`
export const METRE_DETAILS = `
query{
    unit_utilties_readings(where:{unit_utlities_id:$unit_utlities_id} order_by:{created_at:desc}){
        id
        date
        created_at
        units_consumed
        cost_per_unit
        previous_meter_readings
        updated_at
        present_meter_readings
        bill_total
        usage_period_from
        usage_period_to
        unit_utilties{
          period
        }
        currency_master{
            id
            symbol
        }
        invoice{
            company_masterByID{
                company_id:id
            }
        }
    }
}
`
// export const GET_PROPERTY_DETAILS_FOR_MANAGEMENT = () => {
//   return gql`query GET_PROPERTY_DETAILS_FOR_MANAGEMENT($propertyID: ID) {
//     property(
//       where: {
//         id: { eq: $propertyID },
//         management_committee :{
//           committee_role: {role_name:{ iregex: $searchText} },
//         }

//        }
//       ) {
//       id
//       propertyName: name
//       propertyDescription: description
//       management_committee( where: {
//         property_id: { eq: $propertyID },

//       }){
//         id
//         country_code
//         contact_no
//         email_id
//         resident_profileByID{
//           first_name
//           image_url
//       }
//        committee_role{
//            role_name
//        }
//       }
//       property_no
//       property_purpose

//     }

//   }`
// };


export const GET_PROPERTY_DETAILS_FOR_MANAGEMENT = () => {
  return gql`query GET_PROPERTY_DETAILS_FOR_MANAGEMENT($propertyID: ID) {
    management_committee(
      where: {
          property_id: {eq: $propertyID},
             is_active:true,
             client:{eq:$client}
          committee_role: {role_name: {iregex: $searchText}}}

    ) {
      id
      country_code
      contact_no
      is_active
      email_id
      property {
        id
        propertyName: name
        propertyDescription: description
        property_no
        property_purpose
      }
      resident_profileByID {
        first_name
        image_url
        email_id
        mobile_no
        mobile_no_country_code
      }
      committee_role {
        role_name
      }
      unitByID {
        id
        unit_no
        name
      }
    }
  }
  `
};



export const GET_AGREEMENT_UNIT_ID = () => {
  return gql`
  query GET_AGREEMENT_UNIT_DETAILS {
    agreement_units(where: {agreement_id: {eq: $agreementId} unit_id:{eq:$unitId}}) {
      id
    }
  }`
}


export const GET_AGREEMENT_UNITS = () => {
  return gql`
  query GET_AGREEMENT_UNIT_DETAILS {
    agreement_units(where: {agreement_id: {in: $agreementId}unit_id:{in:$unitId}}) {
      id
      unit{
        id
        name
        property{
          id
          name
          company{
            value:id
          }
      }

    }
    }
  }`
}
export const GET_AGREEMENT_DETAILS = () => {
  return gql`query GET_AGREEMENT_DETAILS {
    agreement(where: { id: { eq: $agreementID } }) {
      id
      lease_start_date
      lease_end_date
    }
  }`
};

export const GET_VEHICLES = () => {
  return gql`query GET_VEHICLES {
    parking_slot(
      where: {
        is_active: { eq: true }
        agreement_id: { in: $agreementIDs }
        client: { eq: $client }
        or: {
          number_plate: { iregex: $search }
          parking_master: { parking_no: { iregex: $search } }
          parking_area: { vehicle_parking_area: { iregex: $search } }
        }
      }
      order_by: { updated_at: desc }
      offset: $offset
      limit: $limit
    ) {
      id
      vehicle_image
      parking_id
      property_id
      propertyByID {
        name
      }
      parking_masterByID {
        id
        parking_no
        parking_status
      }
      parking_area_id
      parking_areaByID {
        id
        vehicle_parking_area
      }
      vehicle_type
      number_plate
      agreement_unit_id
      agreement_unitsByID {
        id
        unit_id
        unitByID {
          id
          unit_no
          name
        }
      }
    }
  }`
};

export const GET_VEHICLE = () => {
  return gql`query GET_VEHICLE {
    parking_slot(
      where: { id: {eq: $parkingSlotID}}
    ) {
      id
      vehicle_image
      parking_id
      is_insured
      parking_masterByID {
        id
        parking_no
        parking_status
      }
      parking_area_id
      parking_areaByID {
          id
          vehicle_parking_area
      }
      vehicle_type
      number_plate
      insurance_name
      insurance_notes
      valid_till
      agreement_id
      agreement_unit_id
      agreement_unitsByID {
        id
      agreement_id
      agreementByID {
        id
        lease_start_date
        lease_end_date
      }
      unit_id
      unitByID {
        id
        name
        unit_no
        property_id
        propertyByID {
          id
          company_id
        }
      }
      }
    }
  }`
};

export const UPDATE_PARKING_SLOT = () => {
  return gql`mutation UPDATE_PARKING_SLOT{
    parking_slot(where:{id:{eq:$parkingSlotID}} update:$payload){
        id
    }
}`
};


export const GET_AGREEMENT_UNIT_NO_BY_UNIT = () => {
  return gql`
  query GET_AGREEMENT_UNIT_ID {
    agreement_units(where:{unit_id:{in:$unitId}}) {
      id
      unit{
          id
          name
      }
    }
  }`

}


export const GET_PROPERTY_UNITID = () => {
  return gql`
  query GET_PROPERTY_BY_UNITID {
    unit(
      where: {
        id: { in: $unit_id }
        is_active: { eq: true }
      }
    ) {
      property {
        id
        name
      }
    }
  }`
}

export const GET_PROPERTY_AMENITIES = () => {
  return gql`
  query ($property_id: String) {
  property(where: {id: {eq: $property_id}, client: {eq: $client}}) {
    id
    name
    logo
    companyByID{
      id
      name
    }
    __typename
  }
  property_facilities(
    where: {property_id: {eq: $property_id}, client: {eq: $client}}
  ) {
    id
    is_chargeable
    period
    rate
     company{
      name
      id
    }
     
    description
    currencyByID{
      id 
      symbol
    }
    amenities_type_masterByID {
      id
      amenities_type
      amenities_name
      is_booking
      is_transactional
      __typename
    }
    __typename
  }
}
  `
}
export const GET_UNIT_DETAILS_BY_UNIT_ID = () => {
  return gql`
query ($unitId: String, $agreement_unit_id: String) {
  unit(where: {id: {eq: $unitId}, client: {eq: $client}}) {
    name
    unit_no
    logo   
    property {
      id
      name
      companyByID{
      name
      id
    }
      __typename
    }
    block {
      id
      name
      __typename
    }
    floor {
      id
      name
      __typename
    }
    __typename
  }
  unit_amenities_breakup(where: {unit_id: {eq: $unitId}, client: {eq: $client}}) {
    name
    is_chargeable
    period
    rate
     company{
      name
      id
    }
    currencyByID{
      id
      symbol
    }
    id
    amenities_type_masterByID {
      id
      amenities_name
      is_booking
      is_transactional
      __typename
    }
    __typename
  }
  ameneties_usage(
    where: {agreement_unit_id: {eq: $agreement_unit_id}, client: {eq: $client}}
  ) {
    invoice {
      invoice_no
      payment_due_date
      invoice_type
      description
      __typename
    }
    __typename
  }
  
}
`};

export const GET_UNIT_AMENITIES = `
  query($unit_amenities_id:String){
    ameneties_usage(where:{
      unit_amenities_id:{eq:$unit_amenities_id} }
      order_by:{created_at:desc}){
        from
        to
        total_bill
        paid_on
        rate_per_hour
        paid_amount
        total_hours
        created_at
        invoice{
            payment_due_date
            currency_master{
              symbol
            }
             company_id

        }

    }
}
  `
export const GET_PROPERTY_AMENITIES_DETAILS = `
  query($property_facilities_id:String){
    ameneties_usage(where:{property_facilities_id:{eq:$property_facilities_id}},order_by:{created_at:desc}){
        from
        to
        total_bill
        paid_on
        rate_per_hour
        paid_amount
        total_hours
        created_at
        invoice{
            payment_due_date
             currency_master{
              symbol
            }
                        company_id

        }
    }
}
  `

export const GET_AGREEMENT_PDF = `
query($id:String){
  invoice(where:{id:{eq:$id}})
{
  asset_url
}
}`;


export const GET_AGREEMENT_CONTACT_DETAILS = gql`
query{
  agreement(where:{id:{eq:$id}}){
    id
    contact_id
    contactByID{
      first_name
      last_name
      mobile_no
      mobile_no_country_code
      email_id
    }
    lease_end_date
    lease_start_date
    lease_period
    lease_duration
  }
  }`
export const GET_INVOICE_DETAILS = gql`
query{
  invoice(
    where: {
    is_active:{eq:true}
id:{eq:$id} 
       }  ){
  		id
    asset_url
    invoice_no 
  }
}
`
// currency_master{
//   id
//   symbol
// }
export const GET_ALL_CUNTRIES = (offset = 0, limit = 10, searchText = "") => gql`
query GET_ALL_CURRENCY{
    count:country_master(
         where:{
            country_name:{iregex:"${searchText}"}
        }
    ){
        count:count_id
    }
    country_master(
        where:{
            country_name:{iregex:"${searchText}"}
        }
        offset:${offset}
        limit:${limit}
    ){
        value:id
        label:country_name
    }
}
`
export const GET_ASSET_MASTER = gql`
query {
  assets_master(where: { is_active: { eq: true } }, order_by: { order: asc }) {
    id
    type
    order
  }
}
`
export const GET_CORRESPONDENCES_TYPE_MASTER = gql`
query GET_CORRESPONDENCES_TYPE_MASTER($search: String) {
  correspondences_type_master(where: { name: { iregex: $search } }) {
    value: id
    label: name
  }
}`

export const AGREEMENT_INSPECTION_PDF = gql`
query {
  agreement_inspection(where: { id: { eq: $id } }) {
    id
    description
    signature
    status
    asset_url
  }
}`


export const GET_MOBILE_COUNTRY_CODE = (userProfileID) => gql`
query user_profile{
  user_profiles(where:{id:"${userProfileID}"}){
    mobile_no_country_code
  }
}`

export const GET_AGREEMENT_AGAINST_ACCOUT = (offset, limit, account_no, search) => {
  return gql`
  
  query SELECCONTACT{
    count: agreement(
      where:{
        account_id:{eq:"${account_no}"}
        agreement_no:{iregex:"${search}"}
        is_active:{eq:true}
      }
    ) {
      count:count_id
    }
    agreement(
        where:{
          account_id:{eq:"${account_no}"}
          agreement_no:{iregex:"${search}"}
          is_active:{eq:true}
        }
        offset:${offset}
        limit:${limit}
      ){
          label:agreement_no
          value:id
        }
  }`
}