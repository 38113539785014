import { Box, Divider, Paper, Stack, Typography } from "@mui/material"
import { useAddOnStyles } from "./style"


export const ServiceCard = (props) => {
    const { t=()=>false } = props

    const classes = useAddOnStyles()
    return (
        <>
            <Typography className={classes.serviceHead}>{t("SERVICES AVAILABLE")}</Typography>
            <Paper className={classes.servicelist} >

                {
                    props?.state?.map(e => {
                        return e?.services?.map(val => {
                            return (
                                <>
                                    <Box pr={1} pl={1}>
                                        <Stack pt={1} pb={1} direction="row" alignItems={"center"}
                                            justifyContent={"space-between"}>
                                            <Box className={classes.imgDiv}>
                                                <Box className={classes.svgBox}>
                                                    {val?.img}</Box>
                                                <Box>
                                                    <Typography className={classes.unitName}>{val?.service}</Typography>
                                                    <Typography className={classes.Qty}>{val?.Qty}</Typography>
                                                </Box>
                                            </Box>
                                            <Box className={classes.cardRight}>
                                                <Typography className={classes.amount}>{val?.amount}</Typography>
                                                <Box className={classes.available}>
                                                    <Typography>{val?.available}</Typography>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <Divider />
                                    </Box>
                                </>

                            )
                        })
                    })
                }
            </Paper>

        </>
    )
}